import React from 'react';
import './TimelineComment.scss';
import {IComment} from './commentProxy';
import TimelineMessage from './TimelineMessage';
import TimelineCommentInput from './TimelineCommentInput';
import {getInitials, getName} from '../common/getName';
import {commentStore} from './CommentStore';
import TimelineChats from './TimelineChats';
import AttachmentTag from '../Attachment/AttachmentTag';
import {TimelineRow} from './TimelineItem';
import {colorHash} from '../common/colorHash';
import {user} from '../stores/user';
import { observer } from 'mobx-react';
import { t } from '../translations';

interface TimelineCommentState
{
    editing?: boolean
}

@observer
export default class TimelineComment extends React.PureComponent<IComment, TimelineCommentState>
{
    state: TimelineCommentState = {};

    startEdit = () => this.setState({editing: true});
    endEdit = () => this.setState({editing: false});

    handleDelete = () =>
    {
        if (confirm('Are you sure you want to delete this comment?'))
        {
            commentStore.deleteComment(this.props.id);
        }
    };

    handleSubmit = (newMessage: string, addAttachments: string[], removeAttachments: string[]) =>
    {
        commentStore.editComment(this.props.id, newMessage, removeAttachments, addAttachments).then(() =>
        {
            this.endEdit();
        });
    };

    render()
    {
        const p = this.props;
        const mark = getInitials(p.author);
        const markStyle = {backgroundColor: colorHash.hex(p.author?.id)};
        const name = getName(p.author);
        const chat = <TimelineChats targetId={p.id}/>;
        if (this.state.editing)
        {
            return (
                <TimelineCommentInput
                    withLine
                    mark={mark}
                    markStyle={markStyle}
                    name={name}
                    defaultMessage={p.text}
                    attachments={p.attachments}
                    canAttach
                    cancelable
                    onSubmit={this.handleSubmit}
                    onCancel={this.endEdit}
                >
                    {chat}
                </TimelineCommentInput>
            );
        }
        const attachments = !!p.attachments && p.attachments.map(a => (
            <AttachmentTag
                key={a.key}
                keyName={a.key}
                name={a.name}
            />
        ));
        return (
            <TimelineMessage
                withLine
                mark={mark}
                markStyle={markStyle}
                name={name}
                message={p.text}
                unstyledMessage={attachments}
                date={p.date}
                hoverOptions={user.id === p.author?.id || user.moderator ? [[t.global.edit, this.startEdit], [t.global.delete, this.handleDelete]] : null}
            >
                {!!p.text && <TimelineRow content={attachments}/>}
                {chat}
            </TimelineMessage>
        );
    }
}
