import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import './Timeline.scss';
import {commentStore} from './CommentStore';
import {emailStore} from '../Email/EmailStore';
import {IEmailData} from '../../graphql/api/email/Email';
import {IComment} from './commentProxy';
import {user} from '../stores/user';
import TimelineCommentInput from './TimelineCommentInput';
import TimelineComment from './TimelineComment';
import TimelineEmail from './TimelineEmail';
import {t} from '../translations';
import {ErrorBoundary} from '../common/ErrorBoundary';
import {colorHash} from '../common/colorHash';
import { profileInvoiceStore } from '../Invoice/profileInvoiceStore';
import { IInvoice } from '../Invoice/InvoiceStore';
import TimelineInvoice from './TimelineInvoice';
import {timelineStandStore} from './TimelineStandStore';
import TimelineStand from './TimelineStand';
import { projectStore } from '../stores/ProjectStore';
import { contactsStore } from '../Contact/ContactsStore';
import {getName} from '../common/getName';
import TimelineCheckedInOut from './TimelineCheckedInOut';
import {pickLanguage} from '../stores/utility';
import {flat} from '../../lib/common';

interface TimelineProps
{
    id?: string
    isUserPage?: boolean
    userCompanies?: string[]
}

interface ITimelineStand
{
    id: string
    date: Date
    status: string
    target: string
    // projectId: string
    // companyId: string
    personId: string
    projectName: string
}

export interface ITimelineCheckedInOut
{
    name: string
    date: Date
    projectId: string
    checkedIn: boolean // checkedIn in or checkedIn out
}

type TimelineItemData =
    | (IComment & {type: 'comment'})
    | (IEmailData & {type: 'email'})
    | (IInvoice & {type: 'invoice'})
    | (ITimelineStand & {type: 'stand'})
    | (ITimelineCheckedInOut & {type: 'checkedInOut'})

type TabKey = 'commercialization' | 'event' | 'communication'

export function TimelineWithTabs(props: TimelineProps)
{
    const [tab, setTab] = useState<TabKey>();
    return (
        <>
            <div className='d-flex justify-content-center my-3'>
                <div className='TimelineTabs btn-group' role='group' aria-label='Basic example'>
                    <button type='button' className={'TimelineTab btn ' + (tab === 'commercialization' ? 'btn-primary' : 'btn-secondary')} onClick={() => setTab('commercialization')}>Commercialization</button>
                    <button type='button' className={'TimelineTab btn ' + (tab === 'event' ? 'btn-primary' : 'btn-secondary')} onClick={() => setTab('event')}>Event</button>
                    <button type='button' className={'TimelineTab btn ' + (tab === 'communication' ? 'btn-primary' : 'btn-secondary')} onClick={() => setTab('communication')}>Communication</button>
                </div>
            </div>
            {tab && <Timeline {...props} tab={tab}/>}
        </>
    );
}

const Timeline = observer(function Timeline({id, isUserPage, userCompanies, tab}: TimelineProps & {tab?: TabKey})
{
    useEffect(() =>
    {
        if (id)
        {
            if (!tab || tab === 'communication')
            {
                commentStore.reloadComments(id);
                if (isUserPage && (user.moderator || id == user.id))
                {
                    emailStore.loadEmails(id);
                }
            }
            if (!tab || tab === 'commercialization')
            {
                profileInvoiceStore.loadInvoices(id);
                timelineStandStore.load(user.moderator && user.id == id ? null : isUserPage ? userCompanies : [id]);
            }
        }
    }, [id, isUserPage, userCompanies, tab]);

    if (user.isHeadquarter)
    {
        return null;
    }

    const addComment = (msg: string, attachments: string[]) =>
    {
        commentStore.addComment(id, msg, attachments);
    };

    const person = isUserPage ? contactsStore.currentUserAndContacts.find(p => p.id == id) : null;
    const companyMembers = isUserPage ? [] : contactsStore.currentUserAndContacts.filter(p => p.company?.includes(id));

    let items: TimelineItemData[] = [];

    if (!tab || tab === 'communication')
    {
        items = items.concat(commentStore.comments[id]?.map(c => ({
            ...c,
            type: 'comment',
        })) || []);
        const emails = emailStore.emails[id];
        if (emails)
        {
            items = items.concat(emails.map(e => ({
                ...e,
                type: 'email',
            })));
        }
    }
    if (!tab || tab === 'commercialization')
    {
        const companyInvoices = profileInvoiceStore.getInvoices;
        if (companyInvoices)
        {
            let invoices = companyInvoices.invoices;
            if (person)
            {
                invoices = invoices.filter(i => i.to.personId === id);
            }
            items = items.concat(invoices.map(i => ({
                ...i,
                type: 'invoice',
            })));
        }
        if ((!isUserPage || user.id == id || userCompanies.length) && timelineStandStore.stands.length)
        {
            const companyStands = timelineStandStore.stands
                .filter(s => person ? person.company.includes(s.companyId) : s.companyId == id)
                .map(s => s.items?.map((i): ITimelineStand & {type: 'stand'} => ({
                    ...i,
                    id: s.id,
                    date: new Date(i.date),
                    projectName: pickLanguage(projectStore.projects.find(p => p.id == s.projectId)?.name),
                    type: 'stand',
                })))
                .filter(s => s?.length);
            if (companyStands.length)
            {
                let standItems = flat(companyStands);
                if (person)
                {
                    // filter out the entries that show names of other users
                    standItems = standItems.filter(cs => cs.status == 'approved' || cs.personId == id);
                }
                items = items.concat(standItems);
            }
        }
    }
    if (!tab || tab === 'event')
    {
        const usersForBadges = person ? [person] : companyMembers;
        for (const userForBadges of usersForBadges)
        {
            if (userForBadges.badges)
            {
                for (const badge of userForBadges.badges)
                {
                    if (badge.scannedOut?.length)
                    {
                        items = items.concat(badge.scannedOut.map(s => ({
                            name: getName(userForBadges),
                            projectId: badge.project,
                            date: new Date(s.time),
                            type: 'checkedInOut',
                            checkedIn: false,
                        })));
                    }
                    if (badge.scannedIn?.length)
                    {
                        items = items.concat(badge.scannedIn.map(s => ({
                            name: getName(userForBadges),
                            projectId: badge.project,
                            date: new Date(s.time),
                            type: 'checkedInOut',
                            checkedIn: true,
                        })));
                    }
                }
            }
        }
    }

    items.sort((a, b) => +b.date - +a.date);

    return (
        <ErrorBoundary>
            <div className='Timeline'>
                {user.moderator && (!tab || tab === 'communication') &&
                    <TimelineCommentInput
                        mark={user.initials}
                        name={user.name}
                        markStyle={{backgroundColor: colorHash.hex(user.id)}}
                        canAttach
                        onSubmit={addComment}
                    />
                }
                {items.length ?
                    <ErrorBoundary>
                        {items.map((i, index) =>
                        {
                            if (i.type === 'comment') return <TimelineComment key={index} {...i}/>;
                            if (i.type === 'email') return <TimelineEmail key={index} {...i}/>;
                            if (i.type === 'stand') return <TimelineStand key={index} {...i}/>;
                            if (i.type === 'invoice') return <TimelineInvoice key={index} {...i}/>;
                            if (i.type === 'checkedInOut') return <TimelineCheckedInOut key={index} {...i}/>;
                        })}
                    </ErrorBoundary>
                    :
                    <div className='mt-3 no-content'>
                        {!tab ?
                            commentStore.loadingComments || emailStore.loadingEmails || timelineStandStore.loading ?
                                t.global.loading
                                :
                                t.contacts.noFeed
                            :
                            tab === 'communication' ?
                                commentStore.loadingComments || emailStore.loadingEmails ?
                                    t.global.loading
                                    :
                                    t.contacts.noFeed
                                :

                                tab === 'commercialization' ?
                                    timelineStandStore.loading ?
                                        t.global.loading
                                        :
                                        'Empty'
                                    :
                                    'Empty'
                        }
                    </div>
                }
            </div>
        </ErrorBoundary>
    );
});

export default Timeline;
