import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import './UserEventRegistration.scss';
import {availableBadgesStore} from './AvailableBadgesStore';
import {t} from '../translations';

export default observer(function RemainingBadges(
    {project, companyId, userId, selectedCount}: {project: string, companyId: string, userId?: string, selectedCount: number}
)
{
    useEffect(() =>
    {
        availableBadgesStore.load(project, companyId);
    }, [project, companyId]);

    const badges = availableBadgesStore.find(project, companyId);
    let count = selectedCount;
    if (badges)
    {
        for (const u of badges.assignedBadgesByUser)
        {
            if (u.id != userId)
            {
                count += u.count;
            }
        }
    }

    return (
        <div className='RemainingBadges'>
            <FA name='exclamation-circle' className='mr-2'/>
            {(badges == null ? '?' : badges.companyBadges - count) + ' ' + t.eventPage.badgesRemaining}
        </div>
    );
});

export const RemainingBadgesNegative = observer(function RemainingBadgesNegative(
    {project, companyId, userId, selectedCount}: {project: string, companyId: string, userId?: string, selectedCount: number}
)
{
    const badges = availableBadgesStore.find(project, companyId);

    let count = selectedCount;
    if (badges)
    {
        for (const u of badges.assignedBadgesByUser)
        {
            if (u.id != userId)
            {
                count += u.count;
            }
        }
    }

    if (!badges || badges.companyBadges - count >= 0)
    {
        return null;
    }

    return <RemainingBadgesNegativeBlock/>;
});

export function RemainingBadgesNegativeBlock()
{
    return (
        <div className='RemainingBadges'>
            <FA name='exclamation-circle' className='mr-2'/>
            {t.eventPage.badgesAdditionalCharges}
        </div>
    );
}
