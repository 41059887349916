import {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import './StoreCatalogCard.scss';
import {projectStore} from '../stores/ProjectStore';
import {colon, user} from '../stores/user';
import {ICatalogProduct, ICatalogProductWeek} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {companyStore, ICompany} from '../Company/CompanyStore';
import {countryCurrency} from '../OrderStand/utility';
import { Link } from 'react-router-dom';
import TextEditable from '../common/TextEditable';
import {cartQuantityStore} from './CartQuantityStore';
import {useIsCatalogTurboView} from './StoreCatalog';
import { Country } from '../../graphql/api/Types';
import ProductStarDiscount from './ProductStarDiscount';
import ProductImage from './ProductImage';
import {pickLanguage} from '../stores/utility';
import {ExtraRows, FRInfoRows, FRMarketStars, FRPriceRow, FRProductImage, InfoRows, PriceRows, ProductComment} from './PriceInfo';
import ProductLabels from './ProductLabels';
import TotalQuantityOverlay from './TotalQuantityOverlay';
import {t} from '../translations';
import ProductOrdered from './ProductOrdered';
import {getProductTotal, getProductTotalFR, getProductTotalIT} from './productUtility';
import {getDeliveryMonth} from './deliveryMonthUtility';
import LimitedQuantityDateOverlay from './LimitedQuantityDateOverlay';
import {ProductPackage, ProductPackagePrice} from './ProductDisplayPackage';

interface Props
{
    product: ICatalogProduct
    preselectedStore?: ICompany
    deliveryMonth?: string
    isCatalogView?: boolean
}

export default observer(function StoreCatalogCard({product, preselectedStore, deliveryMonth, isCatalogView}: Props)
{
    const country = projectStore.selectedCountry;
    const currency = countryCurrency(country);
    const isFair = !projectStore.isPromotion;
    const isFRNonFood = projectStore.isFRNonFood;
    const isItalyHeadQuarters = projectStore.italyHeadquarters;

    const ownStores = companyStore.ownStores.filter(c => product.stores.some(s => s.storeId == c.store.id));
    const [selectedCompany, selectCompany] = useState<string>(preselectedStore?.id || ownStores[0]?.id);
    const store = ownStores.find(c => c.id == selectedCompany);
    const storeId = store?.store?.id;
    const storeIndex = ownStores.findIndex(c => c.id == selectedCompany);
    const isTurboView = useIsCatalogTurboView();

    useEffect(() =>
    {
        if (preselectedStore)
        {
            selectCompany(preselectedStore.id);
        }
    }, [preselectedStore]);

    if (!preselectedStore && !store && ownStores.length)
    {
        selectCompany(ownStores[0].id);
    }

    const canView = (!!ownStores.length && !!product.weeks.length) || isItalyHeadQuarters;
    const canViewDueToAdmin = !canView && user.moderator;

    if (!canView && !user.moderator)
    {
        return null;
    }

    const getProductStoreInfoForTotal = (sid: string) =>
    {
        const storeForTotal = product.stores.find(ps => ps.storeId == sid);
        if (deliveryMonth)
        {
            if (storeForTotal)
            {
                const year = deliveryMonth && new Date(projectStore.selected?.fair.date.start).getFullYear();
                return {
                    ...storeForTotal,
                    weeks: storeForTotal.weeks.filter(p => deliveryMonth == getDeliveryMonth(p.week, year)),
                };
            }
        }
        return storeForTotal;
    };
    const totalCell = (s: ICompany) =>
        s ?
            country == 'IT' ?
                totalCellIT(s.store.id) :
                country == 'FR' ?
                    isFRNonFood || ['MF', 'MI', 'H', 'HF'].includes(s.store.storeType) ?
                        totalCellFR(s) :
                        null :
                    totalCellBase(s.store.id) :
            null;
    const totalCellBase = (sid: string) => (
        <td key={sid} className='price'>
            {country == 'BE' ? isFair ? 'PV' : 'PVP' : t.reports.total}{' '}
            <b>{user.formatCurrency(
                currency,
                getProductTotal(product.id, getProductStoreInfoForTotal(sid))
            )}</b>
        </td>
    );
    const totalCellFR = (s: ICompany) =>
    {
        const sid = s.store.id;
        const total = getProductTotalFR(product.id, getProductStoreInfoForTotal(sid));
        if (!total.selling)
        {
            return null;
        }
        return (
            <td key={sid} className={'price text-right' + (isTurboView ? ' text-small' : '')}>
                {isFRNonFood ?
                    'CPI' == s.store.storeType ?
                        <p>
                            {product.flow == 'GSA' ? 'FOB ' : 'Px Cession '}
                            <b>{user.formatCurrency(currency, total.invoice)}</b>
                        </p>
                        :
                        <>
                            <p>{'Px Cession '}<b>{user.formatCurrency(currency, total.invoice)}</b></p>
                            <p>{'PVC '}<b>{user.formatCurrency(currency, total.selling)}</b></p>
                        </>
                :
                    <p>{'PVC '}<b>{user.formatCurrency(currency, total.selling)}</b></p>
                }
            </td>
        );
    };
    const totalCellIT = (sid: string) => (
        <td key={sid} className='price'>
            {'PC '}
            <b>{user.formatCurrency(
                currency,
                getProductTotalIT(product.id, getProductStoreInfoForTotal(sid))
            )}</b>
        </td>
    );

    return (
        <div className={'StoreCatalogCard ' + (isTurboView ? 'turbo' : 'row')}>
            <div className={'top ' + (isTurboView ? '' : 'col-xl-6')}>
                <ProductStarDiscount starDiscount={product.stores.find(s => s.storeId == storeId)?.starDiscount}/>
                <div className='image'>
                    <ProductImage images={product.images}/>
                    <TotalQuantityOverlay product={product} storeId={storeId}/>
                    <LimitedQuantityDateOverlay product={product}/>
                </div>
                <div className='info'>
                    <Link className='name' to={`/catalog/${product.id}`}>
                        {pickLanguage(product.description)}
                    </Link>
                    {country == 'FR' ?
                        <>
                            {isFRNonFood && <FRInfoRows product={product} storeId={storeId}/>}
                            <ProductComment product={product} storeId={storeId}/>
                            <FRPriceRow product={product} storeId={storeId}/>
                            {isFRNonFood && <FRProductImage product={product} storeId={storeId}/>}
                            <ExtraRows product={product} storeId={storeId}/>
                            {isFRNonFood && <FRMarketStars product={product} storeId={storeId}/>}
                        </>
                        :
                        <>
                            <InfoRows product={product} storeId={storeId}/>
                            <ExtraRows product={product} storeId={storeId}/>
                            <PriceRows product={product} storeId={storeId}/>
                        </>
                    }
                    {isCatalogView && <ProductPackage product={product} currency={currency}/>}
                    <ProductPackagePrice product={product} currency={currency}/>
                    <ProductLabels product={product} storeId={storeId}/>
                    <ProductOrdered product={product} storeId={storeId}/>
                </div>
            </div>
            <div className={'bot ' + (isTurboView ? '' : 'col-xl-6')}>
                {projectStore.canAddToCart && !canViewDueToAdmin &&
                <div className='clearfix'>
                    {isTurboView ? (
                        <table className='promotions'>
                            <tbody>
                            <tr>
                                <th scope='row'/>
                                {country == 'BE' && <td/>}
                                {country == 'FR' && <td/>}
                                <td/>
                                <td/>
                                {ownStores.map(s => <td key={s.id} className='store-name'><div>{s.store.id}</div><div>{s.name}</div></td>)}
                            </tr>
                            {product.weeks.map((w, i) =>
                                <TurboRow
                                    key={i}
                                    product={product}
                                    week={w}
                                    country={country}
                                    ownStores={ownStores}
                                />
                            )}
                            <tr className='total'>
                                <td/>
                                {country == 'BE' && <td/>}
                                {country == 'FR' && <td/>}
                                <td/>
                                <td/>
                                {ownStores.map(totalCell)}
                            </tr>
                            </tbody>
                        </table>
                    ) : (
                        <table className='promotions'>
                            <tbody>
                            <tr>
                                <th scope='row'/>
                                {country == 'BE' && <td/>}
                                {country == 'FR' && <td/>}
                                <td className='store-name-select'>
                                    <FA
                                        name='chevron-left'
                                        className={'fas text-secondary cursor-pointer pr-2 ' + (!preselectedStore && storeIndex > 0 ? 'show' : 'hide')}
                                        onClick={() => selectCompany(ownStores[storeIndex - 1].id)}
                                    />
                                    <span className='store-name'>{store?.store?.id} {store?.name}</span>
                                    <FA
                                        name='chevron-right'
                                        className={
                                            'fas text-secondary cursor-pointer pl-2 ' +
                                            (!preselectedStore && storeIndex < ownStores.length - 1 ? 'show' : 'hide')
                                        }
                                        onClick={() => selectCompany(ownStores[storeIndex + 1].id)}
                                    />
                                </td>
                            </tr>
                            {!!product.weeks.length && <>
                                <StoreSlider
                                    country={country}
                                    product={product}
                                    storeId={storeId}
                                    deliveryMonth={deliveryMonth}
                                />
                                <tr className='total'>
                                    <td/>
                                    {country != 'PL' && country != 'IT' && <td/>}
                                    {totalCell(store)}
                                </tr>
                            </>}
                            </tbody>
                        </table>
                    )}
                </div>}
            </div>
        </div>
    );
});

interface ITurboRow
{
    country: Country
    week: ICatalogProductWeek
    product: ICatalogProduct
    ownStores: ICompany[]
}

const TurboRow = observer(function TurboRow({week, product, ownStores}: ITurboRow)
{
    const [bulkQuantity, setBulkQuantity] = useState<number>(0);

    const projId = projectStore.id;
    const country = projectStore.selectedCountry;
    // const isFair = !projectStore.isPromotion;
    const pid = product.id;

    return (
        <tr key={week.promoCode}>
            {country == 'FR' ?
                <th scope='row' className='week'>{week.week != null && `${['fr', 'it'].includes(user.language) ? 'Sem.' : 'Week'} ${week.week}`}</th>
                :
                country != 'PL' ?
                    <th scope='row' className='week'>{`${['fr', 'it'].includes(user.language) ? 'S' : 'W'}${week.week}`}</th>
                    :
                    <th scope='row'>{t.global.quantity + colon()}</th>
            }
            {country == 'BE' &&
            <td className='promoCode'>
                <p>{week.promoCode}</p>
                {!!week.folderType && <p>{week.folderType}</p>}
            </td>}
            {country == 'FR' &&
            <td className='promoCode'>
                {week.sousVente ?
                    <div className='catalog' style={product.sousVenteSize ? {fontSize: product.sousVenteSize + 'em'} : null}>
                        {week.sousVente}
                    </div>
                    :
                    null
                }
            </td>}
            <td className='px-1'>
                <input
                    className='form-control'
                    type='number'
                    min={0}
                    step={1}
                    value={bulkQuantity}
                    onChange={e =>
                    {
                        e.target.value = numberInputFormat(e.target.value);
                        setBulkQuantity(Math.ceil(+e.target.value));
                    }}
                />
            </td>
            <td className='copy'>
                <FA
                    name='chevron-right'
                    className='fas text-secondary cursor-pointer px-2'
                    onClick={() =>
                    {
                        const promoCode = week.promoCode;
                        for (const s of ownStores)
                        {
                            if (product.stores.find(sw => sw.storeId == s.store.id)?.weeks.some(p => p.promoCode == promoCode))
                            {
                                cartQuantityStore.setQuantity(projId, s?.store?.id, pid, promoCode, week.week, +bulkQuantity);
                            }
                        }
                    }}
                />
            </td>
            {ownStores.map(s =>
            {
                const storeId = s.store.id;
                const promoCode = week.promoCode;
                const storeWeek = product.stores.find(sw => sw.storeId == s.store.id)?.weeks.find(p => p.promoCode == promoCode);
                if (!storeWeek)
                {
                    return <td key={storeId} className='px-1'/>;
                }
                const setQuantity = (q: number) => cartQuantityStore.setQuantity(projId, storeId, pid, promoCode, week.week, q);
                return (
                    <td key={storeId} className='px-1'>
                        <input
                            className='form-control'
                            type='number'
                            min={storeWeek.minQuantity || 0}
                            step={1}
                            value={cartQuantityStore.getQuantityChange(projId, storeId, pid, promoCode) ?? storeWeek.quantity}
                            onChange={e =>
                            {
                                e.target.value = numberInputFormat(e.target.value);
                                setQuantity(Math.ceil(+e.currentTarget.value));
                            }}
                        />
                    </td>
                )
            })}
        </tr>
    )
})

interface IStoreSlider
{
    product: ICatalogProduct
    country: Country
    storeId: string
    deliveryMonth?: string
}

const StoreSlider = observer(function StoreSlider({product, country, storeId, deliveryMonth}: IStoreSlider)
{
    const projId = projectStore.id;
    const year = deliveryMonth && new Date(projectStore.selected?.fair.date.start).getFullYear();
    return (<>
        {product.weeks.map(p =>
        {
            const promoCode = p.promoCode;
            const productStoreWeek = product.stores.find(s => s.storeId == storeId)?.weeks.find(wp => wp.promoCode == promoCode);
            if (!productStoreWeek)
            {
                return null;
            }
            const quantity = cartQuantityStore.getQuantityChange(projId, storeId, product.id, promoCode) ?? productStoreWeek.quantity;
            const disabled = !!deliveryMonth && deliveryMonth != getDeliveryMonth(p.week, year);
            const setQuantity = (q: number) => !disabled && cartQuantityStore.setQuantity(projId, storeId, product.id, promoCode, p.week, q);
            return (
                <tr key={promoCode} className={disabled ? 'disabled' : null}>
                    {country == 'FR' ?
                        <th scope='row' className='week'>{p.week != null && `${['fr', 'it'].includes(user.language) ? 'Sem.' : 'Week'} ${p.week}`}</th>
                        :
                        country != 'PL' ?
                            <th scope='row' className='week'>{`${['fr', 'it'].includes(user.language) ? 'S' : 'W'}${p.week}`}</th>
                            :
                            <th scope='row'>{t.global.quantity + colon()}</th>
                    }
                    {country == 'BE' &&
                    <td className='promoCode'>
                        <div>{p.promoCode}</div>
                        {!!p.folderType && <div>{p.folderType}</div>}
                    </td>}
                    {country == 'FR' &&
                    <td className='promoCode'>
                        {p.sousVente ?
                            <div className='sousVente' style={product.sousVenteSize ? {fontSize: product.sousVenteSize + 'em'} : null}>
                                {p.sousVente}
                            </div>
                            :
                            productStoreWeek.catalog ?
                                <div className='catalog'>{productStoreWeek.catalog}</div>
                                :
                                <div>{`Livraison ${['fr', 'it'].includes(user.language) ? 'S' : 'W'}${p.week || ''}`}</div>
                        }
                        {!!productStoreWeek.message &&
                        projectStore.isFRNonFood &&
                        <div className='message'>{productStoreWeek.message}</div>
                        }
                    </td>}
                    <td>
                        <span className='change-amount' onClick={quantity > (productStoreWeek.minQuantity || 0) ? () => setQuantity(quantity - 1) : null}>-</span>
                        <div className='mobile amount underlineElement'>
                            <input
                                type='number'
                                min={productStoreWeek.minQuantity || 0}
                                step={1}
                                value={quantity}
                                onChange={e => setQuantity(+e.currentTarget.value)}
                                disabled={disabled}
                            />
                        </div>
                        <TextEditable
                            type='span'
                            className='amount underlineElement'
                            content={'' + quantity}
                            onChangeEnter={q => setQuantity(+q)}
                            disabled={disabled}
                        />
                        <span className='change-amount' onClick={product.limitedQuantity !== 0 ? () => setQuantity(quantity + 1) : null}>+</span>
                    </td>
                </tr>
            )
        })}
    </>)
});

function numberInputFormat(value: string)
{
    return value[0] == '0' ? value.slice(0, 0) + value.slice(1) : value;
}
