// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MUdiT3t8HHi5KipY_tRd {
  padding: 6px 10px;
  gap: 10px;
  border: 1px solid #2169CA;
  border-radius: 3px;
}
.avgjSp3sRfmuUlw_JCkq {
  border: none;
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/components/PhoneField.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,SAAS;EACT,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,YAAY;AACd","sourcesContent":[".phone {\n  padding: 6px 10px;\n  gap: 10px;\n  border: 1px solid #2169CA;\n  border-radius: 3px;\n}\n.phoneInput {\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phone": `MUdiT3t8HHi5KipY_tRd`,
	"phoneInput": `avgjSp3sRfmuUlw_JCkq`
};
export default ___CSS_LOADER_EXPORT___;
