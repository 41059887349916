import {action, makeAutoObservable} from 'mobx';
import type {ITranslations} from '../../graphql/api/Types';
import {call, gql} from '../client';
import {projectStore} from '../stores/ProjectStore';

export interface IProductWorkshop
{
    // first 8 characters of the page id
    id: string
    name: ITranslations
    count: number
}

function getProductWorkshops(project: string)
{
    return call<{productWorkshops: IProductWorkshop[]}>(
        gql`query($project:ID!){productWorkshops(project:$project){id,name,count}}`,
        {project}
    ).then(({data}) => data?.productWorkshops);
}

class ProductWorkshopsStore
{
    list: {[project: string]: IProductWorkshop[]} = {};
    loading = false;

    get workshops()
    {
        return this.list[projectStore.id] || [];
    }

    constructor()
    {
        makeAutoObservable(this);
    }

    load(project: string)
    {
        this.loading = true;
        getProductWorkshops(project).then(action(res =>
        {
            this.loading = false;
            if (res)
            {
                this.list[project] = res;
            }
        }));
    }
}

export const productWorkshopsStore = new ProductWorkshopsStore();
