// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimelineMessage .name {
  opacity: 0.7;
}
.TimelineMessage .msg {
  margin-top: 0.5rem;
}
.TimelineMessage .msg .content {
  padding: 7px 18px 9px;
  border-radius: 20px;
  background: #f7f8f7;
}
.TimelineMessage .msg .aside {
  padding: 7px 0 9px;
}
.TimelineMessage .hover .options {
  display: none;
}
.TimelineMessage .hover:hover .info {
  display: none;
}
.TimelineMessage .hover:hover .options {
  display: initial;
}
.TimelineMessage .info {
  text-align: right;
}
.TimelineMessage .options a {
  margin-left: 1rem;
}

.TimelineMessageMark {
  font-weight: bold;
  font-size: 0.8rem;
  background: #1da839;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./frontend/Timeline/TimelineMessage.scss"],"names":[],"mappings":"AAEE;EACE,YAAA;AADJ;AAGE;EACE,kBAAA;AADJ;AAEI;EACE,qBAAA;EACA,mBAAA;EACA,mBAAA;AAAN;AAEI;EACE,kBAAA;AAAN;AAIK;EACE,aAAA;AAFP;AAMI;EACE,aAAA;AAJN;AAMI;EACE,gBAAA;AAJN;AAOE;EACE,iBAAA;AALJ;AAQI;EACE,iBAAA;AANN;;AAWA;EACE,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AARF","sourcesContent":["\n.TimelineMessage {\n  .name {\n    opacity: .7;\n  }\n  .msg {\n    margin-top: .5rem;\n    .content {\n      padding: 7px 18px 9px;\n      border-radius: 20px;\n      background: #f7f8f7;\n    }\n    .aside {\n      padding: 7px 0 9px;\n    }\n  }\n  .hover {\n     .options {\n       display: none;\n     }\n   }\n  .hover:hover  {\n    .info {\n      display: none;\n    }\n    .options {\n      display: initial;\n    }\n  }\n  .info {\n    text-align: right;\n  }\n  .options {\n    a {\n      margin-left: 1rem;\n    }\n  }\n}\n\n.TimelineMessageMark {\n  font-weight: bold;\n  font-size: .8rem;\n  background: #1da839;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
