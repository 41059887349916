import React, {ChangeEvent, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import moment from 'moment';
import FA from 'react-fontawesome';
import './StandLayout.scss';
import {user} from '../stores/user';
import {uploadStore} from '../Upload/UploadStore';
import {orderStandStore} from './OrderStandStore';
import {getS3FileNameWithoutHash} from '../common/getS3FileNameWithoutHash';
import {t} from '../translations';
import {pickLanguage} from '../stores/utility';
import {projectStore} from '../stores/ProjectStore';

export default observer(function StandLayout()
{
    const uploadRef = useRef<HTMLInputElement>();

    const handleUpload = (e: ChangeEvent<HTMLInputElement>) =>
    {
        const file = e.target.files[0];
        if (file)
        {
            uploadStore.upload(file, u =>
            {
                orderStandStore.addStandLayout(orderStandStore.selected.id, u.url);
            }, {dir: 'stand-layout'})
        }
    };

    if (user.info.profiles[0].id === 'exhibitorInstallator' || user.info.profiles[0].id === 'exhibitorFinance')
    {
        return null;
    }

    const stand = orderStandStore.selected;
    const project = projectStore.projects.find(p => p.id == stand.project);

    const standLayoutPdf = pickLanguage(project?.fair.settings?.standLayoutPdf);
    if (!standLayoutPdf)
    {
        return null;
    }

    return (
        <div className='StandLayout my-4'>
            <h5>{t.standCreation.standLayout.fillIn}</h5>
            <div className='d-flex my-3'>
                <a
                    href={standLayoutPdf}
                    target='_blank'
                    download
                    className='flex-fill text-center button-empty unstyle-link d-inline-block mr-3'
                >
                    <FA name='download' className='fad mr-3'/>
                    {t.standCreation.standLayout.download}
                </a>
                <button className='flex-fill text-center button-empty' onClick={() => uploadRef.current.click()}>
                    <FA name='upload' className='fad mr-3'/>
                    {t.standCreation.standLayout.upload}
                </button>
                <input ref={uploadRef} type='file' className='d-none' accept='application/pdf' onChange={handleUpload}/>
            </div>
            <StandLayoutList/>
        </div>
    )
});

export const StandLayoutList = observer(function StandLayoutList()
{
    const standLayouts = orderStandStore.selected?.standLayouts || [];
    if (!standLayouts.length)
    {
        return null;
    }
    user.locale;
    return (
        <>
            {(user.moderator ? standLayouts.slice().reverse() : [standLayouts[standLayouts.length - 1]]).map(l =>
                <div key={l.layout} className='uploaded'>
                    <span className='pr-3'>{moment(l.date).format('D MMM, YYYY, HH:mm')}</span>
                    <a href={l.layout} target='_blank' download onClick={() => !l.downloaded ? orderStandStore.downloadStandLayout(l.layout) : null}>
                        {getS3FileNameWithoutHash(l.layout)}
                    </a>
                </div>
            )}
        </>
    )
})
