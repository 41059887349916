import {action, makeObservable, observable} from 'mobx';
import {getMyCountryCode} from './landingProxy';
import {Country} from '../../graphql/api/Types';

class LandingStore
{
    @observable myCountryCode: Country;

    constructor()
    {
        makeObservable(this);
    }

    getMyCountryCode()
    {
        getMyCountryCode().then(action('myCountryCode', myCountryCode =>
        {
            this.myCountryCode = myCountryCode;
        }));
    }
}

export const landingStore = new LandingStore();
