import Swal from 'sweetalert2';

export default function checkIfFileIsReadable(file: File | Blob)
{
    if (!file.size)
    {
        const error = 'Can not read the file ' + ((file as File).name ? `"${(file as File).name}"` : '');
        Swal.fire({
            title: error,
            text: 'Note: drag-and-drop is only supported if the file is not inside a zip file',
            icon: 'error',
        });
        throw new Error(error);
    }
}
