import {action, makeAutoObservable} from 'mobx';
import {loadExhibitorProductNames} from '../ExhibitorProducts/productProxy';
import type {IExhibitorProductContext} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {debounce} from '../common/debounce';

class PageProductOptionsStore
{
    products: {[project: string]: ({id: string, articleCode: string} & Pick<IExhibitorProductContext, 'description'>)[]} = {};

    constructor()
    {
        makeAutoObservable(this);
    }

    load = debounce((project: string) =>
    {
        loadExhibitorProductNames(project).then(action(products =>
        {
            if (products)
            {
                this.products[project] = products;
            }
        }));
    }, 0);
}

export const pageProductOptionsStore = new PageProductOptionsStore();
