import {Link} from 'react-router-dom';
import Button from '../components/Button';
import styles from './InitialView.module.css';
import {t} from '../../translations';

export default function InitialView({title, description}: {title: string, description: string})
{
    return (
        <div className={styles.content}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            <div className={styles.buttons}>
                <div className={styles.buttonMargin}><Link to='#phone'><Button primary>{t.eventRegistration.registerUsingPhone}</Button></Link></div>
                <div className={styles.buttonMargin}><Link to='#email'><Button primary>{t.eventRegistration.registerUsingEmail}</Button></Link></div>
                <div className={styles.alreadyRegistered}>{t.eventRegistration.alreadyRegistered}</div>
                <div><Link to='#badge-code'><Button primary>{t.eventRegistration.modifyParticipation}</Button></Link></div>
            </div>
        </div>
    );
}
