import {call, gql} from '../client';
import {IPage} from './PageStore';
import {IPageContext, IPageRules} from '../../graphql/api/page/Page';
import {DataNode} from './FolderTree';
import {IPageViewStats} from '../../graphql/api/logView/IPageViewStats';
import {IPdfDownload} from './PageStats';
import {IPagesPage} from './PagesStore';
import {Country} from '../../graphql/api/Types';

export function loadPage({id, project, name, noErrorLog}: {id?: string, project?: string, name?: string, noErrorLog?: boolean})
{
    return call<{page: IPage}>(
        gql`query($id:ID,$project:ID,$name:String){page(id:$id,project:$project,name:$name){id,country,project,name,isTemplate,viewAccess,keywords,mainPage,partialEditAccess,editAccess,items{id,type,layout,props},importedItems{pageId,id,published,showOnWeb,showOnMobile,type,layout,props},catalog,updatedAt,updatedBy{id,firstName,lastName},linkedPagesEngagement,orderReports,reportGroup}}`,
        {id, project, name},
        {noErrorLog}
    )
    .then(({data}) => data?.page);
}

export function loadOwnPages()
{
    return call<{ownPages: IPage[]}>(
        gql`{ownPages{id,country,project,name,isTemplate,viewAccess,partialEditAccess,editAccess,keywords,mainPage,items{id,type,layout,props},catalog,updatedAt,updatedBy{id,firstName,lastName}}}`
    ).then(({data}) => data?.ownPages);
}

export function loadPages(project?: string)
{
    return call<{pages: IPagesPage[]}>(
        gql`query($project:ID){pages(project:$project){id,country,project,name,isTemplate,viewAccess,keywords,mainPage,partialEditAccess,editAccess,updatedAt,completion}}`,
        {project}
    ).then(({data}) => data?.pages);
}

export function loadVersions(id: string)
{
    return call<{pageVersions: IPage[]}>(
        gql`query($id:ID!){pageVersions(id:$id){id,country,project,name,isTemplate,viewAccess,keywords,mainPage,partialEditAccess,editAccess,items{id,type,layout,props},importedItems{pageId,id,published,showOnWeb,showOnMobile,type,layout,props},catalog,updatedAt,updatedBy{id,firstName,lastName},orderReports,reportGroup}}`,
        {id}
    ).then(({data}) => data?.pageVersions);
}

export function createPage(input: {country?: Country, project?: string})
{
    return call<{createPage: IPage}>(
        gql`mutation($country:String,$project:ID){createPage(country:$country,project:$project){id,country,project,name,isTemplate,viewAccess,keywords,partialEditAccess,editAccess,items{id,type,layout,props}}}`,
        input
    ).then(({data}) => data?.createPage);
}

export function updatePage(page: {id: string, addNewContactsToChats?: boolean} & Omit<IPageContext & IPageRules, 'project'>)
{
    return call<{updatePage: IPage}>(
        gql`mutation($input:UpdatePageInput!){updatePage(input:$input){id,country,project,name,isTemplate,viewAccess,keywords,mainPage,partialEditAccess,editAccess,items{id,type,layout,props},importedItems{pageId,id,published,showOnWeb,showOnMobile,type,layout,props},catalog,updatedAt,updatedBy{id,firstName,lastName},orderReports,reportGroup}}`,
        {input: page}
    ).then(({data}) => data?.updatePage);
}

export function deletePages(ids: string[])
{
    return call<{deletePages: IPage[]}>(gql`mutation($ids:[ID!]!){deletePages(ids:$ids){id}}`, {ids});
}

// export function newPageFromPage(id: string)
// {
//     return call<{newPageFromPage: IPage}>(
//         gql`mutation($id:ID!){newPageFromPage(id:$id){id,country,project,name,isTemplate,viewAccess,keywords,partialEditAccess,editAccess,items{id,type,layout,props},importedItems{pageId,id,published,showOnWeb,showOnMobile,type,layout,props},catalog,updatedAt,updatedBy{id,firstName,lastName}}}`,
//         {id}
//     ).then(({data}) => data?.newPageFromPage);
// }

export function requestPageVideoUpload(id: string)
{
    return call<{requestPageVideoUpload: boolean}>(gql`mutation($id:ID!){requestPageVideoUpload(id:$id)}`, {id})
    .then(({data}) => data?.requestPageVideoUpload);
}

export function loadPageTree(project: string)
{
    return call<{pageTree: {tree: DataNode[], viewAccess: string[]}}>(gql`query($project:ID!){pageTree(project:$project){tree,viewAccess}}`, {project})
    .then(({data}) => data?.pageTree);
}

export function checkHasPageTreeAccess(project: string)
{
    return call<{hasPageTreeAccess: boolean}>(gql`query($project:ID!){hasPageTreeAccess(project:$project)}`, {project})
    .then(({data}) => data?.hasPageTreeAccess);
}

export function updatePageTree(project: string, tree: DataNode[])
{
    return call<{updatePageTree: {tree: DataNode[], viewAccess: string[]}}>(
        gql`mutation($input:UpdatePageTreeInput!){updatePageTree(input:$input){tree,viewAccess}}`,
        {input: {project, tree}}
    );
}

export function updatePageTreeViewAccess(project: string, viewAccess: string[])
{
    return call<{updatePageTree: {tree: DataNode[], viewAccess: string[]}}>(
        gql`mutation($input:UpdatePageTreeViewAccessInput!){updatePageTreeViewAccess(input:$input){tree,viewAccess}}`,
        {input: {project, viewAccess}}
    );
}

export function createStandPages(project: string)
{
    return call<{createStandPages: {standCount: number, pageCount: number, standsMarked: number, standsNoMatchingTemplate: number}}>(
        gql`mutation($project:ID!){createStandPages(project:$project){standCount,pageCount,standsMarked,standsNoMatchingTemplate}}`,
        {project}
    );
}

export function pageTemplateChoices(project: string)
{
    return call<{pageTemplateChoices: {id: string, name: string}[]}>(
        gql`query($project:ID!){pageTemplateChoices(project:$project){id,name}}`,
        {project}
    ).then(({data}) => data?.pageTemplateChoices);
}

export function chosePageTemplate(project: string, templateId: string)
{
    return call<{choosePageTemplate: IPage[]}>(
        gql`mutation($project:ID!,$templateId:ID!){choosePageTemplate(project:$project,templateId:$templateId){id,country,project,name,isTemplate,viewAccess,keywords,partialEditAccess,editAccess,items{id,type,layout,props},importedItems{pageId,id,published,showOnWeb,showOnMobile,type,layout,props},catalog,updatedAt,updatedBy{id,firstName,lastName}}}`,
        {project, templateId}
    ).then(({data}) => data?.choosePageTemplate);
}

export function exhibitorProductsPage(productId: string, project: string)
{
    return call<{exhibitorProductsPage: string}>(
        gql`query($input:ExhibitorPageInput!){exhibitorProductsPage(input:$input)}`,
        {input: {productId, project}}
    ).then(({data}) => data?.exhibitorProductsPage);
}

export function getPageViewStats(id: string)
{
    return call<{pageViewStats: IPageViewStats}>(gql`query($id:ID!){pageViewStats(id:$id){totalViews,uniqueViews,viewsByProfile{profile,views}}}`, {id})
    .then(({data}) => data?.pageViewStats);
}

export function duplicatePages(ids: string[], project?: string, ignoreWarning?: boolean)
{
    return call<{duplicatePages: {tree?: {project: string, tree: DataNode[]}, pages: IPage[]}}>(
        gql`mutation($input:DuplicatePagesInput!){duplicatePages(input:$input){tree{project,tree},pages{id,country,project,name,isTemplate,viewAccess,keywords,mainPage,partialEditAccess,editAccess,items{id,type,layout,props},importedItems{pageId,id,published,showOnWeb,showOnMobile,type,layout,props},catalog,updatedAt,updatedBy{id,firstName,lastName},orderReports,reportGroup}}}`,
        {input: {ids, project, ignoreWarning}}
    );
}

export function pdfDownloadsCount(pageId: string)
{
    return call<{pagePdfDownloads: IPdfDownload[]}>(gql`query($pageId:ID!){pagePdfDownloads(pageId:$pageId){id,downloads,content}}`, {pageId})
    .then(({data}) => data?.pagePdfDownloads);
}
