import {IPackage} from '../Packages/PackageStore';
import {user} from '../stores/user';
import {companyHasAccessToPkg} from '../../graphql/api/package/packageAccess';
import {companyStore} from '../Company/CompanyStore';
import {orderStandStore} from './OrderStandStore';
import {projectStore} from '../stores/ProjectStore';

export function userHasAccessToPkg(pkg: IPackage)
{
    if (user.moderator)
    {
        return true;
    }
    return companyHasAccessToPkg({
        pkg,
        companyId: orderStandStore.selected?.companyId || user.info?.company?.[0],
        sector: companyStore.companies.find(c => c.id == orderStandStore.selected?.companyId || user.info?.company?.[0])?.exhibitor?.sector,
        country: projectStore.projects.find(p => p.id == pkg.project)?.fair.country,
    });
}
