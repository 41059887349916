import {observable, action, makeObservable} from 'mobx';
import {projectStore} from '../stores/ProjectStore';
import {ISearchResults} from '../../graphql/api/search/Search';
import {algoliaSearch} from './algoliaSearch';
import {registerGlobalEventHandler} from '../stores/globalEvents';
import {debounced} from '../common/debounce';

class SearchStore
{
    @observable items: ISearchResults = null;
    prevSearch: string;
    @observable loading = 0;
    @observable error: string = null;

    constructor()
    {
        makeObservable(this);
        registerGlobalEventHandler('logout', () =>
        {
            this.reset();
        });
    }

    @action
    reset()
    {
        this.items = null;
        this.prevSearch = null;
        this.error = null;
    }

    @debounced(100)
    @action
    search(search: string)
    {
        search = search.trim();

        if (!search || !projectStore.id)
        {
            this.items = null;
            return;
        }
        if (this.items && (
            (
                this.prevSearch == search
            )
            ||
            (
                search.includes(this.prevSearch) &&
                !this.items.pages?.length &&
                !this.items.products?.length &&
                !this.items.users?.length &&
                !this.items.companies?.length &&
                !this.items.stands?.length &&
                !this.items.invoices?.length
            )
        ))
        {
            return;
        }
        this.prevSearch = search;
        if (search.length == 1)
        {
            this.items = null;
            return;
        }
        ++this.loading;
        algoliaSearch(search).then(action(res =>
        {
            --this.loading;
            if (res)
            {
                this.error = null;
                this.items = res;
            }
            else
            {
                this.error = 'Failed to load results';
            }
        }), action(e =>
        {
            --this.loading;
            this.error = 'Failed to load results';
            console.error(e);
        }));
    }
}

export const searchStore = new SearchStore();
