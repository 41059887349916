// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StandLayout .uploaded {
  background-color: #f7f6f6;
  text-align: center;
  margin-bottom: 1rem;
  padding: 0.3rem 0;
}`, "",{"version":3,"sources":["webpack://./frontend/OrderStand/StandLayout.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;AAAJ","sourcesContent":[".StandLayout {\n  .uploaded {\n    background-color: #f7f6f6;\n    text-align: center;\n    margin-bottom: 1rem;\n    padding: .3rem 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
