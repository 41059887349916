import {call, gql} from '../client';
import {IExhibitorProduct} from './ProductStore';
import {ExhibitorProductsInput, ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {ITranslations} from '../../graphql/api/Types';
import {IMaybePath} from '../common/deepSet';

export interface INumberOfItems {
    normal: number
    packages: {
        one: number
        twoToFour: number
        fiveOrMore: number
    }
}

export type ILoadProductsInput = Omit<ExhibitorProductsInput, 'codes'>

export function loadProducts(project: string, cursor?: number)
{
    return call<{exhibitorProducts: {count: number, products: IExhibitorProduct[], numberOfItems: INumberOfItems}}>(
        gql`query($input:ExhibitorProductsInput!){exhibitorProducts(input:$input){numberOfItems{normal,packages{one,twoToFour,fiveOrMore}},count,products{id,project,exhibitors,exhibitor_name,image,description,video,orderable,productType,unitType,uvir,code,carrefour,price,category,info,display,typologyFR,package}}}`,
        {input: {project, cursor}},
        {noBatch: true}
    ).then(({data}) => data?.exhibitorProducts);
}

export function loadCatalogProducts(input: ILoadProductsInput)
{
    return call<{exhibitorProducts: {count: number, catalogProducts: ICatalogProduct[]}}>(
        gql`query($input:ExhibitorProductsInput!){exhibitorProducts(input:$input){count,catalogProducts{id,exhibitor,exhibitorName,description,images,uvir,info{storeId,infoRows{name,value,unit,color,bold},priceRows{name,value,unit,color,bold},extraRows{name,value,color}},labels{id,title,color},weeks{promoCode,week,folderType,sousVente},stores{storeId,starDiscount,weeks{promoCode,quantity,ordered,price{invoice,selling,discount,paid},catalog,message,messageImage,finSupplier,stars,minQuantity},comment,displayFR},display,limitedQuantity,limitedDates,package,comment,details,sousVenteSize,flow}}}`,
        {input})
    .then(({data}) => data?.exhibitorProducts);
}

export function loadCatalogProductsById(ids: string[])
{
    return call<{catalogProducts: ICatalogProduct[]}>(
        gql`query($ids:[ID!]!){catalogProducts(ids:$ids){id,project,exhibitor,exhibitorName,description,images,uvir,info{storeId,infoRows{name,value,unit,color,bold},priceRows{name,value,unit,color,bold},extraRows{name,value,color}},labels{id,title,color},weeks{promoCode,week,folderType,sousVente},stores{storeId,starDiscount,weeks{promoCode,quantity,ordered,price{invoice,selling,discount,paid},catalog,message,messageImage,finSupplier,stars,minQuantity},comment,displayFR},video,display,limitedQuantity,limitedDates,package,comment,details,sousVenteSize,flow}}`,
        {ids})
    .then(({data}) => data?.catalogProducts);
}

// export function loadProduct(id: string)
// {
//     return call<{exhibitorProduct: IExhibitorProduct}>(
//         gql`query($id:ID!){exhibitorProduct(id:$id){id,project,exhibitors,image,description,orderable,productType,unitType,uvir,code,carrefour,price,category,info,display,package}}`,
//         {id})
//     .then(({data}) => data?.exhibitorProduct);
// }

// export function loadProductsCount(project: string)
// {
//     return call<{exhibitorProductsCount: number}>(
//         gql`query($input:ExhibitorProductsCountInput!){exhibitorProductsCount(input:$input)}`,
//         {input: {project}})
//     .then(({data}) => data?.exhibitorProductsCount);
// }

export function callChangeProduct(id: string, changes: {key: IMaybePath, value: any}[])
{
    return call<{changeExhibitorProduct: IExhibitorProduct}>(
        gql`mutation ($input:ExhibitorProductChangesInput!){changeExhibitorProduct(input:$input){id,project,exhibitors,exhibitor_name,image,description,video,orderable,productType,unitType,uvir,code,carrefour,price,category,info,display,package}}`,
        {input: {id, changes}})
    .then(({data}) => data?.changeExhibitorProduct);
}

export function exhibitorProductUsages(id: string, promoCode?: string)
{
    return call<{exhibitorProductUsages: {cartStores: string[], orderStores: string[]}}>(
        gql`query($input:ExhibitorProductUsagesInput!){exhibitorProductUsages(input:$input){cartStores,orderStores}}`, {input: {id, promoCode}})
    .then(({data}) => data?.exhibitorProductUsages);
}

export function callDeleteProducts(ids: string[])
{
    return call<{deleteExhibitorProducts: string}>(gql`mutation($ids:[ID!]!){deleteExhibitorProducts(ids:$ids)}`, {ids});
}

export function callDeleteProductPromotion(id: string, promoCode: string)
{
    return call<{deleteExhibitorProductPromotion: {message: string, product: Pick<IExhibitorProduct, 'price'>}}>(gql`mutation($input:DeleteExhibitorProductPromotionInput!){deleteExhibitorProductPromotion(input:$input){message,product{price}}}`, {input: {id, promoCode}});
}

export function loadExhibitorProductsByIds(ids: string[])
{
    return call<{exhibitorProductsByIds: IExhibitorProduct[]}>(
        gql`query($ids:[String!]!){exhibitorProductsByIds(ids:$ids){id,project,exhibitors,exhibitor_name,image,description,video,orderable,productType,unitType,uvir,code,carrefour,price,category,info,display,package}}`, {ids})
    .then(({data}) => data?.exhibitorProductsByIds);
}

export interface PageCatalogProductsInput
{
    pageId: string
    exhibitors?: string[]
    ids?: string[]
    promoCodes?: string[]
    articleCodes?: string[]
    categories?: string[]
    storeTypes?: string[]
    limit?: number
    page: number
}

export function loadExhibitorProductsPageCatalog(input: PageCatalogProductsInput)
{
    return call<{exhibitorProductsPageCatalog: ICatalogProduct[]}>(
        gql`query($input:PageCatalogProductsInput!){exhibitorProductsPageCatalog(input:$input){id,exhibitor,exhibitorName,description,images,uvir,info{storeId,infoRows{name,value,unit,color,bold},priceRows{name,value,unit,color,bold},extraRows{name,value,color}},labels{id,title,color},weeks{promoCode,week,folderType,sousVente},stores{storeId,starDiscount,weeks{promoCode,quantity,ordered,price{invoice,selling,discount,paid},catalog,message,messageImage,finSupplier,stars,minQuantity},comment,displayFR},display,limitedQuantity,limitedDates,package,comment,details,sousVenteSize,flow}}`, {input})
    .then(({data}) => data?.exhibitorProductsPageCatalog);
}

export function loadExhibitorProductNames(project: string)
{
    return call<{exhibitorProductNames: {id: string, description: ITranslations, articleCode: string}[]}>(
        gql`query($project:ID!){exhibitorProductNames(project:$project){id,description,articleCode}}`, {project})
    .then(({data}) => data?.exhibitorProductNames);
}

export function loadExhibitorProductsPromoCodes(ids: string[])
{
    return call<{exhibitorProductsPromoCodes: {id: string, promoCodes: string[]}[]}>(
        gql`query($ids:[ID!]!){exhibitorProductsPromoCodes(ids:$ids){id,promoCodes}}`, {ids})
    .then(({data}) => data?.exhibitorProductsPromoCodes);
}
