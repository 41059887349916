import type {IPackageContext} from './Package';
import type {IExhibitorSector} from '../company/Company';
import type {Country} from '../Types';

export function companyHasAccessToPkg(o: OptionsExplicit & OptionsSector)
{
    return companyHasExplicitAccessToPkg(o) || companyHasSectorAccessToPkg(o);
}

interface OptionsSector
{
    pkg: IPackageContext
    sector: IExhibitorSector
    country: Country
}

function companyHasSectorAccessToPkg({pkg, sector, country}: OptionsSector)
{
    const sectors = sector ? [sector.primary, sector.secondary].filter(s => s) : [];
    if (pkg.type == 'fresh' && country == 'IT')
    {
        const primarySector = sector.primary.split('-');
        const secondarySector = sector.secondary?.split('-');
        if ((primarySector.includes('PFT') && secondarySector?.includes('PLS')) ||
            (primarySector.includes('PLS') && secondarySector?.includes('PFT')))
        {
            return pkg.sectorAccessGroups?.every(g => g.some(sp => sectors.includes(sp)));
        }
    }
    return (
        pkg.sectorAccess?.some(sp => sectors.includes(sp)) ||
        pkg.sectorAccessGroups?.every(g => g.some(sp => sectors.includes(sp)))
    );
}

interface OptionsExplicit
{
    pkg: IPackageContext
    companyId: string
}

function companyHasExplicitAccessToPkg({pkg, companyId}: OptionsExplicit)
{
    return pkg.companyAccess?.includes(companyId);
}
