// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimelineCommentInput .TimelineMark {
  padding-top: 5px;
}
.TimelineCommentInput .name {
  opacity: 0.7;
  margin-bottom: 0.5rem;
}
.TimelineCommentInput .input .content {
  position: relative;
}
.TimelineCommentInput input {
  padding: 9px 18px;
  border-radius: 20px;
  border-width: 2px;
}
.TimelineCommentInput .attach {
  position: absolute;
  top: 0;
  right: 0.5rem;
  font-size: 1.5rem;
  width: 2rem;
  cursor: pointer;
}
.TimelineCommentInput .submit {
  border-radius: 20px;
  opacity: 0;
  transition: opacity ease-in-out 300ms;
  color: #fff !important;
}
.TimelineCommentInput .submit.canSubmit {
  opacity: 1;
}
.TimelineCommentInput .submit:not(.canSubmit) {
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./frontend/Timeline/TimelineCommentInput.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;AADJ;AAGE;EACE,YAAA;EACA,qBAAA;AADJ;AAII;EACE,kBAAA;AAFN;AAKE;EACE,iBAAA;EACA,mBAAA;EACA,iBAAA;AAHJ;AAKE;EACE,kBAAA;EACA,MAAA;EACA,aAAA;EACA,iBAAA;EACA,WAAA;EACA,eAAA;AAHJ;AAKE;EACE,mBAAA;EACA,UAAA;EACA,qCAAA;EACA,sBAAA;AAHJ;AAII;EACE,UAAA;AAFN;AAII;EACE,oBAAA;AAFN","sourcesContent":["\n.TimelineCommentInput {\n  .TimelineMark {\n    padding-top: 5px;\n  }\n  .name {\n    opacity: .7;\n    margin-bottom: .5rem;\n  }\n  .input {\n    .content {\n      position: relative;\n    }\n  }\n  input {\n    padding: 9px 18px;\n    border-radius: 20px;\n    border-width: 2px;\n  }\n  .attach {\n    position: absolute;\n    top: 0;\n    right: .5rem;\n    font-size: 1.5rem;\n    width: 2rem;\n    cursor: pointer;\n  }\n  .submit {\n    border-radius: 20px;\n    opacity: 0;\n    transition: opacity ease-in-out 300ms;\n    color: #fff !important;\n    &.canSubmit {\n      opacity: 1;\n    }\n    &:not(.canSubmit) {\n      pointer-events: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
