import {memo, ReactNode} from 'react';
import {useId} from './useId';
import styles from './FormCheckbox.module.css';

export default memo(function FormCheckbox(
    {className, label, labelColor = true, checked, onChangeChecked}:
        {className?: string, label: ReactNode, labelColor?: boolean, checked: boolean, onChangeChecked: (value: boolean) => void}
)
{
    const id = useId();
    return (
        <div
            className={styles.field + (className ? ' ' + className : '')}
            onClick={() => onChangeChecked(!checked)}
        >
            <img className={styles.checkbox} src={checked ? 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/checkbox-checked.svg' : 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/checkbox-unchecked.svg'} alt=''/>
            <label htmlFor={id} className={labelColor && checked ? styles.labelChecked : null}>{label}</label>
        </div>
    );
});
