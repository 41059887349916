import {chatStore} from '../Chat/ChatStore';
import {user} from '../stores/user';
import {ICreateChatInput} from '../Chat/chatProxy';

export function openChatWithContacts(i: ICreateChatInput | string[])
{
    const input = Array.isArray(i) ? {participants: i} as ICreateChatInput : i;
    const chat = chatStore.chats.find(c =>
        !c.support &&
        (
            input.participantCompanies?.length ?
                c.companies && input.participantCompanies?.every(a => c.companies.some(b => a === b)) :
                c.usersInChat.some(p => p.id === user.id) &&
                input.participants?.length === c.usersInChat.length - 1 &&
                input.participants?.every(pid => c.usersInChat.some(p => p.id === pid))
        )
    );
    if (chat)
    {
        chatStore.openChat(chat.id);
    }
    else
    {
        chatStore.createChat(input);
    }
}
