import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {IPageViewsByProfile, IPageViewStats} from '../../graphql/api/logView/IPageViewStats';
import {getPageViewStats} from './pageProxy';
import {Loading} from '../common/Loading';
import HighchartsReact from '../Reports/HighchartsReactLazy';
import type {Options} from 'highcharts';
import {capitalize} from '../../lib/common';
import {findProfile} from '../data/profiles';
import {user} from '../stores/user';
import {getVideo, IVideo} from '../Upload/uploadVideoProxy';
import {pageStore} from './PageStore';
import {VideoBlockProps} from './VideoBlock';
import {pickLanguage} from '../stores/utility';
import {ErrorBoundary} from '../common/ErrorBoundary';
import { pdfDownloadsCount } from './pageProxy';
import { getS3FileNameWithoutHash } from '../common/getS3FileNameWithoutHash';
import {SlideshowBlockProps} from './SlideshowBlock';
import {t} from '../translations';

export default function PageStats({id}: {id: string})
{
    return (
        <ErrorBoundary>
            <PageViewStats id={id}/>
            <PageVideoStats id={id}/>
            <PagePdfDownloads id={id}/>
        </ErrorBoundary>
    );
}

export interface IPdfDownload
{
    id: string
    downloads: number
    content: SlideshowBlockProps['content']
}

const PageViewStats = observer(function PageViewStats({id}: {id: string})
{
    const [stats, setStats] = useState<IPageViewStats>();

    useEffect(() =>
    {
        getPageViewStats(id).then(setStats);
    }, [id]);

    if (!stats)
    {
        return (
            <div>
                <div style={{height: '440px', position: 'relative'}}>
                    <Loading/>
                </div>
            </div>
        );
    }

    return (
        <div>
            <p>
                <span className='mr-5'>{t.editPage.views} {stats.totalViews}</span>
                <span>{t.editPage.uniqueVisitors} {stats.uniqueViews}</span>
            </p>
            <HighchartsReact options={pieChartOptions(stats.viewsByProfile)} />
        </div>
    );
})

const pieChartOptions = (viewsByProfile: IPageViewsByProfile[]): Options =>
{
    return {
        chart: {
            type: 'pie',
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{point.name}: <b>{point.y}</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            type: 'pie',
            data: viewsByProfile.map(g => ({name: pickLanguage(findProfile(g.profile)?.name) || capitalize(g.profile), y: g.views})),
        }]
    };
};

const PageVideoStats = observer(function PageVideoStats({id}: {id: string})
{
    const [videos, setVideos] = useState<IVideo[]>();

    useEffect(() =>
    {
        const page = pageStore.getActivePage(id);
        const videoBlocks = page.items.filter(i => i.type == 'video').map(i => i.props as VideoBlockProps);
        const videoLinks = videoBlocks.map(v => pickLanguage(v.url)).filter(v => v);
        Promise.all(videoLinks.map(getVideo)).then(v => setVideos(v.filter(e => e)));
    }, [id, user.language]);

    if (!videos)
    {
        return null;
    }

    return (
        <>
            {videos.map(v =>
                <div key={v.id} className='d-flex align-items-center mb-3'>
                    <img src={v.pictures?.sizes[2].link} alt='' className='mr-3'/>
                    <div>{v.name} - <b>{v.stats?.plays} views</b></div>
                </div>
            )}
        </>
    );
})


const PagePdfDownloads = observer(function PagePdfDownloads({id}: {id: string})
{
    const [pdfDownloads, setPdfDownloads] = useState<IPdfDownload[]>(null);

    useEffect(() =>
    {
        pdfDownloadsCount(id).then(res =>
        {
            if (res)
            {
                setPdfDownloads(res);
            }
        });
    }, [id]);

    if (!pdfDownloads)
    {
        return null;
    }

    return (
        <div className='pdf-downloads'>
        {pdfDownloads.map(d =>
            {
                const content = pickLanguage(d.content);
                return (
                    <div className='d-flex' key={d.id}>
                        <img src={content.images[0]} alt=''/>
                        <div className='d-flex flex-column'>
                            <span>{getS3FileNameWithoutHash(content.pdf)}</span>
                            <b><span>{d.downloads + ' ' + (d.downloads == 1 ? 'download' : 'downloads')}</span></b>
                        </div>
                    </div>
                )
            })}
        </div>
    )
})
