import {projectStore} from '../stores/ProjectStore';
import {countryStore} from '../stores/CountryStore';
import {Country} from '../../graphql/api/Types';

export function projectCurrency(project: string)
{
    return countryCurrency(projectStore.projects.find(p => p.id === project)?.fair?.country);
}

export function countryCurrency(countryCode: Country)
{
    return countryStore.countries.find(c => c.code === countryCode)?.currency || 'EUR';
}
