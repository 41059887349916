import TextEditable from './TextEditable';
import './tag.scss';
import { observer } from 'mobx-react-lite';

interface Props
{
    className?: string
    tags: string[];
    onAdd?(): void;
    onRemove?(i: number): void;
    onTagTextChange?(text: string, i: number): void;
}

export default observer(function AddCustomTag({className, tags, onRemove, onAdd, onTagTextChange}: Props)
{
    return (
        <div className={'addTag ' + (className || '')}>
            {tags.map((m, i) =>
                <SingleTag key={i} name={m} onRemove={() => onRemove(i)} onChange={text => onTagTextChange(text, i)}/>
            )}
            <span className='plusButton' onClick={onAdd}/>
        </div>
    );
});

const SingleTag = ({name, onRemove, onChange}: {name: string, onRemove?(): void, onChange?(text: string): void}) =>
{
    return (
        <div className='tag'>
            <TextEditable onBlur={onChange} content={name}/>
            <span onClick={onRemove}>X</span>
        </div>
    );
};
