import {call, gql} from '../client';
import {IProduct} from './ProductStore';
import {Country} from '../../graphql/api/Types';

export function loadProducts(input: {country: Country})
{
    return call<{products: IProduct[]}>(
        gql`query($country:String!,$availableForProject:ID){products(country:$country,availableForProject:$availableForProject){id,update,archived,image,name,description,country,price,stock,packageAccess,supplier,category,electricity,dimensions,visuals,pallet}}`,
        input
    ).then(({data}) => data?.products);
}
export function loadProductsForProject(input: {country: Country, availableForProject?: string})
{
    return call<{products: IProduct[]}>(
        gql`query($country:String!,$availableForProject:ID){products(country:$country,availableForProject:$availableForProject){id,update,archived,image,name,description,country,price,stock,packageAccess,supplier,category,electricity,dimensions,visuals,pallet,available}}`,
        input
    ).then(({data}) => data?.products);
}
export function loadProduct(id: string)
{
    return call<{product: IProduct}>(
        gql`query($id:ID!){product(id:$id){id,update,archived,image,name,description,country,price,stock,packageAccess,supplier,category,electricity,dimensions,visuals,pallet}}`,
        {id}
    ).then(({data}) => data?.product);
}

export function callCreateProduct(product: IProduct)
{
    return call<{createProduct: IProduct}>(
        gql`mutation($input:ProductCreateInput!){createProduct(input:$input){id,update,archived,image,name,description,country,price,stock,packageAccess,supplier,category,electricity,dimensions,visuals,pallet}}`,
        {input: product}
    ).then(({data}) => data?.createProduct);
}

export function callUpdateProduct(product: IProduct)
{
    return call<{updateProduct: IProduct}>(
        gql`mutation($input:ProductUpdateInput!){updateProduct(input:$input){id,update,archived,image,name,description,country,price,stock,packageAccess,supplier,category,electricity,dimensions,visuals,pallet}}`,
        {input: product}
    );
}

export function callDuplicateProduct(id: string)
{
    return call<{duplicateProduct: IProduct}>(
        gql`mutation($id:ID!){duplicateProduct(id:$id){id,update,archived,image,name,description,country,price,stock,packageAccess,supplier,category,electricity,dimensions,visuals,pallet}}`,
        {id}
    ).then(({data}) => data?.duplicateProduct);
}

export function callDeleteProduct(id: string)
{
    return call<{deleteProduct: string}>(gql`mutation($id:ID!){deleteProduct(id:$id)}`, {id});
}

export function callCopyProductsToCountry(fromCountry: Country, toCountry: Country)
{
    return call<{copyProductsToCountry: boolean}>(
        gql`mutation($fromCountry:ID!,$toCountry:ID!){copyProductsToCountry(fromCountry:$fromCountry,toCountry:$toCountry)}`,
        {fromCountry, toCountry}
    ).then(({data}) => data?.copyProductsToCountry);
}
