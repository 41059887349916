import type {Language} from '../../graphql/api/Types';
import {user} from './user';

export function pickLanguage<T>(multiLang: {[L in Language]?: T}): T
{
    if (!multiLang)
    {
        return null;
    }
    if (multiLang[user.language])
    {
        return multiLang[user.language];
    }
    if (multiLang.en)
    {
        return multiLang.en;
    }
    const languages = Object.getOwnPropertyNames(multiLang);
    if (!languages.length)
    {
        return null;
    }
    for (const l of languages)
    {
        if (multiLang[l])
        {
            return multiLang[l];
        }
    }
}
