// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CKSqwlG5mHzGZNKl_ixA {
  color: #606060;
  background-color: #fefce0;
  border: 1px solid #eeede3;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.upyX1dXbuqIDut6h_E8o {
  color: #606060;
  background-color: #fee0e0;
  border: 1px solid #eee3e3;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/components/RemainingBadges.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,cAAc;AAChB","sourcesContent":[".remainingBadges {\n  color: #606060;\n  background-color: #fefce0;\n  border: 1px solid #eeede3;\n  border-radius: 5px;\n  padding: 10px;\n  margin: 10px 0;\n}\n\n.insufficientBadges {\n  color: #606060;\n  background-color: #fee0e0;\n  border: 1px solid #eee3e3;\n  border-radius: 5px;\n  padding: 10px;\n  margin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"remainingBadges": `CKSqwlG5mHzGZNKl_ixA`,
	"insufficientBadges": `upyX1dXbuqIDut6h_E8o`
};
export default ___CSS_LOADER_EXPORT___;
