import React, {useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import FA from 'react-fontawesome';
import './BlockOptions.scss';
import TagSelect from '../common/TagSelect';
import {profiles} from '../data/profiles';
import {user} from '../stores/user';
import {generateBlockId, pageStore} from './PageStore';
import {projectStore} from '../stores/ProjectStore';
import { t } from '../translations';
import { toJS } from 'mobx';
import {BlockAccessProps} from './blockMap';
import {uploadAndConvert} from './uploadAndConvert';
import {Upload} from '../Upload/UploadStore';
import {pickLanguage} from '../stores/utility';

export default observer(function BlockOptions(
    {pageId, itemId, children, restrictedOptions, enableBlockOptions, fullAccess}:
        React.PropsWithChildren<{pageId: string, itemId: string, restrictedOptions?: React.ReactNode} & Pick<BlockAccessProps, 'enableBlockOptions' | 'fullAccess'>>
)
{
    const [showOptions, setShowOptions] = useState<'settings' | 'placeholders'>();
    const showSettings = showOptions == 'settings';
    const showPlaceholders = showOptions == 'placeholders';

    const country = projectStore.selectedCountry;

    const page = pageStore.getActivePage(pageId);

    const item = page.items.find(i => i.id == itemId);

    const handleDuplicate = () =>
    {
        const duplicate = toJS(item);
        duplicate.id = generateBlockId(page);
        page.items.push(duplicate);
    }

    if (item)
    {
        return (
            <div className='BlockOptions' onMouseDown={stopPropagation} onClick={stopPropagation}>
                <div className='options'>
                    {children}
                    {fullAccess &&
                    <button className='button align-self-start justify-content-center' style={{marginTop: '50px'}} onClick={handleDuplicate}>
                        Duplicate
                    </button>}
                </div>
                {fullAccess && <>
                    <hr/>
                    <div className='access'>
                        <div className='setting-toggle mr-3' onClick={() => setShowOptions(showSettings ? null : 'settings')}>
                            <FA name={showSettings ? 'caret-up' : 'caret-down'}/>
                            {showSettings ? 'Hide block settings' : 'Show block settings'}
                        </div>
                        <div className='setting-toggle mr-3' onClick={() => setShowOptions(showPlaceholders ? null : 'placeholders')}>
                            <FA name={showPlaceholders ? 'caret-up' : 'caret-down'}/>
                            {showPlaceholders ? 'Hide block placeholders' : 'Show block placeholders'}
                        </div>
                    </div>
                    {showSettings &&
                    <div className='access-options'>
                        <div className='user-select-all cursor-pointer mb-3' title='Click to copy' onClick={() => document.execCommand('copy')}>
                            <span className='user-select-none pointer-events-none pr-3'>Block ID:</span>
                            <span>{pageId + '/' + itemId.trim()}</span>
                            <FA name='copy' className='far user-select-none pointer-events-none pl-2 text-larger float-right'/>
                        </div>
                        <div className='user-select-none mb-3'>
                            Show on:
                            <div className='d-inline-block ml-3'>
                                <input
                                    type='checkbox'
                                    id='show-on-web'
                                    checked={item.props.showOnWeb !== false}
                                    onChange={e => pageStore.changeItem(pageId, itemId, 'showOnWeb', e.currentTarget.checked)}
                                />
                                <label htmlFor='show-on-web' className='mb-0 ml-2'>web</label>
                            </div>
                            <div className='d-inline-block ml-3'>
                                <input
                                    type='checkbox'
                                    id='show-on-mobile'
                                    checked={item.props.showOnMobile !== false}
                                    onChange={e => pageStore.changeItem(pageId, itemId, 'showOnMobile', e.currentTarget.checked)}
                                />
                                <label htmlFor='show-on-mobile' className='mb-0 ml-2'>mobile</label>
                            </div>
                        </div>
                        <TagSelect
                            name='View access for this block'
                            className={restrictedOptions ? 'mb-3' : null}
                            boldName={false}
                            options={profiles.filter(p => page.viewAccess?.includes(p.id) && p.countries.includes(country))
                                .map(p => [p.id, pickLanguage(p.name)])}
                            selected={item.props?.viewAccess || []}
                            onChange={a => pageStore.changeItem(pageId, itemId, 'viewAccess', a)}
                            allSelector
                        />
                        {restrictedOptions}
                    </div>}
                    {showPlaceholders &&
                    <div className='access-options d-flex'>
                        <div className='flex-grow-1'>
                            <label htmlFor='placeholder-text'>Placeholder Text:</label>
                            <textarea
                                id='placeholder-text'
                                className='form-control mb-3'
                                value={item.props.placeholderText?.[user.language]}
                                onChange={e => pageStore.changeItem(pageId, itemId, ['placeholderText', user.language], e.target.value)}
                            />
                        </div>
                        <div className='d-flex flex-column ml-3'>
                            <UploadPlaceholderImage pageId={pageId} itemId={itemId}/>
                            {!!item.props.placeholderImage?.[user.language] &&
                            <button
                                className='button-empty mt-3'
                                onClick={() => pageStore.changeItem(pageId, itemId, ['placeholderImage', user.language], null)}
                            >
                                Remove placeholder image
                            </button>
                            }
                        </div>
                    </div>}
                </>}
                <div className='bottom-padding'/>
            </div>
        )
    }
    else
    {
        const importedItem = page.importedItems.find(i => i.id == itemId);
        if (importedItem)
        {
            if (!enableBlockOptions)
            {
                return null;
            }
            return (
                <div className='BlockOptions w-100 px-4 py-2' onMouseDown={stopPropagation} onClick={stopPropagation}>
                    <div className='access'>
                        <div>
                            Nested block
                            {fullAccess && <Link to={`/page/${importedItem.pageId}#edit`} className='d-block mt-2'>Edit this block</Link>}
                        </div>
                        {fullAccess &&
                        <div>
                            <div>
                                <input
                                    type='checkbox'
                                    id='show-nested-block'
                                    checked={!!importedItem.published}
                                    onChange={e => pageStore.changeImportedItem(pageId, itemId, 'published', e.currentTarget.checked)}
                                />
                                <label htmlFor='show-nested-block' className='mb-0 ml-2'>{t.editPage.published}</label>
                            </div>
                            <div className='user-select-none mb-3' title='Can be turned off for all pages from the master block'>
                                Show on:
                                <div className='pl-3'>
                                    <input
                                        type='checkbox'
                                        id='show-on-web'
                                        checked={importedItem.showOnWeb !== false}
                                        onChange={e => pageStore.changeImportedItem(pageId, itemId, 'showOnWeb', e.currentTarget.checked)}
                                    />
                                    <label htmlFor='show-on-web' className='mb-0 ml-2'>web</label>
                                </div>
                                <div className='pl-3'>
                                    <input
                                        type='checkbox'
                                        id='show-on-mobile'
                                        checked={importedItem.showOnMobile !== false}
                                        onChange={e => pageStore.changeImportedItem(pageId, itemId, 'showOnMobile', e.currentTarget.checked)}
                                    />
                                    <label htmlFor='show-on-mobile' className='mb-0 ml-2'>mobile</label>
                                </div>
                            </div>
                        </div>}
                    </div>
                    {user.moderator &&
                    <div className='access'>
                        <div/>
                        <button
                            className='button'
                            onClick={() => pageStore.changePage(pageId, 'importedItems', page.importedItems.filter(i => i.id != itemId))}
                        >
                            Remove
                        </button>
                    </div>}
                    <div className='bottom-padding'/>
                </div>
            )
        }
    }

    return null;
});

const stopPropagation = e => e.stopPropagation();

function UploadPlaceholderImage({pageId, itemId}: {pageId: string, itemId: string})
{
    const imageRef = useRef<HTMLInputElement>();
    const [upload, setUpload] = useState<Upload>();

    const handleImage = (source: File) =>
    {
        let name = source.name;
        if (!name.endsWith('.jpg'))
        {
            name = name.split('.')[0] + '.jpg';
        }
        uploadAndConvert({source, name, dir: 'block/content-placeholders', onUploadStart: setUpload, onResizeEnd: resizedRes =>
            {
                setUpload(null);
                pageStore.changeItem(pageId, itemId, ['placeholderImage', user.language], resizedRes);
            }
        });
    };

    return (
        <>
            <button className='button' onClick={() => imageRef.current.click()}>
                {upload ? upload.ended ? t.editPage.pleaseWait : upload.progress * 100 + '%' : 'Upload placeholder image'}
            </button>
            <input ref={imageRef} className='d-none' type='file' accept='image/*' onChange={e => e.target.files.length && handleImage(e.target.files[0])}/>
        </>
    );
}
