import React from 'react';
import {WindowList} from './WindowList';
import {chatStore} from './ChatStore';

interface Props
{
    windows: React.ReactElement[]
}

export default function WindowListOverlay({windows}: Props)
{
    const [hiddenStartIndex, setHiddenStartIndex] = React.useState(-1);
    const [showHidden, setShowHidden] = React.useState(false);
    const hiddenCount = hiddenStartIndex != -1 ? windows.length - hiddenStartIndex : 0;
    return (
        <div className='ChatOverlay'>
            <WindowList
                windows={windows}
                onHiddenStartChange={setHiddenStartIndex}
            >
                {hiddenCount > 0 && (
                    <div className='ChatCount cursor-pointer mr-2' onClick={() => setShowHidden(!showHidden)}>
                        {windows.length - hiddenStartIndex}
                        {showHidden && (
                            <div className='ChatReveal' onMouseLeave={() => setShowHidden(false)}>
                                {windows.slice(hiddenStartIndex).map((w, i) => (
                                    <p key={w.props.id} data-id={w.props.id} onClick={handleMoveChatToFront}>{w.props.title || i}</p>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </WindowList>
        </div>
    );
}

function handleMoveChatToFront(e)
{
    const id = e.target.dataset.id;
    chatStore.closeChat(id);
    chatStore.openChat(id);
}
