import {action} from 'mobx';
import {validateOrder} from './cartProxy';
import {projectStore} from '../stores/ProjectStore';
import {mainStateReload} from '../Navbar/NavBarStore';
import {pickLanguage} from '../stores/utility';

type Listener = (project: string, storeId: string) => void

class CartValidateStore
{
    private listeners = new Set<Listener>();

    // other stores should reset or reload their state when a cart is validated
    // (listeners are called inside of a mobx action)
    addListener(listener: Listener)
    {
        if (typeof listener != 'function')
        {
            throw new TypeError('Listener must be a function');
        }
        this.listeners.add(listener);
    }

    // removeListener(listener: Listener)
    // {
    //     this.listeners.delete(listener);
    // }

    validate(project: string, storeId: string)
    {
        return validateOrder(project, storeId).then(action('validate', success =>
        {
            if (success)
            {
                for (const listener of this.listeners)
                {
                    try
                    {
                        listener(project, storeId);
                    }
                    catch (e)
                    {
                        console.error(e);
                    }
                }
            }
            else
            {
                const proj = projectStore.projects.find(p => p.id == project);
                alert('Access Denied - ' + (proj ? pickLanguage(proj.name) : 'selected project'));
                mainStateReload();
            }
            return success;
        }));
    }
}

export const cartValidateStore = new CartValidateStore();
