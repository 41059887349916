import {call, gql} from '../client';
import {ICategory} from './CategoryStore';
import {ICategoryBase} from '../../graphql/api/category/Category';
import {Country} from '../../graphql/api/Types';

export function loadCategories(country: string)
{
    return call<{categories: ICategory[]}>(
        gql`query($country:String!){categories(country:$country){id,country,name,highlight{active,title,description,image,products},subcategories{id,name,highlight{active,title,description,image,products}}}}`,
        {country})
    .then(({data}) => data?.categories);
}

export function callCreateCategory(country: Country)
{
    return call<{createCategory: Pick<ICategory, 'id' | 'name' | 'country'>}>(
        gql`mutation($country:String!){createCategory(country:$country){id,name,country}}`, {country})
    .then(({data}) => data?.createCategory);
}

export function callUpdateCategory(input: ICategoryBase)
{
    return call<{updateCategory: Pick<ICategory, 'id'>}>(gql`mutation ($input:CategoryUpdateInput!){updateCategory(input:$input){id}}`, {input});
}

export function callCreateSubCategory(categoryId: string)
{
    return call<{createSubCategory: Pick<ICategoryBase, 'id' | 'name'>}>(gql`mutation ($categoryId:ID!){createSubCategory(categoryId:$categoryId){id,name}}`, {categoryId})
    .then(({data}) => data?.createSubCategory);
}

export function callUpdateSubCategory(input: ICategoryBase & {categoryId: string})
{
    return call<{updateSubCategory: Pick<ICategoryBase, 'id'>}>(gql`mutation ($input:SubCategoryUpdateInput!){updateSubCategory(input:$input){id}}`, {input});
}

export function callDeleteCategory(id: string)
{
    return call<{deleteCategory: string}>(gql`mutation ($id:ID!){deleteCategory(id:$id)}`, {id})
    .then(({data}) => data?.deleteCategory);
}

export function callDeleteSubCategory(categoryId: string, id: string)
{
    return call<{deleteSubCategory: string}>(gql`mutation ($input:SubCategoryDeleteInput!){deleteSubCategory(input:$input)}`, {input: {categoryId, id}})
    .then(({data}) => data?.deleteSubCategory);
}
