import {MouseEventHandler, PropsWithChildren} from 'react';
import {t} from '../translations';
import ActionButton, {ActionButtonProps} from '../common/ActionButton';

export function ConfirmLarge({onClick}: {onClick: MouseEventHandler<HTMLButtonElement>})
{
    return <button className='ConfirmLarge button' onClick={onClick}>{t.global.confirm}</button>;
}

export function RetryLarge({onClick}: {onClick: MouseEventHandler<HTMLButtonElement>})
{
    return <button className='ConfirmLarge button' onClick={onClick}>{t.authentication.retry}</button>;
}

export function Confirm({onClick}: {onClick: MouseEventHandler<HTMLButtonElement>})
{
    return <button className='Confirm button' onClick={onClick}>{t.global.confirm}</button>;
}

export function ReSend({action, children}: PropsWithChildren<Pick<ActionButtonProps, 'action'>>)
{
    return <ActionButton className='ReSend button' action={action} actionText={t.authentication.sending}>{t.authentication.resend}{children}</ActionButton>;
}
