import {ItemPriceInfo} from '../cart/getItemPrice';
import {IOrderTotal} from './Order';

export function orderPriceTotals(priceInfo: ItemPriceInfo[])
{
    const total: ItemPriceInfo = {
        invoice: 0,
        selling: 0,
        discount: 0,
        paid: 0,
    };
    for (const p of priceInfo)
    {
        total.invoice += p?.invoice;
        total.selling += p?.selling;
        total.discount += p?.discount;
        total.paid += p?.paid;
    }
    return total;
}
export function orderTotalsSum(priceInfo: IOrderTotal[])
{
    const total: IOrderTotal = {
        invoice: 0,
        selling: 0,
        discount: 0,
        total: 0,
    };
    for (const p of priceInfo)
    {
        total.invoice += p?.invoice;
        total.selling += p?.selling;
        total.discount += p?.discount;
        total.total += p?.total;
    }
    return total;
}
