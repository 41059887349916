import React, {useEffect} from 'react';
import {useLocation} from 'react-router';
import {Link, Navigate} from 'react-router-dom';
import FA from 'react-fontawesome';
import {observer} from 'mobx-react-lite';
import './OrderStand.scss';
import SplitView from '../Dashboard/SplitView';
import {OrderStandType, OrderStandTypeExisting} from './OrderStandType';
import {OrderStandSetup} from './OrderStandSetup';
import OrderStandCheckout from './OrderStandCheckout';
import {orderStandStore} from './OrderStandStore';
import OrderCatalog from './OrderCatalog';
import PackageList from '../Packages/PackageList';
import {packageStore} from '../Packages/PackageStore';
import {orderStandProductStore} from './OrderStandProductStore';
import {categoryStore} from '../Categories/CategoryStore';
import {user} from '../stores/user';
import {t} from '../translations';
import { companyStore } from '../Company/CompanyStore';
import { projectStore } from '../stores/ProjectStore';
import {StandType} from '../../graphql/api/stand/Stand';
import {userHasAccessToPkg} from './packageAccess';
import {useHashId, useNewStandCompanyId} from './useHash';
import {tryGet} from '../stores/userPersist';
import {invoiceStore} from '../Invoice/InvoiceStore';

const has = (type: StandType) => orderStandStore.ownSubmittedStands.some(s => s.setup.type == type);

function hasMultipleOptions()
{
    const blockNewStandOrders = !user.moderator && projectStore.selected?.fair.settings?.blockNewStandOrders;
    return (
        +(
            has('normal') ||
            !blockNewStandOrders?.normal
        )
        +
        +(
            has('fresh') ||
            (
                user.hasFreshSector &&
                !blockNewStandOrders?.fresh
            )
        )
        +
        +(
            has('concept') ||
            (
                !blockNewStandOrders?.concept &&
                conceptPackagesAreAvailable()
            )
        )
    ) > 1;
}

function conceptPackagesAreAvailable()
{
    return packageStore.projectPackages.some(pkg => pkg.type === 'concept' && userHasAccessToPkg(pkg));
}

export default observer(function OrderStandPage()
{
    useEffect(() =>
    {
        projectStore.withId(pid =>
        {
            setTimeout(() => orderStandStore.reloadStands(pid), 5);
            setTimeout(() => packageStore.reload(pid), 10);
            setTimeout(() => invoiceStore.reload(pid), 1000);
        });
    }, [projectStore.id]);
    useEffect(() =>
    {
        if (projectStore.selectedCountry)
        {
            setTimeout(() =>
            {
                projectStore.withLoaded(() =>
                {
                    orderStandProductStore.load(projectStore.selectedCountry, projectStore.id);
                    categoryStore.reloadCategories(projectStore.selectedCountry);
                });
            }, 10);
        }
    }, [projectStore.id, projectStore.selectedCountry]);

    const newStandCompanyId = useNewStandCompanyId();
    const id = useHashId();

    useEffect(() =>
    {
        if (id)
        {
            orderStandStore.loadStand(id).then(s =>
            {
                if (s && s.project !== projectStore.id)
                {
                    projectStore.select(s.project);
                }
            });
        }
    }, [id]);

    useEffect(() =>
    {
        if (id)
        {
            orderStandStore.select(id);
        }
        else if (projectStore.selected)
        {
            orderStandStore.select(null);
            if (newStandCompanyId)
            {
                if (user.moderator && projectStore.isDigitalFair)
                {
                    // auto select/create stand on digital fair, because this is the only option there
                    orderStandStore.createStand('concept', newStandCompanyId);
                }
            }
            else if (!orderStandStore.selected && orderStandStore.initialLoad[projectStore.id])
            {
                if (projectStore.isDigitalFair)
                {
                    // auto select/create stand on digital fair, because this is the only option there
                    orderStandStore.selectOwnStand('concept', true);
                }
                else
                {
                    const blockNewStandOrders = !user.moderator && projectStore.selected.fair.settings?.blockNewStandOrders;
                    // if there are multiple options then check what did the user select previously, otherwise select the option which is available to him
                    const hm = hasMultipleOptions();
                    let type: StandType;
                    if (hm)
                    {
                        const persistedType = tryGet('orderStand.type') as StandType;
                        if (persistedType && !blockNewStandOrders?.[persistedType])
                        {
                            type = persistedType;
                        }
                    }
                    if (!type)
                    {
                        if (has('normal'))
                        {
                            type = 'normal';
                        }
                        else if (has('fresh'))
                        {
                            type = 'fresh';
                        }
                        else if (has('concept'))
                        {
                            type = 'concept';
                        }
                        else if (!blockNewStandOrders?.normal)
                        {
                            type = 'normal';
                        }
                        else if (!blockNewStandOrders?.fresh && user.hasFreshSector)
                        {
                            type = 'fresh';
                        }
                        else if (!blockNewStandOrders?.concept && conceptPackagesAreAvailable())
                        {
                            type = 'concept';
                        }
                    }
                    if (type)
                    {
                        orderStandStore.selectOwnStand(type, !hm);
                    }
                }
            }
        }
    }, [id, newStandCompanyId, !projectStore.selected, projectStore.id, !packageStore.projectPackages.length, orderStandStore.initialLoad[projectStore.id]]);

    if (companyStore.initialLoad && projectStore.initialLoad && !projectStore.ownProjects.length && !user.moderator)
    {
        return <Navigate to='/dashboard' />
    }
    // console.log(orderStandStore.selected?.setup.type);
    return (
        <SplitView
            left={<OrderStandLeft/>}
            right={<OrderStandCheckout/>}
        />
    )
});

const OrderStandLeft = observer(function OrderStandLeft()
{
    const id = useHashId();
    if (id && !projectStore.archived)
    {
        return (<>
            <h3 className='OrderStandTitle'>Edit stand</h3>
            {!!orderStandStore.selected && <OrderStandOptions/>}
        </>);
    }
    return (<>
        {!projectStore.archived && <div className='OrderStandTitle'>{t.standCreation.title}</div>}
        {!projectStore.isDigitalFair &&
        <div className='d-flex justify-content-between'>
            {user.moderator ?
                <OrderStandType/> :
                projectStore.archived || user.viewOnlyExhibitor ?
                    (+has('normal') + +has('fresh') + +has('concept')) > 1 && <OrderStandTypeExisting/> :
                    hasMultipleOptions() && <OrderStandType/>
            }
            <StandFloorMap/>
        </div>}
        {!!orderStandStore.selected && !user.archivedExhibitor && !user.viewOnlyExhibitor && <OrderStandOptions/>}
    </>);
});

const StandFloorMap = observer(function StandMap()
{
    const fair = projectStore.selected?.fair;
    if (!fair?.floorMap)
    {
        return null;
    }
    return <a className='StandFloorMap' href={fair.floorMap} target='_blank' style={{backgroundImage: `url(${fair.floorMapPreview})`}}/>
});

const OrderStandOptions = observer(function OrderStandOptions()
{
    const location = useLocation();
    const stand = orderStandStore.selected;
    const canEditPackage = user.moderator || !orderStandStore.isSubmittedApprovedInvoiced;
    const showPackages = canEditPackage && (location.search ? location.search !== '?products' : !stand.invoiced?.package);

    return (
        <div className='mt-5'>
            {canEditPackage &&
            <OrderStandSetup/>}
            {canEditPackage &&
            <div className='OrderStandOptionsTabs mt-5'>
                <Link replace to={location.pathname + '?package' + location.hash} className={showPackages ? 'active' : ''}>{t.global.navBar.packages}</Link>
                <Link replace to={location.pathname + '?products' + location.hash} className={showPackages ? '' : 'active'}>{t.standCreation.additionalMaterial}</Link>
            </div>}
            {showPackages ?
                <PackageList
                    className='my-5'
                    requireContainsSetup
                    setup={stand.setup}
                    selected={orderStandStore.selectedPackage}
                    onSelect={orderStandStore.selectPackage}
                /> :
                orderStandStore.selectedPackage ?
                    <OrderCatalog/> :
                    <div className='OrderStandOptionsPleaseSelect'>
                        <FA name='exclamation-circle' className='far'/>
                        {t.standCreation.addingAdditionalMaterial}
                    </div>
            }
        </div>
    )
});
