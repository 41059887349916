import React, {useState} from 'react';
import {t} from '../translations';
import {LoginChildProps} from './LoginBox';
import LoginLCTitle from './LCTitle';
import PhoneInput from '../Landing/PhoneInputLazy';
import {ConfirmLarge} from './Buttons';
import {handleEnter} from './utility';
import LoginMethodSwitchOptions from './LoginMethodSwitchOptions';
import type {GraphQLFormattedError} from 'graphql';
import {loginErrorMessages} from './EnterEmail';
import BlueBox from './BlueBox';
import useIsMounted from '../common/useIsMounted';

export default function EnterPhone(props: LoginChildProps)
{
    const {isEvent, defaultPhone, onLogin} = props;
    const [phone, setPhone] = useState(defaultPhone || '');
    const [errors, setErrors] = useState<GraphQLFormattedError[]>();
    const isMounted = useIsMounted();
    const confirm = () => onLogin({value: phone, method: 'Sms'}).then(({errors: nextErrors}) =>
    {
        if (nextErrors && isMounted.current)
        {
            setErrors(nextErrors);
        }
    });
    return (
        <BlueBox>
            <LoginLCTitle/>
            <div className='LoginInput'>
                <label className='EnterPhoneLabel'>{isEvent ? t.eventPage.registerForTheEvent : t.welcomePage.enterPhone}</label>
                <PhoneInput
                    className='mb-3'
                    numberInputProps={{className: 'form-control'}}
                    autoFocus
                    value={phone}
                    onChange={setPhone as any}
                    onKeyDown={handleEnter(confirm)}
                />
            </div>
            {!!errors && <p className='LoginError'>{loginErrorMessages(errors)}</p>}
            <div className='LoginInput'>
                <ConfirmLarge onClick={confirm}/>
                <LoginMethodSwitchOptions {...props}/>
            </div>
        </BlueBox>
    )
}
