import {call, gql} from '../client';

export function badgePdf(project: string, person: string, onlyFirstPage?: boolean)
{
    return call<{badgePdf: string}>(gql`query($input:BadgePdfInput!){badgePdf(input:$input)}`, {input: {project, person, onlyFirstPage}}, {noBatch: true});
}

export function badgePdfRes(project: string, person: string)
{
    return badgePdf(project, person).then(({data}) => data?.badgePdf);
}

export function badgePdfEventRegistration(project: string, person: string, registrationId?: string, authorization?: string)
{
    return call<{badgePdf: string}>(gql`query($input:BadgePdfInput!){badgePdf(input:$input)}`, {input: {project, person, registrationId}}, {authorization})
    .then(({data}) => data?.badgePdf);
}
