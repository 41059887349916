import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {contactsStore} from '../Contact/ContactsStore';
import {eventStore} from '../Event/EventStore';
import {projectStore} from '../stores/ProjectStore';
import {sameUTCDay} from '../../lib/common';
import {badgePdf} from './badgePdfProxy';
import {objectUrlForBase64} from '../common/blobUtility';
import {t} from '../translations';
import {user} from '../stores/user';

interface Props
{
    companyId: string
    fairParticipation?: boolean
    className?: string
}

export default observer(function DownloadCompanyBadges({companyId, fairParticipation, className}: Props)
{
    useEffect(() =>
    {
        contactsStore.loadCompanyMembers([companyId]);
    }, [companyId]);

    useEffect(() =>
    {
        eventStore.loadEvent(projectStore.id);
    }, [projectStore.id]);

    const [status, setStatus] = useState('download');

    if (user.restrictedFairParticipation)
    {
        return fairParticipation ? <div className='text-right mb-3 invisible'><div className='button-empty d-inline-block'>D</div></div> : null;
    }

    const users = contactsStore.currentUserAndContacts.filter(u => u.company?.includes(companyId))
        .filter(u => eventStore.projectEvent?.fairDates.some(d =>
        {
            const badge = u.badges?.find(p => p.project == projectStore.id);
            return badge?.days.some(uDate => sameUTCDay(d.date, uDate)) && badge?.status === 'Accepted';
        }));

    if (!users.length)
    {
        return fairParticipation ? <div className='text-right mb-3 invisible'><div className='button-empty d-inline-block'>D</div></div> : null;
    }

    function handleDownloadCompanyPdfBadges(e)
    {
        if (status == 'downloading')
        {
            return;
        }
        setStatus('downloading');
        e.preventDefault();
        const a = e.currentTarget;
        Promise.all(users.map(u =>
            badgePdf(projectStore.id, u.id).then(({errors, data}) =>
            {
                if (data?.badgePdf)
                {
                    a.href = objectUrlForBase64(data.badgePdf, 'application/pdf');
                    a.download = `${u.firstName}_${u.lastName}_Badge.pdf`;
                    a.click();
                }
                else if (errors)
                {
                    setStatus('failed');
                    throw new Error('Failed to download');
                }
            })
        ))
            .then(() => setStatus('download'), () => {})
            .then(() =>
            {
                a.href = '#';
                a.download = '';
            });
    }

    return fairParticipation ? (
        <div className='text-right mb-3'>
            {t.fairParticipation.downloadCMBadges}
            <a href='#' target='_blank' className='button-empty unstyle-link d-inline-block ml-3' onClick={handleDownloadCompanyPdfBadges}>
                {{
                    download: t.global.download,
                    downloading: t.global.downloading,
                    failed: t.global.somethingWentWrong,
                }[status]}
            </a>
        </div>
    ) : (
        <a href='#' target='_blank' className={'button unstyle-link d-inline-block ' + (className || '')} onClick={handleDownloadCompanyPdfBadges}>
            {{
                download: t.fairParticipation.downloadBadgePDF,
                downloading: t.global.downloading,
                failed: t.global.somethingWentWrong,
            }[status]}
        </a>
    );
});
