import {call, gql} from '../client';
import { IEvent } from './EventStore';
import { IUserRegistrationEvent } from './EventUserRegistrationStore';
import {IBadge} from '../../graphql/api/user/User';
import {EventRegistrationInput, EventRegistrationInput_v2} from '../../graphql/api/event/Event';

export interface EventUsersListItem
{
    id: string
    admin?: boolean
    organization?: boolean
    tester?: boolean
    firstName: string
    lastName: string
    function?: string
    email: string
    phone: string
    profile: string
    company?: {id: string, name: string, storeId?: string, storeType?: string, territory?: string}[]
    companyName?: string
    badges: IBadge[]
    showTerms?: boolean
}

export interface CompanyEventRegistrationExhibitorSelectItem
{
    id: string
    name: string
    hasAvailable: boolean
}
export interface CompanyEventRegistrationHostessSelectItem
{
    id: string
    name: string
}

export interface CompanyEventRegistrationAvailableBadges
{
    companyBadges: number
    assignedBadgesByUser: {
        id: string
        count: number
    }[]
}

export interface IRegisteredForEventOnDate
{
    date: Date
    byProfile: {[key: string]: number}
}

export function loadEvent(project: string)
{
    return call<{event: IEvent}>(
        gql`query($project:ID!){event(project:$project){id,project,image,title,description,confirmationPage,fairDates{date,name,capacity,capacities,profileAccess,regionStoreTypeAccess,contactAccess},sideEvents{name,description,checkbox,smsNotification,smsSent,profileAccess,contactAccess,reportsAccess,reference,capacity,date,group,invitationCode},eventRegistration,maps{floorMap,floorMapPreview,floorMapMobile},url,info,frNonFood,updateDate}}`,
        {project}
    ).then(({data}) => data?.event);
}

export function loadEventByUrl(url: string)
{
    return call<{eventByUrl: IEvent}>(
        gql`query($url:String!){eventByUrl(url:$url){id,project,image,title,description,confirmationPage,fairDates{date,name,capacity,capacities,profileAccess,regionStoreTypeAccess,contactAccess},sideEvents{name,description,checkbox,smsNotification,smsSent,profileAccess,contactAccess,reportsAccess,reference,capacity,date,group,invitationCode},eventRegistration,maps{floorMap,floorMapPreview,floorMapMobile},url,info,frNonFood,updateDate}}`,
        {url}
    ).then(({data}) => data?.eventByUrl);
}

export function loadEventStatuses(project: string)
{
    return call<{event: Pick<IEvent, 'dateStatuses' | 'sideEventStatuses'>}>(
        gql`query($project:ID!){event(project:$project){dateStatuses{date,full,fullByProfile},sideEventStatuses{reference,full}}}`,
        {project}
    ).then(({data}) => data?.event);
}

export function loadEvents()
{
    return call<{events: IEvent[]}>(
        gql`{events{id,project,image,title,description,confirmationPage,fairDates{date,name,capacity,capacities,profileAccess,regionStoreTypeAccess,contactAccess},sideEvents{name,description,checkbox,smsNotification,smsSent,profileAccess,contactAccess,reportsAccess,reference,capacity,date,group,invitationCode},eventRegistration,maps{floorMap,floorMapPreview,floorMapMobile},url,info,frNonFood,updateDate}}`
    ).then(({data}) => data?.events);
}

export function updateEvent(input: {updateDate?: Date | string, updateUsers?: boolean} & IEvent)
{
    return call<{updateEvent: IEvent}>(
        gql`mutation($input:UpdateEventInput!){updateEvent(input:$input){id,project,image,title,description,confirmationPage,fairDates{date,name,capacity,capacities,profileAccess,regionStoreTypeAccess,contactAccess},sideEvents{name,description,checkbox,smsNotification,smsSent,profileAccess,contactAccess,reportsAccess,reference,capacity,date,group,invitationCode},eventRegistration,maps{floorMap,floorMapPreview,floorMapMobile},url,info,updateDate}}`,
        {input}
    );
}

export function loadUserRegistrationEvent(project: string)
{
    return call<{event: IUserRegistrationEvent}>(
        gql`query($project:ID!){event(project:$project){id,project,country,frNonFood,eventOnly,image,title,description,confirmationPage,fairDates{date,name,profileAccess,regionStoreTypeAccess,contactAccess},sideEvents{name,description,checkbox,smsNotification,smsSent,profileAccess,contactAccess,reportsAccess,reference,capacity,date,group,invitationCode},url,info,eventProfiles}}`,
        {project}
    ).then(({data}) => data?.event);
}
export function loadUserRegistrationEventByUrl(url: string)
{
    return call<{eventByUrl: IUserRegistrationEvent}>(
        gql`query($url:String!){eventByUrl(url:$url){id,project,country,frNonFood,eventOnly,image,title,description,confirmationPage,fairDates{date,name,profileAccess,regionStoreTypeAccess,contactAccess},sideEvents{name,description,checkbox,smsNotification,smsSent,profileAccess,contactAccess,reportsAccess,reference,capacity,date,group,invitationCode},url,info,eventProfiles}}`,
        {url}
    ).then(({data}) => data?.eventByUrl);
}

export function loadUserRegistrationDateStatusesForUser(project: string, profile?: string, selectedUser?: string, registrationId?: string)
{
    return call<{event: IUserRegistrationEvent}>(
        gql`query($project:ID!,$input:EventDateStatusesInput!){event(project:$project){dateStatusesForUser(input:$input){date,full}}}`,
        {project, input: {profile, selectedUser, registrationId}}
    );
}

export function loadUserRegistrationSideEventStatusesForUser(project: string, selectedUser: string, registrationId: string)
{
    return call<{event: IUserRegistrationEvent}>(
        gql`query($project:ID!,$input:SideEventStatusesInput!){event(project:$project){sideEventStatusesForUser(input:$input){reference,full}}}`,
        {project, input: {selectedUser, registrationId}}
    );
}

export function loadUserRegistrationStatusesForUser(project: string, selectedUser: string, registrationId?: string, authorization?: string)
{
    return call<{event: IUserRegistrationEvent}>(
        gql`query($project:ID!,$selectedUser:ID!,$registrationId:ID){
            event(project:$project){
                dateStatusesForUser(input:{selectedUser:$selectedUser,registrationId:$registrationId}){date,full}
                sideEventStatusesForUser(input:{selectedUser:$selectedUser,registrationId:$registrationId}){reference,full}
            }
        }`,
        {project, selectedUser, registrationId},
        {authorization}
    );
}

export function loadInvitedGuests(project: string, invitedBy: string, registrationId: string, authorization: string)
{
    return call<{eventInvitedGuests: EventUsersListItem[]}>(
        gql`query($input:EventInvitedGuestsInput!){eventInvitedGuests(input:$input){id,firstName,lastName,badges,email,phone,profile,company,companyName,function,showTerms}}`,
        {input: {project, invitedBy, registrationId}},
        {authorization}
    ).then(({data}) => data?.eventInvitedGuests);
}

export function companyEventRegistrationExhibitorSelectList(project: string)
{
    return call<{companyEventRegistrationExhibitorSelectList: CompanyEventRegistrationExhibitorSelectItem[]}>(
        gql`query($project:ID!){companyEventRegistrationExhibitorSelectList(project:$project){id,name,hasAvailable}}`,
        {project}
    ).then(({data}) => data?.companyEventRegistrationExhibitorSelectList);
}

export function companyEventRegistrationAvailableBadges(project: string, companyId: string)
{
    return call<{companyEventRegistrationAvailableBadges: CompanyEventRegistrationAvailableBadges}>(
        gql`query($input:CompanyEventRegistrationAvailableBadgesInput!){companyEventRegistrationAvailableBadges(input:$input){companyBadges,assignedBadgesByUser{id,count}}}`,
        {input: {project, companyId}}
    ).then(({data}) => data?.companyEventRegistrationAvailableBadges);
}

export function loadEventUserAccounts(value: string, authorization: string)
{
    return call<{userRegistrationAccounts: EventUsersListItem[]}>(
        gql`query($value:String){userRegistrationAccounts(value:$value){id,admin,organization,firstName,lastName,badges,email,phone,profile,company,companyName,function,showTerms}}`,
        {value},
        {authorization}
    ).then(({data}) => data?.userRegistrationAccounts);
}

export function loadUserAccountRegID(project: string, registrationId: string)
{
    return call<{userRegistrationAccountRegistrationID: EventUsersListItem}>(
        gql`query($input:UserRegistrationAccountRegistrationIDInput!){userRegistrationAccountRegistrationID(input:$input){id,admin,organization,firstName,lastName,badges,email,phone,profile,company,companyName,function,showTerms}}`,
        {input: {project, registrationId}}
    ).then(({data}) => data?.userRegistrationAccountRegistrationID);
}

export function registerForTheEvent(input: EventRegistrationInput, authorization: string)
{
    return call<{registerForEvent: EventUsersListItem & {authenticationToken?: string}}>(
        gql`mutation($input:EventRegistrationInput!){registerForEvent(input:$input){id,admin,organization,tester,firstName,lastName,badges,email,phone,profile,company,companyName,function,showTerms,authenticationToken}}`,
        {input},
        {authorization}
    );
}

export function registerForEvent_v2(input: EventRegistrationInput_v2, authorization?: string)
{
    return call<{registerForEvent_v2: Pick<EventUsersListItem, 'id' | 'badges'>}>(
        gql`mutation($input:EventRegistrationInput_v2!){registerForEvent_v2(input:$input){id,badges}}`,
        {input},
        {authorization}
    );
}

export function companyEventRegistrationHostessSelectList(project: string)
{
    return call<{companyEventRegistrationHostessSelectList: CompanyEventRegistrationHostessSelectItem[]}>(
        gql`query($project:ID!){companyEventRegistrationHostessSelectList(project:$project){id,name}}`,
        {project}
    ).then(({data}) => data?.companyEventRegistrationHostessSelectList);
}

export function registeredForEventByDateByProfile(project: string)
{
    return call<{registeredForEventByDateByProfile: IRegisteredForEventOnDate[]}>(gql`query($project:ID!){registeredForEventByDateByProfile(project:$project){date,byProfile}}`, {project})
    .then(({data}) => data?.registeredForEventByDateByProfile);
}

export function checkInvitationCode(project: string, invitationCode: string)
{
    return call<{checkInvitationCode: string}>(
        gql`query($input:CheckInvitationCodeInput!){checkInvitationCode(input:$input)}`,
        {input: {project, invitationCode}}
    ).then(({data}) => data?.checkInvitationCode);
}
