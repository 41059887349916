import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {usePageScale} from './Page';

interface Input
{
    textTitle: string
    definedFontSize: number
}

// reduce font size if the text wont fit
export default function useReducedFontSize({textTitle, definedFontSize}: Input)
{
    const scale = usePageScale();
    definedFontSize *= scale;
    const [reducedFontSize, setReducedFontSize] = useState<number>();
    useEffect(() =>
    {
        // reset on content change
        setReducedFontSize(null);
    }, [textTitle, definedFontSize]);
    const textRef = useRef<any>();
    useLayoutEffect(() =>
    {
        const resetReducedSize = () =>
        {
            setReducedFontSize(v => v === null ? undefined : null);
            setTimeout(() =>
            {
                setReducedFontSize(v => v === null ? undefined : null);
            }, 1000);
        };
        window.addEventListener('resize', resetReducedSize);
        return () => window.removeEventListener('resize', resetReducedSize);
    }, []);
    useLayoutEffect(() =>
    {
        // has to be delayed so that it would be executed after content change
        setTimeout(() =>
        {
            const el: HTMLElement = textRef.current;
            if (el && (
                el.clientWidth < el.scrollWidth ||
                el.clientHeight < el.scrollHeight
            ))
            {
                const fSize = reducedFontSize ?? definedFontSize;
                // approximate the max font size
                setReducedFontSize(Math.floor(
                    fSize *
                    Math.min(
                        el.clientWidth / el.scrollWidth,
                        el.clientHeight / el.scrollHeight,
                    )
                ));
            }
        });
    });
    return {
        textRef,
        fontSize: reducedFontSize ?? definedFontSize
    };
}
