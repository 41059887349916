// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductOrdered {
  margin-top: 10px;
  border: 2px solid #e35e33;
  color: #e35e33;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  max-width: max-content;
}`, "",{"version":3,"sources":["webpack://./frontend/StoreCatalog/ProductOrdered.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,yBAAA;EACA,cAAA;EACA,iBAAA;EACA,oBAAA;EACA,sBAAA;AACF","sourcesContent":[".ProductOrdered {\n  margin-top: 10px;\n  border: 2px solid #e35e33;\n  color: #e35e33;\n  font-size: 1.1rem;\n  padding: .5rem 1rem;\n  max-width: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
