import React from 'react';
import {observer} from 'mobx-react-lite';
import { TimelineItem, TimelineRow } from './TimelineItem'
import FA from 'react-fontawesome';
import { formatTimelineDate } from '../common/timeFormatting';
import {ITimelineCheckedInOut} from './Timeline';
import {projectStore} from '../stores/ProjectStore';
import {pickLanguage} from '../stores/utility';

export default observer(function TimelineCheckedInOut({name, projectId, checkedIn, date}: ITimelineCheckedInOut)
{
    return (
        <TimelineItem
            withLine
            mark={checkedIn ? <FA name='check'/> : <FA name='check-double' className='fad'/>}
        >
            <TimelineRow
                className='top'
                content={
                    <>
                        <b>{name}</b>
                        {checkedIn ? ' checked in to ' : ' checked out from '}
                        {pickLanguage(projectStore.projects.find(p => p.id == projectId)?.name)}
                    </>
                }
                aside={formatTimelineDate(date)}
            />
        </TimelineItem>
    )
})
