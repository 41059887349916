import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import FA from 'react-fontawesome';
import Swal from 'sweetalert2';
import './CartByWorkshopPage.scss';
import {t} from '../translations';
import {user} from '../stores/user';
import {projectStore} from '../stores/ProjectStore';
import {debouncedLogView} from '../stores/logView';
import {cartByWorkshopStore} from './CartByWorkshopStore';
import {cartValidateStore} from './CartValidateStore';
import {Loading} from '../common/Loading';

export default observer(function CartByWorkshopPage()
{
    const projectId = projectStore.id;

    useEffect(() =>
    {
        if (projectId)
        {
            debouncedLogView({type: 'cart', project: projectId});
            cartByWorkshopStore.load(projectId);
        }
    }, [projectId]);

    const currency = projectStore.currency;

    const data = cartByWorkshopStore.data[projectId];

    return (
        <div className='CartByWorkshopPage'>
            {cartByWorkshopStore.loading && <Loading className='position-fixed'/>}
            <h1>{t.cart.rangeOfWorkshops}</h1>
            <a href='/cart' className='d-inline-block'>{t.cart.returnToYourCart}</a>
            {data?.map(d =>
                <div key={d.storeId}>
                    <h2 className='mt-4 mb-3'>
                        {d.storeId} {d.storeName}
                        <button
                            className='button float-right'
                            onClick={() =>
                            {
                                Swal.fire(
                                    {
                                        title: t.catalog.validateOrderCheck(d.storeId),
                                        icon: 'question',
                                        confirmButtonText: t.catalog.validate,
                                        cancelButtonText: t.global.cancel,
                                        showCancelButton: true,
                                    }).then((result) =>
                                {
                                    if (result.value)
                                    {
                                        cartValidateStore.validate(projectId, d.storeId).then(() =>
                                        {
                                            cartByWorkshopStore.load(projectId);
                                        });
                                    }
                                });
                            }}
                        >
                            {t.cart.validateOrder}
                        </button>
                    </h2>
                    <table className='table'>
                        <thead>
                        <tr>
                            <th colSpan={2}>{t.cart.workshop}</th>
                            <th className='text-right'>Total Px Cession</th>
                            <th className='text-right'>Total PVC</th>
                            <th className='text-center'>{t.cart.viewCart}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {d.workshopGroups.map(g =>
                            g.workshops.map((w, i) =>
                                <tr key={i}>
                                    {i === 0 && <td rowSpan={g.workshops.length}>{g.name}</td>}
                                    <td>{w.name}</td>
                                    <td className='text-right'>{user.formatCurrency(currency, w.invoice)}</td>
                                    <td className='text-right'>{user.formatCurrency(currency, w.selling)}</td>
                                    <td className='text-center'><Link to={`/cart?store=${d.storeId}&workshop=${w.id}`} className='viewCartButton'><FA name='file-invoice-dollar' className='fad'/></Link></td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
});
