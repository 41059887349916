// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vim2YCGyauZv2GYDOut_ {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  word-wrap: break-word;
}
.Vim2YCGyauZv2GYDOut_ > * {
  max-width: 100%;
  flex: 1;
}

.B9WbZF_Ihkd8wazeZ0PA {
  background: #ECECEC;
  padding: 20px;
}
.aRs9b8k4p7anKjLcU7w9 {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}
.Y_Z_7pF7BxoE6t3qoohN {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.DKg3bAR1NJTgBoNOtlT5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}
.U7bVf7Yg7WnTNB_bDaN2 {
  font-size: 18px;
  line-height: 22px;
  display: block;
  text-decoration: underline;
}

.CFKoRAcaffiAAzQZGFrV {
  padding: 10px;
}
.jubPthak2IXLjXKegNQw {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}
.w1bdJHcT73T7hOz03tNw {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.f3SeNlZrFsCwvptA6RBb {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
  margin-top: 20px;
}
.f3SeNlZrFsCwvptA6RBb button {
  max-width: 100%;
  width: 260px;
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/views/Confirmation.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,SAAS;EACT,mBAAmB;EACnB,qBAAqB;AACvB;AACA;EACE,eAAe;EACf,OAAO;AACT;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,aAAa;AACf;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,YAAY;AACd","sourcesContent":[".details {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  gap: 20px;\n  margin-bottom: 20px;\n  word-wrap: break-word;\n}\n.details > * {\n  max-width: 100%;\n  flex: 1;\n}\n\n.personal {\n  background: #ECECEC;\n  padding: 20px;\n}\n.registrationId {\n  font-size: 14px;\n  line-height: 17px;\n  margin-bottom: 10px;\n}\n.name {\n  font-size: 20px;\n  line-height: 24px;\n  font-weight: 600;\n  margin-bottom: 10px;\n}\n.personalText {\n  font-size: 18px;\n  line-height: 22px;\n  font-weight: 500;\n  margin-bottom: 10px;\n}\n.edit {\n  font-size: 18px;\n  line-height: 22px;\n  display: block;\n  text-decoration: underline;\n}\n\n.participation {\n  padding: 10px;\n}\n.participationLabel {\n  font-size: 18px;\n  line-height: 22px;\n  font-weight: 500;\n  margin-bottom: 10px;\n}\n.participationDate {\n  font-size: 20px;\n  line-height: 24px;\n  font-weight: 600;\n  margin-bottom: 10px;\n}\n\n.buttons {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n  gap: 20px;\n  margin-top: 20px;\n}\n.buttons button {\n  max-width: 100%;\n  width: 260px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"details": `Vim2YCGyauZv2GYDOut_`,
	"personal": `B9WbZF_Ihkd8wazeZ0PA`,
	"registrationId": `aRs9b8k4p7anKjLcU7w9`,
	"name": `Y_Z_7pF7BxoE6t3qoohN`,
	"personalText": `DKg3bAR1NJTgBoNOtlT5`,
	"edit": `U7bVf7Yg7WnTNB_bDaN2`,
	"participation": `CFKoRAcaffiAAzQZGFrV`,
	"participationLabel": `jubPthak2IXLjXKegNQw`,
	"participationDate": `w1bdJHcT73T7hOz03tNw`,
	"buttons": `f3SeNlZrFsCwvptA6RBb`
};
export default ___CSS_LOADER_EXPORT___;
