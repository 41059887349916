import {action, makeAutoObservable} from 'mobx';
import {loadProduct, loadProducts} from './productProxy';
import {IProductContext} from '../../graphql/api/product/Product';
import {projectStore} from '../stores/ProjectStore';
import {Country} from '../../graphql/api/Types';

export interface IProduct extends IProductContext
{
    id?: string
    update?: string
}

class ProductStore
{
    products: IProduct[] = [];
    productsByCountry: {[country in Country]?: IProduct[]} = {};
    loading = false;

    get countryProducts()
    {
        return this.productsByCountry[projectStore.selectedCountry] || [];
        // const country = projectStore.selectedCountry;
        // return this.products.filter(p => p.country == country);
    }

    constructor()
    {
        makeAutoObservable(this);
    }

    reloadProducts(country: Country)
    {
        this.loading = true;
        loadProducts({country}).then(action('reloadProducts', res =>
        {
            this.loading = false;
            if (res)
            {
                this.productsByCountry[country] = res;
                const combined = [];
                for (const p of Object.getOwnPropertyNames(this.productsByCountry))
                {
                    combined.push(...this.productsByCountry[p]);
                }
                this.products = combined;
            }
        }));
    }

    reloadProduct(id: string)
    {
        return loadProduct(id).then(action('reloadProduct', res =>
        {
            if (res)
            {
                const product = this.products.find(p => p.id == res.id);
                if (product)
                {
                    if (product.update != res.update)
                    {
                        Object.assign(product, res);
                    }
                }
                else
                {
                    this.products.push(res);
                }
            }
            return res;
        }));
    }
}

export const productStore = new ProductStore();
