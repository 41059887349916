import {action, makeObservable, observable, runInAction, toJS} from 'mobx';
import Swal from 'sweetalert2';
import { IEventContext} from '../../graphql/api/event/Event';
import {debounce, debounced} from '../common/debounce';
import {projectStore} from '../stores/ProjectStore';
import {
    IRegisteredForEventOnDate,
    loadEvent,
    loadEventByUrl,
    loadEvents,
    loadEventStatuses,
    registeredForEventByDateByProfile,
    updateEvent
} from './eventsProxy';
import {user} from '../stores/user';
import {deepSet, IMaybePath} from '../common/deepSet';
import {sameUTCDay} from '../../lib/common';

export interface IEvent extends IEventContext
{
    id?: string
    frNonFood?: boolean
    dateStatuses?: {
        date: string | Date
        full: boolean
        fullByProfile: {[profile: string]: boolean}
    }[]
    sideEventStatuses?: {
        reference: string
        full: boolean
    }[]
    updateDate?: Date | string
}

class EventStore
{
    @observable events: {[project: string]: IEvent} = {};
    @observable eventRegisteredCounts: {[project: string]: IRegisteredForEventOnDate[]} = {};
    @observable loaded: boolean;

    constructor()
    {
        makeObservable(this);
    }

    get canAccessFairParticipation()
    {
        return !this.loaded || this.canSeeFairParticipation;
    }
    get canSeeFairParticipation()
    {
        return (
            (user.moderator || !this.projectEvent?.frNonFood) &&
            !user.restrictedFairParticipation &&
            (user.moderator || this.projectEvent?.eventRegistration)
        );
    }

    get hasSideEventReports()
    {
        return this.projectEvent?.sideEvents.some(se => se.reportsAccess?.includes(user.id));
    }

    eventByUrl(url: string)
    {
        const matchUrl = new RegExp('^' + url.replace(/[\\\[\]()+?.*]/g, c => '\\' + c) + '$', 'i');
        for (const p of Object.getOwnPropertyNames(eventStore.events))
        {
            const e = eventStore.events[p];
            if (matchUrl.test(e.url))
            {
                return e;
            }
        }
    }

    loadEvents()
    {
        loadEvents().then(action('events', res =>
        {
            this.loaded = true;
            if (res)
            {
                for (const event of res)
                {
                    this.events[event.project] = event;
                }
            }
        }));
    }

    @debounced()
    loadEvent(project: string)
    {
        if (!project)
        {
            return;
        }
        loadEvent(project).then(action('event', res =>
        {
            if (res)
            {
                this.events[project] = res;
            }
        }));
    }

    loadEventByUrl(url: string)
    {
        return loadEventByUrl(url).then(action('event', res =>
        {
            if (res)
            {
                this.events[res.project] = res;
            }
            return res;
        }));
    }

    @debounced()
    loadStatuses(project: string)
    {
        loadEventStatuses(project).then(action(res =>
        {
            const event = this.events[project];
            if (event)
            {
                event.dateStatuses = res.dateStatuses;
                event.sideEventStatuses = res.sideEventStatuses;
            }
        }));
    }

    @debounced()
    loadEventRegisteredCounts(project: string)
    {
        if (!project)
        {
            return;
        }
        registeredForEventByDateByProfile(project).then(action(res =>
        {
            if (res)
            {
                this.eventRegisteredCounts[project] = res;
            }
        }));
    }

    private update()
    {
        const event = this.projectEvent;
        runInAction(() =>
        {
            event.fairDates.sort((a, b) => +new Date(a.date) - +new Date(b.date));
            event.sideEvents?.sort((a, b) => +new Date(a.date) - +new Date(b.date));
        });
        const input = toJS(event);
        delete input.frNonFood;
        delete input.dateStatuses;
        delete input.sideEventStatuses;
        if (input.sideEvents)
        {
            for (const se of input.sideEvents)
            {
                delete se.smsSent;
            }
        }
        updateEvent(input).then(res =>
        {
            if (res.errors)
            {
                const e = res.errors[0];
                if (e.message == 'This operation would update badges for some users')
                {
                    Swal.fire({
                        title: e.message,
                        html: (e.extensions?.list as {id: string, name: string}[])?.map(u => `<a href="/contact/${u.id}" class="d-block" target="_blank">${u.name}</a>`).join('') || '',
                        icon: 'warning',
                        showCancelButton: true,
                    }).then(result =>
                    {
                        if (result.value)
                        {
                            updateEvent({...input, updateUsers: true});
                        }
                        else
                        {
                            this.loadEvent(projectStore.id);
                        }
                    });
                }
                else
                {
                    this.loadEvent(projectStore.id);
                    Swal.fire({
                        title: e.message,
                        icon: 'error',
                    });
                }
            }
            else
            {
                event.updateDate = res.data.updateEvent.updateDate;
            }
        });
    }

    private debouncedUpdate = debounce(this.update, 500);

    get projectEvent()
    {
        return this.events[projectStore.id];
    }

    private get projectEventOrNew()
    {
        const pid = projectStore.id;
        if (!this.events[pid])
        {
            this.events[pid] = {
                project: pid,
                image: {},
                title: {},
                description: {},
                fairDates: [],
                sideEvents: [],
                maps: {}
            };
        }
        return this.events[pid];
    }

    @action
    change(field: IMaybePath, value: any)
    {
        deepSet(this.projectEventOrNew, field, value);
        this.debouncedUpdate();
    }

    @action.bound
    addFairDate()
    {
        const event = this.projectEventOrNew;
        if (!event.fairDates)
        {
            event.fairDates = [];
        }
        let date: Date;
        if (event.fairDates.length)
        {
            date = new Date(event.fairDates[event.fairDates.length - 1].date);
            date.setUTCDate(date.getUTCDate() + 1);
        }
        else
        {
            date = new Date();
            date.setUTCHours(12, 0, 0, 0);
        }
        event.fairDates.push({date});
        this.debouncedUpdate();
    }

    @action
    removeFairDate(index: number)
    {
        const date = eventStore.projectEvent.fairDates[index].date;
        const sideEventsCount = eventStore.projectEvent.sideEvents?.filter(s => sameUTCDay(s.date, date));
        if (sideEventsCount?.length && confirm(`Also delete ${sideEventsCount.length} side events?`))
        {
            eventStore.projectEvent.sideEvents = eventStore.projectEvent.sideEvents.filter(s => !sameUTCDay(s.date, date));
        }
        eventStore.projectEvent.fairDates.splice(index, 1);
        eventStore.debouncedUpdate();
    }

    @action.bound
    addSideEvent(date?: Date)
    {
        const event = this.projectEventOrNew;
        if (!event.sideEvents)
        {
            event.sideEvents = [];
        }
        if (date)
        {
            date = new Date(date);
            date.setUTCHours(12, 0, 0, 0);
        }
        else
        {
            date = new Date();
            date.setUTCHours(12, 0, 0, 0);
            while (event.fairDates.some(f => sameUTCDay(f.date, date)))
            {
                date.setUTCDate(date.getUTCDate() + 1);
            }
        }
        event.sideEvents.push({date, name: {}, description: {}});
        this.debouncedUpdate();
    }

    @action
    removeSideEvent(index: number)
    {
        eventStore.projectEvent.sideEvents.splice(index, 1);
        eventStore.debouncedUpdate();
    }

    @action
    removeRegionStoreTypeAccess(fairDateIndex: number, regionIndex: number)
    {
        eventStore.projectEvent.fairDates[fairDateIndex].regionStoreTypeAccess.splice(regionIndex, 1);
        eventStore.debouncedUpdate();
    }
}

export const eventStore = new EventStore();
