import React from 'react';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import {t} from '../translations';
import { Country } from '../../graphql/api/Types';
import { projectStore } from '../stores/ProjectStore';
import {user} from '../stores/user';

export default observer(function YourContact()
{
    if (!user.info?.profiles?.some(p => forProfiles.includes(p.id)))
    {
        return null;
    }

    const contact = contacts[projectStore.selectedCountry || user.info?.country[0]];

    if (!contact)
    {
        return null;
    }

    return (
        <div className='support-contact'>
            <h5 className='pb-2 mb-4 border-bottom border-dark'>
                <FA name='user-headset' className='fad mr-2'/>
                {t.global.yourContact}
            </h5>
            <div className='d-flex'>
                <div className='supportPicture'>
                    <img src={contact.image} alt=''/>
                </div>
                <div className='info'>
                    <div>{contact.name}</div>
                    <p>{contact.phone}</p>
                    <div>{contact.job}</div>
                    <p>{contact.company}</p>
                </div>
            </div>
        </div>
    );
});

const contacts: {[code in Country]: {name: string; phone: string; image: string; job?: string; company?: string}} = {
    BE: {
        name: 'Eléonore Havenith',
        phone: '+32 4 78 78 19 94',
        image: 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/contact/Eléonore Havenith.jpg'
    },
    PL: {
        name: 'Adriana Tyszkiewicz',
        phone: '+48 573 173 552',
        job: 'Project Manager',
        company: 'D-side Group',
        image: 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/contact/Adriana Tyszkiewicz.jpg'
    },
    IT: {
        name: 'Tatiana Di Marco',
        phone: '+39 392 625 0228',
        job: 'Local Project Manager',
        company: 'D-side Group',
        image: 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/contact/Tatiana Di Marco.jpg'
    },
    FR: {
        name: 'Stéphanie Gerard',
        phone: '+33 755609055',
        image: 'https://yourfair-pub.s3-eu-west-1.amazonaws.com/assets/contact/Stéphanie Gerard.jpg'
    },
    ES: {
        name: 'Eugenia Pagán',
        phone: '+34 627621610',
        image: 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/contact/Eugenia Pagán.jpg'
    },
}

const forProfiles = [
    'exhibitorMainAccount',
    'exhibitorKeyAccount',
    'exhibitorFinance',
    'exhibitorInstallator',
    'exhibitorRep',
];
