import {GraphQLClient} from './GraphQLClient';
import type {GraphQLFormattedError} from 'graphql';
import * as Sentry from '@sentry/browser';

export {gql} from './GraphQLClient';

export const client = new GraphQLClient({credentials: 'same-origin'});
export const call = client.call;

if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1')
{
    client.interceptResult = (res, {query, variables, options}, duration) =>
    {
        // report to sentry, but only if it was not an input error
        if (!options?.noErrorLog && res.errors && res.errors.some(e => !e.extensions?.input))
        {
            Sentry.captureMessage(
                res.errors.map(e =>
                    (e as GraphQLFormattedError & {stacktrace?: string[]}).stacktrace?.join('\n') || e.message
                ).join('\n') +
                `\nQuery: ${query}\nVariables: ${JSON.stringify(variables, null, 2)}`
                ,
                {
                    level: 'warning',
                    fingerprint: [query, ...res.errors.map(e => e.message)],
                    extra: {
                        duration,
                    },
                }
            );
        }
        return res;
    };
}
