// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CartByWorkshopPage {
  padding: 20px;
  max-width: min(1000px, 100%);
  min-width: max-content;
  margin: 0 auto;
}
.CartByWorkshopPage .viewCartButton {
  display: inline-block;
  border-radius: 50%;
  padding: 0.5rem;
  text-align: center;
  width: 2.7rem;
  margin: -0.5rem auto;
  cursor: pointer;
  color: inherit;
}
.CartByWorkshopPage .viewCartButton .fa {
  font-size: 1.5rem;
}
.CartByWorkshopPage .viewCartButton:hover {
  background-color: #ddd;
}`, "",{"version":3,"sources":["webpack://./frontend/StoreCatalog/CartByWorkshopPage.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,4BAAA;EACA,sBAAA;EACA,cAAA;AACF;AAAE;EACE,qBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,oBAAA;EACA,eAAA;EACA,cAAA;AAEJ;AADI;EACE,iBAAA;AAGN;AADI;EACE,sBAAA;AAGN","sourcesContent":[".CartByWorkshopPage {\n  padding: 20px;\n  max-width: min(1000px, 100%);\n  min-width: max-content;\n  margin: 0 auto;\n  .viewCartButton {\n    display: inline-block;\n    border-radius: 50%;\n    padding: .5rem;\n    text-align: center;\n    width: 2.7rem;\n    margin: -.5rem auto;\n    cursor: pointer;\n    color: inherit;\n    .fa {\n      font-size: 1.5rem;\n    }\n    &:hover {\n      background-color: #ddd;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
