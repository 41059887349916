import {makeAutoObservable} from 'mobx';
import {call, gql} from '../client';

function loadCarrefourBrandNames(id: string)
{
    return call<{carrefourBrands: CarrefourBrandItem[]}>(gql`query($id:ID!){carrefourBrands(id:$id){id,name}}`, {id})
    .then(({data}) => data?.carrefourBrands);
}

interface CarrefourBrandItem
{
    id: string
    name: string
}

class CarrefourBrandStore
{
    brands: {[companyId: string]: CarrefourBrandItem[]} = {};

    constructor()
    {
        makeAutoObservable(this);
    }

    load(id: string)
    {
        loadCarrefourBrandNames(id).then(brands =>
        {
            if (brands)
            {
                this.brands[id] = brands;
            }
        });
    }
}

export const carrefourBrandStore = new CarrefourBrandStore();
