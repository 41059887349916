import React, {useEffect, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import './ArticleBlock.scss';
import {pageStore} from './PageStore';
import BlockOptions from './BlockOptions';
import {editorChangeHandlerEx, tinyMCEConfig} from '../common/tinymceConfig';
import {Editor as EditorTinyMCE} from '@tinymce/tinymce-react';
import {user} from '../stores/user';
import {duplicate} from '../../lib/common';
import {ITranslations} from '../../graphql/api/Types';
import {convertToPageSize, usePageScale} from './Page';
import {CommonBlockProps} from './blockMap';
import EmptyBlock from './EmptyBlock';
import {pickLanguage} from '../stores/utility';
import {debounce} from '../common/debounce';
import {t} from '../translations';
import {Upload} from '../Upload/UploadStore';
import {UploadProgressBar} from './UploadProgressBar';

export interface ArticleBlockProps extends CommonBlockProps
{
    published?: boolean
    content?: ITranslations
}

export default observer(function ArticleBlock(
    {pageId, id, published, content, imported, layout, fullAccess, editAccess, enableBlockOptions, placeholderText, placeholderImage}: ArticleBlockProps
)
{
    const [showOptions, setShowOptions] = useState(false);
    const [showEditor, setShoeEditor] = useState(false);
    const [uploads, setUploads] = useState<Upload[]>(null);

    const handleContent = editorChangeHandlerEx(
        newUploads =>
        {
            setUploads(a => a ? [...a, ...newUploads] : newUploads);
        },
        doneUploads =>
        {
            setUploads(a => a.filter(u => !doneUploads.includes(u)));
        },
        (c: string) =>
        {
            if (!content)
            {
                content = {};
            }
            if (content[user.language] !== c)
            {
                content[user.language] = c;
                pageStore.changeItem(pageId, id, 'content', content);
            }
        },
        true
    );

    const changeItemHeight = useMemo(() => debounce(pageStore.changeItemHeight, 50), []);
    const textRef = useRef<HTMLDivElement>();
    const scale = usePageScale();
    useEffect(() =>
    {
        const height = textRef.current?.scrollHeight;
        if (height)
        {
            const heightInUnits = convertToPageSize(height) / scale;
            if (enableBlockOptions)
            {
                if (heightInUnits < 2)
                {
                    changeItemHeight(pageId, id, 2);
                }
            }
            else
            {
                changeItemHeight(pageId, id, heightInUnits);
            }
        }
    }, [enableBlockOptions, scale]);

    return (
        <div
            tabIndex={0}
            className={'ArticleBlock' + (!(enableBlockOptions && showEditor) && !pickLanguage(content) ? ' border' : '')}
            onDoubleClick={enableBlockOptions ? () =>
            {
                setShowOptions(true);
                setShoeEditor(true);
                pageStore.focusItem(pageId, id);
            } : null}
            onBlur={enableBlockOptions ? e =>
            {
                if (e.relatedTarget ?
                    !e.currentTarget.contains(e.relatedTarget as any) :
                    e.currentTarget == e.target || !e.currentTarget.contains(e.target as any)
                )
                {
                    setShowOptions(false);
                }
            } : null}
        >
            {!imported && enableBlockOptions && showEditor ? (
                <EditorTinyMCE
                    value={content?.[user.language] || ''}
                    {...(duplicate(tinyMCEConfig))}
                    onEditorChange={handleContent}
                    onBlur={() =>
                    {
                        setShoeEditor(false);
                    }}
                />
            ) : (
                <div ref={textRef} className='text clearfix' dangerouslySetInnerHTML={{__html: pickLanguage(content)}}/>
            )}
            {!!uploads?.length &&
            <UploadProgressBar
                text={uploads.every(u => u.ended) ? t.editPage.pleaseWait : null}
                progress={uploads.reduce((p, u) => p + u.progress, 0) / uploads.length}
            />}
            {!imported && editAccess && !showEditor && !pickLanguage(content) &&
            <EmptyBlock
                layout={layout}
                name={t.editPage.blockArticle}
                msg={showOptions ? 'Type something' : ' '}
                text={placeholderText}
                image={placeholderImage}
            />}
            {showOptions &&
            <BlockOptions pageId={pageId} itemId={id} enableBlockOptions={enableBlockOptions} fullAccess={fullAccess}>
                <div>
                    <p className='font-weight-bold mb-4'>{t.editPage.blockArticle}</p>
                </div>
                <div>
                    <div className='form-check form-check-inline mb-3'>
                        <input
                            id='options-published'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!published}
                            onChange={e => pageStore.changeItem(pageId, id, 'published', e.target.checked)}
                        />
                        <label htmlFor='options-published' className='form-check-label font-weight-bold'>{t.editPage.published}</label>
                    </div>
                    {fullAccess &&
                    <button
                        className='button mb-3'
                        onClick={() =>
                        {
                            if (confirm('Do you want to delete this block?'))
                            {
                                pageStore.deleteItem(pageId, id);
                            }
                        }}
                    >
                        {t.editPage.deleteContent}
                    </button>}
                </div>
            </BlockOptions>
            }
        </div>
    );
});
