// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SupportChatButton {
  position: fixed;
  bottom: 25px;
  right: 0;
  margin: 2rem;
  font-size: 24px;
  background-color: #16345F;
  color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 1.5rem;
  border: none;
  z-index: 1000;
}
.SupportChatButton .fa {
  margin-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./frontend/Chat/SupportChatButton.scss"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,YAAA;EACA,QAAA;EACA,YAAA;EACA,eAAA;EACA,yBAAA;EACA,WAAA;EACA,oBAAA;EACA,qBAAA;EACA,YAAA;EACA,aAAA;AAAF;AACE;EACE,kBAAA;AACJ","sourcesContent":["\n.SupportChatButton {\n  position: fixed;\n  bottom: 25px;\n  right: 0;\n  margin: 2rem;\n  font-size: 24px;\n  background-color: #16345F;\n  color: #fff;\n  padding: .5rem 2rem;\n  border-radius: 1.5rem;\n  border: none;\n  z-index: 1000;\n  .fa {\n    margin-right: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
