import {observable, action, makeObservable} from 'mobx';
import { callRegister, callAddCompanyMember } from '../stores/userProxy';
import {deepSet} from '../common/deepSet';
import { Country, Language } from '../../graphql/api/Types';
import { setCompany } from '../Company/CompanyProxy';
import { companyStore } from '../Company/CompanyStore';
import { user } from '../stores/user';
import {getUserRegisterErrorName} from '../Landing/Registration';
import { contactsStore } from '../Contact/ContactsStore';
import {call, gql} from '../client';
import Swal from 'sweetalert2';
import {swalModal} from '../common/sweetAlert';
import {usersListStore} from '../UsersPage/UsersListStore';
import {findProfile} from '../data/profiles';

export interface Person
{
    name: string;
    company: string;
    companyId: string;
    email: string;
    phone: string;
    country: Country;
    profile: string;
}

export interface CompanyMember extends Pick<Person, 'email' | 'phone' | 'profile'>
{
    firstName: string
    lastName: string
}

export function nonValidatedContacts()
{
    return call<{nonValidatedContacts: INonValidatedContact[]}>(gql`{nonValidatedContacts{id,name,company{id,name},country,language,date,profile}}`)
    .then(({data, errors}) =>
    {
        if (errors)
        {
            return null;
        }
        for (const contact of data.nonValidatedContacts)
        {
            contact.date = new Date(contact.date);
        }
        return data.nonValidatedContacts;
    });
}

export function callValidate(id: string)
{
    return call<{validateUser: string}>(gql`mutation($id:ID!){validateUser(id:$id)}`, {id})
    .then(({data}) => data?.validateUser);
}

export function callValidateAll()
{
    return call<{validateAll: string[]}>(gql`mutation{validateAll}`)
    .then(({data}) => data?.validateAll);
}

export function callReject(id: string)
{
    return call<{rejectUser: string}>(gql`mutation($id:ID!){rejectUser(id:$id)}`, {id})
    .then(({data}) => data?.rejectUser);
}

export function callUpdateOrganization(id: string, value: boolean)
{
    return call<{updateOrganization: boolean}>(gql`mutation($input:UpdateBooleanInput!){updateOrganization(input:$input)}`, {input: {id, value}})
    .then(({data}) => data?.updateOrganization);
}

export function callUpdateEventAdmin(id: string, value: boolean)
{
    return call<{updateEventAdmin: boolean}>(gql`mutation($input:UpdateBooleanInput!){updateEventAdmin(input:$input)}`, {input: {id, value}})
    .then(({data}) => data?.updateEventAdmin);
}

export function callUpdateSupport(id: string, value: boolean)
{
    return call<{updateSupport: boolean}>(gql`mutation($input:UpdateBooleanInput!){updateSupport(input:$input)}`, {input: {id, value}})
    .then(({data}) => data?.updateSupport);
}

export function callUpdateDeleteAccess(id: string, value: boolean)
{
    return call<{updateDeleteAccess: boolean}>(gql`mutation($input:UpdateBooleanInput!){updateDeleteAccess(input:$input)}`, {input: {id, value}})
    .then(({data}) => data?.updateDeleteAccess);
}

export function callUpdateTester(id: string, value: boolean)
{
    return call<{updateTester: boolean}>(gql`mutation($input:UpdateBooleanInput!){updateTester(input:$input)}`, {input: {id, value}})
    .then(({data}) => data?.updateTester);
}

export interface INonValidatedContact
{
    date: Date
    id: string
    company:
    {
        id?: string
        name: string
    }
    name: string
    language: Language
    country: Country[]
    profile: string
}

class OrganizationManagement
{
    @observable newCompany: {name?: string, country?: string} = null;
    @observable newPerson: Partial<Person> = null;
    @observable lastError: string;
    @observable nonValidatedContacts: INonValidatedContact[] = [];
    @observable loading = false;

    constructor()
    {
        makeObservable(this);
    }

    @action.bound
    addCompany()
    {
        this.newCompany = {};
        this.newPerson = null;
        this.lastError = null;
    }
    @action.bound
    addPerson()
    {
        this.newCompany = null;
        this.newPerson = {};
        this.lastError = null;
    }

    @action.bound
    cancel()
    {
        this.newCompany = null;
        this.newPerson = null;
        this.lastError = null;
    }

    @action
    updateCompany(key: string | string[], value: string)
    {
        deepSet(this.newCompany, key, value || '')
    }

    @action
    updatePerson(key: string | string[], value: string)
    {
        deepSet(this.newPerson, key, value)
    }

    @action
    load()
    {
        if (!user.moderator)
        {
            return;
        }
        this.loading = true;
        nonValidatedContacts().then(action('loadNonValidatedContacts', res =>
        {
            if (res)
            {
                this.nonValidatedContacts = res;
            }
            this.loading = false;
        }));
    }

    validate(id: string)
    {
        callValidate(id).then(action('Validate contact', res =>
        {
            if (res)
            {
                this.updateValidated(res);
            }
        }));
    }

    validateAll()
    {
        callValidateAll().then(action('Validate all', res =>
        {
            if (res)
            {
                for (const id of res)
                {
                    this.updateValidated(id);
                }
                alert(res.length ? `Accepted ${res.length} people` : 'There is no one pending');
            }
        }));
    }

    private removeNonValidated(id: string)
    {
        const index = this.nonValidatedContacts.findIndex(c => c.id === id);
        if (index > -1)
        {
            this.nonValidatedContacts.splice(index, 1);
        }
    }

    private updateValidated(id: string)
    {
        this.removeNonValidated(id);
        const markUserAsValidated = (contact: {validated?: boolean, rejected?: boolean}) =>
        {
            if (contact)
            {
                contact.validated = true;
                if (contact.rejected)
                {
                    contact.rejected = false;
                }
            }
        };
        markUserAsValidated(contactsStore.contacts.find(c => c.id === id));
        markUserAsValidated(usersListStore.users.find(c => c.id === id));
    }

    reject(id: string)
    {
        callReject(id).then(action('Validate contact', res =>
        {
            if (res)
            {
                this.removeNonValidated(id);
                const markUserAsRejected = (contact: {validated?: boolean, rejected?: boolean}) =>
                {
                    if (contact)
                    {
                        contact.rejected = true;
                        if (contact.validated)
                        {
                            contact.validated = false;
                        }
                    }
                };
                markUserAsRejected(contactsStore.contacts.find(c => c.id === id));
                markUserAsRejected(usersListStore.users.find(c => c.id === id));
            }
        }));
    }

    updateOrganization(id: string, organization: boolean)
    {
        callUpdateOrganization(id, organization).then(action(res =>
        {
            if (res)
            {
                const contact = contactsStore.contacts.find(c => c.id === id);
                if (contact)
                {
                    contact.organization = organization;
                }
            }
        }));
    }

    updateEventAdmin(id: string, eventAdmin: boolean)
    {
        callUpdateEventAdmin(id, eventAdmin).then(action(res =>
        {
            if (res)
            {
                const contact = contactsStore.contacts.find(c => c.id === id);
                if (contact)
                {
                    contact.eventAdmin = eventAdmin;
                }
            }
        }));
    }

    updateSupport(id: string, support: boolean)
    {
        callUpdateSupport(id, support).then(action(res =>
        {
            if (res)
            {
                const contact = contactsStore.contacts.find(c => c.id === id);
                if (contact)
                {
                    contact.support = support;
                }
            }
        }));
    }

    updateDeleteAccess(id: string, deleteAccess: boolean)
    {
        callUpdateDeleteAccess(id, deleteAccess).then(action(res =>
        {
            if (res)
            {
                const contact = contactsStore.contacts.find(c => c.id === id);
                if (contact)
                {
                    contact.deleteAccess = deleteAccess;
                }
            }
        }));
    }

    updateTester(id: string, tester: boolean)
    {
        callUpdateTester(id, tester).then(action(res =>
        {
            if (res)
            {
                const contact = contactsStore.contacts.find(c => c.id === id);
                if (contact)
                {
                    contact.tester = tester;
                }
            }
        }));
    }

    submitAddCompany()
    {
        this.lastError = null;
        if (!this.newCompany.name)
        {
            this.lastError = 'Please enter a company name';
        }
        if (!this.newCompany.country)
        {
            this.lastError = 'Please select a country';
        }
        if (this.lastError)
        {
            Swal.fire(swalModal('error', this.lastError));
            return;
        }
        setCompany({userId: null, name: this.newCompany.name, country: this.newCompany.country}).then(action('createCompany', res =>
        {
            if (res)
            {
                console.log('Created company:', res.id);
                companyStore.companies.unshift(res);
                this.newCompany = null;
            }
        }))
    }

    @action
    submitAddPerson()
    {
        this.lastError = this.validationCheck(this.newPerson, true);
        if (!this.newPerson.country)
        {
            this.lastError =  'Please select a country';
        }
        if (!this.newPerson.company)
        {
            this.lastError =  'Please select a company';
        }
        if (this.lastError)
        {
            Swal.fire(swalModal('error', this.lastError));
            return;
        }
        const n = this.newPerson.name ? this.newPerson.name.split(' ') : null;
        const firstName = n?.[0];
        const lastName = n?.length > 2 ? n?.slice(1).join(' ') : n?.[1] || '';
        callRegister({
            firstName,
            lastName,
            email: this.newPerson.email,
            phone: this.newPerson.phone,
            language: user.language,
            company: this.newPerson.company,
            country: this.newPerson.country,
            profile: this.newPerson.profile,
            profileType: this.newPerson.profile == 'headquarter' ? 'headquarter' :
                ['guest', 'media', 'maintenanceFournisseur'].includes(this.newPerson.profile) ? 'guest' :
                    findProfile(this.newPerson.profile).features?.mobile?.store?.length ? 'store' :
                        'exhibitor',
        }).then(action('addNewPerson', ({errors, data}) =>
        {
            if (errors)
            {
                this.lastError = getUserRegisterErrorName(errors);
                Swal.fire(swalModal('error', this.lastError));
            }
            else if (data.register)
            {
                console.log('Created person:', data.register.personId);
                this.newPerson = null;
                // if (!companyStore.companies.some(c => c.id == data.register.company.id))
                // {
                //     companyStore.companies.push(data.register.company);
                // }
            }
        }));
    }

    @action
    async addCompanyMember(p: CompanyMember)
    {
        const v = this.validationCheck(p);
        if (v)
        {
            return {code: 'error', message: v};
        }
        const {data, errors} = await callAddCompanyMember(p);
        if (data?.addCompanyMember)
        {
            contactsStore.contacts.push(data.addCompanyMember);
            return {code: 'success', message: 'User added successfully!'};
        }
        else
        {
           return {code: 'error', message: getUserRegisterErrorName(errors)};
        }
    }

    @action
    validationCheck(p: Partial<Person> | CompanyMember, onelinerName?: boolean)
    {
        if (onelinerName)
        {
            if (!(p as Person).name?.split(' ').length)
            {
                return 'Please enter full name';
            }
        }
        else if (!(p as CompanyMember).firstName?.length || !(p as CompanyMember).lastName?.length)
        {
            return 'Please enter full name';
        }
        if (!p.email)
        {
            return 'Please enter an email';
        }
        if (!p.profile)
        {
            return 'Please select a profile';
        }
    }
}

export const organizationStore = new OrganizationManagement();
