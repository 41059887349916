import React from 'react';
import './Chat.scss';

export interface MinimizedChatProps
{
    title: React.ReactNode
    titleInitials: React.ReactNode
    titleColor?: string
    onMaximize?()
    onClose?()
}

export default class MinimizedChat extends React.PureComponent<MinimizedChatProps>
{
    render()
    {
        const {titleInitials, titleColor, title, onMaximize, onClose} = this.props;
        return (
            <div className='ChatTitle minimized'>
                {!!titleInitials &&
                <div>
                    <div className='ChatIcon' style={{backgroundColor: titleColor}}>{titleInitials}</div>
                </div>}
                <span className='text'>{title}</span>
                <div className='options'>
                    <pre onClick={onMaximize}>‾</pre>
                    <pre onClick={onClose}>X</pre>
                </div>
            </div>
        );
    }
}
