import {cartTotalStore} from './CartTotalStore';
import {projectStore} from '../stores/ProjectStore';
import type {ItemPriceInfo} from '../../graphql/api/cart/getItemPrice';
import {getDeliveryMonth} from './deliveryMonthUtility';
import {productWorkshopProductsStore} from './ProductWorkshopProductsStore';

export default function getCartInfo(storeId: string, deliveryMonth?: string, workshop?: string)
{
    const isFRNonFood = projectStore.isFRNonFood;
    const deliveryMonths: string[] = isFRNonFood ? [] : null;
    const workshops: typeof productWorkshopProductsStore.workshops = isFRNonFood ? [] : null;
    const total: ItemPriceInfo = {
        invoice: 0,
        selling: 0,
        discount: 0,
        paid: 0,
    };
    cartTotalStore.cartViewUpdated;
    const products = cartTotalStore.products[projectStore.id];
    if (products)
    {
        const year = new Date(projectStore.selected?.fair.date.start).getFullYear();
        const currentStoreProducts = products.filter(p =>
            p.stores.find(s => s.storeId == storeId)?.weeks.some(w => w.quantity)
        );
        const allWorkshops = productWorkshopProductsStore.workshops;
        const selectedWorkshop = workshop && allWorkshops?.find(w => w.id == workshop);
        for (let i = 0; i < currentStoreProducts.length; ++i)
        {
            const p = currentStoreProducts[i];
            const weeks = p.stores.find(s => s.storeId == storeId).weeks;
            let workshopCondition = !isFRNonFood;
            let partialPromoCodes: string[];
            if (isFRNonFood)
            {
                if (!workshop || selectedWorkshop?.products.includes(p.id))
                {
                    workshopCondition = true;
                    if (selectedWorkshop?.partial)
                    {
                        partialPromoCodes = selectedWorkshop.partial.find(a => a.id === p.id)?.promoCodes;
                    }
                }
                const thisWorkshops = allWorkshops?.filter(w =>
                {
                    if (!w.products.includes(p.id))
                    {
                        return false;
                    }
                    if (!w.partial)
                    {
                        return true;
                    }
                    const partial = w.partial.find(a => a.id === p.id);
                    if (!partial)
                    {
                        return true;
                    }
                    return weeks.some(v => v.quantity && partial.promoCodes.includes(v.promoCode));
                });
                if (thisWorkshops?.length)
                {
                    for (const thisWorkshop of thisWorkshops)
                    {
                        if (!workshops.includes(thisWorkshop))
                        {
                            workshops.push(thisWorkshop);
                        }
                    }
                }
            }
            for (let j = 0; j < weeks.length; ++j)
            {
                const w = weeks[j];
                if (w.quantity)
                {
                    let deliveryMonthCondition = !isFRNonFood;
                    let partialCondition = !isFRNonFood;
                    if (isFRNonFood)
                    {
                        const thisDeliveryMonth = getDeliveryMonth(w.week, year);
                        if (!deliveryMonth || deliveryMonth == thisDeliveryMonth)
                        {
                            deliveryMonthCondition = true;
                        }
                        if (thisDeliveryMonth && !deliveryMonths.includes(thisDeliveryMonth))
                        {
                            deliveryMonths.push(thisDeliveryMonth);
                        }
                        partialCondition = !partialPromoCodes || partialPromoCodes.includes(w.promoCode);
                    }
                    if (workshopCondition && deliveryMonthCondition && partialCondition)
                    {
                        total.invoice += w.price.invoice * w.quantity;
                        total.selling += w.price.selling * w.quantity;
                        total.discount += w.price.discount * w.quantity;
                        total.paid += w.price.paid * w.quantity;
                    }
                }
            }
        }
    }
    return {
        total,
        deliveryMonths: deliveryMonths?.sort(),
        workshops,
    };
}
