import {action, makeObservable, observable} from 'mobx';
import {call, gql} from '../client';

export function loadVisualsAccess(project: string)
{
    return call<{visualsAccess: boolean}>(
        gql`query($project:ID!){visualsAccess(project:$project)}`,
        {project}
    ).then(({data}) => data?.visualsAccess);
}

class VisualsAccessStore
{
    @observable access: {[project: string]: boolean} = {};

    constructor()
    {
        makeObservable(this);
    }

    load(project: string)
    {
        loadVisualsAccess(project).then(action(res =>
        {
            this.access[project] = res;
        }));
    }
}

export const visualsAccessStore = new VisualsAccessStore();
