import {call, gql} from '../../client';
import {IProject} from '../../stores/ProjectStore';

export function createProject(info: IProject)
{
    return call<{createProject: IProject}>(
        gql`mutation ($input:CreateProjectInput!){createProject(input:$input){id,name,client,archived,fair{productFamilies,productWorkshops,country,active,homeLink,floorMapPreview,floorMap,floorMapMobile,week,jobNumber,settings,slug,codes{invoice,creditNote},date{start,end},videos{live,url,access},percentages,minSurface,earlyBird,lateOrder,lateOrder2,totalSpace,deals,scannerGroups,filters{productType,promotions,productFamilies,productWorkshops,concept,brands},universalQRCode,eventProfiles,outOfOfficeMessage}}}`,
        {input: info})
    .then(({data}) => data?.createProject);
}

export function updateProject(info: IProject)
{
    return call<{updateProject: IProject}>(
        gql`mutation ($input:UpdateProjectInput!){updateProject(input:$input){id,name,client,archived,fair{productFamilies,productWorkshops,country,active,homeLink,floorMapPreview,floorMap,floorMapMobile,week,jobNumber,settings,slug,codes{invoice,creditNote},date{start,end},videos{live,url,access},percentages,minSurface,earlyBird,lateOrder,lateOrder2,totalSpace,deals,scannerGroups,filters{productType,promotions,productFamilies,productWorkshops,concept,brands},universalQRCode,eventProfiles,outOfOfficeMessage}}}`,
        {input: info})
    .then(({data}) => data?.updateProject);
}

export function deleteProject(id: string)
{
    return call<{deleteProject: boolean}>(gql`mutation ($id:ID!){deleteProject(id:$id)}`, {id})
    .then(({data}) => data?.deleteProject);
}
