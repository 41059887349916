import {Link} from 'react-router-dom';
import { TimelineItem, TimelineRow } from './TimelineItem';
import FA from 'react-fontawesome';
import { formatTimelineDate } from '../common/timeFormatting';
import { t } from '../translations';
import {user} from '../stores/user';

interface Props
{
    id: string
    target: string
    status: string
    date: Date
    projectName?: string
}

export default function TimelineStand({id, target, status, date, projectName}: Props)
{
    return (
        <TimelineItem
            withLine
            mark={<FA name='box-check' className='fad'/>}
        >
            <TimelineRow
                className='top'
                content={
                    <div className='d-flex'>
                        <div className=''>
                            <span>
                                {status == 'submitted' && <>
                                    <b>{target}</b>
                                    {' ' + t.timelineStand.submittedStand + ' '}
                                    <StandName id={id} name={t.timelineStand.submittedStandName}/>
                                    {projectName && (<> {t.timelineStand.for} <b>{projectName}</b></>)}
                                </>}
                                {status == 'approved' && <>
                                    {t.timelineStand.approvedStand1}
                                    <StandName id={id} name={target}/>
                                    <span dangerouslySetInnerHTML={{__html: ' ' + t.timelineStand.approvedStand2}}/>
                                </>}
                                {/*{status == 'created' && <>created a <b>stand</b></>}*/}
                            </span>
                        </div>
                    </div>
                }
                aside={formatTimelineDate(date)}
            />
        </TimelineItem>
    );
}

function StandName({id, name}: {id: string, name: string})
{
    return (
        <b>{user.moderator ? <Link className='color-initial' to={'/stands#' + id}>{name}</Link> : name}</b>
    );
}
