// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimelinePostSeparator {
  margin-top: 2rem;
  margin-left: 2.5rem;
  display: flex;
}
.TimelinePostSeparator:before, .TimelinePostSeparator:after {
  content: "";
  border-top: 1px solid #aaaaaa;
  flex-grow: 1;
  margin: 0.8rem 2rem 0;
}`, "",{"version":3,"sources":["webpack://./frontend/Post/TimelinePostSeparator.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,mBAAA;EACA,aAAA;AAAF;AACE;EACE,WAAA;EACA,6BAAA;EACA,YAAA;EACA,qBAAA;AACJ","sourcesContent":["\n.TimelinePostSeparator {\n  margin-top: 2rem;\n  margin-left: 2.5rem;\n  display: flex;\n  &:before, &:after {\n    content: \"\";\n    border-top: 1px solid #aaaaaa;\n    flex-grow: 1;\n    margin: .8rem 2rem 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
