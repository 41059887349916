export interface DataUrl
{
    contentType: string | undefined
    attributes: Map<string, string> | undefined
    base64: boolean
    content: string
}

// doesn't do any url decoding
export function parseDataUrl(url: string): DataUrl
{
    const match = url.match(/^data:([\w-]+\/[\w-+.]+)?(;[^=;,]+=[^=;,]*)*((;base64,(|[a-zA-Z0-9+/]{2}|[a-zA-Z0-9+/]{3,}={0,2}))|,(.*))$/);

    if (!match)
    {
        return null;
    }

    const [
        ,
        contentType,
        attributes,
        ,
        base64Full,
        base64Content,
        plainTextContent,
    ] = match;

    const attributesMap = attributes ? new Map<string, string>() : undefined;
    if (attributes)
    {
        for (const a of attributes.substring(1).split(';'))
        {
            const [name, value] = a.split('=');
            attributesMap.set(name, value);
        }
    }

    return {
        contentType,
        attributes: attributesMap,
        base64: !!base64Full,
        content: base64Content ?? plainTextContent,
    };
}

export function getDecodedDatURLContent(parsedDataUrl: DataUrl)
{
    return parsedDataUrl.base64 ? atob(parsedDataUrl.content) : parsedDataUrl.content;
}
