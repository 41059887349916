// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoRows {
  margin-bottom: 20px;
}

.PriceRows {
  font-size: 18px;
}
.PriceRows > span {
  height: 24px;
}
.PriceRows span {
  display: inline-block;
  white-space: nowrap;
}

.ProductComment {
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-bottom: 20px;
}

.FRInfoRows {
  margin-bottom: 20px;
}
.FRInfoRows p {
  margin-bottom: 0.5em;
}

.FRPriceRow {
  max-width: 400px;
  text-align: center;
  color: #fff;
  background-color: #02abee;
  border-radius: 5px;
  padding: 5px 0;
  margin: 10px 0;
  display: flex;
  justify-content: space-around;
}

.FRProductImage {
  height: 50px;
  margin: 10px 0;
}

.FRExtraRows p {
  margin-bottom: 0.5em;
}

.FRMarketStar {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./frontend/StoreCatalog/PriceInfo.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;;AAIA;EACE,eAAA;AADF;AAEE;EACE,YAAA;AAAJ;AAEE;EACE,qBAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,qBAAA;EACA,qBAAA;EACA,mBAAA;AADF;;AAIA;EACE,mBAAA;AADF;AAEE;EACE,oBAAA;AAAJ;;AAIA;EACE,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;EACA,cAAA;EACA,aAAA;EACA,6BAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;AADF;;AAKE;EACE,oBAAA;AAFJ;;AAMA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AAHF","sourcesContent":["@import 'frontend/variables';\n\n.InfoRows {\n  margin-bottom: 20px;\n}\n\n.PriceRows {\n  font-size: 18px;\n  > span {\n    height: 24px;\n  }\n  span {\n    display: inline-block;\n    white-space: nowrap;\n  }\n}\n\n.ProductComment {\n  word-wrap: break-word;\n  white-space: pre-wrap;\n  margin-bottom: 20px;\n}\n\n.FRInfoRows {\n  margin-bottom: 20px;\n  p {\n    margin-bottom: .5em;\n  }\n}\n\n.FRPriceRow {\n  max-width: 400px;\n  text-align: center;\n  color: #fff;\n  background-color: #02abee;\n  border-radius: 5px;\n  padding: 5px 0;\n  margin: 10px 0;\n  display: flex;\n  justify-content: space-around;\n}\n\n.FRProductImage {\n  height: 50px;\n  margin: 10px 0;\n}\n\n.FRExtraRows {\n  p {\n    margin-bottom: .5em;\n  }\n}\n\n.FRMarketStar {\n  width: 20px;\n  height: 20px;\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
