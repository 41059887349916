import {useState} from 'react';
import {Link} from 'react-router-dom';
import Form from '../components/Form';
import Button from '../components/Button';
import {user} from '../../stores/user';
import {LoginMethodProps} from '../EventRegistrationFormPage';
import styles from './Login.module.css';
import {t} from '../../translations';

export default function EnterEmail({onLogin}: LoginMethodProps)
{
    const [email, setEmail] = useState(() => user.info?.email || '');
    const [error, setError] = useState<string>();
    const confirm = () => onLogin({value: email, method: 'Email'}).then(e =>
    {
        if (e)
        {
            setError(e);
        }
    });
    return (
        <Form className={styles.content} onSubmit={confirm}>
            <h3>{t.eventRegistration.registerUsingEmail}</h3>
            <input
                className={styles.emailInput}
                type='email'
                value={email}
                onChange={e => setEmail(e.currentTarget.value)}
                placeholder='your.name@company.com'
                required
                autoFocus
            />
            {error && <div className={styles.error}>{error}</div>}
            <Button type='submit' primary className={styles.submitButton}>{t.eventRegistration.sendConfirmationCode}</Button>
            <div className={styles.textBelowSubmitButton}><Link to='#phone'>{t.eventRegistration.registerUsingPhone}</Link></div>
            <div className={styles.textBelowSubmitButtonFlexible}>{t.eventRegistration.alreadyRegistered}<Link to='#badge-code'>{t.eventRegistration.modifyParticipation}</Link></div>
        </Form>
    );
}
