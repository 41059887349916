// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.underlineElement {
  position: relative;
}
.underlineElement:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: rgba(68, 68, 68, 0.1);
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}
.underlineElement:hover:after, .underlineElement:focus:after {
  visibility: visible;
  transform: scaleX(1);
}
.underlineElement:focus:after {
  background: #4c8bf5;
}`, "",{"version":3,"sources":["webpack://./frontend/common/underlineElement.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;AACE;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,SAAA;EACA,OAAA;EACA,iCAAA;EACA,kBAAA;EACA,kBAAA;EACA,oBAAA;EACA,wBAAA;AACJ;AAEI;EACE,mBAAA;EACA,oBAAA;AAAN;AAII;EACE,mBAAA;AAFN","sourcesContent":["\n.underlineElement {\n  position: relative;\n  &:after {\n    content: \"\";\n    position: absolute;\n    width: 100%;\n    height: 3px;\n    bottom: 0;\n    left: 0;\n    background: rgba(68, 68, 68, 0.1);\n    visibility: hidden;\n    border-radius: 5px;\n    transform: scaleX(0);\n    transition: .25s linear;\n  }\n  &:hover, &:focus {\n    &:after {\n      visibility: visible;\n      transform: scaleX(1);\n    }\n  }\n  &:focus {\n    &:after {\n      background: #4c8bf5;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
