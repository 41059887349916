import {action, makeObservable, observable} from 'mobx';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {ILoadProductsInput, loadCatalogProducts} from '../ExhibitorProducts/productProxy';
import {cartQuantityStore} from './CartQuantityStore';
import {catalogProductStore} from './CatalogProductStore';
import {cartValidateStore} from './CartValidateStore';
import {registerGlobalEventHandler} from '../stores/globalEvents';

class CatalogStore
{
    @observable products: {[project: string]: ICatalogProduct[]} = {};
    @observable counts: {[project: string]: number} = {};
    lastInput: Omit<ILoadProductsInput, 'cursor'>;

    @observable searchProducts: ICatalogProduct[] = null;
    @observable searchCount: number = null;

    @observable loading = false;
    @observable loadingMore = false;

    constructor()
    {
        makeObservable(this);
        cartQuantityStore.addListener((project, products) =>
        {
            for (const product of products)
            {
                let existing = this.products[project]?.find(p => p.id == product.id);
                if (existing)
                {
                    Object.assign(existing, product);
                }
                existing = this.searchProducts?.find(p => p.id == product.id);
                if (existing)
                {
                    Object.assign(existing, product);
                }
            }
        });
        cartValidateStore.addListener(project =>
        {
            if (this.products[project])
            {
                this.products[project] = null;
            }
        });
        registerGlobalEventHandler('logout', () =>
        {
            this.products = {};
        });
    }

    @action
    loadProducts(input: Omit<ILoadProductsInput, 'cursor'>)
    {
        const {project} = input;
        const thisIsSearch = isSearch(input);
        this.lastInput = input;
        this.loading = true;
        if (!thisIsSearch)
        {
            this.searchProducts = null;
            this.searchCount = null;
        }
        else
        {
            this.searchProducts = [];
        }
        loadCatalogProducts(input).then(action(res =>
        {
            this.loading = false;
            if (res)
            {
                if (thisIsSearch)
                {
                    this.searchProducts = res.catalogProducts;
                    this.searchCount = res.count;
                }
                else
                {
                    this.products[project] = res.catalogProducts;
                    this.counts[project] = res.count;
                }
                for (const product of res.catalogProducts)
                {
                    catalogProductStore.addProduct(product);
                }
            }
        }))
    }

    get isSearch()
    {
        return isSearch(this.lastInput);
    }

    @action
    loadMoreProducts()
    {
        if (!this.lastInput)
        {
            return;
        }
        if (this.loading || this.loadingMore)
        {
            return;
        }
        const {project} = this.lastInput;
        const thisIsSearch = this.isSearch;
        if (thisIsSearch)
        {
            if (this.searchCount == this.searchProducts.length)
            {
                return;
            }
        }
        else
        {
            if (this.counts[project] == this.products[project].length)
            {
                return;
            }
        }
        this.loadingMore = true;
        loadCatalogProducts({...this.lastInput, cursor: thisIsSearch ? this.searchProducts.length : this.products[project].length}).then(action(res =>
        {
            this.loadingMore = false;
            if (res)
            {
                if (thisIsSearch)
                {
                    this.searchProducts.push(...res.catalogProducts);
                    this.searchCount = res.count;
                }
                else
                {
                    this.products[project].push(...res.catalogProducts);
                    this.counts[project] = res.count;
                }
                for (const product of res.catalogProducts)
                {
                    catalogProductStore.addProduct(product);
                }
            }
        }))
    }
}

export const catalogStore = new CatalogStore();

function isSearch(input: Omit<ILoadProductsInput, 'cursor'>)
{
    return !!(input && (input.brands?.length || input.productFamilies?.length || input.search));
}
