// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mtiU22GuxQYtGnhn3g83 {
  padding: 50px;
}
.MD4dgOyUPsuShOXj8O33 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}
.e7_hyX1huZk6SFfudg07 {
  text-align: center;
  margin-bottom: 50px;
}
.N6NXnR0zErNQANT8pOEq {
  text-align: center;
}
.N6NXnR0zErNQANT8pOEq button {
  max-width: 100%;
  width: 260px;
  padding-left: 0;
  padding-right: 0;
}
.KmQUfB0JKGBvt5T6qLLl {
  margin-bottom: 10px;
}
.KKTUGedYocZxenSRzDK6 {
  margin-bottom: 10px;
  color: #2A3B52;
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/views/InitialView.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".content {\n  padding: 50px;\n}\n.title {\n  text-align: center;\n  margin-bottom: 20px;\n  font-weight: bold;\n}\n.description {\n  text-align: center;\n  margin-bottom: 50px;\n}\n.buttons {\n  text-align: center;\n}\n.buttons button {\n  max-width: 100%;\n  width: 260px;\n  padding-left: 0;\n  padding-right: 0;\n}\n.buttonMargin {\n  margin-bottom: 10px;\n}\n.alreadyRegistered {\n  margin-bottom: 10px;\n  color: #2A3B52;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `mtiU22GuxQYtGnhn3g83`,
	"title": `MD4dgOyUPsuShOXj8O33`,
	"description": `e7_hyX1huZk6SFfudg07`,
	"buttons": `N6NXnR0zErNQANT8pOEq`,
	"buttonMargin": `KmQUfB0JKGBvt5T6qLLl`,
	"alreadyRegistered": `KKTUGedYocZxenSRzDK6`
};
export default ___CSS_LOADER_EXPORT___;
