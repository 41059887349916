import {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import FA from 'react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { projectStore } from '../stores/ProjectStore';
import {colon, user} from '../stores/user';
import './StoreCatalogProduct.scss';
import '../Admin/Labels/Labels.scss';
import {countryCurrency} from '../OrderStand/utility';
import {cartQuantityStore} from './CartQuantityStore';
import TextEditable from '../common/TextEditable';
import { companyStore } from '../Company/CompanyStore';
import { t } from '../translations';
import ProductImage from './ProductImage';
import {debouncedLogView} from '../stores/logView';
import {catalogProductStore} from './CatalogProductStore';
import {pickLanguage} from '../stores/utility';
import {Loading} from '../common/Loading';
import {catalogStore} from './CatalogStore';
import ProductLabels from './ProductLabels';
import {ProductComment, ExtraRows, FRInfoRows, FRPriceRow, InfoRows, PriceRows, FRProductImage, FRMarketStars} from './PriceInfo';
import TotalQuantityOverlay from './TotalQuantityOverlay';
import useWindowDimensions from '../common/useWindowDimensions';
import ProductStarDiscount from './ProductStarDiscount';
import ProductOrdered from './ProductOrdered';
import {getProductTotal, getProductTotalFR, getProductTotalIT} from './productUtility';
import {formatVideoEmbedUrl} from '../../lib/common';
import LimitedQuantityDateOverlay from './LimitedQuantityDateOverlay';
import {ProductDisplay, ProductDisplayFR, ProductPackage, ProductPackagePrice} from './ProductDisplayPackage';

export default observer(function StoreCatalogProduct()
{
    const navigate = useNavigate();
    const params = useParams<{product: string}>();
    const productId = params.product;
    const [wasLoading, setWasLoading] = useState(false);
    const projId = projectStore.id;
    const country = projectStore.selectedCountry;
    const isFRNonFood = projectStore.isFRNonFood;
    const currency = countryCurrency(country);
    const isFair = !projectStore.isPromotion;
    const product = catalogProductStore.products?.find(p => p.id === productId);
    const ownStores = companyStore.ownStores?.filter(c => product?.stores.some(s => s.storeId == c.store.id));
    const firstStore = ownStores[0];
    const [storeListOffset, setStoreListOffset] = useState(0);
    const {width} = useWindowDimensions();

    useEffect(() =>
    {
        catalogProductStore.loadProduct(productId).then(p =>
        {
            if (p)
            {
                debouncedLogView({type: 'product', project: p.project || projectStore.id, product: productId});
            }
        });
        setWasLoading(true);
    }, [productId]);

    useEffect(() =>
    {
        if (product?.project && product.project != projectStore.id)
        {
            projectStore.select(product.project);
        }
    }, [product?.project]);

    useEffect(() =>
    {
        return () =>
        {
            if (product && product.project != projectStore.id && projId != projectStore.id)
            {
                Promise.resolve().then(() => navigate('/catalog'));
            }
        };
    }, [projId]);

    const products = catalogStore.products[projId];
    const productIndex = products?.findIndex(p => p.id == productId);
    const nextProductId = productIndex >= 0 ?
        products[products.length > productIndex + 1 ? productIndex + 1 : 0].id :
        null;

    useEffect(() =>
    {
        if (projId && (productIndex == null || productIndex < 0 || productIndex + 1 == products.length))
        {
            if (products?.length || catalogStore.isSearch)
            {
                catalogStore.loadMoreProducts();
            }
            else
            {
                catalogStore.loadProducts({project: projId});
            }
        }
    }, [productIndex, products?.length]);

    if (!product && (!wasLoading || catalogProductStore.loading))
    {
        return (
            <div style={{height: '440px', position: 'relative'}}>
                <Loading/>
            </div>
        );
    }
    if (!product)
    {
        return <div>No product found</div>;
    }

    if (!user.moderator && (!ownStores.length || !product.stores.length) && !projectStore.italyHeadquarters)
    {
        return null;
    }
    const pid = product.id;
    const storeId = firstStore?.store?.id;

    let displayedStores = product.stores.slice().sort((a, b) =>
        companyStore.ownStores.findIndex(c => c.store.id == a.storeId)
        -
        companyStore.ownStores.findIndex(c => c.store.id == b.storeId)
    );
    const showCount = width >= 992 ? 3 : width >= 768 ? 2 : 1
    displayedStores = displayedStores.slice(storeListOffset, storeListOffset + showCount);

    const productStore = storeId ? product.stores.find(s => s.storeId == storeId) : null;

    return (
        <div className='store-catalog-product p-5'>
            <div className='product d-flex row'>
                <div className='left col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12'>
                    <div className='image-container'>
                        <ProductImage images={product?.images}/>
                        <ProductStarDiscount starDiscount={productStore?.starDiscount}/>
                        <TotalQuantityOverlay product={product} storeId={storeId} large/>
                        <LimitedQuantityDateOverlay product={product} large/>
                    </div>
                </div>
                <div className='right w-100 d-flex flex-column col-xl-9 col-lg-8 col-md-8 col-sm-8 col-12'>
                    <div className='product-info'>
                        <p className='name'>{pickLanguage(product.description)}</p>
                        {country == 'FR' ?
                            <FRInfoRows product={product} storeId={storeId}/>
                            :
                            <InfoRows product={product} storeId={storeId}/>
                        }
                        <ProductComment product={product} storeId={storeId}/>
                        {country == 'FR' ?
                            <>
                                <FRPriceRow product={product} storeId={storeId}/>
                                {isFRNonFood && <FRProductImage product={product} storeId={storeId}/>}
                                <ExtraRows product={product} storeId={storeId}/>
                                {isFRNonFood && <FRMarketStars product={product} storeId={storeId}/>}
                            </>
                            :
                            <>
                                <ExtraRows product={product} storeId={storeId}/>
                                <PriceRows product={product} storeId={storeId}/>
                            </>
                        }
                        {!!product.video &&
                        <iframe
                            className='product-video'
                            src={formatVideoEmbedUrl(product.video, 'loop=1&byline=0&title=0')}
                            frameBorder='0'
                            allow='fullscreen'
                            allowFullScreen
                        />
                        }
                        <ProductLabels product={product} storeId={storeId}/>
                        <ProductDisplay product={product}/>
                        <ProductPackage product={product} currency={currency}/>
                        <ProductPackagePrice product={product} currency={currency}/>
                        <ProductDisplayFR product={product} currency={currency} storeId={storeId}/>
                        <ProductOrdered product={product}/>
                    </div>
                </div>
                {projectStore.canAddToCart &&
                <div className='d-flex w-100'>
                    <div className='col-lg-3 col-md-3 col-sm-3 col-6'/>
                    <div className='col-lg-9 col-md-9 col-sm-9 col-6'>
                        <div className='arrows w-100 d-flex justify-content-between align-items-center position-relative'>
                            {storeListOffset > 0 ?
                                <FA name='chevron-left' onClick={() => setStoreListOffset(v => v - 1)}/> :
                                <span/>
                            }
                            {storeListOffset < product.stores.length - showCount ?
                                <FA name='chevron-right' onClick={() => setStoreListOffset(v => v + 1)}/> :
                                <span/>
                            }
                        </div>
                    </div>
                </div>}
                {projectStore.canAddToCart &&
                <div className='weeks w-100 d-flex'>
                    <div className='weeks-left d-flex flex-column col-lg-3 col-md-3 col-sm-3 col-6'>
                        {product.weeks.map(p =>
                        {
                            const promoCode = p.promoCode;
                            const productStoreWeek = product.stores.find(s => s.storeId == storeId)?.weeks.find(wp => wp.promoCode == promoCode);
                            if (!productStoreWeek)
                            {
                                return null;
                            }
                            return (
                                <div className='d-flex justify-content-around align-items-center week-info' key={promoCode}>
                                    {country == 'FR' ?
                                        <div className='week-name text-center'>
                                            {p.week != null && `${['fr', 'it'].includes(user.language) ? 'Sem.' : 'Week'} ${p.week}`}
                                        </div>
                                        :
                                        country != 'PL' ?
                                            <div className='week-name text-center'>
                                                {`${['fr', 'it'].includes(user.language) ? 'S' : 'W'}${p.week}`}
                                            </div>
                                            :
                                            null
                                    }
                                    {country == 'BE' &&
                                    <div className='promo-code text-center'>
                                        <div>{promoCode}</div>
                                        {!!p.folderType && <div>{p.folderType}</div>}
                                    </div>}
                                    {country == 'FR' &&
                                    <div className='promo-code text-center'>
                                        {p.sousVente ?
                                            <div className='sousVente' style={product.sousVenteSize ? {fontSize: product.sousVenteSize + 'em'} : null}>
                                                {p.sousVente}
                                            </div>
                                            :
                                            productStoreWeek.catalog ?
                                                <div className='catalog'>{productStoreWeek.catalog}</div>
                                                :
                                                <div>{`Livraison ${['fr', 'it'].includes(user.language) ? 'S' : 'W'}${p.week || ''}`}</div>
                                        }
                                        {!!productStoreWeek.message &&
                                        isFRNonFood &&
                                        <div className='message'>{productStoreWeek.message}</div>
                                        }
                                    </div>}
                                </div>
                            );
                        })}
                    </div>
                    <div className='weeks-right w-100 position-relative col-lg-9 col-md-9 col-sm-9 col-6'>
                        {product.weeks.map((p, i) =>
                            <div className='store-row w-100' key={p.promoCode}>
                                <div className='week d-flex w-100'>
                                    {displayedStores.map(c =>
                                    {
                                        const week = c.weeks.find(wp => wp.promoCode == p.promoCode);
                                        const quantity = week ?
                                            cartQuantityStore.getQuantityChange(projId, c.storeId, pid, week.promoCode) ?? week.quantity : 0;
                                        const setQuantity = (q: number) =>
                                            cartQuantityStore.setQuantity(projId, c.storeId, pid, p.promoCode, p.week, q);
                                        return (
                                            <div
                                                className='position-relative store-quantity col-lg-4 col-md-6 col-sm-12 col-12'
                                                key={c.storeId}
                                            >
                                                {i === 0 &&
                                                <h6 className='store-name text-center'>
                                                    {c.storeId} {ownStores.find(s => s.store.id == c.storeId)?.name}
                                                </h6>}
                                                {!!week &&
                                                <div className={'d-flex align-items-center justify-content-center order-quantity'}>
                                                    <div
                                                        className='plus-button minus'
                                                        onClick={quantity > (week.minQuantity || 0) ? () => setQuantity(quantity - 1) : null}
                                                    >
                                                        <FA className='fal plus-sign' name='minus'/>
                                                    </div>
                                                    <div className='mobile amount underlineElement'>
                                                        <input
                                                            type='number'
                                                            min={week.minQuantity || 0}
                                                            step={1}
                                                            value={quantity}
                                                            onChange={e => setQuantity(+e.currentTarget.value)}
                                                        />
                                                    </div>
                                                    <TextEditable
                                                        type='span'
                                                        className='amount underlineElement'
                                                        content={'' + quantity}
                                                        onChangeEnter={q => setQuantity(+q)}
                                                    />
                                                    <div className='plus-button' onClick={product.limitedQuantity !== 0 ? () => setQuantity(quantity + 1) : null}>
                                                        <FA className='fal plus-sign' name='plus'/>
                                                    </div>
                                                </div>}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                        <div className='store-row w-100'>
                            <div className='d-flex pv-total'>
                                {displayedStores.map(s =>
                                {
                                    const sid = s.storeId;
                                    if (country == 'FR')
                                    {
                                        const storeType = companyStore.ownStores.find(c => c.store.id == sid).store.storeType;
                                        if (!(isFRNonFood || ['MF', 'MI', 'H', 'HF'].includes(storeType)))
                                        {
                                            return <div key={sid} className='col-lg-4 col-md-6 col-sm-12 col-12'/>;
                                        }
                                        const total = getProductTotalFR(pid, product.stores.find(ps => ps.storeId == sid));
                                        if (!total.selling)
                                        {
                                            return <div key={sid} className='col-lg-4 col-md-6 col-sm-12 col-12'/>;
                                        }
                                        return (
                                            <div key={sid} className='col-lg-4 col-md-6 col-sm-12 col-12 text-center'>
                                                {isFRNonFood ?
                                                    'CPI' == storeType ?
                                                        <p>
                                                            {product.flow == 'GSA' ? 'FOB ' : 'Px Cession '}
                                                            <b>{user.formatCurrency(currency, total.invoice)}</b>
                                                        </p>
                                                        :
                                                        <>
                                                            <p>{'Px Cession '}<b>{user.formatCurrency(currency, total.invoice)}</b></p>
                                                            <p>{'PVC '}<b>{user.formatCurrency(currency, total.selling)}</b></p>
                                                        </>
                                                    :
                                                    <p>{'PVC '}<b>{user.formatCurrency(currency, total.selling)}</b></p>
                                                }
                                            </div>
                                        );
                                    }
                                    if (country == 'IT')
                                    {
                                        return (
                                            <div key={sid} className='d-flex col-lg-4 col-md-6 col-sm-12 col-12 text-center justify-content-center'>
                                                <span>PC</span>
                                                <b className='ml-2'>{user.formatCurrency(
                                                    currency,
                                                    getProductTotalIT(product.id, product.stores.find(ps => ps.storeId == sid))
                                                )}</b>
                                            </div>
                                        );
                                    }
                                    return (
                                        <div key={sid} className='d-flex col-lg-4 col-md-6 col-sm-12 col-12 text-center justify-content-center'>
                                            <span>{country == 'BE' ? isFair ? 'PV' : 'PVP' : t.reports.total}</span>
                                            <b className='ml-2'>{user.formatCurrency(
                                                currency,
                                                getProductTotal(product.id, product.stores.find(ps => ps.storeId == sid))
                                            )}</b>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>}
                <div className='buttons ml-auto'>
                    {projectStore.selectedCountry == 'IT' ?
                        <a
                            className='button'
                            href='#back'
                            onClick={(e) =>
                            {
                                e.preventDefault();
                                navigate(-1);
                            }}
                        >
                            {t.catalog.back}
                        </a>
                        :
                        <Link
                            to={nextProductId ? `/catalog/${nextProductId}` : '#'}
                            className='button'
                        >
                            {t.catalog.nextProduct}
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
});
