import React from 'react';
import {LoginChildProps} from './LoginBox';
import {t} from '../translations';

export default function LoginMethodSwitchOptions({isEvent, state, setState}: LoginChildProps)
{
    return (
        <div className='LoginMethodSwitchOptions mt-4'>
            {state != 'enterPhone' &&
            <div className='clickableLink' onClick={() => setState('enterPhone')}>
                {t.authentication.loginSwitchToPhone}
            </div>}
            {state != 'enterEmail' &&
            <div className='clickableLink' onClick={() => setState('enterEmail')}>
                {t.authentication.loginSwitchToEmail}
            </div>}
            {isEvent && state != 'enterRegID' && state != 'regIDNotFound' &&
            <div>
                {t.authentication.loginSwitchToRegID1 + ' '}
                <span className='clickableLink' onClick={() => setState('enterRegID')}>
                    {t.authentication.loginSwitchToRegID2}
                </span>
            </div>}
        </div>
    )
}
