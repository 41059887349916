import React from 'react';
import TextEditable from './TextEditable';
import FA from 'react-fontawesome';
import './ProfileItem.scss'
import {IMaybePath} from './deepSet';

interface Props
{
    content: any
    icon?: string
    editable?: boolean
    objKey?: IMaybePath
    className?: string
    dimmed?: boolean
    slickToCopy?: boolean
    title?: string
    children?: React.ReactNode
    onChanged?(objKey: IMaybePath, value: string): void
}

export const ProfileItem = ({content, icon, editable = true, objKey, onChanged, className, dimmed, slickToCopy, title, children}: Props) =>
{
    const handleBlur = (value) =>
    {
        return onChanged(objKey, value);
    };

    return (
        <div className={'profileItem ' + (editable ? 'editable ' : '') + (dimmed ? 'dimmed ' : '') + (className ? className : '')} title={title}>
            {!!icon && (
                slickToCopy ?
                    <FA name={icon} className='fad mr-2 cursor-pointer' title='Click to copy' onClick={() => navigator.clipboard.writeText(content)}/>
                    :
                    <FA name={icon} className='fad mr-2'/>
            )}
            {editable ? (<TextEditable onChangeEnter={handleBlur} content={content ? content.toString() : ''}/>) : (<div>{content}</div>)}
            {children}
        </div>
    );
}

export const MemoizedProfileItem = React.memo(ProfileItem);

