import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import Form from '../components/Form';
import Button from '../components/Button';
import FormField from '../components/FormField';
import FormFieldPhone from '../components/FormFieldPhone';
import {Country} from '../../../graphql/api/Types';
import {EventUsersListItem} from '../../Event/eventsProxy';
import {findProfile} from '../../data/profiles';
import SelectCompanyExhibitor from '../components/SelectCompanyExhibitor';
import SelectCompanyHostess from '../components/SelectCompanyHostess';
import SelectCompanyStore from '../components/SelectCompanyStore';
import {IVerificationMethod, RegisterInput} from '../../../graphql/api/user/User';
import {UpdatePersonInfoInput} from '../EventRegistrationFormStore';
import erStyles from './EventRegistration.module.css';
import {t} from '../../translations';
import {LoadingInline} from '../../common/Loading';
import {user} from '../../stores/user';

interface Props
{
    country: Country
    project: string
    method?: IVerificationMethod | 'RegID'
    emailOPhone?: string
    // use either an existing account or a profile type for a new account
    account?: EventUsersListItem
    profile?: string
    onBack: () => void
    onRegister?: (accountInfo: RegisterInput) => Promise<string | void>
    onUpdate?: (accountInfo: Omit<UpdatePersonInfoInput, 'id'>) => Promise<boolean>
    onNext?: () => void
}

export default observer(function AccountInfo({country, project, method, emailOPhone, account, profile, onBack, onRegister, onUpdate, onNext}: Props)
{
    if (profile == 'invitedGuest')
    {
        profile = 'guest';
    }
    profile = account ? account.profile : profile;
    const profileInfo = findProfile(profile);

    const isGuestOrMediaOrMF = profile === 'guest' || profile === 'media' || profile === 'maintenanceFournisseur';
    const isHostess = profile === 'hostess';
    const isCarrefour = profile === 'headquarter' || profile === 'carrefourGroup';
    // const isExhibitor = profile.includes('exhibitor');
    const isStore = profileInfo.storeType || profileInfo.storeTypes || profileInfo.features?.mobile?.store?.length;

    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [email, setEmail] = useState<string>(() => method === 'Email' ? emailOPhone : null);
    const [phone, setPhone] = useState<string>(() => method === 'Sms' ? emailOPhone : null);
    const [company, setCompany] = useState<string>();
    const [stores, setStores] = useState<string[]>();
    const [companyName, setCompanyName] = useState<string>();
    const [func, setFunc] = useState<string>();

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();

    const wasModified = account && (
        (firstName && firstName != account.firstName) ||
        (lastName && lastName != account.lastName) ||
        (func && func != account.function)
    );

    const handleDone = async () =>
    {
        if (account)
        {
            if (wasModified)
            {
                setSubmitting(true);
                const ok = await onUpdate({
                    firstName: firstName ?? account?.firstName,
                    lastName: lastName ?? account?.lastName,
                    function: func ?? account?.function,
                });
                setSubmitting(false);
                if (!ok)
                {
                    setError('Failed to update');
                }
            }
            else
            {
                onNext();
            }
        }
        else
        {
            setSubmitting(true);
            const err = await onRegister({
                language: user.language,
                country,
                profile,
                email,
                phone,
                firstName,
                lastName,
                company,
                stores,
                companyName,
                function: func,
            });
            setSubmitting(false);
            if (err)
            {
                setError(
                    err === 'company-required' ?
                        t.registration.form.errors.companyRequired
                        :
                        err
                );
            }
        }
    };

    return (
        <>
            <div className={erStyles.title}>
                <img className={erStyles.icon} src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/personal-info.svg' alt=''/>
                <h2>{account ? t.eventRegistration.checkPersonalInfo : t.eventRegistration.personalInfo}</h2>
            </div>
            <Form className={erStyles.content} onSubmit={handleDone}>
                <FormField label={t.eventRegistration.firstName} value={firstName ?? account?.firstName ?? ''} onChangeText={setFirstName} required autoFocus/>
                <FormField label={t.eventRegistration.lastName} value={lastName ?? account?.lastName ?? ''} onChangeText={setLastName} required/>
                <FormField type='email' label='Email' value={email ?? account?.email ?? ''} disabled={!!account || method === 'Email'} onChangeText={setEmail} required/>
                <FormFieldPhone label={t.eventRegistration.phone} value={phone ?? account?.phone ?? ''} disabled={!!account || method === 'Sms'} onChange={setPhone}/>
                {isGuestOrMediaOrMF ?
                    <FormField label={t.global.company} value={companyName ?? account?.companyName ?? ''} disabled={!!account} onChangeText={setCompanyName}/>
                    :
                    isStore ?
                        <SelectCompanyStore
                            country={country}
                            profile={profile}
                            accountCompanies={account?.company}
                            onChange={setStores}
                        />
                        :
                        isHostess ?
                            <SelectCompanyHostess
                                project={project}
                                accountCompanies={account?.company}
                                changed={company}
                                onChange={setCompany}
                            />
                            :
                            isCarrefour ?
                                null
                                :
                                <SelectCompanyExhibitor
                                    project={project}
                                    accountCompanies={account?.company}
                                    changed={company}
                                    onChange={setCompany}
                                />
                }
                {!isStore &&
                    <FormField label={t.eventRegistration.function} value={func ?? account?.function ?? ''} onChangeText={setFunc} required/>
                }
                <div className='text-danger'>{error}</div>
                <div className={erStyles.buttons}>
                    {onBack &&
                        <Button onClick={onBack}>
                            <img className='mr-2' src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/back.svg' alt=''/>
                            {t.eventRegistration.back}
                        </Button>
                    }
                    <Button type='submit' primary className='d-flex justify-content-center align-items-center'>
                        {account ? wasModified ? t.global.update : t.eventRegistration.next : t.eventRegistration.validate}
                        {submitting && <LoadingInline className='ml-2'/>}
                    </Button>
                </div>
            </Form>
        </>
    );
});
