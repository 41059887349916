// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline-invoice .TimelineRow {
  cursor: pointer;
}

.invoiceBody {
  margin-top: 15px;
  border: 1px solid #403d39;
  border-radius: 25px;
  padding: 20px;
}
.invoiceBody p:not(:last-child) {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./frontend/Timeline/TimelineInvoice.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR;;AAGA;EACI,gBAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAAJ;AACI;EACI,mBAAA;AACR","sourcesContent":[".timeline-invoice {\n    .TimelineRow {\n        cursor: pointer;\n    }\n}\n.invoiceBody {\n    margin-top: 15px;\n    border: 1px solid #403d39;\n    border-radius: 25px;\n    padding: 20px;\n    p:not(:last-child) {\n        margin-bottom: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
