import {observer} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import moment from 'moment/moment';
import {IInvoice, invoiceStore} from '../Invoice/InvoiceStore';
import {projectStore} from '../stores/ProjectStore';
import {orderStandStore} from '../OrderStand/OrderStandStore';
import {user} from '../stores/user';
import {discountedOrderTotal, orderProductsTotal} from '../OrderStand/lib';
import {hasNonInvoicedItems} from '../Invoice/utility';
import {capitalize} from '../../lib/common';

interface Props
{
    standId: string
    invoice: IInvoice
    currency: string
}

export default observer(function InvoiceListItem({standId, invoice: i, currency}: Props)
{
    let total = i.total.value;
    if (projectStore.selectedCountry === 'PL')
    {
        total = 0;
        const type = i.status !== 'paid' ? 'approved' : 'invoiced';
        const section = orderStandStore.selected[type];
        if (section.package?.invoice == i.id)
        {
            total += discountedOrderTotal(section.package.price, section.package.discount).total;
        }
        const products = section.products?.filter(p => p.invoice == i.id);
        if (products?.length)
        {
            total += orderProductsTotal(products);
        }
    }
    return (
        <div className='d-flex justify-content-between mb-1'>
            <div>
                <Link to={`/invoice/${standId}/${i.id}`}>{i.number || i.id.split('-')[0]}</Link>
                {` | ${moment(i.date || new Date()).format('DD/MM/YYYY')} | ${user.formatCurrency(currency, total)}`}
            </div>
            {user.moderator && i.status === 'unpaid' && <button className='button-sm-empty' onClick={() => invoiceStore.markAsPaid(i.id)}>Mark as paid</button>}
            {user.moderator && i.status === 'draft' && hasNonInvoicedItems(orderStandStore.selected?.approved)
                && <button className='button-sm-empty' onClick={() => invoiceStore.recollect(i.id, orderStandStore.selectedId)}>Recollect</button>}
            <div>{capitalize(i.status)}</div>
        </div>
    );
});
