import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import ChangeImage from '../common/ChangeImage';
import './ContactProfile.scss';
import { MemoizedProfileItem as ProfileItem } from '../common/ProfileItem';
import { user } from '../stores/user';
import { companyStore } from '../Company/CompanyStore';
import {IContact} from '../stores/userProxy';
import { languageList } from '../data/language';
import {emailStore} from '../Email/EmailStore';
import {openChatWithContacts} from '../Contacts/utility';
import { contactsStore } from './ContactsStore';
import { countryStore } from '../stores/CountryStore';
import {profiles} from '../data/profiles';
import { organizationStore } from '../Dashboard/organizationStore';
import DropdownSearch from '../common/DropdownSearch';
import AddCompanyToUser from './AddCompanyToUser';
import DownloadButton from '../Reports/DownloadButton';
import {badgePdfRes} from '../FairParticipation/badgePdfProxy';
import {projectStore} from '../stores/ProjectStore';
import {t} from '../translations';
import {Country} from '../../graphql/api/Types';
import {pickLanguage} from '../stores/utility';
import {capitalize} from '../../lib/common';

interface Props
{
    id?: string
}

export default observer(function ContactProfile({id}: Props)
{
    const person = !id || id == user.id ? user.loaded ? user.info : null : contactsStore.contacts.find(c => c.id === id);

    useEffect(() =>
    {
        if (person?.company?.length)
        {
            companyStore.loadCompany(person.company);
        }
    }, [id]);

    const handleUpdateName = (objKey, value) =>
    {
        const arr = value.split(' ');
        user.updatePerson(id, {
            ...person,
            firstName: arr[0],
            lastName: arr.length > 2 ? arr.slice(1).join(' ') : arr[1] || ''
        });
    };
    const handleUpdateLang = (e) =>
    {
        user.updatePersonLanguage(id, e.target.value);
    };
    const handleUpdateImage = (url) =>
    {
        user.updatePerson(id, {...person, image: url});
    };

    const handleDeleteImage = () =>
    {
        user.updatePerson(id, {...person, image: ''});
    };

    const validateUser = () =>
    {
        organizationStore.validate(id);
    };

    const sendEmail = () =>
    {
        emailStore.openEmail(contactsStore.contacts.find(c => c.id === id));
    };

    const startChat = () =>
    {
        openChatWithContacts([id]);
    };
    const handleProfile = (profile: string) =>
    {
        user.updatePerson(id, {...person, profiles: [{id: profile}]});
    };
    const handleCountry = (country: Country[]) =>
    {
        if (!country.length)
        {
            alert('Add another country first');
            return;
        }
        user.updatePerson(id, {...person, country});
    };
    const handleEmailOrPhone = (objKey: 'email' | 'phone', value: string) =>
    {
        contactsStore.updateUserEmailOrPhone(value, objKey, id).then(res =>
        {
            if (res)
            {
                alert(`${capitalize(objKey)} changed successfully`);
            }
        });
    };

    const setCompanies = (company: string[]) =>
    {
        user.updatePerson(id, {...person, company});
    };
    const renameCompany = (objKey: string, value: string) =>
    {
        user.updatePerson(id, {...person, companyName: value?.trim() || null});
    };

    const advancedSwitchAccount = () =>
    {
        if (id && id != user.id)
        {
            user.advancedSwitchAccount(id);
        }
    };

    const editable = !id || id == user.id || user.moderator;

    const personProfiles = person.profiles?.map(pp => profiles.find(p => p.id == pp.id)).filter(p => p)
        .map(p => pickLanguage(p.name)).join(',') || 'Exhibitor Key Account';

    const personCountries = person.country?.map(code => countryStore.countries.find(c => c.code == code)).filter(p => p)
        .map(c => pickLanguage(c.name)).join(',') || 'Country';

    const myCompany = (!id || id == user.id ? user.info : contactsStore.contacts.find(c => c.id === id))?.company;
    const myCompanyId = myCompany?.length ? myCompany[0] : null;

    return (
        <div className='contactProfile'>
            <div className='inner'>
                <ChangeImage
                    pic={person.image || 'https://yourfair-pub.s3-eu-west-1.amazonaws.com/assets/userPlaceholder.svg'}
                    onDelete={handleDeleteImage}
                    onUpload={handleUpdateImage}
                    className='profileImage'
                    editable={editable}
                />
                <div className='profileInfo'>
                    <div className='d-flex flex-wrap-reverse justify-content-between'>
                        <div className='name d-flex flex-wrap align-items-center'>
                            <ProfileItem
                                content={(person.firstName || 'Unnamed') + ' ' + (person.lastName || '')}
                                objKey='name'
                                className='mr-4'
                                onChanged={handleUpdateName}
                                editable={editable}
                            />
                            {!!id && id !== user.id && !user.isHostess &&
                            <div>
                                {user.moderator && <FA name='envelope' className='mr-4 cursor-pointer' onClick={sendEmail}/>}
                                <FA name='comments-alt' className='fad cursor-pointer' onClick={startChat}/>
                            </div>}
                        </div>
                        <div className='d-flex flex-wrap align-items-start justify-content-end flex-grow-1'>
                            {user.moderator &&
                            <>
                                {(user.admin && id && id != user.id) &&
                                    <button className='button ml-3 mb-3' onClick={advancedSwitchAccount}>Switch To</button>}
                                {(person as IContact).validated === false &&
                                    <button className='button ml-3 mb-3' onClick={validateUser}>Validate</button>}
                                <AddCompanyToUser person={person} userId={id}/>
                            </>}
                            {!projectStore.isFRNonFood && !!person.badges?.find(b => b.project == projectStore.id)?.registrationId &&
                            <DownloadButton
                                getter={p => badgePdfRes(p, id || user.id)}
                                downloadText={t.fairParticipation.downloadBadgePDF}
                                addDate
                                filename={`${person.firstName}_${person.lastName}_Badge`}
                                type='pdf'
                                className='ml-3 mb-3'
                            />}
                            {!!myCompanyId &&
                            <Link
                                to={'/company/' + myCompanyId}
                                className='float-right button dashboard-company-link ml-3'
                            >
                                {t.global.myCompany}
                            </Link>}
                        </div>
                    </div>
                    <div className='d-flex flex-wrap align-items-center'>
                        {user.moderator ?
                            <>
                                <FA name={'address-card'} className='fad mr-2'/>
                                <DropdownSearch
                                    className='companyProfile'
                                    options={
                                        profiles.filter(p => person.country?.some(uc => p.countries.includes(uc)))
                                            .map(c => ({value: c.id, name: pickLanguage(c.name)}))
                                    }
                                    onSelect={handleProfile}
                                    selected={person.profiles?.[0]?.id}
                                    defaultValue={'Exhibitor'}
                                />
                                <DropdownSearch
                                    className='companyCountry'
                                    options={countryStore.countryOptions}
                                    onChange={handleCountry}
                                    selected={person.country}
                                />&#44;
                            </>
                            :
                            <>
                                <ProfileItem
                                    content={`${personProfiles}, ${personCountries}`}
                                    editable={false}
                                    icon='address-card'
                                    className={'status'}
                                />&#44;
                            </>
                        }
                        <select className='contactLang' onChange={handleUpdateLang} value={person.language || 'en'}>
                            {languageList.map(l => (
                                <option key={l} value={l}>{l}</option>
                            ))}
                        </select>
                    </div>
                    <ContactCompanies companies={person.company} setCompanies={setCompanies}/>
                    {person.companyName &&
                    <ProfileItem
                        content={person.companyName}
                        editable={user.moderator}
                        icon='city'
                        className='user-company'
                        onChanged={renameCompany}
                    >
                        {user.moderator && <span className='remove-company' onClick={() => renameCompany(null, null)}>X</span>}
                    </ProfileItem>
                    }
                    <div className='d-flex flex-wrap'>
                        <ProfileItem
                            content={person.email}
                            editable={user.moderator}
                            slickToCopy
                            objKey={'email'}
                            icon='envelope'
                            className='mr-5'
                            onChanged={handleEmailOrPhone}
                        />
                        <ProfileItem
                            content={person.phone}
                            editable={user.moderator}
                            slickToCopy
                            objKey={'phone'}
                            icon='phone'
                            onChanged={handleEmailOrPhone}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

const ContactCompanies = observer(function ContactCompanies({companies, setCompanies}: {companies: string[], setCompanies: (companies: string[]) => void})
{
    const [draggedIndex, setDraggedIndex] = useState<number>();
    const [hoveredIndex, setHoveredIndex] = useState<number>();
    if (!companies)
    {
        return null;
    }
    return (
        <>
            {companies.map((cid, i) =>
                <div
                    key={cid}
                    className={'user-company' + (i == hoveredIndex ? ' dropHover' : '')}
                    draggable
                    onDragStart={user.moderator ? () =>
                    {
                        setDraggedIndex(i);
                    } : null}
                    onDragEnd={user.moderator ? () =>
                    {
                        setDraggedIndex(null);
                        setHoveredIndex(null);
                    } : null}
                    onDragEnter={user.moderator ? () =>
                    {
                        setHoveredIndex(i);
                    } : null}
                    onDragExit={user.moderator ? () =>
                    {
                        setHoveredIndex(null);
                    } : null}
                    onDragOver={user.moderator ? e =>
                    {
                        e.preventDefault();
                    } : null}
                    onDrop={user.moderator ? e =>
                    {
                        e.preventDefault();
                        if (draggedIndex != i && draggedIndex != i + 1)
                        {
                            const updatedCompanies = companies.slice();
                            updatedCompanies.splice(draggedIndex > i ? i + 1 : i, 0, updatedCompanies.splice(draggedIndex, 1)[0]);
                            setCompanies(updatedCompanies);
                        }
                    } : null}
                >
                    <FA name='city' className='fad mr-2'/>
                    <Link to={`/company/${cid}`}>{companyStore.companies.find(c => c.id === cid)?.name || 'Company'}</Link>
                    {user.moderator &&
                        <span
                            className='remove-company'
                            onClick={() =>
                            {
                                if (confirm("Are you sure you want to remove this company from this user's profile?"))
                                {
                                    const updatedCompanies = companies.slice();
                                    updatedCompanies.splice(i, 1);
                                    setCompanies(updatedCompanies);
                                }
                            }}
                        >
                            X
                        </span>
                    }
                </div>
            )}
        </>
    );
});
