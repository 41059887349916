
export interface UploadOptions
{
    method?: string
    url: string
    file: File | Blob
    name?: string
    asAttachment?: boolean
    headers?: {[header: string]: string}
    onUploaded?(): void
    onError?(e): void
    onProgress?(progress: number): void
}

export function uploadFile(o: UploadOptions)
{
    const xhr = new XMLHttpRequest();
    if (o.onUploaded)
    {
        xhr.upload.addEventListener('load', o.onUploaded);
    }
    if (o.onError)
    {
        xhr.addEventListener('error', o.onError);
        xhr.addEventListener('abort', o.onError);
        xhr.addEventListener('timeout', o.onError);
    }
    if (o.onProgress)
    {
        const reportProgress = e => {o.onProgress(e.loaded / e.total)};
        xhr.upload.addEventListener('loadstart', reportProgress);
        xhr.upload.addEventListener('progress', reportProgress);
    }
    xhr.open(o.method || 'PUT', o.url, true);
    if (o.headers)
    {
        for (const h in o.headers)
        {
            xhr.setRequestHeader(h, o.headers[h]);
        }
    }
    else
    {
        xhr.setRequestHeader('Content-Type', o.file.type);
        const name = o.name || ('name' in o.file ? o.file.name : null);
        xhr.setRequestHeader('Content-Disposition', (o.asAttachment ? 'attachment' : 'inline') + (name ? `;filename="${encodeURIComponent(name)}"` : ''));
    }
    xhr.send(o.file);
    return xhr;
}
