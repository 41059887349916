import React, {useState} from 'react';
import {LoginChildProps} from './LoginBox';
import {t} from '../translations';
import {Confirm} from './Buttons';
import BlueBox from './BlueBox';

export default function PickAccount({accounts, onSelectAccount, onSelectNewAccount}: LoginChildProps)
{
    const [selectedAccount, setSelectedAccount] = useState<string>();
    return (
        <BlueBox>
            <div className='PickAccount'>
                <p>{t.authentication.selectAccount}</p>
                {accounts?.map(a =>
                    <button
                        key={a.id}
                        className={'account-option button' + (selectedAccount === a.id ? ' selected' : '')}
                        onClick={() => setSelectedAccount(a.id)}
                    >
                        {a.name}
                    </button>
                )}
                <button
                    className={'account-option button mb-3' + (selectedAccount === null ? ' selected' : '')}
                    onClick={() => setSelectedAccount(null)}
                >
                    {t.authentication.dontSeeMyName}
                </button>
                {selectedAccount !== undefined &&
                <Confirm
                    onClick={() =>
                    {
                        if (selectedAccount)
                        {
                            onSelectAccount?.(selectedAccount);
                        }
                        else
                        {
                            onSelectNewAccount?.();
                        }
                    }}
                />
                }
            </div>
        </BlueBox>
    );
}
