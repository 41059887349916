// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderStandTitle {
  border-radius: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  background-color: #f7f6f6;
  margin-bottom: 30px;
}

.StandFloorMap {
  display: inline-block;
  border: 2px solid #e0e0e0;
  width: 316px;
  min-width: 316px;
  max-width: 316px;
  height: 130px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.OrderStandOptionsTabs {
  display: flex;
}
.OrderStandOptionsTabs a {
  flex: 1;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  background: transparent;
  border-radius: 5px;
  color: #403d39;
  font-size: 16px;
  line-height: 40px;
  margin-right: 26px;
  margin-bottom: 26px;
  border: 2px solid #d0cfce;
  width: 180px;
}
.OrderStandOptionsTabs a.active {
  color: #fff;
  background-color: #3d8af7;
  border-color: #3d8af7;
}

.OrderStandOptionsPleaseSelect {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  color: #7a7a7a;
  background-color: #fefce0;
  padding: 0.5em 1em;
}
.OrderStandOptionsPleaseSelect .fa {
  margin-right: 0.5em;
}`, "",{"version":3,"sources":["webpack://./frontend/OrderStand/OrderStand.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mCAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,qBAAA;EACA,yBAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,4BAAA;EACA,2BAAA;EACA,wBAAA;AACF;;AAEA;EACE,aAAA;AACF;AAAE;EACE,OAAA;EACA,qBAAA;EACA,kBAAA;EACA,qBAAA;EACA,mCAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;AAEJ;AADI;EACE,WAAA;EACA,yBAAA;EACA,qBAAA;AAGN;;AAEA;EACE,oCAAA;EACA,eAAA;EACA,cAAA;EACA,yBAAA;EACA,kBAAA;AACF;AAAE;EACE,mBAAA;AAEJ","sourcesContent":[".OrderStandTitle {\n  border-radius: 5px;\n  font-family: Montserrat, sans-serif;\n  font-size: 20px;\n  line-height: 40px;\n  text-align: center;\n  background-color: #f7f6f6;\n  margin-bottom: 30px;\n}\n\n.StandFloorMap {\n  display: inline-block;\n  border: 2px solid #e0e0e0;\n  width: 316px;\n  min-width: 316px;\n  max-width: 316px;\n  height: 130px;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n}\n\n.OrderStandOptionsTabs {\n  display: flex;\n  a {\n    flex: 1;\n    display: inline-block;\n    text-align: center;\n    text-decoration: none;\n    font-family: Montserrat, sans-serif;\n    background: transparent;\n    border-radius: 5px;\n    color: #403d39;\n    font-size: 16px;\n    line-height: 40px;\n    margin-right: 26px;\n    margin-bottom: 26px;\n    border: 2px solid #d0cfce;\n    width: 180px;\n    &.active {\n      color: #fff;\n      background-color: #3d8af7;\n      border-color: #3d8af7;\n    }\n  }\n}\n\n.OrderStandOptionsPleaseSelect {\n  font-family: 'Open Sans', sans-serif;\n  font-size: 18px;\n  color: #7a7a7a;\n  background-color: #fefce0;\n  padding: .5em 1em;\n  .fa {\n    margin-right: .5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
