import React, {useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import './FileBlock.scss';
import DropZone from '../Email/DropZone';
import {Upload, uploadStore} from '../Upload/UploadStore';
import {fontSizes} from './ContentBlock';
import {objectUrlForBase64} from '../common/blobUtility';
import {parseDataUrl} from '../../lib/dataUrl';
import {pageStore} from './PageStore';
import BlockOptions from './BlockOptions';
import {ITranslations} from '../../graphql/api/Types';
import {user} from '../stores/user';
import {deepSet} from '../common/deepSet';
import {CommonBlockProps} from './blockMap';
import EmptyBlock from './EmptyBlock';
import {pickLanguage} from '../stores/utility';
import {UploadProgressBar} from './UploadProgressBar';
import {t} from '../translations';
import {uploadAndConvert} from './uploadAndConvert';
import useReducedFontSize from './useReducedFontSize';

export interface FileBlockProps extends CommonBlockProps
{
    published?: boolean
    text?: {
        display?: boolean
        title?: ITranslations
        fontSize?: number
        color?: string
    }
    image?: {
        display?: boolean
        url?: ITranslations
    }
    background?: {
        display?: boolean
        color: string
    }
    file?: ITranslations
}

export default observer(function FileBlock(props: FileBlockProps)
{
    const {
        pageId, id, published, text, background, file, imported, layout, fullAccess, editAccess, enableBlockOptions, placeholderText, placeholderImage
    } = props;
    let {image} = props;
    const [showOptions, setShowOptions] = useState(false);

    const [tempFile, setTempFile] = useState<string>();
    const [upload, setUpload] = useState<Upload>();
    const [tempName, setTempName] = useState<string>();
    const url = tempFile || pickLanguage(file);

    const [uploadImage, setUploadImage] = useState<Upload>();
    const imageUrl = pickLanguage(image?.url);

    const handleFile = (source: File) =>
    {
        const reader = new FileReader();
        reader.onloadend = () =>
        {
            const data = parseDataUrl(reader.result as string);
            setTempFile(objectUrlForBase64(data.content, data.contentType));
            setTempName(source.name);
        };
        reader.readAsDataURL(source);
        uploadStore.upload(source, u =>
        {
            setTempFile(null);
            setTempName(null);
            pageStore.changeItem(pageId, id, 'file', {...file, [user.language]: u.url});
        }, {dir: 'block/file', asAttachment: true}).then(setUpload);
    };

    const handleImage = (source: File) =>
    {
        let name = source.name;
        if (!name.endsWith('.jpg'))
        {
            name = name.split('.')[0] + '.jpg';
        }
        uploadAndConvert({source, name, dir: 'block/file/image', onUploadStart: setUploadImage, onResizeEnd: resizedRes =>
            {
                setUploadImage(null);
                image = image || {};
                image.url = image.url || {};
                image.url[user.language] = resizedRes;
                pageStore.changeItem(pageId, id, 'image', image);
            }
        });
    };

    const fileInputRef = useRef<HTMLInputElement>();
    const colorRef = useRef<HTMLInputElement>();
    const backgroundColorRef = useRef<HTMLInputElement>();
    const imageRef = useRef<HTMLInputElement>();

    const handleText = (path: string[]) => e =>
    {
        const obj = props[path[0]] || {};
        deepSet(obj, path.slice(1), e.target.value);
        pageStore.changeItem(pageId, id, path[0], obj);
    };
    const handleCheckbox = (path: string[]) => e =>
    {
        const obj = props[path[0]] || {};
        deepSet(obj, path.slice(1), e.target.checked);
        pageStore.changeItem(pageId, id, path[0], obj);
    };

    const textTitle = text?.display ? pickLanguage(text?.title) : null
    const {textRef, fontSize} = useReducedFontSize({
        textTitle,
        definedFontSize: +text?.fontSize ? +text.fontSize : 20,
    });

    return (
        <div
            tabIndex={0}
            className={'FileBlock' + (!text?.display ? ' border d-flex justify-content-center align-items-center' : '')}
            style={{
                backgroundImage: imageUrl && image?.display ? `url(${pickLanguage(image.url)})` : null,
                backgroundColor: background?.display ? background?.color || '#ffffff' : null,
            }}
            onClick={showOptions && !imported ? () => fileInputRef.current.click() : null}
            onDoubleClick={enableBlockOptions ? () =>
            {
                setShowOptions(true);
                pageStore.focusItem(pageId, id);
            } : null}
            onBlur={enableBlockOptions ? e =>
            {
                if (e.relatedTarget ?
                    !e.currentTarget.contains(e.relatedTarget as any) :
                    e.currentTarget == e.target || !e.currentTarget.contains(e.target as any)
                )
                {
                    setShowOptions(false);
                }
            } : null}
        >
            <a
                ref={textRef}
                href={!enableBlockOptions && url ? url : null}
                download={tempName}
                target='_blank'
                className='text'
                style={{
                    color: text?.color || '#111111',
                    fontSize: fontSize + 'px',
                }}
            >
                {textTitle}
            </a>
            {!!upload && !upload.ended && <UploadProgressBar progress={upload.progress}/>}
            {!!uploadImage && <UploadProgressBar text={uploadImage.ended ? t.editPage.pleaseWait : null} progress={uploadImage.progress}/>}
            {editAccess && !text?.display && !image?.display && !background?.display &&
            <EmptyBlock
                layout={layout}
                name={t.editPage.blockFileDownload}
                msg={showOptions && t.editPage.DragDropUpload}
                text={placeholderText}
                image={placeholderImage}
            />}
            {enableBlockOptions &&
            <DropZone onAttachment={handleFile} onlySingleFile>{' '}</DropZone>}
            {!imported && enableBlockOptions &&
            <input ref={fileInputRef} className='d-none' type='file' onChange={e => e.target.files.length && handleFile(e.target.files[0])}/>}
            {enableBlockOptions && showOptions &&
            <BlockOptions pageId={pageId} itemId={id} enableBlockOptions={enableBlockOptions} fullAccess={fullAccess}>
                <div>
                    <div className='form-check form-check-inline mb-3'>
                        <input
                            id='options-text'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!text?.display}
                            onChange={handleCheckbox(['text', 'display'])}
                        />
                        <label htmlFor='options-text' className='form-check-label font-weight-bold'>{t.editPage.text}</label>
                    </div>
                    <textarea
                        className='form-control mb-3'
                        value={text?.title?.[user.language] || ''}
                        onChange={handleText(['text', 'title', user.language])}
                    />
                    <div className='row no-gutters mb-3'>
                        <div className='col-6 pr-2'>
                            <select className='form-control form-control-sm' value={text?.fontSize || 20} onChange={handleText(['text', 'fontSize'])}>
                                {!!text?.fontSize && !fontSizes.includes(text.fontSize) &&
                                <option value={text.fontSize}>{text.fontSize}</option>
                                }
                                {fontSizes.map(s => <option key={s} value={s}>{s}</option>)}
                            </select>
                        </div>
                        <div className='col-6 pl-2'>
                            <input
                                type='text'
                                className='form-control form-control-sm'
                                value={text?.color || '#111111'}
                                onChange={handleText(['text', 'color'])}
                                onClick={() => colorRef.current.click()}
                            />
                            <input
                                ref={colorRef}
                                type='color'
                                className='d-none'
                                value={text?.color || '#111111'}
                                onChange={handleText(['text', 'color'])}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className='form-check form-check-inline mb-3'>
                        <input
                            id='options-image'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!image?.display}
                            onChange={handleCheckbox(['image', 'display'])}
                        />
                        <label htmlFor='options-image' className='form-check-label font-weight-bold'>{t.editPage.image}</label>
                    </div>
                    {!!image?.display &&
                    <div className='mb-3'>
                        <button className='button-sm' onClick={() => imageRef.current.click()}>{t.editPage.selectImage}</button>
                        <input ref={imageRef} className='d-none' type='file' accept='image/*' onChange={e => e.target.files.length && handleImage(e.target.files[0])}/>
                    </div>}
                    <div className='form-check form-check-inline'>
                        <input
                            id='options-bgc'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!background?.display}
                            onChange={handleCheckbox(['background', 'display'])}
                        />
                        <label htmlFor='options-bgc' className='form-check-label font-weight-bold'>{t.editPage.backgroundColor}</label>
                    </div>
                    <div>
                        <input
                            type='text'
                            className='form-control form-control-sm'
                            value={background?.color || '#ffffff'}
                            onChange={handleText(['background', 'color'])}
                            onClick={() => backgroundColorRef.current.click()}
                        />
                        <input
                            ref={backgroundColorRef}
                            type='color'
                            className='d-none'
                            value={background?.color || '#ffffff'}
                            onChange={handleText(['background', 'color'])}
                        />
                    </div>
                </div>
                <div>
                    <div className='form-check form-check-inline mb-3'>
                        <input
                            id='options-published'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!published}
                            onChange={e => pageStore.changeItem(pageId, id, 'published', e.target.checked)}
                        />
                        <label htmlFor='options-published' className='form-check-label font-weight-bold'>{t.editPage.published}</label>
                    </div>
                    {fullAccess &&
                    <button
                        className='button mb-3'
                        onClick={() =>
                        {
                            if (confirm('Do you want to delete this block?'))
                            {
                                pageStore.deleteItem(pageId, id);
                            }
                        }}
                    >
                        {t.editPage.deleteContent}
                    </button>}
                </div>
            </BlockOptions>
            }
        </div>
    );
});
