// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-stand-checkout {
  color: #707070;
  display: flex;
  flex-direction: column;
  padding-bottom: 5em;
}
.order-stand-checkout .stand-section-title {
  font-size: 18px;
}
.order-stand-checkout .stand-section-body {
  background-color: #f7f6f6;
  border-radius: 5px;
}
.order-stand-checkout .extras {
  margin-top: 25px;
}
.order-stand-checkout .extras div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.order-stand-checkout .extras div span:first-child {
  font-weight: 800;
  color: #403d39;
}
.order-stand-checkout .checkout-terms > div {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.order-stand-checkout .checkout-terms .checkbox {
  width: 25px;
  padding-top: 2px;
}
.order-stand-checkout .checkout-terms label {
  max-width: calc(100% - 25px);
  margin-bottom: 0;
}
.order-stand-checkout .checkout-submit-button {
  width: 100%;
  border: none;
  text-decoration: none;
  color: #fff;
  background-color: #3d8af7;
  font-size: 1.25em;
  padding: 10px 0;
  outline: none;
  border-radius: 5px;
}
.order-stand-checkout div label {
  color: #403d39;
}
.order-stand-checkout div input#po-attach {
  border: 1px solid #403d39;
  border-radius: 5px;
  padding-left: 5px;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./frontend/OrderStand/OrderStandCheckout.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAIE;EACE,eAAA;AAFJ;AAIE;EACE,yBAAA;EACA,kBAAA;AAFJ;AAOE;EACE,gBAAA;AALJ;AAMI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAJN;AAMQ;EACE,gBAAA;EACA,cAAA;AAJV;AAUI;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;AARN;AAUI;EACE,WAAA;EACA,gBAAA;AARN;AAUI;EACE,4BAAA;EACA,gBAAA;AARN;AAWE;EACE,WAAA;EACA,YAAA;EACA,qBAAA;EACA,WAAA;EACA,yBAAA;EACA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,kBAAA;AATJ;AAYI;EACE,cAAA;AAVN;AAYI;EACE,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AAVN","sourcesContent":[".order-stand-checkout {\n  color: #707070;\n  display: flex;\n  flex-direction: column;\n  padding-bottom: 5em;\n  //span,\n  //ul li {\n  //  font-size: 0.9em;\n  //}\n  .stand-section-title {\n    font-size: 18px;\n  }\n  .stand-section-body {\n    background-color: #f7f6f6;\n    border-radius: 5px;\n    * {\n      //font-size: 14px;\n    }\n  }\n  .extras {\n    margin-top: 25px;\n    div {\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between;\n      span {\n        &:first-child {\n          font-weight: 800;\n          color: #403d39;\n        }\n      }\n    }\n  }\n  .checkout-terms {\n    > div {\n      display: flex;\n      align-items: center;\n      margin-bottom: 1.5rem;\n    }\n    .checkbox {\n      width: 25px;\n      padding-top: 2px;\n    }\n    label {\n      max-width: calc(100% - 25px);\n      margin-bottom: 0;\n    }\n  }\n  .checkout-submit-button {\n    width: 100%;\n    border: none;\n    text-decoration: none;\n    color: #fff;\n    background-color: #3d8af7;\n    font-size: 1.25em;\n    padding: 10px 0;\n    outline: none;\n    border-radius: 5px;\n  }\n  div {\n    label {\n      color:#403d39;\n    }\n    input#po-attach {\n      border: 1px solid #403d39;\n      border-radius: 5px;\n      padding-left: 5px;\n      max-width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
