import React from 'react';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import './SupportChatButton.scss';
import {chatStore} from './ChatStore';
import {t} from '../translations';

export default observer(function SupportChatButton()
{
    const [disabled, setDisabled] = React.useState(false);

    const handleClick = () =>
    {
        chatStore.openSupportChat();
        setDisabled(true);
        setInterval(() => setDisabled(false), 1500);
    }

    return (
        <button className='SupportChatButton box-shadow' onClick={handleClick} disabled={disabled}>
            <FA name='comments-alt' className='fad'/>
            {t.global.supportChatTitle}
        </button>
    );
});
