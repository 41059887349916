import React from 'react';
import {observer} from 'mobx-react-lite';
import {user} from '../stores/user';
import {t} from '../translations';
import {Country} from '../../graphql/api/Types';

interface Props
{
    isTotal?: boolean
    storeType: string
    isFRNonFood: boolean
    isFair: boolean
    country: Country
    currency: string
    invoice: number
    selling: number
    // FR Non Food
    flow: 'GSE' | 'GSA' | undefined
}

export default observer(function OrderItemTotal({isTotal, storeType, country, isFRNonFood, isFair, currency, invoice, selling, flow}: Props)
{
    const totalText = isTotal ? t.reports.total + ' ' : '';
    if (country == 'FR')
    {
        return !!selling && (
            isFRNonFood ?
                'CPI' == storeType ?
                    <p className='text-right'>
                        <b>{totalText + (flow == 'GSA' ? 'FOB' : 'Px Cession') + ' : '}</b>
                        <b>{user.formatCurrency(currency, invoice)}</b>
                    </p>
                    :
                    <>
                        <p className='text-right'>
                            <b>{totalText + 'Px Cession : '}</b>
                            <b>{user.formatCurrency(currency, invoice)}</b>
                        </p>
                        <p className='text-right'>
                            <b>{totalText + 'PVC : '}</b>
                            <b>{user.formatCurrency(currency, selling)}</b>
                        </p>
                    </>
                :
                <p className='text-right'>
                    <b>{totalText + 'PVC : '}</b>
                    <b>{user.formatCurrency(currency, selling)}</b>
                </p>
        );
    }
    if (country == 'BE')
    {
        const orderTotalCodeName = isFair ?
            ['MF', 'E'].includes(storeType) ? 'PF' :
                ['H', 'MI'].includes(storeType) ? 'PR' : 'PV' :
            ['H', 'MI'].includes(storeType) ? 'PRP' : 'PFP';
        return (
            <p className='text-right'>
                <span>{totalText + orderTotalCodeName + ': '}</span>
                <b>{user.formatCurrency(currency, invoice)}</b>
            </p>
        );
    }
    if (country == 'PL')
    {
        return (
            <p className='text-right'>
                <span>{(isTotal ? t.cart.totalInvoicePrice : t.reports.total) + ': '}</span>
                <b>{user.formatCurrency(currency, invoice)}</b>
            </p>
        );
    }
    // IT
    return null;
});
