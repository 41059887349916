
export function arrayMove<T>(array: T[], from: number, to: number)
{
    array = [...array];
    if (to < 0)
    {
        to = array.length + to;
    }
    if (to >= 0 && to < array.length)
    {
        const item = array[to];
        array[to] = array[from];
        array[from] = item;
    }
    return array;
}
