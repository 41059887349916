import {ReactNode, useEffect, useState} from 'react';

export default function RefreshEverySecond({children}: {children: () => ReactNode})
{
    const [, setRefresh] = useState(0);
    useEffect(() =>
    {
        const interval = setInterval(() =>
        {
            setRefresh(v => v + 1);
        }, 1000);
        return () =>
        {
            clearInterval(interval);
        };
    }, []);
    return children() as any;
}
