import {makeObservable, observable} from 'mobx';
import {call, gql} from '../client';

function loadCompanyNamesByCountry()
{
    return call<{addCompanyList: AddCompanyItem[]}>(gql`{addCompanyList{id,country,name,storeId,projects}}`)
    .then(({data}) => data?.addCompanyList);
}

interface AddCompanyItem
{
    id: string
    country: string[]
    name: string
    storeId: string
    projects: string[]
}

class AddCompanyStore
{
    @observable companies: AddCompanyItem[] = [];

    constructor()
    {
        makeObservable(this);
    }

    load()
    {
        loadCompanyNamesByCountry().then(companies =>
        {
            if (companies)
            {
                this.companies = companies;
            }
        });
    }
}

export const addCompanyStore = new AddCompanyStore();
