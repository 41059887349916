import React from 'react';
import moment from 'moment';
import './TimelinePostSeparator.scss';
import {observer} from 'mobx-react-lite';
import {user} from '../stores/user';

interface Props
{
    date: Date | string
}

export default observer(function TimelinePostSeparator(p: Props)
{
    user.language;
    return (
        <div className='TimelinePostSeparator'>{moment(p.date).format('dddd DD MMMM YYYY')}</div>
    );
});
