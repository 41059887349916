import {IPost, IPostFromTo} from './PostView';

export interface WithUpdated
{
    updated?: any
}
export type WithoutUpdated<T> = Omit<T, 'updated'>
export type IPostInput = WithoutUpdated<IPost>
export type IPostFromToInput = WithoutUpdated<IPostFromTo>

export function withoutUpdated<T extends WithUpdated>(obj: T): WithoutUpdated<T>
{
    const res = Object.assign({}, obj);
    delete res.updated;
    return res;
}
