// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  color: black;
}
.checkout-title * {
  font-size: 18px;
}

.OrderStandCheckoutCategory {
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 20px;
}
.OrderStandCheckoutCategory * {
  font-family: "Open Sans", sans-serif;
}
.OrderStandCheckoutCategory .category-name {
  font-weight: 800;
  color: #666360;
  margin: 0;
}
.OrderStandCheckoutCategory .subtotal-price {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
}
.OrderStandCheckoutCategory .subtotal-price .total-name {
  font-weight: 800;
  color: #666360;
}
.OrderStandCheckoutCategory .subtotal-price .total-price {
  min-width: 90px;
  padding-left: 15px;
  text-align: right;
}

.CheckoutBottomLine {
  color: #fff;
  background: #3d8af7;
  border-radius: 5px;
  padding: 10px 10px 8px;
}
.CheckoutBottomLine * {
  font-family: Montserrat, sans-serif;
}
.CheckoutBottomLine .text {
  font-size: 16px;
}
.CheckoutBottomLine .total {
  font-size: 18px;
}

.checkout-inner {
  margin-bottom: 150px;
}`, "",{"version":3,"sources":["webpack://./frontend/OrderStand/OrderStandCheckoutItem.scss"],"names":[],"mappings":"AACA;EACE,2CAAA;EACA,YAAA;AAAF;AACE;EACE,eAAA;AACJ;;AAGA;EAIE,eAAA;EACA,iBAAA;EACA,oBAAA;AAHF;AAFE;EACE,oCAAA;AAIJ;AACE;EACE,gBAAA;EACA,cAAA;EACA,SAAA;AACJ;AACE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AACJ;AAAI;EACE,gBAAA;EACA,cAAA;AAEN;AAAI;EACE,eAAA;EACA,kBAAA;EACA,iBAAA;AAEN;;AAGA;EAIE,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;AAHF;AAHE;EACE,mCAAA;AAKJ;AACE;EACE,eAAA;AACJ;AACE;EACE,eAAA;AACJ;;AAGA;EACE,oBAAA;AAAF","sourcesContent":["\n.checkout-title {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.3);\n  color: black;\n  * {\n    font-size: 18px;\n  }\n}\n\n.OrderStandCheckoutCategory {\n  * {\n    font-family: 'Open Sans', sans-serif;\n  }\n  font-size: 14px;\n  padding-top: 15px;\n  padding-bottom: 20px;\n  .category-name {\n    font-weight: 800;\n    color: #666360;\n    margin: 0;\n  }\n  .subtotal-price {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    margin-top: 10px;\n    .total-name {\n      font-weight: 800;\n      color: #666360;\n    }\n    .total-price {\n      min-width: 90px;\n      padding-left: 15px;\n      text-align: right;\n    }\n  }\n}\n\n.CheckoutBottomLine {\n  * {\n    font-family: Montserrat, sans-serif;\n  }\n  color: #fff;\n  background: #3d8af7;\n  border-radius: 5px;\n  padding: 10px 10px 8px;\n  .text {\n    font-size: 16px;\n  }\n  .total {\n    font-size: 18px;\n  }\n}\n\n.checkout-inner {\n  margin-bottom: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
