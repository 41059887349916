import algoliasearch from 'algoliasearch/lite';
import type {MultipleQueriesQuery, Hit, HighlightResult} from '@algolia/client-search';
import {projectStore} from '../stores/ProjectStore';
import {ISearchResults, ISearchUser} from '../../graphql/api/search/Search';
import {AlgoliaTypes} from '../../graphql/api/algolia/AlgoliaTypes';
import {user} from '../stores/user';
import {companyStore} from '../Company/CompanyStore';
import {escapeHtmlTags, flat, isID} from '../../lib/common';
import {algoliaInfoStore, ISearchInfo} from './AlgoliaInfoStore';
import {deepSet, IPath} from '../common/deepSet';

export function getClient()
{
    const init = (info: ISearchInfo) => algoliasearch(info.id, info.key);
    if (algoliaInfoStore.info)
    {
        return init(algoliaInfoStore.info);
    }
    else
    {
        return algoliaInfoStore.load().then(init);
    }
}

function canSearchProducts()
{
    return projectStore.canSeeCatalog && (projectStore.canOrder || projectStore.italyHeadquarters);
}

export async function algoliaSearch(search: string): Promise<ISearchResults>
{
    if (user.moderator)
    {
        return algoliaFullSearch(search);
    }
    if (user.isHostess)
    {
        return algoliaHostessSearch(search);
    }
    if (canSearchProducts())
    {
        return algoliaPagesProductsSearch(search);
    }
    return algoliaPagesSearch(search);
}

async function algoliaFullSearch(search: string): Promise<ISearchResults>
{
    const client = await getClient();
    const projectFilter = 'project:' + projectStore.id;

    const {results: [
        companies,
        invoices,
        pages,
        products,
    ]} = await client.search([
        formatCompanyQuery(search),
        {
            indexName: 'invoices',
            params: {
                query: search,
                facetFilters: projectFilter,
            },
        },
        formatPageQuery(search),
        formatProductQuery(search),
    ]);

    const persons = await searchUsers(search, companies.hits as AlgoliaTypes.Company[]);
    const resPersons = formatPersons(persons, companies.hits as AlgoliaTypes.Company[]);

    const {results: standResults} = await client.search([
        {
            indexName: 'stands',
            params: {
                hitsPerPage: 5,
                query: search,
                facetFilters: projectFilter,
            },
        },
        (companies.hits.length || persons.length) &&
        {
            indexName: 'stands',
            params: {
                hitsPerPage: 5,
                facetFilters: [
                    projectFilter,
                    [
                        ...companies.hits.map(c => 'company:' + c.objectID),
                        ...persons.map(c => 'persons:' + c.objectID),
                    ] as any
                ],
            },
        }
    ].filter(v => v));
    const stands = flat(standResults.map(r => r.hits)).filter((a, index, arr) => arr.findIndex(b => a.objectID == b.objectID) == index) as AlgoliaTypes.Stand[];

    return {
        users: await resPersons,
        companies: companies.hits.map(replaceHighlighted).map(formatCompany),
        stands: (stands.length > 5 ? stands.slice(0, 5) : stands).map(replaceHighlighted).map(formatStand),
        invoices: invoices.hits.map(replaceHighlighted).map(formatInvoice),
        pages: pages.hits.map(replaceHighlighted).map(formatPage),
        products: products?.hits.map(replaceHighlighted).map(formatProduct),
    };
}

async function algoliaPagesProductsSearch(search: string): Promise<ISearchResults>
{
    const client = await getClient();
    const {results: [pages, products]} = await client.search([
        formatPageQuery(search),
        formatProductQuery(search),
    ].filter(v => v));

    return {
        pages: pages.hits.map(replaceHighlighted).map(formatPage),
        products: products?.hits.map(replaceHighlighted).map(formatProduct),
    };
}

async function algoliaPagesSearch(search: string): Promise<ISearchResults>
{
    if (user.isHostess)
    {
        return algoliaHostessSearch(search);
    }

    const client = await getClient();
    const {results} = await client.search([
        formatPageQuery(search),
    ].filter(v => v));

    return {
        pages: results[0].hits.map(replaceHighlighted).map(formatPage),
    };
}

async function algoliaHostessSearch(search: string): Promise<ISearchResults>
{
    const client = await getClient();
    const {results: [companies]} = await client.search([
        formatCompanyQuery(search),
    ]);

    const persons = await searchUsers(search, companies.hits as AlgoliaTypes.Company[]);

    return {
        users: await formatPersons(persons, companies.hits as AlgoliaTypes.Company[]),
        companies: companies.hits.map(replaceHighlighted).map(formatCompany),
    };
}

async function searchUsers(search: string, companies: AlgoliaTypes.Company[])
{
    const userCountryFilters = [user.info.country.map(c => 'country:' + c)];

    const client = await getClient();
    const {results} = await client.search([
        {
            indexName: 'persons',
            params: {
                hitsPerPage: 5,
                query: search,
                facetFilters: userCountryFilters,
            },
        },
        companies.length &&
        {
            indexName: 'persons',
            params: {
                hitsPerPage: 5,
                facetFilters: [
                    companies.map(c => 'company:' + c.objectID)
                ],
            },
        }
    ].filter(v => v));

    const res = flat(results.map(r => r.hits)).filter((a, index, arr) => arr.findIndex(b => a.objectID == b.objectID) == index) as AlgoliaTypes.Person[];
    return (
        res.length > 5 ? res.slice(0, 5) : res
    ).map(replaceHighlighted);
}

async function formatPersons(persons: AlgoliaTypes.Person[], companies: AlgoliaTypes.Company[])
{
    const highlightedCompanyIds = companies.map(c => c.objectID);

    const companyIDByPerson = new Map<AlgoliaTypes.Person, string>();
    for (const i of persons)
    {
        companyIDByPerson.set(i, i.company.length > 1 ? i.company.find(id => highlightedCompanyIds.includes(id)) || i.company[0] : i.company[0]);
    }

    // load company names if we don't already have them
    const companyIdsForPersons = persons.map((i: AlgoliaTypes.Person) => companyIDByPerson.get(i)).filter(id => !highlightedCompanyIds.includes(id));

    const client = await getClient();
    const companiesForPersons = companyIdsForPersons.length ?
        (await client.search([
            formatCompanyByIdsQuery(companyIdsForPersons)
        ])).results[0].hits as AlgoliaTypes.Company[]
        :
        [];

    const companiesHighlighted = companies.map(replaceHighlighted);
    return persons.map(replaceHighlighted).map<ISearchUser>(i =>
    {
        const companyId = companyIDByPerson.get(i);
        return {
            ...i,
            id: i.objectID,
            companyId,
            companyName: companyId ?
                formatCompanyName(
                    companiesHighlighted.find(c => c.objectID == companyId) ||
                    companiesForPersons.find(c => c.objectID == companyId)
                )
                :
                i.companyName,
        };
    });
}

function formatCompanyQuery(search: string): MultipleQueriesQuery
{
    const userCountryFilters = [user.info.country.map(c => 'country:' + c)];

    return {
        indexName: 'companies',
        params: {
            hitsPerPage: 5,
            query: search,
            facetFilters: userCountryFilters,
        },
    };
}

function formatCompanyByIdsQuery(ids: string[]): MultipleQueriesQuery
{
    return {
        indexName: 'companies',
        params: {
            filters: ids.map(id => 'objectID:' + id).join(' OR '),
        },
    };
}

function formatPageQuery(search: string): MultipleQueriesQuery
{
    const projectFilter = 'project:' + projectStore.id;

    return {
        indexName: 'pages',
        params: {
            hitsPerPage: 5,
            query: search,
            facetFilters: user.moderator ? projectFilter : [
                projectFilter,
                'isTemplate:false',
                [
                    'profiles:' + user.profile,
                    'ids:' + user.id,
                ] as any
            ],
        },
    };
}

function formatProductQuery(search: string): MultipleQueriesQuery
{
    // match condition in <App>
    if (!user.moderator && !canSearchProducts())
    {
        return null;
    }

    const projectFilter = 'project:' + projectStore.id;

    return {
        indexName: 'products',
        params: {
            hitsPerPage: 5,
            query: search,
            facetFilters: user.moderator ? projectFilter : [
                projectFilter,
                user.isStore && [
                    ...companyStore.ownStores.map(s => 'storeType:' + s.store.storeType),
                ] as any,
                projectStore.selectedCountry == 'IT' && user.isStore && companyStore.ownStores.some(s => s.store.warehouse) && [
                    ...flat(companyStore.ownStores.filter(s => s.store.warehouse).map(s =>
                                Object.getOwnPropertyNames(s.store.warehouse).map(wName =>
                                    `warehouse.${wName}:${s.store.warehouse[wName]}`
                                )
                            )).filter(v => v)
                ] as any,
                projectStore.selectedCountry == 'FR' && !projectStore.isFRNonFood && user.isStore && companyStore.ownStores.every(s => s.store.regionCode) && [
                    'regionCode:000',
                    ...companyStore.ownStores.map(s => 'regionCode:' + s.store.regionCode)
                ],
                // user.isExhibitor && [
                //     ...flat(companyStore.ownCompanies.map(c => c.exhibitor?.code)).filter(v => v).map(code => 'code:' + code),
                // ] as any,
            ].filter(a => a?.length),
        },
    };
}

const formatCompany = (i: AlgoliaTypes.Company) => ({
    ...i,
    id: i.objectID,
    name: formatCompanyName(i),
});

const formatCompanyName = (c: AlgoliaTypes.Company) => c && c.name;

const formatStand = (i: AlgoliaTypes.Stand) => ({
    ...i,
    id: i.objectID,
});

const formatInvoice = (i: AlgoliaTypes.Invoice) => ({
    ...i,
    id: i.objectID,
});

const formatPage = (i: AlgoliaTypes.Page) => ({
    ...i,
    id: i.objectID,
});

const formatProduct = (i: AlgoliaTypes.Product) => ({
    id: i.objectID,
    description: i.name,
});

function replaceHighlighted<T, H extends Hit<T>>(hit: H): H
{
    if (hit._highlightResult)
    {
        const highlightMatches = findHighlightMatches(hit._highlightResult);
        if (highlightMatches.length)
        {
            for (const h of highlightMatches)
            {
                if (!isID(deepGet(hit, h.path)))
                {
                    deepSet(hit, h.path, escapeHtmlTagsExceptEM(h.match.value));
                }
            }
        }
    }
    return hit;
}

function findHighlightMatches(highlightResult: HighlightResult<any>, path: IPath = [])
{
    const highlightMatches: {path: IPath, match: HighlightResult<string | number>}[] = [];
    if (isHighlightMatch(highlightResult))
    {
        if (highlightResult.matchLevel != 'none')
        {
            highlightMatches.push({path, match: highlightResult});
        }
    }
    else if (Array.isArray(highlightResult))
    {
        for (let h = 0; h < highlightResult.length; ++h)
        {
            const subRes = findHighlightMatches(highlightResult[h]);
            if (subRes.length)
            {
                for (const s of subRes)
                {
                    s.path.unshift(...path, h);
                }
                highlightMatches.push(...subRes);
            }
        }
    }
    else
    {
        for (const h of Object.getOwnPropertyNames(highlightResult))
        {
            const subRes = findHighlightMatches(highlightResult[h]);
            if (subRes.length)
            {
                for (const s of subRes)
                {
                    s.path.unshift(...path, h);
                }
                highlightMatches.push(...subRes);
            }
        }
    }
    return highlightMatches;
}

function isHighlightMatch(highlightResult: HighlightResult<any>): highlightResult is HighlightResult<string | number>
{
    return 'value' in highlightResult && 'matchLevel' in highlightResult && 'matchedWords' in highlightResult;
}

function escapeHtmlTagsExceptEM(unsafe: string)
{
    return unsafe && escapeHtmlTags(unsafe).replace(/&lt;em&gt;|&lt;\/em&gt;/g, v => v == '&lt;em&gt;' ? '<em>' : '</em>');
}

function deepGet(obj: {}, path: IPath)
{
    for (let i = 0;; ++i)
    {
        const value = obj[path[i]];
        if (i == path.length - 1)
        {
            return value;
        }
        if (value == null)
        {
            return undefined;
        }
        obj = value;
    }
}
