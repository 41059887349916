import React, {useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import './SlideshowBlock.scss';
import DropZone from '../Email/DropZone';
import {Upload, uploadStore} from '../Upload/UploadStore';
import {base64toBlob} from '../common/blobUtility';
import {parseDataUrl} from '../../lib/dataUrl';
import {pageStore} from './PageStore';
import BlockOptions from './BlockOptions';
import {RotationArrows} from './RotationArrows';
import {Language} from '../../graphql/api/Types';
import {user} from '../stores/user';
import {CommonBlockProps} from './blockMap';
import EmptyBlock from './EmptyBlock';
import {pickLanguage} from '../stores/utility';
import {UploadProgressBar} from './UploadProgressBar';
import {t} from '../translations';
import {logView} from '../stores/logView';
import {useAutoRotate} from './useAutoRotate';
import {convertToPageSize} from './Page';

export interface SlideshowBlockProps extends CommonBlockProps
{
    published?: boolean
    content?: {
        [L in Language]?: {
            pdf?: string
            images?: string[]
        }
    }
}

const minWidth = 820;
const minHeight = 461;

export default observer(function SlideshowBlock(
    {
        pageId, id, published, content, imported, layout, fullAccess, editAccess, enableBlockOptions, placeholderText, placeholderImage, isMobileView
    }: SlideshowBlockProps
)
{
    const [showOptions, setShowOptions] = useState(false);

    const [tempImages, setTempImages] = useState<string[]>();
    const [pdfUploadP, setPdfUpload] = useState<Upload>();
    const [imageUploadsP, setImageUploads] = useState<Upload[]>();
    const languageContent = pickLanguage(content);
    const images = tempImages || languageContent?.images;

    const [index, setIndex] = useAutoRotate(images?.length, isMobileView ? 3000 : 8000);
    const image = images?.[index];

    const handleFile = async (file: File) =>
    {
        const pdfJS = await import('pdfjs-dist/webpack');
        if (file.type != 'application/pdf')
        {
            alert('This file is not a pdf');
            return;
        }
        uploadStore.upload(file, pdfUpload =>
        {
            if (pdfUpload.error)
            {
                alert('Failed to upload PDF');
                return;
            }

            const folder = pdfUpload.url.match(/[^/]*$/)[0].replace(/\.pdf$/i, '');

            const reader = new FileReader();
            reader.onloadend = async () =>
            {
                const rawPdf = new Uint8Array(reader.result as any);
                const pdfDoc = await pdfJS.getDocument(rawPdf).promise;

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const pageImages: string[] = [];

                for (let i = 1; i <= pdfDoc.numPages; i++)
                {
                    const page = await pdfDoc.getPage(i);

                    let viewport = page.getViewport({scale: 1});
                    if (viewport.width < minWidth || viewport.height < minHeight)
                    {
                        viewport = page.getViewport({scale: Math.max(minWidth / viewport.width, minHeight / viewport.height)});
                    }
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;

                    await page.render({canvasContext: ctx, viewport}).promise;

                    pageImages.push(canvas.toDataURL('image/jpeg'));
                }

                let unfinishedUploads = pageImages.length;
                const imageUploads = await Promise.all(pageImages.map((pageImage, i) =>
                {
                    const data = parseDataUrl(pageImage);
                    const blob = base64toBlob(data.content, data.contentType);
                    const imageFile = new File([blob], i + '.jpg', {type: data.contentType});
                    return uploadStore.upload(imageFile, () =>
                    {
                        --unfinishedUploads;
                        if (!unfinishedUploads)
                        {
                            setTempImages(null);
                        }
                    }, {dir: 'block/slideshow/images/' + folder, noHashInName: true});
                }));
                setImageUploads(imageUploads);
                setPdfUpload(null);
                pageStore.changeItem(pageId, id, 'content', {
                    ...content, [user.language]: {pdf: pdfUpload.url, images: imageUploads.map(u => u.url)}
                });
                // setTempImages(pageImages);
            };
            reader.readAsArrayBuffer(file);

        }, {dir: 'block/slideshow/pdf', asAttachment: true}).then(setPdfUpload);
    };

    const divRef = useRef<HTMLDivElement>();
    const fileInputRef = useRef<HTMLInputElement>();

    return (
        <div
            ref={divRef}
            tabIndex={0}
            className={'SlideshowBlock RotationArrows-container' + (image ? '' : ' border d-flex justify-content-center align-items-center')}
            style={image ? {backgroundImage: `url(${image})`} : null}
            onClick={showOptions && !imported ? () => fileInputRef.current.click() : null}
            onDoubleClick={enableBlockOptions ? () =>
            {
                setShowOptions(true);
                pageStore.focusItem(pageId, id);
            } : null}
            onBlur={enableBlockOptions ? e =>
            {
                if (e.relatedTarget ?
                    !e.currentTarget.contains(e.relatedTarget as any) :
                    e.currentTarget == e.target || !e.currentTarget.contains(e.target as any)
                )
                {
                    setShowOptions(false);
                }
            } : null}
        >
            {!!images?.length &&
            <img src={index < images.length - 1 ? images?.[index + 1] : images?.[0]} width={0} height={0} alt=''/>}
            {!isMobileView && <RotationArrows index={index} maxIndex={images?.length ? images.length - 1 : 0} setIndex={setIndex}/>}
            {!isMobileView && !!languageContent?.pdf &&
            <div className='download-container'>
                <a
                    href={languageContent?.pdf}
                    target='_blank'
                    className='unstyle-link button'
                    onClick={() => logView({type: 'slideshow-download', project: pageStore.getActivePage(pageId).project, page: pageId, block: id})}
                >
                    {t.timelineInvoice.pdf}
                </a>
            </div>}
            {(
                imageUploadsP?.some(u => !u.ended) &&
                <UploadProgressBar progress={imageUploadsP.reduce((res, u, i, arr) => res + u.progress / arr.length, 0) * .5 + .5}/>
            ) || (
                !!pdfUploadP &&
                <UploadProgressBar progress={pdfUploadP.progress * .5}/>
            )}
            {editAccess && !languageContent &&
            <EmptyBlock
                layout={layout}
                name={t.editPage.blockSlideshow}
                msg={!imported && showOptions && t.editPage.DragDropUpload || 'PDF format 16:9'}
                text={placeholderText}
                image={placeholderImage}
            />}
            {!imported && enableBlockOptions &&
            <DropZone onAttachment={handleFile} onlySingleFile>{' '}</DropZone>}
            {!imported && enableBlockOptions &&
            <input ref={fileInputRef} className='d-none' type='file' accept='application/pdf' onChange={e => e.target.files.length && handleFile(e.target.files[0])}/>}
            {showOptions &&
            <BlockOptions pageId={pageId} itemId={id} enableBlockOptions={enableBlockOptions} fullAccess={fullAccess}>
                <div>
                    <p className='font-weight-bold mb-4'>{t.editPage.blockSlideshow} (PDF, 16:9)</p>
                    <button
                        className='button mb-3'
                        onClick={() =>
                        {
                            const width = divRef.current.offsetWidth;
                            const img = new Image();
                            img.onload = () =>
                            {
                                const heightInUnits = convertToPageSize(img.height * (width / img.width));
                                pageStore.changeItemHeight(pageId, id, heightInUnits);
                            };
                            img.src = image;
                        }}
                    >
                        Auto-size
                    </button>
                </div>
                <div>
                    <div className='form-check form-check-inline mb-3'>
                        <input
                            id='options-published'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!published}
                            onChange={e => pageStore.changeItem(pageId, id, 'published', e.target.checked)}
                        />
                        <label htmlFor='options-published' className='form-check-label font-weight-bold'>{t.editPage.published}</label>
                    </div>
                    {fullAccess &&
                    <button
                        className='button mb-3'
                        onClick={() =>
                        {
                            if (confirm('Do you want to delete this block?'))
                            {
                                pageStore.deleteItem(pageId, id);
                            }
                        }}
                    >
                        {t.editPage.deleteContent}
                    </button>}
                </div>
            </BlockOptions>
            }
        </div>
    );
});
