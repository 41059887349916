import React from 'react';
import {observer} from 'mobx-react-lite';
import './TimelineMessage.scss';
import {user} from '../stores/user';
import {TimelineItem, TimelineRow} from './TimelineItem';
import {formatTimelineDate} from '../common/timeFormatting';

interface TimelineMessageProps
{
    className?: string
    mark?: React.ReactNode // initials
    markStyle?: React.CSSProperties
    name?: React.ReactNode
    message?: React.ReactNode
    unstyledMessage?: React.ReactNode
    date?: Date
    hoverOptions?: [React.ReactNode, () => void][]
    children?: React.ReactNode
    withLine?: boolean
}

export default function TimelineMessage(p: TimelineMessageProps)
{
    const aside = <>
        {!!p.hoverOptions && <div className='options'>{p.hoverOptions.map((o, i) =>
            <a key={i} href='#' onClick={o[1]}>{o[0]}</a>
        )}</div>}
        <div className='info'><ReactiveTimelineDate date={p.date}/></div>
    </>;
    if (p.message || p.unstyledMessage)
    {
        return (
            <TimelineItem
                className={'TimelineMessage ' + (p.className || '')}
                markClassName='TimelineMessageMark'
                markStyle={p.markStyle}
                mark={p.mark}
                withLine={p.withLine}
            >
                <TimelineRow
                    className='name'
                    content={p.name}
                />
                {p.message ? (
                    <TimelineRow
                        className={'msg ' + (p.hoverOptions ? 'hover' : '')}
                        content={p.message}
                        aside={aside}
                    />
                ) : (
                    <TimelineRow
                        className={p.hoverOptions ? 'hover' : ''}
                        content={p.unstyledMessage}
                        aside={aside}
                    />
                )}
                {p.children}
            </TimelineItem>
        );
    }
    return (
        <TimelineItem
            className={'TimelineMessage ' + (p.className || '')}
            markClassName='TimelineMessageMark'
            mark={p.mark}
            withLine={p.withLine}
        >
            <TimelineRow
                className={'name ' + (p.hoverOptions ? 'hover' : '')}
                content={p.name}
                aside={aside}
            />
            {p.children}
        </TimelineItem>
    );
}

const ReactiveTimelineDate = observer(function ReactiveTimelineDate({date}: {date: Date})
{
    user.locale;
    return formatTimelineDate(date) as any;
});
