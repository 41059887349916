import {memo} from 'react';
import styles from './PhoneField.module.css';
import PhoneInput from '../../Landing/PhoneInputLazy';
import type {Props, DefaultInputComponentProps} from 'react-phone-number-input';

export default memo(function PhoneField(props: Props<DefaultInputComponentProps>)
{
    return (
        <PhoneInput
            {...props}
            className={
                props.className ?
                    styles.phone + ' ' + props.className
                    :
                    styles.phone
            }
            numberInputProps={
                props.numberInputProps ?
                    {
                        ...props.numberInputProps,
                        className:
                            (props.numberInputProps as any).className ?
                                styles.phoneInput + ' ' + (props.numberInputProps as any).className
                                :
                                styles.phoneInput,
                    }
                    :
                    {className: styles.phoneInput}
            }
        />
    );
});
