import React from 'react';
import './TimelineItem.scss';

interface TimelineItemProps
{
    className?: string
    markClassName?: string
    markStyle?: React.CSSProperties
    mark?: React.ReactNode // initials
    children?: React.ReactNode
    withLine?: boolean
}

export function TimelineItem(p: TimelineItemProps)
{
    return (
        <div className={'TimelineItem ' + (p.className || '')}>
            <div className={'wrapper' + (p.withLine ? ' withLine' : '')}>
                <div className='TimelineMark'>
                    <div className={p.markClassName} style={p.markStyle}>{p.mark}</div>
                </div>
                {p.children}
            </div>
        </div>
    );
}

interface TimelineRowProps
{
    className?: string
    content?: React.ReactNode
    aside?: React.ReactNode
    onClick?(e: React.MouseEvent<HTMLDivElement>): void
}

export function TimelineRow(p: TimelineRowProps)
{
    return (
        <div className={'TimelineRow ' + (p.className || '')} onClick={p.onClick}>
            <div className='content'>
                {p.content}
            </div>
            <div className='aside'>
                {p.aside}
            </div>
        </div>
    )
}
