import styles from './SelectAccount.module.css';
import Button from '../components/Button';
import {EventUsersListItem} from '../../Event/eventsProxy';
import {getName} from '../../common/getName';
import erStyles from './EventRegistration.module.css';
import {t} from '../../translations';

interface Props
{
    account: EventUsersListItem
    guests: EventUsersListItem[]
    onSelectGuest: (account: EventUsersListItem) => void
    onAddGuest: () => void
    onBack: () => void
}

export default function SelectGuest({account, guests, onSelectGuest, onAddGuest, onBack}: Props)
{
    return (
        <>
            <div className={erStyles.title}>
                <img className={erStyles.icon} src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/select-account-type.svg' alt=''/>
                <h2>Select your guest</h2>
            </div>
            <div className={erStyles.content}>
                <div>Logged in as <b>{getName(account)}</b></div>
                <br/>
                <div className='mt-3 align-items-center'>
                    {guests.map(a =>
                        <Button key={a.id} className={styles.account} onClick={() => onSelectGuest(a)}>{getName(a)}</Button>
                    )}
                </div>
                <div className={erStyles.buttons}>
                    <Button className={styles.account} onClick={onAddGuest}>Add another guest</Button>
                </div>
                <div className={erStyles.buttons}>
                    <Button onClick={onBack}>
                        <img className='mr-2' src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/back.svg' alt=''/>
                        {t.eventRegistration.back}
                    </Button>
                </div>
            </div>
        </>
    );
}
