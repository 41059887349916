import {observer} from 'mobx-react-lite';
import {packageStore} from './PackageStore';
import PackageCard from './PackageCard';
import {IStandSetup, StandType} from '../../graphql/api/stand/Stand';
import {packageContainsSetup} from '../../lib/packageLib';
import {calculatePackagePrice} from './lib';
import {userHasAccessToPkg} from '../OrderStand/packageAccess';
import {user} from '../stores/user';

interface Props
{
    className?: string
    type?: StandType
    selected?: string
    itemPricePreview?: boolean
    setup: IStandSetup
    requireContainsSetup?: boolean
    onSelect?(id: string): void
}

export default observer(function PackageList({className, type, selected, itemPricePreview, setup, requireContainsSetup, onSelect}: Props)
{
    const filterType = type || setup.type;
    const packages = packageStore.projectPackages
        .filter(pkg =>
            pkg.type == filterType &&
            (!requireContainsSetup || userHasAccessToPkg(pkg)) &&
            (!requireContainsSetup || packageContainsSetup(pkg, setup, pid => packageStore.packages.find(pa => pa.id === pid), user.moderator))
        ).map(pkg => ({
            pkg,
            price: calculatePackagePrice(pkg, setup)
        })).sort((a, b) => a.price - b.price);

    let minPrice = Infinity;
    for (const p of packages)
    {
        if (p.price && minPrice > p.price)
        {
            minPrice = p.price;
        }
    }

    return (
        <div className={'d-flex flex-wrap align-items-stretch ' + (className || '')}>
            {packages.map(({pkg, price}) =>
                <PackageCard
                    key={pkg.id}
                    package={pkg}
                    active={selected === pkg.id}
                    savePercentage={!pkg.fixedPrice && packages.length > 1 && price > minPrice ? (price - minPrice) / minPrice : 0}
                    setup={setup}
                    itemPricePreview={itemPricePreview}
                    onSelect={onSelect}
                />
            )}
        </div>
    );
});
