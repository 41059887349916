import {client} from '../client';

interface IResizeResult
{
    status: number
    result: string
}

// returns the url where the result image is
// if the input url contains /resize/ the result will replace it with /resized/
export function resizeUploaded(url: string, width: number, height: number, deleteOnFailure?: boolean): Promise<IResizeResult>
{
    const headers: any = {
        'content-type': 'application/json',
        accept: 'application/json',
    };
    if (client.authorization)
    {
        headers.authorization = client.authorization;
    }
    return fetch('/resize', {
        method: 'POST',
        headers,
        body: JSON.stringify({url, width, height, deleteOnFailure}),
        credentials: 'same-origin',
    }).then(res => res.text().then(text => ({
        status: res.status,
        result: text,
    })));
}
