import React, {lazy} from 'react';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react-lite';
import {addOverlayCondition} from '../common/overlay';
const BrandImageCreatorUI = lazy(() => import('./BrandImageCreatorUI'));

export interface BrandImageCreatorOptions
{
    initialState?: Partial<BrandImageCreatorState>
    onDone: (img: Blob, state: BrandImageCreatorState) => void
    onDismiss?: () => void
}

export interface BrandImageCreatorState
{
    width: number
    height: number
    logoHeight: number
    imageFile?: File // in case a new file is selected
    imageUrl?: string // for initial state only
    cropTop: number
    cropLeft: number
    cropRight: number
    cropBottom: number
    backgroundColor: string
    fontSize: number
    text: string
}

const store = observable.object<{showing: BrandImageCreatorOptions}>({showing: null});

addOverlayCondition(() => store.showing);

export const openBrandImageCreator = action((options: BrandImageCreatorOptions) =>
{
    if (store.showing)
    {
        throw new Error('Already active');
    }
    store.showing = options;
});

export default observer(function BrandImageCreator()
{
    const showing = store.showing;
    return showing ?
        <BrandImageCreatorUI
            {...showing}
            onDone={action((...args) =>
            {
                store.showing = null;
                showing.onDone(...args);
            })}
            onDismiss={action(() =>
            {
                store.showing = null;
                showing.onDismiss?.();
            })}
        /> :
        null;
});
