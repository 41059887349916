
const currencySymbols = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    GBP: '£', // British Pound Sterling
    PLN: 'zł', // Polish Zloty
};

export function currencySymbol(currency: string): string
{
    return currencySymbols[currency] || currency;
}

export function formatCurrency(locale: string, currency: string, num: number): string
{
    currency = currency || 'EUR';
    // return new Intl.NumberFormat(locale, {style: 'currency', currency}).format(num);
    return formatCurrencyNumber(locale, currency, num) + ' ' + currencySymbol(currency);
}

export function formatCurrencyNumber(locale: string, currency: string, num: number): string
{
    currency = currency || 'EUR';
    return new Intl.NumberFormat(locale, {style: 'currency', currency, currencyDisplay: 'code'}).format(num).replace(currency, '').trim();
}
