import {ButtonHTMLAttributes, PropsWithChildren} from 'react';
import styles from './Button.module.css';

export default function Button({primary, disabled, children, className, ...rest}: PropsWithChildren<{primary?: boolean, disabled?: boolean} & ButtonHTMLAttributes<HTMLButtonElement>>)
{
    return (
        <button
            type='button'
            {...rest}
            className={(className ? className + ' ' : '') + (disabled ? styles.disabled : primary ? styles.primary : styles.secondary)}
        >
            {children}
        </button>
    );
}
