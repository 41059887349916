import {action, makeObservable, observable} from 'mobx';
import {Country} from '../../graphql/api/Types';

class RegisterStore
{
    @observable country: Country

    constructor()
    {
        makeObservable(this);
    }

    @action
    setCountry(country: Country)
    {
        this.country = country;
    }
}

export const registerStore = new RegisterStore();
