import {action, makeAutoObservable} from 'mobx';
import {loadExhibitorProductsPromoCodes} from '../ExhibitorProducts/productProxy';
import {debounce} from '../common/debounce';

class PageProductsPromoCodesStore
{
    products: {id: string, promoCodes: string[]}[] = [];

    constructor()
    {
        makeAutoObservable(this);
    }

    load = debounce((ids: string[]) =>
    {
        loadExhibitorProductsPromoCodes(ids).then(action(products =>
        {
            if (products)
            {
                for (const p of products)
                {
                    const existing = this.products.find(e => e.id === p.id);
                    if (existing)
                    {
                        existing.promoCodes = p.promoCodes;
                    }
                    else
                    {
                        this.products.push(p);
                    }
                }
            }
        }));
    }, 0);
}

export const pageProductsPromoCodesStore = new PageProductsPromoCodesStore();
