import React, {useEffect, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import './ProductBlock.scss';
import {pageStore} from './PageStore';
import BlockOptions from './BlockOptions';
import {RotationArrows} from './RotationArrows';
import {user} from '../stores/user';
import {usePageUnits} from './Page';
import {CommonBlockProps} from './blockMap';
import EmptyBlock from './EmptyBlock';
import {companyStore} from '../Company/CompanyStore';
import {projectStore} from '../stores/ProjectStore';
import DropdownSearch from '../common/DropdownSearch';
import {pageProductOptionsStore} from './PageProductOptionsStore';
import { exhibitorProductsPage } from './pageProxy';
import { globalNavigate } from '../App';
import {debounce} from '../common/debounce';
import { t } from '../translations';
import ProductStarDiscount from '../StoreCatalog/ProductStarDiscount';
import {useStoreProductImage} from '../StoreCatalog/useStoreProductImage';
import {catalogProductStore} from '../StoreCatalog/CatalogProductStore';
import {pickLanguage} from '../stores/utility';
import {getBusinessUnitValue} from '../../graphql/api/exhibitorProduct/utility';

interface Props extends CommonBlockProps
{
    published?: boolean
    items?: IProductBlockItem[]
}

interface IProductBlockItem
{
    published?: boolean
    product?: string
}

export default observer(function ProductBlock(props: Props)
{
    const {pageId, id, layout, fullAccess, editAccess, enableBlockOptions} = props;
    let {items} = props;

    if (!items?.length)
    {
        items = [{}];
    }
    if (!enableBlockOptions)
    {
        items = items.filter(i =>
        {
            if (!i.published || !filterProductsByStore(i))
            {
                return false;
            }
            return true;
            // const prod = i.product && catalogProductStore.products.find(p => p.id == i.product);
            // if (!prod)
            // {
            //     return true;
            // }
            // return canViewProduct(prod);
        });
        if (!items?.length)
        {
            items = [{}];
        }
    }

    const [index, setIndex] = useState<number>(0);
    const current = items[index];

    const [showOptions, setShowOptions] = useState(false);

    const product = current.product && catalogProductStore.products.find(p => p.id == current.product);

    const projId = projectStore.id;
    const country = projectStore.selectedCountry;

    const loadProductCondition = !!(!product && current.product && (enableBlockOptions || current.published));
    useEffect(() =>
    {
        if (loadProductCondition)
        {
            catalogProductStore.loadProduct(current.product);
        }
    }, [loadProductCondition]);

    const changeItemHeight = useMemo(() => debounce(pageStore.changeItemHeight, 50), []);
    const changeItemWidth = useMemo(() => debounce(pageStore.changeItemWidth, 50), []);
    const infoRef = useRef<HTMLDivElement>();
    const pageUnits = usePageUnits();
    useEffect(() =>
    {
        if (!enableBlockOptions && infoRef.current)
        {
            const shrink = pageUnits.rowHeight / pageUnits.defaultHeight;
            const height = infoRef.current.scrollHeight + 60;
            changeItemHeight(pageId, id, shrink < 1 ? height * pageUnits.heightToUnits : 2);
            changeItemWidth(pageId, id, shrink < .6 ? 8 : 4);
        }
    }, [enableBlockOptions, pageUnits.rowHeight, !!product]);

    const loadOptionsCondition = !!(projId && showOptions);
    const isItalyHQ = projectStore.selectedCountry === 'IT' && user.info?.profiles[0].id === 'headquarter';
    useEffect(() =>
    {
        if (loadOptionsCondition)
        {
            pageProductOptionsStore.load(projId);
        }
    }, [loadOptionsCondition]);
    const productImage = useStoreProductImage(product?.images);

    if (!isItalyHQ && !enableBlockOptions && !product)
    {
        return null;
    }

    const ownStores = companyStore.ownStores?.filter(c => product?.stores.some(s => s.storeId == c.store.id));
    const store = ownStores[0];
    const storeId = store?.store?.id;

    const handleClick = () =>
    {
        exhibitorProductsPage(product.id, projId).then(exhibitorPageId =>
        {
            if (exhibitorPageId)
            {
                globalNavigate()(`/page/${exhibitorPageId}`);
            }
        })
    }

    // if (!blockOptions && !canViewProduct(product))
    // {
    //     return null;
    // }

    return (
        <div
            tabIndex={0}
            className={'ProductBlock RotationArrows-container' + (product ? '' : ' border')}
            onClick={!enableBlockOptions ? handleClick : null}
            onDoubleClick={enableBlockOptions ? () =>
            {
                setShowOptions(true);
                pageStore.focusItem(pageId, id);
            } : null}
            onBlur={enableBlockOptions ? e =>
            {
                if (e.relatedTarget ?
                    !e.currentTarget.contains(e.relatedTarget as any) :
                    e.currentTarget == e.target || !e.currentTarget.contains(e.target as any)
                )
                {
                    setShowOptions(false);
                }
            } : null}
        >
            {!!product &&
            <div className='card'>
                <ProductStarDiscount starDiscount={product.stores.find(s => s.storeId == storeId)?.starDiscount}/>
                <div className='image' style={{backgroundImage: `url(${productImage})`}}/>
                <div className='info' ref={infoRef}>
                    <p className='name'>{pickLanguage(product.description)}</p>
                    <div className='properties'>
                        {/*<p>*/}
                        {/*    {country != 'IT' &&*/}
                        {/*    <span className='d-inline-block mr-4'>{isFair ? 'UVIR' : 'VE/UV'}: {product.uvir}</span>}*/}
                        {/*    <span className='d-inline-block'>{t.catalog.articleCode}: {product.code.article}</span>*/}
                        {/*</p>*/}
                    </div>
                    {country != 'IT' &&
                    <div className='price'>
                        {/*<p>PV: <b>{user.formatCurrency(currency, pricePv(firstPrice, store?.store?.profile) || 0)}</b></p>*/}
                        {/*{country == 'BE' && <PriceMargin price={firstPrice} storeProfile={store?.store?.profile}/>}*/}
                    </div>}
                    <div className='d-flex flex-row-reverse cart-add-button'>
                        <img src='https://yourfair-pub.s3-eu-west-1.amazonaws.com/assets/shopping-cart_white.svg' alt=''/>
                    </div>
                </div>
            </div>}
            <RotationArrows index={index} maxIndex={items?.length ? items.length - 1 : 0} setIndex={setIndex}/>
            {editAccess && !product &&
            <EmptyBlock layout={layout} name={t.editPage.selectProduct}/>}
            {showOptions &&
            <BlockOptions pageId={pageId} itemId={id} enableBlockOptions={enableBlockOptions} fullAccess={fullAccess}>
                <div>
                    <p className='font-weight-bold mb-4'>{t.editPage.selectProduct}</p>
                    <DropdownSearch
                        selected={current.product}
                        options={pageProductOptionsStore.products[projId]?.map(p => ({
                            value: p.id,
                            name: pickLanguage(getBusinessUnitValue(p.description)) || '',
                            keywords: [p.articleCode],
                        })) || []}
                        onSelect={selected =>
                        {
                            current.product = selected;
                            pageStore.changeItem(pageId, id, 'items', items);
                        }}
                    />
                </div>
                <div>
                    <div className='form-check form-check-inline mb-3'>
                        <input
                            id='options-published'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!current.published}
                            onChange={e =>
                            {
                                current.published = e.target.checked;
                                pageStore.changeItem(pageId, id, 'items', items);
                            }}
                        />
                        <label htmlFor='options-published' className='form-check-label font-weight-bold'>{t.editPage.published}</label>
                    </div>
                    {(fullAccess || !!props.items?.length) &&
                    <button
                        className='button mb-3'
                        onClick={() =>
                        {
                            if (props.items?.length)
                            {
                                if (confirm('Are you sure you want to remove currently selected content from this block?'))
                                {
                                    items.splice(index, 1);
                                    if (index > 0)
                                    {
                                        setIndex(index - 1);
                                    }
                                    pageStore.changeItem(pageId, id, 'items', items);
                                }
                            }
                            else
                            {
                                if (confirm('Do you want to delete this block?'))
                                {
                                    pageStore.deleteItem(pageId, id);
                                }
                            }
                        }}
                    >
                        {t.editPage.deleteContent}
                    </button>}
                    {fullAccess &&
                    <button
                        className='button'
                        onClick={() =>
                        {
                            items.push({});
                            setIndex(items.length - 1);
                            pageStore.changeItem(pageId, id, 'items', items);
                        }}
                    >
                        + Add more content
                    </button>}
                </div>
            </BlockOptions>
            }
        </div>
    );
});

export function filterProductsByStore(i: IProductBlockItem)
{
    // if (user.moderator)
    // {
    //     return true;
    // }
    if (!projectStore.canSeeProducts)
    {
        return false;
    }
    const country = projectStore.selectedCountry;
    if (country === 'IT' && user.info?.profiles[0].id === 'headquarter')
    {
        return true;
    }
    return true;
    // const product = i.product && catalogProductStore.products.find(p => p.id == i.product);
    // if (!product || !companyStore.ownStores.length)
    // {
    //     return true;
    // }
    // const ownStores = cartStore.storeCanAccessProduct(product);
    // if (!ownStores)
    // {
    //     return false;
    // }
    // let storeProfiles = ownStores.map(s => s.store?.profile);
    // const storeTypes = storeProfiles.map(getStoreTypeForProducts).filter(onlyUnique);
    // const promotionWeeksForStores = cartStore.storeCanAccessWeek(product?.price[country] as BEPrice[] || []);
    // return promotionWeeksForStores.some(promo => storeTypes.some(st => promo.storeType.includes(st)));
}

// function pricePv(price: BEPrice, storeProfile: string)
// {
//     const storeType = getStoreTypeForProducts(storeProfile);
//     const p = getPricesBE(price, storeType);
//     return ['marketIntegrated', 'hypermarket'].includes(storeProfile) ? p.inteSelling : p.selling;
// }
//
// function PriceMargin({price, storeProfile}: {price: BEPrice, storeProfile: string})
// {
//     const margin = pricesFairBEMargin(price, storeProfile);
//     return margin ? <p><FA name='badge-percent' className='fal'/> <b>{pricesFairBEMargin(price, storeProfile)}</b></p> : null;
// }

// export function canViewProduct(product: IExhibitorProduct)
// {
//     return user.moderator ||
//         !companyStore.ownCompany?.store?.profile?.includes('exhibitor') ||
//         companyStore.ownCompany?.exhibitor?.code?.includes(product?.carrefour.supplier.code);
// }
