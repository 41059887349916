// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profileItem.editable > div {
  position: relative;
}
.profileItem.editable > div::after {
  background: none repeat scroll 0 0 transparent;
  bottom: 5px;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: rgb(150, 150, 150);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.profileItem.editable > div:hover:after {
  width: 100%;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./frontend/common/ProfileItem.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACQ;EACI,kBAAA;AACZ;AAAS;EACI,8CAAA;EACA,WAAA;EACA,WAAA;EACA,cAAA;EACA,WAAA;EACA,SAAA;EACA,kBAAA;EACA,8BAAA;EACA,iDAAA;EACA,QAAA;AAEb;AAAS;EACI,WAAA;EACA,OAAA;AAEb","sourcesContent":[".profileItem {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    &.editable {\n        > div {\n            position: relative;\n         &::after {\n             background: none repeat scroll 0 0 transparent;\n             bottom: 5px;\n             content: \"\";\n             display: block;\n             height: 1px;\n             left: 50%;\n             position: absolute;\n             background: rgb(150, 150, 150);\n             transition: width 0.3s ease 0s, left 0.3s ease 0s;\n             width: 0;\n         }\n         &:hover:after {\n             width: 100%; \n             left: 0; \n         }\n        }\n    } \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
