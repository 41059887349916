import {call, gql} from '../client';
import {UpdateOwnCartProductInput} from '../../graphql/api/cart/Cart';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {ItemPriceInfo} from '../../graphql/api/cart/getItemPrice';

interface ICartListProductsResult
{
    products: ICatalogProduct[]
    count: number
}
interface ICartTotalProductsResult
{
    products: ICartTotalProduct[]
    count: number
    modified: /* Date */ string
}
export interface ICartTotalProduct extends Pick<ICatalogProduct, 'id'>
{
    stores: {
        storeId: string
        weeks: {
            promoCode: string
            week?: number | string
            quantity: number
            price: ItemPriceInfo
        }[]
    }[]
}

export interface ICartInput
{
    project: string
    limit?: number
    skip?: number
    storeId?: string
    ifModifiedSince?: number
    brands?: string[]
    // deprecated
    codes?: string[]
    deliveryMonth?: string
    // first 8 characters of the page id
    workshop?: string
}

export function loadCartListProducts(input: Omit<ICartInput, 'limit'>)
{
    return call<{cartProducts: ICartListProductsResult}>(
        gql`query($input:CartInput!){cartProducts(input:$input){count,products{id,exhibitor,exhibitorName,description,images,uvir,info{storeId,infoRows{name,value,unit,color,bold},priceRows{name,value,unit,color},extraRows{name,value,color}},labels{id,title,color},weeks{promoCode,week,folderType,sousVente},stores{storeId,starDiscount,weeks{promoCode,week,quantity,ordered,price{invoice,selling,discount,paid},catalog,message,messageImage,finSupplier,stars,minQuantity},comment,displayFR},display,limitedQuantity,limitedDates,package,comment,details,sousVenteSize,flow}}}`,
        {input: {...input, limit: 50}}
    ).then(({data}) => data?.cartProducts);
}

export async function loadCartTotalsProducts(project: string, ifModifiedSince?: /* Date */ string)
{
    let products: ICartTotalProductsResult['products'];
    let modified: /* Date */ string = null;
    for (;;)
    {
        const part = await _loadCartTotalsProducts(project, products ? products.length : 0, ifModifiedSince);
        if (part)
        {
            modified = part.modified;
            if (ifModifiedSince && ifModifiedSince == modified)
            {
                return {
                    products: null,
                    modified,
                };
            }
        }
        if (part && part.products.length)
        {
            if (products)
            {
                products.push(...part.products);
            }
            else
            {
                products = part.products;
            }
            if (products.length >= part.count)
            {
                break;
            }
        }
        else
        {
            if (!products)
            {
                products = [];
            }
            break;
        }
    }
    return {
        products,
        modified,
    };
}

function _loadCartTotalsProducts(project: string, skip: number, ifModifiedSince: /* Date */ string | null)
{
    return call<{cartProducts: ICartTotalProductsResult}>(
        gql`query($input:CartInput!){cartProducts(input:$input){count,modified,products{id,stores{storeId,weeks{promoCode,week,quantity,price{invoice,selling,discount,paid}}}}}}`,
        {input: {project, skip, limit: 5000, ifModifiedSince}},
        {noBatch: true}
    ).then(({data}) => data?.cartProducts);
}

export function updateOwnCartProducts(project: string, changes: UpdateOwnCartProductInput[])
{
    return call<{updateOwnCartProducts: {catalogProducts: ICatalogProduct[], modified: /* Date */ string, prevModified: /* Date */ string | null}}>(
        gql`mutation($input:UpdateOwnCartProductsInput!){updateOwnCartProducts(input:$input){catalogProducts,modified,prevModified}}`,
        {input: {project, changes}}
    );
}

export function validateOrder(project: string, storeId: string)
{
    return call<{addOrder: {success: boolean}}>(
        gql`mutation($project:ID!,$storeId:String!){addOrder(project:$project,storeId:$storeId){success}}`, {project, storeId})
    .then(({data}) => data?.addOrder?.success);
}
