
type Func<T extends any[] = any, R = any> = (...args: T) => R;

export function debounce<T extends any[]>(func: Func<T>, wait?: number): Func<T, void>
{
    let timeout;
    // @ts-ignore
    return function()
    {
        clearTimeout(timeout);
        const args = arguments;
        timeout = setTimeout(() =>
        {
            timeout = null;
            func.apply(this, args);
        }, wait);
    };
}

// decorator
export function debounced(wait?: number)
{
    return (target, propertyKey: string, descriptor: PropertyDescriptor) =>
    {
        descriptor.value = debounce(descriptor.value, wait);
    };
}
