import {action, makeAutoObservable} from 'mobx';
import {call, gql} from '../client';

export function loadProductsCount(project: string, cursor?: number)
{
    return call<{exhibitorProducts: {count: number}}>(
        gql`query($input:ExhibitorProductsInput!){exhibitorProducts(input:$input){count}}`,
        {input: {project, cursor}},
    ).then(({data}) => data?.exhibitorProducts.count);
}

class ProductsTableCountStore
{
    count: {[project: string]: number} = {};

    constructor()
    {
        makeAutoObservable(this);
    }

    load(project: string)
    {
        loadProductsCount(project).then(action(res =>
        {
            this.count[project] = res;
        }));
    }
}

export const productsTableCountStore = new ProductsTableCountStore();
