import {isID} from '../../lib/common';

export function simplifyCustomUrl(url: string)
{
    if (url.startsWith(location.origin))
    {
        url = url.replace(location.origin, '');
    }
    if (url.startsWith(location.host))
    {
        url = url.replace(location.host, '');
    }
    if (url.startsWith('/page/'))
    {
        const linkId = url.substr(6, 36);
        if (isID(linkId))
        {
            url = linkId;
        }
    }
    return url;
}
