import {useState} from 'react';
import Form from '../components/Form';
import Button from '../components/Button';
import CodeField from '../components/CodeField';
import {ILoginInput} from '../EventRegistrationFormStore';
import styles from './Login.module.css';
import {t} from '../../translations';

interface Props extends ILoginInput
{
    onSubmitCode(code: string): Promise<string | null | undefined>
    onResendCode(): Promise<string | null | undefined>
}

const codeFormatRegExp = /\d/;

export default function EnterConfirmationCode({method, onSubmitCode, onResendCode}: Props)
{
    const [code, setCode] = useState('');
    const isFilled = code.length === 6 && code.split('').every(c => codeFormatRegExp.test(c));
    const [error, setError] = useState<string>();
    const confirm = () => isFilled ?
        onSubmitCode(code).then(e =>
        {
            if (e)
            {
                setError(e);
            }
        }) :
        null;
    return (
        <Form className={styles.content} onSubmit={confirm}>
            <h3>{method === 'Email' ? t.eventRegistration.verifyEmail : t.eventRegistration.verifyPhone}</h3>
            <div>{method === 'Email' ? t.eventRegistration.pleaseEnterCodeEmail : t.eventRegistration.pleaseEnterCodePhone}</div>
            <CodeField className={styles.confirmationCode} size={6} code={code} onChange={setCode} format={codeFormatRegExp} required autoFocus/>
            {error && <div className={styles.error}>{error}</div>}
            <Button type='submit' primary disabled={!isFilled} className={styles.submitButton}>
                {method === 'Email' ? t.eventRegistration.verifyEmail : t.eventRegistration.verifyPhone}
            </Button>
            <ResendCode onResend={onResendCode}/>
        </Form>
    );
}

function ResendCode({onResend}: {onResend: () => void})
{
    const [timer, setTimer] = useState<number>();
    return (
        <div className={styles.resend}>
            {t.eventRegistration.didntReceiveCode}
            <span
                className={timer ? styles.resendButtonUnavailable : styles.resendButtonAvailable}
                onClick={timer ? null : () =>
                {
                    let remaining = 60;
                    setTimer(remaining);
                    const interval = setInterval(() =>
                    {
                        --remaining;
                        setTimer(remaining);
                        if (!remaining)
                        {
                            clearInterval(interval);
                        }
                    }, 1000);
                    onResend();
                }}
            >
                {timer ? `${t.eventRegistration.nextResendIn} ${timer} ${t.eventRegistration.seconds}` : t.eventRegistration.sendItAgain}
            </span>
        </div>
    );
}
