import React, {Fragment, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import FA from 'react-fontawesome';
import './NavbarSearch.scss';
import {t} from '../translations'
import {ErrorBoundary} from '../common/ErrorBoundary';
import {searchStore} from './SearchStore';
import {pickLanguage} from '../stores/utility';
import {LoadingInline} from '../common/Loading';
import {searchHistoryStore} from './SearchHistoryStore';
import {ISearchResults} from '../../graphql/api/search/Search';
import {getBusinessUnitValue} from '../../graphql/api/exhibitorProduct/utility';
import {companyStore} from '../Company/CompanyStore';
import {findStoreTypeBU} from '../data/storeTypes';
import {projectStore} from '../stores/ProjectStore';

export default observer(function NavbarSearch()
{
    const [open, setOpen] = useState<boolean>();
    return (
        <>
            <div
                className='NavbarSearch'
                onClick={() =>
                {
                    setOpen(!open);
                    searchStore.reset();
                }}
            >
                <FA name='search' className='fas search-icon'/>
                {t.global.navbarSearch}
            </div>
            {open && <NavbarSearchModal onClose={() => setOpen(false)}/>}
        </>
    );
});

const NavbarSearchModal = observer(function NavbarSearchModal({onClose}: {onClose: () => void})
{
    const [search, setSearch] = useState();

    const handleSearch = e =>
    {
        setSearch(e.target.value);
        searchStore.search(e.target.value);
    }

    return (
        <div
            className='NavbarSearchModalContainer'
            onClick={e =>
            {
                if (e.relatedTarget ?
                    !e.currentTarget.contains(e.relatedTarget as any) :
                    e.currentTarget == e.target || !e.currentTarget.contains(e.target as any)
                )
                {
                    onClose();
                }
            }}
        >
            <div className='NavbarSearchModal'>
                <div className='search'>
                    <FA name='search' className='fas search-icon'/>
                    <input
                        autoFocus
                        tabIndex={0}
                        type='text'
                        placeholder={t.global.navbarSearchLong}
                        value={search || ''}
                        onChange={handleSearch}
                    />
                    {!!searchStore.loading && <LoadingInline className='LoadingOnGrayBackground'/>}
                </div>
                {(!!searchStore.items || !!searchStore.error) ?
                    <ErrorBoundary><NavbarSearchResults onClose={onClose}/></ErrorBoundary>
                    :
                    <SearchHistoryItems onClose={onClose}/>
                }
            </div>
        </div>
    );
});

const searchResultOrder: (keyof ISearchResults)[] = [
    'pages',
    'products',
    'companies',
    'users',
    'stands',
    'invoices',
];

const searchResultTitles: {[T in keyof ISearchResults]: () => string} = {
    pages: () => t.global.navBar.pages,
    products: () => t.global.navBar.products,
    companies: () => t.global.navBarSearchCompanies,
    users: () => t.global.people,
    stands: () => t.global.navBarSearchStands,
    invoices: () => t.global.navBarSearchInvoices,
};

const itemRenderers: {[T in keyof ISearchResults]: (item: ISearchResults[T][number]) => any} = {
    users: u =>
        <>
            <FA name='user-alt' className='fad'/>
            <div>
                <Link
                    to={'/contact/' + u.id}
                    onClick={() => searchHistoryStore.add('users', u)}
                    className='d-block text-dark'
                    dangerouslySetInnerHTML={{__html: u.name}}
                />
                {u.companyId ?
                    <Link
                        to={'/company/' + u.companyId}
                        onClick={() => searchHistoryStore.add('users', u)}
                        className='d-block text-muted'
                        dangerouslySetInnerHTML={{__html: u.companyName || u.companyId}}
                    />
                    :
                    u.companyName ?
                        <div dangerouslySetInnerHTML={{__html: u.companyName}}/>
                        :
                        null
                }
            </div>
        </>,
    companies: c =>
        <>
            <FA name='building' className='fad'/>
            <Link
                to={'/company/' + c.id}
                onClick={() => searchHistoryStore.add('companies', c)}
                className='text-dark'
                dangerouslySetInnerHTML={{__html: c.name}}
            />
        </>,
    stands: s =>
        <>
            <FA name='frame' className='fad'/>
            <Link
                to={'/stands#' + s.id}
                onClick={() => searchHistoryStore.add('stands', s)}
                className='text-dark'
                dangerouslySetInnerHTML={{__html: s.name}}
            />
        </>,
    invoices: inv =>
        <>
            <FA name='file-invoice-dollar' className='fad'/>
            <Link
                to={`/invoice/${inv.standId}/${inv.id}`}
                onClick={() => searchHistoryStore.add('invoices', inv)}
                className='text-dark'
                dangerouslySetInnerHTML={{__html: '#' + inv.number}}
            />
        </>,
    pages: p =>
        <>
            <FA name='file-alt' className='fad'/>
            <Link
                to={'/page/' + p.id}
                onClick={() => searchHistoryStore.add('pages', p)}
                className='text-dark'
                dangerouslySetInnerHTML={{__html: p.name}}
            />
        </>,
    products: p =>
        <>
            <FA name='box-alt' className='fad'/>
            <Link
                to={'/catalog/' + p.id}
                onClick={() => searchHistoryStore.add('products', p)}
                className='text-dark'
                dangerouslySetInnerHTML={{
                    __html: pickLanguage(
                        getBusinessUnitValue(
                            p.description,
                            findStoreTypeBU(projectStore.selectedCountry, companyStore.ownStores[0]?.store.storeType)
                        )
                    )
                }}
            />
        </>,
};

const NavbarSearchResults = observer(function NavbarSearchResults({onClose}: {onClose: () => void})
{
    const {items, error} = searchStore;
    return error ?
        <div className='NavbarSearchResults'>{error}</div>
        :
        !!items &&
        <div
            className='NavbarSearchResults'
            onClick={e =>
            {
                if ('href' in e.target || 'href' in (e.target as HTMLElement).parentElement)
                {
                    onClose();
                }
            }}
        >
            {searchResultOrder.map(type =>
                !!items[type]?.length &&
                <Fragment key={type}>
                    <h6>{searchResultTitles[type]()}</h6>
                    <div className='result-category'>
                        {items[type].map((item, index) =>
                            <div key={index}>
                                {itemRenderers[type](item)}
                            </div>
                        )}
                    </div>
                </Fragment>
            )}
            {!searchResultOrder.some(type => items[type]?.length) &&
            <div className='d-flex justify-content-center'><FA name='empty-set' className='fas'/></div>
            }
        </div>
});

const SearchHistoryItems = observer(function SearchHistoryItems({onClose}: {onClose: () => void})
{
    const {items} = searchHistoryStore;
    if (!items.length)
    {
        return null;
    }
    return (
        <div
            className='NavbarSearchResults'
            onClick={e =>
            {
                if ('href' in e.target || 'href' in (e.target as HTMLElement).parentElement)
                {
                    onClose();
                }
            }}
        >
            <div className='result-category'>
                {items.map((item, index) =>
                    <div key={index}>
                        {itemRenderers[item.type](item as any)}
                        <div className='remove-history-item' onClick={() => searchHistoryStore.remove(item.id)}>
                            <FA name='history' className='fad'/>
                            <FA name='trash-alt' className='fad'/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});
