// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-company-link {
  color: #fff !important;
  text-decoration: none !important;
}

.support-contact {
  margin-bottom: 20px;
}
.support-contact .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 25px;
  font-size: 1.5em;
}
.support-contact .supportPicture {
  flex-basis: 40%;
}
.support-contact .supportPicture img {
  max-width: 175px;
  margin: 0 auto;
  display: block;
}

.dashboard-views {
  font-size: 1.5rem;
  margin-left: 1rem;
}
.dashboard-views > * {
  margin-right: 2rem;
  color: gray;
}
.dashboard-views .active {
  color: initial;
}`, "",{"version":3,"sources":["webpack://./frontend/Dashboard/Dashboard.scss"],"names":[],"mappings":"AACA;EACE,sBAAA;EACA,gCAAA;AAAF;;AAEA;EACE,mBAAA;AACF;AAAE;EACE,OAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AAEJ;AAAE;EACE,eAAA;AAEJ;AADI;EACE,gBAAA;EACA,cAAA;EACA,cAAA;AAGN;;AAEA;EACE,iBAAA;EACA,iBAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;AAEJ;AAAE;EACE,cAAA;AAEJ","sourcesContent":["\n.dashboard-company-link {\n  color: #fff !important;\n  text-decoration: none !important;\n}\n.support-contact {\n  margin-bottom: 20px;\n  .info {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    padding: 0 25px;\n    font-size: 1.5em;\n  }\n  .supportPicture {\n    flex-basis: 40%;\n    img {\n      max-width: 175px;\n      margin: 0 auto;\n      display: block;\n    }\n  }\n}\n\n.dashboard-views {\n  font-size: 1.5rem;\n  margin-left: 1rem;\n  > * {\n    margin-right: 2rem;\n    color: gray;\n  }\n  .active {\n    color: initial;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
