import React from 'react';
import {observer} from 'mobx-react-lite';
import './EmptyBlock.scss';
import {IPageItem} from '../../graphql/api/page/Page';
import {pageHeightSizeFromUnits, pageWidthSizeFromUnits} from './DroppableElement';
import {ITranslations} from '../../graphql/api/Types';
import {pickLanguage} from '../stores/utility';

interface Props
{
    layout: IPageItem['layout']
    name?: string
    msg?: string
    text?: ITranslations
    image?: ITranslations
}

export default observer(function EmptyBlock({layout, name, msg, text, image}: Props)
{
    const t = pickLanguage(text);
    const i = pickLanguage(image);
    return (
        <div className='EmptyBlock' style={i ? {backgroundImage: `url(${i})`} : null}>
            {t ?
                <span className='customText'>{t}</span>
                :
                <>
                    {name}
                    <br/>
                    {msg || pageWidthSizeFromUnits(layout.w) + ' x ' + pageHeightSizeFromUnits(layout.h)}
                </>
            }
        </div>
    );
});
