
interface IGlobalEventBase
{
    type: string
}
type IGlobalEventHandler<T extends IGlobalEventBase = any> = (event: T) => void

const handlers = new Map<IGlobalEventHandler, string>();

export function registerGlobalEventHandler(type: string, handler: IGlobalEventHandler)
{
    handlers.set(handler, type);
}

// export function unregisterGlobalEventHandler(handler: IGlobalEventHandler)
// {
//     handlers.delete(handler);
// }

export function triggerGlobalEvent<T extends IGlobalEventBase = any>(event: T)
{
    for (const [handler, type] of handlers.entries())
    {
        if (typeof type != 'string' || type == event.type)
        {
            try
            {
                handler(event);
            }
            catch (e)
            {
                console.error(e);
            }
        }
    }
}
