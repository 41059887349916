// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderStandTypeTitle {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 18px;
}

.OrderStandType button {
  font-family: Montserrat, sans-serif;
  background: transparent;
  border-radius: 5px;
  color: #403d39;
  font-size: 16px;
  line-height: 25px;
  margin-right: 26px;
  margin-bottom: 26px;
  border: 2px solid #d0cfce;
  min-width: 180px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.OrderStandType button.selected {
  color: #fff;
  font-weight: bold;
  background-color: #3d8af7;
  border-color: #3d8af7;
}`, "",{"version":3,"sources":["webpack://./frontend/OrderStand/OrderStandType.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAGE;EACE,mCAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AAAJ;AACI;EACE,WAAA;EACA,iBAAA;EACA,yBAAA;EACA,qBAAA;AACN","sourcesContent":[".OrderStandTypeTitle {\n  font-family: Montserrat, sans-serif;\n  font-size: 18px;\n  line-height: 26px;\n  margin-bottom: 18px;\n}\n\n.OrderStandType {\n  button {\n    font-family: Montserrat, sans-serif;\n    background: transparent;\n    border-radius: 5px;\n    color: #403d39;\n    font-size: 16px;\n    line-height: 25px;\n    margin-right: 26px;\n    margin-bottom: 26px;\n    border: 2px solid #d0cfce;\n    min-width: 180px;\n    padding-left: 1rem;\n    padding-right: 1rem;\n    &.selected {\n      color: #fff;\n      font-weight: bold;\n      background-color: #3d8af7;\n      border-color: #3d8af7;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
