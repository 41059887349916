import {action, makeObservable, observable} from 'mobx';
import moment from 'moment';
import {updateOwnCartProducts} from './cartProxy';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {UpdateOwnCartProductInput} from '../../graphql/api/cart/Cart';
import type * as catalogProductStoreType from './CatalogProductStore';
import {onlyUnique} from '../../lib/common';
import {loadCatalogProductsById} from '../ExhibitorProducts/productProxy';

type Listener = (project: string, products: ICatalogProduct[], modified: /* Date */ string, prevModified: /* Date */ string | null) => void

interface ICartNotification
{
    quantity: {[storeId: string]: number}
    productId: string
    timeout: any
}

class CartQuantityStore
{
    private listeners = new Set<Listener>();
    @observable private changes: {[project: string]: UpdateOwnCartProductInput[]} = {};
    private submitTimeout;
    @observable cartNotifications: ICartNotification[] = [];

    constructor()
    {
        makeObservable(this);
    }

    // allows other stores to listen to quantity change events,
    // so that they can update the UI after the server responds
    // (listeners are called inside a mobx action)
    addListener(listener: Listener)
    {
        if (typeof listener != 'function')
        {
            throw new TypeError('Listener must be a function');
        }
        this.listeners.add(listener);
    }

    // removeListener(listener: Listener)
    // {
    //     this.listeners.delete(listener);
    // }

    // returns the quantity that was not yet submitted to the server
    getQuantityChange(project: string, storeId: string, productId: string, promoCode: string)
    {
        return this.getChange(project, storeId, productId, promoCode)?.quantity;
    }

    private getChange(project: string, storeId: string, productId: string, promoCode: string)
    {
        return this.changes[project]?.find(c =>
            c.storeId == storeId && c.productId == productId && c.promoCode == promoCode
        );
    }

    // returns false if the change is not allowed
    setQuantity(project: string, storeId: string, productId: string, promoCode: string, week: number | string, quantity: number)
    {
        if (!storeId)
        {
            return false;
        }
        // can't use normal import due to circular dependency
        const {catalogProductStore} = require('./CatalogProductStore') as typeof catalogProductStoreType;
        const catalogProduct = catalogProductStore.products.find(p => p.id == productId);
        const storeWeekInfo = catalogProduct
            ?.stores.find(s => s.storeId == storeId)
            ?.weeks.find(w => w.promoCode == promoCode);
        const minQuantity = storeWeekInfo
            ?.minQuantity || 0;
        if (quantity < minQuantity)
        {
            quantity = minQuantity;
        }
        if (quantity > 9999)
        {
            quantity = 9999;
        }
        if (catalogProduct.limitedQuantity != null || catalogProduct.limitedDates != null)
        {
            const difference = quantity - (storeWeekInfo?.quantity || 0);
            if (difference > 0)
            {
                if (catalogProduct.limitedQuantity != null && difference > catalogProduct.limitedQuantity)
                {
                    return false;
                }
                if (catalogProduct.limitedDates != null && !catalogProduct.limitedDates.includes(moment().format('DD/MM')))
                {
                    return false;
                }
            }
        }
        // if (typeof week == 'string')
        // {
        //     week = +week;
        // }
        const pendingChange = this.getChange(project, storeId, productId, promoCode);
        if (pendingChange)
        {
            pendingChange.quantity = quantity;
        }
        else
        {
            if (!this.changes[project])
            {
                this.changes[project] = [];
            }
            this.changes[project].push({storeId, productId, promoCode, week, quantity});
        }
        clearTimeout(this.submitTimeout);
        this.submitTimeout = setTimeout(this.submitChanges, 500);
    }

    @action.bound
    private submitChanges()
    {
        for (const project of Object.getOwnPropertyNames(this.changes))
        {
            const pendingChanges = this.changes[project];
            if (pendingChanges.length)
            {
                updateOwnCartProducts(project, pendingChanges).then(action(({data, errors}) =>
                {
                    this.changes[project] = [];
                    const res = data?.updateOwnCartProducts;
                    if (res)
                    {
                        // can't use normal import due to circular dependency
                        const {catalogProductStore} = require('./CatalogProductStore') as typeof catalogProductStoreType;
                        for (const product of res.catalogProducts)
                        {
                            // show cart notifications
                            const pendingChangesThisProduct = pendingChanges.filter(p => p.productId == product.id);
                            for (const {storeId, productId, quantity, promoCode} of pendingChangesThisProduct)
                            {
                                const pr = this.cartNotifications.find(p => p.productId == productId);
                                if (pr)
                                {
                                    if (quantity == 0)
                                    {
                                        delete pr.quantity[storeId];
                                    }
                                    else
                                    {
                                        pr.quantity[storeId] = quantity;
                                    }
                                    clearTimeout(pr.timeout);
                                    pr.timeout = setTimeout(() => this.closeNotification(productId), 3000);
                                }
                                else
                                {
                                    const oldQuantity = catalogProductStore.products.find(p => p.id == productId)
                                        ?.stores.find(s => s.storeId == storeId)
                                        ?.weeks.find(w => w.promoCode == promoCode)
                                        ?.quantity || 0;
                                    if (quantity > oldQuantity)
                                    {
                                        this.cartNotifications.push({
                                            productId,
                                            quantity: {[storeId]: quantity},
                                            timeout: setTimeout(() => this.closeNotification(productId), 3000),
                                        });
                                    }
                                }
                            }
                        }

                        // update other stores
                        for (const listener of this.listeners)
                        {
                            try
                            {
                                listener(project, res.catalogProducts, res.modified, res.prevModified);
                            }
                            catch (e)
                            {
                                console.error(e);
                            }
                        }
                    }
                    else if (errors[0].message === 'Not enough stock')
                    {
                        loadCatalogProductsById(pendingChanges.map(p => p.productId).filter(onlyUnique)).then(action(products =>
                        {
                            for (const listener of this.listeners)
                            {
                                try
                                {
                                    listener(project, products, null, null);
                                }
                                catch (e)
                                {
                                    console.error(e);
                                }
                            }
                        }));
                    }
                }));
            }
        }
    }

    @action
    private closeNotification(productId: string)
    {
        const i = this.cartNotifications.findIndex(p => p.productId == productId);
        this.cartNotifications.splice(i, 1);
    }

    @action
    closeNotificationByIndex(productIndex: number)
    {
        this.cartNotifications.splice(productIndex, 1);
    }
}

export const cartQuantityStore = new CartQuantityStore();
