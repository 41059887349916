import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useLocation} from 'react-router';
import {useNavigate} from 'react-router-dom';
import FA from 'react-fontawesome';
import Swal from 'sweetalert2';
import './CartPage.scss';
import {projectStore} from '../stores/ProjectStore';
import {companyStore} from '../Company/CompanyStore';
import StoreCatalogCard from './StoreCatalogCard';
import CartOrderTotal from './CartOrderTotal';
import {mainStateReload} from '../Navbar/NavBarStore';
import { t } from '../translations';
import {Loading} from '../common/Loading';
import {debouncedLogView} from '../stores/logView';
import {cartValidateStore} from './CartValidateStore';
import {cartListStore} from './CartListStore';
import {cartTotalStore} from './CartTotalStore';
import getCartInfo from './getCartInfo';
import DeliveryMonthSelect from './DeliveryMonthSelect';
import CartWorkshopSelect from './CartWorkshopSelect';
import {productWorkshopProductsStore} from './ProductWorkshopProductsStore';
import {companyMemberWasScannedIn} from '../stores/CompanyMemberWasScannedIn';
import {user} from '../stores/user';

export default observer(function CartPage()
{
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    let storeId = query.get('store');
    const deliveryMonth = query.get('deliveryMonth');
    const workshop = query.get('workshop');

    useEffect(mainStateReload, []);

    const projectId = projectStore.id;
    const isFRNonFood = projectStore.isFRNonFood;

    useEffect(() =>
    {
        if (projectId)
        {
            debouncedLogView({type: 'cart', project: projectId});
            cartTotalStore.loadProductsThrottled(projectId, true);
        }
    }, [projectId]);
    useEffect(() =>
    {
        if (projectId && isFRNonFood)
        {
            productWorkshopProductsStore.load(projectId);
        }
    }, [projectId, isFRNonFood]);

    const ownStores = companyStore.ownStores;
    const store = ownStores.find(c => c.store.id === storeId) || ownStores[0];
    const storeIndex = ownStores.indexOf(store);
    storeId = store?.store.id;

    useEffect(() =>
    {
        if (projectId && storeId)
        {
            cartListStore.loadProducts({project: projectId, storeId, deliveryMonth, workshop});
        }
    }, [projectId, storeId, deliveryMonth, workshop]);

    useEffect(() =>
    {
        const handleScroll = () =>
        {
            const scrollPos = document.documentElement.scrollTop;
            const offsetHeight = window.innerHeight;
            const height = document.documentElement.scrollHeight;

            if (scrollPos + offsetHeight >= height - 150)
            {
                cartListStore.loadMoreProducts();
            }
        };
        window.addEventListener('scroll', handleScroll, true);
        return () =>
        {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, []);

    const storeCompanyId = store?.id;
    const validationOnlyAfterScanningIn = projectStore.validationOnlyAfterScanningIn;
    useEffect(() =>
    {
        if (projectId && storeCompanyId && validationOnlyAfterScanningIn && !user.projectBadge?.scannedIn?.length)
        {
            companyMemberWasScannedIn.load(projectId, storeCompanyId);
        }
    }, [projectId, storeCompanyId, validationOnlyAfterScanningIn]);

    const products = cartListStore.searchProducts || cartListStore.products[projectStore.id]?.[storeId];

    const {total, deliveryMonths, workshops} = getCartInfo(storeId, deliveryMonth, workshop);

    return (
        <div className='CartPage mx-5'>
            <div className='storeCartCardContainer'>
                <div className='content'>
                    <DeliveryMonthSelect
                        className='mb-3'
                        deliveryMonths={deliveryMonths}
                        deliveryMonth={deliveryMonth}
                        setDeliveryMonth={selectedDeliveryMonth =>
                        {
                            if (selectedDeliveryMonth)
                            {
                                query.set('deliveryMonth', selectedDeliveryMonth);
                            }
                            else
                            {
                                query.delete('deliveryMonth');
                            }
                            navigate('?' + query.toString());
                        }}
                    />
                    <CartWorkshopSelect
                        className='mb-3'
                        workshops={workshops}
                        selected={workshop}
                        onSelect={selectedWorkshop =>
                        {
                            if (selectedWorkshop)
                            {
                                query.set('workshop', selectedWorkshop);
                            }
                            else
                            {
                                query.delete('workshop');
                            }
                            navigate('?' + query.toString());
                        }}
                    />
                    <div className='StoreCartCard'>
                        <p className='store'>
                            {storeIndex > 0 &&
                            <FA
                                name='chevron-left'
                                className='fas text-secondary cursor-pointer px-2'
                                onClick={() =>
                                {
                                    query.set('store', ownStores[storeIndex - 1].store.id);
                                    navigate('?' + query);
                                }}
                            />}
                            <span className='store-name'>{storeId} {store?.name}</span>
                            {storeIndex < ownStores.length - 1 &&
                            <FA
                                name='chevron-right'
                                className='fas text-secondary cursor-pointer px-2'
                                onClick={() =>
                                {
                                    query.set('store', ownStores[storeIndex + 1].store.id);
                                    navigate('?' + query);
                                }}
                            />}
                        </p>
                        <CartOrderTotal
                            storeType={store?.store.storeType}
                            {...total}
                            flow={projectStore.isFRNonFood ? products?.some(p => p.flow == 'GSA') ? 'GSA' : 'GSE' : undefined}
                        />
                        {store &&
                        projectStore.canValidateWithoutQR &&
                        (!validationOnlyAfterScanningIn || companyMemberWasScannedIn.getIsScannedInOnCurrentProject(storeCompanyId)) &&
                        <button
                            className={products?.length ? 'button' : 'button-empty'}
                            onClick={() =>
                            {
                                if (deliveryMonth || workshop)
                                {
                                    navigate('?');
                                }
                                else if (products?.length)
                                {
                                    Swal.fire(
                                        {
                                            title: t.catalog.validateOrderCheck(store.store.id),
                                            icon: 'question',
                                            confirmButtonText: t.catalog.validate,
                                            cancelButtonText: t.global.cancel,
                                            showCancelButton: true,
                                        }).then((result) =>
                                    {
                                        if (result.value)
                                        {
                                            cartValidateStore.validate(projectStore.id, store.store.id);
                                        }
                                    });
                                }
                            }}
                        >
                            {deliveryMonth || workshop ? t.cart.removeFilters : t.cart.validateOrder}
                        </button>}
                    </div>
                </div>
            </div>
            <div className='products'>
                <h1>{t.global.cart}</h1>
                {isFRNonFood && !!store && !['H', 'HF'].includes(store.store.storeType) && <a href='/cart-by-workshop' className='d-inline-block mb-4'>{t.cart.cartByWorkshop}</a>}
                {products?.map(product =>
                    <StoreCatalogCard key={product.id} product={product} preselectedStore={store} deliveryMonth={deliveryMonth}/>
                )}
                {(cartListStore.loading || cartTotalStore.loading) && <div className='loading-products'><Loading/></div>}
            </div>
        </div>
    );
});
