import {action, makeObservable, observable} from 'mobx';
import {loadCompanySelectList, loadContactSelectList} from './contactSelectionProxy';
import {Country} from '../../graphql/api/Types';

export interface ContactSelectionItem
{
    id: string
    name: string
    email: string
}

class ContactSelectionStore
{
    @observable private internalList: {[country in Country]?: ContactSelectionItem[]} = {};
    private loading: Country = null;

    constructor(
        private loader: (country: Country) => Promise<ContactSelectionItem[]>
    )
    {
        makeObservable(this);
    }

    @action
    load(country: Country)
    {
        if (this.loading == country)
        {
            return;
        }
        this.loading = country;
        this.loader(country).then(action('loadContacts', list =>
        {
            this.loading = null;
            this.internalList[country] = list;
        }));
    }

    list(country: Country)
    {
        return this.internalList[country] || [];
    }
}

export const contactSelectionStore = new ContactSelectionStore(loadContactSelectList);
export const companySelectionStore = new ContactSelectionStore(loadCompanySelectList);
