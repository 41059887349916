import {action, makeObservable, observable} from 'mobx';
import {chosePageTemplate, pageTemplateChoices} from './pageProxy';
import {pageStore} from './PageStore';

class PickPageTemplate
{
    @observable templateChoices: {[project: string]: {id: string, name: string}[]} = {};

    constructor()
    {
        makeObservable(this);
    }

    load(project: string)
    {
        pageTemplateChoices(project).then(action('pageTemplateChoices', templateChoices =>
        {
            if (templateChoices?.length)
            {
                this.templateChoices[project] = templateChoices;
            }
        }));
    }

    choose(project: string, templateId: string)
    {
        return chosePageTemplate(project, templateId).then(action('chosePageTemplate', pages =>
        {
            if (pages)
            {
                pageStore.pages.push(...pages);
                this.templateChoices[project] = null;
            }
            return pages;
        }));
    }
}

export const pickPageTemplate = new PickPageTemplate();
