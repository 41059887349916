import {action, makeObservable, observable} from 'mobx';
import deepEqual from 'deep-equal';
import {loadExhibitorProductsPageCatalog, PageCatalogProductsInput} from '../ExhibitorProducts/productProxy';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {cartQuantityStore} from '../StoreCatalog/CartQuantityStore';
import {catalogProductStore} from '../StoreCatalog/CatalogProductStore';
import {cartValidateStore} from '../StoreCatalog/CartValidateStore';
import {registerGlobalEventHandler} from '../stores/globalEvents';

interface ILoadedCatalog
{
    products: ICatalogProduct[]
    nextPage: number
    hasMore: boolean
    lastInput: Omit<PageCatalogProductsInput, 'page'>
    lastLoadTime?: Date
}

class PageCatalogStore
{
    @observable catalog: {[id: string]: ILoadedCatalog} = {};

    constructor()
    {
        makeObservable(this);
        cartQuantityStore.addListener((project, products) =>
        {
            for (const pageId of Object.getOwnPropertyNames(this.catalog))
            {
                const catalog = this.catalog[pageId];
                for (const {id, stores, weeks, ...rest} of products)
                {
                    const existing = catalog.products.find(p => p.id == id);
                    if (existing)
                    {
                        Object.assign(existing, rest);
                        // update without adding additional weeks, since the page might be using products with partial promotions
                        for (const es of existing.stores)
                        {
                            const us = stores.find(s => s.storeId === es.storeId);
                            if (us)
                            {
                                for (const ew of es.weeks)
                                {
                                    const uw = us.weeks.find(w => w.promoCode === ew.promoCode);
                                    if (uw)
                                    {
                                        Object.assign(ew, uw);
                                    }
                                }
                            }
                        }
                        for (const ew of existing.weeks)
                        {
                            const uw = weeks.find(w => w.promoCode === ew.promoCode);
                            if (uw)
                            {
                                Object.assign(ew, uw);
                            }
                        }
                    }
                }
            }
        });
        cartValidateStore.addListener(() =>
        {
            this.catalog = {};
        });
        registerGlobalEventHandler('logout', () =>
        {
            this.catalog = {};
        });
        registerGlobalEventHandler('add-store', () =>
        {
            this.catalog = {};
        });
    }

    @action
    load(input: Omit<PageCatalogProductsInput, 'page'>, reset?: boolean)
    {
        let catalog = this.catalog[input.pageId];
        if (catalog)
        {
            if (reset)
            {
                catalog.nextPage = 0;
            }
            else
            {
                if (!deepEqual(input, catalog.lastInput, {strict: true}))
                {
                    catalog = null; // reset because a parameter has changed
                }
            }
        }
        if (!catalog)
        {
            this.catalog[input.pageId] = {
                products: [],
                nextPage: 0,
                hasMore: true,
                lastInput: input,
            };
            catalog = this.catalog[input.pageId];
        }
        catalog.lastLoadTime = new Date();
        if (input.storeTypes && !input.storeTypes.length)
        {
            catalog.products = [];
            catalog.nextPage = 0;
            catalog.hasMore = false;
            return Promise.resolve(false);
        }
        return loadExhibitorProductsPageCatalog({...input, page: catalog.nextPage++}).then(action(products =>
        {
            if (reset)
            {
                catalog.products = [];
            }
            if (products)
            {
                if (products.length)
                {
                    catalog.products.push(...products);
                    for (const product of products)
                    {
                        catalogProductStore.addProduct(product);
                    }
                }
                else
                {
                    catalog.hasMore = false;
                }
            }
            return !!products?.length;
        }));
    }
}

export const pageCatalogStore = new PageCatalogStore();
