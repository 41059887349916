import {makeTranslation} from '../../lib/translation';

export const translationsPrivate = {
    global: {
        language: {
            en: 'Language',
            fr: 'Langue',
            nl: 'Taal',
            pl: 'Język',
            it: 'Lingua',
            es: 'Idioma',
        },
        welcome: {
            en: 'Welcome',
            fr: 'Bienvenue',
            nl: 'Welkom',
            pl: 'Witamy!',
            it: 'Benvenuto',
            es: 'Bienvenidos',
        },
        signout: {
            en: 'Sign out',
            fr: 'Se déconnecter',
            nl: 'Log uit',
            pl: 'Wyloguj się',
            it: 'Esci',
            es: 'Cerrar sesión',
        },
        submit: {
            en: 'Submit',
            fr: 'Soumettre',
            nl: 'Verzend',
            pl: 'Zatwierdź',
            it: 'Invia',
            es: 'Enviar',
        },
        update: {
            en: 'Update',
            fr: 'Mettre à jour',
            pl: 'Zaktualizuj',
            it: 'Aggiorna',
            es: 'Actualizar',
        },
        confirm: {
            en: 'Confirm',
            fr: 'Confirmer',
            nl: 'Bevestigen',
            pl: 'Potwierdzam',
            it: 'Conferma',
            es: 'Confirmar',
        },
        cancel: {
            en: 'Cancel',
            fr: 'Annuler',
            nl: 'Annuleren',
            pl: 'Anuluj',
            it: 'Annulla',
            es: 'Cancelar',
        },
        yes: {
            en: 'Yes',
            fr: 'Oui',
            nl: 'Ja',
            pl: 'Tak',
            it: 'Si',
            es: 'Sí',
        },
        no: {
            en: 'No',
            fr: 'Non',
            nl: 'Nee',
            pl: 'Nie',
            it: 'No',
            es: 'No',
        },
        edit: {
            en: 'Edit',
            fr: 'Modifier',
            nl: 'Bewerk',
            pl: 'Edytuj',
            it: 'Modica',
            es: 'Editar',
        },
        delete: {
            en: 'Delete',
            fr: 'Effacer',
            nl: 'Verwijder',
            pl: 'Usuń',
            it: 'Cancella',
            es: 'Eliminar',
        },
        remove: {
            en: 'Remove',
            fr: 'Retirer',
            nl: 'Verwijderen',
            pl: 'Usuń',
            it: 'Rimuovere',
            es: 'Eliminar',
        },
        loading: {
            en: 'Loading...',
            fr: 'En cours de chargement...',
            nl: 'Aan het laden...',
            pl: 'Ładowanie...',
            it: 'Caricamento in corso...',
            es: 'Cargando...',
        },
        name: {
            en: 'Address name',
            fr: "Nom de l'adresse",
            nl: 'Adres naam',
            pl: 'Adres',
            it: 'Indirizzo email',
            es: 'Dirección de email',
        },
        company: {
            en: 'Company',
            fr: 'Société',
            nl: 'Bedrijf',
            pl: 'Firma',
            it: 'Societá',
            es: 'Empresa',
        },
        function: {
            en: 'Function',
            fr: 'Fonction',
            nl: 'Functie',
            pl: 'Stanowisko',
            it: 'Funzione',
            es: 'Función',
        },
        // nameLastName: {
        //     en: 'Name Lastname',
        //     pl: 'Imię i nazwisko',
        //     es 'Nombre',
        // },
        navbarSearch: {
            en: 'Search...',
            fr: 'Rechercher...',
            nl: 'Zoeken...',
            pl: 'Szukaj...',
            it: 'Cerca...',
            es: 'Buscar...',
        },
        navbarSearchLong: {
            en: 'Search a product, a brand, or anything else...',
            fr: 'Rechercher...',
            nl: 'Zoeken...',
            pl: 'Szukaj...',
            it: 'Cerca...',
            es: 'Buscar...',
        },
        // navBarSearchContacts: {
        //     en: 'Contacts',
        //     fr: 'Contacts',
        //     nl: 'Contacten',
        //     pl: 'Kontakty',
        //     it: 'Contatti',
        //     es: 'Contactos',
        // },
        navBarSearchCompanies: {
            en: 'Companies',
            fr: 'Compagnies',
            nl: 'Bedrijven',
            pl: 'Firmy',
            it: 'Aziende',
            es: 'Empresas',
        },
        people: {
            en: 'People',
            fr: 'Personnes',
            nl: 'Personen',
            pl: 'Ludzie',
            it: 'Persone',
            es: 'Personas',
        },
        navBarSearchStands: {
            en: 'Stands',
            fr: 'Stands',
            nl: 'Standen',
            pl: 'Stoiska',
            it: 'Stands',
            es: 'Stands',
        },
        navBarSearchInvoices: {
            en: 'Invoices',
            fr: 'Invoices',
            nl: 'Facturen',
            pl: 'Faktury',
            it: 'Fatturazione',
            es: 'Facturas',
        },
        // navBarNoStands: {
        //     en: 'No stands found',
        //     fr: 'Aucun stand trouvé',
        //     nl: 'Geen stands gevonden',
        //     pl: 'Nie znaleziono stoisk',
        //     it: 'Nessuno stand trovato',
        //     es: 'No se han encontrado stands',
        // },
        navBarNoContacts: {
            en: 'No contacts found',
            fr: 'Aucun contact trouvé',
            nl: 'Geen contacten gevonden',
            pl: 'Nie znaleziono kontaktu',
            it: 'Nessun contatto trovato',
            es: 'No se han encontrado contactos',
        },
        addCompanyMembers: {
            en: 'Add company members',
            fr: "Ajouter des membres de l'entreprise",
            nl: 'Voeg bedrijfsleden toe',
            pl: 'Dodaj członków firmy',
            it: 'Aggiunga colleghi della sua azienda',
            es: 'Añadir miembros de su empresa',
        },
        contactFlowButton: {
            en: 'Send your contact info to chat admins (full name, email, profile, company), and start the chat?',
            fr: "Envoyez vos coordonnées à l'administrateur du chat (nom complet, e-mail, profil, entreprise), et commencez le chat ?",
            nl: 'Stuur je contactinfo naar de chat admin (volledige naam, email, profiel, bedrijf & start de chat?)',
            pl: 'Zgadzam się aby wysłano moje dane do administratora (imię, nazwisko, email, profil, firma) i rozpocznę kontakt (chat)',
            it: "Inviare i suoi dati all'espositore (nome, cognome, email, profilo, punto vendita) per iniziare la chat?",
            es: 'Envía tus datos al administrador del chat (nombre completo, email, perfil, empresa) e inicie el chat',
        },
        chatTakeLead : {
            en: 'Take the lead on selected chats?',
            it: 'Desideri avere la gestione esclusiva della chat selezionata?'
        },
        chatTakeLeadWarning: {
            en: 'This will remove other chat admins from these chats.',
            it: "Questo rimuovera' automaticamente gli altri colleghi collegati alla chat."
        },
        youAreNowInCharge: {
            en: 'You are now in charge of these chats.',
            it: 'Ora hai tu la gestione di queste chat.',
            es: 'Ahora está a cargo de estos chats.',
        },
        done: {
            en: 'Done!',
            it: 'Fatto!',
            es: '¡Hecho!',
        },
        chatNoLongerAvailable: {
            en: 'This contact button is no longer available',
            it: 'Questo pulsante di contatto non é piú disponibile',
            es: 'Este botón de contacto ya no está disponible',
        },
        profile: {
            en: 'Profile',
            fr: 'Profil',
            nl: 'Profiel',
            pl: 'Profil',
            it: 'Profilo',
            es: 'Perfil',
        },
        navBar: {
            // eventRegistration: {
            //     en: 'Event registration',
            //     nl: 'Event registratie',
            //     pl: 'Rejestracja identyfikatorów',
            // },
            info: {
                en: 'Info',
                fr: 'Info',
                nl: 'Info',
                pl: 'Info',
                it: 'Info',
                es: 'Info',
            },
            cartWidget: {
                addToCart: {
                    en: 'added to your store',
                    fr: 'ajouté(s) à votre magasin',
                    nl: 'toegevoegd aan uw winkel',
                    pl: 'dodany dla Twojego sklepu',
                    it: 'unità nel carrello del tuo punto vendita',
                    es: 'añadido a su punto de venta',
                },
            },
            standCreator: {
                en: 'Stand creator',
                fr: 'Création de stand',
                nl: 'Standcreatie',
                pl: 'Formularz zamówienia',
                it: 'Realizzatore Stand',
                es: 'Creación de stand',
            },
            agenda: {
                en: 'Agenda',
                fr: 'Agenda',
                nl: 'Agenda',
                pl: 'Program',
                it: 'Agenda',
                es: 'Agenda',
            },
            orders: {
                en: 'Orders',
                fr: 'Commandes',
                nl: 'Bestellingen',
                pl: 'Zamówienia',
                it: 'Ordini',
                es: 'Pedidos',
            },
            catalog: {
                en: 'Catalog',
                fr: 'Catalogue',
                nl: 'Catalogus',
                pl: 'Katalog',
                it: 'Catalogo',
                es: 'Catálogo',
            },
            cart: {
                en: 'My cart',
                fr: 'Mon panier',
                nl: 'Mijn winkelmandje',
                pl: 'Mój koszyk',
                it: 'Il mio carrello',
                es: 'Mi carro',
            },
            myOrders: {
                en: 'My orders',
                fr: 'Mes commandes',
                nl: 'Mijn bestellingen',
                pl: 'Moje zamówienia',
                it: 'I miei ordini',
                es: 'Mis pedidos',
            },
            manager: {
                en: 'Manager',
                fr: 'Manager',
                nl: 'Manager',
                pl: 'Manager',
                it: 'Manager',
                es: 'Manager',
            },
            myConnections: {
                en: 'My connections',
                fr: 'Mes liens',
                nl: 'Mijn connecties',
                pl: 'Moje kontakty',
                it: 'I miei contatti',
                es: 'Mis contactos',
            },
            help: {
                en: 'Questions?',
                fr: 'Questions ?',
                nl: 'Vragen?',
                pl: 'Pytania?',
                it: 'Domande Frequenti',
                es: 'Preguntas Frecuentes',
            },
            products: {
                en: 'Products',
                fr: 'Produits',
                nl: 'Producten',
                pl: 'Produkty',
                it: 'Prodotti',
                es: 'Productos',
            },
            reports: {
                en: 'Reports',
                fr: 'Rapports',
                nl: 'Rapporten',
                pl: 'Raporty',
                it: 'Reports',
                es: 'Informes',
            },
            chats: {
                en: 'Chats',
                fr: 'Chats',
                nl: 'Chats',
                pl: 'Czat',
                it: 'Chats',
                es: 'Chats',
            },
            pages: {
                en: 'Pages',
                fr: 'Pages',
                nl: "Pagina's",
                pl: 'Strony',
                it: 'Pagine',
                es: 'Páginas',
            },
            production: {
                en: 'Production',
                fr: 'Production',
                nl: 'Productie',
                pl: 'Produkcja',
                it: 'Produzione',
                es: 'Producción',
            },
            stands: {
                en: 'Stands',
                fr: 'Stands',
                nl: 'Standen',
                pl: 'Stoiska',
                it: 'Stands',
                es: 'Stands',
            },
            supplierCatalog: {
                en: 'Supplier catalog',
                fr: 'Catalogue fournisseurs',
                nl: 'Catalogus leverancier',
                pl: 'Katalog produktów',
                it: 'Catalogo fornitori',
                es: 'Catálogo de proveedores',
            },
            users: {
                en: 'Users',
                fr: 'Utilisateurs',
                nl: 'Gebruikers',
                pl: 'Użytkownicy',
                it: 'Utenti',
                es: 'Usuarios',
            },
            prospects: {
                en: 'Prospects',
                fr: 'Prospects',
                nl: 'Prospects',
                pl: 'Baza kontaktów',
                it: 'Potenziali',
                es: 'Perspectivas',
            },
            packages: {
                en: 'Packages',
                fr: 'Paquets',
                nl: 'Pakket',
                pl: 'Pakiety',
                it: 'Pacchetti',
                es: 'Packs',
            },
            storeOrders: {
                en: 'Store orders',
                fr: 'Commandes magasins',
                nl: 'Bestellingen winkel',
                pl: 'Zamówienia',
                it: 'Ordini punto vendita',
                es: 'Pedidos de tiendas',
            },
            eventSettings: {
                en: 'Event registration settings',
                fr: "Paramètres d'inscription aux événements",
                nl: 'Eventregistratie instellingen',
                pl: 'Ustawienia rejestracji na targi',
                it: "Parametri per la registrazione all'evento",
                es: 'Configuración de la inscripción en el evento',
            },
            madeByDSide: {
                en: 'Made by d-side group',
                fr: 'Réalisé par d-side group',
                nl: 'Gemaakt door d-side group',
                pl: 'Made by d-side group',
                it: 'Realizzato da d-side group',
                es: 'Realizado por d-side group',
            },
            settings: {
                en: 'Settings',
                fr: 'Paramètres',
                nl: 'Instellingen',
                pl: 'Ustawienia',
                it: 'Impostazioni',
                es: 'Ajustes',
            },
            updateProfile: {
                en: 'Update my profile',
                fr: 'Mettre à jour mon profil',
                nl: 'Update mijn profiel',
                pl: 'Zaktualizuj profil',
                it: 'Aggiornare il mio profilo',
                es: 'Actualizar mi perfil',
            },
            disconnect: {
                en: 'Disconnect',
                fr: 'Se déconnecter',
                nl: 'Uitloggen',
                pl: 'Rozłącz',
                it: 'Disconnettersi',
                es: 'Desconectar',
            },
            letsConnectHome: {
                en: "Let's connect Home",
                fr: "Let's connect Home",
                nl: "Let's connect Startpagina",
                pl: "Let's connect Strona główna",
                it: "Let's connect Home",
                es: "Let's connect Home",
            },
            visuals: {
                en: 'Visuals',
                fr: 'Visuels',
                nl: 'Afbeeldingen',
                pl: 'Grafika',
                it: 'Grafiche',
                es: 'Diseños para impresión',
            },
            fairParticipation: {
                en: 'Fair Participation',
                fr: 'Participation au salon',
                nl: 'Beursdeelname',
                pl: 'Uczestnictwo w targach',
                it: '',
                es: 'Participación en la feria',
            },
        },
        profileSettings: {
            en: 'Profile settings',
            fr: 'Paramètres de profil',
            nl: 'Profiel instelling',
            pl: 'Ustawienia profilu',
            it: 'Impostazioni del profilo',
            es: 'Configuración del perfil',
        },
        companyInfo: {
            en: 'Company info',
            fr: "Information d'entreprise",
            nl: 'Bedrijfsinfo',
            pl: 'Informacje o Firmie',
            it: "Informazioni sull'azienda",
            es: 'Información de la empresa',
        },
        archivedProject: {
            en: 'Note: You are viewing the info of the past fair',
            fr: "Remarque. Vous regardez l'information de salon précédent",
            nl: 'Opmerking: je kijkt naar de informatie van de vorige beurs',
            pl: '',
            it: '',
        },
        myCompany: {
            en: 'My company',
            fr: 'Ma société',
            nl: 'Mijn bedrijf',
            pl: 'Moja Firma',
            it: 'La mia Societá',
            es: 'Mi empresa',
        },
        street: {
            en: 'Street',
            fr: 'Rue',
            nl: 'Straat',
            pl: 'Ulica',
            it: 'Indirizzo',
            es: 'Calle',
        },
        zipCode: {
            en: 'Zip code',
            fr: 'Code postal',
            nl: 'Postcode',
            pl: 'Kod pocztowy',
            it: 'Codice Postale',
            es: 'Código Postal',
        },
        city: {
            en: 'City',
            fr: 'Ville',
            nl: 'Stad',
            pl: 'Miasto',
            it: 'Cittá',
            es: 'Ciudad',
        },
        country: {
            en: 'Country',
            fr: 'Pays',
            nl: 'Land',
            pl: 'Kraj',
            it: 'Paese',
            es: 'País',
        },
        website: {
            en: 'Website',
            fr: 'Site web',
            nl: 'Website',
            pl: 'Strona www.',
            it: 'Sito Web',
            es: 'Web',
        },
        phone: {
            en: 'Phone',
            fr: 'N° de téléphone',
            nl: 'Telefoonnummer',
            pl: 'Telefon',
            it: 'Numero di telefono',
            es: 'Teléfono',
        },
        email: {
            en: 'Email',
            fr: 'E-mail',
            nl: 'E-mail',
            pl: 'E-mail',
            it: 'Email',
            es: 'Email',
        },
        emailLowerCase: {
            en: 'email',
            fr: 'e-mail',
            nl: 'e-mail',
            pl: 'e-mail',
            it: 'email',
            es: 'email',
        },
        newEmail: {
            en: 'Send Email',
            fr: 'Envoyer E-mail',
            nl: 'Verstuur E-mail',
            pl: 'Wyślij Email',
            it: 'Invia Email',
            es: 'Enviar Email',
        },
        vat: {
            en: 'VAT',
            fr: 'TVA',
            nl: 'BTW',
            pl: 'NIP',
            it: 'Partita Iva',
            es: 'CIF',
        },
        select: {
            en: 'Select',
            fr: 'Sélectionner',
            nl: 'Selecteer',
            pl: 'Wybierz',
            it: 'Selezionare',
            es: 'Seleccione',
        },
        selected: {
            en: 'Selected',
            fr: 'Sélectionné',
            nl: 'Geselecteerd',
            pl: 'Wybrany',
            it: 'Selezionato',
            es: 'Seleccionado',
        },
        meters: {
            en: 'meters',
            fr: 'mètres',
            nl: 'meter',
            pl: 'metrów',
            it: 'metri',
            es: 'metros',
        },
        piece: {
            en: 'piece',
            fr: 'unité',
            nl: 'stuk',
            pl: 'szt.',
            it: 'unitá',
            es: 'unidad',
        },
        pieces: {
            en: 'pieces',
            fr: 'unités',
            nl: 'stuks',
            pl: 'szt.',
            it: 'unitá',
            es: 'unidades',
        },
        moreDetails: {
            en: 'More details',
            fr: 'Plus de détails',
            nl: 'Meer details',
            pl: 'Więcej szczegółów',
            it: 'Maggiori dettagli',
            es: 'Más detalles',
        },
        priceOnDemand: {
            en: 'Price on demand',
            fr: 'Prix sur demande',
            nl: 'Prijs op aanvraag',
            pl: 'Cena na zapytanie',
            it: 'Prezzo su richiesta',
        },
        sendMessage: {
            en: 'Send message',
            fr: 'Envoyer message',
            nl: 'Stuur een bericht',
            pl: 'Wyślij wiadomość',
            it: 'Invia il messaggio',
            es: 'Enviar mensaje',
        },
        yourContact: {
            en: "Your contact @ Let's connect",
            fr: "Votre contact @ Let's connect",
            nl: "Jouw contact @ Let's connect",
            pl: "Twój kontakt na platformie Let's connect",
            it: "Il tuo contatto @ Let's connect",
            es: "Tu contacto @ Let's connect",
        },
        create: {
            en: 'Create',
            fr: 'Créer',
            nl: 'Creëren',
            pl: 'Utwórz',
            it: 'Crea',
            es: 'Crear',
        },
        // Support Chat
        supportChatTitle: {
            en: 'Need help?',
            fr: "Besoin d'aide ?",
            nl: 'Heb je hulp nodig?',
            pl: 'Potrzebujesz pomocy?',
            it: 'Ha bisogno di aiuto?',
            es: '¿Necesita ayuda?',
        },
        supportAutomatedMessage: {
            en: 'Can we help you ? Write down your questions here. Forgive us, we are not always behind our pc ;) No worries, we’ll be back to you as soon as possible!',
            fr: 'Besoin d’aide ? Posez vos questions ici. Pardonnez-nous, nous ne sommes pas toujours derrière notre écran ;) Pas d’inquiétude, nous revenons vers vous dès que possible !',
            nl: 'Kunnen we jou helpen ? Stel je vragen hier. Vergeef ons, we zijn niet altijd bezig achter onze computer ;) Geen zorgen, we komen zo snel mogelijk bij u terug!',
            pl: 'Jak możemy pomóc? Proszę napisz tutaj swoje pytanie. Wybacz nie zawsze jesteśmy przy komputerze, ale nie martw się, jak tylko wrócimy, niezwłocznie odpiszemy.',
            it: 'Possiamo aiutarla? Scriva qui le sue domande. Ci scusiamo, non siamo sempre dietro il nostro pc ;) Ma non si preoccupi le daremo riscontro al piú presto!',
            es: '¿Podemos ayudarle? Escriba aquí sus preguntas. Lo siento, no siempre estamos detrás de nuestro ordenador ;) Pero no se preocupe, nos pondremos en contacto con usted lo antes posible. ',
        },
        somethingWentWrong: {
            en: 'Something went wrong',
            fr: 'Il y a eu une erreur',
            nl: 'Er is iets misgegaan',
            pl: 'Coś poszło nie tak',
            it: 'Qualcosa non ha funzionato',
            es: 'Algo ha ido mal',
        },
        somethingWentWrongFeedback: {
            en: 'Report feedback',
            fr: 'Remonter du feedback',
            nl: 'Rapporteer feedback',
            pl: 'Zgłoś błąd',
            it: 'Report del feedback',
            es: 'Informe del feedback',
        },
        included: {
            en: 'included',
            fr: 'inclus',
            nl: 'inbegrepen',
            pl: 'wliczone',
            it: 'incluso',
            es: 'incluido',
        },
        parking: {
            en: 'Parking',
            fr: 'Parking',
            nl: 'Parkeren',
            pl: 'Parking',
            it: 'Parcheggio',
            es: 'Parking',
        },
        download: {
            en: 'Download',
            fr: 'Télécharger',
            nl: 'Download',
            pl: 'Pobierz',
            it: 'Scarica',
            es: 'Descargar',
        },
        downloading: {
            en: 'Downloading…',
            fr: 'Téléchargement…',
            nl: 'Aan het downloaden…',
            pl: 'Pobieranie…',
            it: 'Caricamento…',
            es: 'Descargando…',
        },
        generating: {
            en: 'Generating…',
        },
        selectImage: {
            en: 'Select an image…',
            fr: 'Ajouter une photo…',
            es: 'Seleccione una imagen…',
        },
        cart: {
            en: 'Cart',
            fr: 'Panier',
            nl: 'Winkelmandje',
            pl: 'Koszyk',
            it: 'Carrello',
            es: 'Carrito',
        },
        orderDetails: {
            en: 'Order details',
            fr: 'Details de commande',
            nl: 'Details bestelling',
            pl: 'Szczegóły zamówienia',
            it: 'Dettagli ordini',
            es: 'Detalles del pedido',
        },
        searchContacts: {
            en: 'Search contacts',
            fr: 'Recherche de contacts',
            nl: 'Zoek contacten',
            pl: 'Wyszukaj kontakt',
            it: 'Ricerca contatti',
            es: 'Búsqueda de contactos',
        },
        exhibitor: {
            en: 'Exhibitor',
            fr: 'Exposant',
            nl: 'Exposant',
            pl: 'Wystawca',
            it: 'Espositore',
            es: 'Expositor',
        },
        // more: {
        //     en: 'More',
        //     fr: 'Plus',
        //     nl: 'Meer',
        //     it: 'Più',
        // },
        // less: {
        //     en: 'Less',
        //     fr: 'Moins',
        //     nl: 'Minder',
        //     it: 'Meno',
        // },
        all: {
            en: 'All',
            fr: 'Tout',
            nl: 'Allemaal',
            pl: 'Wszystko',
            it: 'Tutto',
            es: 'Todo',
        },
        copyToProject: {
            en: 'Copy to project',
            fr: 'Copier dans le projet',
            nl: 'Kopieer naar het project',
            it: 'Copia nel progetto',
            es: 'Copiar al proyecto',
        },
        title: {
            en: 'Title',
            fr: 'Titre',
            nl: 'Titel',
            it: 'Titolo',
            es: 'Título',
        },
        description: {
            en: 'Description',
            fr: 'Description',
            nl: 'Omschrijving',
            it: 'Descrizione',
            es: 'Descripción',
        },
        quantity: {
            en: 'Quantity',
            fr: 'Quantité',
            nl: 'Aantal stuks',
            pl: 'Ilość',
            it: 'Quantità',
            es: 'Cantidad',
        },
        discount: {
            en: 'Discount',
            fr: 'Remise',
            nl: 'Korting',
            pl: 'Rabat',
            it: 'Discount',
            es: 'Descuento',
        },
        products: {
            en: 'Products',
            fr: 'Produits',
            nl: 'Producten',
            pl: 'Produkty',
            it: 'Prodotti',
            es: 'Productos',
        },
        archived: {
            en: 'Archived',
            fr: 'Archivé',
            nl: 'Gearchiveerd',
            pl: 'Zarchiwizowany',
            it: 'Archiviato',
            es: 'Archivado',
        },
        workshops: {
            en: 'Workshops',
            fr: 'Ateliers',
        },
        duplicate: {
            en: 'Duplicate',
            fr: 'Dupliquez',
            nl: 'Dupliceren',
            pl: 'Duplikuj',
        },
        properties: {
            en: 'Properties',
            fr: 'Propriétés',
            nl: 'Eigenschappen',
            pl: 'Właściwości',
        },
        access: {
            en: 'Access',
            fr: 'Accès',
            nl: 'Toegang',
            pl: 'Dostęp',
        },
        search: {
            en: 'Search',
            fr: 'Rechercher',
            nl: 'Zoeken',
            pl: 'Szukaj',
            it: 'Ricerca',
            es: 'Buscar',
        },
        comment: {
            en: 'Comment',
            fr: 'Commenter',
            nl: 'Commentaar',
            pl: 'Komentarz',
            it: 'Commento',
            es: 'Comentario',
        },
    },
    catalog: {
        validateOrderCheck: (storeId: string | number) => ({
            en: `You are now validating all the products in your cart for ${storeId}.`,
            fr: `Vous validez maintenant tous les produits de votre panier pour ${storeId}.`,
            nl: `U bent nu alle producten in uw winkelwagen aan het valideren voor ${storeId}.`,
            pl: `Potwierdzasz teraz wszystkie produkty z Twojego koszyka dla ${storeId}.`,
            it: `Ora stai convalidando tutti i pacchetti nel carrello per ${storeId}.`,
        }),
        validate: {
            en: 'Validate',
            fr: 'Valider',
            nl: 'Valideer',
            pl: 'Potwierdź',
            it: 'Validare',
        },
        brands: {
            en: 'Brands',
            fr: 'Marques',
            nl: 'Merken',
            pl: 'Marki',
        },
        articleCode: {
            en: 'Code',
            fr: 'Code',
            nl: 'Code',
            pl: 'Kod Produktu',
            it: 'Codice',
        },
        delivery: {
            en: 'Delivery',
            fr: 'Livraison',
            nl: 'Levering',
            pl: 'Dostawa',
            it: 'Consegna',
        },
        brand: {
            en: 'Brand',
            fr: 'Fournisseur',
            nl: 'Leverancier',
            pl: 'Marka',
            it: 'Marca',
        },
        unit: {
            en: 'piece',
            fr: 'pièce',
            nl: 'stuk',
            pl: 'szt.',
            it: 'pezzo',
            es: 'unidad',
        },
        alreadyOrdered: {
            en: 'Note. This product is already ordered for',
            fr: 'Remarque : Ce produit est déjà commandé par le magasin',
            nl: 'Let op. Dit product is al besteld door winkel',
            pl: 'Uwaga: Ten product został już zamówiony dla',
            it: 'Nota. Questo prodotto é giá stato ordinato per',
        },
        nextProduct: {
            en: 'Next product',
            fr: 'Produit suivant',
            nl: 'Volgend product',
            pl: 'Następny produkt',
            it: 'Prossimo prodotto',
            es: 'Próximo producto',
        },
        back: {
            en: 'Go back',
            it: 'Torna indietro',
        },
        qty: {
            en: 'Qty',
            fr: 'Qté',
            nl: 'Aantal',
        },
        ordered: {
            en: 'Ordered',
            fr: 'Commandé',
            nl: 'Besteld',
            pl: 'Zamówiono',
            it: 'Ordinare',
        },
        orderedOnImage: (quantity: number) => ({
            en: `In cart ${quantity} ${quantity > 1 ? 'pieces' : 'piece'}`,
            fr: `${quantity} ${quantity > 1 ? 'pièces' : 'pièce'} dans le panier`,
            nl: `In mandje ${quantity} ${quantity > 1 ? 'stuks' : 'stuk'}`,
            pl: `W koszyku ${quantity} x INK`,
            it: `${quantity} pz. nel carrello`,
            es: `${quantity} ${quantity > 1 ? 'unidades en la cesta' : 'unidad en la cesta'}`,
        }),
        limitedQuantity: {
            en: 'Available qty:',
            fr: 'Qté dispo :',
            nl: 'Beschikbare hoeveelheid:',
            pl: 'Dostępna ilość:',
            it: 'Quantità disponibile:',
        },
        outOfStock: {
            en: 'Out of stock',
            it: 'Esaurito',
        },
        limitedDates: (dates: string) => ({
            en: `Availability: only ${dates}`,
            fr: `Dispo : ${dates} uniquement`,
            nl: `Beschikbaar : Enkel ${dates}`,
            pl: `Dostępność : tylko do ${dates}`,
            it: `Disponibilità : solo il ${dates}`,
        }),
        invoicePriceProduct: {
            en: 'PRODUCT PRICE',
            it: 'PREZZO DI CESSIONE',
        },
        invoicePricePackage: {
            en: 'PACKAGE PRICE',
            it: 'PC PACCHETTO',
        },
        netWithDiscount: {
            en: '(net with discount)',
            it: '(al netto dello sconto)',
        },
    },
    cart: {
        validateOrder: {
            en: 'Validate order',
            fr: 'Valider votre commande',
            nl: 'Valideer mijn bestelling',
            pl: 'Potwierdź zamówienie',
            it: "Valida l'ordine",
            es: 'Validar pedido',
        },
        removeFilters: {
            en: 'Remove filters',
            fr: 'Supprimer les filtres',
        },
        totalInvoicePrice: {
            en: 'Total Invoice Price',
            fr: 'Prix Total de Facturation',
            nl: 'Totale Factuurprijs',
            pl: 'Razem cena zakupu netto',
            it: 'Totale prezzo di cessione',
            es: 'Precio Total Factura',
        },
        grandOrderTotal: {
            en: 'Grand Order Total',
        },
        toBePaid: {
            en: 'To Be Paid',
            fr: 'A Payer',
            nl: 'Te Betalen',
            pl: 'Do zapłaty netto',
            it: 'To Be Paid',
        },
        totalIT: {
            en: 'Total PC',
            it: 'Total PC',
        },
        discountTotal: {
            en: 'Discount total',
            it: 'Sconto totale',
        },
        week: {
            en: 'Week',
            fr: 'Semaine',
            nl: 'Week',
            pl: 'Tydzień',
            it: 'Settimana',
        },
        invoicePrice: {
            en: 'Invoice Price',
            pl: 'Cena zakupu netto',
        },
        monthlyEngagement: {
            en: 'Your monthly engagement',
            fr: 'Vos engagements mensuel',
        },
        cartByWorkshop: {
            en: 'Cart by Workshop',
            fr: 'Panier par Atelier',
        },
        rangeOfWorkshops: {
            en: 'Range of workshops',
            fr: 'Éventails d’ateliers',
        },
        returnToYourCart: {
            en: 'Return to your cart',
            fr: 'Retour vers votre panier',
        },
        workshop: {
            en: 'Workshop',
            fr: 'Atelier',
        },
        viewCart: {
            en: 'View cart',
            fr: 'Voir Panier',
        },
    },
    order: {
        toYourFinancialReport: {
            en: 'To your financial report',
            fr: 'Vers votre étalement financier',
        },
        financialReport: {
            en: 'Financial Report',
            fr: 'Étalement financier',
        },
        returnToYourOrders: {
            en: 'Return to your orders',
            fr: 'Retour vers vos commandes',
        },
        seeOrders: {
            en: 'See orders',
            fr: 'Voir les Commandes',
        },
    },
    companyPage: {
        main: {
            en: 'Main',
            fr: 'Principal',
            pl: 'Dane firmy',
            it: 'Principale',
            es: 'Principal',
        },
        invoicing: {
            en: 'Invoicing',
            pl: 'Dane do faktury',
            it: 'Fatturazione',
            es: 'Facturación',
        },
        poc: {
            en: 'Point of contact',
            pl: 'Główny kontakt',
            it: 'Persona di contatto',
            es: 'Contacto principal',
        },
        poa: {
            en: 'Point of contact for accounting',
            pl: 'Osoba do kontaktu (fakturowanie)',
            it: 'Persona di contatto per la contabilitá',
            es: 'Persona de contacto (facturación)',
        },
        allowPageEdit: {
            en: 'Allow page edit',
            es: 'Permitir la edición de la página',
        },
        system: {
            en: 'System',
            fr: 'Système',
            pl: 'Sektor',
            it: 'Sistema',
            es: 'Sector',
        },
        storeId: {
            en: 'Store ID',
            fr: 'ID du Magasin',
            nl: 'Winkel ID',
            pl: 'ID Sklepu',
            it: 'Numero del punto vendita',
            enFR: 'Code Prolog',
            frFR: 'Code Prolog',
        },
        codes: {
            en: 'Codes',
            it: 'Codici'
        },
        primarySector: {
            en: 'Primary sector',
            pl: 'Sektor główny',
            it: 'Settore primario',
            es: 'Sector primario',
        },
        secondarySector: {
            en: 'Secondary sector',
            pl: 'Sektor dodatkowy',
            it: 'Settore secondario',
            es: 'Sector secundario',
        },
    },
    contacts: {
        comment: {
            en: 'Type your comment... (visible only to your team)',
            fr: 'Tapez votre commentaire... (visible seulement par votre équipe)',
            nl: 'Typ jouw opmerking... (enkel zichtbaar voor jouw team)',
            pl: 'Napisz wiadomość... (widoczne tylko dla Twojego zespołu)',
            it: 'Scrivi un commento... (visibile solo al tuo team)',
            es: 'Escribe tu comentario... (solo visible para tu equipo)',
        },
        noFeed: {
            en: 'No comments or emails yet',
            fr: 'Pas encore de commentaires ou e-mails',
            nl: 'Nog geen opmerkingen of e-mails',
            pl: 'Brak komentarzy lub wiadomości',
            it: 'Nessun commento o email',
            es: 'Sin comentarios o emails',
        },
        myDetails: {
            en: 'My personal details',
            fr: 'Mes données personnelles',
            nl: 'Mijn persoonlijke gegevens',
            pl: 'Moje dane osobowe',
            it: 'Informazioni personali',
            es: 'Precio a consultar',
        },
        members: {
            en: 'Company Members',
            fr: 'Les collègues inscrit sous la même société',
            nl: 'Bedrijfsleden',
            pl: 'Członkowie Firmy',
            it: 'I colleghi della sua societá',
            es: 'Compañeros registrados en la misma empresa',
        },
        registeredFor: {
            en: 'Registered for:',
            fr: 'Inscrit pour :',
            nl: 'Ingeschreven voor:',
            pl: 'Zarejstrowany na:',
            it: 'Registrato per:',
            es: 'Registrado para:',
        },
    },
    timelineStand: {
        submittedStand: {
            en: 'submitted a',
            pl: '- złożone zapytanie o',
            it: 'ha inviato una richiesta di',
            es: '- presentó una solicitud de',
        },
        submittedStandName: {
            en: 'stand',
            pl: 'stoisko',
            it: 'stand',
            es: 'stand',
        },
        for: {
            en: 'for',
            it: 'per il',
            pl: 'na',
            es: 'para',
        },
        approvedStand1: {
            en: ' ',
            pl: ' ',
            it: 'Lo stand ',
            es: 'El stand de ',
        },
        approvedStand2: {
            en: 'stand is <b>approved</b>',
            pl: '- stoisko <b>zaakceptowane</b>',
            it: 'é stato <b>approvato</b>',
            es: 'está <b>aprobado</b>',
        },
    },
    timelineInvoice: {
        receivedInvoice: {
            en: ' received an <b>invoice</b>',
            pl: ' - otrzymana <b>faktura</b>',
            it: ' ha ricevuto una <b>fattura</b>',
            es: ' recibió la <b>factura</b>',
        },
        receivedCreditNote: {
            en: ' received a <b>credit note</b>',
            pl: ' - otrzymana <b>nota kredytowa</b>',
            it: ' ha ricevuto una <b>nota di credito</b>',
        },
        invoiceNumber: {
            en: 'Invoice number:',
            pl: 'Numer faktury:',
            it: 'Numero fattura:',
            es: 'Número de factura:',
        },
        creditNoteNumber: {
            en: 'Credit note number:',
            pl: 'Numer nota kredytowa:',
            it: 'Numero nota di credito:',
        },
        date: {
            en: 'Date:',
            pl: 'Data:',
            it: 'Data:',
            es: 'Fecha:',
        },
        amount: {
            en: 'Amount:',
            pl: 'Kwota:',
            it: 'Importo:',
            es: 'Cantidad:',
        },
        pdf: {
            en: 'Download PDF',
            fr: 'Téléchargez le PDF',
            nl: 'Download de PDF',
            pl: 'Pobierz PDF',
            it: 'Scaricare il PDF',
            es: 'Descargar PDF',
        }
    },
    standCreation: {
        conceptStandSubmitQuestion: (projectName: string) => ({
            en: `You are submitting a request for the ${projectName}`,
            fr: `Vous soumettez une requête pour le ${projectName}`,
            nl: `Jullie dienen een aanvraag in voor ${projectName}`,
            pl: `Składasz zamówienie na ${projectName}`,
            it: `Sta inviando una richiesta per il ${projectName}`,
            es: `Está enviando una solicitud para el ${projectName}`,
        }),
        conceptStandConfirmButton: {
            en: 'Submit for approval',
            fr: 'Soumettre pour approbation',
            nl: 'Verzend voor goedkeuring',
            pl: 'Zatwierdź do weryfikacji',
            it: 'Inviare per approvazione',
            es: 'Enviar para su aprobación',
        },
        additionalMaterial: {
            en: 'Additional material',
            fr: 'Matériel complémentaire',
            nl: 'Extra materiaal',
            pl: 'Materiały dodatkowe',
            it: 'Materiale aggiuntivo',
            es: 'Material adicional',
        },
        addingAdditionalMaterial: {
            en: 'Please select the package before adding additional material',
            fr: 'Veuillez sélectionner votre package avant d’ajouter du matériel complémentaire',
            nl: 'Selecteer het pakket voordat u extra materiaal toevoegt',
            pl: 'Proszę wybrać pakiet przed dodaniem materiałów dodatkowych',
            it: 'Per favore selezioni il pacchetto prima di aggiungere materiale addizionale',
            es: 'Seleccione el pack antes de añadir material adicional',
        },
        conceptStandDeclineButton: {
            en: 'Cancel',
            fr: 'Annuler',
            nl: 'Annuleren',
            pl: 'Anuluj',
            it: 'Cancella',
            es: 'Cancelar',
        },
        conceptStand: {
            en: 'Concept stand',
            pl: 'Koncept',
            es: 'Concept',
        },
        title: {
            en: "Let's start the configuration of your participation",
            fr: 'Démarrons la configuration de votre participation',
            nl: 'Begin aan jouw configuratie van jouw deelname',
            pl: 'Rozpocznij konfigurację Twojego uczestnictwa',
            it: 'Inizi a configurare la sua partecipazione',
            es: 'Comencemos la configuración de su participación',
        },
        participation: {
            en: 'Which kind of participation do you wish?',
            fr: 'Quel type de participation souhaitez-vous ?',
            nl: 'Wat soort deelname wens jij?',
            pl: 'Jaką formułę uczestnictwa wybierasz?',
            it: 'Quale tipo di partecipazione desidera?',
            es: '¿Qué tipo de participación desea?',
        },
        mapAndPreferences: {
            question: {
                en: 'View map',
                fr: 'Voir plan',
                nl: 'Bekijk het plan',
                pl: 'Zobacz plan',
                it: 'Guardi la pianta',
                es: 'Ver mapa',
            },
            // viewMapAndPreferences:{
            //     en: 'View map and add preferences',
            //     fr: "Voir plan & ajouter vos préférences",
            //     nl: 'Bekijk het plan en voeg jouw voorkeuren toe',
            //     pl: 'Zobacz plan i dodaj swoje preferencje lokalizacji',
            //     it: 'Guardi la pianta ed aggiunga le sue preferenze',
            //     es: 'Ver mapa y añadir sus preferencias',
            // },
            // viewFullMap:{
            //     en: 'View full map',
            //     fr: "Voir plan détaillé",
            //     nl: 'Bekijk volledige map',
            //     pl: 'Zobacz pełny plan',
            //     it: 'Guardi la pianta dettagliata',
            //     es: 'Ver mapa completo',
            // },
            notes: {
                en: 'Notes',
                es: 'Notas',
            },
            yourPreferences: {
                en: 'Tell us about your preferences',
                fr: 'Dites-nous en plus sur vos préférences',
                nl: 'Vertel ons over jouw voorkeuren',
                pl: 'Podaj preferencje dotyczące lokalizacji (numer stoiska)',
                it: 'Ci dica qualcosa in piú sulle sue preferenze',
                es: 'Díganos más sobre sus preferencias',
            },
        },
        surface: {
            en: 'How much surface do you need?',
            fr: 'De quelle surface avez-vous besoin ?',
            nl: 'Hoeveel oppervlakte heb je nodig?',
            pl: 'Ile powierzchni potrzebujesz?',
            it: 'Di quanti metri ha bisogno?',
            es: '¿Cuánta superficie necesita?',
        },
        standType: {
            fresh: {
                en: 'Fresh Market',
                fr: 'Marché du frais',
                nl: 'Versmarkt',
                pl: 'Targ Produktów Świeżych',
                it: 'Mercato Fresco',
                es: 'Mercado Fresco',
            },
            stand: {
                en: 'Stand',
                fr: 'Stand',
                nl: 'Stand',
                pl: 'Stoisko',
                it: 'Stand',
                es: 'Stand',
            },
            concept: {
                en: 'Concept',
                fr: 'Concept',
                nl: 'Concept',
                pl: 'Koncept',
                it: 'Concept',
                es: 'Concepto',
            },
            conceptES: {
                en: 'Concept/Regional Market',
                es: 'Concepto/Mercado Regional',
            },
        },
        sharedStand: {
            en: 'Shared Stand',
            fr: 'Stand partagé',
            nl: 'Gedeelde stand',
            pl: 'Stoisko współdzielone',
            it: 'Stand Condiviso',
            es: 'Stand compartido',
        },
        setup: {
            question: {
                en: 'What setup do you prefer?',
                fr: 'Quelle implantation préférez-vous ?',
                nl: 'Welke opstelling heeft jouw voorkeur?',
                pl: 'Jakie ustawienie preferujesz?',
                it: 'Quale allestimento preferisce?',
                es: '¿Qué configuración prefiere?',
            },
            classicStand: {
                en: 'Classic stand',
                fr: 'Stand classique',
                nl: 'Klassieke stand',
                pl: 'Stoisko klasyczne (liniowe)',
                it: 'Stand Classico',
                es: 'Stand Clasico',
            },
            cornerStand: (value: string | number) => ({
                en: `Corner Stand, add an extra cost of +${value}%`,
                fr: `Stand de coin (majoration de ${value}%)`,
                nl: `Hoekstand, voeg een toeslag toe van +${value}%`,
                pl: `Stoisko narożne, dodatkowy koszt +${value}% z metra`,
                it: `Stand ad angolo, comporta un extra costo del ${value}%`,
                es: `Stand en esquina, conlleva un coste adicional del ${value}%`,
            }),
            openStand: (value: string | number) => ({
                en: `Open Stand, add an extra cost of +${value}%`,
                fr: `Stand 4 faces ouvertes (majoration de ${value}%)`,
                // nl: `Hoekstand, voeg een toeslag toe van +${value}%`,
                // pl: `Stoisko narożne, dodatkowy koszt +${value}% z metra`,
                // it: `Stand ad angolo, comporta un extra costo del ${value}%`,
            }),
            // cornerStandAlt: {
            //     en: 'Corner Stand, extra cost of +14%',
            //     fr: 'Stand de coin (majoration de 14%)',
            //     nl: 'Hoekstand, voeg een toeslag toe van +14%',
            //     pl: 'Stoisko narożne, dodatkowy koszt +14% z metra',
            //     it: 'Stand ad angolo, comporta un extra costo del 14%',
            // },
            cornerStandCheckout: {
                en: 'Corner Stand',
                fr: 'Stand de coin',
                pl: 'stoisko narożne',
                es: 'Stand en esquina',
            },
            headStand: (value: string | number) => ({
                en: `Head Stand, add an extra cost of +${value}%`,
                fr: `Stand de tête (majoration de ${value}%)`,
                nl: `Kopstand, voeg een toeslag toe van +${value}%`,
                pl: `Stoisko czołowe, dodatkowy koszt +${value}% z metra`,
                it: `Stand di testa, comporta un extra costo del ${value}%`,
                es: `Stand frontal, conlleva un coste adicional del ${value}%`,
            }),
            headStandAlt: {
                en: 'Head Stand, extra cost of +18%',
                fr: 'Stand de tête (majoration de 18%)',
                nl: 'Kopstand, voeg een toeslag toe van +18%',
                pl: 'Stoisko czołowe, dodatkowy koszt +18% z metra',
                it: 'Stand di testa, comporta un extra costo del 18%',
            },
            headStandCheckout: {
                en: 'Head stand',
                fr: 'Stand de tête',
                pl: 'stoisko czołowe',
                es: 'Estand frontal',
            },
            sharedStand: {
                en: 'A shared stand is a stand occupied by several exhibitors, without separation walls. E.g. each exhibitors has 6m² for him/her out of a common stand of 12m²',
                fr: 'Un stand partagé est un stand occupé par plusieurs fournisseurs, sans cloisons de séparation. Ex. chaque exposant dispose de 6m² sur un stand commun de 12m²',
                nl: 'Een gedeelde stand is een stand die door meerdere exposanten wordt bezet, zonder scheidingswanden. B.v. elke exposant heeft 6m² voor hen/haar uit een gemeenschappelijke stand van 12m²',
                pl: 'Stoisko współdzielone to stoisko dla kilku wystawców bez separacji ścianami. Np. na stoisku 12m2 przestrzeń wydzielona między dwóch wystawców - dla każdego po 6m2.',
                it: 'Lo stand condiviso é uno stand occupato da due o piú fornitori senza pareti divisorie. Nel caso di due fornitori ognuno avrá 6m² metri a sua disposizione in uno spazio complessivo di 12m²',
                es: 'Un stand compartido es un stand ocupado por varios expositores, sin paredes de separación. Por ejemplo, cada expositor dispone de 6m² para él de un stand común de 12m²',
            },
            selectRange: {
                en: 'Select range',
                fr: "Selectionner l'intervalle",
                nl: 'Selecteer het interval',
                pl: 'Wybierz ekspozycję',
                it: 'Selezionare la categoria',
                es: 'Seleccione la categoría',
            },
            lowRange: {
                FR: (to: number) => ({
                    en: `Low Range (1 to ${to} references)`,
                    fr: `Catégorie basse (1 à ${to} références)`,
                }),
                BE: (to: number) => ({
                    en: `Low Range (1 to ${to} references)`,
                    fr: `Catégorie basse (1 à ${to} références)`,
                    nl: 'Lage categorie',
                }),
                PL: (to: number) => ({
                    en: `Low Range (1 to ${to} references)`,
                    pl: `Mała ekspozycja (1 - ${to} produktów)`,
                }),
                IT: (to: number) => ({
                    en: `Low Range (1 to ${to} references)`,
                    it: `Categoria inferiore (da 1 a ${to} referenze)`,
                }),
                ES: (to: number) => ({
                    en: `Low Range (1 to ${to} references)`,
                    es: `Categoría inferior (de 1 a ${to} referencias)`,
                }),
            },
            highRange: {
                FR: (from: number) => ({
                    en: `High Range (More than ${from} references)`,
                    fr: `Catégorie haute (plus de ${from} références)`,
                }),
                BE: (from: number) => ({
                    en: `High Range (More than ${from} references)`,
                    fr: `Catégorie haute (plus de ${from} références)`,
                    nl: 'Hoge categorie',
                }),
                PL: (from: number) => ({
                    en: `High Range (More than ${from} references)`,
                    pl: `Duża ekspozycja (powyżej ${from} produktów)`,
                }),
                IT: (from: number, to: number) => ({
                    en: `High Range (${from + 1} to ${to} references)`,
                    it: `Categoria superiore (da ${from + 1} a ${to} referenze)`,
                }),
                ES: (from: number) => ({
                    en: `High Range (More than ${from} references)`,
                    es: `Categoría superior (más de ${from} referencias)`,
                }),
            },
        },
        simulation: {
            emplacement: {
                en: 'Emplacement',
                fr: 'Emplacement',
                nl: 'Standplaats',
                pl: 'Powierzchnia targowa',
                it: 'Spazio',
                es: 'Localización',
            },
            surfaceDetail: (surface: number) => ({
                en: `Your emplacement will be ${surface}m²`,
                fr: `Votre emplacement sera de ${surface}m²`,
                nl: `Jouw standplaats zal ${surface}m² zijn`,
                pl: `Twoja powierzchnia wynosi ${surface}m²`,
                it: `Il suo spazio sará di ${surface}m²`,
                es: `Su espacio será de ${surface}m²`,
            }),
            dimensionDetail: (length: number, depth: number) => ({
                en: `${length}m length by ${depth}m in depth *`,
                fr: `${length}m de long sur ${depth}m de profondeur*`,
                nl: `${length}m lengte bij ${depth}m diepte*`,
                pl: `${length}m długość na ${depth}m głębokość*`,
                it: `${length}m di lunghezza e ${depth}m di profonditá*`,
                es: `${length}m de longitud por ${depth}m de profundidad*`,
            }),
            freshDetail: {
                en: 'Your participation is including space in the fridge',
                fr: 'Votre participation inclut un emplacement dans un frigo',
                nl: 'Uw deelname is inclusief ruimte in de koelkast',
                pl: 'Twoje uczestnictwo uwzględnia miejsce na produkty w regałach chłodniczych',
                it: 'La sua partecipazione include lo spazio nel frigorifero',
                es: 'Su participación incluye espacio en el frigorífico',
            },
            includedIn: {
                en: 'Included in',
                fr: 'Inclus dans',
                nl: 'Inbegrepen in ',
                pl: 'Wliczony',
                it: 'Incluso nel',
                es: 'Incluido en',
            },
            notIncluded: {
                en: 'not included',
                fr: 'non inclus',
                nl: 'niet inbegrepen',
                pl: 'nie wliczone',
                it: 'non incluso',
                es: 'no includo',
            },
            surfaceWarning: {
                en: "Beware that the useful surface is slightly smaller than the one ordered (e.g. structure incl.). Don't hesitate to contact the organisation for further info.",
                fr: "La surface utile est légèrement inférieure à celle indiquée (Ex. structure incl.). N'hésitez pas à prendre contact avec l'organisation pour plus de détails.",
                nl: 'Pas op dat het nuttige oppervlak kleiner is dan het bestelde oppervlak (bv. structuur incl.). Aarzel niet om contact op te nemen met de organisatie voor meer informatie.',
                pl: 'Uwaga, powierzchnia użytkowa stoiska może być nieznacznie mniejsza z uwagi na szerokość konstrukcji ścian. W przypadku własnej zabudowy, prosimy o kontakt z organizatorem w celu doprecyzowania.',
                it: "La superficie reale é leggermente inferiore a quella indicata (p.e. per le strutture incluse). Non esiti a contattare l'organizzazione per ulteriori informazioni",
                es: 'Tenga en cuenta que la superficie útil es ligeramente inferior a la solicitada (por ejemplo, incluye la estructura). No dude en ponerse en contacto con la organización para obtener más información.',
            },
            stockWarning: {
                en: 'Please note that some products might be out of stock by the time you confirm your stand.',
                fr: 'Veuillez noter que certains produits peuvent être en rupture de stock au moment où vous confirmez votre stand.',
                nl: 'Houd er rekening mee dat sommige producten niet op voorraad kunnen zijn tegen de tijd dat u uw stand bevestigt.',
                pl: 'Niektóre produkty mogą być niedostępne w momencie potwierdzania rezerwacji stoiska.',
                it: 'Al momento della conferma dello stand per favore controlla che i materiali non siano fuori stock.',
                es: 'Tenga en cuenta que algunos productos pueden estar agotados en el momento de confirmar su stand.',
            },
            priceWarning: {
                en: 'Some of your options may request a personalized price and are not included in the total price.',
                fr: 'Certaines de vos options peuvent demander un prix personnalisé et ne sont pas incluses dans le prix total.',
                nl: 'Sommige van uw opties kunnen een gepersonaliseerde prijs vragen en zijn niet inbegrepen in de totale prijs.',
                pl: 'Niektóre z wybranych przez Ciebie opcji mogą wymagać dopasowania i nie są uwzględnione w łacznej kwocie zamówienia.',
                it: 'Alcune delle sue opzioni potrebbero richiedere un prezzo personalizzato e non sono incluse nel prezzo totale.',
                es: 'Algunas de sus opciones pueden requerir un precio personalizado y no están incluidas en el precio total.',
            },
            total: {
                en: 'TOTAL excl. VAT',
                fr: 'TOTAL HTVA',
                nl: 'TOTAAL zonder BTW',
                pl: 'Razem netto (VAT niewliczony)',
                it: 'TOTALE (IVA esclusa)',
                es: 'TOTAL (sin IVA)',
            },
            subtotal: {
                en: 'Subtotal',
                fr: 'Sous-total',
                nl: 'Subtotaal',
                pl: 'suma częściowa',
                it: 'Subtotale',
                es: 'Subtotal ',
            },
            unitPrice: {
                en: 'pu',
                fr: 'pc.',
                nl: 'st.',
                pl: 'szt.',
                it: 'cad.',
                es: 'cada uno',
            },
            savedMsg: {
                en: 'Your simulation is saved!',
                fr: 'Votre simulation est enregistrée!',
                nl: 'Jouw simulatie is opgeslagen!',
                pl: 'Twoja symulacja kosztów została zapisana!',
                it: 'La sua simulazione é stata salvata!',
                es: '¡Su simulación se ha guardado!',
            },
            palletType: {
                en: 'Pallet Type',
                fr: 'Type de palette',
                nl: 'Pallet type',
                pl: 'Rodzaj palety',
                it: 'Tipo di spazio magazzino',
                es: 'Zona de stock',
            },
            selectPalletType: {
                en: 'Select pallet type',
                fr: 'Sélectionner le type de palette',
                nl: 'Kies het type pallet',
                pl: 'Wybierz typ palety',
                it: 'Seleziona il tipo di spazio magazzino',
                es: 'Seleccione el tipo de zona de stock',
            },
            notSelected: {
                en: 'Not selected',
                fr: 'Non selectionné',
                nl: 'Niet geselecteerd',
                pl: 'Nie wybrano',
                it: 'Non selezionato',
                es: 'No seleccionado',
            },
            fairConditions: {
                a: {
                    en: 'I agree with the ',
                    fr: "J'accepte ",
                    nl: 'Ik ga akkoord met de ',
                    pl: 'Zapoznałem się i akceptuję ',
                    it: 'Accetto le ',
                    es: 'Acepto las ',
                },
                b: {
                    en: 'fair conditions',
                    fr: 'les conditions générales',
                    nl: 'algemene voorwaarden',
                    pl: 'warunki udziału w Targach',
                    it: 'condizioni Generali',
                    // es: 'condiciones del Salón',
                    es: 'condiciones generales',
                },
                c: {
                    en: ' and ',
                    fr: ' et ',
                    nl: ' en ',
                    pl: ' oraz ',
                    it: ' ed ',
                    es: ' y ',
                },
                d: {
                    en: 'code of conduct',
                    fr: 'le code de conduite',
                    nl: 'gedragscode',
                    pl: 'kodeks postępowania',
                    it: 'il Codice di condotta',
                    es: 'código de conducta',
                },
                secondOne: {
                    en: 'I hereby confirm that I have the power to sign for my company or brand, and that in no way d-side can be hold liable for wrongly made orders by the exhibitors.',
                    fr: "Je déclare avoir l'autorisation de lancer des demandes d'offres et d'approuver ces offres pour mon entreprise ou marque.",
                    nl: 'Ik verklaar dat ik namens mijn bedrijf de nodige beslissingsbevoegdheid heb om deze aanvraag te plaatsen, en het nodige zal doen om deze correct te laten valideren.',
                    pl: 'Potwierdzam, że jestem osobą upoważnioną do składania zamówień w ramach firmy, którą reprezentuję.',
                    it: 'Dichiaro di avere potere di firma per la mia societá o marca, o di essere in possesso di tutte le autorizzazioni necessarie a sottoscrivere la richiesta di partecipazione, e che in nessun modo d-side potrà essere ritenuta responsabile di ordini erronemente fatti dagli espositori',
                    es: 'Confirmo que estoy facultado para firmar en nombre de mi empresa o marca, y que en ningún caso d-side puede ser considerado responsable de los pedidos realizados erróneamente por los expositores.',
                }
            },
            submitMsg: {
                en: 'Simulations are not binding as long as you do not click on “submit for approval". After submission of your booking, our team will review it make sure that everything is working perfectly for you.',
                fr: `Les simulations n'ont pas valeur d'engagement tant que vous ne cliquez pas sur "soumettre pour approbation". Après la soumission de votre réservation, notre équipe l'examinera pour s'assurer qu'elle correspond à vos attentes et est possible.`,
                nl: 'Simulaties zijn niet bindend zolang u niet op "verzend voor goedkeuring" klikt. Na het indienen van uw reservering zal ons team deze bekijken om er zeker van te zijn dat alles perfect werkt voor u.',
                pl: 'Symulacja nie jest wiążąca dopóki nie naciśniesz na przycisk "Zatwierdź do weryfikacji". Po otrzymaniu zamówienia nasz zespół zweryfikuje informacje i upewni się, że wszystko jest poprawnie zaplanowane.',
                it: 'Le simulazioni non sono vincolanti finché non viene cliccato il tasto «inviare per approvazione ». Dopo aver eseguito l’invio dell’ordine,  il nostro team lo verificherá per accertarsi che tutto sia corretto.',
                es: 'Envíe su simulación a nuestro equipo. Lo comprobaremos para asegurarnos de que es correcto.',
            },
            poQuestion: {
                en: 'Get a PO to attach?',
                fr: 'Joindre un N° de bon de commande ?',
                nl: 'Een PO laten bevestigen?',
                pl: 'Czy masz już numer PO?',
                it: 'Ha un PO da inserire?',
                enES: 'Purchasing Order (You can include it now or at another time)',
                es: 'Número de pedido (lo puede incluir ahora o comunicarlo más adelante)',
            },
            poNumber: {
                en: 'PO number',
                fr: 'N° de bon de commande',
                nl: 'PO-nummer',
                pl: 'Numer PO',
                it: 'Numero di PO',
                es: 'Número de PO (Orden de pedido)',
            },
            submitButton: {
                en: 'Submit for approval',
                fr: 'Soumettre pour approbation',
                nl: 'Verzend voor goedkeuring',
                pl: 'Zatwierdź do weryfikacji.',
                it: 'Inviare per approvazione',
                es: 'Enviar para su aprobación',
            },
            submitted: {
                en: 'Submitted',
                fr: 'Soumis',
                nl: 'Verzonden',
                pl: 'Zatwierdzony',
                it: 'Inviata',
                es: 'Enviada',
            },
            approved: {
                en: 'Approved',
                fr: 'Validé',
                nl: 'Goedgekeurd',
                pl: 'Zaakceptowany',
                it: 'Approvata',
                es: 'Aprobada',
            },
            invoiced: {
                en: 'Invoiced',
                fr: 'Facturé',
                nl: 'Gefactureerd',
                pl: 'Zafakturowano',
                it: 'Fatturato',
                es: 'Facturado',
            },
            errorTermsFirst: {
                en: 'Please configure your participation before submitting',
                fr: 'Veuillez configurer votre participation avant de soumettre votre simulation',
                nl: 'Configureer uw deelname voordat je deze indient',
                pl: 'Proszę utwórz konfigurację swojego uczestnictwa przed wysłaniem do akceptacji',
                it: 'Per favore configuri la sua partecipazioine prima di inviarla',
                es: 'Por favor, configure su participación antes de enviarla',
            },
            errorNoPackage: {
                en: 'Please select a package first.',
                fr: 'Veuillez sélectionner un package.',
                nl: 'Selecteer eerst een package.',
                pl: 'Proszę wybrać najpierw rodzaj pakietu.',
                it: 'Per favore selezioni prima un pacchetto.',
                es: 'Por favor, seleccione primero un paquete.',
            },
        },
        alreadyInYourStand: {
            en: 'already in your stand',
            fr: 'déjà sur votre stand',
            nl: 'Reeds in jouw stand',
            pl: 'już wliczone w Twoje stoisko',
            it: 'Giá presenti nel suo stand',
            es: 'Ya incluido en su stand',
        },
        addedToYourStand: {
            en: 'added to your stand',
            fr: 'Ajouté à votre stand',
            nl: 'Toegevoegd aan jouw stand',
            pl: 'dodane do stoiska',
            it: 'Aggiungere al suo stand',
            es: 'Añadido a su stand',
        },
        freshDetailLow: {
            en: 'The attributed space for your products in the fresh market (or fridges) are equivalent to 1 to 15 references',
            fr: "L'espace attribué dans la zone marché (ou frigos) est de 1 à 15 références",
            nl: 'De toegewezen ruimte op de versmarkt (of in de koelkasten) is van 1 tot 15 referenties.',
            pl: 'Twoja dedykowana przestrzeń na Targu Produktów Świeżych dotyczy ekspozycji 1-15 produktów',
            it: 'Lo spazio assegnato nella zona mercato del fresco (o nei frigoriferi) sará equivalente ad 1-15 referenze',
            es: 'El espacio asignado en la zona de mercado fresco (o en los frigoríficos) equivaldrá a 1-15 referencias.',
        },
        freshDetailHigh: {
            en: 'The attributed space for your products in the fresh market (or fridges) are equivalent to more than 15 references',
            fr: "L'espace attribué dans la zone marché (ou frigos) est de plus de 15 références",
            nl: 'De toegewezen ruimte op de versmarkt (of in de koelkasten) is meer dan 15 referenties. ',
            pl: 'Twoja dedykowana przestrzeń na Targu Produktów Świeżych dotyczy ekspozycji powyżej 15 produktów',
            it: 'Lo spazio assegnato nella zona mercato del fresco (o nei frigoriferi) sará equivalente a piú di 15 referenze',
            es: 'El espacio asignado en la zona de mercado fresco (o en los frigoríficos) equivaldrá a más de 15 referencias.',
        },
        standLayout: {
            list: {
                en: 'Stand layouts',
                fr: 'Disposition des stands',
                pl: 'Układ stoiska',
                it: 'Disposizione degli stand',
                es: 'Diseño del stand',
            },
            fillIn: {
                en: 'Fill in the stand layout',
                fr: 'Remplissez la disposition du stand',
                nl: 'Vul het standplan in',
                pl: 'Naszkicuj plan stoiska',
                it: 'Compili il form della pianta del suo stand',
                es: 'Rellene el formulario de diseño del stand',
            },
            download: {
                en: 'Download layout',
                fr: 'Télécharger la mise en page',
                nl: 'Download de layout',
                pl: 'Pobierz plan stoiska',
                it: 'Scaricare il form della pianta',
                es: 'Descargar el diseño',
            },
            upload: {
                en: 'Upload layout',
                fr: 'Télécharger la mise en page',
                nl: 'Upload de layout',
                pl: 'Załaduj plan stoiska',
                it: 'Caricare il form della pianta',
                es: 'Cargar el diseño',
            },
        },
        packageNotAllowed: {
            en: 'Please select one of the available packages for the selected number of square meters',
            fr: "Veuillez sélectionner l'un des paquets disponibles pour le nombre de mètres carrés sélectionnés",
            nl: 'Selecteer een van de beschikbare pakketten voor het geselecteerde aantal vierkante meters',
            pl: 'Proszę wybierz jeden z dostępnych pakietów dla dedykowanej wielkości stoiska',
            it: 'Scelga uno dei pacchetti disponibili per i metri quadri che ha selezionato',
            es: 'Por favor, seleccione uno de los paquetes disponibles para el número de metros cuadrados seleccionado',
        },
        yourSelectionDeleted: {
            en: 'Your selection has been deleted',
            fr: 'Votre sélection à été supprimée',
            nl: 'Uw selectie is verwijderd',
            pl: 'Twój wybór został usunięty',
            it: 'La sua selezione é stata eliminata',
            es: 'Su selección ha sido eliminada',
        },
        pleaseSelectPalletType: {
            en: 'Please select pallet type before submitting the stand',
            fr: 'Veuillez sélectionner le type de palette avant de soumettre le stand',
            nl: 'Selecteer het type pallet voordat u de stand indient',
            pl: 'Prosimy o wybranie rodzaju palety przed zgłoszeniem stoiska',
            it: 'Per favore seleziona il tipo di spazio magazzino prima di inviare la richiesta di stand',
            es: 'Por favor seleccione qué tipo de zona de stock desea antes de contratar el stand',
        },
        outOfStock: {
            en: 'Some of the additional material that you have selected is out-of-stock !',
            fr: 'Certains des matériaux supplémentaires que vous avez sélectionnés ne sont pas en stock !',
            nl: 'Sommige van de door u geselecteerde aanvullende materialen zijn niet op voorraad !',
            pl: 'Niektóre z wybranych przez Ciebie materiałów dodatkowych są niedostępne !',
            it: 'Alcuni dei materiali extra che hai selezionato non sono disponibili !',
            es: 'Parte del material que ha seleccionado no está disponible !',
        },
        removeOutOfStock: {
            en: 'Remove the out-of-stock material',
            fr: 'Retirer le matériel en rupture de stock',
            nl: 'Verwijder het niet op voorraad zijnde materiaal',
            pl: 'Usuń niedostępny materiał',
            it: 'Rimuovere i materiali non disponibili',
            es: 'Elimine el material no disponible',
        },
    },
    agenda: {
        title: {
            en: 'Agenda',
            fr: 'Agenda',
            nl: 'Agenda',
            pl: 'Program',
            it: 'Agenda',
            es: 'Agenda',
        },
    },
    helpPage: {
        title: {
            en: 'Questions?',
            fr: 'Questions ?',
            nl: 'Vragen?',
            pl: 'Pytania?',
            it: 'Domande Frequenti',
            es: 'Preguntas Frecuentes',
        },
        back: {
            en: 'Back to question categories',
            fr: 'Retournez aux questions',
            nl: 'Terug naar vragen',
            pl: 'Powrót do pytań',
            it: 'Torna alle categorie di domande',
            es: 'Volver a la sección de preguntas frecuentes',
        },
        viewAnswer: {
            en: 'view answer',
            fr: 'Voir la réponse',
            nl: 'Bekijk antwoord',
            pl: 'zobacz odpowiedź',
            it: 'Vedi la risposta',
            es: 'Ver respuesta',
        },
        hideAnswer: {
            en: 'hide answer',
            fr: 'Masquer la réponse',
            nl: 'Verberg antwoord',
            pl: 'ukryj odpowiedź',
            it: 'Nascondi la risposta',
            es: 'Ocultar respuesta',
        },
        sections: {
            accessParking: {
                en: 'Access & Parking',
                fr: 'Accès & parking',
                nl: 'Toegang & parking',
                pl: 'Dojazd i Parking',
                it: 'Accesso & Parcheggio',
                es: 'Acceso & Parking',
            },
            agendaContact: {
                en: 'Agenda & Contact',
                fr: 'Agenda & Contact',
                nl: 'Agenda & Contact',
                pl: 'Program i Kontakt',
                it: 'Agenda & Contatti',
            },
            badges: {
                en: 'Badges',
                fr: 'Badges',
                nl: 'Badges',
                pl: 'Identyfikatory',
                it: 'Badges',
                es: 'Acreditaciones',
            },
            communicationAdvertising: {
                en: 'Communication & Advertising',
                fr: 'Communication & Publicité',
                nl: 'Communicatie & Advertising',
                pl: 'Komunikacja i Reklama',
                it: 'Comunicazione & Pubblicitá',
                es: 'Comunicación y publicidad',
            },
            conceptArea: {
                en: 'Concept area',
                fr: 'Concept area',
                nl: 'Concept area',
                pl: '',
                it: 'Area Concept',
                es: 'Area Concept',
            },
            constructionDismantling: {
                en: 'Construction & Dismantling',
                fr: 'Montage & Démontage',
                nl: 'Opbouw & Afbouw',
                pl: 'Montaż i Demontaż',
                it: 'Allestimento & Disallestimento',
                es: 'Montaje y desmontaje',
            },
            invoices: {
                en: 'Invoices',
                fr: 'Factures',
                nl: 'Facturen',
                pl: 'Faktury',
                it: 'Fatturazione',
                es: 'Facturación',
            },
            other: {
                en: 'Other',
                fr: 'Autres',
                nl: 'Andere',
                pl: 'Inne',
                it: 'Altro',
                es: 'Otros',
            },
            sectors: {
                en: 'Sectors',
                fr: 'Secteurs',
                nl: 'Sectoren',
                pl: 'Sektory',
                it: 'Settori',
                es: 'Sectores',
            },
            stand: {
                en: 'Stand',
                fr: 'Stand',
                nl: 'Stand',
                pl: 'Stoisko',
                it: 'Stand',
            },
            tipsTricks: {
                en: 'Tips & Tricks',
                fr: 'Tips & Tricks',
                nl: 'Tips & Tricks',
                pl: 'Porady i Triki',
                it: 'Suggerimenti',
                es: 'Sugerencias y consejos',
            },
        },
        addCategory: {
            en: 'Add category',
            fr: 'Ajouter une catégorie',
            nl: 'Categorie toevoegen',
            pl: 'Dodaj kategorię',
            it: 'Aggiungi categoria',
            es: 'Añadir categoría',
        },
        view: {
            en: 'View',
            fr: 'Voir',
            nl: 'Zien',
            pl: 'Zobacz',
            it: 'Vedi',
            es: 'Vista',
        },
        helpCategoryInputFieldName: {
            en: 'Category Name',
            fr: 'Nom de catégorie',
            nl: 'Categorie naam',
            pl: 'Nazwa kategorii',
            it: 'Nome Categoria',
            es: 'Nombre de la categoría',
        },
        helpCategoryTagName: {
            en: 'Category Access',
            fr: 'Accès aux catégories',
            nl: 'Categorie toegang',
            pl: 'Dostęp do kategorii',
            it: 'Accesso Categoria',
            es: 'Acceso a la categoría',
        },
        update: {
            en: 'Update',
            fr: 'Réactualiser',
            nl: 'Bijwerken',
            pl: 'Uaktualnij',
            it: 'Aggiorna',
            es: 'Actualización',
        },
    },
    welcome: {
        title: {
            en: "Welcome to Let's connect",
            fr: "Bienvenue sur Let's connect",
            nl: "Welkom op Let's connect",
            pl: "Witamy na platformie Let's connect",
            it: "Benvenuto su Let's connect",
        },
        info : {
            en: 'Upcoming edition',
            fr: 'La prochaine édition',
            nl: 'De volgende editie',
            pl: 'Nadchodząca edycja',
            it: 'Prossima Edizione',
            es: 'Prossima Edizione',
        },
    },
    packages: {
        save: {
            en: 'Save',
            fr: 'Economisez',
            nl: 'Bespaar',
            pl: 'Oszczędzasz',
            it: 'Salva',
            es: 'Guardar',
        }
    },
    packageSettings: {
        toCreate: {
            en: 'To create a new package, select the stand type',
            fr: 'Pour créer une nouvelle réservation, sélectionnez le type de stand',
            nl: 'Om een nieuw pakket te creëren, moet je een standtype selecteren',
            it: 'Per creare un nuovo pacchetto selezionare il tipo di stand',
        },
        fixedPrice: {
            en: 'Fixed price',
            fr: 'Prix fixe',
            nl: 'Vaste prijs',
            it: 'Prezzo stabilito',
            es: 'Precio fijo',
        },
        discountAppliedToAll: {
            en: 'Discount (applied to all products in the package except the surface)',
            fr: "Réduction (s'applique à tous les produits du forfait, à l'exception de la surface)",
            nl: 'Korting (Toegepast op alle producten van het pakket, uitzonderlijk op de oppervlakte)',
            it: 'Sconto (applicato su tutti i prodotti nel pacchetto eccetto la superficie)',
            es: 'Descuento (aplicado a todos los productos del paquete excepto la superficie)',
        },
        pkgOnlyForCHO: {
            en: 'Package is only available for Corner, Head, Open',
            fr: 'Le forfait est uniquement disponible pour les stands ouverts sur 2 côtés (coin), 3 côtés (tête), 4 côtés (ouvert)',
            nl: 'Package is enkel beschikbaar voor hoek-, kop- en open standen',
            it: 'Il pacchetto é disponibile solo per stand ad angolo e di testa',
            es: 'El paquete sólo está disponible para Esquina, Frontal, Abierto',
        },
        noProducts: {
            en: 'No products',
            fr: 'Pas de produits',
            nl: 'Geen producten',
            it: 'Nessun prodotto',
            es: 'Sin productos',
        },
        foldProductDetails: {
            en: 'Fold product details',
            fr: 'Cacher les détails du produit',
            nl: 'Verberg de productdetails',
            it: 'Riduci i dettagli del prodotto',
            es: 'Ocultar detalles del producto',
        },
        unfoldProductDetails: {
            en: 'Unfold product details',
            fr: 'Montrer les détails du produit',
            nl: 'Toon de productdetails',
            it: 'Mostrare i dettagli del prodotto',
            es: 'Mostrar detalles del producto',
        },
        hashtagIsReplacedWithQuantity: {
            en: '# is replaced with quantity',
            fr: '# est remplacé par la quantité',
            nl: '# is vervangen door de hoeveelheid',
            it: '# é sostituito con la quantitá',
            es: '# se sustituye por la cantidad',
        },
        surfaces: {
            en: 'Surfaces',
            fr: 'Superficie',
            nl: 'Oppervlakte',
            it: 'Superfici',
            es: 'Superficie',
        },
        hideForExhibitors: {
            en: 'Hide for exhibitors',
            fr: 'Cacher pour les exposants',
            nl: 'Verberg voor de exposanten',
            it: 'Nascondere agli espositori',
            es: 'Ocultar para expositores',
        },
        additionalRestrictionsMay: {
            en: 'Additional restrictions may apply to exhibitors',
            fr: "Des restrictions supplémentaires peuvent s'appliquer aux exposants",
            nl: 'Er kunnen bijkomende beperkingen gelden voor exposanten',
            it: 'Restrizioni aggiuntive potrebbero essere applicate agli espositori',
            es: 'Pueden aplicarse restricciones adicionales a los expositores',
        },
        exhibitorsHaveDifferent: {
            en: 'Exhibitors have different min size for different stand types:',
            fr: 'Les exposants ont des tailles minimales différentes en fonction du type de stand :',
            nl: 'Exposanten hebben verschillende minimum afmetingen voor verschillende standtypes:',
            it: 'Gli espositori hanno misure minime diverse per tipi di stand differenti:',
            es: 'Los expositores tienen diferentes tamaños mínimos para los diferentes tipos de stand:',
        },
        contactAdminToChange: {
            en: 'Contact admin to change the global min for your project.',
            fr: "Contactez l'administrateur pour modifier la taille minimale globale de votre projet.",
            nl: 'Neem contact op met admin om het globale minimum voor uw project te wijzigen.',
            it: "Contatta l'amministratore per modificare il minimo globale per il tuo progetto.",
            es: 'Póngase en contacto con el administrador para cambiar el tamaño mínimo global de su proyecto.',
        },
        addSurfaceRange: {
            en: 'Add Surface Range',
            fr: 'Ajouter un interval de surfaces',
            nl: 'Voeg een interval van oppervlak toe',
            it: "Aggiungere l'intervallo",
            es: 'Añadir rango de superficie',
        },
        addSurface: {
            en: 'Add Surface:',
            fr: 'Ajouter une superficie :',
            nl: 'Voeg een oppervlak toe:',
            it: 'Aggiungere la superficie:',
            es: 'Añadir superficie:',
        },
        addWallRange: {
            en: 'Add Wall Range',
            fr: 'Ajouter un interval de mur',
            nl: 'Voeg een interval van muurlengte toe',
            it: 'Aggiungere un intervallo per la parete',
            es: 'Añadir rango de la pared',
        },
        addWall: {
            en: 'Add Wall:',
            fr: 'Ajouter un mur :',
            nl: 'Voeg een muur toe:',
            it: 'Aggiugere la parete:',
            es: 'Añadir pared:',
        },
        addProduct: {
            en: 'Add Product',
            fr: 'Ajouter un produit',
            nl: 'Voeg een product toe',
            pl: 'Dodaj produkt',
            it: 'Aggiungere un prodotto',
            es: 'Añadir producto',
        },
        sectorsWithAccess: {
            en: 'Sectors with access',
            fr: 'Secteurs avec accès',
            nl: 'Sectoren met toegang',
            it: 'Settori con accesso',
            es: 'Sectores con acceso',
        },
        wallLength: {
            en: 'Walls length:',
            fr: 'Longueur des murs :',
            nl: 'Muurlengte:',
            it: 'Lunghezza delle pareti:',
            es: 'Longitud de las paredes:',
        },
        onlyBackWall: {
            en: 'Only back wall',
            fr: 'Seulement les murs arrières',
            nl: 'Enkel achtermuur',
            it: 'Soltanto le pareti di fondo',
            es: 'Sólo pared trasera',
        },
        onlySideWalls: {
            en: 'Only side walls',
            fr: 'Seulement les murs latéraux',
            nl: 'Enkel zijmuren',
            it: 'Soltanto le pareti laterla',
            es: 'Sólo paredes laterales',
        },
        addCustomQuantity: {
            en: 'Add custom quantity',
            fr: 'Ajouter une quantité personnalisée',
            nl: 'Voeg een aangepaste hoeveelheid toe',
            it: 'Soltanto le pareti laterali',
            es: 'Añade cantidad personalizada',
        },
        removeQuantity: {
            en: 'Remove quantity',
            fr: 'Retirer la quantité',
            nl: 'Verwijder hoeveelheid',
            it: 'Rimuovere la quantità',
            es: 'Eliminar la cantidad',
        },
        accessIfOneFromEach: {
            en: 'Access if company has 1 sector from each list',
            fr: "Accès si l'entreprise a 1 secteur de chaque liste",
            nl: 'Toegang als bedrijf 1 sector heeft uit de lijst',
            it: "Accesso se l'azienda ha 1 settore per ogni lista",
            es: 'Acceso si la empresa tiene 1 sector de cada lista',
        },
        list: {
            en: 'List',
            fr: 'Liste',
            nl: 'Lijst',
            it: 'Lista',
            es: 'Lista',
        },
        enterIds: {
            en: 'Enter IDs',
            fr: 'Entrer les identifiants',
            nl: "Voer ID's in",
            it: 'Inseire codice IDs',
            es: 'Introduzca ID',
        },
        addReference: {
            en: 'Add Reference',
            fr: 'Ajouter une référence',
            nl: 'Voeg referentie toe',
            it: 'Aggiungere referenza',
            es: 'Añade una referencia',
        },
        reference: {
            en: 'Reference',
            fr: 'Référence',
            nl: 'Referentie',
            it: 'Referenza',
            es: 'Referencia',
        },
        referenceAny: {
            en: 'Any',
            fr: 'Toute',
            nl: 'Elke',
            it: 'Tutti',
            es: 'Todo',
        },
        referenceLow: {
            en: 'Low Range',
            fr: 'Catégorie basse',
            nl: 'Lage categorie',
            pl: 'Mała ekspozycja',
            it: 'Categoria inferiore',
            es: 'Categoría inferior',
        },
        referenceHigh: {
            en: 'High Range',
            fr: 'Catégorie haute',
            nl: 'Hoge categorie',
            pl: 'Duża ekspozycja',
            it: 'Categoria superiore',
            es: 'Categoría superior',
        },
        itemPricePreview: {
            en: 'Item price preview',
            fr: "Aperçu du prix de l'article",
            nl: 'Preview prijsitem',
            it: "Anteprima del prezzo dell'articolo",
            es: 'Vista previa del precio del artículo',
        },
        to: {
            en: 'to',
            fr: 'à',
            nl: 'tot',
            pl: 'do',
            it: 'a',
            es: 'a',
        },
        templatePages: {
            en: 'Template pages',
            fr: 'Pages du modèle',
            nl: "Sjabloonpagina's",
            pl: 'Strony z szablonami',
        },
        searchTemplatePages: {
            en: 'Search template pages...',
            fr: 'Recherchez de pages de modèles...',
            nl: "Zoek sjabloonpagina's",
            pl: 'Wyszukaj strony z szablonami',
        },
        companiesWithAccess: {
            en: 'Companies with access',
            fr: 'Entreprises avec accès',
            nl: 'Bedrijven met toegang',
            pl: 'Firmy z dostępem',
        },
        searchCompanies: {
            en: 'Search companies...',
            fr: 'Recherchez des entreprises...',
            nl: 'Zoek bedrijven...',
            pl: 'Szukaj firmy...',
        },
        searchSectors: {
            en: 'Search sectors...',
            fr: 'Recherchez de secteurs...',
            nl: 'Zoek sectoren...',
            pl: 'Szukaj sektorów...',
        },
        customiseName: {
            en: 'Customise name...',
            fr: 'Personnalisez le nom...',
            nl: 'Pas naam aan...',
            pl: 'Dostosuj nazwę...',
        },
    },
    stands: {
        selectCompany: {
            en: 'Select a company',
            fr: 'Sélectionnez une entreprise',
            nl: 'Selecteer een bedrijf',
            pl: 'Wybierz firmę',
            it: "Seleziona un'azienda",
            es: 'Selecciona una empresa',
        },
        createStand: {
            en: 'Create Stand',
            fr: 'Créer un stand',
            nl: 'Maak een standaard',
            pl: 'Utwórz stoisko',
            it: 'Crea lo Stand',
            es: 'Crea el stand',
        },
        standPosition: {
            en: 'Stand position',
            pl: 'Numer stoiska',
            es: 'Posición del stand',
        },
        invoice: {
            en: 'Invoice',
            pl: 'Faktura',
            es: 'Factura',
        },
        addProducts: {
            en: 'Add Products',
            pl: 'Dodaj produkty',
            es: 'Añadir productos',
        },
        editPackage: {
            en: 'Edit package',
            pl: 'Edytuj pakiet',
            es: 'Editar paquete',
        },
        created: {
            en: 'Created',
            fr: 'Créé',
            nl: 'Gecreëerd',
            pl: 'Utworzone',
            it: 'Creato',
            es: 'Creado',
        },
        submitted: {
            en: 'Submitted',
            fr: 'Soumis',
            nl: 'Verzonden',
            pl: 'Przedłożony',
            it: 'Inviata',
            es: 'Enviada',
        },
        approved: {
            en: 'Approved',
            fr: 'Validé',
            nl: 'Goedgekeurd',
            pl: 'Zaakceptowany',
            it: 'Approvata',
            es: 'Aprobada',
        },
        invoiced: {
            en: 'Invoiced',
            fr: 'Facturé',
            nl: 'Gefactureerd',
            pl: 'Zafakturowano',
            it: 'Fatturato',
            es: 'Facturado',
        },
        unpaid: {
            en: 'Unpaid',
            fr: 'Non payé',
            nl: 'Onbetaald',
            pl: 'Niezapłacone',
            it: 'Non pagato',
            es: 'Impagado',
        },
        surface: {
            en: 'Surface',
            fr: 'Superficie',
            nl: 'Oppervlakte',
            pl: 'Powierzchnia',
            it: 'Superficie',
            es: 'Superficie',
        },
        realSurface: {
            en: 'Real surface',
            fr: 'Surface réelle',
            nl: 'Echt oppervlak',
            pl: 'Prawdziwa powierzchnia',
            it: 'Superficie reale',
            es: 'Superficie real',
        },
        wallBack: {
            en: 'Wall Back',
            fr: 'Paroi Arrière',
            nl: 'Achterwand',
            pl: 'Ściana Tylna',
        },
        wallSide: {
            en: 'Wall Side',
            fr: 'Paroi Latérale',
            nl: 'Zijwand',
            pl: 'Ściana Boczna',
        },
    },
    visuals: {
        panel: {
            en: 'Panel',
            fr: 'Panneau',
            nl: 'Paneel',
            pl: 'Panel',
            it: 'Pannello',
            es: 'Panel',
        },
        copyLink: {
            en: 'Copy link',
            fr: 'Copiez le lien',
            nl: 'Kopieer link',
            pl: 'Kopiuj link',
            it: 'Copiare il link',
            es: 'Copiar el link',
        },
        uploaded: {
            en: 'Uploaded',
            fr: 'Téléchargé',
            nl: 'Geupload',
            pl: 'Załadowany',
            it: 'Caricato',
            es: 'Subido',
        },
        status: {
            en: 'Status',
            fr: 'Statut',
            nl: 'Status',
            pl: 'Status',
            it: 'Stato',
            es: 'Status',
        },
        accepted: {
            en: 'Accepted',
            fr: 'Accepté',
            nl: 'Geaccepteerd',
            pl: 'Zaakceptowane',
            it: 'Accettato',
            es: 'Aceptado',
        },
        rejected: {
            en: 'Rejected',
            fr: 'Rejeté',
            nl: 'Afgewezen',
            pl: 'Odrzucony',
            it: 'Rifiutato',
            es: 'Rechazado',
        },
        to: {
            en: 'To',
            fr: 'À',
            nl: 'Aan',
            pl: 'Do',
            it: 'A',
            es: 'Para',
        },
        subject: {
            en: 'Subject',
            fr: 'Objet',
            nl: 'Onderwerp',
            pl: 'Temat',
            it: 'Oggetto',
            es: 'Asunto',
        },
        message: {
            en: 'Message',
            fr: 'Message',
            nl: 'Bericht',
            pl: 'Wiadomość',
            it: 'Messaggio',
            es: 'Mensaje',
        },
    },
    reports: {
        showForDay: {
            en: 'Show reports for day:',
            fr: 'Afficher les rapports pour la journée:',
            nl: 'Toon rapporten voor de dag:',
            pl: 'Pokaż raporty z dnia:',
            it: 'Mostra rapporti per giorno:',
            es: 'Mostrar informes del día:',
        },
        allDays: {
            en: 'All days',
            fr: 'Tous les jours',
            nl: 'Alle dagen',
            pl: 'Wszystkie dni',
            it: 'Tutti i giorni',
            es: 'Todos los días',
        },
        totalNumberOfOrders: {
            en: 'Total number of orders since the opening of the fair',
            fr: "Nombre total de commandes depuis l'ouverture du salon",
            nl: 'Totaal aantal bestellingen sinds de opening van de beurs',
            pl: 'Łączna liczba zamówień od momentu otwarcia targów',
            it: "Numero totale degli ordini dall'apertura del Salone",
            es: 'Número total de pedidos desde la apertura de la feria',
        },
        numberOfAtLeastOneOrderStores: {
            en: 'Number of stores having made at least 1 order since the opening of the fair',
            fr: "Nombre de magasins ayant passé au moins une commande depuis l'ouverture du salon",
            nl: 'Aantal winkels die sinds de opening van de beurs ten minste 1 bestelling hebben gedaan',
            pl: 'Liczba sklepów, która zleciła wykonanie minimum 1 zamówienia od otwarcia targów',
            it: "Numero dei punti vendita che hanno eseguito almento 1 ordine dall'apertura del Salone",
            es: 'Número de puntos de venta que han realizado al menos 1 pedido desde la apertura del salón',
        },
        orders: {
            en: 'Orders',
            fr: 'Commandes',
            nl: 'Bestellingen',
            pl: 'Zamówienia',
            it: 'Ordini',
            es: 'Pedidos',
        },
        stores: {
            en: 'Stores',
            fr: 'Magasins',
            nl: 'Winkels',
            pl: 'Sklepy',
            it: 'Punti vendita',
            es: 'Tiendas',
        },
        numberOfOrders: {
            en: 'Number of orders',
            fr: 'Nombre de commandes',
            nl: 'Aantal bestellingen',
            pl: 'Liczba zamówień',
            it: 'Numero di ordini',
            es: 'Número de pedidos',
        },
        numberOfStores: {
            en: 'Number of stores',
            fr: 'Nombre de magasins',
            nl: 'Aantal winkels',
            pl: 'Liczba sklepów',
            it: 'Numero di punti vendita',
            es: 'Número de puntos de venta',
        },
        grandTotalPV: {
            en: 'Grand Total PV:',
            fr: 'Grand Total PV :',
            nl: 'Groot Totaal PV:',
            pl: 'Łączna wartość zamówień:',
            it: 'Somma totale PV:',
            es: 'Gran Total PV:',
        },
        searchProducts: {
            en: 'Search products',
            fr: 'Recherche produits',
            nl: 'Zoek producten',
            pl: 'Wyszukaj produkty',
            it: 'Cerca prodotti',
            es: 'Buscar productos',
        },
        productName: {
            en: 'Product name',
            frBE: 'Nom produit',
            frFR: 'Libellé produit',
            nl: 'Productnaam',
            pl: 'Nazwa produktu',
            it: 'Nome prodotto',
            es: 'Nombre del producto',
        },
        total: {
            en: 'Total',
            fr: 'Total',
            nl: 'Totaal',
            pl: 'Razem',
            it: 'Totale',
            es: 'Total',
        },
        totalSelling: {
            en: 'Total selling',
            pl: 'Cena sprzedaży',
        },
        totalInvoicing: {
            en: 'Total invoicing',
            pl: 'Cena zakupu',
        },
        totalDiscount: {
            en: 'Total discount',
            pl: 'Razem rabat',
        },
        totalToBePaid: {
            en: 'Total to be paid',
            pl: 'Razem cena zakupu po rabacie',
        },
        grandTotal: {
            en: 'Grand total',
            pl: 'Razem',
        },
        reportBySector: {
            en: 'Report by sector',
            pl: 'Raport według kategorii',
        },
        sector: {
            en: 'Sector',
            pl: 'Kategoria',
        },
        totalOrders: {
            en: 'Total Orders',
            fr: 'Total commandes',
            nl: 'Totaal bestellingen',
            pl: 'Razem zamówienia',
            it: 'Totale Ordini',
            es: 'Total pedidos',
        },
        totalPV: {
            en: 'Total PV',
            fr: 'Total PV',
            nl: 'Totaal PV',
            pl: 'Razem wartość zamówienia',
            it: 'Totale PV',
            es: 'Total PV',
        },
        pvMarket: {
            en: 'PV Market',
            fr: 'PV Market',
            nl: 'PV Market',
            pl: 'Cena sprzedaży (Super / Hipermarket)',
            it: 'PV Market',
            es: 'PV Market',
        },
        pvExpress: {
            en: 'PV Express',
            fr: 'PV Express',
            nl: 'PV Express',
            pl: 'Cena sprzedaży (Express)',
            it: 'PV Express',
            es: 'PV Express',
        },
        pvHyper: {
            en: 'PV Hyper',
            fr: 'PV Hyper',
            nl: 'PV Hyper',
            pl: 'Cena sprzedaży (Hyper)',
            itemTypenl: 'PV Hyper',
            es: 'PV Hyper',
        },
        packageDescription: {
            en: 'Package Description',
            it: 'Descrizione Pacchetto',
        },
        productDescription: {
            en: 'Product Description',
            it: 'Descrizione Prodotto',
        },
    },
    exhibitorProduct: {
        products: {
            en: 'Products',
            fr: 'Produits',
            nl: 'Producten',
            pl: 'Produkty',
            it: 'Prodotti',
            es: 'Productos',
        },
        downloadPdf: {
            en: 'Download PDF',
            fr: 'Télécharger le PDF',
            pl: 'Pobierz pdf',
            it: 'Scarica il PDF',
        },
    },
    choosePage: {
        title: {
            en: 'Please select your template',
            it: 'Per favore seleziona il tuo modello di pagina',
        }
    },
    pages: {
        moreInfoContactUs: {
            en: 'More info? Contact us',
            fr: "Plus d'informations ? Contactez-nous",
            nl: 'Meer info? Contacteer ons',
            pl: 'Więcej info? Skontakuj się z nami',
            it: 'Desidera maggiori informazioni? Ci contatti',
        },
        sendContactInfo: {
            en: 'We will send your contact information to',
            fr: 'Nous enverrons vos coordonnées à',
            nl: 'Wij sturen uw contactgegevens door naar',
            pl: 'Wyślemy Twoje dane kontaktowe do wystawcy',
            it: 'Invieremo i suoi dati a',
        },
        proceed: {
            en: 'Proceed?',
            fr: 'Procéder ?',
            nl: 'Verder gaan?',
            pl: 'Kontynuować?',
            it: 'Procedere?',
        },
        downloadSlideshow: {
            en: 'Download the slideshow',
            fr: 'Téléchargez le diaporama',
            nl: 'De slideshow downloaden',
            pl: 'Pobierz prezentację',
            it: 'Caricare lo slideshow',
        },
        globalPages: {
            en: 'Global Pages',
            fr: 'Pages générales',
            nl: "Algemene pagina's",
            pl: 'Wszystkie Strony',
            it: '',
            es: 'Páginas generales',
        },
        projectPages: {
            en: 'Project Pages',
            fr: 'Pages du projet',
            nl: "Project pagina's",
            pl: 'Strony projektu',
            it: '',
            es: 'Páginas de proyecto',
        },
    },
    editPage: {
        edit: {
            en: 'Edit',
            fr: 'Editer',
            nl: 'Bewerken',
            pl: 'Edytuj',
            it: 'Modifica',
            es: 'Editar',
        },
        // pages: {
        //     en: 'Pages',
        //     fr: 'Pages',
        //     nl: "Pagina's",
        //     pl: 'Strony',
        //     it: 'Pagine',
        //     es: 'Páginas',
        // },
        save: {
            en: 'Save',
            fr: 'Enregistrer',
            nl: 'Opslaan',
            pl: 'Zapisz',
            it: 'Salva',
            es: 'Guardar',
        },
        dragAndDrop: {
            en: 'Drag and Drop',
            fr: 'Glisser-déposer',
            nl: 'Slepen en neerzetten',
            pl: 'Przeciągnij i upuść',
            it: 'Trascinare e rilasciare',
            es: 'Arrastrar y soltar',
        },
        blockTextImageLink: {
            en: 'Text, Image, Link',
            fr: 'Texte, Image, Lien',
            nl: 'Tekst, afbeelding, link',
            pl: 'Tekst, Obraz, Link',
            it: 'Testo, Immagine, Link',
            es: 'Texto, Imagen, Link',
        },
        blockProductPreview: {
            en: 'Product preview',
            fr: 'Prévisualisation produit',
            nl: 'Preview product',
            pl: 'Promocja produktu',
            it: 'Antemprima prodotto',
            es: 'Vista previa del producto',
        },
        blockVideo: {
            en: 'Video',
            fr: 'Vidéo',
            nl: 'Video',
            pl: 'Wideo',
            it: 'Video',
            es: 'Video',
        },
        blockSlideshow: {
            en: 'Slideshow',
            fr: 'Diaporama',
            nl: 'Diavoorstelling',
            pl: 'Pokaz slajdów',
            it: 'Slideshow',
            es: 'Presentación de diapositivas',
        },
        blockArticle: {
            en: 'Article',
            fr: 'Article',
            nl: 'Artikel',
            pl: 'Artykuł',
            it: 'Articolo',
            es: 'Artículo',
        },
        blockFileDownload: {
            en: 'File download',
            fr: 'Téléchargement fichier',
            nl: 'Dowload document',
            pl: 'Plik załaduj',
            it: 'Download del file',
            es: 'Descarga de archivos',
        },
        text: {
            en: 'Text',
            fr: 'Texte',
            nl: 'Tekst',
            pl: 'Tekst',
            it: 'Testo',
            es: 'Texto',
        },
        image: {
            en: 'Image',
            fr: 'Image',
            nl: 'Afbeelding',
            pl: 'Obraz',
            it: 'Immagine',
            es: 'Imagen',
        },
        backgroundColor: {
            en: 'Background color',
            fr: 'Couleur de fond',
            nl: 'Achtergrond kleur',
            pl: 'Kolor tła',
            it: 'Colore di fondo',
            es: 'Color del fondo',
        },
        published: {
            en: 'Published',
            fr: 'Publié',
            nl: 'Gepubliceerd',
            pl: 'Opublikować',
            it: 'Pubblicato',
            es: 'Publicado',
        },
        alignTop: {
            en: 'Align Top',
            fr: 'Aligner en haut',
            nl: 'Boven uitlijnen',
            pl: 'Wyrównaj do góry',
            it: 'Allineamento in alto',
            es: 'Alinear arriba',
        },
        alignCenter: {
            en: 'Align Center',
            fr: 'Aligner au milieu',
            nl: 'Midden uitlijnen',
            pl: 'Wyrównaj do centrum',
            it: 'Allineamento al centro',
            es: 'Alinear al centro',
        },
        alignBottom: {
            en: 'Align Bottom',
            fr: 'Aligner en bas',
            nl: 'Onder uitlijnen',
            pl: 'Wyrównaj do dołu',
            it: 'Allineamento in basso',
            es: 'Alinear abajo',
        },
        alignLeft: {
            en: 'Align Left',
            fr: 'Aligner à gauche',
            nl: 'Links uitlijnen',
            pl: 'Wyrównaj do lewej',
            it: 'Allineamento a sinistra',
            es: 'Alinear a la izquierda',
        },
        alignRight: {
            en: 'Align Right',
            fr: 'Aligner à droite',
            nl: 'Rechts uitlijnen',
            pl: 'Wyrównaj do prawej',
            it: 'Allineamento a destra',
            es: 'Alinear a la derecha',
        },
        selectProduct: {
            en: 'Select product',
            fr: 'Sélectionner produit',
            nl: 'Product selecteren',
            pl: 'Wybierz produkt',
            it: 'Seleziona il prodotto',
            es: 'Seleccionar producto',
        },
        DragDropUpload: {
            en: 'Drag & Drop or Click to upload',
            fr: 'Drag & Drop ou Cliquer pour charger',
            nl: 'Drag & Drop of klik om te uploaden',
            pl: 'Przeciągnij lub kliknij aby załadować',
            it: 'Trascina & Rilascia oppoure clicca per caricare',
            es: 'Arrastrar y soltar o hacer clic para cargar',
        },
        addALink: {
            en: 'Add a link',
            fr: 'Ajouter un lien',
            nl: 'Voeg een link toe',
            pl: 'Dodaj link',
            it: 'Aggiungi un link',
            es: 'Añadir un link',
        },
        addPageLink: {
            en: 'Add page link',
            es: 'Añadir el enlace de la página',
        },
        selectImage: {
            en: 'Select Image',
            fr: 'Sélectionner image',
            nl: 'Afbeelding selecteren',
            pl: 'Wybierz obraz',
            it: 'Seleziona Immagine',
            es: 'Seleccionar imagen',
        },
        deleteContent: {
            en: 'Delete content',
            fr: 'Effacer contenu',
            nl: 'Inhoud verwijderen',
            pl: 'Usuń zawartość',
            it: 'Cancella il contenuto',
            es: 'Eliminar contenido',
        },
        pleaseWait: {
            en: 'Please wait...',
            fr: 'Veuillez patienter...',
            nl: 'Wachten aub...',
            pl: 'Proszę czekać...',
            it: 'Attendere per favore...',
            es: 'Por favor, espere...',
        },
        views: {
            en: 'Views:',
            fr: 'Vues :',
            nl: 'Aantal keren gezien:',
            pl: 'Wyświetlenia:',
            it: 'Visualizzazioni:',
            es: 'Vistas:',
        },
        uniqueVisitors: {
            en: 'Unique visitors:',
            fr: 'Visiteurs uniques :',
            nl: 'Unieke bezoekers:',
            pl: 'Unikalni odwiedzający:',
            it: 'Visitatori unici:',
            es: 'Visitantes únicos:',
        },
    },
    chat: {
        // peopleInConversation: {
        //     en: "People in conversation",
        //     fr: "Personnes en conversation",
        //     nl: 'Personen in conversatie ',
        //     pl: 'Osoby w konwersacji',
        //     it: 'Persone in conversazione',
        //     es: 'Personas en la conversación',
        // },
        addPeople: {
            en: 'Add People',
            fr: 'Ajouter des personnes',
            nl: 'Voeg personen toe ',
            pl: 'Dodaj osobę',
            it: 'Aggiungi persone',
            es: 'Añadir personas',
        },
        removeFromConversation: {
            en: 'Remove from conversation?',
            fr: 'Retirer de la conversation ?',
            nl: 'Uit het gesprek verwijderen?',
            pl: 'Usuń z konwersacji',
            it: 'Rimuovere dalla conversazione?',
            es: '¿Quitar de la conversación?',
        },
        willBeRemovedFromConversation: {
            en: ' will be removed from this conversation.',
            fr: ' sera retiré(e) de la conversation.',
            nl: ' zal uit het gesprek verwijderd worden ',
            pl: ' zostanie usunięty z tej konwersacji',
            it: ' uscirá dalla conversazione',
            es: ' será eliminado de esta conversación.',
        },
        peopleYouAddCanSeeAllPreviousMessages: {
            en: 'People you add can see all previous messages in this conversation',
            fr: 'Les personnes que vous ajoutez verront tous les messages précédents de cette conversation',
            nl: 'Mensen die je toevoegt kunnen alle vorige berichten in dit gesprek zien',
            pl: 'Osoby, które dodałeś w konwersacji mogą widzieć wcześniejsze wiadomości tej grupy',
            it: 'Le persone che vengono aggiunte potranno vedere tutti i precedenti messaggi di questa conversazione',
            es: 'Las personas que añadas pueden ver todos los mensajes anteriores de esta conversación',
        },
        search: {
            en: 'Search...',
            fr: 'Rechercher...',
            nl: 'Zoeken...',
            pl: 'Szukaj...',
            it: 'Cerca...',
            es: 'Buscar...',
        },
        sender: {
            en: 'Sender:',
            fr: 'Expéditeur:',
            nl: 'Verzender:',
            pl: 'Nadawca:',
            it: 'Mittente:',
            es: 'Remitente:',
        },
        subject: {
            en: 'Subject:',
            fr: 'Sujet:',
            nl: 'Onderwerp:',
            pl: 'Temat:',
            it: 'Ogetto:',
            es: 'Asunto:',
        },
        sentOn: {
            en: 'Sent on:',
            fr: 'Envoyé le:',
            nl: 'Verzonden op:',
            pl: 'Wysłano:',
            it: 'Inviato il:',
            es: 'Enviado el:',
        },
        visibileTo: {
            en: 'Visible to:',
            fr: 'Visible par:',
            nl: 'Zichtbaar voor: ',
            pl: 'Widoczne dla:',
            it: 'Visibile a:',
            es: 'Visible para:',
        },
        submittedOn: {
            en: 'Submitted on:',
            fr: 'Soumis le:',
            nl: 'Ingediend op: ',
            pl: 'Wysłano:',
            it: 'Inviato il:',
            es: 'Enviado el:',
        },
        visibleOnlyToYourTeam: {
            en: 'visible only to your team',
            fr: 'Visible seulement par votre équipe:',
            nl: 'Enkel zichtbaar voor jouw team ',
            pl: 'Widoczne tylko dla Twojego zespołu',
            it: 'Visibile solo al tuo team',
            es: 'Visible sólo para su equipo',
        },
        startNewConversation: {
            en: 'Start new conversation',
            fr: 'Démarrer une nouvelle conversation',
            nl: 'Start een nieuwe conversatie',
            pl: 'Rozpocznij nową konwersację',
            it: 'Inizia una nuova conversazione',
            es: 'Iniciar una nueva conversación',
        },
        startedConversationWithTheTeam: {
            en: 'started conversation with the team.',
            fr: "a démarré une conversation avec l'équipe.",
            nl: 'is een nieuwe conversatie gestart met het team',
            pl: 'rozpoczął konwersację z zespołem',
            it: 'ha iniziato una nuova conversazione con il team',
            es: 'inició una nueva conversación con el equipo',
        },
        ongoingConversations: {
            en: 'ongoing conversations about this topic',
            fr: 'discussions en cours sur ce sujet',
            nl: 'lopende gesprekken over dit onderwerp',
            pl: 'rozpoczętych konwersacji w tym temacie',
            it: 'conversazioni in corso su questo argomento',
            es: 'conversaciones en curso sobre este tema',
        },
    },
    chatBoard: {
        archive: {
            en: 'Archive',
            fr: 'Archiver',
            nl: 'Archiveren',
            pl: 'Zarchiwizuj',
            it: 'Archiviare',
            es: 'Archivar',
        },
        restore: {
            en: 'Restore',
            fr: 'Rétablir',
            nl: 'Herstel',
            pl: 'Przywróć',
            it: 'Ripristinare',
            es: 'Restaurar',
        },
        takeTheLead: {
            en: 'Take the lead',
            it: 'Prendi la gestione',
        },
        noMessage: {
            en: 'no message',
            fr: 'pas de message',
            nl: 'geen bericht',
            pl: 'brak wiadomości',
            it: 'nessun messaggio',
            es: 'sin mensajes',
        },
        active: {
            en: 'Active',
            fr: 'Active',
            nl: 'Actief',
            pl: 'Aktywny',
            it: 'Attivo',
            es: 'Activo',
        },
        deleted: {
            en: 'Deleted',
            fr: 'Supprimé',
            nl: 'Verwijderd',
            pl: 'Usunięty',
            it: 'Cancellato',
            es: 'Eliminado',
        },
        support: {
            en: 'Support',
            fr: 'Support',
            nl: 'Support',
            pl: 'Pomoc',
            it: 'Supporto',
            es: 'Soporte',
        },
    },
    fairParticipation: {
        membersFairPart: (companyName: string) => ({
            en: `${companyName || 'Company members'} fair participation`,
            fr: companyName ? companyName + ' participation' : 'Participation des membres de mon entreprise',
            nl: companyName ? companyName + ' deelname' : 'Deelname van de leden van mijn bedrijf',
            pl: `${companyName || 'Przedstawiciele firmy'} na targach`,
            it: companyName ? companyName + ' partecipazione' : 'Partecipazione dei membri della mia azienda',
            es: companyName ? companyName + ' participación' : 'Participación de los miembros de mi empresa',
        }),
        viewOfCompany: {
            en: 'View fair participation of company',
            pl: 'Zobacz uczestników',
        },
        totalNoBadges: {
            en: 'Total number of badges',
            fr: 'Nombre total de badges',
            nl: 'Totaal aantal badges ',
            pl: 'Łączna liczba wejściówek',
            it: 'Numero totale di badges',
            es: 'Número total de acreditaciones',
        },
        assignedFor: {
            en: 'Assigned for',
            fr: 'Assignés au',
            nl: 'Toegewezen voor',
            pl: 'Zgłoszone już imiennie na dzień',
            it: 'Assegnati a',
            es: 'Asignado a ',
        },
        unassignedBadges: {
            en: 'Unassigned entrance badges',
            fr: 'Badges non assignés',
            nl: 'Niet-toegewezen toegangsbadges',
            pl: 'Jeszcze niepotwierdzona imiennie liczba wejściówek',
            it: 'Badges di entrata non assegnati',
            es: 'Acreditaciones de entrada no asignadas',
        },
        buyBadges: {
            en: 'Buy more badges',
            fr: 'Commander des badges supplémentaires',
            nl: 'Koop meer badges',
            pl: 'Zakup więcej identyfikatorów/wejściówek',
            it: 'Acquista altri badges',
            es: 'Comprar más acreditaciones',
        },
        code: {
            en: 'Code',
            fr: 'Code',
            nl: 'Code',
            pl: 'Kod',
            it: 'Codice',
        },
        carParking: {
            en: 'Car parking',
            fr: 'Parking voiture',
            nl: 'Auto parking',
            pl: 'Miejsce parkingowe',
            it: 'Parcheggio auto',
            es: 'Parking de automóviles',
        },
        participate: {
            en: 'Participate',
            fr: 'Participer',
            nl: 'Deelnemen',
            pl: 'Uczestnictwo',
            it: 'Partecipare',
            es: 'Participante',
        },
        sendBadgePdf: {
            en: 'Send badge PDF',
            fr: 'Envoyer PDF du badge',
            nl: 'Stuur badge in PDF',
            pl: 'Wyślij wejściówkę',
            it: 'Inviare il PDF del badge',
            es: 'Enviar acreditación en PDF',
        },
        sendingBadgePdf: {
            en: 'Sending badge PDF…',
            fr: 'Envoi badge PDF…',
            nl: 'Aan het verzenden badge PDF…',
            pl: 'Wysyłamy badge PDF…',
            it: 'Sta inviando badge PDF…',
            es: 'Envío de la acreditación en formato PDF…',
        },
        downloadXLS: {
            en: 'Download xls with participations',
            fr: 'Télécharger la liste des participation (xls)',
            nl: 'Download de lijst met deelnemers (xls)',
            pl: 'Pobierz plik xls z listą uczestników',
            it: 'Caricare la lista dei partecipanti (xls)',
            es: 'Descargar xls con asistentes',
        },
        downloadCMBadges: {
            en: 'Download company members badges',
            fr: "Télécharger les badges des membres de l'entreprise",
            nl: 'Download de badges van de bedrijfsleden',
            pl: 'Pobierz wejściówki dla wszystkich członków firmy',
            it: 'Scarichi i badge dei suoi colleghi',
            es: 'Descargar las acreditaciones de los miembros de la empresa',
        },
        downloadBadgePDF: {
            en: 'Download badge PDF',
            fr: 'Télécharger le badge en PDF',
            nl: 'Download de bagde in PDF',
            pl: 'Pobierz identyfikator w PDF',
            it: 'Scarica il badge in PDF',
            es: 'Descargar el PDF de la acreditación',
        },
        confirmParticipation: {
            en: 'Please first confirm the participation to the main event',
            fr: "Veuillez confirmer votre participation à l'évènement principal",
            nl: 'Gelieve eerst de deelname aan het hoofdevenement te bevestigen. ',
            pl: 'Proszę potwierdź najpierw uczestnictwo w głównym wydarzeniu.',
            it: "Confermi la sua partecipazione all'evento principale",
            es: 'Por favor, confirme primero la participación en el evento principal',
        },
        removeBadgeConfirmation: {
            en: 'Removing all dates will delete the Badge ID of this user, are you sure?',
            fr: "La suppression de toutes les dates supprimera l'ID de badge de cet utilisateur, êtes-vous sûr ?",
            nl: 'Het verwijderen van alle data zal de Badge ID van deze gebruiker verwijderen, weet je het zeker?',
            pl: 'Usunięcie wszsytkich dat spowoduje anulację ID identyfikatora tego użytkownika, czy jesteś pewien?',
            it: 'Se cancella tutte le date verrá annullato il numero identificativo del  badge di questo utilizzatore. É sicuro?',
            es: 'Si quita todas las fechas, se cancelará el número de identificación de este usuario, ¿está seguro?',
        },
        parkingAlreadyAssigned: {
            en: 'Car parking for this day has already been assigned',
            fr: 'Le parking pour cette journée a déjà été attribué',
            nl: 'De parkeerplaatsen voor deze dag zijn al toegewezen',
            pl: 'Miejsce parkingowe na ten dzień już zostało przypisane',
            it: 'Il posto auto per questa giornata é gia stato assegnato',
            es: 'El aparcamiento para esta fecha ya está asignado',
        },
        noBadgesAvailable: {
            en: "You don't have any available badges left",
            fr: "Vous n'avez plus de badges disponibles",
            nl: 'Je hebt geen beschikbare badges meer',
            pl: 'Nie masz już dostępnych wejściówek',
            it: 'Non ci sono piú badges disponibili',
            es: 'No le queda ninguna acreditación disponible',
        },
        buyMoreBadges: {
            en: 'Please buy more badges',
            fr: 'Veuillez commander des badges supplémentaires',
            nl: 'Koop meer badges',
            pl: 'Proszę dokup więcej wejściówek',
            it: 'Per favore acquisti altri badges',
            es: 'Por favor, compre más acreditaciones',
        },
        buyBadgesButton: {
            en: 'Buy badges',
            fr: 'Commander des badges',
            nl: 'Koop badges',
            pl: 'Zakup wejściówek',
            it: 'Acquista badges',
            es: 'Comprar acreditaciones',
        },
        cantSelectDate: {
            en: "You can't select this date",
            fr: 'Vous ne pouvez pas selectionner cette date',
            nl: 'Je kan deze datum niet selecteren',
            pl: 'Nie możesz wybrać tej daty',
            it: 'Non puó selezionare questa data',
            es: 'No puede selccionar esa fecha',
        },
        mainEventFull: {
            en: 'We are sorry, but the maximum attendance for this date has been reached',
            fr: 'Nous sommes désolés, mais la participation maximale à cette date a été atteinte',
            nl: 'Het spijt ons, maar de maximale opkomst voor deze datum is bereikt',
            pl: 'Przepraszamy, limit uczestników na ten dzień został przekroczony',
            it: 'Siamo spiacenti ma il numero massimo di partecipanti per questo evento é stato raggiunto',
            es: 'Lo sentimos, pero se ha alcanzado el número máximo de participantes para esta fecha',
        },
        eventFull: {
            en: 'We are sorry, but the maximum attendance for this event has been reached',
            fr: 'Nous sommes désolés, mais la participation maximale à cet événement a été atteinte',
            nl: 'Het spijt ons, maar de maximale opkomst voor dit evenement is bereikt',
            pl: 'Przepraszamy, maksymalna frekwencja na ten event została osiągnięta',
            it: 'Siamo spiacenti, ma per questo evento é stato raggiunto il numero massimo di partecipanti',
            es: 'Lo sentimos, pero se ha alcanzado el máximo de asistencia para este evento',
        },
        datesFull: {
            en: 'We are sorry, but the maximum attendance for selected dates has been reached',
            fr: 'Nous sommes désolés, mais la fréquentation maximale pour les dates sélectionnées a été atteinte',
            nl: 'Het spijt ons, maar het maximale aantal deelnemers voor de geselecteerde data is bereikt',
            pl: 'Przepraszamy, maksymalna frekwencja na wybrane dni została osiągnięta',
            it: 'Siamo spiacenti, ma per le date selezionate é stato raggiunto il numero massimo di partecipanti',
            es: 'Lo sentimos, pero se ha alcanzado el máximo de asistencia para las fechas seleccionadas',
        },
    },
};

export default makeTranslation(translationsPrivate);
