import React from 'react';
import {observer} from 'mobx-react-lite';
import './TotalQuantityOverlay.scss';
import {t} from '../translations';
import {projectStore} from '../stores/ProjectStore';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';

interface Props
{
    product: ICatalogProduct
    storeId: string
    large?: boolean
}

export default observer(function TotalQuantityOverlay({product, storeId, large}: Props)
{
    let totalQuantity = product.stores.find(ps => ps.storeId == storeId)?.weeks.reduce((sum, w) => sum + w.quantity, 0);
    if (!totalQuantity)
    {
        return null;
    }
    if (projectStore.selectedCountry == 'FR')
    {
        totalQuantity *= product.uvir;
    }
    return (
        <div className={'TotalQuantityOverlay ' + (large ? 'large' : 'small')}>
            {t.catalog.orderedOnImage(totalQuantity)}
        </div>
    );
});
