import {observable, action, computed, makeObservable} from 'mobx';
import { IInvoice } from './InvoiceStore';
import { loadTimelineInvoices } from './invoiceProxy';

export interface ILoadedInvoices {
    searchedId: string;
    invoices: IInvoice[];
}
class ProfileInvoiceStore {

    @observable loadedInvoices: ILoadedInvoices[] = [];
    @observable selectedId: string;

    @computed
    get getInvoices()
    {
        return this.selectedId && (this.loadedInvoices.find(i => i.searchedId === this.selectedId) ||
        this.loadedInvoices.find(i => i.invoices.find(ii => (ii.to.personId === this.selectedId || ii.to.companyId === this.selectedId))));
    }

    constructor()
    {
        makeObservable(this);
    }

    @action
    loadInvoices(searchedId: string)
    {
        this.selectedId = searchedId;
        if (this.getInvoices)
        {
            return;
        }
        loadTimelineInvoices(searchedId).then(action('loadInvoices', res =>
        {
            if (res)
            {
                if (res.length)
                {
                    res = res.map(i => ({...i, date: new Date(i.date)}))
                    this.loadedInvoices.push({searchedId, invoices: res});
                }
            }
        }))
    }
}

export const profileInvoiceStore = new ProfileInvoiceStore();
