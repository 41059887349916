import {action, computed, makeObservable, observable, toJS} from 'mobx';
import {
    callAddUsersToChat,
    callCreateChat,
    callHighlightChatMessage,
    callRemoveUsersFromChat,
    callSendMessage,
    callSendMessageOnTarget,
    IChat,
    ICreateChatInput,
    listChats,
    loadChats,
    callArchiveOrDeleteChat,
    ArchiveOrDeleteChatMethod,
    chatsPreviewMessage,
    chatActivity,
    callTakeLead,
    assignMeToChat,
    unassignMeFromChat,
} from './chatProxy';
import {user} from '../stores/user';
import {tryGetMinimizedChats, tryGetOpenChats, trySetMinimizedChats, trySetOpenChats} from '../stores/userPersist';
import {IChatActivityContext} from '../../graphql/api/chat/ChatActivity';
import { getCompanyChats } from '../Company/CompanyProxy';
import {IChatHighlightAction, IChatMessage, IChatMessageAction, IChatParticipantAction, IChatSupportAssignedAction} from '../../graphql/api/chat/Chat';
import {wsConnection} from '../ws/WsConnection';
import {registerGlobalEventHandler} from '../stores/globalEvents';

class ChatStore
{
    @observable chats: IChat[] = [];
    @observable openChatIds: string[] = tryGetOpenChats() || [];
    @observable minimizedChatIds: string[] = tryGetMinimizedChats() || [];
    @observable selectedChatIds: string[] = [];
    @observable chatsPreviewMessage: {id: string, previewMessage: string}[] = [];
    @observable chatActivity: IChatActivityContext = {deleted: [], archived: []};

    private targetIdsToLoad: string[];

    @computed
    get openChats()
    {
        return this.openChatIds.map(id => this.chats.find(c => c.id == id)).filter(c => c);
    }

    constructor()
    {
        makeObservable(this);
        registerGlobalEventHandler('logout', () =>
        {
            this.clear();
        });
    }

    reloadChatList()
    {
        return listChats().then(action('List Chats', res =>
        {
            if (res)
            {
                // remove chats we lost access to
                for (let i = 0; i < this.chats.length; ++i)
                {
                    const chatId = this.chats[i].id;
                    if (!res.find(c => c.id == chatId))
                    {
                        this.chats.splice(i--, 1);
                    }
                }
                // add new chats
                for (const chat of res)
                {
                    const loadedChat = this.chats.find(c => c.id === chat.id);
                    if (!loadedChat)
                    {
                        this.chats.push(chat);
                        if (chat.contactFlowChat || chat.contactButton)
                        {
                            const company = user.info?.company.find(c => chat.companies?.includes(c));
                            if (company && !this.chatsPreviewMessage.find(p => p.id === chat.id)?.previewMessage)
                            {
                                getCompanyChats(company).then(action(r =>
                                {
                                    if (r.includes(user.id))
                                    {
                                        if (+Date.now() - +chat.updated <= 2 * 60 * 1000)
                                        {
                                            this.openChat(chat.id);
                                        }
                                    }
                                }));
                            }
                        }
                    }
                }
            }
            else if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1' && user.loggedIn)
            {
                user.reloadUser();
            }
            return res;
        }));
    }

    loadChatsPreviewMessage()
    {
        chatsPreviewMessage().then(action(res =>
        {
            if (res)
            {
                this.chatsPreviewMessage = res;
            }
        }));
    }

    loadChatActivity()
    {
        chatActivity().then(action(res =>
        {
            if (res)
            {
                this.chatActivity = res;
            }
        }));
    }

    reloadOpenChats()
    {
        let since: Date;
        for (const chat of this.chats)
        {
            if (!since || +since < +chat.updated)
            {
                since = chat.updated;
            }
        }
        loadChats({ids: this.openChats.filter(c => !c.loaded).map(c => c.id), since}).then(this.handleLoadedChats);
    }

    reloadChats(ids: string[])
    {
        return loadChats({ids}).then(this.handleLoadedChats);
    }

    reloadChatsOnTargets(targetIds: string[])
    {
        if (this.targetIdsToLoad)
        {
            this.targetIdsToLoad.push(...targetIds);
        }
        else
        {
            this.targetIdsToLoad = targetIds.slice();
            setTimeout(() =>
            {
                loadChats({targetIds: this.targetIdsToLoad}).then(this.handleLoadedChats);
                this.targetIdsToLoad = null;
            }, 100);
        }
    }

    @action.bound
    handleLoadedChats(chats: IChat[])
    {
        if (chats)
        {
            for (const chat of chats)
            {
                chat.loaded = true;
                const index = this.chats.findIndex(c => c.id === chat.id);
                if (index >= 0)
                {
                    const prev = this.chats[index];
                    this.chats[index] = chat;
                    if (+prev.updated != +chat.updated)
                    {
                        this.openChat(chat.id);
                    }
                }
                else
                {
                    this.chats.push(chat);
                    if (!chat.targetId)
                    {
                        this.openChat(chat.id);
                    }
                }
            }
        }
    }

    @action
    openChat(id: string)
    {
        const index = this.openChatIds.indexOf(id);
        if (index < 0)
        {
            this.openChatIds.unshift(id);
            trySetOpenChats(toJS(this.openChatIds));
            this.reloadChats([id]);
        }
        else if (index > 1)
        {
            // move the chat to front
            this.openChatIds.splice(index, 1);
            this.openChatIds.unshift(id);
            trySetOpenChats(toJS(this.openChatIds));
        }
        this.maximizeChat(id);
    }

    @action
    closeChat(i: number | string)
    {
        if (typeof i !== 'number')
        {
            i = this.openChatIds.indexOf(i);
        }
        if (i < 0) return;
        const [id] = this.openChatIds.splice(i, 1);
        trySetOpenChats(toJS(this.openChatIds));
        this.maximizeChat(id);
    }

    @action
    minimizeChat(id: string)
    {
        if (!this.minimizedChatIds.includes(id))
        {
            this.minimizedChatIds.unshift(id);
            trySetMinimizedChats(this.minimizedChatIds);
        }
    }

    @action
    maximizeChat(id: string)
    {
        const i = this.minimizedChatIds.indexOf(id);
        if (i < 0) return;
        this.minimizedChatIds.splice(i, 1);
        trySetMinimizedChats(this.minimizedChatIds);
    }

    createChat(input: ICreateChatInput | string[])
    {
        if (Array.isArray(input))
        {
            input = {participants: input};
        }
        return callCreateChat(input).then(action('createChat', res =>
        {
            if (res)
            {
                this.chats.unshift(res);
                this.openChatIds.unshift(res.id);
            }
            return res;
        }));
    }

    @action.bound
    openSupportChat()
    {
        const chat = this.chats.find(c => c.support && c.usersInChat.some(p => p.id === user.id));
        if (chat)
        {
            this.openChatIds.unshift(chat.id);
            if (!chat.loaded)
            {
                this.reloadChats([chat.id]);
            }
            return;
        }
        this.createChat({support: true});
    }

    @action.bound
    openContactChat(page: string, block: string)
    {
        const chat = this.chats.find(c =>
            c.contactButton &&
            c.contactButton.page == page &&
            c.contactButton.block == block &&
            c.contactButton.person == user.id
        );
        if (chat)
        {
            this.openChat(chat.id);
            return true;
        }
        return false;
    }

    @action
    targetDeleted(targetId: string)
    {
        const index = this.chats.findIndex(c  => c.targetId == targetId);
        if (index >= 0)
        {
            this.closeChat(this.chats[index].id);
            this.chats.splice(index, 1);
        }
    }

    sendMessage(chatId: string, message: string, attachments: string[])
    {
        message = message.trim();
        // empty messages are pointless
        if (!message && !attachments?.length)
        {
            return;
        }
        callSendMessage(chatId, message, attachments).then(action('sendMessage', res =>
        {
            if (res)
            {
                const chat = this.chats.find(c => c.id == chatId);
                if (chat)
                {
                    if (!chat.participants.some(p => p.id === user.id))
                    {
                        chat.participants.push({
                            id: user.id,
                            firstName: user.info?.firstName,
                            lastName: user.info?.lastName,
                        });
                    }
                    // check if the update was already executed over the websocket
                    if (!chat.messages.some(m => +m.date == +res.date))
                    {
                        chat.messages.push(res);
                        // check if the chat was updated in the meanwhile
                        if (+chat.updated != +res.previousUpdated)
                        {
                            this.reloadChats([chatId]);
                        }
                        chat.updated = +res.date > +res.previousUpdated ? res.date : res.previousUpdated;
                    }
                }
                chatStore.resetChatArchivedDeletedStatus(chatId);
            }
            else
            {
                const index = this.chats.findIndex(c => c.id == chatId);
                if (index >= 0)
                {
                    this.chats.splice(index, 1);
                }
            }
        }));
    }

    sendMessageOnTarget(targetId: string, message: string, attachments: string[])
    {
        message = message.trim();
        // empty messages are pointless
        if (!message && !attachments?.length)
        {
            return;
        }
        callSendMessageOnTarget(targetId, message, attachments).then(action('sendMessageOnTarget', res =>
        {
            const chatMessage: IChatMessage = {
                author: res.author,
                date: res.date,
                msg: res.msg,
                attachments: res.attachments,
            };
            let chat = this.chats.find(c => c.targetId == targetId);
            if (chat)
            {
                chat.messages.push(chatMessage);
            }
            else
            {
                this.chats.unshift(chat = {
                    id: res.chatId,
                    updated: res.date,
                    targetId,
                    participants: res.participants,
                    usersInChat: res.participants,
                    messages: [chatMessage],
                });
            }
            // check if the chat was updated in the meanwhile
            if (+chat.updated != +res.previousUpdated)
            {
                this.reloadChats([chat.id]);
            }
            chat.updated = res.previousUpdated;
            chatStore.resetChatArchivedDeletedStatus(chat.id);
        }));
    }

    @action
    addUsersToChat(chatId: string, ids: string[])
    {
        callAddUsersToChat(chatId, ids).then(action(usersInChat =>
        {
            if (usersInChat)
            {
                const chat = this.chats.find(c => c.id == chatId);
                chat.usersInChat = usersInChat;
            }
        }));
    }

    @action
    removeUsersFromChat(chatId: string, ids: string[])
    {
        callRemoveUsersFromChat(chatId, ids).then(action(usersInChat =>
        {
            if (usersInChat)
            {
                const chat = this.chats.find(c => c.id == chatId);
                chat.usersInChat = usersInChat;
            }
        }));
    }

    @action
    highlightChatMessage(chatId: string, author: string, messageDate: Date, highlight: boolean)
    {
        callHighlightChatMessage(chatId, author, messageDate, highlight).then(action(success =>
        {
            if (success)
            {
                const chat = this.chats.find(c => c.id == chatId);
                const msg = chat.messages.find(m => m.author == author && +m.date == +messageDate);
                msg.highlighted = highlight;
            }
        }));
    }

    archiveOrDeleteChat(method: ArchiveOrDeleteChatMethod, restore = false)
    {
        const selectedChatIds = this.selectedChatIds;
        if (!selectedChatIds.length)
        {
            return null;
        }
        callArchiveOrDeleteChat(selectedChatIds, method, restore).then(action(res =>
        {
            if (res)
            {
                const field: keyof IChatActivityContext = method === 'delete' ? 'deleted' : 'archived';
                if (restore)
                {
                    this.chatActivity[field] = this.chatActivity[field].filter(id => !selectedChatIds.includes(id));
                }
                else
                {
                    this.chatActivity[field].push(...selectedChatIds);
                    for (const chat of this.chats)
                    {
                        if (chat.supportAssignedTo && selectedChatIds.includes(chat.id))
                        {
                            delete chat.supportAssignedTo;
                        }
                    }
                }
                this.selectedChatIds = [];
            }
        }));
    }

    resetChatArchivedDeletedStatus(chatId: string)
    {
        const {archived, deleted} = this.chatActivity;
        if (archived)
        {
            const indexArchived = archived.indexOf(chatId);
            if (indexArchived >= 0)
            {
                archived.splice(indexArchived, 1);
            }
        }
        if (deleted)
        {
            const indexDeleted = deleted.indexOf(chatId);
            if (indexDeleted >= 0)
            {
                deleted.splice(indexDeleted, 1);
            }
        }
    }

    takeLead()
    {
        if (!this.selectedChatIds.length)
        {
            return null;
        }
        return callTakeLead(this.selectedChatIds).then(action(chats =>
        {
            if (chats)
            {
                for (const chat of chats)
                {
                    const existing = this.chats.find(c => c.id == chat.id);
                    Object.assign(existing, chat);
                }
                this.selectedChatIds = [];
            }
            return chats;
        }));
    }

    onAssignMe(chatId: string)
    {
        assignMeToChat(chatId).then(action(({data, errors}) =>
        {
            if (!errors)
            {
                const existing = this.chats.find(c => c.id === chatId);
                existing.supportAssignedTo = data.assignMeToChat;
            }
        }));
    }

    onUnassignMe(chatId: string)
    {
        unassignMeFromChat(chatId).then(action(({data, errors}) =>
        {
            if (!errors)
            {
                const existing = this.chats.find(c => c.id === chatId);
                existing.supportAssignedTo = data.unassignMeFromChat;
            }
        }));
    }

    @action
    clear()
    {
        this.chats = [];
        this.openChatIds = [];
        this.minimizedChatIds = [];
        trySetOpenChats(null);
        trySetMinimizedChats(null);
    }
}

export const chatStore = new ChatStore();

if (location.hash.startsWith('#chat='))
{
    chatStore.openChat(location.hash.substr(6));
    location.hash = '';
}

wsConnection.addOpenListener((c, isReopen) =>
{
    if (isReopen)
    {
        chatStore.reloadChatList().then(res =>
        {
            if (res)
            {
                chatStore.reloadOpenChats();
            }
        });
    }
});

wsConnection.addMessageListener((msg: IChatMessageAction | IChatParticipantAction | IChatHighlightAction | IChatSupportAssignedAction) =>
{
    if (msg.action == 'chatMessage')
    {
        const chat = chatStore.chats.find(c => c.id == msg.chatId);
        if (chat)
        {
            msg.message.date = new Date(msg.message.date);
            if (!chat.messages.some(m => +m.date == +msg.message.date))
            {
                chat.messages.push(msg.message);
                chat.updated = msg.message.date;
                if (!chat.targetId && !chatStore.openChatIds.includes(msg.chatId))
                {
                    chatStore.openChat(msg.chatId);
                }
            }
        }
        else
        {
            chatStore.reloadChats([msg.chatId]);
        }
        chatStore.resetChatArchivedDeletedStatus(msg.chatId);
    }
    else if (msg.action == 'chatParticipant')
    {
        const chat = chatStore.chats.find(c => c.id == msg.chatId);
        if (chat)
        {
            chat.participants.push(msg.participant);
            chat.usersInChat.push(msg.participant);
        }
        chatStore.resetChatArchivedDeletedStatus(msg.chatId);
    }
    else if (msg.action == 'chatHighlight')
    {
        const chat = chatStore.chats.find(c => c.id == msg.chatId);
        if (chat)
        {
            msg.messageDate = new Date(msg.messageDate);
            if (+msg.messageDate > +chat.updated)
            {
                chat.updated = msg.messageDate;
            }
            const message = chat.messages.find(m => +m.date == +msg.messageDate);
            if (message)
            {
                message.highlighted = msg.highlight;
            }
        }
        chatStore.resetChatArchivedDeletedStatus(msg.chatId);
    }
    else if (msg.action == 'chatSupportAssigned')
    {
        const chat = chatStore.chats.find(c => c.id == msg.chatId);
        if (chat)
        {
            chat.supportAssignedTo = msg.supportAssignedTo;
        }
    }
});
