import {action, makeObservable, observable} from 'mobx';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {loadCatalogProductsById} from '../ExhibitorProducts/productProxy';
import {cartQuantityStore} from './CartQuantityStore';
import {cartValidateStore} from './CartValidateStore';
import {registerGlobalEventHandler} from '../stores/globalEvents';

class CatalogProductStore
{
    @observable products: ICatalogProduct[] = [];
    @observable loading: boolean = false;

    constructor()
    {
        makeObservable(this);
        cartQuantityStore.addListener((project, products) =>
        {
            for (const product of products)
            {
                const existing = this.products?.find(p => p.id == product.id);
                if (existing)
                {
                    Object.assign(existing, product);
                }
            }
        });
        cartValidateStore.addListener(() =>
        {
            this.products = [];
        });
        registerGlobalEventHandler('logout', () =>
        {
            this.products = [];
        });
    }

    // other catalog stores should update this store,
    // so that the product can be directly displayed
    // when the user navigates to single product page
    @action
    addProduct(product: ICatalogProduct)
    {
        const existing = this.products.find(p => p.id == product.id);
        if (existing)
        {
            Object.assign(existing, product);
        }
        else
        {
            this.products.push(product);
        }
    }

    loadProduct(id: string)
    {
        return this.loadProducts([id]).then(products => products ? products[0] : null);
    }

    @action
    loadProducts(ids: string[])
    {
        this.loading = true;
        return loadCatalogProductsById(ids).then(action(products =>
        {
            this.loading = false;
            if (products)
            {
                for (const product of products)
                {
                    this.addProduct(product);
                }
            }
            return products;
        }))
    }
}

export const catalogProductStore = new CatalogProductStore();
