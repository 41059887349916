import {call, gql} from '../client';
import { IChatActivityContext } from '../../graphql/api/chat/ChatActivity';
import {IChatContactButton, IChatMessage, IChatParticipant} from '../../graphql/api/chat/Chat';

export interface IChat
{
    id: string
    updated: Date
    targetId?: string
    support?: boolean
    supportAssignedTo?: string
    smsNumber?: boolean
    title?: string
    participants: IChatParticipant[]
    usersInChat: IChatParticipant[]
    companies?: string[]
    messages: IChatMessage[]
    loaded?: boolean // whether or not the messages are loaded
    // @deprecated
    contactFlowChat?: boolean
    contactButton?: IChatContactButton
}

export interface ICreateChatInput
{
    participants?: string[]
    participantCompanies?: string[]
    support?: boolean
    title?: string
    targetId?: string
    contactButton?: IChatContactButtonInput
}

type IChatContactButtonInput = Omit<IChatContactButton, 'person'>;

export interface INewChatMessage extends IChatMessage
{
    previousUpdated?: Date
}

export interface IChatMessageOnTarget extends IChatMessage
{
    chatId: string
    participants?: IChatParticipant[]
    previousUpdated?: Date
}

export interface ChatsFilter
{
    ids?: string[]
    targetIds?: string[]
    since?: Date
}

export type ArchiveOrDeleteChatMethod = 'delete' | 'archive';

// returns chats without messages
export function listChats()
{
    return call<{chatList: IChat[]}>(gql`{chatList{id,updated,targetId,support,supportAssignedTo,smsNumber,title,participants{id,firstName,lastName,phone,image,company},usersInChat{id,firstName,lastName,phone,image,company},companies,contactFlowChat,contactButton{page,block,person}}}`)
    .then(({data}) =>
    {
        if (!data) return null;
        for (const chat of data.chatList)
        {
            chat.updated = new Date(chat.updated);
            chat.messages = [];
        }
        return data.chatList;
    });
}

export function chatsPreviewMessage()
{
    return call<{chatListPreviewMessage: {id: string, previewMessage: string}[]}>(gql`{chatListPreviewMessage{id,previewMessage}}`)
    .then(({data}) => data?.chatListPreviewMessage);
}

export function chatActivity()
{
    return call<{chatActivity: IChatActivityContext}>(gql`{chatActivity{deleted,archived}}`)
    .then(({data}) => data?.chatActivity);
}

// chats can be filtered by id and/or by date
export function loadChats(input?: ChatsFilter)
{
    return call<{chats: IChat[]}>(
        gql`query($input:ChatFilters){chats(input:$input){id,updated,targetId,support,supportAssignedTo,smsNumber,title,participants{id,firstName,lastName,phone,image,company},usersInChat{id,firstName,lastName,phone,image,company},companies,contactButton{page,block,person},messages{author,date,msg,attachments{key,name,contentType},highlighted}}}`, {input})
    .then(({data}) =>
    {
        if (!data) return null;
        for (const chat of data.chats)
        {
            chat.updated = new Date(chat.updated);
            for (const message of chat.messages)
            {
                message.date = new Date(message.date);
            }
        }
        return data.chats;
    });
}

export function callCreateChat(input: ICreateChatInput)
{
    return call<{createChat: IChat}>(
        gql`mutation($input:CreateChatInput!){createChat(input:$input){id,updated,targetId,support,title,participants{id,firstName,lastName,phone,image,company},usersInChat{id,firstName,lastName,phone,image,company},companies,contactButton{page,block,person},messages{author,date,msg}}}`, {input})
    .then(({data}) =>
    {
        if (!data) return null;
        data.createChat.updated = new Date(data.createChat.updated);
        for (const message of data.createChat.messages)
        {
            message.date = new Date(message.date);
        }
        return data.createChat;
    });
}

export function callSendMessage(chatId: string, message: string, attachments: string[])
{
    return call<{sendMessage: INewChatMessage}>(
        gql`mutation($input:MessageInput!){sendMessage(input:$input){author,date,msg,attachments{key,name,contentType},previousUpdated}}`,
        {input: {chatId, message, attachments}})
    .then(({data}) =>
    {
        if (!data) return null;
        data.sendMessage.date = new Date(data.sendMessage.date);
        if (data.sendMessage.previousUpdated)
        {
            data.sendMessage.previousUpdated = new Date(data.sendMessage.previousUpdated);
        }
        return data.sendMessage;
    });
}

export function callSendMessageOnTarget(targetId: string, message: string, attachments: string[])
{
    return call<{sendMessageOnTarget: IChatMessageOnTarget}>(
        gql`mutation($input:MessageOnTargetInput!){sendMessageOnTarget(input:$input){chatId,author,date,msg,attachments{key,name,contentType},participants{id,firstName,lastName},usersInChat{id,firstName,lastName,image,company}}}`,
        {input: {targetId, message, attachments}})
    .then(({data}) =>
    {
        if (!data) return null;
        data.sendMessageOnTarget.date = new Date(data.sendMessageOnTarget.date);
        if (data.sendMessageOnTarget.previousUpdated)
        {
            data.sendMessageOnTarget.previousUpdated = new Date(data.sendMessageOnTarget.previousUpdated);
        }
        return data.sendMessageOnTarget;
    });
}

export function callAddUsersToChat(chatId: string, ids: string[])
{
    return call<{addUsersToChat: IChatParticipant[]}>(
        gql`mutation($input:ChatParticipantsInput!){addUsersToChat(input:$input){id,firstName,lastName,phone,image,company}}`, {input: {chatId, ids}})
    .then(({data}) => data?.addUsersToChat);
}

export function callRemoveUsersFromChat(chatId: string, ids: string[])
{
    return call<{removeUsersFromChat: IChatParticipant[]}>(
        gql`mutation($input:ChatParticipantsInput!){removeUsersFromChat(input:$input){id,firstName,lastName,phone,image,company}}`, {input: {chatId, ids}})
    .then(({data}) => data?.removeUsersFromChat);
}

export function callHighlightChatMessage(chatId: string, author: string, messageDate: Date, highlight: boolean)
{
    return call<{highlightChatMessage: boolean}>(
        gql`mutation($input:HighlightChatMessageInput!){highlightChatMessage(input:$input)}`, {input: {chatId, author, messageDate, highlight}})
    .then(({data}) => data?.highlightChatMessage);
}

export function callArchiveOrDeleteChat(chatsIds: string[], method: ArchiveOrDeleteChatMethod, restore: boolean)
{
    return call<{archiveOrDeleteChat: boolean}>(
        gql`mutation($input:ArchiveOrDeleteChatInput!){archiveOrDeleteChat(input:$input)}`, {input: {chatsIds, method, restore}})
    .then(({data}) => data?.archiveOrDeleteChat);
}

export function callTakeLead(ids: string[])
{
    return call<{chatTakeLead: IChat[]}>(
        gql`mutation($input:ChatTakeLeadInput!){chatTakeLead(input:$input){id,updated,usersInChat{id,firstName,lastName,phone,image,company},companies}}`, {input: {ids}})
    .then(({data}) => data?.chatTakeLead);
}

export function contactButtonChatsExist(input: IChatContactButtonInput[])
{
    return call<{contactButtonChatsExist: number}>(
        gql`query($input:[ChatContactButtonInput!]!){contactButtonChatsExist(input:$input)}`, {input})
    .then(({data}) => data?.contactButtonChatsExist);
}

export function assignMeToChat(id: string)
{
    return call<{assignMeToChat: string}>(
        gql`mutation($id:ID!){assignMeToChat(id:$id)}`,
        {id}
    );
}

export function unassignMeFromChat(id: string)
{
    return call<{unassignMeFromChat: string}>(
        gql`mutation($id:ID!){unassignMeFromChat(id:$id)}`,
        {id}
    );
}
