import {call, gql} from '../client';
import {Country, Language} from '../../graphql/api/Types';
import { ICompany } from '../Company/CompanyStore';
import {
    IBadge,
    IBadgeOperationalManagement,
    IBadgeStatus,
    IPersonPrivacy,
    IPersonProfile,
    IVerificationMethod,
    RegisterInput,
    UpdatePerson,
    UpdatePersonInput,
    UpdatePersonLanguageInput,
} from '../../graphql/api/user/User';
import { CompanyMember } from '../Dashboard/organizationStore';

export type ProfileType = 'headquarter' | 'guest' | 'store' | 'exhibitor';

export interface IContact
{
    id: string
    organization?: boolean
    deleteAccess?: boolean
    eventAdmin?: boolean
    support?: boolean
    validated?: boolean
    rejected?: boolean
    tester?: boolean
    email: string
    firstName: string
    lastName: string
    image?: string
    phone?: string
    language?: Language
    company?: string[]
    companyName?: string
    country?: Country[]
    profiles?: IPersonProfile[]
    function?: string
    badges?: IBadge[]
}

export interface IUserPageContact
{
    id: string
    validated?: boolean
    rejected?: boolean
    email: string
    firstName: string
    lastName: string
    phone?: string
    language?: Language
    company?: {id: string, name: string}[]
    country?: Country[]
    profiles?: IPersonProfile[]
    registered?: Date | string
}

export interface LoginInput
{
    value: string
    setCookie?: boolean
    eventRegistration?: boolean
    eventProjectId?: string
    language?: Language
}

export interface LoginResult
{
    identityId?: string
    personId?: string
    token: string
    method: IVerificationMethod
    verified?: boolean
}

export interface SwitchAccountResult
{
    identityId: string
    personId: string
    token: string
    user: UserInfo
}

export interface RegisterResult
{
    identityId: string
    personId: string
    company?: ICompany;
}

export interface UserInfo
{
    selectedProject?: string
    loginMethod?: string
    admin?: boolean
    organization?: boolean
    deleteAccess?: boolean
    eventAdmin?: boolean
    tester?: boolean
    video?: boolean
    hqReports?: boolean
    privacy?: Omit<IPersonPrivacy, 'date'>
    support?: boolean
    language?: Language
    firstName: string
    lastName: string
    email: string;
    company?: string[]
    companyName?: string
    country?: Country[]
    phone?: string
    image?: string
    profiles?: IPersonProfile[]
    function?: string
    badges?: IBadge[]
}

export interface UsersListItem
{
    id: string
    firstName: string
    lastName: string
    email: string
    phone: string
    showTerms?: boolean
}

export interface CreateUserOnEventInput
{
    project: string
    email: string
    phone: string
    firstName: string
    lastName: string
    profile: string
    company?: string
    function?: string
    // id or name
    invitedBy?: string
    fairDates: Date[]
    sideEvents: string[]
    sendConfirmation?: boolean
}

export function callLogin(input: LoginInput)
{
    return call<{login: LoginResult}>(
        gql`mutation ($input:LoginInput!){login(input:$input){identityId,personId,token,method,verified}}`,
        {input}
    );
}

export function callRegister(input: RegisterInput)
{
    return call<{register: RegisterResult}>(gql`mutation ($input:RegisterInput!){register(input:$input){identityId,personId,company}}`, {input});
}

// return null or error
export function callVerify(id: string, tokenId: string, code: string)
{
    return call<{verify: string}>(gql`mutation ($input:VerifyInput!){verify(input:$input)}`, {input: {id, tokenId, code}})
    .then(({data, errors}) => errors || (data?.verify ? [{message: data?.verify}] : null));
}

// return null or error
export function callVerifyOwnership(id: string, tokenId: string, code: string)
{
    return call<{verifyOwnership: string}>(gql`mutation ($input:VerifyInput!){verifyOwnership(input:$input)}`, {input: {id, tokenId, code}})
    .then(({data, errors}) => errors || (data?.verifyOwnership ? [{message: data?.verifyOwnership}] : null));
}

export function callLogout()
{
    return call(gql`mutation ($input:LogoutInput!){logout(input:$input)}`, {input: {unsetCookie: true}});
}

export function loadUser(authorization?: string)
{
    return call<{user: UserInfo}>(
        gql`{user{selectedProject,loginMethod,admin,organization,deleteAccess,eventAdmin,support,tester,video,hqReports,privacy{necessary,preferences,statistics},email,language,firstName,lastName,company,companyName,country,phone,image,profiles{id},function,badges}}`,
        null,
        {authorization}
    );
}

export function loadUserAccounts(value: string, authorization: string)
{
    return call<{userAccounts: UsersListItem[]}>(
        gql`query($value:String){userAccounts(value:$value){id,firstName,lastName,email,phone,showTerms}}`,
        {value},
        {authorization}
    ).then(({data}) => data?.userAccounts);
}

export function callSwitchAccount(id: string, authorization: string)
{
    return call<{switchAccount: SwitchAccountResult}>(
        gql`mutation($id:ID!){switchAccount(id:$id){identityId,personId,token,user{admin,organization,eventAdmin,support,tester,video,hqReports,privacy{necessary,preferences,statistics},email,language,firstName,lastName,company,companyName,country,phone,image,profiles{id},badges}}}`,
        {id},
        {authorization}
    );
}

export function callAdvancedSwitchAccount(id: string)
{
    return call<{advancedSwitchAccount: SwitchAccountResult}>(
        gql`mutation($id:ID!){advancedSwitchAccount(id:$id){identityId,personId,token,user{admin,organization,eventAdmin,support,tester,video,hqReports,privacy{necessary,preferences,statistics},email,language,firstName,lastName,company,companyName,country,phone,image,profiles{id},badges}}}`,
        {id}
    ).then(({data}) => data?.advancedSwitchAccount);
}

export function requestWSToken()
{
    return call<{requestWSToken: {identityId: string, connectionToken: string}}>(
        gql`mutation{requestWSToken{identityId,connectionToken}}`
    ).then(({data}) => data?.requestWSToken);
}

export function loadContacts()
{
    return call<{contacts: Pick<IContact, 'id' | 'email' | 'firstName' | 'lastName'>[]}>(
        gql`{contacts{id,email,firstName,lastName}}`,
        null,
        {noBatch: true}
    )
    .then(({data}) => data?.contacts);
}

export function loadEventContacts(authorization?: string)
{
    return call<{contacts: {id: string, firstName: string, lastName: string}[]}>(
        gql`{contacts{id,firstName,lastName}}`,
        null,
        {authorization}
    )
    .then(({data}) => data?.contacts);
}

export function getContact(id: string)
{
    return call<{getContact: IContact & {companyInfo: ICompany[]}}>(
        gql`query($id: String!){getContact(id:$id){id,organization,deleteAccess,eventAdmin,support,validated,rejected,tester,language,email,firstName,lastName,phone,image,company,companyName,country,companyInfo{id,name,country,image,projects,exhibitor,store,supplier},profiles{id},function,badges}}`,
        {id}
    ).then(({data}) => data?.getContact);
}

export function updatePerson(input: UpdatePersonInput)
{
    return call<{updatePerson: UpdatePerson}>(
        gql`mutation ($input:UpdatePersonInput!){updatePerson(input:$input){firstName,lastName,image,language,profiles{id},company,companyName,country}}`,
        {input}
    ).then(({data}) => data?.updatePerson);
}

export function updatePersonLanguage(input: UpdatePersonLanguageInput)
{
    return call<{updatePersonLanguage: Language}>(
        gql`mutation ($input:UpdatePersonLanguageInput!){updatePersonLanguage(input:$input)}`,
        {input}
    ).then(({data}) => data?.updatePersonLanguage);
}

export function acceptPrivacyPolicy(input: Omit<IPersonPrivacy, 'date'>)
{
    return call<{acceptPrivacyPolicy: Omit<IPersonPrivacy, 'date'>}>(
        gql`mutation ($input:PrivacyPolicyInput!){acceptPrivacyPolicy(input:$input){necessary,preferences,statistics}}`,
        {input}
    ).then(({data}) => data?.acceptPrivacyPolicy);
}

export function getCompanyMembers(ids: string[])
{
    return call<{companyMembers: IContact[]}>(
        gql`query($ids:[ID!]!){companyMembers(ids:$ids){id,validated,rejected,email,firstName,lastName,phone,image,company,country,language,profiles{id},function,badges}}`,
        {ids}
    ).then(({data}) => data?.companyMembers);
}

export function updateEmailOrPhone(input: {value: string, target: 'email' | 'phone', id: string})
{
    return call<{updateUserEmailOrPhone: IContact}>(
        gql`mutation ($input:ContactEmailOrPhoneInput!){updateUserEmailOrPhone(input:$input){id,validated,rejected,email,firstName,lastName,phone,image,company,companyName,country,language,profiles{id},function,badges}}`,
        {input}
    );
}

export function renamePerson(input: {id: string, firstName: string, lastName: string})
{
    return call<{renamePerson: boolean}>(
        gql`mutation ($input:RenamePersonInput!){renamePerson(input:$input)}`,
        {input}
    ).then(({data}) => data?.renamePerson);
}

export function changePersonCompanies(input: {id: string, companies: string[], companyName?: string})
{
    return call<{changePersonCompanies: {companies: {id: string, name: string}[], companyName?: string}}>(
        gql`mutation ($input:ChangePersonCompaniesInput!){changePersonCompanies(input:$input){companies{id,name},companyName}}`,
        {input}
    ).then(({data}) => data?.changePersonCompanies);
}

export function changePersonProfile(input: {id: string, profile: string})
{
    return call<{changePersonProfile: boolean}>(
        gql`mutation ($input:ChangePersonProfileInput!){changePersonProfile(input:$input)}`,
        {input}
    ).then(({data}) => data?.changePersonProfile);
}

export function deleteUser(userId: string)
{
    return call<{deleteUser: boolean}>(
        gql`mutation ($userId:String!){deleteUser(userId:$userId)}`,
        {userId}
    ).then(({data}) => data?.deleteUser);
}

export function callAddCompanyMember(input: CompanyMember)
{
    return call<{addCompanyMember: IContact}>(
        gql`mutation ($input:addCompanyMemberInput!){addCompanyMember(input:$input){id,validated,rejected,email,firstName,lastName,phone,image,company,companyName,country,language,profiles{id},function,badges}}`,
        {input}
    );
}

export function updateExhibitorOrderValidation(userId: string, companyId: string, enabled: boolean)
{
    return call<{updateExhibitorOrderValidation: boolean}>(
        gql`mutation ($input:ExhibitorOrderValidationInput!){updateExhibitorOrderValidation(input:$input)}`,
        {input: {userId, companyId, enabled}}
    ).then(({data}) => data?.updateExhibitorOrderValidation);
}

export function getCompanyOrderValidation(companyId: string)
{
    return call<{companyOrderValidation: string[]}>(
        gql`query($companyId:ID!){companyOrderValidation(companyId:$companyId)}`,
    {companyId}
    ).then(({data}) => data?.companyOrderValidation);
}

export function assignBadgeForDay(targetUserId: string, project: string, day: Date | string)
{
    return call<{assignBadgeForDay: IBadge[]}>(
        gql`mutation($input:AssignBadgeForDayInput!){assignBadgeForDay(input:$input)}`,
    {input: {targetUserId, project, day}}
    );
}

export function unAssignBadgeForDay(targetUserId: string, project: string, day: Date | string)
{
    return call<{unAssignBadgeForDay: IBadge[]}>(
        gql`mutation($input:AssignBadgeForDayInput!){unAssignBadgeForDay(input:$input)}`,
    {input: {targetUserId, project, day}}
    ).then(({data}) => data?.unAssignBadgeForDay);
}

export function assignParkingForDay(targetUserId: string, project: string, day: Date | string)
{
    return call<{assignParkingForDay: IBadge[]}>(
        gql`mutation($input:AssignBadgeForDayInput!){assignParkingForDay(input:$input)}`,
        {input: {targetUserId, project, day}}
    );
}

export function unAssignParkingForDay(targetUserId: string, project: string, day: Date | string)
{
    return call<{unAssignParkingForDay: IBadge[]}>(
        gql`mutation($input:AssignBadgeForDayInput!){unAssignParkingForDay(input:$input)}`,
        {input: {targetUserId, project, day}}
    );
}

export function assignSideEvent(targetUserId: string, project: string, reference: string)
{
    return call<{assignSideEvent: IBadge[]}>(
        gql`mutation($input:AssignSideEventInput!){assignSideEvent(input:$input)}`,
    {input: {targetUserId, project, reference}}
    );
}

export function unAssignSideEvent(targetUserId: string, project: string, reference: string)
{
    return call<{unAssignSideEvent: IBadge[]}>(
        gql`mutation($input:AssignSideEventInput!){unAssignSideEvent(input:$input)}`,
    {input: {targetUserId, project, reference}}
    ).then(({data}) => data?.unAssignSideEvent);
}

export function setBadgeStatus(targetUserId: string, project: string, status: IBadgeStatus)
{
    return call<{setBadgeStatus: IBadge[]}>(
        gql`mutation($input:SetBadgeStatusInput!){setBadgeStatus(input:$input)}`,
    {input: {targetUserId, project, status}}
    );
}

export function setBadgeVIP(targetUserId: string, project: string, vip: boolean)
{
    return call<{setBadgeVIP: IBadge[]}>(
        gql`mutation($input:SetBadgeVIPInput!){setBadgeVIP(input:$input)}`,
    {input: {targetUserId, project, vip}}
    );
}

export function updateUserFunction(targetUserId: string, func: string)
{
    return call<{updateUserFunction: IContact}>(
        gql`mutation($input:UpdateUserFunctionInput!){updateUserFunction(input:$input){id,function}}`,
    {input: {targetUserId, function: func}}
    ).then(({data}) => data?.updateUserFunction);
}

export function updateUserOperationalManagement(targetUserId: string, project: string, operationalManagement: IBadgeOperationalManagement)
{
    return call<{updateUserOperationalManagement: IContact}>(
        gql`mutation($input:UpdateUserOperationalManagementInput!){updateUserOperationalManagement(input:$input){id,function}}`,
    {input: {targetUserId, project, operationalManagement}}
    ).then(({data}) => data?.updateUserOperationalManagement);
}

export function createUserOnEvent(input: CreateUserOnEventInput)
{
    return call<{createUserOnEvent: {user: IContact, companyName: string}}>(
        gql`mutation($input:CreateUserOnEventInput!){createUserOnEvent(input:$input){user{id,validated,rejected,email,firstName,lastName,phone,image,company,companyName,country,language,profiles{id},function,badges},companyName}}`,
    {input}
    );
}
