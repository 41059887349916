import {UserInfo} from './userProxy';
import { Language } from '../../graphql/api/Types';

export function tryGet(key: string): string | null
{
    try
    {
        return localStorage.getItem(key);
    }
    catch (e)
    {
        return null;
    }
}

export function trySet(key: string, value: string)
{
    try
    {
        if (value != null)
        {
            localStorage.setItem(key, value);
        }
        else
        {
            localStorage.removeItem(key);
        }
    }
    catch (e)
    {}
}

export function tryGetObj<T extends {} = {}>(key: string): T | null
{
    try
    {
        const json = localStorage.getItem(key);
        return json ? JSON.parse(json) : null;
    }
    catch (e)
    {
        return null;
    }
}

export function trySetObj<T extends {} = {}>(key: string, value: T)
{
    try
    {
        if (value != null)
        {
            localStorage.setItem(key, JSON.stringify(value));
        }
        else
        {
            localStorage.removeItem(key);
        }
    }
    catch (e)
    {}
}

export function tryGetVerified()
{
    return !!tryGet('verified');
}

export function trySetVerified(verified: boolean)
{
    trySet('verified', verified ? 'true' : null);
}

export function tryGetAdvancedSwitch()
{
    return tryGet('advancedSwitch');
}

export function trySetAdvancedSwitch(advancedSwitch: string)
{
    trySet('advancedSwitch', advancedSwitch);
}

export function tryGetUserInfo()
{
    return tryGetObj<UserInfo>('user_info');
}

export function trySetUserInfo(info: UserInfo)
{
    if (info)
    {
        const filtered = {};
        for (const key of Object.getOwnPropertyNames(info))
        {
            if (typeof info[key] == 'boolean' || ['language', 'company', 'profiles', 'country', 'privacy'].includes(key))
            {
                filtered[key] = info[key];
            }
        }
        trySetObj('user_info', filtered);
    }
    else
    {
        trySetObj('user_info', null);
    }
}

export function tryGetLandingLanguage()
{
    return tryGetObj<Language>('landing_lang');
}
export function trySetLandingLanguage(lang: string)
{
    trySetObj('landing_lang', lang);
}

export function tryGetOpenChats()
{
    return tryGetObj<string[]>('openChats');
}
export function trySetOpenChats(openChatsIds: string[])
{
    trySetObj('openChats', openChatsIds);
}

export function tryGetMinimizedChats()
{
    return tryGetObj<string[]>('minimizedChats');
}
export function trySetMinimizedChats(minimizedChatIds: string[])
{
    trySetObj('minimizedChats', minimizedChatIds);
}
