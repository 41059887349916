import {action, makeObservable, observable} from 'mobx';
import {tryGet, tryGetObj, trySet, trySetObj} from '../stores/userPersist';
import {user} from '../stores/user';
import {IPersonPrivacy} from '../../graphql/api/user/User';

class PrivacyPolicyStore
{
    @observable private choices: Omit<IPersonPrivacy, 'date'> = getChoices(); // apply after next login
    @observable private choiceTime: number = getChoiceTime(); // apply after next login
    @observable private accepted: boolean = getAccepted(); // whether to show the disclaimer on login page

    constructor()
    {
        makeObservable(this);
    }

    get showDisclaimer()
    {
        return user.loggedIn && user.info && !this.choices ? !!user.info?.privacy?.necessary : this.accepted;
    }

    get necessary()
    {
        return user.loggedIn && user.info ? !!user.info?.privacy?.necessary : this.choices?.necessary;
    }

    get preferences()
    {
        return user.loggedIn && user.info ? !!user.info?.privacy?.preferences : this.choices?.preferences;
    }

    get statistics()
    {
        return user.loggedIn && user.info ? !!user.info?.privacy?.statistics : this.choices?.statistics;
    }

    @action
    accept(privacy: Omit<IPersonPrivacy, 'date'>)
    {
        if (user.loggedIn && user.info)
        {
            user.acceptPrivacyPolicy(privacy);
        }
        else
        {
            this.choices = privacy;
            setChoices(privacy);
            this.choiceTime = Date.now();
            setChoiceTime(Date.now());
        }
        this.accepted = true;
        setAccepted(true);
    }

    @action
    addToUser()
    {
        if (user.loggedIn && !user.info.privacy?.necessary && this.choices)
        {
            // user must login within 1h after accepting
            if (Date.now() - this.choiceTime < 60 * 60000)
            {
                user.acceptPrivacyPolicy(this.choices);
            }
            this.choices = null;
            setChoices(null);
            this.choiceTime = 0;
            setChoiceTime(null);
        }
    }
}

export const privacyPolicyStore = new PrivacyPolicyStore();

function getChoices()
{
    return tryGetObj<Omit<IPersonPrivacy, 'date'>>('login-privacy');
}

function setChoices(privacy: Omit<IPersonPrivacy, 'date'>)
{
    return trySetObj('login-privacy', privacy);
}

function getChoiceTime()
{
    return +tryGet('login-privacy-date') || 0;
}

function setChoiceTime(time: number)
{
    return trySet('login-privacy-date', time == null ? null : time + '');
}

export function getAccepted()
{
    return !!tryGet('login-privacy-accepted');
}

export function setAccepted(accepted: boolean)
{
    trySet('login-privacy-accepted', accepted ? 'true' : null);
}
