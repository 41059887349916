import React from 'react';
import OrderStandProductCard from './OrderStandProductCard';
import './OrderCatalog.scss';
import {observer} from 'mobx-react-lite';
import {IProduct, orderStandProductStore} from './OrderStandProductStore';
import {categoryStore} from '../Categories/CategoryStore';
import {ICategoryHighlight} from '../../graphql/api/category/Category';
import {pickLanguage} from '../stores/utility';
import {orderStandStore} from './OrderStandStore';
import {isID} from '../../lib/common';
import {user} from '../stores/user';

export default observer(function OrderCatalog()
{
    const mod = user.moderator;
    const availableProducts = orderStandProductStore.projectProducts.filter(p => !p.archived && (mod || p.available));
    const categories = categoryStore.countryCategories.map(cat => ({
        cat,
        products: availableProducts.filter(p => p.category?.main === cat.id && !isID(p.category?.sub)),
        subcategories: cat.subcategories.map(sub => ({
            sub,
            products: availableProducts.filter(p =>
                p.category?.main === cat.id &&
                p.category?.sub === sub.id
            ),
        }))
    }));
    return (
        <div>
            {categories.filter(cat =>
                cat.products.some(productHasPackageAccess) || cat.subcategories.some(sub => sub.products.some(productHasPackageAccess))
            ).map(({cat, products: catProds, subcategories}) =>
                <div key={cat.id}>
                    <h4 className='font-2 mt-4'>{pickLanguage(cat.name)}</h4>
                    <OrderCategoryHighlight highlight={cat.highlight}/>
                    <div className='d-flex flex-wrap'>
                        {catProds.filter(productHasPackageAccess).map(renderProduct)}
                    </div>
                    {subcategories.filter(sub =>
                        sub.products.some(productHasPackageAccess)
                    ).map(({sub, products: subProds}) =>
                        <React.Fragment key={sub.id}>
                            <h5 className='font-2 mt-4'>{pickLanguage(sub.name)}</h5>
                            <OrderCategoryHighlight highlight={sub.highlight}/>
                            <div className='d-flex flex-wrap'>
                                {subProds.filter(productHasPackageAccess).map(renderProduct)}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}
        </div>
    );
});

function productHasPackageAccess(p: IProduct)
{

    return p?.packageAccess?.includes(orderStandStore.selectedPackage);
}

function renderProduct(p: IProduct)
{
    return (
        <OrderStandProductCard key={p.id} product={p}/>
    );
}

interface OrderCategoryHighlightProps
{
    highlight: ICategoryHighlight
    packageAccess?: boolean
}

export const OrderCategoryHighlight = observer(function OrderCategoryHighlight({highlight, packageAccess}: OrderCategoryHighlightProps)
{
    if (!highlight || !highlight.active)
    {
        return null;
    }
    let products = highlight.products.map(id => orderStandProductStore.projectProducts.find(p => p.id === id));
    if (!packageAccess)
    {
        products = products.filter(productHasPackageAccess);
    }
    if (!products.length)
    {
        return null;
    }
    return (
        <div className='d-flex'>
            <div className='CategoryHighlightBanner mr-4 mb-4'>
                <div className='content'>
                    <h2>{pickLanguage(highlight.title)}</h2>
                    <div
                        className='clearfix'
                        dangerouslySetInnerHTML={{__html: pickLanguage(highlight.description)}}
                    />
                </div>
                <div className='image' style={highlight.image ? {backgroundImage: `url(${highlight.image})`} : null}/>
            </div>
            {products.map(renderProduct)}
        </div>
    )
});
