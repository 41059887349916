import React, {lazy, Suspense, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
const PhoneInput = lazy(() => import('react-phone-number-input'));
import type {Props, DefaultInputComponentProps} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {landingStore} from './LandingStore';
import {t} from '../translations';

export default observer(function PhoneInputLazy(props: Props<DefaultInputComponentProps>)
{
    useEffect(() =>
    {
        if (!landingStore.myCountryCode)
        {
            landingStore.getMyCountryCode();
        }
    }, []);

    return (
        <Suspense
            fallback={
                <div className={(props.className || '') + ' PhoneInput'}>
                    {React.createElement(props.inputComponent || 'input', {
                        value: props.value,
                        placeholder: props.placeholder ?? t.authentication.phonePlaceholder,
                        autoFocus: props.autoFocus,
                        onChange: props.onChange && (e => props.onChange(e.currentTarget.value)),
                        onKeyDown: props.onKeyDown as any,
                        ...props.numberInputProps
                    })}
                </div>
            }
        >
            <PhoneInput
                placeholder={t.authentication.phonePlaceholder}
                defaultCountry={landingStore.myCountryCode || null}
                countryOptionsOrder={['BE', 'PL', 'IT', 'FR', 'NL', 'ES', '|', '...']}
                {...props}
            />
        </Suspense>
    );
});
