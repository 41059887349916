import {action, computed, makeObservable, observable, toJS} from 'mobx';
import {
    createStand,
    getStands,
    setupStand,
    selectPackage,
    selectProducts,
    submitStand,
    StandSetupInput,
    standPreference,
    StandPreferenceInput,
    approveStand,
    changeStandItems,
    changeStandPosition,
    declineStand,
    renameStand,
    setStandProject,
    standAttachments,
    getStand,
    deleteStand,
    addStandLayout,
    markLayoutAsDownloaded,
    ChangeStandPackageProductDiscountInput,
    changeStandPackageProductDiscounts,
    removeOutOfStockProducts,
    duplicateStand,
    selectPalletType,
    setStandRealSurface,
} from './OrderStandProxy';
import {
    FreshSpaceRange,
    IStandContext,
    IStandSetupFresh,
    IStandSetupNormal,
    ISurfaceDimensions,
    StandType,
    IStandDiscount,
    IStandProduct
} from '../../graphql/api/stand/Stand';
import {debounce} from '../common/debounce';
import {user} from '../stores/user';
import {getSectorType, pickSectorToUse, standSurfaceMin, SurfaceSectorType, SurfaceStandType} from '../data/surfaceOptions';
import {companyStore} from '../Company/CompanyStore';
import {projectStore} from '../stores/ProjectStore';
import { globalNavigate } from '../App';
import Swal from 'sweetalert2';
import { packageStore } from '../Packages/PackageStore';
import {t} from '../translations';
import {trySet} from '../stores/userPersist';
import {ITranslations} from '../../graphql/api/Types';
import {pickLanguage} from '../stores/utility';

export interface IStand extends IStandContext
{
    id: string
    versions?: any[]
    submittedBy?: {id: string, name: string}[]
}

interface ChangeStandItems
{
    id: string
    section: string
    products?: IStandProduct[]
    packageDiscount?: IStandDiscount[]
    invoiceId?: string
}

class OrderStandStore
{
    @observable stands: IStand[] = [];
    @observable selectedType: StandType = null;
    @observable createSelectedType = false;
    @observable selectedId: string = null;

    // pending updates
    @observable selectedPackageId: string = null;
    @observable selectedProducts: {[id: string]: number} = {};
    @observable PO: string;
    @observable preference: string;

    // general data
    @observable termsAndConditions = false;
    @observable secondTermsAndConditions = false;

    private initialLoadStart: {[project: string]: boolean} = {};
    @observable initialLoad: {[project: string]: boolean} = {};
    private isCreating: boolean;
    private submittingStandSetup: boolean;

    constructor()
    {
        makeObservable(this);
    }

    @computed
    get selected(): IStand
    {
        return this.selectedId ? this.stands.find(s => s.id === this.selectedId) :
            this.selectedType ? this.ownStands.find(s => s.setup.type === this.selectedType) : null;
    }

    @computed
    get projectStands(): IStand[]
    {
        const projectId = projectStore.id;
        return this.stands.filter(s => s.project === projectId);
    }

    // only for selected project
    @computed
    get ownStands(): IStand[]
    {
        return this.projectStands.filter(s =>
            user.info?.company.includes(s.companyId) &&
            (
                (user.moderator || !projectStore.selected?.fair.settings?.blockNewStandOrders?.[s.setup.type]) ||
                s.approved?.package || s.approved?.products ||
                s.submitted?.package || s.submitted?.products ||
                s.invoiced?.package || s.invoiced?.products
            )
        );
    }

    // only for selected project
    @computed
    get ownSubmittedStands(): IStand[]
    {
        return this.ownStands.filter(s =>
            s.approved?.package || s.approved?.products ||
            s.submitted?.package || s.submitted?.products ||
            s.invoiced?.package || s.invoiced?.products
        );
    }

    get isSubmittedApprovedInvoiced()
    {
        const s = this.selected;
        return (
            !!s.submitted.package || !!s.submitted.products?.length ||
            !!s.approved.package || !!s.approved.products?.length ||
            !!s.invoiced?.package || !!s.invoiced?.products?.length
        );
    }

    get isSubmitted()
    {
        const s = this.selected;
        return !!s.submitted.package || !!s.submitted.products?.length;
    }

    get selectedPackage(): string
    {
        let s: IStand;
        return this.selectedPackageId ||
            ((s = this.selected) && (
                s.created?.package?.id ||
                s.submitted?.package?.id ||
                s.approved?.package?.id ||
                s.invoiced?.package?.id)
            );
    }

    @computed
    get selectedStandSectorType(): SurfaceSectorType
    {
        if (user.moderator)
        {
            return 'all';
        }
        const sector = companyStore.companies.find(c => c.id == this.selected.companyId)?.exhibitor.sector;
        return sector && pickSectorToUse(projectStore.selectedCountry, getSectorType(sector.primary), getSectorType(sector.secondary)) || 'all';
    }

    @computed
    get selectedStandSectors()
    {
        const sector = companyStore.companies.find(c => c.id == this.selected.companyId)?.exhibitor.sector;
        return sector && [getSectorType(sector.primary) || sector.primary, getSectorType(sector.secondary) || sector.secondary];
    }

    userStandSurfaceMin(standType: SurfaceStandType)
    {
        if (user.moderator)
        {
            return 9;
        }
        const sectors = this.selectedStandSectors;
        return standSurfaceMin(projectStore.selected, sectors?.[0], sectors?.[1], standType);
    }

    loadStand(id: string)
    {
        return getStand(id).then(action(stand =>
        {
            if (stand)
            {
                const s = this.stands.find(ss => ss.id == id);
                if (s)
                {
                    Object.assign(s, stand);
                }
                else
                {
                    this.stands.push(stand);
                }
            }
            return stand;
        }));
    }

    reloadStands(project: string)
    {
        this.initialLoadStart[project] = true;
        getStands(project).then(action('stands', res =>
        {
            if (res)
            {
                this.initialLoad[project] = true;
                for (const stand of res)
                {
                    const index = this.stands.findIndex(s => s.id == stand.id);
                    if (index >= 0)
                    {
                        this.stands[index] = stand;
                    }
                    else
                    {
                        this.stands.push(stand);
                    }
                }
                if (!this.selected && this.selectedType)
                {
                    orderStandStore.selectOwnStand(this.selectedType, this.createSelectedType);
                }
            }
        }));
    }

    @action
    selectOwnStand(type: StandType, create?: boolean)
    {
        this.selectedType = type;
        this.selectedId = null;
        trySet('orderStand.type', type);
        this.createSelectedType = create;
        const project = projectStore.id;
        if (!this.initialLoad[project])
        {
            if (!this.initialLoadStart[project])
            {
                this.reloadStands(projectStore.id);
            }
            return;
        }
        if (create)
        {
            const existing = this.stands.find(s => user.info?.company.includes(s.companyId) && s.setup.type == type && s.project == project);

            if (!existing && (type == 'concept' || !projectStore.isDigitalFair))
            {
                this.createStand(type);
            }
        }
    }

    @action
    select(id: string)
    {
        this.selectedId = id;
        if (id)
        {
            this.selectedType = null;
        }
    }

    createStand(type: StandType, companyId?: string)
    {
        if (this.isCreating || !projectStore.id || (!user.moderator && projectStore.selected.fair.settings?.blockNewStandOrders?.[type]))
        {
            return Promise.resolve(null);
        }
        this.isCreating = true;
        return createStand({type, companyId, project: projectStore.id}).then(action('createStand', ({errors, data}) =>
        {
            this.isCreating = false;
            if (errors)
            {
                alert(errors[0].extensions?.input ? errors[0].message : t.global.somethingWentWrong);
            }
            else if (data)
            {
                const res = data.createStand;
                this.stands.push(res);
                this.select(res.id); // some code relies on this
                return res.id;
            }
        }));
    }

    @action
    setStandProject(id: string, project: string)
    {
        setStandProject({id, project}).then(action('setStandProject', stand =>
        {
            if (stand)
            {
                const loadedStand = this.stands.find(s => s.id === id);
                loadedStand.project = stand.project;
            }
        }));
    }

    private setupStand()
    {
        const setup = toJS(this.selected.setup);
        delete setup.type;
        delete (setup as IStandSetupNormal).realSurface;
        this.submittingStandSetup = true;
        this.debouncedSetupStand({
            id: this.selected.id,
            ...setup
        });
    }

    private debouncedSetupStand = debounce((input: StandSetupInput)  =>
    {
        setupStand(input).then(action(stand =>
        {
            this.submittingStandSetup = false;
            if (stand)
            {
                const loadedStand = this.stands.find(s => s.id === stand.id);
                loadedStand.created.package = stand.created.package;
                loadedStand.submitted.package = stand.submitted.package;
                loadedStand.approved.package = stand.approved.package;
            }
        }));
    }, 500);

    @action.bound
    changeSurface(surface: number, dimensions: ISurfaceDimensions)
    {
        const setup = this.selected.setup as IStandSetupNormal;
        setup.surface = surface;
        setup.dimensions = dimensions;
        if (surface < this.userStandSurfaceMin('corner'))
        {
            setup.corner = false;
        }
        if (surface < this.userStandSurfaceMin('head'))
        {
            setup.head = false;
        }
        if (surface < this.userStandSurfaceMin('open'))
        {
            setup.open = false;
        }
        this.setupStand();
    }
    @action
    changeRealSurface(realSurface: number)
    {
        if (this.selected.setup.type === 'normal')
        {
            this.selected.setup.realSurface = realSurface;
            setStandRealSurface(this.selected.id, realSurface).then(action(stand =>
            {
                if (stand)
                {
                    const loadedStand = this.stands.find(s => s.id === stand.id);
                    (loadedStand.setup as IStandSetupNormal).realSurface = (stand.setup as IStandSetupNormal).realSurface;
                }
            }));
        }
    }
    @action
    changeSpaceRange(spaceRange: FreshSpaceRange)
    {
        (this.selected.setup as IStandSetupFresh).spaceRange = spaceRange;
        this.setupStand();
    }
    @action
    changeDimensions(d: ISurfaceDimensions)
    {
        if (this.selected.setup.type === 'normal')
        {
            this.selected.setup.dimensions = d;
            this.setupStand();
        }
    }
    @action
    changeOpenStand(type: 'open' | 'head' | 'corner' | null | undefined)
    {
        if (this.selected.setup.type === 'normal')
        {
            this.selected.setup.corner = type == 'corner';
            this.selected.setup.head = type == 'head';
            this.selected.setup.open = type == 'open';
            this.setupStand();
        }
    }

    @action.bound
    selectPackage(id: string)
    {
        if (this.submittingStandSetup)
        {
            return;
        }
        if (this.selectedPackage == id)
        {
            return;
        }
        this.selectedPackageId = id;
        selectPackage(this.selected.id, id).then(action('selectPackage', stand =>
        {
            this.selectedPackageId = null;
            if (stand)
            {
                const loadedStand = this.stands.find(s => s.id === stand.id);
                loadedStand.created = stand.created;
                loadedStand.submitted = stand.submitted;
                loadedStand.approved = stand.approved;
                loadedStand.invoiced = stand.invoiced;
                const setup = this.selected.setup;
                if (!user.moderator && setup.type === 'normal' && packageStore.packages.find(p => p.id === id)?.cornerHeadOpenOnly &&
                    (!setup.open && !setup.head && !setup.corner) && this.userStandSurfaceMin('corner') <= setup.surface)
                {
                    this.changeOpenStand('corner');
                }
            }
        }));
    }

    @action
    selectProduct(id: string, quantity: number)
    {
        this.selectedProducts[id] = quantity;
        this.debouncedSelectProducts();
    }
    private debouncedSelectProducts = debounce(()  =>
    {
        const list = Object.getOwnPropertyNames(this.selectedProducts).map(id => ({id, quantity: this.selectedProducts[id]}));
        selectProducts({
            id: this.selected.id,
            products: list,
        }).then(action('selectProducts', stand =>
        {
            for (const {id, quantity} of list)
            {
                if (this.selectedProducts[id] === quantity)
                {
                    delete this.selectedProducts[id];
                }
            }
            if (stand)
            {
                const loadedStand = this.stands.find(s => s.id === stand.id);
                loadedStand.created.products = stand.created.products;
                loadedStand.created.includesLimitedStock = stand.created.includesLimitedStock;
            }
        }));
    }, 500);

    @action
    changePO(PO: string)
    {
        this.PO = PO;
    }

    @action
    submitStand()
    {
        if (user.archivedExhibitor)
        {
            alert('Cannot submit a stand for this fair');
            return;
        }
        const pkg = packageStore.packages.find(p => p.id === this.selectedPackage);
        const setup = this.selected.setup;
        if (pkg?.cornerHeadOpenOnly && !user.moderator && setup?.type === 'normal' && !setup?.corner && !setup?.head && !setup?.open)
        {
            return;
        }
        const PO = this.PO && !this.selected.PO.includes(this.PO) ? this.PO : undefined;
        this.PO = undefined;
        const id = this.selected.id;
        submitStand(id, PO).then(action('submitStand', ({errors, data}) =>
        {
            if (errors)
            {
                const err = errors[0];
                if (err.message == 'out-of-stock')
                {
                    Swal.fire({
                        title: t.standCreation.outOfStock,
                        html: (err.extensions?.additionalProductsOutOfStock as {id: string, name: ITranslations}[])?.map(p => pickLanguage(p.name)).join('<br/>'),
                        icon: 'error',
                        confirmButtonText: t.standCreation.removeOutOfStock,
                        cancelButtonText: t.global.cancel,
                        showCancelButton: true,
                    }).then(value =>
                    {
                        if (value.value)
                        {
                            removeOutOfStockProducts(id).then(action(stand =>
                            {
                                if (stand)
                                {
                                    const loadedStand = this.stands.find(s => s.id === stand.id);
                                    loadedStand.created.products = stand.created.products;
                                    loadedStand.created.includesLimitedStock = stand.created.includesLimitedStock;
                                }
                            }));
                        }
                    });
                }
                else
                {
                    const message =
                        err.message == 'Package not allowed for selected setup' ?
                            t.standCreation.packageNotAllowed
                            :
                            err.message == 'select-pallet-type' ?
                                t.standCreation.pleaseSelectPalletType
                                :
                                err.message === 'invalid-stand' ?
                                    t.standCreation.yourSelectionDeleted
                                    :
                                    err.extensions?.input ?
                                        err.message
                                        :
                                        t.global.somethingWentWrong;

                    Swal.fire({
                        title: message,
                        icon: 'error',
                        confirmButtonColor: '#403d39',
                    }).then(() =>
                    {
                        if (err.message == 'invalid-stand')
                        {
                            location.reload();
                        }
                    });
                }
            }
            else if (data)
            {
                const stand = data.submitStand;
                const loadedStand = this.stands.find(s => s.id === stand.id);
                loadedStand.PO = stand.PO;
                loadedStand.created = stand.created;
                loadedStand.submitted = stand.submitted;
                loadedStand.submittedBy = stand.submittedBy;
                Swal.fire({title: 'OK!', icon: 'success'});
            }
        }));
    }

    @action
    changePreference(id: string, preference: string)
    {
        this.preference = preference;
        this.debouncedStandPreference({id, preference});
    }

    private debouncedStandPreference = debounce((input: StandPreferenceInput)  =>
    {
        standPreference(input).then(action('changePreference', stand =>
        {
            if (stand)
            {
                this.preference = null;
                const loadedStand = this.stands.find(s => s.id === stand.id);
                loadedStand.preference = stand.preference;
            }
        }));
    }, 1000);

    rename(id: string, name: string)
    {
        renameStand({id, name}).then(action('renameStand', stand =>
        {
            if (stand)
            {
                const loadedStand = this.stands.find(s => s.id === stand.id);
                loadedStand.name = stand.name;
            }
        }));
    }

    changePosition(id: string, position: string)
    {
        changeStandPosition({id, position}).then(action('changeStandPosition', stand =>
        {
            if (stand)
            {
                const loadedStand = this.stands.find(s => s.id === stand.id);
                loadedStand.position = stand.position;
            }
        }));
    }

    approve(id: string)
    {
        approveStand(id).then(action('approveStand', stand =>
        {
            if (stand)
            {
                const loadedStand = this.stands.find(s => s.id === stand.id);
                loadedStand.submitted = stand.submitted;
                loadedStand.approved = stand.approved;
            }
        }));
    }

    decline(id: string)
    {
        declineStand(id).then(action('declineStand', stand =>
        {
            if (stand)
            {
                const loadedStand = this.stands.find(s => s.id === stand.id);
                loadedStand.submitted = stand.submitted;
            }
        }));
    }

    @action
    handleStandItemsChange({id, section, products, packageDiscount}: ChangeStandItems)
    {
        if (packageDiscount)
        {
            packageDiscount = packageDiscount.filter(d => d.type === 'percentage' ? d.amount !== 1 : d.amount !== 0);
            if (!packageDiscount.length)
            {
                packageDiscount = [];
            }
        }
        if (products)
        {
            for (const p of products)
            {
                if (p.discount)
                {
                    p.discount = p.discount.filter(d => d.type === 'percentage' ? d.amount !== 1 : d.amount !== 0);
                    if (!p.discount.length)
                    {
                        p.discount = null;
                    }
                }
            }
        }
        changeStandItems({
            id,
            section,
            products: products?.map(p => ({
                id: p.id,
                price: p.price,
                quantity: p.quantity,
                discount: p.discount
            })),
            packageDiscount
        }).then(action('changeStandItems', ({errors, data: {changeStandItems: stand}}) =>
        {
            if (!errors)
            {
                if (stand)
                {
                    const loadedStand = this.stands.find(s => s.id === id);
                    Object.assign(loadedStand, stand);
                }
            }
            else
            {
                this.reloadStands(projectStore.id);
            }
        }));
    }

    @action
    handleStandPackageProductsDiscountsChange(input: ChangeStandPackageProductDiscountInput)
    {
        if (input.discount)
        {
            input.discount = input.discount.filter(d => d.type === 'percentage' ? d.amount !== 1 : d.amount !== 0);
            if (!input.discount.length)
            {
                input.discount = null;
            }
        }
        changeStandPackageProductDiscounts(input).then(action( ({errors, data: {changeStandPackageProductDiscounts: stand}}) =>
        {
            if (!errors)
            {
                if (stand)
                {
                    const loadedStand = this.stands.find(s => s.id === input.id);
                    Object.assign(loadedStand, stand);
                }
            }
            else
            {
                this.reloadStands(projectStore.id);
            }
        }));
    }

    standAttachments(add: string[], remove: string, standId: string)
    {
        standAttachments({add, remove, standId}).then(action(stand =>
        {
            if (stand)
            {
                const std = this.stands.find(s => s.id == stand.id);
                std.attachments = stand.attachments;
            }
        }));
    }

    removeStand(standId: string)
    {
        if (!confirm('Delete stand?'))
        {
            return;
        }
        deleteStand(standId).then(action(res =>
        {
            if (res)
            {
                this.stands = this.stands.filter(c => c.id != standId);
                globalNavigate()('/stands');
            }
        }));
    }

    duplicateStand(standId: string)
    {
        duplicateStand(standId).then(action(stand =>
        {
            if (stand)
            {
                this.stands.push(stand);
                globalNavigate()('/stands#' + stand.id);
            }
        }));
    }

    addStandLayout(standId: string, url: string)
    {
        addStandLayout(standId, url).then(action(standLayouts =>
        {
            const s = this.stands.find(ss => ss.id == standId);
            if (s)
            {
                s.standLayouts = standLayouts;
            }
        }));
    }

    downloadStandLayout(layout: string)
    {
        markLayoutAsDownloaded(this.selected?.id, layout).then(res =>
        {
            if (res)
            {
                const l = this.stands.find(s => s.id === this.selected?.id).standLayouts.find(sl => sl.layout == layout);
                l.downloaded = true;
            }
        });
    }

    @action
    changePalletType(palletType: string)
    {
        const stand = this.selected;
        const standPkg =
            stand.created.package ||
            stand.submitted.package ||
            stand.approved.package ||
            stand.invoiced?.package;
        const prev = standPkg.palletType;
        standPkg.palletType = palletType;
        selectPalletType(stand.id, palletType).then(action(res =>
        {
            standPkg.palletType = res || prev;
        }));
    }
}

export const orderStandStore = new OrderStandStore();
