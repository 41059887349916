// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N_6apGzzlEwYgz8Lqov6, .qPGQjUKiw9J2YSqcpQLD {
  margin-bottom: 10px;
}

.N_6apGzzlEwYgz8Lqov6 label {
  margin-bottom: 10px;
  display: inline-block;
  font-size: 18px;
}

.N_6apGzzlEwYgz8Lqov6 input {
  /*width: 100%;*/
  padding: 10px;
  font-size: 18px;
  border-radius: 3px;
  border: 1px solid #2169CA;
  /*outline: none;*/
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/components/FormField.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":[".field, .fieldMargin {\n  margin-bottom: 10px;\n}\n\n.field label {\n  margin-bottom: 10px;\n  display: inline-block;\n  font-size: 18px;\n}\n\n.field input {\n  /*width: 100%;*/\n  padding: 10px;\n  font-size: 18px;\n  border-radius: 3px;\n  border: 1px solid #2169CA;\n  /*outline: none;*/\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `N_6apGzzlEwYgz8Lqov6`,
	"fieldMargin": `qPGQjUKiw9J2YSqcpQLD`
};
export default ___CSS_LOADER_EXPORT___;
