import {call, gql} from '../client';
import {ICompany} from './CompanyStore';
import { Country } from '../../graphql/api/Types';
import {IAddress} from '../../graphql/api/company/Company';

interface CompanyInfo
{
    userId?: string
    country: string
    name: string
}

interface CompanyAddressFromVatInput
{
    id: string
    contact: number
}

export interface ICompanySearchResult
{
    id: string
    name: string
    address: string
    vat?: string
}

interface CompanyContactsInfoInput
{
    id: string
    contacts: IAddress[]
}

export function setCompany(info: CompanyInfo)
{
    return call<{createCompany: ICompany}>(
        gql`mutation ($input:CompanyInfo!){createCompany(input:$input){id,name,image,country,projects,exhibitor,store,supplier}}`,
        {input: info}
    ).then(({data}) => data?.createCompany);
}

export function getCompanies(ids?: string[])
{
    return call<{companies: ICompany[]}>(gql`query($ids:[ID!]){companies(ids:$ids){id,name,country,image,projects,exhibitor,store,supplier}}`, {ids})
    .then(({data}) => data?.companies);
}

export function searchCompanyOrVat(search: string, country: Country)
{
    return call<{searchCompanyOrVat: ICompanySearchResult[]}>(
        gql`query($input: SearchCompanyOrVatInput!){searchCompanyOrVat(input: $input){id,name,address,vat}}`,
    {input: {search, country}}
    ).then(({data}) => data?.searchCompanyOrVat);
}
export function updateCompany(info: ICompany)
{
    return call<{updateCompany: ICompany}>(
        gql`mutation ($input:CompanyEdit!){updateCompany(input:$input){id,name,image,country,projects,exhibitor,store,supplier}}`,
        {input: info}
    ).then(({data}) => data?.updateCompany);
}
export function updateCompanyContactInfo(input: CompanyContactsInfoInput)
{
    return call<{updateCompanyContactInfo: ICompany}>(
        gql`mutation($input:CompanyContactInfoInput!){updateCompanyContactInfo(input:$input){id,name,image,country,projects,exhibitor,store,supplier}}`,
        {input}
    ).then(({data}) => data?.updateCompanyContactInfo);
}

export function companyAddressFromVat(input: CompanyAddressFromVatInput)
{
    return call<{companyAddressFromVat: ICompany}>(
        gql`mutation ($input:CompanyAddressFromVatInput!){companyAddressFromVat(input:$input){id,name,country,image,exhibitor,store,supplier}}`,
        {input}
    ).then(({data}) => data?.companyAddressFromVat);
}

export function deleteCompany(id: string)
{
    return call<{deleteCompany: string}>(gql`mutation ($id:String!){deleteCompany(id:$id)}`, {id})
    .then(({data}) => data?.deleteCompany);
}

export function getCompanyChats(companyId: string)
{
    return call<{companyChats: string[]}>(gql`query($companyId:ID!){companyChats(companyId:$companyId)}`, {companyId})
    .then(({data}) => data?.companyChats);
}

export function setCompanyChats(personId: string, companyId: string, enabled: boolean)
{
    return call<{updateCompanyChats: boolean}>(
        gql`mutation ($input:CompanyChatAccessInput!){updateCompanyChats(input:$input)}`,
        {input: {personId, companyId, enabled}})
    .then(({data}) => data?.updateCompanyChats);
}
