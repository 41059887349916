// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UploadProgressBar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}
.UploadProgressBar .progressBar {
  position: absolute;
  height: 20px;
  background-color: #4c8bf5;
}
.UploadProgressBar .progressText {
  position: absolute;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  width: 100%;
  background-color: rgba(76, 139, 245, 0.2);
}`, "",{"version":3,"sources":["webpack://./frontend/Pages/UploadProgressBar.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,UAAA;EACA,oBAAA;AACF;AAAE;EACE,kBAAA;EACA,YAAA;EACA,yBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;EACA,yCAAA;AAEJ","sourcesContent":[".UploadProgressBar {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 1;\n  pointer-events: none;\n  .progressBar {\n    position: absolute;\n    height: 20px;\n    background-color: #4c8bf5;\n  }\n  .progressText {\n    position: absolute;\n    font-size: 14px;\n    line-height: 20px;\n    color: #fff;\n    text-align: center;\n    width: 100%;\n    background-color: rgba(76, 139, 245, .2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
