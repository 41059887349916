import React from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import type {GraphQLFormattedError} from 'graphql';
import './LandingNavbar.scss';
import {user} from '../stores/user';
import {languageList, languageNames} from '../data/language';

interface State
{
    loginPopup: boolean
    loginEmail: boolean
    languagePopup: boolean
    sent: boolean
    codeField: boolean
    input: string
    code: string
    status: string
    errors?: GraphQLFormattedError[]
}

@observer
export class LandingNavbar extends React.PureComponent<{}, State>
{
    state: State = {
        loginPopup: false,
        loginEmail: true,
        languagePopup: false,
        sent: false,
        codeField: false,
        input: '',
        code: '',
        status: '',
        errors: []
    };

    navbarContent = React.createRef<HTMLDivElement>();

    toggleLanguage = (e) =>
    {
        e.preventDefault();
        this.setState({languagePopup: !this.state.languagePopup});
    };

    toggleExpanded = () =>
    {
        if (this.navbarContent.current.classList.contains('show'))
        {
            this.navbarContent.current.classList.remove('show');
        }
        else
        {
            this.navbarContent.current.classList.add('show');
        }
    };

    handleLanguage = e =>
    {
        user.changeLandingLanguage(e.target.dataset.language);
        this.setState({languagePopup: false});
    };

    handleInput = e => this.setState({input: e.target.value});
    handlePhone = input => this.setState({input});
    handleCode = e => this.setState({code: e.target.value});

    handleLanguageBlur = (e: React.FocusEvent) =>
    {
        if (this.state.languagePopup && !e.currentTarget.contains(e.relatedTarget as any))
        {
            this.setState({languagePopup: false});
        }
    };

    render()
    {
        const s = this.state;
        return (
            <nav className='navbar navbar-expand-sm navbar-light'>
                <Link className='navbar-brand' to='/'>LET'S CONNECT</Link>
                <button
                    className='navbar-toggler'
                    onClick={this.toggleExpanded}
                >
                    <span className='navbar-toggler-icon'/>
                </button>
                <div ref={this.navbarContent} className='collapse navbar-collapse'>
                    <ul className='navbar-nav mr-auto'/>
                    <ul className='navbar-nav'>
                        <li className='nav-item dropdown language-select-landing' tabIndex={0} onBlur={this.handleLanguageBlur}>
                            <a className='nav-link cursor-pointer dropdown-toggle' onClick={this.toggleLanguage}>
                                {languageNames[user.language]}
                            </a>
                            {s.languagePopup &&
                            <div className='dropdown-menu p-0 mb-2 language-popup show'>
                                <ul className='list-group'>
                                    {languageList.map(l =>
                                        <li
                                            key={l}
                                            className={'list-group-item cursor-pointer ' + (user.language == l ? 'active' : '')}
                                            data-language={l}
                                            onClick={this.handleLanguage}
                                        >
                                            {languageNames[l]}
                                        </li>
                                    )}
                                </ul>
                            </div>}
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}
