import {useMemo, useState} from 'react';
import './Email.scss';
import {duplicate} from '../../lib/common';
import {IContact} from '../stores/userProxy';
import {emailStore} from './EmailStore';
import DropZone from './DropZone';
import {attachmentStore} from '../Attachment/AttachmentStore';
import AttachmentTag, {AttachmentTagProps} from '../Attachment/AttachmentTag';
import {EmailTo, PersonSelection} from './PersonSelection';
import {editorChangeHandler, tinyMCEConfigEmail} from '../common/tinymceConfig';
import { Editor as EditorTinyMCE } from '@tinymce/tinymce-react';

interface Props
{
    id?: any
    defaultTo?: IContact
}

export default function EmailWindow({id, defaultTo}: Props)
{
    const [subject, setSubject] = useState('');
    const [to, setTo] = useState<EmailTo[]>(() => defaultTo ? [defaultTo] : []);
    const [content, setContent] = useState('');
    const [attachments, setAttachments] = useState<string[]>([]);
    const [minimize, setMinimize] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);

    const tinymceConfig = useMemo(() => duplicate(tinyMCEConfigEmail), []);

    const canSend = !!to.length && !!subject && (!!content || !!attachments.length);

    const handleSubject = (e) => setSubject(e.target.value);
    const handleContent = editorChangeHandler(setContent);

    const handleSelect = selected =>
    {
        if (!to.includes(selected))
        {
            const copy = to.slice();
            copy.push(selected);
            setTo(copy);
        }
    };
    const handleRemove = removed =>
    {
        const index = to.indexOf(removed);
        if (index != -1)
        {
            const copy = to.slice();
            copy.splice(index, 1);
            setTo(copy);
        }
    };

    const handleSend = () =>
    {
        if (!to.length)
        {
            alert('Please specify at least 1 recipient');
            return;
        }
        if (!subject)
        {
            alert('Please specify the subject');
            return;
        }
        if (!content && !attachments.length)
        {
            alert('Please specify some content');
            return;
        }
        if (attachments.length && attachments.some(key => attachmentStore.getUploadInProgress(key)))
        {
            alert('Some files are still uploading.');
            return;
        }
        emailStore.sendEmail(to.map(t => typeof t == 'string' ? t : t.id), subject, content, attachments);
        emailStore.closeEmail(id);
    };

    const handleClose = () =>
    {
        emailStore.closeEmail(id);
    };

    const handleAttachment = (attachment: File) =>
    {
        attachmentStore.upload(attachment).then(u =>
        {
            const copy = attachments.slice();
            copy.push(u.key);
            setAttachments(copy);
        });
    };

    const removeAttachment = (a: AttachmentTagProps) =>
    {
        attachmentStore.cancel(a.keyName);
        const copy = attachments.slice();
        copy.splice(a.index, 1);
        setAttachments(copy);
    };

    const handleMinimize = () =>
    {
        setMinimize(!minimize);
    };

    const handleFullscreen = () =>
    {
        setFullscreen(!fullscreen);
        setMinimize(false);
    };

    return (
        <div className={'EmailWindow ' + (minimize ? 'minimized ' : '') + (fullscreen ? 'fullscreen' : '')}>
            <div className='EmailTitle'>
                <span className='text'>{subject}</span>
                <div className='options'>
                    <pre onClick={handleMinimize}>_</pre>
                    <pre onClick={handleFullscreen}>🗖</pre>
                    <pre onClick={handleClose}>X</pre>
                </div>
            </div>
            {!minimize && (<>
                <div className='EmailContent'>
                    <PersonSelection
                        selected={to}
                        onSelect={handleSelect}
                        onRemove={handleRemove}
                    />
                    <div className='EmailSubject mt-2 mb-3 d-flex flex-wrap'>
                        <span className='label float-left mr-2'>Subject</span>
                        <input type='text' value={subject} onChange={handleSubject}/>
                    </div>
                    <div className='EmailBody'>
                        <EditorTinyMCE
                            value={content}
                            {...tinymceConfig}
                            onEditorChange={handleContent}
                        />
                        <DropZone onAttachment={handleAttachment}/>
                    </div>
                    <div className='mt-2 d-flex flex-wrap'>
                        {attachments.map((a, i) => (
                            <AttachmentTag
                                key={i}
                                keyName={a}
                                index={i}
                                onClick={removeAttachment}
                            />
                        ))}
                    </div>
                </div>
                <div className='EmailBottom'>
                    <button className={canSend ? 'button' : 'button-empty'} onClick={handleSend}>Send</button>
                </div>
            </>)}
        </div>
    );
}
