import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {Link, useNavigate} from 'react-router-dom';
import moment from 'moment';
import {observer} from 'mobx-react-lite';
import './FairParticipation.scss';
import {user} from '../stores/user';
import {contactsStore} from '../Contact/ContactsStore';
import {pickLanguage} from '../stores/utility';
import {findProfile} from '../data/profiles';
import {SortButton} from '../UsersPage/SortButton';
import {Loading} from '../common/Loading';
import {projectStore} from '../stores/ProjectStore';
import {eventStore} from '../Event/EventStore';
import {companyBadgesStore} from './CompanyBadgesStore';
import {sameUTCDay} from '../../lib/common';
import Checkbox from '../common/Checkbox';
import {companyNameStore} from '../StoreCatalog/CompanyNameStore';
import TextEditable from '../common/TextEditable';
import {call, gql} from '../client';
import DownloadButton from '../Reports/DownloadButton';
import DownloadCompanyBadges from './DownloadCompanyBadges';
import { t } from '../translations';
import DropdownSearch from '../common/DropdownSearch';
import ActionButton from '../common/ActionButton';
import {RemainingBadgesNegativeBlock} from '../Event/RemainingBadges';
import {companyStore} from '../Company/CompanyStore';
import {IContact} from '../stores/userProxy';

export default observer(function FairParticipation()
{
    const projectId = projectStore.id;
    useEffect(() =>
    {
        if (projectId)
        {
            eventStore.loadEvent(projectId);
            eventStore.loadStatuses(projectId);
        }
    }, [projectId]);

    let companyId = useLocation().hash.substr(1);
    if (!companyId)
    {
        companyId = user.info?.company[0];
    }
    useEffect(() =>
    {
        contactsStore.loadCompanyMembers([companyId]);
    }, [companyId]);

    if (!user.info)
    {
        return <div className='position-relative py-5'><Loading/></div>;
    }

    return (
        <div className='FairParticipation p-3'>
            <div className='d-flex justify-content-between'>
                <h3 className='mb-4'>{t.fairParticipation.membersFairPart(companyNameStore.find(companyId))}</h3>
                <div>
                    <DownloadTable companyId={companyId}/>
                    {!projectStore.isFRNonFood && <DownloadCompanyBadges companyId={companyId} fairParticipation/>}
                </div>
            </div>
            {(user.moderator || user.isHostess || user.info.company.length > 1) &&
            <FairParticipationSelect companyId={companyId}/>
            }
            <FairParticipationView companyId={companyId}/>
        </div>
    )
});

const FairParticipationSelect = observer(function FairParticipationSelect({companyId}: {companyId: string})
{
    const navigate = useNavigate();
    const canLoadAll = user.moderator || user.isHostess;

    const country = projectStore.selectedCountry;
    useEffect(() =>
    {
        if (canLoadAll)
        {
            companyNameStore.loadForCountry(country);
        }
        else
        {
            companyNameStore.load(user.info.company);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canLoadAll ? country : user.info.company.join('')]);

    const companyNames = (canLoadAll ?
            companyNameStore.companyNamesForCountry.get(country)?.slice() || [] :
            user.info.company.map(id => ({
                id,
                name: companyNameStore.find(id)?.trim() || '',
            }))
    );
    companyNames.sort((a, b) => (a.name).localeCompare(b.name, 'en', {sensitivity: 'base'}));

    return (
        <div className='form-inline mb-4'>
            <span className='mr-3'>{t.fairParticipation.viewOfCompany}</span>
            <DropdownSearch
                className='CompanySelectDropdown'
                selected={companyId}
                options={companyNames.map(c => ({value: c.id, name: c.name || 'Unnamed'}))}
                onSelect={id => navigate('#' + id)}
            />
        </div>
    )
});

interface SelectedCompanyProps
{
    companyId: string
}

const FairParticipationView = observer(function FairParticipationView({companyId}: SelectedCompanyProps)
{
    return (
        <>
            {(contactsStore.currentUserAndContacts.some(u => u.company?.includes(companyId) && u.profiles?.[0]?.id.includes('exhibitor'))) &&
            <CompanyBadges companyId={companyId}/>
            }
            <FairParticipationTable companyId={companyId}/>
            <AddMembers/>
            <SideEvents/>
        </>
    )
});

const CompanyBadges = observer(function CompanyBadges({companyId}: SelectedCompanyProps)
{
    const projectId = projectStore.id;
    useEffect(() =>
    {
        companyBadgesStore.load(projectId, companyId);
    }, [projectId, companyId]);

    let totalAssigned = 0;
    const assignedByDate = eventStore.projectEvent?.fairDates.map(d =>
    {
        let count = 0;
        for (const u of contactsStore.currentUserAndContacts)
        {
            if (u.company?.includes(companyId) && u.profiles[0].id !== 'guest')
            {
                const badge = u.badges?.find(p => p.project == projectId);
                if (badge &&
                    badge.days.some(uDate => sameUTCDay(d.date, uDate)) &&
                    !badge.sideEvents?.some(s => s.invitationCode && sameUTCDay(s.date, d.date))
                )
                {
                    ++count;
                    ++totalAssigned;
                }
            }
        }
        return count;
    });

    const badges = companyBadgesStore.find(projectId, companyId);

    return (
        <div className='mt-4 d-flex align-items-baseline'>
            <div className='mr-4'>
                <p>{t.fairParticipation.totalNoBadges}: <b>{badges ?? '?'}</b></p>
                {eventStore.projectEvent?.fairDates.map((d, i) =>
                    <p key={d.date as any}>{t.fairParticipation.assignedFor} {moment(d.date).format('DD/MM/YYYY')}: <b>{assignedByDate[i]}</b></p>
                )}
                <p>{t.fairParticipation.unassignedBadges}: <b>{badges != null ? badges - totalAssigned : '?'}</b></p>
                {!user.moderator && !user.isHostess && ['exhibitorMainAccount', 'exhibitorKeyAccount'].includes(user.info?.profiles[0]?.id) &&
                <Link to='/order-stand'><button className='button-empty'>{t.fairParticipation.buyBadges}</button></Link>}
            </div>
            {badges != null && badges - totalAssigned < 0 &&
            <RemainingBadgesNegativeBlock/>
            }
        </div>
    );
});

const FairParticipationTable = observer(function FairParticipationTable({companyId}: SelectedCompanyProps)
{
    const [sortBy, setSortBy] = useState('');

    let users = contactsStore.currentUserAndContacts.filter(u => u.company?.includes(companyId));
    if (sortBy)
    {
        const projectId = projectStore.id;
        users = users.slice();
        const [byParts, direction] = sortBy.split('-');
        const [by, param] = byParts.split('_');
        const date = new Date(+param);
        switch (by)
        {
            case 'code': users.sort((a, b) => (a.badges?.find(i => i.project == projectId)?.registrationId || '').localeCompare(b.badges?.find(i => i.project == projectId)?.registrationId || '', 'en', {sensitivity: 'base'})); break;
            case 'profile': users.sort((a, b) => (a.profiles?.[0]?.id || 'Exhibitor').localeCompare(b.profiles?.[0]?.id || 'Exhibitor', 'en', {sensitivity: 'base'})); break;
            case 'name': users.sort((a, b) => (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName, 'en', {sensitivity: 'base'})); break;
            case 'email': users.sort((a, b) => (a.email).localeCompare(b.email, 'en', {sensitivity: 'base'})); break;
            case 'function': users.sort((a, b) => (a.function || '').localeCompare(b.function || '', 'en', {sensitivity: 'base'})); break;
            case 'date':
                users.sort((a, b) =>
                    +!!b.badges?.find(p => p.project == projectId)?.days.some(uDate => sameUTCDay(date, uDate))
                    -
                    +!!a.badges?.find(p => p.project == projectId)?.days.some(uDate => sameUTCDay(date, uDate))
                );
                break;
            case 'sideEvent':
                users.sort((a, b) =>
                    +!!b.badges?.find(p => p.project == projectId)?.sideEvents?.some(s => s.reference == param)
                    -
                    +!!a.badges?.find(p => p.project == projectId)?.sideEvents?.some(s => s.reference == param)
                );
                break;
        }
        if (direction == 'up')
        {
            users.reverse();
        }
    }

    const userProfile = user.info?.profiles?.[0]?.id;

    const canAssign = !user.isExhibitor || ['exhibitorMainAccount', 'exhibitorKeyAccount'].includes(userProfile);

    const event = eventStore.projectEvent;
    const fairDates = event ?
        user.moderator || user.isHostess ?
            event.fairDates :
            event.fairDates.filter(d =>
                d.profileAccess?.includes(userProfile) ||
                d.regionStoreTypeAccess?.some(rp =>
                    companyStore.ownStores.some(s =>
                        rp.territory?.toUpperCase() == s.store.territory?.toUpperCase() &&
                        rp.storeType == s.store.storeType
                    )
                ) ||
                d.contactAccess?.some(c => user.id == c || user.info?.company.includes(c))
            ) :
        [];
    const sideEvents = event ?
        user.moderator || user.isHostess ?
            event.sideEvents :
            event.sideEvents.filter(d =>
                d.profileAccess?.includes(userProfile) ||
                d.contactAccess?.some(c => user.id == c || user.info?.company.includes(c))
            ) :
        [];

    const country = projectStore.selectedCountry;

    const withParkingColumns = country == 'IT' && users.some(u => u.profiles[0].id.includes('exhibitor'));

    return (<>
        <table className='FairParticipationTable table mt-4'>
            <thead>
            <tr>
                <th>{t.fairParticipation.code}<SortButton selected={sortBy} onChange={setSortBy} name='code'/></th>
                <th>{t.global.profile}<SortButton selected={sortBy} onChange={setSortBy} name='profile'/></th>
                <th>{t.eventPage.name}<SortButton selected={sortBy} onChange={setSortBy} name='name'/></th>
                <th>{t.global.email}<SortButton selected={sortBy} onChange={setSortBy} name='email'/></th>
                <th>{t.global.function}<SortButton selected={sortBy} onChange={setSortBy} name='function'/></th>
                {fairDates.map(d =>
                    <React.Fragment key={d.date as any}>
                        <th>
                            {moment(d.date).format('DD/MM/YYYY')}
                            <SortButton selected={sortBy} onChange={setSortBy} name={'date_' + +new Date(d.date)}/>
                        </th>
                        {withParkingColumns &&
                        <th>
                            {t.fairParticipation.carParking}<br/>{moment(d.date).format('DD/MM/YYYY')}
                            <SortButton selected={sortBy} onChange={setSortBy} name={'date_' + +new Date(d.date)}/>
                        </th>
                        }
                    </React.Fragment>
                )}
                {sideEvents.map(d =>
                    <th key={d.date + d.reference}>
                        {pickLanguage(d.name)}
                        <SortButton selected={sortBy} onChange={setSortBy} name={'sideEvent_' + d.reference}/>
                    </th>
                )}
                {!projectStore.isFRNonFood && <th/>}
            </tr>
            </thead>
            <tbody>
            {users.map(u =>
                <UserRow
                    key={u.id}
                    u={u}
                    event={event}
                    fairDates={fairDates}
                    sideEvents={sideEvents}
                    canAssign={canAssign}
                    withParkingColumns={withParkingColumns}
                />
            )}
            </tbody>
        </table>
        {contactsStore.loading && <div className='position-relative py-5'><Loading/></div>}
    </>)
});

interface UserRowProps
{
    u: IContact
    event
    fairDates
    sideEvents
    canAssign: boolean
    withParkingColumns: boolean
}

const UserRow = observer(function UserRow({u, event, fairDates, sideEvents, canAssign, withParkingColumns}: UserRowProps)
{
    const projectId = projectStore.id;
    const profile = u.profiles[0].id;
    const badge = u.badges?.find(p => p.project == projectId);
    return <tr>
        <td>{badge?.registrationId}</td>
        <td>{u.profiles?.length && u.profiles[0] ? pickLanguage(findProfile(u.profiles[0].id)?.name) || u.profiles[0].id : 'Exhibitor'}</td>
        <td><Link to={'/contact/' + u.id}>{u.firstName + ' ' + u.lastName}</Link></td>
        <td>{u.email}</td>
        <td>
            <TextEditable
                className='underlineElement'
                content={u.function || ''}
                onChangeEnter={f => contactsStore.updateUserFunction(u.id, f)}
            />
        </td>
        {fairDates.map(d =>
        {
            const checkedDate = !!badge?.days.some(uDate => sameUTCDay(d.date, uDate));
            const checkedParking = !!badge?.parking?.some(uDate => sameUTCDay(d.date, uDate));
            const dateStatuses = checkedDate ? null : event.dateStatuses?.find(e => sameUTCDay(d.date, e.date));
            return (
                <React.Fragment key={d.date as any}>
                    <td className='check-participation p-1'>
                        {checkedDate || !dateStatuses || (!dateStatuses.full && !dateStatuses.fullByProfile[profile]) ?
                            <Checkbox
                                className={'selectable ' + (checkedDate ? 'selected' : true)}
                                checked={checkedDate}
                                onChange={canAssign ? e =>
                                {
                                    if (e.target.checked)
                                    {
                                        contactsStore.assignBadgeForDay(u.id, projectId, d.date);
                                    }
                                    else
                                    {
                                        contactsStore.unAssignBadgeForDay(u.id, projectId, d.date);
                                    }
                                } : null}
                                label={moment(d.date).format('DD/MM/YYYY')}
                            />
                            :
                            <div className='complete'>Complete</div>
                        }
                    </td>
                    {withParkingColumns && (
                        profile.includes('exhibitor') ?
                            <td className='check-participation p-1'>
                                <Checkbox
                                    className={'selectable ' + (checkedParking ? 'selected' : true)}
                                    checked={checkedParking}
                                    onChange={canAssign ? e =>
                                    {
                                        if (e.target.checked)
                                        {
                                            contactsStore.assignParkingForDay(u.id, projectId, d.date);
                                        }
                                        else
                                        {
                                            contactsStore.unAssignParkingForDay(u.id, projectId, d.date);
                                        }
                                    } : null}
                                    label={t.global.parking}
                                />
                            </td>
                            :
                            <td/>
                    )}
                </React.Fragment>
            );
        })}
        {sideEvents.map(d =>
        {
            const checked = !!badge?.sideEvents?.some(s => d.reference == s.reference);
            return (
                <td key={d.date + d.reference} className='check-participation'>
                    {checked || !event.sideEventStatuses?.find(s => d.reference == s.reference).full ?
                        <Checkbox
                            checked={checked}
                            onChange={canAssign ? e =>
                            {
                                if (e.target.checked)
                                {
                                    contactsStore.assignSideEvent(u.id, projectId, d.reference);
                                }
                                else
                                {
                                    contactsStore.unAssignSideEvent(u.id, projectId, d.reference);
                                }
                            } : null}
                            label={t.fairParticipation.participate}
                        />
                        :
                        <div className='complete'>Complete</div>
                    }
                </td>
            )
        })}
        {!projectStore.isFRNonFood &&
            <td>
                {!!badge?.registrationId &&
                    <ActionButton className='button-sm-empty' action={p => sendBadgePdf(p, u.id)} actionText={t.fairParticipation.sendingBadgePdf}>
                        {t.fairParticipation.sendBadgePdf}
                    </ActionButton>
                }
            </td>
        }
    </tr>
});

const AddMembers = observer(function AddMembers()
{
    if (!user.moderator &&
        user.info?.profiles[0].id.includes('exhibitor') &&
        !['exhibitorMainAccount', 'exhibitorKeyAccount'].includes(user.info?.profiles[0].id)
    )
    {
        return null;
    }

    const event = eventStore.projectEvent;
    return (
        <Link className='my-5' to={event?.url ? '/' + encodeURIComponent(encodeURIComponent(event.url)) : '/event/' + projectStore.id}>
            <button className='button my-5'>{t.global.addCompanyMembers}</button>
        </Link>
    );
});

const SideEvents = observer(function SideEvents()
{
    const userProfile = user.info?.profiles?.[0]?.id;
    const sideEvents = eventStore.projectEvent?.sideEvents.filter(d =>
        (user.moderator || user.isHostess) ||
        d.profileAccess?.includes(userProfile) ||
        d.contactAccess?.some(c => user.id == c || user.info?.company.includes(c))
    ) || [];
    if (!sideEvents?.length)
    {
        return null;
    }
    return (
        <div>
            <h3>{t.eventPage.sideEvents}</h3>
            <div className='d-flex flex-wrap'>
                {sideEvents.map(d =>
                    <div key={d.date + d.reference} className='SideEvent'>
                        <p><b>{pickLanguage(d.name)}</b><br/><span>{moment(d.date).format('HH:mm, DD/MM/YYYY')}</span></p>
                        <p>{pickLanguage(d.description)}</p>
                    </div>
                )}
            </div>
        </div>
    );
});

const DownloadTable = observer(function DownloadTable({companyId}: SelectedCompanyProps)
{
    return (
        <div className='text-right'>
            {t.fairParticipation.downloadXLS}
            <DownloadButton
                buttonEmpty
                className='ml-3 mb-3'
                getter={project => fairParticipationParticipantsTable(project, companyId)}
                addDate
                filename={(companyNameStore.find(companyId) || 'Company members') + '_Fair_Participation'}
                downloadText={t.global.download}
                type='xlsx'
            />
        </div>
    );
});

function fairParticipationParticipantsTable(project: string, companyId: string)
{
    return call<{fairParticipationParticipantsTable: string}>(gql`query($input:FairParticipationParticipantsTableInput!){fairParticipationParticipantsTable(input:$input)}`, {input: {project, companyId}})
    .then(({data}) => data?.fairParticipationParticipantsTable);
}

function sendBadgePdf(project: string, userId: string)
{
    return call<{sendBadgePdf: boolean}>(gql`mutation($input:SendBadgePdfInput!){sendBadgePdf(input:$input)}`, {input: {project, userId}})
    .then(({data}) => data?.sendBadgePdf);
}
