import React, {FunctionComponent, ReactNode, useState} from 'react';
import type {GraphQLFormattedError} from 'graphql';
import './Login.scss';
import EnterPhone from './EnterPhone';
import EnterEmail from './EnterEmail';
import EnterRegID from './EnterRegID';
import EnterCode from './EnterCode';
import PickAccount from './PickAccount';
import RegIDNotFound from './RegIDNotFound';
import NotValidated from './NotValidated';
import {IVerificationMethod} from '../../graphql/api/user/User';

export interface ILoginInput
{
    value: string
    method: IVerificationMethod | 'RegID'
}

interface LoginProps
{
    isEvent?: boolean
    defaultPhone?: string
    defaultEmail?: string
    defaultRegID?: string
    onLogin(input: ILoginInput): Promise<{success: boolean, verified?: boolean, errors?: GraphQLFormattedError[]}>
    onSubmitCode(code: string): Promise<{success: boolean, errors?: GraphQLFormattedError[]}>
    accounts?: {id: string, name: ReactNode}[]
    onSelectAccount?(is: string): Promise<{success: boolean, errors?: GraphQLFormattedError[]}>
    onSelectNewAccount?(): void
}

export interface LoginChildProps extends LoginProps
{
    state: keyof typeof componentMap
    setState(state: keyof typeof componentMap): void
    input?: ILoginInput
}

const componentMap = {
    enterPhone: EnterPhone,
    enterEmail: EnterEmail,
    enterRegID: EnterRegID,
    regIDNotFound: RegIDNotFound,
    enterCode: EnterCode,
    pickAccount: PickAccount,
    notValidated: NotValidated,
};

export default function LoginBox(props: LoginProps)
{
    const {onLogin, onSubmitCode, onSelectAccount, isEvent} = props;
    const [state, setState] = useState<keyof typeof componentMap>('enterPhone');
    const [lastInput, setInput] = useState<ILoginInput>();
    const Component: FunctionComponent<LoginChildProps> = componentMap[state];
    if (!Component)
    {
        return null;
    }
    return (
        <Component
            {...props}
            state={state}
            setState={setState}
            onLogin={input =>
                onLogin(input).then(res =>
                {
                    const {success, verified} = res;
                    if (success)
                    {
                        if (verified)
                        {
                            if (!isEvent)
                            {
                                setState('pickAccount');
                            }
                        }
                        else if (input.method != 'RegID')
                        {
                            setInput(input);
                            setState('enterCode');
                        }
                    }
                    else if (input.method == 'RegID')
                    {
                        setState('regIDNotFound');
                    }
                    return res;
                })
            }
            input={lastInput}
            onSubmitCode={code =>
                onSubmitCode(code).then(res =>
                {
                    const {success} = res;
                    if (success)
                    {
                        setState('pickAccount');
                    }
                    return res;
                })
            }
            onSelectAccount={code =>
                onSelectAccount(code).then(res =>
                {
                    const {success, errors} = res;
                    if (!success && errors.some(e => e.message == 'not-validated'))
                    {
                        setState('notValidated');
                    }
                    return res;
                })
            }
        />
    )
}
