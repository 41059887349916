import React from 'react';
import {IImportedPageItem, IPageItem, IPageItemProps, PageItemType} from '../../graphql/api/page/Page';
import ContentBlock from './ContentBlock';
import FileBlock from './FileBlock';
import VideoBlock from './VideoBlock';
import SlideshowBlock from './SlideshowBlock';
import ArticleBlock from './ArticleBlock';
import ProductBlock from './ProductBlock';
import {ITranslations} from '../../graphql/api/Types';

const blockMap: {[type in PageItemType]: React.ComponentType<any>} = {
    content: ContentBlock,
    product: ProductBlock,
    video: VideoBlock,
    slideshow: SlideshowBlock,
    article: ArticleBlock,
    file: FileBlock,
};

export interface CommonBlockProps extends IPageItemProps, BlockAccessProps
{
    pageId: string
    id: string
    imported?: boolean
    layout: IPageItem['layout']
    placeholderText?: ITranslations
    placeholderImage?: ITranslations
    isMobileView?: boolean
}

export interface BlockAccessProps
{
    editMode: boolean
    fullAccess: boolean
    editAccess: boolean
    enableBlockOptions: boolean
}

export function mapItemToBlock(pageId: string, i: (IPageItem | (IPageItem & IImportedPageItem)) & {imported?: boolean}, access: BlockAccessProps)
{
    const Component = blockMap[i.type];
    return Component ?
        <Component {...i.props} pageId={pageId} id={i.id} imported={i.imported} layout={i.layout} {...access}/> :
        <div className='h-100 border d-flex justify-content-center align-items-center'>{i.type}</div>;
}
