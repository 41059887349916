import {action, makeAutoObservable} from 'mobx';
import {loadProductsForProject} from '../Products/productProxy';
import type {IProduct as IProductMain} from '../Products/ProductStore';
import {projectStore} from '../stores/ProjectStore';
import {Country} from '../../graphql/api/Types';

export interface IProduct extends IProductMain
{
    available?: boolean
}

class OrderStandProductStore
{
    productsByProject: {[project: string]: IProduct[]} = {};

    get projectProducts()
    {
        return this.productsByProject[projectStore.id] || [];
    }

    constructor()
    {
        makeAutoObservable(this);
    }

    load(country: Country, project: string)
    {
        loadProductsForProject({country, availableForProject: project}).then(action(res =>
        {
            if (res)
            {
                this.productsByProject[project] = res;
            }
        }));
    }
}

export const orderStandProductStore = new OrderStandProductStore();
