// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimelineEmail .top .content {
  display: flex;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.TimelineEmail .top .aside {
  text-align: right;
}
.TimelineEmail .name {
  flex-basis: max-content;
  white-space: nowrap;
}
.TimelineEmail .text {
  position: relative;
  padding-left: 1rem;
  padding-right: 3rem;
  flex-grow: 1;
  height: 1.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.TimelineEmail .text .fa {
  position: absolute;
  right: 0;
  font-size: 1.5rem;
  color: #007bff;
}
.TimelineEmail .emailBody {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 1rem 1.5rem;
}`, "",{"version":3,"sources":["webpack://./frontend/Timeline/TimelineEmail.scss"],"names":[],"mappings":"AAGI;EACE,aAAA;EACA,qBAAA;EACA,eAAA;AAFN;AAII;EACE,iBAAA;AAFN;AAKE;EACE,uBAAA;EACA,mBAAA;AAHJ;AAKE;EACE,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAHJ;AAII;EACE,kBAAA;EACA,QAAA;EACA,iBAAA;EACA,cAAA;AAFN;AAKE;EACE,sBAAA;EACA,mBAAA;EACA,oBAAA;AAHJ","sourcesContent":["\n.TimelineEmail {\n  .top {\n    .content {\n      display: flex;\n      margin-bottom: .5rem;\n      cursor: pointer;\n    }\n    .aside {\n      text-align: right;\n    }\n  }\n  .name {\n    flex-basis: max-content;\n    white-space: nowrap;\n  }\n  .text {\n    position: relative;\n    padding-left: 1rem;\n    padding-right: 3rem;\n    flex-grow: 1;\n    height: 1.5em;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    .fa {\n      position: absolute;\n      right: 0;\n      font-size: 1.5rem;\n      color: #007bff;\n    }\n  }\n  .emailBody {\n    border: 1px solid #000;\n    border-radius: 20px;\n    padding: 1rem 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
