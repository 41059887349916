import React, {useRef} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import FA from 'react-fontawesome';
import Swal from 'sweetalert2';
import { user } from '../stores/user';
import TabLink from '../common/TabLink';
import './ChatPage.scss';
import { chatStore } from './ChatStore';
import { formatTimelineDate } from '../common/timeFormatting';
import {getInitials, getName, WithName} from '../common/getName';
import {IChat} from './chatProxy';
import {colorHash} from '../common/colorHash';
import {t} from '../translations';

export default observer(function ChatPage()
{
    return (
        <div className='ChatPage'>
            <div className='d-flex pt-3 pb-3'>
                <div className='dashboard-views'>
                    <Link to={'/dashboard'}><FA name='stream' className='fad'/></Link>
                    {user.moderator && <Link to={'/inbox'}><FA name='envelope'/></Link>}
                    <FA name='comments-alt' className='fad active'/>
                </div>
                <div className='d-flex flex-fill justify-content-center'>
                    <div className='border-bottom'>
                        <TabLink to='/chat'>{t.chatBoard.active}</TabLink>
                        {user.support &&
                            <TabLink to='/chat?assigned-to-me'>Assigned to me</TabLink>
                        }
                        <TabLink to='/chat?archived'>{t.global.archived}</TabLink>
                        <TabLink to='/chat?deleted'>{t.chatBoard.deleted}</TabLink>
                    </div>
                </div>
            </div>
            <ChatList/>
        </div>
    )
});

const ChatList = observer(function ChatList()
{
    const tab = useLocation().search.substr(1);

    const chatActivity = chatStore.chatActivity;
    let chats = chatStore.chats
        .filter(c => !c.support || c.messages.length || chatStore.chatsPreviewMessage.find(p => p.id === c.id)?.previewMessage)
        .sort((a, b) => +b.updated - +a.updated);
    switch (tab)
    {
        case 'assigned-to-me': chats = chats.filter(c => c.supportAssignedTo === user.id); break;
        case 'archived': chats = chats.filter(c => chatActivity?.archived?.includes(c.id)); break;
        case 'deleted': chats = chats.filter(c => chatActivity?.deleted?.includes(c.id)); break;
        default: chats = chats.filter(c => !chatActivity?.deleted?.includes(c.id) && !chatActivity?.archived?.includes(c.id)); break;
    }

    return (
    <div className='chat-dashboard py-3'>
        <ChatListTop chats={chats}/>
        <div className='mt-2'>
            {chats.map(c =>
                <ChatPageItem
                    key={c.id}
                    chat={c}
                />
            )}
        </div>
    </div>)
})

const ChatListTop = observer(function ChatListTop({chats}: {chats: IChat[]})
{
    const tab = useLocation().search.substr(1);

    const checkAll = () => chatStore.selectedChatIds = chatStore.selectedChatIds.length ? [] : chats.map(c => c.id);

    const takeLead = () =>
    {
        Swal.fire({
            title: t.global.chatTakeLead,
            text: t.global.chatTakeLeadWarning,
            showCancelButton: true,
            confirmButtonText: t.global.yes,
            cancelButtonText: t.global.cancel,
        }).then((result) =>
        {
            if (result.value)
            {
                chatStore.takeLead().then(res =>
                {
                    if (res)
                    {
                        Swal.fire(t.global.done, t.global.youAreNowInCharge, 'success');
                    }
                });
            }
        });
    };

    return (
        <div className='d-flex align-items-center controls px-3'>
            <input type='checkbox' onChange={checkAll} checked={!!chatStore.selectedChatIds.length}/>
            {tab !== 'archived' && <FA name='arrow-alt-square-down' className='fad' title={t.chatBoard.archive} onClick={() => chatStore.archiveOrDeleteChat('archive')}/>}
            {tab !== 'deleted' && <FA name='trash' title={t.global.delete} onClick={() => chatStore.archiveOrDeleteChat('delete')}/>}
            {!!tab && <FA name='redo' title={t.chatBoard.restore} className='fad' onClick={() => chatStore.archiveOrDeleteChat(tab === 'archived' ? 'archive' : 'delete', true)}/>}
            {chatStore.selectedChatIds.some(id =>
            {
                const chat = chatStore.chats.find(c => c.id == id);
                return chat && (chat.contactButton ? chat.contactButton.person != user.id : chat.companies?.some(c => user.info?.company?.includes(c)));
            }) &&
            <FA name='user-check' title={t.chatBoard.takeTheLead} className='fad' onClick={takeLead}/>}
        </div>
    )
})

interface IChatProps
{
    chat: IChat
}

const ChatPageItem = observer(function ChatPageItem({chat}: IChatProps)
{
    const checkbox = useRef<HTMLInputElement>();
    const {title, id, usersInChat, support, updated} = chat;

    const handleCheck = action(() =>
    {
        const index = chatStore.selectedChatIds.findIndex(cid => cid === chat.id);
        if (index > -1)
        {
            chatStore.selectedChatIds.splice(index, 1);
        }
        else
        {
            chatStore.selectedChatIds.push(id);
        }
    });

    const openChat = (e) =>
    {
        if (checkbox && !checkbox.current.contains(e.target))
        {
            chatStore.openChat(id);
        }
    }

    const others = usersInChat?.filter(p => p.id != user.id) || [];

    return (
        <div className='ChatPageItem d-flex align-items-center px-3' onClick={openChat}>
            <div className='check'>
                <input type='checkbox' onChange={handleCheck} checked={!!chatStore.selectedChatIds.find(c => c === chat.id)} ref={checkbox}/>
            </div>
            <div className='img' style={{backgroundColor: colorHash.hex(others.map(p => p.id).join(''))}}>
                {chat.smsNumber ? <FA name='mobile-alt' className='fad'/> :
                    !others.length ? <ChatIcon participant={(usersInChat.length ? usersInChat[0] : null) || user.info}/> :
                        others.length == 1 ? <ChatIcon participant={others[0]}/> :
                            <FA name='users' className='fad chat-icon'/>}
            </div>
            <div className='chat-details d-flex flex-column ml-3'>
                <div className='upper-row d-flex align-items-center'>
                    {!!title && <h6>{title}</h6>}
                    <span className={'chat-message ' + (title ? 'ml-3 title-displayed' : '')}>
                        {
                            (chat.messages.length ? chat.messages[chat.messages.length - 1].msg : null) ||
                            chatStore.chatsPreviewMessage.find(p => p.id === chat.id)?.previewMessage ||
                            t.chatBoard.noMessage
                        }
                    </span>
                    {!!chatStore.chatActivity?.deleted?.find(c => c === chat.id) && <span className='badge badge-danger ml-2'>{t.chatBoard.deleted}</span>}
                    {!!chatStore.chatActivity?.archived?.find(c => c === chat.id) && <span className='badge badge-primary ml-3'>{t.global.archived}</span>}
                    {support && <span className='badge badge-dark ml-3'>{t.chatBoard.support}</span>}
                </div>
                <div className='down-row'>
                    <div className='participants'>
                        {usersInChat.filter(u => u.id !== user.id).map(p => p.phone || getName(p) || 'Unknown').join(', ')}
                        <span className='ml-3'>{formatTimelineDate(updated)}</span>
                    </div>
                </div>
            </div>
        </div>
    )
})

function ChatIcon({participant}: {participant: WithName & {image?: string}})
{
    if (!participant)
    {
        return <FA name='user' className='fad chat-icon'/>;
    }
    const initials = getInitials(participant);
    return participant.image ? <img src={participant.image} alt={initials}/> : <FA name='user' className='fad chat-icon'/>;
}
