import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import Swal from 'sweetalert2';
import './ContactMessage.scss';
import {sendContactMessage} from './landingProxy';
import {t} from '../translations';
import {countryStore} from '../stores/CountryStore';
import {Country} from '../../graphql/api/Types';
import {landingStore} from './LandingStore';
import {emailValidation} from '../../lib/common';

export const ContactMessage = observer(function ContactMessage()
{
    const [open, setOpen] = useState(false);
    const togglePopup = () => setOpen(v => !v);
    return (
        <div className='ContactMessage'>
            {open && <ContactMessagePopup onClose={togglePopup}/>}
            <button className='open' onClick={togglePopup}>
                <FA name='envelope' className='fad'/>
                {t.help.title}
            </button>
        </div>
    );
});

interface ContactMessagePopupProps
{
    onClose(): void
}

const ContactMessagePopup = observer(function ContactMessagePopup({onClose}: ContactMessagePopupProps)
{
    useEffect(() =>
    {
        if (!landingStore.myCountryCode)
        {
            landingStore.getMyCountryCode();
        }
    }, []);

    const [state, setState] = useState<'sending' | 'sent'>(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [selectedCountry, setSelectedCountry] = useState<Country>(null);
    const country = selectedCountry || (countryStore.countries.some(c => c.code == landingStore.myCountryCode) ? landingStore.myCountryCode : null);

    const handleSend = () =>
    {
        if (!name)
        {
            Swal.fire({
                title: t.help.errors.nameRequired,
                icon: 'warning',
            });
            return;
        }
        if (!email)
        {
            Swal.fire({
                title: t.help.errors.emailRequired,
                icon: 'warning',
            });
            return;
        }
        if (!emailValidation(email))
        {
            Swal.fire({
                title: t.registration.form.errors.emailFormat,
                icon: 'warning',
            });
            return;
        }
        if (!country)
        {
            Swal.fire({
                title: t.registration.form.errors.countryRequired,
                icon: 'warning',
            });
            return;
        }
        if (!message)
        {
            Swal.fire({
                title: t.help.errors.messageRequired,
                icon: 'warning',
            });
            return;
        }
        setState('sending');
        sendContactMessage({name, email, country, message}).then(res =>
        {
            if (res.data?.sendContactMessage)
            {
                setState('sent');
            }
            else
            {
                setState(null);
                Swal.fire({
                    title: t.authentication.failedToSend,
                    icon: 'error',
                });
            }
        });
    };

    return (
        <div className='ContactMessagePopup'>
            <div className='top'>
                {state != 'sent' ? t.help.title : t.help.sentTitle}
                <span className='close cursor-pointer' onClick={onClose}>X</span>
            </div>
            <div className='body'>
                {state != 'sent' ?
                    <>
                        <div className='mb-3'>
                            <label htmlFor='name'>{t.help.fullName}</label>
                            <input
                                className='form-control'
                                disabled={state == 'sending'}
                                autoFocus
                                type='text'
                                id='name'
                                value={name}
                                onChange={e => setName(e.currentTarget.value)}
                            />
                        </div>
                        <div className='mb-3'>
                            <label htmlFor='email'>{t.global.email}</label>
                            <input
                                className='form-control'
                                disabled={state == 'sending'}
                                type='text'
                                id='email'
                                value={email}
                                onChange={e => setEmail(e.currentTarget.value)}
                            />
                        </div>
                        <div className='mb-3'>
                            <label htmlFor='country'>{t.global.country}</label>
                            <select
                                className='form-control'
                                disabled={state == 'sending'}
                                id='country'
                                value={country || ''}
                                onChange={e => setSelectedCountry(e.currentTarget.value as Country)}
                            >
                                <option value='' disabled>{t.global.select}</option>
                                {countryStore.countryTupleOptions.map(([countryCode, countryName]) =>
                                    <option key={countryCode} value={countryCode}>{countryName}</option>
                                )}
                            </select>
                        </div>
                        <div className='mb-3'>
                            <label htmlFor='message'>{t.help.message}</label>
                            <textarea
                                className='form-control'
                                disabled={state == 'sending'}
                                id='message'
                                value={message}
                                onChange={e => setMessage(e.currentTarget.value)}
                            />
                        </div>
                        <div className='send'>
                            <button className='button-lg' onClick={state != 'sending' ? handleSend : null}>
                                {state == 'sending' ? t.authentication.sending : t.help.send}
                            </button>
                        </div>
                    </>
                    :
                    <>
                        <p className='sentInfo'>{t.help.sentMsg(email)}</p>
                        <div className='send'><button className='button-lg' onClick={onClose}>OK</button></div>
                    </>
                }
            </div>
        </div>
    );
});
