import {CSSProperties, FocusEvent, KeyboardEvent, MouseEvent, DragEvent, useState, useRef, useMemo, ChangeEvent, useCallback} from 'react';
import './DropdownSearch.scss';
import {arrayMove} from './common';

export interface DropdownSearchProps
{
    className?: string
    style?: CSSProperties
    options: DropdownSearchOption[]
    selected?: string | string[] // use array for multi select
    arrow?: boolean
    placeholder?: string
    // placeholder to use only when the list is empty
    emptyPlaceholder?: string
    inputId?: string
    disabled?: boolean
    defaultValue?: string
    allowCustom?: boolean
    onChange?: ((selected: string) => void) | ((selected: string[]) => void)
    onSelect?(selected: string): void // empty string is used when nothing is selected
    onCustom?(selected: string): void
    // props for multi select
    allOption?: boolean
    allowDuplicates?: boolean
    onRemove?(selected: string, index: number): void
    onRearrange?(selected: string[]): void
    onFocus?(): void
}

export interface DropdownSearchOption
{
    value: string
    name: string
    keywords?: string[] // For more detailed search
}

export default function DropdownSearch(
    {
        className,
        style,
        options,
        selected,
        arrow,
        placeholder,
        emptyPlaceholder,
        inputId,
        disabled,
        defaultValue,
        allowCustom,
        onChange,
        onSelect,
        onCustom,
        allOption,
        allowDuplicates,
        onRemove,
        onRearrange,
        onFocus,
    }: DropdownSearchProps
)
{
    const [search, setSearch] = useState<string>();
    const [focused, setFocused] = useState<boolean>();
    const [draggedIndex, setDraggedIndex] = useState<number>();
    const [hoveredIndex, setHoveredIndex] = useState<number>();

    const input = useRef<HTMLInputElement>();

    const triggerSelect = useCallback((value: string) =>
    {
        const s = selected || '';
        if (value || !Array.isArray(s))
        {
            if (allowDuplicates || (Array.isArray(s) ? !s.includes(value) : s !== value))
            {
                onSelect?.(value);
                onChange?.(Array.isArray(s) ? [...selected, value] : value as any);
            }
        }
    }, [allowDuplicates, onChange, onSelect, selected]);

    const triggerCustom = (value: string) =>
    {
        if (allowCustom)
        {
            const s = selected || '';
            if (value || !Array.isArray(s))
            {
                if (allowDuplicates || (Array.isArray(s) ? !s.includes(value) : s !== value))
                {
                    onCustom?.(value);
                    onChange?.(Array.isArray(s) ? [...s, value] : value as any);
                }
            }
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    {
        setSearch(e.currentTarget.value);
    };

    const handleFocus = () =>
    {
        if (!disabled)
        {
            setFocused(true);
            input.current?.focus();
            onFocus?.();
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) =>
    {
        if (e.key == 'Enter')
        {
            e.currentTarget.blur();
            e.preventDefault();
        }
    };

    const handleBlur = (e: FocusEvent) =>
    {
        if (!e.currentTarget.contains(e.relatedTarget as any))
        {
            if (search)
            {
                if (filteredOptions.length === 1)
                {
                    triggerSelect(filteredOptions[0].value);
                }
                else
                {
                    triggerCustom(search);
                }
            }
            else if (search != null)
            {
                triggerSelect('');
            }
            setSearch(null);
            setFocused(false);
        }
    };

    const filteredOptions = useMemo(() =>
    {
        let res = options;
        if (!allowDuplicates && Array.isArray(selected))
        {
            res = res.filter(o => !selected.includes(o.value));
        }
        if (search)
        {
            const searchRegExp = new RegExp(search.replace(/[\\\[\]()+?.*]/g, c => '\\' + c), 'i');
            res = res.filter(o => o.name?.match(searchRegExp) || o.value?.match(searchRegExp) || o.keywords?.some(k => k?.match(searchRegExp)));
        }
        return res;
    }, [allowDuplicates, options, selected, search]);

    const show = !!(focused && filteredOptions.length);
    const renderOptionsRef = useRef(false);
    const renderOptions = useMemo(() => renderOptionsRef.current ||= show, [show]);

    const renderedOptions = useMemo(() =>
    {
        if (!renderOptions)
        {
            return null;
        }

        const handleSelect = (e: MouseEvent<HTMLLIElement>) =>
        {
            if (Array.isArray(selected))
            {
                input.current?.focus();
            }
            else
            {
                setSearch(null);
                setFocused(false);
            }
            const value = e.currentTarget.dataset.value;
            triggerSelect(value);
        };

        return (
            <>
                {filteredOptions.map(o =>
                    <li
                        key={o.value}
                        data-value={o.value}
                        onClick={handleSelect}
                        className={o.value === selected ? 'selected' : null}
                    >
                        {o.name}
                    </li>
                )}
            </>
        );
    }, [filteredOptions, renderOptions, selected, triggerSelect]);

    const renderedSelectAllOption = useMemo(() =>
    {
        if (!allOption)
        {
            return null;
        }

        const handleSelectAll = () =>
        {
            setSearch(null);
            setFocused(false);
            onChange?.(options.map(o => o.value) as any);
        };

        return (
            <li onClick={handleSelectAll}>All</li>
        );
    }, [allOption, onChange, options]);

    const selectedOptions = useMemo(() =>
    {
        return Array.isArray(selected) ?
            selected.map(s => options.find(o => o.value === s) || {value: s, name: s}) :
            options.find(o => o.value === selected) || {value: selected, name: selected};
    }, [options, selected]);

    const renderedSelected = useMemo(() =>
    {
        if (!Array.isArray(selectedOptions))
        {
            return null;
        }

        const handleUnselect = (e: MouseEvent<HTMLSpanElement>) =>
        {
            const index = +e.currentTarget.dataset.index;
            onRemove?.(selectedOptions[index].value, index);
            if (onChange)
            {
                const copy = (selected as string[]).slice();
                copy.splice(index, 1);
                onChange(copy as any);
            }
            input.current?.focus();
        };

        const handleSelectedDragStart = (e: DragEvent<HTMLDivElement>) =>
        {
            const index = +e.currentTarget.dataset.index;
            e.dataTransfer.setData('text/x-index', index + '');
            setDraggedIndex(index);
        };

        const handleSelectedDragEnd = () =>
        {
            setDraggedIndex(null);
            setHoveredIndex(null);
        };

        const handleSelectedDragEnter = (e: DragEvent<HTMLDivElement>) =>
        {
            const index = +e.currentTarget.dataset.index;
            const eventIndex = +e.dataTransfer.getData('text/x-index');
            if (index != eventIndex)
            {
                setHoveredIndex(index);
            }
        };

        const handleSelectedDragExit = () =>
        {
            setHoveredIndex(null);
        };

        const handleSelectedDrop = (e: DragEvent<HTMLDivElement>) =>
        {
            const index = +e.currentTarget.dataset.index;
            const eventIndex = +e.dataTransfer.getData('text/x-index');
            const copy = arrayMove(selected as string[], eventIndex, index);
            onRearrange?.(copy);
            onChange?.(copy as any);
        };

        return selectedOptions.map((s, i) =>
            <div
                key={i}
                className={'selected' + (draggedIndex == i ? ' dragged' : '') + (hoveredIndex == i ? ' hovered' : '')}
                data-index={i}
                draggable={!disabled}
                onDragStart={disabled ? handleSelectedDragStart : null}
                onDragEnd={disabled ? handleSelectedDragEnd : null}
                onDragEnter={disabled ? handleSelectedDragEnter : null}
                onDragExit={disabled ? handleSelectedDragExit : null}
                onDrop={disabled ? handleSelectedDrop : null}
            >
                {s.name}
                {!disabled &&
                    <span
                        className='remove'
                        data-index={i}
                        onClick={handleUnselect}
                    />
                }
            </div>
        );
    }, [disabled, draggedIndex, hoveredIndex, onChange, onRearrange, onRemove, selected, selectedOptions]);

    return (
        <div
            className={'DropdownSearch ' + (show ? 'show ' : '') + (className || '')}
            style={style}
            tabIndex={0}
            onBlur={handleBlur}
        >
            <div className='d-flex' onClick={handleFocus}>
                <div className={'input' + (arrow ? ' border-with-arrow' : '')}>
                    {renderedSelected}
                    {(allowCustom || !Array.isArray(selectedOptions) || !selectedOptions.length || !!filteredOptions.length) &&
                        <input
                            ref={input}
                            id={inputId}
                            type='text'
                            value={(search ?? (!Array.isArray(selectedOptions) && selectedOptions?.name)) || defaultValue || ''}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onKeyDown={handleKeyDown}
                            placeholder={Array.isArray(selectedOptions) && selectedOptions.length == 0 ? emptyPlaceholder || placeholder : placeholder}
                            autoComplete={'off'}
                            disabled={disabled}
                        />
                    }
                </div>
                {arrow && <div className={'dropdown-arrow ' + (show ? 'arrow-focused' : '')} onClick={handleFocus}>▼</div>}
            </div>
            <div className='items'>
                <ul>
                    {renderOptions && renderedSelectAllOption}
                    {renderOptions && renderedOptions}
                </ul>
            </div>
        </div>
    );
}
