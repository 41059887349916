import {action, observable, computed, toJS, makeObservable} from 'mobx';
import {IHelpQuestion} from './HelpQuestion';
import {
    callCreateHelpQuestion,
    callDeleteHelpQuestion,
    callUpdateHelpQuestion,
    loadHelpQuestions,
    getHelpCategories,
    createHelpCategory,
    updateHelpCategory,
    deleteHelpCategory,
    copyCategory,
} from './helpProxy';
import {deepSet} from '../common/deepSet';
import {withoutUpdated} from '../Post/postUtility';
import { ITranslations } from '../../graphql/api/Types';
import { projectStore } from '../stores/ProjectStore';
import { swalModal } from '../common/sweetAlert';
import Swal from 'sweetalert2';

export type IHelpQuestionInput = Omit<IHelpQuestion, 'updated'> & {section: string}

export interface IHelpCategory {
    id?: string;
    name?: ITranslations;
    customURL?: string;
    image?: string;
    access?: string[];
    attachments?: ITranslations
}
class HelpStore
{
    @observable bySection: {[section: string]: {[project: string]: IHelpQuestion[]}} = {};
    @observable helpCategories: {[project: string]: IHelpCategory[]} = {};
    @observable selectedId: string = null;
    @observable selected: IHelpCategory = {};
    @observable changes: Partial<IHelpCategory> = {};

    constructor()
    {
        makeObservable(this);
    }

    reloadHelpSection(section: string, project: string)
    {
        loadHelpQuestions(section, project).then(action(res =>
        {
            if (res)
            {
                if (!this.bySection[section])
                {
                    this.bySection[section] = {};
                }
                this.bySection[section][project] = res;
            }
        }));
    }

    create(post: IHelpQuestionInput)
    {
        callCreateHelpQuestion(post).then(action(res =>
        {
            if (res)
            {
                if (!this.bySection[post.section][post.project])
                {
                    this.bySection[post.section][post.project] = [res];
                }
                else
                {
                    this.bySection[post.section][post.project].push(res);
                }
            }
        }));
    }

    @action
    handleChange(section: string, id: string, key: string | string[], value: any)
    {
        const post = this.bySection[section][projectStore.id].find(i => i.id === id);
        deepSet(post, key, value);
        this.update(section, post);
    }

    update(section: string, update: Partial<IHelpQuestion>)
    {
        update = withoutUpdated(update);
        delete update.attachments;
        callUpdateHelpQuestion(update)
            .then(action(res =>
            {
                if (res)
                {
                    const post = this.bySection[section][projectStore.id].find(i => i.id == res.id);
                    if (post)
                    {
                        Object.assign(post, res);
                        delete post.addAttachments;
                        delete post.removeAttachments;
                    }
                }
            }));
    }

    delete(section: string, id: string)
    {
        callDeleteHelpQuestion(id).then(action(() =>
        {
            const i = this.bySection[section][projectStore.id].findIndex(p => p.id == id);
            if (i >= 0)
            {
                this.bySection[section][projectStore.id].splice(i, 1);
            }
        }));
    }

    @action
    selectCategory(id: string)
    {
        this.selected = this.helpCategories[projectStore.id]?.find(c => c.id === id);
        if (this.selected)
        {
            this.selectedId = id;
            this.changes = toJS(this.selected);
        }
        else
        {
            this.selectedId = null;
            this.changes = {};
        }
    }
    @computed
    get getHelpCategories()
    {
        return this.helpCategories;
    }
    @action.bound
    changeCategory(key: string | string[], value: string)
    {
        deepSet(this.changes, key, value);
    }
    loadCategories(project: string)
    {
        getHelpCategories(project).then(action('loadHelpCategories', res =>
        {
            this.helpCategories[project] = res;
        }));
    }

    @action
    submit()
    {
        if (!this.changes.customURL)
        {
            const name = this.changes.name;
            this.changes.customURL = urlFromName(name.en || name.fr || name.nl || name.it || name.pl || name.es);
        }

        if (this.selectedId)
        {
            updateHelpCategory(this.changes).then(action('updateHelpCategory', res =>
            {
                if (res)
                {
                    const cat = this.helpCategories[projectStore.id].find(p => p.id == res.id);
                    Object.assign(cat, res);
                    this.changes.customURL = res.customURL;
                }
            }))
        }
        else
        {
            createHelpCategory({...this.changes, project: projectStore.id}).then(action('createHelpCategory', res =>
            {
                if (res)
                {
                    this.helpCategories[projectStore.id].push(res);
                    this.selectCategory(res.id);
                }
            }))
        }
    }
    deleteHelpCategory()
    {
        deleteHelpCategory(this.selectedId).then(action('deleteHelpCat', res =>
        {
            if (res)
            {
                const i = this.helpCategories[projectStore.id].findIndex(c => c.id === this.selectedId);
                this.helpCategories[projectStore.id].splice(i, 1);
                this.selectedId = null;
                this.selected = {};
                this.changes = {};
            }
        }))
    }

    copyCategory(project: string, categoryId: string)
    {
        return copyCategory(project, categoryId).then(res =>
        {
            Swal.fire(res.data ? swalModal('success', 'Category copied!') : swalModal('error', res.errors[0]?.message));
            return !!res.data;
        })
    }
}

export const helpStore = new HelpStore();

function urlFromName(name: string)
{
    return name?.trim().replace(/[ &/-]+/g, ' ') || '';
}
