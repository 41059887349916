import React, {useEffect, useState} from 'react';
import './ProductImage.scss';
import {useStoreProductImage} from './useStoreProductImage';

export default function ProductImage({images}: {images: string[]})
{
    const productImage = useStoreProductImage(images);
    const [fallback, setFallback] = useState<boolean>();
    useEffect(() =>
    {
        setFallback(false);
    }, [productImage]);
    return <img className='ProductImage' src={fallback || !productImage ? placeholder : productImage} onError={() => setFallback(true)} alt=''/>;
}

const placeholder = 'https://yourfair-pub.s3-eu-west-1.amazonaws.com/assets/product/product.svg';
