// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageSettings .setting-group {
  display: block;
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./frontend/Pages/PageSettings.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,uBAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".PageSettings {\n  .setting-group {\n    display: block;\n    padding: .5rem .75rem;\n    line-height: 1.5;\n    color: #495057;\n    border: 1px solid #ced4da;\n    border-radius: .25rem;\n    margin-bottom: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
