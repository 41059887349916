import {useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import {useParams} from 'react-router';
import SplitView from '../Dashboard/SplitView';
import CompanyProfile from './CompanyProfile';
import Timeline from '../Timeline/Timeline';
import { user } from '../stores/user';
import { companyStore } from './CompanyStore';
import CompanyContacts from './CompanyContacts';
import CompanySystem from './CompanySystem';
import CompanyMembers from '../Dashboard/CompanyMembers';
import DeleteCompany from './DeleteCompany';
import {ErrorBoundary} from '../common/ErrorBoundary';
import {Loading} from '../common/Loading';
import AssignCarrefourCompany from './AssignCarrefourCompany';
import CarrefourBrands from './CarrefourBrands';

export default observer(function CompanyPage()
{
    const params = useParams<{id?: string}>();
    const id = params.id || user.info?.company[0];

    useEffect(() =>
    {
        companyStore.select(id);
    }, [id]);

    const company = companyStore.company;

    if (!companyStore.selectedId)
    {
        return null;
    }
    if (!user.loaded || (!company && companyStore.loading))
    {
        return <div className='position-relative py-5'><Loading/></div>;
    }
    if (!company)
    {
        return <h2 className='text-center py-4'>Company Not Found</h2>;
    }

    return (
        <SplitView
            left={
                <div className='p-3'>
                    <CompanyProfile company={company}/>
                    <ErrorBoundary>
                        <Timeline id={id}/>
                    </ErrorBoundary>
                </div>
            }
            right={
                <>
                    {user.moderator &&
                    <>
                        <h5 className='border-bottom border-dark'>Admin</h5>
                        <div className='my-3'>
                            <input
                                id='is-tester'
                                type='checkbox'
                                className='mr-2'
                                checked={!!company.store?.test}
                                onChange={e => companyStore.update(['store', 'test'], e.currentTarget.checked)}
                            />
                            <label htmlFor='is-tester'>Test</label>
                        </div>
                        <div className='my-3'>
                            <input
                                id='is-blocked'
                                type='checkbox'
                                className='mr-2'
                                checked={!!company.store?.blocked}
                                onChange={e => companyStore.update(['store', 'blocked'], e.currentTarget.checked)}
                            />
                            <label htmlFor='is-blocked'>Blocked</label>
                        </div>
                        {company.exhibitor?.carrefour &&
                            <div className='my-3'>
                                <AssignCarrefourCompany company={company}/>
                            </div>
                        }
                    </>
                    }
                    {!user.isHostess && <CompanyContacts id={id}/>}
                    <CompanySystem id={id}/>
                    {company.exhibitor?.carrefour && !company.exhibitor.carrefourBrand &&
                        <CarrefourBrands id={id}/>
                    }
                    <CompanyMembers companyId={id}/>
                    {user.moderator && <DeleteCompany id={id}/>}
                </>
            }
        />
    );
});
