import {useEffect, useState} from 'react';
import {action} from 'mobx';
import {observer} from 'mobx-react-lite';
import {IPage, pageStore} from './PageStore';
import DropdownSearch from '../common/DropdownSearch';
import {projectStore} from '../stores/ProjectStore';
import {call, gql} from '../client';
import {ContentBlockProps} from './ContentBlock';
import {IUIPageItem} from '../../graphql/api/page/Page';
import {isID} from '../../lib/common';

interface Props
{
    page: IPage
}

export default observer(function ExhibitorLogos({page}: Props)
{
    const [list, setList] = useState<IExhibitorPageLink[]>([]);

    const projectId = projectStore.id;
    useEffect(() =>
    {
        exhibitorPageLinks(projectId).then(res =>
        {
            setList(res || []);
        });
    }, [projectId]);

    const sort = action(() =>
    {
        const isExhibitorLogo = (item: IUIPageItem) => (
            item.type == 'content' &&
            item.layout.w == 2 &&
            item.layout.h == 2 &&
            (item.props as ContentBlockProps).items.length == 1 &&
            isID((item.props as ContentBlockProps).items[0].link?.to?.en) &&
            findExhibitor((item.props as ContentBlockProps).items[0].link.to.en)
        );
        const findExhibitor = (pageId: string) => list.find(e => e.pageId == pageId);
        let changed = false;
        const items = page.items;
        for (let x = 0; x < items.length; ++x)
        {
            const a = items[x];
            if (isExhibitorLogo(a))
            {
                for (let y = x + 1; y < items.length; ++y)
                {
                    const b = items[y];
                    if (a != b && isExhibitorLogo(b))
                    {
                        // check if there are any non-exhibitor logos in between
                        if (a.layout.y != b.layout.y)
                        {
                            const topY = Math.min(a.layout.y, b.layout.y);
                            const bottomY = Math.max(a.layout.y, b.layout.y);
                            if (items.some(i =>
                                    topY < i.layout.y && i.layout.y < bottomY &&
                                    !isExhibitorLogo(i)
                                )
                            )
                            {
                                continue;
                            }
                        }
                        // negative = a is first, positive = b is first
                        const positionCompared =
                            (a.layout.y < b.layout.y ? -2 : a.layout.y > b.layout.y ? 2 : 0)
                            +
                            (a.layout.x < b.layout.x ? -1 : a.layout.x > b.layout.x ? 1 : 0);
                        // negative = a should be first, positive = b should be first
                        const nameCompared = findExhibitor((a.props as ContentBlockProps).items[0].link.to.en).name
                            .localeCompare(
                                findExhibitor((b.props as ContentBlockProps).items[0].link.to.en).name
                            );
                        if ((positionCompared > 0 && nameCompared < 0) || (positionCompared < 0 && nameCompared > 0))
                        {
                            const ax = a.layout.x;
                            const ay = a.layout.y;
                            a.layout.x = b.layout.x;
                            a.layout.y = b.layout.y;
                            b.layout.x = ax;
                            b.layout.y = ay;
                            changed = true;
                        }
                    }
                }
            }
        }
        if (changed)
        {
            page.changed = true;
        }
    });

    return (
        <>
            <div className='text-center font-weight-bold'>Add exhibitor page logo</div>
            <DropdownSearch
                options={list
                    .filter(e => !page.items.some(i => i.type == 'content' && i.props?.items?.some(j => j?.link?.to?.en == e.pageId)))
                    .map(e => ({value: e.pageId, name: e.name}))
                }
                arrow
                placeholder='Select exhibitor'
                onSelect={selected =>
                {
                    const exhibitorPageLink = list.find(e => e.pageId == selected);
                    pageStore.appendItem(page.id, {
                        id: null,
                        type: 'content',
                        layout: {
                            x: null,
                            y: null,
                            w: 2,
                            h: 2,
                        },
                        props: {
                            items: [{
                                published: true,
                                image: exhibitorPageLink.image ? {
                                    display: true,
                                    url: {
                                        en: exhibitorPageLink.image,
                                    }
                                } : null,
                                link: {
                                    active: true,
                                    to: {
                                        en: exhibitorPageLink.pageId,
                                    }
                                }
                            }],
                            viewAccess: page.viewAccess,
                        } as ContentBlockProps
                    });
                }}
            />
            <button className='button' onClick={sort}>Sort exhibitor logos</button>
        </>
    );
});

interface IExhibitorPageLink
{
    pageId: string
    name?: string
    image?: string
}

function exhibitorPageLinks(project: string)
{
    return call<{exhibitorPageLinks: IExhibitorPageLink[]}>(
        gql`query($project:ID!){exhibitorPageLinks(project:$project){pageId,name,image}}`, {project})
    .then(({data}) => data?.exhibitorPageLinks);
}
