// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HelpCard {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  max-width: 250px;
  border: 1px solid #000;
  border-radius: 1rem;
  align-content: center;
  text-align: center;
  justify-content: center;
  color: #000;
  font-size: 1.5rem;
}
.HelpCard:hover {
  cursor: pointer;
}
.HelpCard .inner {
  height: 100%;
  width: 100%;
  padding: 25px 35px;
}
.HelpCard .inner .helpName {
  font-size: 0.8em;
  margin-top: 15px;
}
.HelpCard .inner .card-image {
  height: 125px;
}
.HelpCard .inner .card-image img {
  height: 100%;
}
.HelpCard .inner .viewButton {
  margin-top: 35px;
}
.HelpCard .inner .viewButton .button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./frontend/Help/HelpCard.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,sBAAA;EACA,mBAAA;EACA,qBAAA;EACA,kBAAA;EACA,uBAAA;EACA,WAAA;EACA,iBAAA;AAAF;AACE;EACE,eAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;EACA,kBAAA;AACJ;AAAI;EACE,gBAAA;EACA,gBAAA;AAEN;AAAI;EACE,aAAA;AAEN;AADM;EACE,YAAA;AAGR;AAAI;EACE,gBAAA;AAEN;AADM;EACE,WAAA;AAGR","sourcesContent":["\n.HelpCard {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  height: 100%;\n  max-width: 250px;\n  border: 1px solid #000;\n  border-radius: 1rem;\n  align-content: center;\n  text-align: center;\n  justify-content: center;\n  color: #000;\n  font-size: 1.5rem;\n  &:hover {\n    cursor: pointer;\n  }\n  .inner {\n    height: 100%;\n    width: 100%;\n    padding: 25px 35px;\n    .helpName {\n      font-size: .8em;\n      margin-top: 15px;\n    }\n    .card-image {\n      height: 125px;\n      img {\n        height: 100%;\n      }\n    }\n    .viewButton {\n      margin-top: 35px;\n      .button {\n        width: 100%;\n      }\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
