import {Upload, uploadStore} from '../Upload/UploadStore';
import config from '../config';
import {onlyUnique} from '../../lib/common';
import {parseDataUrl} from '../../lib/dataUrl';
import {base64toBlob} from './blobUtility';
import {resizeUploaded} from './resizeUploaded';

const toolbarNoImages = 'undo redo | bold italic underline | formatselect | fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | charmap emoticons | link | ltr rtl | forecolor backcolor';

const toolbarEmail = 'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent emoticons | insertfile image link | ltr rtl | forecolor backcolor';

const tinymceInitConfigCommon = {
    height: 500,
    menubar: false,
    file_picker_types: 'image',
    resize: false,
    branding: false,
    autosave_ask_before_unload: false,
    plugins: [
        `searchreplace autolink autosave directionality
        visualblocks visualchars image link table charmap hr
        pagebreak nonbreaking anchor advlist lists imagetools textpattern
        noneditable help charmap quickbars emoticons`
    ],
    toolbar: 'undo redo | bold italic underline | formatselect | fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | charmap emoticons | insertfile image link | ltr rtl | forecolor backcolor',
    content_css: ['/bootstrap.min.css', '/tinymce.css'],
}

export const tinyMCEConfig = {
    apiKey: config.tinymceKey,
    init: {...tinymceInitConfigCommon}
}

export const tinyMCEConfigNoImages = {
    apiKey: config.tinymceKey,
    init: {...tinymceInitConfigCommon, toolbar: toolbarNoImages}
}

export const tinyMCEConfigPlaceholder = {
    apiKey: config.tinymceKey,
    init: {...tinymceInitConfigCommon, placeholder: 'Enter description'}
}

export const tinyMCEConfigEmail = {
    apiKey: config.tinymceKey,
    init: {...tinymceInitConfigCommon, toolbar: toolbarEmail},
}

const maxResolution = 2000;

function uploadImagesFromHtml(html: string, autoConvert?: boolean)
{
    const dataUrls = html.match(/ src="data:([\s\S]*?)"/g).map(s => s.substring(6, s.length - 1)).filter(onlyUnique);
    let promise: Promise<any>;
    const uploads: Promise<Upload>[] = [];
    if (dataUrls.length)
    {
        promise = Promise.all(dataUrls.map(dataUrl =>
            new Promise<void>((resolve, reject) =>
            {
                const data = parseDataUrl(dataUrl);
                const blob = base64toBlob(data.content, data.contentType);
                uploads.push(uploadStore.upload(blob, u =>
                {
                    if (u.finished)
                    {
                        html = html.replace(dataUrl, u.url);
                        if (autoConvert)
                        {
                            const img = new Image();
                            img.onload = async () =>
                            {
                                let w = img.width;
                                let h = img.height;
                                if (w > maxResolution || h > maxResolution)
                                {
                                    const aspect = w / h;
                                    if (w >= h)
                                    {
                                        w = maxResolution;
                                        h = Math.round(maxResolution / aspect);
                                    }
                                    else
                                    {
                                        w = Math.round(maxResolution * aspect);
                                        h = maxResolution;
                                    }
                                }
                                resizeUploaded(u.url, w, h).then(resolve as any);
                            };
                            img.src = dataUrl;
                        }
                        else
                        {
                            resolve();
                        }
                    }
                    else
                    {
                        reject(new Error('Image upload failed.'));
                    }
                }));
            })
        ));
    }
    return {
        result: promise ? promise.then(() => html) : Promise.resolve(html),
        uploads,
    };
}

export const editorChangeHandler = (handler: (content: string) => void, autoConvert?: boolean) =>
{
    return (c: string) =>
    {
        if (c.includes(' src="data:'))
        {
            uploadImagesFromHtml(c, autoConvert).result.then(handler);
        }
        else
        {
            handler(c);
        }
    };
};

export const editorChangeHandlerEx = (
    handleUploads: (uploads: Upload[]) => void,
    handleUploadsDone: (uploads: Upload[]) => void,
    handleResult: (content: string) => void,
    autoConvert?: boolean,
) =>
{
    return (c: string) =>
    {
        if (c.includes(' src="data:'))
        {
            const u = uploadImagesFromHtml(c, autoConvert);
            Promise.all(u.uploads).then(uploads =>
            {
                handleUploads(uploads);
                u.result.then(html =>
                {
                    handleUploadsDone(uploads);
                    handleResult(html);
                });
            });
        }
        else
        {
            handleResult(c);
        }
    };
};
