import {DetailedHTMLProps, FormHTMLAttributes, useState} from 'react';

export default function Form(props: DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>)
{
    const [wasValidated, setWasValidated] = useState(false);
    return (
        <form
            {...props}
            className={wasValidated ? (props.className || '') + ' was-validated' : props.className}
            onSubmit={e =>
            {
                e.preventDefault();
                setWasValidated(true);
                props.onSubmit?.(e);
            }}
        />
    );
}
