import translatedPublic, {translationsPublic} from './public';
import translatedPrivate, {translationsPrivate} from './private';

export const t = {
    ...translatedPrivate,
    ...translatedPublic,
};

export const translations = {
    ...translationsPublic,
    ...translationsPrivate,
};
