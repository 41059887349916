import {observable, action, computed, makeObservable} from 'mobx';
import {IProjectContext} from '../../graphql/api/project/Project';
import {trySet, tryGet} from './userPersist';
import {callSelectProject, callViewProject, loadProjects} from './projectProxy';
import {companyStore} from '../Company/CompanyStore';
import {user} from './user';
import { ICompanyProjects } from '../../graphql/api/company/Company';
import {deepSet, IMaybePath} from '../common/deepSet';
import {updateProject} from '../Admin/Projects/ProjectsProxy';
import {debounce} from '../common/debounce';
import {findProfile} from '../data/profiles';
import {searchStore} from '../Navbar/SearchStore';
import {registerGlobalEventHandler} from './globalEvents';
import {countryCurrency} from '../OrderStand/utility';
import {currencySymbol} from '../../lib/currency';

export interface IProject extends IProjectContext
{
    id?: string;
}

const activeProjectKey = 'active-project';
const activeProjectTimeKey = 'active-project-time';

type OnLoadListener = (id: string) => void;

class ProjectStore
{
    @observable projects: IProject[] = [];
    @observable private selectedId: string; // persist selected project - might not be own project
    @observable initialLoad = false;
    private onLoadListeners: OnLoadListener[];
    private projectViews: {[id: string]: number} = {};

    // project we're working with must be assigned to us
    @computed
    get id()
    {
        const own = this.selectableProjects;
        return own.length ?
            own.find(p => p.id === this.selectedId)?.id || own.find(p => !p.archived)?.id || own[0]?.id :
            this.projects.length ?
                null :
                this.selectedId;
    }

    get archived()
    {
        return !!this.selected?.archived;
    }

    @computed
    get baseProject(): ICompanyProjects
    {
        return {
            id: projectStore.id,
            commercialization: {
                status: 'Pending confirmation'
            }
        };
    }

    constructor()
    {
        makeObservable(this);
        const selectedAt = new Date(tryGet(activeProjectTimeKey));
        const selectedAtNum = +selectedAt;
        if (isNaN(selectedAtNum) || Date.now() - selectedAtNum > 7 * 24 * 60 * 60000)
        {
            trySet(activeProjectKey, null);
            trySet(activeProjectTimeKey, null);
        }
        else
        {
            this.selectedId = tryGet(activeProjectKey);
            trySet(activeProjectTimeKey, new Date().toISOString());
        }
        registerGlobalEventHandler('logout', () =>
        {
            this.projects = [];
            this.initialLoad = false;
            this.select(null);
        });
    }

    reload()
    {
        return loadProjects().then(action('loadProjects', res =>
        {
            if (res)
            {
                this.projects = res;
                if (this.id && (!this.selectedId || this.selectedId != this.id))
                {
                    this.select(this.id);
                }
                else if (this.id)
                {
                    this.viewProject(this.id);
                }
                if (this.id && this.onLoadListeners)
                {
                    for (const listener of this.onLoadListeners)
                    {
                        try
                        {
                            listener(this.id);
                        }
                        catch (e)
                        {
                            console.error(e);
                        }
                    }
                    this.onLoadListeners = null;
                }
                if (!this.initialLoad)
                {
                    this.initialLoad = true;
                }
            }
        }));
    }

    withId(listener: OnLoadListener)
    {
        if (this.id)
        {
            listener(this.id);
        }
        else
        {
            if (!this.onLoadListeners)
            {
                this.onLoadListeners = [];
            }
            this.onLoadListeners.push(listener);
        }
    }

    withLoaded(listener: OnLoadListener)
    {
        if (this.selected)
        {
            listener(this.id);
        }
        else
        {
            if (!this.onLoadListeners)
            {
                this.onLoadListeners = [];
            }
            this.onLoadListeners.push(listener);
        }
    }

    @computed
    get selected()
    {
        return this.projects.find(p => p.id === this.id);
    }

    @computed
    get isDigitalFair()
    {
        return this.selected?.fair?.settings?.digital;
    }

    @computed
    get selectedCountry()
    {
        return this.selected?.fair?.country;
    }

    @computed
    get currency()
    {
        return countryCurrency(this.selectedCountry);
    }

    @computed
    get currencySymbol()
    {
        return currencySymbol(this.currency);
    }

    @computed
    get slug()
    {
        return this.selected?.fair?.slug || '';
    }

    @computed
    get ownProjects()
    {
        if (user.isStore)
        {
            const now = Date.now();
            return this.projects.filter(p =>
                (p.fair.settings.openForTester && user.tester) ||
                (
                    p.fair.settings.availableForStoreTypes?.length &&
                    (
                        companyStore.ownStores.length ?
                            companyStore.ownStores.map(s => s.store.storeType)
                            :
                            user.info.profiles.map(pr =>
                            {
                                const pt = findProfile(pr.id);
                                return pt ? pt.storeType || pt.storeTypes?.[0] : null;
                            })
                    ).some(st =>
                        p.fair.settings.availableForStoreTypes.some(a =>
                            +new Date(a.from) < now && now < +new Date(a.to) &&
                            a.storeTypes.includes(st)
                        )
                    )
                )
            );
        }
        return this.projects;
    }

    @computed
    get selectableProjects()
    {
        return this.projects;
    }

    @action
    select(id: string)
    {
        trySet(activeProjectKey, this.selectedId = id);
        trySet(activeProjectTimeKey, id ? new Date().toISOString() : null);
        searchStore.items = null;
        if (id)
        {
            this.viewProject(id);
            callSelectProject(id);
        }
    }

    handleProjectIdLoad(id: string)
    {
        if (this.selectedId !== id)
        {
            this.select(id);
        }
    }

    private viewProject(id: string)
    {
        if (id && !this.projectViews[id] || Date.now() - this.projectViews[id] > 60 * 60000)
        {
            this.projectViews[id] = Date.now();
            callViewProject(id);
        }
    }

    @action
    change(field: IMaybePath, value: any)
    {
        deepSet(this.selected, field, value);
        this.debouncedUpdate();
    }

    private debouncedUpdate = debounce(() =>
    {
        updateProject(this.selected).then(res =>
        {
            if (!res)
            {
                this.reload();
            }
        });
    }, 1000);

    @computed
    get isFair()
    {
        const s = this.selected?.fair?.settings;
        return !!s?.orderFair || !s?.orderPromotion;
    }

    @computed
    get isPromotion()
    {
        return !!this.selected?.fair?.settings?.orderPromotion;
    }

    @computed
    get isFRNonFood()
    {
        return this.selectedCountry == 'FR' && !!this.selected?.fair?.settings?.frNonFood;
    }

    @computed
    get isEventOnly()
    {
        return this.selectedCountry == 'FR' && !!this.selected?.fair?.settings?.eventOnly;
    }

    @computed
    get canAddToCart()
    {
        return !!this.selected?.fair?.settings?.addToCart;
    }

    @computed
    get canValidateWithoutQR()
    {
        return !!this.selected?.fair?.settings?.validationWithoutQRLetsConnect;
    }

    @computed
    get validationOnlyAfterScanningIn()
    {
        return !!this.selected?.fair?.settings?.validationOnlyAfterScanningIn;
    }

    @computed
    get canSeeCatalog()
    {
        return !!this.selected?.fair?.settings?.displayCatalog;
    }

    @computed
    get isActive()
    {
        const now = Date.now();
        const p = this.selected;
        return !!(
            p &&
            (
                (p.fair.settings.openForTester && user.tester)
                ||
                p.fair.settings.availableForStoreTypes?.some(a =>
                    +new Date(a.from) < now && now < +new Date(a.to) &&
                    companyStore.ownStores.some(s => a.storeTypes.includes(s.store.storeType))
                )
            )
        );
    }

    @computed
    get wasActive()
    {
        const startedBefore = Date.now();
        const endingAfter = Date.now() - 365 * 24 * 60 * 60 * 1000;
        const p = this.selected;
        return !!(
            p &&
            (
                (p.fair.settings.openForTester && user.tester)
                ||
                p.fair.settings.availableForStoreTypes?.some(a =>
                    +new Date(a.from) < startedBefore && endingAfter < +new Date(a.to) &&
                    companyStore.ownStores.some(s => a.storeTypes.includes(s.store.storeType))
                )
            )
        );
    }

    @computed
    get canOrder()
    {
        if (!this.selected)
        {
            return !this.projects.length;
        }
        if (user.info?.company?.length == 0)
        {
            return false;
        }
        if (companyStore.ownCompanies.every(c => !c))
        {
            return true;
        }
        const country = this.selectedCountry;
        if (country == 'IT' && !companyStore.ownCompanies.map(c => c?.store?.storeType).filter(i => i).some(storeType => ['MF', 'E'].includes(storeType)))
        {
            return false;
        }
        const profile = findProfile(user.profile);
        const featuresMobile = profile?.features?.mobile;
        return this.selected?.fair?.settings?.orderPromotion ?
            featuresMobile?.orderPromotion?.includes(country) :
            featuresMobile?.orderFair?.includes(country);
    }

    @computed
    get canSeeProducts()
    {
        return user.moderator || user.isExhibitor || (this.canSeeCatalog && (this.italyHeadquarters || this.canOrder));
    }

    @computed
    get productsTableAccess()
    {
        return (
            this.selectedCountry != 'ES' &&
            (
                user.moderator ||
                (this.selectedCountry != 'IT' || !(user.isHeadquarter || user.isExhibitor))
            )
        );
    }

    @computed
    get italyHeadquarters()
    {
        return this.selectedCountry === 'IT' && user.isHeadquarter;
    }
}

export const projectStore = new ProjectStore();
