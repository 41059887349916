import {IPackageProduct} from '../../graphql/api/package/Package';
import {IProduct} from '../Products/ProductStore';
import {pickLanguage} from '../stores/utility';

export function formatPackageProductName(pkgProduct: IPackageProduct, product: IProduct, quantity: number)
{
    const name = pickLanguage(pkgProduct?.title);
    return name ? name.replace('#', quantity + '') :
        `${quantity} ${pickLanguage(product?.name) || 'missing'}`;
}
