import React from 'react';
import {observer} from 'mobx-react-lite';
import {packageStore} from '../Packages/PackageStore';
import {user} from '../stores/user';
import {t} from '../translations';
import {projectStore} from '../stores/ProjectStore';
import CheckoutPackageProductList from './CheckoutPackageProductList';
import {IStandPackage, IStandSetup} from '../../graphql/api/stand/Stand';
import {pickLanguage} from '../stores/utility';

interface CheckoutPackageViewProps
{
    // only needed for org view
    standId?: string
    pkg: IStandPackage
    setup: IStandSetup
    currency: string
}

export default observer(function CheckoutPackageView({standId, pkg, setup, currency}: CheckoutPackageViewProps)
{
    const packageInfo = packageStore.packages.find(pa => pa.id == pkg.id);
    const pkgPriceFormatted = user.formatCurrency(currency, pkg.price);
    return (
        <>
            {setup.type === 'normal' ? (
                <CheckoutItemBase
                    name={<>
                        {t.standCreation.simulation.surfaceDetail(setup.surface)}
                        <br/>
                        {t.standCreation.simulation.dimensionDetail(setup.dimensions.length, setup.dimensions.depth)}
                    </>}
                    price={pkgPriceFormatted}
                />
            ) : setup.type === 'fresh' ? (
                <CheckoutItemBase
                    name={t.standCreation.simulation.freshDetail}
                    price={pkgPriceFormatted}
                />
            ) : (!projectStore.isDigitalFair && (
                <CheckoutItemBase
                    name={t.standCreation.conceptStand}
                    price={pkgPriceFormatted}
                />
            ))}
            <div className='d-flex justify-content-between'>
                <p>{`${t.standCreation.simulation.includedIn} ${pickLanguage(packageInfo?.title)}:`}</p>
                {projectStore.isDigitalFair && <span>{pkgPriceFormatted}</span>}
            </div>
            <CheckoutPackageProductList standId={standId} standPkg={pkg} pkg={packageInfo} currency={currency}/>
        </>
    );
});

interface CheckoutItemBaseProps
{
    name?: React.ReactNode
    price?: React.ReactNode
}

const CheckoutItemBase = (p: CheckoutItemBaseProps) =>
{
    return (
        <div className='d-flex justify-content-between'>
            <div>{p.name}</div>
            <div className='text-nowrap ml-4'>{p.price}</div>
        </div>
    );
};
