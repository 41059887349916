import {useEffect, useState} from 'react';

export function useAutoRotate(length: number, time = 3000, enabled = true)
{
    const state = useState(0);
    const [index, setIndex] = state;

    useEffect(() =>
    {
        if (enabled && length)
        {
            if (index >= length)
            {
                setIndex(0);
            }
            let timer;
            timer = setTimeout(() =>
            {
                timer = null;
                setIndex(prevIndex => prevIndex + 1 >= length ? 0 : prevIndex + 1);
            }, time);
            return () =>
            {
                if (timer)
                {
                    clearTimeout(timer);
                }
            };
        }
    }, [length, index]);

    return state;
}
