import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import SplitView from '../Dashboard/SplitView';
import ContactProfile from './ContactProfile';
import Timeline from '../Timeline/Timeline';
import { user } from '../stores/user';
import { contactsStore } from './ContactsStore';
import {ErrorBoundary} from '../common/ErrorBoundary';
import {organizationStore} from '../Dashboard/organizationStore';
import {Loading} from '../common/Loading';
import {t} from '../translations';

export default observer(function ContactPage()
{
    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();

    useEffect(() =>
    {
        contactsStore.loadContact(id);
    }, [id]);

    const contact = id == user.id ? user.loaded ? user.info : null : contactsStore.contacts.find(c => c.id === id);

    if (!user.loaded || (!contact?.country && contactsStore.loading))
    {
        return <div className='position-relative py-5'><Loading/></div>;
    }
    if (!contact)
    {
        return <h2 className='text-center py-4'>Contact Not Found</h2>;
    }

    return (
        <SplitView
            left={
                <>
                    <ContactProfile id={id}/>
                    <ErrorBoundary>
                        {!!contact.country && <Timeline id={id || user.id} isUserPage userCompanies={contact.company}/>}
                    </ErrorBoundary>
                </>
            }
            right={
                user.moderator &&
                <>
                    <h5 className='border-bottom border-dark'>Admin</h5>
                    {user.admin &&
                    <div className='my-3'>
                        <input
                            id='is-organization'
                            type='checkbox'
                            className='mr-2'
                            checked={!!contact.organization}
                            onChange={e => organizationStore.updateOrganization(id, e.currentTarget.checked)}
                        />
                        <label htmlFor='is-organization'>Organization</label>
                    </div>
                    }
                    {(user.admin && !!contact.organization) &&
                        <div className='my-3'>
                            <input
                                id='is-eventAdmin'
                                type='checkbox'
                                className='mr-2'
                                checked={!!contact.eventAdmin}
                                onChange={e => organizationStore.updateEventAdmin(id, e.currentTarget.checked)}
                            />
                            <label htmlFor='is-eventAdmin'>Event Admin</label>
                        </div>
                    }
                    {user.admin &&
                        <div className='my-3'>
                            <input
                                id='is-support'
                                type='checkbox'
                                className='mr-2'
                                checked={!!contact.support}
                                onChange={e => organizationStore.updateSupport(id, e.currentTarget.checked)}
                            />
                            <label htmlFor='is-support'>Support</label>
                        </div>
                    }
                    {(user.admin && !!contact.organization) &&
                    <div className='my-3'>
                        <input
                            id='has-deleteAccess'
                            type='checkbox'
                            className='mr-2'
                            checked={!!contact.deleteAccess}
                            onChange={e => organizationStore.updateDeleteAccess(id, e.currentTarget.checked)}
                        />
                        <label htmlFor='has-deleteAccess'>Delete Access</label>
                    </div>
                    }
                    {(!!id && user.id != id) &&
                    <div className='my-3'>
                        <input
                            id='is-tester'
                            type='checkbox'
                            className='mr-2'
                            checked={!!contact.tester}
                            onChange={e => organizationStore.updateTester(id, e.currentTarget.checked)}
                        />
                        <label htmlFor='is-tester'>Tester</label>
                    </div>
                    }
                    {user.deleteAccess &&
                    <button
                        className='d-block button-empty'
                        onClick={async () =>
                        {
                            const choice = await Swal.fire({
                                title: 'Delete user?',
                                icon: 'error',
                                showCancelButton: true,
                                confirmButtonText: t.global.delete,
                                cancelButtonText: t.global.cancel,
                            });
                            if (choice.value)
                            {
                                const res = await contactsStore.removeUser(id);
                                if (res)
                                {
                                    alert('User deleted');
                                    navigate('/', {replace: true});
                                }
                            }
                        }}
                    >
                        Delete user
                    </button>
                    }
                </>
            }
        />
    );
});
