import React from 'react';

export default function ProductStarDiscount({starDiscount}: {starDiscount: string})
{
    return starDiscount != null && (
        <div className='discount'>
            <div className='percentage'>{starDiscount}</div>
        </div>
    );
}
