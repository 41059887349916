import {action, makeObservable, observable, runInAction} from 'mobx';
import {PersonOnEvent, PersonsOnEventInput, PersonsOnEventResult} from '../../graphql/api/event/PersonOnEvent';
import {projectStore} from '../stores/ProjectStore';
import {call, gql} from '../client';
import {throttle} from '../common/throttle';
import {registerGlobalEventHandler} from '../stores/globalEvents';

function getPersonsOnEvent(input: PersonsOnEventInput)
{
    return call<{personsOnEvent: PersonsOnEventResult}>(
        gql`query($input:PersonsOnEventInput!){personsOnEvent(input:$input){count,nextCursor,persons{id,rid,om,pr,fn,ln,e,fu,c{id,n,sid,t},cn,ibid,ibn,da,lu,pa,se,sc,rat,bs,bv,bf}}}`,
        {input}
    ).then(({data}) => data?.personsOnEvent);
}

class PersonsOnEventStore
{
    persons: {[project: string]: PersonOnEvent[]} = {};
    @observable changed = 0;
    @observable loading = false;
    loadedAt: Date;

    get currentPersons()
    {
        return this.persons[projectStore.id] || [];
    }

    get currentGuests()
    {
        return this.persons[projectStore.id]?.filter(u => u.pr == 'guest') || [];
    }

    constructor()
    {
        makeObservable(this);
        registerGlobalEventHandler('logout', action(() =>
        {
            this.persons = {};
            this.changed = 0;
        }));
    }

    @action
    markChanged()
    {
        ++this.changed;
    }

    load = throttle(async (input: Omit<PersonsOnEventInput, 'cursor'>) =>
    {
        runInAction(() =>
        {
            this.loading = true;
        });
        const isFiltered = input.profile || input.status || input.search;
        const newPersons = [];
        let cursor = 0;
        for (;;)
        {
            const res = await getPersonsOnEvent({...input, cursor});
            if (!res)
            {
                break;
            }
            const {count, nextCursor, persons} = res;
            cursor = nextCursor;
            newPersons?.push(...persons);
            if (isFiltered)
            {
                runInAction(() =>
                {
                    if (this.persons[input.project]?.length)
                    {
                        const currentPersons = this.persons[input.project];
                        const newList = [];
                        for (const p of newPersons)
                        {
                            const existing = currentPersons.find(cp => cp.id == p.id);
                            if (existing)
                            {
                                Object.assign(existing, p);
                            }
                            else
                            {
                                newList.push(p);
                            }
                        }
                        currentPersons.push(...newList);
                    }
                    else
                    {
                        this.persons[input.project] = newPersons;
                    }
                    ++this.changed;
                    this.loadedAt = new Date();
                });
            }
            if (cursor >= count)
            {
                break;
            }
        }
        runInAction(() =>
        {
            this.loading = false;
            if (!isFiltered)
            {
                this.persons[input.project] = newPersons;
                ++this.changed;
                this.loadedAt = new Date();
            }
        });
    }, 2000, true);
}

export const personsOnEventStore = new PersonsOnEventStore();
