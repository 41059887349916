import {observer} from 'mobx-react-lite';
import './OrderProductView.scss';
import {IOrderProductPromotion} from '../../graphql/api/order/Order';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import ProductImage from './ProductImage';
import {pickLanguage} from '../stores/utility';
import {t} from '../translations';
import {colon} from '../stores/user';
import OrderItemTotal from './OrderItemTotal';
import {orderPriceTotals} from '../../graphql/api/order/utility';
import {Country} from '../../graphql/api/Types';

interface Props extends Pick<ICatalogProduct, 'uvir' | 'flow' | 'description' | 'images'>
{
    promotion: IOrderProductPromotion[]
    ean?: string
    country: Country
    storeType: string
    isFRNonFood: boolean
    isFair: boolean
    currency: string
}

export default observer(function OrderProductView({promotion, uvir, flow, description, images, ean, country, storeType, isFRNonFood, isFair, currency}: Props)
{
    return (
        <div className='OrderProductView'>
            <div className='productImage'>
                <ProductImage images={images}/>
            </div>
            <div className='productContent'>
                <p className='name'>{pickLanguage(description)}</p>
                {ean && <p className='ean'>EAN{colon()}{ean}</p>}
                <div className='weeks'>
                    {promotion.filter(pr => pr.quantity).map(pr =>
                        <p key={pr.promoCode || pr.week}>
                            {country == 'BE' ?
                                <>
                                    <span>{t.cart.week}{colon()}{pr.week}</span>
                                    <span>{t.global.quantity}{colon()}{pr.quantity}</span>
                                    <span>{isFair ? 'UVIR' : 'VE/UV'}{colon()}{uvir}</span>
                                </>
                                :
                                country == 'PL' ?
                                    <>
                                        <span>{t.catalog.delivery}{colon()}{pr.promoCode}</span>
                                        <span>{t.global.quantity}{colon()}{pr.quantity}</span>
                                        <span>{isFair ? 'INK' : 'VE/UV'}{colon()}{uvir}</span>
                                    </>
                                    :
                                    country == 'IT' ?
                                        <>
                                            <span>{t.cart.week}{colon()}{pr.week}</span>
                                            <span>{t.global.quantity}{colon()}{pr.quantity}</span>
                                        </>
                                        :
                                        country == 'FR' ?
                                            <>
                                                {pr.week != null && <span>{t.cart.week}{colon()}{pr.week}</span>}
                                                <span>{t.global.quantity}{colon()}{pr.quantity}</span>
                                                <span>PCB{colon()}{uvir}</span>
                                                <span>Engagement UVC{colon()}{pr.quantity * uvir}</span>
                                            </>
                                            :
                                            null
                            }
                        </p>
                    )}
                </div>
                <OrderItemTotal
                    storeType={storeType}
                    isFRNonFood={isFRNonFood}
                    isFair={isFair}
                    country={country}
                    currency={currency}
                    {...orderPriceTotals(promotion)}
                    flow={flow}
                />
            </div>
        </div>
    );
});
