import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import './OrderStandType.scss';
import {orderStandStore} from './OrderStandStore';
import {t} from '../translations';
import {packageStore} from '../Packages/PackageStore';
import {user} from '../stores/user';
import { projectStore } from '../stores/ProjectStore';
import {userHasAccessToPkg} from './packageAccess';
import {useNewStandCompanyId} from './useHash';
import {pickLanguage} from '../stores/utility';

export const OrderStandType = observer(function OrderStandType()
{
    const navigate = useNavigate();
    const newStandCompanyId = useNewStandCompanyId();
    const [showConceptDropdown, setShowConceptDropdown] = useState(false);

    function handleStandType(e)
    {
        const selectedType = e.currentTarget.dataset.value;
        if (newStandCompanyId)
        {
            orderStandStore.createStand(selectedType, newStandCompanyId).then(standId =>
            {
                if (standId)
                {
                    navigate('#' + standId, {replace: true});
                }
            });
        }
        else
        {
            orderStandStore.selectOwnStand(selectedType, true);
        }
    }
    function handleConceptPackage(e)
    {
        setShowConceptDropdown(false);
        const packageId = e.currentTarget.dataset.id;
        orderStandStore.createStand('concept', newStandCompanyId).then(standId =>
        {
            if (standId)
            {
                orderStandStore.selectPackage(packageId);
                navigate('#' + standId, {replace: true});
            }
        });
    }
    if (!projectStore.selected)
    {
        return null;
    }
    const type = orderStandStore.selected?.setup.type;
    const conceptPackages = packageStore.projectPackages.filter(p => p.type === 'concept');
    return (
        <div>
            <div className='OrderStandTypeTitle'>{t.standCreation.participation}</div>
            <div className='OrderStandType'>
                {user.hasFreshSector &&
                (
                    user.moderator ||
                    !projectStore.selected.fair.settings?.blockNewStandOrders?.fresh ||
                    orderStandStore.ownSubmittedStands.some(s => s.setup.type === 'fresh')
                ) &&
                <button
                    data-value='fresh'
                    className={'fresh' == type ? 'selected' : ''}
                    disabled={projectStore.isDigitalFair}
                    onClick={handleStandType}
                >
                    {t.standCreation.standType.fresh}
                </button>}
                {(
                    user.moderator ||
                    !projectStore.selected.fair.settings?.blockNewStandOrders?.normal ||
                    orderStandStore.ownSubmittedStands.some(s => s.setup.type === 'normal')
                ) &&
                <button
                    data-value='normal'
                    className={'normal' == type ? 'selected' : ''}
                    disabled={projectStore.isDigitalFair}
                    onClick={handleStandType}
                >
                    {t.standCreation.standType.stand}
                </button>}
                {!newStandCompanyId &&
                (
                    user.moderator ||
                    (
                        !projectStore.selected.fair.settings?.blockNewStandOrders?.concept &&
                        conceptPackages.some(userHasAccessToPkg)
                    ) ||
                    orderStandStore.ownSubmittedStands.some(s => s.setup.type === 'concept')
                ) &&
                <button
                    data-value='concept'
                    className={'concept' == type ? 'selected' : ''}
                    onClick={handleStandType}
                >
                    {projectStore.selectedCountry == 'ES' ? t.standCreation.standType.conceptES : t.standCreation.standType.concept}
                </button>}
                {!!newStandCompanyId && user.moderator &&
                <button
                    data-value='concept'
                    className={'dropdown-toggle position-relative ' + ('concept' == type ? 'selected' : '')}
                    onClick={() => setShowConceptDropdown(!showConceptDropdown)}
                >
                    {projectStore.selectedCountry == 'ES' ? t.standCreation.standType.conceptES : t.standCreation.standType.concept}
                    {showConceptDropdown &&
                    <div className='dropdown-menu p-0 profile-popup show'>
                        <ul className='list-group'>
                            {conceptPackages.length ?
                                conceptPackages.filter(pa => pa.project == projectStore.id).map(p =>
                                <li
                                    key={p.id}
                                    data-id={p.id}
                                    className='list-group-item cursor-pointer d-flex'
                                    onClick={handleConceptPackage}
                                >
                                    <span>{pickLanguage(p.title)}</span>
                                </li>
                            ) : <li><span>None</span></li>}
                        </ul>
                    </div>}
                </button>}
            </div>
        </div>
    );
});

export const OrderStandTypeExisting = observer(function OrderStandTypeSelectOnly()
{
    function handleStandType(e)
    {
        const selectedType = e.target.dataset.value;
        orderStandStore.selectOwnStand(selectedType);
    }
    const type = orderStandStore.selected?.setup.type || orderStandStore.selectedType;
    return (
        <div>
            <div className='OrderStandTypeTitle'>{t.standCreation.participation}</div>
            <div className='OrderStandType'>
                {orderStandStore.ownSubmittedStands.some(s => s.setup.type == 'fresh') &&
                <button
                    data-value='fresh'
                    className={'fresh' == type ? 'selected' : ''}
                    onClick={handleStandType}
                >
                    {t.standCreation.standType.fresh}
                </button>}
                {orderStandStore.ownSubmittedStands.some(s => s.setup.type == 'normal') &&
                <button
                    data-value='normal'
                    className={'normal' == type ? 'selected' : ''}
                    onClick={handleStandType}
                >
                    {t.standCreation.standType.stand}
                </button>}
                {(orderStandStore.ownSubmittedStands.some(s => s.setup.type === 'concept')) &&
                <button
                    data-value='concept'
                    className={'concept' == type ? 'selected' : ''}
                    onClick={handleStandType}
                >
                    {projectStore.selectedCountry == 'ES' ? t.standCreation.standType.conceptES : t.standCreation.standType.concept}
                </button>}
            </div>
        </div>
    );
});
