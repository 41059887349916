import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import ChatWindow, {ChatWindowProps, IFormattedChatMessage} from './ChatWindow';
import MinimizedChat, {MinimizedChatProps} from './MinimizedChat';
import {chatStore} from './ChatStore';
import {IChat} from './chatProxy';
import {user} from '../stores/user';
import {t} from '../translations';
import {getInitials, getName} from '../common/getName';
import {colorHash} from '../common/colorHash';
import {projectStore} from '../stores/ProjectStore';
import {pickLanguage} from '../stores/utility';

export default observer(function MinimizableChatWindow({chat}: {chat: IChat})
{
    const props = formatChatProps(chat);
    const isMinimized = chatStore.minimizedChatIds.includes(chat.id);
    const onClick = useCallback(() => isMinimized ? chatStore.maximizeChat(chat.id) : chatStore.minimizeChat(chat.id), [isMinimized]);
    return isMinimized ?
        <MinimizedChat {...props} onMaximize={onClick}/> :
        <ChatWindow {...props} onMinimize={onClick}/>;
});

function formatChatProps(c: IChat): ChatWindowProps & MinimizedChatProps
{
    const others = c.usersInChat?.filter(p => p.id != user.id) || [];
    const asSupportChat = c.support && !user.info?.support && !user.moderator;
    return {
        id: c.id,
        support: c.support,
        supportAssignedTo: c.supportAssignedTo,
        supportChatMessage: asSupportChat ?
            outOfOfficeMessageIsActive() ?
                pickLanguage(projectStore.selected.fair.outOfOfficeMessage.message)
                :
                t.global.supportAutomatedMessage
            :
            c.contactButton?.person == user.id && t.global.supportAutomatedMessage,
        title: c.title ? c.title :
            asSupportChat && !others.length ? t.help.title :
                others.length == 1 ? others[0].phone || getName(others[0]) :
                    others.map(p => p.firstName || p.phone || '').join(', '),
        titleInitials: c.smsNumber ? <FA name='mobile-alt' className='fad'/> :
            others.length == 1 ? getInitials(others[0]) :
                <FA name='users' className='fad'/>,
        titleColor: colorHash.hex(others.map(p => p.id).join('')),
        messages: c.messages.map((m): IFormattedChatMessage =>
        {
            const author = c.participants?.find(p => p.id == m.author) || {id: m.author};
            return {
                author: author.id,
                firstName: author.firstName || author.phone || '',
                lastName: author.lastName,
                initials: author.phone ? <FA name='mobile-alt' className='fad'/> : getInitials(author),
                color: colorHash.hex(author.id),
                date: new Date(m.date),
                msg: m.msg,
                attachments: m.attachments,
                highlighted: m.highlighted,
            };
        }),
        participants: c.participants,
        usersInChat: c.usersInChat,
        onClose: () =>
        {
            chatStore.closeChat(c.id);
        },
        onSend: (text: string, attachments: string[]) =>
        {
            chatStore.sendMessage(c.id, text, attachments);
        },
        onAddUsers: (ids: string[]) =>
        {
            chatStore.addUsersToChat(c.id, ids);
        },
        onRemoveUser: (id: string) =>
        {
            chatStore.removeUsersFromChat(c.id, [id]);
        },
        onHighlight: (author: string, messageDate: Date, highlight: boolean) =>
        {
            chatStore.highlightChatMessage(c.id, author, messageDate, highlight);
        },
        onAssignMe: () =>
        {
            chatStore.onAssignMe(c.id);
        },
        onUnassignMe: () =>
        {
            chatStore.onUnassignMe(c.id);
        },
    };
}

const outOfOfficeMessageIsActive = () =>
{
    const outOfOfficeMessage = projectStore.selected?.fair?.outOfOfficeMessage;
    if (!outOfOfficeMessage?.use)
    {
        return null;
    }
    const {fromDay, fromMinute, toDay, toMinute} = outOfOfficeMessage;

    const from = fromDay + fromMinute / (24 * 60);

    let to = toDay + toMinute / (24 * 60);
    if (to < from)
    {
        to += 7;
    }

    const nowDate = new Date();
    const nowDay = nowDate.getDay();
    const nowMinutes = nowDate.getHours() * 60 + nowDate.getMinutes();
    let now = nowDay + nowMinutes / (24 * 60);
    if (now < from)
    {
        now += 7;
    }

    return from < now && now < to;
};
