import {action, makeObservable, observable} from 'mobx';
import {call, gql} from '../client';
import {Country} from '../../graphql/api/Types';

function loadCompanyNames(ids: string[])
{
    return call<{companyNames: ICompanyName[]}>(gql`query($ids:[ID!]!){companyNames(ids:$ids){id,name}}`, {ids})
    .then(({data}) => data?.companyNames);
}

function loadCompanyNamesForCountry(country: Country)
{
    return call<{companyNamesForCountry: ICompanyName[]}>(gql`query($country:String!){companyNamesForCountry(country:$country){id,name}}`, {country})
    .then(({data}) => data?.companyNamesForCountry);
}

export interface ICompanyName
{
    id: string
    name: string
}

class CompanyNameStore
{
    @observable private companyNames = new Map<string, ICompanyName>();
    @observable companyNamesForCountry = new Map<Country, ICompanyName[]>();

    constructor()
    {
        makeObservable(this);
    }

    load(ids: string[])
    {
        if (!ids)
        {
            return;
        }
        ids = ids.filter(id => id);
        if (!ids.length)
        {
            return;
        }
        return loadCompanyNames(ids).then(action(companies =>
        {
            if (companies)
            {
                for (const c of companies)
                {
                    this.companyNames.set(c.id, c);
                }
            }
        }));
    }

    loadForCountry(country: Country)
    {
        if (!country)
        {
            return;
        }
        return loadCompanyNamesForCountry(country).then(action(companies =>
        {
            if (companies)
            {
                for (const c of companies)
                {
                    this.companyNames.set(c.id, c);
                }
                this.companyNamesForCountry.set(country, companies);
            }
        }));
    }

    find(id: string)
    {
        const c = this.companyNames.get(id);
        return c && c.name;
    }
}

export const companyNameStore = new CompanyNameStore();
