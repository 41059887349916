import React, {useState} from 'react';
import {t} from '../translations';
import {LoginChildProps} from './LoginBox';
import LoginLCTitle from './LCTitle';
import {ConfirmLarge} from './Buttons';
import {handleEnter} from './utility';
import LoginMethodSwitchOptions from './LoginMethodSwitchOptions';
import BlueBox from './BlueBox';

export default function EnterRegID(props: LoginChildProps)
{
    const {defaultRegID, onLogin} = props;
    const [regID, setRegId] = useState(defaultRegID || '');
    const [showInvalid, setShowInvalid] = useState(false);
    const isValid = /[0-9A-Z]{7}/.test(regID);
    const confirm = () =>
    {
        if (isValid)
        {
            onLogin({value: regID, method: 'RegID'});
        }
        else
        {
            setShowInvalid(true);
        }
    }
    return (
        <BlueBox>
            <LoginLCTitle/>
            <div className='LoginInput'>
                <label>{t.eventPage.modifyBadge}</label>
                <input
                    className={'form-control mb-3' + (showInvalid && !isValid ? ' is-invalid' : '')}
                    autoFocus
                    type='text'
                    name='LetsConnectRegistrationId'
                    placeholder={t.eventPage.enterUniqueCode}
                    value={regID}
                    onChange={e => setRegId(e.currentTarget.value.trim().toUpperCase().slice(0, 7))}
                    onKeyDown={handleEnter(confirm)}
                />
                <ConfirmLarge onClick={confirm}/>
                <LoginMethodSwitchOptions {...props}/>
            </div>
        </BlueBox>
    )
}
