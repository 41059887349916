import React, {useMemo, useState} from 'react';
import {Editor as EditorTinyMCE, IAllProps} from '@tinymce/tinymce-react';

export default function DebouncedEditorTinyMCE(props: IAllProps)
{
    const [value, setValue] = useState<string>();
    const timeout = useMemo(() => ({timeout: null}), []);
    return (
        <EditorTinyMCE
            {...props}
            value={value ?? props.value}
            onEditorChange={props.onEditorChange && ((a, editor) =>
            {
                setValue(a);
                clearTimeout(timeout.timeout);
                timeout.timeout = setTimeout(() =>
                {
                    props.onEditorChange(a, editor);
                    setValue(undefined);
                }, 700);
            })}
        />
    );
}
