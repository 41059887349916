import {observable, action, toJS, makeObservable} from 'mobx';
import {navItems, editNavItems} from './navbarProxy';
import {projectStore} from '../stores/ProjectStore';
import {INavigationPagesContext} from '../../graphql/api/navLinks/NavLinks';
import {user} from '../stores/user';
import {companyStore} from '../Company/CompanyStore';
import {registerGlobalEventHandler} from '../stores/globalEvents';

export interface IMenuItems extends INavigationPagesContext
{
    id?: string
}

const baseMenu: IMenuItems =
{
    project: null,
    list: [],
    home: {}
};

class NavBarStore
{
    @observable menuItems: IMenuItems = null;
    @observable changes: Partial<IMenuItems> = null;
    @observable edit: boolean = false;

    @observable navMenu: boolean = false;
    @observable dismountMenu: boolean = true;
    private dismountTimeout;
    private reloadInterval;

    constructor()
    {
        makeObservable(this);
        registerGlobalEventHandler('logout', () =>
        {
            this.menuItems = null;
            this.changes = null;
        });
    }

    @action
    toggleMenu()
    {
        if (!this.navMenu)
        {
            clearTimeout(this.dismountTimeout);
            this.dismountMenu = false;
            setTimeout(action(() => this.navMenu = true), 5);
            mainStateReload();
            this.reloadInterval = setInterval(mainStateReload, 2000);
        }
        else
        {
            clearInterval(this.reloadInterval);
            this.navMenu = false;
            this.dismountTimeout = setTimeout(action(() => this.dismountMenu = true), 750);
        }
    }

    @action
    load(projectId: string)
    {
        this.menuItems = null;
        if (projectId)
        {
            navItems(projectId).then(action(res =>
            {
                this.menuItems = res || baseMenu;
                this.changes = toJS(this.menuItems);
            }));
        }
    }

    @action.bound
    toggleEdit()
    {
        this.edit = !this.edit;
        if (!this.edit)
        {
            this.changes = toJS(this.menuItems);
        }
    }

    @action
    addPage()
    {
        if (!this.changes.list.length)
        {
            this.changes.list.push({label: {id: 'pages', name: {en: 'Pages'}}, pages: []});
        }
        const last = this.changes.list[this.changes.list.length - 1];
        const identifier = Math.floor(Math.random() * 10000);
        last.pages.push({name: {en: 'New page'}, id: `${last.label.id}_${identifier}`, link: 'https://'});
    }

    @action
    addLabel()
    {
        const identifier = Math.floor(Math.random() * 10000);
        if (this.changes.list.find(l => l.label.id === `label_${identifier}`))
        {
            return;
        }
        this.changes.list.push({pages: [], label: {name: {en: 'New Label'}, id: `label_${identifier}`}});
    }

    save()
    {
        editNavItems(projectStore.id, this.changes.list, this.changes.home).then(action(res =>
        {
            if (res)
            {
                this.menuItems = res;
                this.changes = res;
                this.edit = false;
            }
        }))
    }
}

export const navbarStore = new NavBarStore();

let lastReloadTime = 0;
export function mainStateReload()
{
    if (user.loggedIn && Date.now() - lastReloadTime > 3000)
    {
        lastReloadTime = Date.now();
        companyStore.loadCompany();
        projectStore.reload();
    }
}
