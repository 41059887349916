// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SlideshowBlock {
  position: relative;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}
.SlideshowBlock .download-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SlideshowBlock .download-container .button {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
@media (hover: none) {
  .SlideshowBlock .download-container .button {
    opacity: 0.7;
  }
}
.SlideshowBlock:hover .download-container .button {
  opacity: 0.7;
}
.SlideshowBlock:hover .download-container .button:hover {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./frontend/Pages/SlideshowBlock.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,sBAAA;EACA,2BAAA;EACA,4BAAA;EACA,gCAAA;AACF;AAAE;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;AADI;EACE,UAAA;EACA,oCAAA;AAGN;AAAE;EAEI;IACE,YAAA;EACN;AACF;AAIM;EACE,YAAA;AAFR;AAIM;EACE,UAAA;AAFR","sourcesContent":[".SlideshowBlock {\n  position: relative;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  transition: all .3s ease-in-out;\n  .download-container {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    .button {\n      opacity: 0;\n      transition: opacity .3s ease-in-out;\n    }\n  }\n  @media (hover: none) {\n    .download-container {\n      .button {\n        opacity: .7;\n      }\n    }\n  }\n  &:hover {\n    .download-container {\n      .button {\n        opacity: .7;\n      }\n      .button:hover {\n        opacity: 1;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
