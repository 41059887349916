import {call, gql} from '../client';
import {IPostFromTo} from '../Post/PostView';
import {IPostFromToInput} from '../Post/postUtility';

export function loadAgenda()
{
    return call<{agenda: IPostFromTo[]}>(gql`{agenda{id,updated,date,dateTo,published,shownOnBadgePDF,title,content,profiles,project,attachments}}`)
    .then(({data}) =>
    {
        if (!data) return null;
        for (const post of data.agenda)
        {
            post.updated = new Date(post.updated);
            post.date = new Date(post.date);
        }
        return data.agenda;
    });
}

export function callCreateAgenda(post: IPostFromToInput & {project: string})
{
    return call<{createAgenda: IPostFromTo}>(
        gql`mutation ($input:AgendaCreateInput!){createAgenda(input:$input){id,updated,date,dateTo,published,shownOnBadgePDF,title,content,profiles,project}}`,
        {input: post})
    .then(({data}) =>
    {
        if (!data) return null;
        data.createAgenda.updated = new Date(data.createAgenda.updated);
        data.createAgenda.date = new Date(data.createAgenda.date);
        return data.createAgenda;
    });
}

export function callUpdateAgenda(post: Partial<IPostFromToInput>)
{
    return call<{updateAgenda: IPostFromTo}>(
        gql`mutation ($input:AgendaUpdateInput!){updateAgenda(input:$input){id,updated,date,dateTo,published,shownOnBadgePDF,title,content,profiles,project,attachments}}`,
        {input: post})
    .then(({data}) =>
    {
        if (!data) return null;
        data.updateAgenda.updated = new Date(data.updateAgenda.updated);
        data.updateAgenda.date = new Date(data.updateAgenda.date);
        return data.updateAgenda;
    });
}

export function callDeleteAgenda(id: string)
{
    return call<{deleteAgenda: string}>(gql`mutation ($id:ID!){deleteAgenda(id:$id)}`, {id});
}
