import React from 'react';
import {observer} from 'mobx-react-lite';
import './HelpEdit.scss';
import ChangeImage from '../common/ChangeImage';
import {helpStore} from './HelpStore';
import {TranslationForm} from '../Products/TranslationForm';
import {computed} from 'mobx';
import {t} from '../translations';
import CopyToAnotherProject from '../common/CopyToAnotherProject';

const handleChange = (key, lang, value) =>
{
    lang ? helpStore.changeCategory([key, lang], value) : helpStore.changeCategory(key, value);
}
const handleImageUpload = (url) =>
{
    helpStore.changeCategory('image', url);
}
const handleSubmit = () =>
{
    helpStore.submit();
}
const handleDelete = () =>
{
    helpStore.deleteHelpCategory();
}
const handleUrlChange = (e) =>
{
    helpStore.changeCategory('customURL', e.target.value);
};

export default observer(function HelpCategoryEdit()
{
    const translationOptions = computed(() =>
    {
        const cha = helpStore.changes;
        const sel = helpStore.selected;
        return [
            {
                key: 'name',
                name: t.helpPage.helpCategoryInputFieldName,
                value: cha?.name || sel?.name,
                placeholder: t.global.parking,
            },
        ];
    })
    const to = translationOptions.get();
    return (
        <div className='helpEdit'>
            <div className='overview d-flex'>
                <div className='helpImage'>
                    <ChangeImage
                        pic={helpStore.changes?.image || helpStore.selected?.image || 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/p%2BrTmh0Th9rJgjB7_FAVPNG_grayscale-icon_0d24EUeE.png'}
                        onUpload={handleImageUpload}
                    />
                </div>
                <div className='editButtons'>
                    <button className='button' onClick={handleSubmit}>{helpStore.selectedId ? t.helpPage.update : t.global.create}</button>
                    {helpStore.selectedId && (<button className='button-empty w-100' onClick={handleDelete}>{t.global.delete}</button>)}
                </div>
            </div>
            <div className='customUrl'>
                <div className='input-group mb-3'>
                    <div className='input-group-prepend'>
                        <span className='input-group-text' id='basic-addon3'>{location.origin + '/help/'}</span>
                    </div>
                    <input
                        type='text'
                        className='form-control'
                        id='basic-url'
                        value={helpStore.changes?.customURL || helpStore.selected?.customURL || ''}
                        aria-describedby='basic-addon3'
                        onChange={handleUrlChange}
                    />
                </div>
            </div>
            <div className='helpInfo'>
                <TranslationForm values={to} onChange={handleChange}/>
            </div>
            {!!helpStore.selectedId &&
            <CopyToAnotherProject className='mt-3' onSelect={project => helpStore.copyCategory(project, helpStore.selectedId)}/>
            }
        </div>
    )
})
