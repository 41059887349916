const msInADay = 24 * 60 * 60 * 1000;

// week is in range 0-52
// not handled the special case for the first week, nor does it check if the week number is out of range
export function getStartOfWeekInYear(year: number, week: number)
{
    const date = new Date(year, 0, 1);
    return new Date(+date + msInADay * (7 * week - (date.getDay() - 1)));
}

export function parsePromotionWeek(promotionWeek: number | string, projectYear: number)
{
    if (promotionWeek == null)
    {
        return null;
    }
    let week: number;
    let year: number;
    if (isNaN(promotionWeek as any))
    {
        const match = /^(\d{1,2})-(\d{4})$/.exec(promotionWeek as string);
        if (match)
        {
            week = +match[1];
            year = +match[2];
        }
        else
        {
            throw new Error(`Invalid week format: "${promotionWeek}"`);
        }
    }
    else
    {
        if (projectYear == null)
        {
            return null;
        }
        week = +promotionWeek;
        year = projectYear;
    }
    return {year, week};
}
