// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ftKF4V97exm1vVm6xErw {
  color: #fff;
  padding: 25px 50px;
  background: #2169CA;
  border-radius: 3px 3px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ftKF4V97exm1vVm6xErw h2 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 42px;
  margin: 0;
}
.vTiEJlpSTHyG1h6dfdTR {
  margin-right: 22px;
}

.uBjZAxpAY6m8iG8xiYTg {
  padding: 50px;
}

.wI7yhSBh67ohGpE9xQmy {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
  margin-top: 50px;
}
.wI7yhSBh67ohGpE9xQmy button {
  max-width: 100%;
  width: 260px;
}

@media screen and (max-width: 450px) {
  .ftKF4V97exm1vVm6xErw {
    padding: 20px;
  }
  .ftKF4V97exm1vVm6xErw h2 {
    font-size: 30px;
  }
  .uBjZAxpAY6m8iG8xiYTg {
    padding: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/views/EventRegistration.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,8BAA8B;EAC9B,gBAAgB;EAChB,eAAe;EACf,SAAS;AACX;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".title {\n  color: #fff;\n  padding: 25px 50px;\n  background: #2169CA;\n  border-radius: 3px 3px 0 0;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.title h2 {\n  font-family: Inter, sans-serif;\n  font-weight: 700;\n  font-size: 42px;\n  margin: 0;\n}\n.icon {\n  margin-right: 22px;\n}\n\n.content {\n  padding: 50px;\n}\n\n.buttons {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n  gap: 20px;\n  margin-top: 50px;\n}\n.buttons button {\n  max-width: 100%;\n  width: 260px;\n}\n\n@media screen and (max-width: 450px) {\n  .title {\n    padding: 20px;\n  }\n  .title h2 {\n    font-size: 30px;\n  }\n  .content {\n    padding: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `ftKF4V97exm1vVm6xErw`,
	"icon": `vTiEJlpSTHyG1h6dfdTR`,
	"content": `uBjZAxpAY6m8iG8xiYTg`,
	"buttons": `wI7yhSBh67ohGpE9xQmy`
};
export default ___CSS_LOADER_EXPORT___;
