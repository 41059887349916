import {action, computed, makeObservable, observable} from 'mobx';
import {ICountryContext} from '../../graphql/api/country/Country';
import {loadCountries} from './countryProxy';
import {defaultCountries} from '../../lib/defaultCoiuntries';
import {Country} from '../../graphql/api/Types';
import {user} from './user';
import {pickLanguage} from './utility';
import {projectStore} from './ProjectStore';

export interface ICountry extends ICountryContext {}

class CountryStore
{
    @observable countries: ICountry[] = defaultCountries;

    constructor()
    {
        makeObservable(this);
    }

    reload()
    {
        loadCountries().then(action('loadCountries', res =>
        {
            if (res?.length)
            {
                this.countries = res;
            }
        }));
    }

    @computed
    get countryCodes(): Country[]
    {
        return this.countries.map(c => c.code);
    }

    @computed
    get projectCountry(): ICountry
    {
        return this.countries.find(c => c.code == projectStore.selectedCountry);
    }

    @computed
    get countryTupleOptions(): [Country, string][]
    {
        return this.countries.map(c => [c.code, pickLanguage(c.name)]);
    }

    // Based on user countries
    @computed
    get filteredCountryTupleOptions(): [Country, string][]
    {
        return (user.admin ? this.countries : this.countries.filter(c => user.info.country.includes(c.code))).map(c => [c.code, pickLanguage(c.name)]);
    }

    @computed
    get countryOptions(): {name: string, value: Country}[]
    {
        return this.countries.map(c => ({name: pickLanguage(c.name), value: c.code}));
    }
}

export const countryStore = new CountryStore();
