import {action, computed, makeObservable, observable} from 'mobx';
import {loadPackage, loadPackages} from './packageProxy';
import {IPackageContext} from '../../graphql/api/package/Package';
import {projectStore} from '../stores/ProjectStore';

export interface IPackage extends IPackageContext
{
    id: string
}

class PackageStore
{
    @observable packages: IPackage[] = [];
    @observable packagesByProject: {[project: string]: IPackage[]} = {};

    @computed get projectPackages()
    {
        return this.packagesByProject[projectStore.id] || [];
    }

    constructor()
    {
        makeObservable(this);
    }

    reload(project: string)
    {
        loadPackages(project).then(action('reloadPackages', res =>
        {
            if (res)
            {
                this.packagesByProject[project] = res;
                const combined = [];
                for (const p of Object.getOwnPropertyNames(this.packagesByProject))
                {
                    combined.push(...this.packagesByProject[p]);
                }
                this.packages = combined;
            }
        }));
    }

    @action
    loadPackage(id: string)
    {
        loadPackage(id).then(action(p =>
        {
            if (p)
            {
                if (this.packagesByProject[p.project])
                {
                    const existing = this.packagesByProject[p.project].find(o => o.id == p.id);
                    if (existing)
                    {
                        Object.assign(existing, p);
                    }
                    else
                    {
                        this.packagesByProject[p.project].push(p);
                        this.packages.push(p);
                    }
                }
                else
                {
                    this.packagesByProject[p.project] = [p];
                    this.packages.push(p);
                }
            }
        }));
    }
}

export const packageStore = new PackageStore();
