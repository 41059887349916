import {action, makeObservable, observable} from 'mobx';
import {
    callUploadCanceled,
    callUploadFinished,
    callRequestUpload,
} from './uploadProxy';
import {uploadFile} from './uploadFile';
import checkIfFileIsReadable from './checkIfFileIsReadable';

export interface Upload
{
    url: string
    name: string
    progress: number // 0 to 1
    ended?: boolean // true when upload ended for whatever reason
    finished?: boolean // true when the upload successfully finishes
    canceled?: boolean // true when upload is canceled
    error?: boolean // true when there is an error during upload
    _xhr?: XMLHttpRequest
}

export interface UploadOptions
{
    bucket?: string
    dir?: string
    name?: string
    asAttachment?: boolean
    noHashInName?: boolean
}

class UploadStore
{
    @observable uploads: {[url: string]: Upload} = {};

    constructor()
    {
        makeObservable(this);
    }

    // resolves when the server responds with a signed url
    // use the onEnd() callback to get notified when the file upload actually ends
    // onEnd() callback is not called if we fail to get signed url (if the promise is rejected)
    @action
    upload(file: File | Blob, onEnd?: (a: Upload) => void, options?: UploadOptions)
    {
        checkIfFileIsReadable(file);
        return callRequestUpload({
            name: options?.name || (file as File).name,
            contentType: file.type,
            asAttachment: options?.asAttachment,
            bucket: options?.bucket,
            dir: options?.dir,
            noHashInName: options?.noHashInName,
        }).then(({name, url, signedUrl}) =>
        {
            console.log('upload to:', url);
            this.uploads[url] = {url, name, progress: 0};
            const u = this.uploads[url];
            u._xhr = uploadFile({
                url: signedUrl,
                file,
                name: options?.name,
                asAttachment: options?.asAttachment,
                onUploaded: action(() =>
                {
                    u.finished = true;
                    u.ended = true;
                    callUploadFinished(url);
                    // wait a bit before trying to access the image
                    setTimeout(() =>
                    {
                        onEnd?.(u);
                    }, 100);
                }),
                onError: action((e) =>
                {
                    if (!u.canceled)
                    {
                        u.error = true;
                    }
                    console.log('upload failed for', url);
                    callUploadCanceled(url);
                    u.ended = true;
                    onEnd?.(u);
                }),
                onProgress:  action((progress: number) =>
                {
                    u.progress = progress;
                }),
            });
            return u;
        });
    }

    @action
    cancel(url: string)
    {
        const u = this.uploads[url];
        if (u && !u.canceled)
        {
            u.canceled = true;
            if (!u.ended)
            {
                u.ended = true;
                u._xhr.abort();
            }
        }
    }

    // returns the upload only if it is still in progress
    getUploadInProgress(key: string)
    {
        const u = this.uploads[key];
        return u && !u.ended ? u : null;
    }
}

export const uploadStore = new UploadStore();

window.addEventListener('beforeunload', e =>
{
    for (const key of Object.getOwnPropertyNames(uploadStore.uploads))
    {
        if (!uploadStore.uploads[key].ended)
        {
            console.log('There are active uploads.');
            e.preventDefault();
            e.returnValue = '';
        }
    }
});
