import React from 'react';
import FA from 'react-fontawesome';
import './Chat.scss';
import {ChatAttachment} from '../../graphql/api/chat/Chat';
import {user} from '../stores/user';
import {IChatParticipant} from '../../graphql/api/chat/Chat';
import {ChatUsers} from './ChatWindowUsers';
import {ChatContent} from './ChatWindowContent';
import {contactsStore} from '../Contact/ContactsStore';

export interface ChatWindowProps
{
    id?: string
    title: React.ReactNode
    titleInitials: React.ReactNode
    titleColor?: string
    support?: boolean
    supportAssignedTo?: string
    supportChatMessage?: string
    messages: IFormattedChatMessage[]
    participants: IChatParticipant[]
    usersInChat: IChatParticipant[]
    onSend?(text: string, attachments: string[])
    onMinimize?()
    onClose?()
    onAddUsers?(ids: string[]): void
    onRemoveUser?(id: string): void
    onHighlight?(author: string, messageDate: Date, highlight: boolean): void
    onAssignMe?(): void
    onUnassignMe?(): void
}

export interface IFormattedChatMessage
{
    author: string
    initials: React.ReactNode
    color?: string
    firstName: string
    lastName: string
    date: Date
    msg: string
    attachments?: ChatAttachment[]
    highlighted?: boolean
}

interface ChatWindowState
{
    view?: 'users'
}

export default class ChatWindow extends React.PureComponent<ChatWindowProps, ChatWindowState>
{
    state: ChatWindowState = {};

    showUsers = () => this.setState({view: 'users'});
    hideUsers = () => this.setState({view: null});

    render()
    {
        const p = this.props;
        const s = this.state;
        return (
            <div className='ChatWindow'>
                <div className='ChatTitle'>
                    {!!p.titleInitials &&
                    <div>
                        <div className='ChatIcon' style={{backgroundColor: p.titleColor}}>{p.titleInitials}</div>
                    </div>}
                    <span className='text'>{p.title}</span>
                    <div className='options'>
                        {!s.view &&
                        <FA name='user-plus' className='fad chat-users-icon' onClick={this.showUsers}/>
                        }
                        <pre onClick={p.supportChatMessage && (!user.info?.support || !user.moderator) ? p.onClose : p.onMinimize}>_</pre>
                        <pre onClick={p.onClose}>X</pre>
                    </div>
                </div>
                {!s.view && <>
                    {p.supportChatMessage && (<p className='automatedSupportMessage'>{p.supportChatMessage || ''}</p>)}
                    {p.support && user.support &&
                        <div className='chatAssign' onClick={p.supportAssignedTo === user.id ? p.onUnassignMe : p.onAssignMe}>
                            {p.supportAssignedTo ?
                                p.supportAssignedTo === user.id ?
                                    <>
                                        <div className='regular'><FA name='user-check' className='fad'/> Assigned to me</div>
                                        <div className='hover'><FA name='user-minus' className='fad'/> Unassign me</div>
                                    </>
                                    :
                                    <>
                                        <div className='regular'><FA name='user-check' className='fad'/> Assigned to {getOtherSupportPersonName(p.supportAssignedTo)}</div>
                                        <div className='hover'><FA name='user-minus' className='fad'/> Assign to me instead</div>
                                    </>
                                :
                                <><FA name='user' className='fad'/> Assign to me</>
                            }
                        </div>
                    }
                    <ChatContent id={p.id} messages={p.messages} onSend={p.onSend} onHighlight={p.onHighlight}/>
                </>}
                {s.view == 'users' && (<>
                    <ChatUsers id={p.id} usersInChat={p.usersInChat} onClose={this.hideUsers} onAddUsers={p.onAddUsers} onRemoveUser={p.onRemoveUser}/>
                </>)}
            </div>
        );
    }
}

const getOtherSupportPersonName = (id: string) =>
{
    const otherPerson = contactsStore.contacts.find(c => c.id === id);
    return otherPerson ? otherPerson.firstName || otherPerson.lastName : 'someone else';
};
