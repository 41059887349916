import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import './TimelineEmail.scss';
import FA from 'react-fontawesome';
import {IEmailData} from '../../graphql/api/email/Email';
import {getName} from '../common/getName';
import {html2text} from '../../graphql/api/email/html2text';
import {formatTimelineDate} from '../common/timeFormatting';
import {TimelineItem, TimelineRow} from './TimelineItem';
import TimelineChats from './TimelineChats';
import AttachmentTag from '../Attachment/AttachmentTag';
import {chatStore} from '../Chat/ChatStore';
import {t} from '../translations';

export default observer(function TimelineEmail({id, sender, html, date, attachments}: IEmailData)
{
    useEffect(() =>
    {
        chatStore.reloadChatsOnTargets([id]);
    }, [id]);

    const [maximized, setMaximized] = useState(false);

    const toggleMaximized = () => setMaximized(v => !v);

    const hasChats = chatStore.chats.some(c => c.targetId == id);

    return (
        <TimelineItem
            className='TimelineEmail'
            withLine
            mark={<FA name='envelope' className='fad'/>}
        >
            <TimelineRow
                onClick={toggleMaximized}
                className='top'
                content={<>
                    <span className='name'><b>{getName(sender)}</b> sent an <b>{t.global.emailLowerCase}.</b></span>
                    {!maximized && <span className='text'>{html2text(html)}{!hasChats && <FA name='comment-alt-lines' className='fad'/>}</span>}
                </>}
                aside={formatTimelineDate(date)}
            />
            {maximized && <div className='clearfix emailBody' dangerouslySetInnerHTML={{__html: html}}/>}
            {!!attachments && <TimelineRow
                content={attachments.map(a =>
                    <AttachmentTag
                        key={a.key}
                        keyName={a.key}
                        name={a.name}
                    />
                )}
            />}
            {(hasChats || maximized) && <TimelineChats targetId={id}/>}
        </TimelineItem>
    );
});
