import {observer} from 'mobx-react-lite';
import moment from 'moment/moment';
import {IUserRegistrationEvent} from '../EventRegistrationFormStore';
import {EventUsersListItem} from '../../Event/eventsProxy';
import {t} from '../../translations';
import {pickLanguage} from '../../stores/utility';
import styles from './Confirmation.module.css';
import erStyles from './EventRegistration.module.css';
import {getName} from '../../common/getName';
import Button from '../components/Button';
import DownloadButton from '../components/DownloadButton';

interface Props
{
    event: IUserRegistrationEvent
    account: EventUsersListItem
    onBack: () => void
    onRegisterAnotherPerson: () => void
    onDownloadBadge: () => Promise<string>
}

export default observer(function Confirmation({event, account, onBack, onRegisterAnotherPerson, onDownloadBadge}: Props)
{
    const project = event.project;
    const badge = account.badges.find(b => b.project == project);

    return (
        <>
            <div className={erStyles.title}>
                <img className={erStyles.icon} src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/summary.svg' alt=''/>
                <h2>{t.eventRegistration.summary}</h2>
            </div>
            <div className={erStyles.content}>
                <div className={styles.details}>
                    <div className={styles.personal}>
                        <div className={styles.registrationId}>{badge.registrationId}</div>
                        <div className={styles.name}>{getName(account)}</div>
                        <div className={styles.personalText}>{account.function}</div>
                        <div className={styles.personalText}>{account.email}</div>
                        <a className={styles.edit} href='#back' onClick={e => {e.preventDefault(); onBack()}}>
                            {t.eventRegistration.edit}
                        </a>
                    </div>
                    <div className={styles.participation}>
                        <div className={styles.participationLabel}>{t.eventRegistration.summaryDays}</div>
                        {badge.days.slice().sort((a, b) => +new Date(a) - +new Date(b)).map(d =>
                            <div key={d + ''} className={styles.participationDate}>{moment(d).format('DD/MM')} - {t.eventRegistration.fair}</div>
                        )}
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{__html: pickLanguage(event.confirmationPage)}}/>
                <div className={styles.buttons}>
                    {badge.registrationId &&
                        <DownloadButton
                            getter={onDownloadBadge}
                            downloadText={t.registration.confirmation.download}
                            addDate
                            filename={`${account.firstName}_${account.lastName}_Badge`}
                            type='pdf'
                        />
                    }
                    <Button primary onClick={onRegisterAnotherPerson}>
                        {t.eventRegistration.registerAnother}
                    </Button>
                </div>
            </div>
        </>
    );
});
