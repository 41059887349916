import React from 'react';
import './SplitView.scss';

interface SplitViewProps
{
    left: React.ReactNode
    right: React.ReactNode
    scrollOff?: boolean
}

export default function SplitView({left, right, scrollOff}: SplitViewProps)
{
    return (
        <div className='SplitView'>
            <div className={'left ' + (scrollOff ? 'scroll-off' : '')}>
                {left}
            </div>
            <div className='right'>
                {right}
            </div>
        </div>
    )
}
