import {call, gql} from '../client';
import {AttachmentDownloadResult, AttachmentUploadResult} from '../../graphql/api/attachments/Attachment';

export function callDownloadAttachment(key: string)
{
    return call<{requestAttachmentDownload: AttachmentDownloadResult}>(
        gql`query($key:String!){requestAttachmentDownload(key:$key){signedUrl,finished,canceled,deleted}}`, {key})
    .then(({data}) => data?.requestAttachmentDownload);
}

// gets a signed url from our server and downloads the file directly from s3
export function autoDownloadAttachment(key: string)
{
    return callDownloadAttachment(key).then(res =>
    {
        if (res.signedUrl)
        {
            location.href = res.signedUrl;
        }
        return res;
    });
}

export function callRequestAttachmentUpload(file: File)
{
    return call<{requestAttachmentUpload: AttachmentUploadResult}>(
        gql`mutation($input: AttachmentInput!){requestAttachmentUpload(input:$input){key,name,signedUrl}}`,
        {input: {name: file.name, contentType: file.type}})
    .then(({data}) => data?.requestAttachmentUpload);
}

export function callAttachmentUploadFinished(key: string)
{
    return call(gql`mutation($key: String!){attachmentUploadFinished(key:$key)}`, {key});
}

export function callAttachmentUploadCanceled(key: string)
{
    return call(gql`mutation($key: String!){attachmentUploadCanceled(key:$key)}`, {key});
}
