import {lazy, Suspense, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {action} from 'mobx';
import {NavLink} from 'react-router-dom';
import {navbarStore} from './NavBarStore';
import {user} from '../stores/user';
import TextEditable from '../common/TextEditable';
import {projectStore} from '../stores/ProjectStore';
import {t} from '../translations';
import CustomMenuView from './CustomMenuView';
const CustomMenuEdit = lazy(() => import('./CustomMenuEdit'));
import {isID} from '../../lib/common';
import {handleNavMenu} from './Navbar';
import {simplifyCustomUrl} from '../common/simplifyCustomUrl';

export default observer(function CustomMenu()
{
    const items = navbarStore.edit ? navbarStore.changes : navbarStore.menuItems;
    const letsconnectHome = items?.home;

    useEffect(() =>
    {
        if (!navbarStore.menuItems || navbarStore.menuItems.project != projectStore.id)
        {
            navbarStore.load(projectStore.id);
        }
    }, [projectStore.id]);

    return (
        <div>
            <div className='d-flex justify-content-end mt-4'>
                {user.moderator &&
                <span className='edit-menu' onClick={navbarStore.toggleEdit}>{navbarStore.edit ? 'Cancel' : 'Edit'}</span>}
                {navbarStore.edit && <span className='edit-menu pl-5' onClick={() => {navbarStore.save()}}>Save</span>}
            </div>
            <div className='menu-pages'>
                <NavItemHome
                    name={t.global.navBar.letsConnectHome}
                    link={user.isHostess ?  '/' : letsconnectHome?.link || projectStore.selected?.fair?.homeLink || '/'}
                    className='letsconnectHome'
                />
                {(user.moderator || !user.isStore || projectStore.isActive) && (
                    navbarStore.edit ?
                        <Suspense fallback={<CustomMenuView/>}><CustomMenuEdit/></Suspense> :
                        <CustomMenuView/>
                )}
                {navbarStore.edit &&
                <div className='d-flex flex-column mt-4'>
                    <span className='edit-menu' onClick={() => {navbarStore.addPage()}}>+ Add new page</span>
                    <span className='edit-menu mt-2' onClick={() => {navbarStore.addLabel()}}>+ Add new label</span>
                </div>}
            </div>
        </div>
    )
})

interface INavMenuHome
{
    link: string
    name: string
    className?: string
}

const NavItemHome = observer(function NavItemHome({className, name, link}: INavMenuHome)
{
    const handleChangeLink = action((l: string) =>
    {
        navbarStore.changes.home.link = simplifyCustomUrl(l);
    });

    return (
        <div className={'d-flex align-items-center position-relative ' + (className ? className : '')}>
            {navbarStore.edit ?
                <>
                    <div className='d-flex flex-column'>
                        <h6>{name}</h6>
                        <TextEditable content={link} onChange={handleChangeLink}/>
                    </div>
                </>
                :
                <LinkForPages link={link} name={name}/>
            }
        </div>
    )
});

export function LinkForPages({link, name}: { link: string, name: string })
{
    const pageID = isID(link);
    return (
        link?.startsWith('/') || pageID ? (
            <NavLink to={pageID ? `/page/${link}` : link} onClick={handleNavMenu}>{name}</NavLink>
        ) : (
            <a
                href={link?.startsWith('https://') || link?.startsWith('http://') ? link : `http://${link}`}
                className='link-page'
                target='_blank'
            >
                {name}
            </a>
        ))
}
