import React, {useRef, useState} from 'react';
import {LoginChildProps} from './LoginBox';
import {t} from '../translations';
import {handleEnter} from './utility';
import {Confirm, ReSend} from './Buttons';
import type {GraphQLFormattedError} from 'graphql';
import BlueBox from './BlueBox';
import useIsMounted from '../common/useIsMounted';
import RefreshEverySecond from '../common/RefreshEverySecond';

const resendTimeout = 10000; // 10s

export default function EnterCode({input, onLogin, onSubmitCode}: LoginChildProps)
{
    const {method, value} = input;
    const [code, setCode] = useState('');
    const [errors, setErrors] = useState<GraphQLFormattedError[]>();
    const [canResendAfter, setCanResendAfter] = useState(() => Date.now() + resendTimeout);
    const [showResendTimer, setShowResendTimer] = useState(false);
    const inputRef = useRef<HTMLInputElement>();
    const isMounted = useIsMounted();
    const confirm = () => code ?
        onSubmitCode(code).then(({errors: nextErrors}) =>
        {
            if (nextErrors && isMounted.current)
            {
                setErrors(nextErrors);
            }
        }) :
        inputRef.current.focus();
    const isEmail = method == 'Email';
    return (
        <BlueBox>
            <div className='LoginInput'>
                <label className='d-block text-center'>
                    {isEmail ? t.authentication.awaitingConfirmationSub + ' ' + value : t.authentication.otpCode}
                </label>
                <input
                    ref={inputRef}
                    className='form-control mb-3'
                    autoFocus
                    type='code'
                    name='code'
                    autoComplete='one-time-code'
                    placeholder={isEmail ? t.authentication.emailCodeVerification : t.authentication.smsCodeVerification}
                    value={code}
                    onChange={e => setCode(e.target.value.trim())}
                    onKeyDown={handleEnter(confirm)}
                />
            </div>
            {!!errors && <p className='LoginError'>{loginCodeErrorMessages(errors)}</p>}
            <div className='LoginInput'>
                <div className='d-flex justify-content-between mt-2'>
                    <ReSend
                        action={() =>
                        {
                            if (canResendAfter < Date.now())
                            {
                                setShowResendTimer(false);
                                setCanResendAfter(Date.now() + resendTimeout);
                                return onLogin(input);
                            }
                            else if (!showResendTimer)
                            {
                                setShowResendTimer(true);
                            }
                        }}
                    >
                        {!!showResendTimer && canResendAfter > Date.now() &&
                        <RefreshEverySecond>
                            {() => canResendAfter > Date.now() && ` (${((canResendAfter - Date.now()) / 1000).toFixed(0)}s)`}
                        </RefreshEverySecond>
                        }
                    </ReSend>
                    <Confirm onClick={confirm}/>
                </div>
            </div>
        </BlueBox>
    );
}

export function loginCodeErrorMessages(errors: GraphQLFormattedError[])
{
    if (errors.some(e => e.message === 'bad-code'))
    {
        const waitTime = errors.find(e => e.message === 'bad-code').extensions?.waitTime as number;
        if (waitTime)
        {
            return t.authentication.invalidOtp + ' ' + t.authentication.waitForXAmount(formatRemainingTime(waitTime));
        }
        else
        {
            return t.authentication.validOtp;
        }
    }
    else if (errors.some(e => e.message === 'rate-limit'))
    {
        const waitTime = errors.find(e => e.message === 'rate-limit').extensions?.waitTime as number;
        return t.authentication.waitForXAmount(formatRemainingTime(waitTime));
    }
    else
    {
        return t.global.somethingWentWrong;
    }
}

function formatRemainingTime(ms: number)
{
    const minutes = Math.floor(ms / 60000);
    const minutesRemainder = ms % 60000;
    const seconds = Math.floor(minutesRemainder / 1000);
    return minutes ? minutes + ' minutes' : seconds + ' seconds';
}
