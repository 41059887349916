import React from 'react';
import {observer} from 'mobx-react-lite';
import {orderStandStore} from './OrderStandStore';
import {t} from '../translations';
import {user} from '../stores/user';
import {projectStore} from '../stores/ProjectStore';

export default observer(function SetStandPreferences()
{
    const type = orderStandStore.selected?.setup.type;
    if (!(type === 'normal' && (user.moderator || !orderStandStore.isSubmittedApprovedInvoiced)))
    {
        return null;
    }
    return (
        <div className='mt-3 mb-2'>
            <p className='text-large text-black font-2'>
                {projectStore.selectedCountry == 'ES' ? t.standCreation.mapAndPreferences.notes : t.standCreation.mapAndPreferences.yourPreferences}
            </p>
            <textarea
                className='form-control'
                value={orderStandStore.preference ?? orderStandStore.selected.preference ?? ''}
                onChange={handlePreferenceChange}
                rows={1}
            />
        </div>
    );
});

function handlePreferenceChange(e)
{
    orderStandStore.changePreference(orderStandStore.selected.id, e.target.value);
}
