import React, {PropsWithChildren} from 'react';
import './OrderStandCheckoutItem.scss';
import {t} from '../translations';
import { observer } from 'mobx-react-lite';

interface OrderStandCheckoutCategoryProps
{
    className?: string
    name: string
    total?: React.ReactNode
    hideTopName?: boolean
}

export const OrderStandCheckoutCategory = observer(function OrderStandCheckoutCategory(
    {className, name, hideTopName, total, children}: PropsWithChildren<OrderStandCheckoutCategoryProps>
)
{
    return (
        <div className={'OrderStandCheckoutCategory ' + (className || '')}>
            {!hideTopName && <div className='category-name'>{name}</div>}
            {children}
            {!!total &&
            <div className='subtotal-price'>
                <span className='total-name'>{name + ' ' + t.standCreation.simulation.subtotal}</span>
                <span className='total-price'>{total}</span>
            </div>}
        </div>
    );
});

interface CheckoutBottomLineProps
{
    className?: string
    text: React.ReactNode
    price?: React.ReactNode
}

export const CheckoutBottomLine = ({className, text, price}: CheckoutBottomLineProps) =>
{
    return (
        <div className={'CheckoutBottomLine clearfix ' + (className || '')}>
            <div className='text float-left'>
                {text}
            </div>
            <div className='total float-right'>
                <span className='text-nowrap text-bold ml-1'>{t.standCreation.simulation.total}</span>
                <span className='text-nowrap ml-4'>{price}</span>
            </div>
        </div>
    )
};
