import {Link} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import moment from 'moment';
import FA from 'react-fontawesome';
import './StoreExhibitorOrderCard.scss';
import {IOrder} from './orderProxy';
import {projectStore} from '../stores/ProjectStore';
import OrderItemTotal from './OrderItemTotal';
import {countryCurrency} from '../OrderStand/utility';
import {catalogProductStore} from './CatalogProductStore';

interface Props
{
    exhibitorName: string
    order: IOrder
    storeType: string
}

export default observer(function StoreExhibitorOrderCard({exhibitorName, order, storeType}: Props)
{
    const isFair = !projectStore.isPromotion;
    const isFRNonFood = projectStore.isFRNonFood;
    const country = projectStore.selectedCountry;
    const showBrand = projectStore.selected ? !projectStore.selected.fair.settings.hideBrand : false;

    return (
        <div className='StoreExhibitorOrderCard'>
            <Link to={'/order/' + order.id} className='link'><FA name='info-circle' className='fal'/></Link>
            <p className='text-pre'>{moment(order.date).format('DD MMM YYYY   H:mm')}</p>
            {showBrand && <p className='exhibitorName'>{exhibitorName}</p>}
            <OrderItemTotal
                isTotal
                storeType={storeType}
                isFRNonFood={isFRNonFood}
                isFair={isFair}
                country={country}
                currency={countryCurrency(country)}
                {...order.total}
                flow={isFRNonFood ?
                    order.products.some(op => catalogProductStore.products.find(e => e.id == op.id)?.flow == 'GSA') ? 'GSA' : 'GSE' :
                    undefined
                }
            />
        </div>
    );
});
