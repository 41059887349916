import {call, gql} from '../client';
import {IProject} from './ProjectStore';

export function loadProjects()
{
    return call<{projects: IProject[]}>
    (gql`{projects{id,name,client,archived,fair{productFamilies,productWorkshops,country,active,homeLink,floorMapPreview,floorMap,floorMapMobile,week,jobNumber,settings,slug,codes{invoice,creditNote},date{start,end},videos{live,url,access},percentages,minSurface,earlyBird,lateOrder,lateOrder2,totalSpace,deals,scannerGroups,filters{productType,promotions,productFamilies,productWorkshops,concept,brands},universalQRCode,eventProfiles,outOfOfficeMessage}}}`)
    .then(({data}) => data?.projects);
}

export function callViewProject(project: string)
{
    return call(gql`mutation($project:ID!,$type:String){viewProject(project:$project,type:$type)}`, {project, type: 'web'});
}

export function callSelectProject(project: string)
{
    return call(gql`mutation($project:ID!){selectProject(project:$project)}`, {project});
}
