import React from 'react';
import {observer} from 'mobx-react-lite';
import './LandingPage.scss';
import {LandingNavbar} from './LandingNavbar';
import {Registration} from './Registration';
import {ContactMessage} from './ContactMessage';
import {t} from '../translations';
import {ErrorBoundary} from '../common/ErrorBoundary';
import LoginBox from '../Login/LoginBox';
import {loginStore} from '../stores/LoginStore';
import {getName} from '../common/getName';

export default observer(function LandingPage()
{
    return (
        <div className='LandingPage'>
            <LandingNavbar/>
            <div className='LandingTop'>
                <img src='https://yourfair-pub.s3-eu-west-1.amazonaws.com/assets/letsconnect_full.png' alt=''/>
            </div>
            <div className='LandingMain container'>
                <h3 className='mb-3'>{t.welcomePage.title}</h3>
                <div className='inner'>
                    {loginStore.registering ?
                        <ErrorBoundary><Registration/></ErrorBoundary>
                        :
                        <>
                            {(!loginStore.registered || loginStore.autoValidated) &&
                            <LoginBox
                                onLogin={loginStore.login}
                                onSubmitCode={loginStore.submitCode}
                                accounts={loginStore.accounts.map(u => ({id: u.id, name: getName(u)}))}
                                onSelectAccount={loginStore.selectAccount}
                                onSelectNewAccount={loginStore.selectNewAccount}
                            />
                            }
                            {loginStore.registered &&
                            <div className='Registration Registered'>
                                <h4 dangerouslySetInnerHTML={{__html: loginStore.autoValidated ? t.registration.form.thanksV2 : t.registration.form.thanks}}/>
                            </div>
                            }
                        </>
                    }
                </div>
            </div>
            <ContactMessage/>
        </div>
    );
})
