import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import styles from './RemainingBadges.module.css';
import {availableBadgesStore} from '../../Event/AvailableBadgesStore';
import {t} from '../../translations';
import {user} from '../../stores/user';

export default observer(function RemainingBadges(
    {project, companyId, userId, selectedCount}: {project: string, companyId: string, userId?: string, selectedCount: number}
)
{
    useEffect(() =>
    {
        availableBadgesStore.load(project, companyId);
    }, [project, companyId]);

    const badges = availableBadgesStore.find(project, companyId);
    let count = selectedCount;
    if (badges)
    {
        for (const u of badges.assignedBadgesByUser)
        {
            if (u.id != userId)
            {
                count += u.count;
            }
        }
    }

    return (
        <>
            <div className={styles.remainingBadges}>{(badges == null ? '?' : badges.companyBadges - count) + ' ' + t.eventPage.badgesRemaining}</div>
            {user.moderator ?
                badges != null && badges.companyBadges - count < 0 &&
                <div className={styles.insufficientBadges}>{t.eventPage.badgesAdditionalCharges}</div>
                :
                badges != null && badges.companyBadges - count === 0 &&
                <div className={styles.insufficientBadges}>{t.registration.form.increaseBadgeNumber}</div>
            }
        </>
    );
});
