import React from 'react';
import {observer} from 'mobx-react-lite';
import './PackageCard.scss';
import {IPackage, packageStore} from './PackageStore';
import {productStore} from '../Products/ProductStore';
import {orderStandProductStore} from '../OrderStand/OrderStandProductStore';
import {calculatePackagePrice} from './lib';
import {filterPackageProductsForSetup, packageContainsSetup, packageProductSetup} from '../../lib/packageLib';
import {IStandSetup} from '../../graphql/api/stand/Stand';
import {user} from '../stores/user';
import {t} from '../translations';
import {applyDiscounts, earlyBirdDiscounts, lateFeeDiscounts, setupDiscounts} from '../../lib/discounts';
import {formatPackageProductName} from './utility';
import {projectStore} from '../stores/ProjectStore';
import {countryStore} from '../stores/CountryStore';
import {pickLanguage} from '../stores/utility';

interface Props
{
    package: IPackage
    setup?: IStandSetup
    active?: boolean
    itemPricePreview?: boolean
    savePercentage?: number
    onSelect?(id: string): void
}

export default observer(function PackageCard({setup, active, savePercentage, package: pkg, itemPricePreview, onSelect}: Props)
{
    const {parentId, title, description, discount, image, project: projectId} = pkg;
    let {products} = pkg;
    const parent = parentId && packageStore.packages.find(p => p.id === parentId);
    if (products && setup)
    {
        products = filterPackageProductsForSetup(products, setup, user.moderator);
    }
    if (!setup)
    {
        setup = {} as any;
    }
    const project = projectStore.projects.find(p => p.id === projectId);
    const country = project?.fair?.country || 'BE';

    const fixedPrice = pkg.fixedPrice;
    const calculatedSetupExtraCost = setupDiscounts(setup, project);
    const calculatedEarlyBird = earlyBirdDiscounts(project);
    const calculatedLateFee = lateFeeDiscounts(project);

    const currency = countryStore.countries.find(c => c.code === country)?.currency || 'EUR';

    return (
        <div className={'PackageCard ' + (active ? 'selected-card' : '')}>
            <div className='card-top'>
                <div className='image' style={image ? {backgroundImage: `url(${image})`} : null}/>
                <div className='card-name'>
                    {pickLanguage(title)}
                </div>
            </div>
            <div className='card-info'>
                <div className='card-price'>
                    {user.formatCurrency(currency, calculatePackagePrice(pkg, setup))}
                </div>
                <div className='card-details'>
                    <div className='card-description'>{pickLanguage(description)}</div>
                    <button onClick={() => onSelect?.(pkg.id)} className='select-card'>
                        {active ? t.global.selected : t.global.select}
                    </button>
                    {setup.type == 'normal' &&
                    user.moderator &&
                    packageContainsSetup(pkg, setup, pid => packageStore.packages.find(pa => pa.id === pid), true) &&
                    !packageContainsSetup(pkg, setup, pid => packageStore.packages.find(pa => pa.id === pid), false) &&
                    <div className='only-admin'>Only available to Admin</div>
                    }
                    <ul className='card-benefits'>
                        {parent && <li>All from {parent.title?.en}</li>}
                        {products && products.map((product, i) =>
                        {
                            if (product.hide)
                            {
                                return null;
                            }
                            const prod =
                                orderStandProductStore.projectProducts.find(pa => pa.id == product.id) ||
                                productStore.products.find(p => p.id === product.id);
                            const {quantity, priceMultiplier} = packageProductSetup(product, setup, discount, user.moderator);
                            function calculatePrice()
                            {
                                const productPrice = prod?.price?.selling?.value || 0;
                                const useSetupExtraCost = !fixedPrice && product.useDiscounts;
                                const discounts = [
                                    ...(useSetupExtraCost ? calculatedSetupExtraCost : []),
                                    ...calculatedEarlyBird,
                                    ...calculatedLateFee,
                                ];
                                return applyDiscounts(productPrice * priceMultiplier, discounts?.map(d => d.amount));
                            }
                            return (
                                <li
                                    key={i}
                                >
                                    {formatPackageProductName(product, prod, quantity)}
                                    {itemPricePreview && user.moderator ?
                                        <span className='card-item-price'> {user.formatCurrency(currency, calculatePrice())}</span> :
                                        null
                                    }
                                </li>
                            );
                        })}
                    </ul>
                </div>
                {!!savePercentage && <div className='card-save'>{t.packages.save + ' ' + (savePercentage * 100).toFixed(0)}%</div>}
            </div>
        </div>
    );
});
