import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import {IPage} from './PageStore';
import './PageCatalog.scss';
import {pageCatalogStore} from './PageCatalogStore';
import {cartQuantityStore} from '../StoreCatalog/CartQuantityStore';
import {projectStore} from '../stores/ProjectStore';
import {countryCurrency} from '../OrderStand/utility';
import {colon, user} from '../stores/user';
import {companyStore, ICompany} from '../Company/CompanyStore';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import TextEditable from '../common/TextEditable';
import { Link } from 'react-router-dom';
import {mainStateReload} from '../Navbar/NavBarStore';
import ProductStarDiscount from '../StoreCatalog/ProductStarDiscount';
import ProductImage from '../StoreCatalog/ProductImage';
import {getStoreTypeForProducts} from '../StoreCatalog/utility';
import {pickLanguage} from '../stores/utility';
import {ExtraRows, FRInfoRows, FRMarketStars, FRPriceRow, FRProductImage, InfoRows, PriceRows, ProductComment} from '../StoreCatalog/PriceInfo';
import ProductLabels from '../StoreCatalog/ProductLabels';
import TotalQuantityOverlay from '../StoreCatalog/TotalQuantityOverlay';
import {t} from '../translations';
import ProductOrdered from '../StoreCatalog/ProductOrdered';
import {getProductTotal, getProductTotalFR, getProductTotalIT} from '../StoreCatalog/productUtility';
import LimitedQuantityDateOverlay from '../StoreCatalog/LimitedQuantityDateOverlay';
import {ProductPackage, ProductPackagePrice} from '../StoreCatalog/ProductDisplayPackage';

function pageCatalogProductsInput(page: IPage, previewProfile: string)
{
    const storeType = previewProfile && getStoreTypeForProducts(previewProfile);
    return {
        pageId: page.id,
        exhibitors: page.catalog?.products?.exhibitors,
        ids: page.catalog?.products?.ids,
        promoCodes: page.catalog?.products?.promoCodes,
        articleCodes: page.catalog?.products?.articleCodes,
        categories: page.catalog?.products?.categories,
        partial: page.catalog?.products?.partial,
        limit: page.catalog?.settings?.limit,
        storeTypes: previewProfile ? storeType ? [storeType] : [] : null,
    };
}

export default observer(function PageCatalog({page, previewProfile}: {page: IPage, previewProfile: string})
{
    useEffect(mainStateReload, []);

    useEffect(() =>
    {
        pageCatalogStore.load(pageCatalogProductsInput(page, previewProfile), true);
    }, [
        page.id,
        page.catalog?.products?.exhibitors?.join(''),
        page.catalog?.products?.ids?.join(''),
        page.catalog?.products?.promoCodes?.join(''),
        page.catalog?.products?.articleCodes?.join(''),
        page.catalog?.products?.categories?.join(''),
        page.catalog?.products?.partial?.map(p => p.id + p.promoCodes.join('')).join(''),
        page.catalog?.settings?.limit,
        previewProfile,
    ]);

    useEffect(() =>
    {
        const handleScroll = () =>
        {
            const scrollPos = document.documentElement.scrollTop;
            const offsetHeight = window.innerHeight;
            const height = document.documentElement.scrollHeight;

            if (scrollPos + offsetHeight >= height - 150 &&
                pageCatalogStore.catalog[page.id] &&
                Date.now() - +pageCatalogStore.catalog[page.id].lastLoadTime > 1000
            )
            {
                pageCatalogStore.load(pageCatalogProductsInput(page, previewProfile));
            }
        };
        window.addEventListener('scroll', handleScroll, true);
        return () =>
        {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, [page, previewProfile]);

    return (
        <div className='PageCatalog row'>
            {pageCatalogStore.catalog[page.id]?.products.map(p =>
                <div key={p.id} className={(page.catalog?.settings?.columns || 2) == 2 ? 'col-md-6' : 'col-12'}>
                    <PageCatalogProduct product={p}/>
                </div>
            )}
        </div>
    );
});

const PageCatalogProduct = observer(function PageCatalogProduct({product}: {product: ICatalogProduct})
{
    const projId = projectStore.id;
    const country = projectStore.selectedCountry;
    const isFRNonFood = projectStore.isFRNonFood;
    const currency = countryCurrency(country);
    const isFair = !projectStore.isPromotion;

    const pid = product.id;

    const ownStores = companyStore.ownStores?.filter(c => product.stores.some(s => s.storeId == c.store.id));
    const [selectedCompany, selectCompany] = useState<string>(ownStores[0]?.id);
    const store = companyStore.companies.find(c => c.id == selectedCompany);
    const storeIndex = ownStores.findIndex(c => c.id == selectedCompany);
    const storeId = store?.store.id;

    const totalCell = (s: ICompany) =>
        country == 'IT' ?
            totalCellIT(s.store.id) :
            country == 'FR' ?
                isFRNonFood || ['MF', 'MI', 'H', 'HF'].includes(s.store.storeType) ?
                    totalCellFR(s.store.id) :
                    null :
                totalCellBase(s.store.id);
    const totalCellBase = (sid: string) => (
        <td key={sid} className='price'>
            {country == 'BE' ? isFair ? 'PV' : 'PVP' : t.reports.total}{' '}
            <b>{user.formatCurrency(
                currency,
                getProductTotal(product.id, product.stores.find(ps => ps.storeId == sid))
            )}</b>
        </td>
    );
    const totalCellFR = (sid: string) =>
    {
        const total = getProductTotalFR(product.id, product.stores.find(ps => ps.storeId == sid));
        if (!total.selling)
        {
            return null;
        }
        return (
            <td key={sid} className='price text-right'>
                {isFRNonFood && <p>{'Px Cession '}<b>{user.formatCurrency(currency, total.invoice)}</b></p>}
                <p>{'PVC '}<b>{user.formatCurrency(currency, total.selling)}</b></p>
            </td>
        );
    };
    const totalCellIT = (sid: string) => (
        <td key={sid} className='price'>
            {'PC '}
            <b>{user.formatCurrency(
                currency,
                getProductTotalIT(product.id, product.stores.find(ps => ps.storeId == sid))
            )}</b>
        </td>
    );

    return (
        <div className='catalog-product'>
            <div className='top'>
                <ProductStarDiscount starDiscount={product.stores.find(s => s.storeId == storeId)?.starDiscount}/>
                <div className='image'>
                    <ProductImage images={product.images}/>
                    <TotalQuantityOverlay product={product} storeId={storeId}/>
                    <LimitedQuantityDateOverlay product={product}/>
                </div>
                <div className='info'>
                    <p className='name'>
                        {(projectStore.canOrder || user.moderator || projectStore.italyHeadquarters) ?
                            <Link to={`/catalog/${product.id}`}>{pickLanguage(product.description)}</Link>
                            :
                            pickLanguage(product.description)
                        }
                    </p>
                    {country == 'FR' ?
                        <>
                            {isFRNonFood && <FRInfoRows product={product} storeId={storeId}/>}
                            <ProductComment product={product} storeId={storeId}/>
                            <FRPriceRow product={product} storeId={storeId}/>
                            {isFRNonFood && <FRProductImage product={product} storeId={storeId}/>}
                            <ExtraRows product={product} storeId={storeId}/>
                            {isFRNonFood && <FRMarketStars product={product} storeId={storeId}/>}
                        </>
                        :
                        <>
                            <InfoRows product={product} storeId={storeId}/>
                            <ExtraRows product={product} storeId={storeId}/>
                            <PriceRows product={product} storeId={storeId}/>
                        </>
                    }
                    <ProductPackage product={product} currency={currency}/>
                    <ProductPackagePrice product={product} currency={currency}/>
                    <ProductLabels product={product} storeId={storeId}/>
                    <ProductOrdered product={product} storeId={storeId}/>
                </div>
            </div>
            {projectStore.canAddToCart && projectStore.canOrder &&
            <div className='bot'>
                <table className='promotions'>
                    <tbody>
                    <tr>
                        <th scope='row'/>
                        {country == 'BE' && <td/>}
                        {country == 'FR' && <td/>}
                        <td className='d-flex align-items-center'>
                            {storeIndex > 0 &&
                            <FA
                                name='chevron-left'
                                className='fas text-secondary cursor-pointer pr-2'
                                onClick={() => selectCompany(ownStores[storeIndex - 1].id)}
                            />}
                            <div className='store-name'>{store?.store.id} {store?.name}</div>
                            {storeIndex < ownStores.length - 1 &&
                            <FA
                                name='chevron-right'
                                className='fas text-secondary cursor-pointer pl-2'
                                onClick={() => selectCompany(ownStores[storeIndex + 1].id)}
                            />}
                        </td>
                    </tr>
                    {product.weeks.map(p =>
                    {
                        const promoCode = p.promoCode;
                        const productStoreWeek = product.stores.find(s => s.storeId == storeId)?.weeks.find(wp => wp.promoCode == promoCode);
                        if (!productStoreWeek)
                        {
                            return null;
                        }
                        const quantity = cartQuantityStore.getQuantityChange(projId, storeId, pid, promoCode) ?? productStoreWeek.quantity;
                        const setQuantity = (q: number) => cartQuantityStore.setQuantity(projId, storeId, pid, promoCode, p.week, q);
                        return (
                            <tr key={promoCode}>
                                {country == 'FR' ?
                                    <th scope='row' className='week'>
                                        {p.week != null && `${['fr', 'it'].includes(user.language) ? 'Sem.' : 'Week'} ${p.week}`}
                                    </th>
                                    :
                                    country != 'PL' ?
                                        <th scope='row' className='week'>{`${['fr', 'it'].includes(user.language) ? 'S' : 'W'}${p.week}`}</th>
                                        :
                                        <th scope='row'>{t.global.quantity}{colon()}</th>
                                }
                                {country == 'BE' &&
                                <td className='promoCode'>
                                    <div>{promoCode}</div>
                                    {!!p.folderType && <div>{p.folderType}</div>}
                                </td>}
                                {country == 'FR' &&
                                <td className='promoCode'>
                                    {p.sousVente ?
                                        <div className='sousVente' style={product.sousVenteSize ? {fontSize: product.sousVenteSize + 'em'} : null}>
                                            {p.sousVente}
                                        </div>
                                        :
                                        productStoreWeek.catalog ?
                                            <div className='catalog'>{productStoreWeek.catalog}</div>
                                            :
                                            <div>{`Livraison ${['fr', 'it'].includes(user.language) ? 'S' : 'W'}${p.week || ''}`}</div>
                                    }
                                    {!!productStoreWeek.message &&
                                    isFRNonFood &&
                                    <div className='message'>{productStoreWeek.message}</div>
                                    }
                                </td>}
                                <td>
                                    <span className='change-amount' onClick={quantity > (productStoreWeek.minQuantity || 0) ? () => setQuantity(quantity - 1) : null}>-</span>
                                    <TextEditable
                                        type='span'
                                        className='amount underlineElement'
                                        content={'' + quantity}
                                        onChangeEnter={q => setQuantity(+q)}
                                    />
                                    <span className='change-amount' onClick={() => setQuantity(quantity + 1)}>+</span>
                                </td>
                            </tr>
                        );
                    })}
                    <tr className='total'>
                        <td/>
                        {country != 'PL' && country != 'IT' && <td/>}
                        {store && totalCell(store)}
                    </tr>
                    </tbody>
                </table>
            </div>}
        </div>
    );
});
