import React from 'react';
import FA from 'react-fontawesome';
import {PostView, PostViewProps} from './PostView';
import {TimelineItem} from '../Timeline/TimelineItem';
import moment from 'moment';
import { user } from '../stores/user';

interface TimelinePostViewProps extends PostViewProps
{
    useTimeMark?: boolean
}

export function TimelinePostView(p: TimelinePostViewProps)
{
    const [preview, setPreview] = React.useState(true);
    return (
        <TimelineItem
            mark={p.useTimeMark ? moment(p.item.date).format('H:mm') : <FA name='newspaper' className='fad'/>}
            markClassName='font-2 h5'
            withLine
        >
            {user.moderator && <div className='preview' onClick={() => setPreview(!preview)}>{preview ? 'Edit' : 'Show preview'}</div>}
            <PostView {...p} preview={preview}/>
        </TimelineItem>
    );
}
