// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.muHp9m_TMbb1FLqcxJWs {
  height: 100vh;
  overflow-y: auto;
  background: #F5F5F5;
}
main .muHp9m_TMbb1FLqcxJWs {
  height: calc(100vh - 60px);
}
.muHp9m_TMbb1FLqcxJWs {
  font-family: Inter, sans-serif;
}

.cy5C9a3YG4DvvymZzsVF {
  position: relative;
}
.jYxtcUgB3APJtVMDkd9Y {
  width: 100%;
  min-height: 60px;
  color: white;
}

.LOtg2ILei4PbJEpOpVrS {
  max-width: min(800px, calc(100% - 20px));
  min-width: min(600px, calc(100% - 20px));
  /*width: max-content;*/
  margin: 50px auto;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.jywcmyQkdQLTOSC5afrp {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
}
.jywcmyQkdQLTOSC5afrp button {
  text-transform: uppercase;
  background:	#D3D3D3;
  color: #000 !important;
  padding: 0.1rem .6rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  margin: 0 3px;
  outline: none;
  box-shadow: none;
}
.jywcmyQkdQLTOSC5afrp button.RzXGh2t4DpVL_gWO9dEO {
  background: #094dad;
  color: #fff !important;
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/components/Mainlayout.module.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,wCAAwC;EACxC,wCAAwC;EACxC,sBAAsB;EACtB,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;EACzB,6CAA6C;EAC7C,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,oBAAoB;EACpB,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,sBAAsB;EACtB,qBAAqB;EACrB,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,sBAAsB;AACxB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');\n\n.container {\n  height: 100vh;\n  overflow-y: auto;\n  background: #F5F5F5;\n}\nmain .container {\n  height: calc(100vh - 60px);\n}\n.container {\n  font-family: Inter, sans-serif;\n}\n\n.bannerContainer {\n  position: relative;\n}\n.banner {\n  width: 100%;\n  min-height: 60px;\n  color: white;\n}\n\n.content {\n  max-width: min(800px, calc(100% - 20px));\n  min-width: min(600px, calc(100% - 20px));\n  /*width: max-content;*/\n  margin: 50px auto;\n  background: #FFFFFF;\n  border: 1px solid #000000;\n  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.25);\n  border-radius: 3px;\n}\n\n.languageButtons {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  padding-bottom: 15px;\n  display: flex;\n  justify-content: center;\n}\n.languageButtons button {\n  text-transform: uppercase;\n  background:\t#D3D3D3;\n  color: #000 !important;\n  padding: 0.1rem .6rem;\n  font-size: 1rem;\n  border: none;\n  border-radius: 5px;\n  margin: 0 3px;\n  outline: none;\n  box-shadow: none;\n}\n.languageButtons button.active {\n  background: #094dad;\n  color: #fff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `muHp9m_TMbb1FLqcxJWs`,
	"bannerContainer": `cy5C9a3YG4DvvymZzsVF`,
	"banner": `jYxtcUgB3APJtVMDkd9Y`,
	"content": `LOtg2ILei4PbJEpOpVrS`,
	"languageButtons": `jywcmyQkdQLTOSC5afrp`,
	"active": `RzXGh2t4DpVL_gWO9dEO`
};
export default ___CSS_LOADER_EXPORT___;
