import {Fragment, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import FA from 'react-fontawesome';
import './FinancialReportPage.scss';
import {Loading} from '../common/Loading';
import {t} from '../translations';
import {user} from '../stores/user';
import {countryCurrency} from '../OrderStand/utility';
import {call, gql} from '../client';
import {IFinancialReportResultStore} from '../../graphql/api/order/Order';

export default observer(function FinancialReportPage()
{
    const [loading, setLoading] = useState(true);
    const [financialReport, setFinancialReport] = useState<IFinancialReportResultStore[]>();
    useEffect(() =>
    {
        loadFinancialReport().then(data =>
        {
            setLoading(false);
            setFinancialReport(data);
        });
    }, []);

    if (loading)
    {
        return (
            <div style={{height: '440px', position: 'relative'}}>
                <Loading/>
            </div>
        );
    }
    if (!financialReport)
    {
        return <h3>{t.global.somethingWentWrong}</h3>;
    }

    const currency = countryCurrency('FR');

    return (
        <div className='FinancialReportPage'>
            <h1>{t.order.financialReport}</h1>
            <a href='/orders' className='d-inline-block'>{t.order.returnToYourOrders}</a>
            {financialReport.map(store =>
            {
                return (
                    <Fragment key={store.id}>
                        <h2 className='mt-4 mb-3'>{store.id} {store.name}</h2>
                        <table className='table'>
                            <thead>
                            <tr>
                                <th>{t.cart.week}</th>
                                <th>Total Px Cession</th>
                                <th>Total PVC</th>
                                <th className='text-center'>{t.order.seeOrders}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {store.orderTotalByWeek.map(w =>
                                <tr key={w.week}>
                                    <td>{w.week}</td>
                                    <td>{user.formatCurrency(currency, w.invoice)}</td>
                                    <td>{user.formatCurrency(currency, w.selling)}</td>
                                    <td className='text-center'>
                                        <Link className='viewWeekButton' to={'/orders?deliveryWeek=' + w.week}>
                                            <FA name='file-invoice-dollar' className='fad'/>
                                        </Link>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </Fragment>
                );
            })}
        </div>
    );
});

function loadFinancialReport()
{
    return call<{ordersFinancialReport: IFinancialReportResultStore[]}>(
        gql`{ordersFinancialReport{id,name,orderTotalByWeek{week,invoice,selling,project}}}`
    ).then(({data}) => data?.ordersFinancialReport);
}
