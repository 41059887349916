import {makeObservable, observable} from 'mobx';
import {companyBadgesCount} from './companyBadgesProxy';

class CompanyBadgesStore
{
    @observable private badges = new Map<string, number>();

    constructor()
    {
        makeObservable(this);
    }

    load(project: string, company: string)
    {
        companyBadgesCount(project, company).then(res =>
        {
            if (res != null)
            {
                this.badges.set(project + company, res);
            }
        });
    }

    find(project: string, company: string)
    {
        return this.badges.get(project + company);
    }
}

export const companyBadgesStore = new CompanyBadgesStore();
