import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {action, makeAutoObservable} from 'mobx';
import {t} from '../../translations';
import {CompanyEventRegistrationHostessSelectItem, companyEventRegistrationHostessSelectList, EventUsersListItem} from '../../Event/eventsProxy';
import DropdownSearch from '../../common/DropdownSearch';

interface Props
{
    project: string
    accountCompanies?: EventUsersListItem['company']
    changed?: string
    onChange(selected: string): void
}

export default observer(function SelectCompanyHostess({project, accountCompanies, changed, onChange}: Props)
{
    useEffect(() =>
    {
        hostessSelectListStore.load(project);
    }, [project]);

    return (
        <>
            <label htmlFor='company'>{t.global.company + '*'}</label>
            <DropdownSearch
                className='mb-3'
                inputId='company'
                selected={accountCompanies ? accountCompanies.map(c => c.id) : changed}
                options={accountCompanies ?
                    accountCompanies.map(c => ({value: c.id, name: c.name})) :
                    hostessSelectListStore.list[project]?.map(c => ({value: c.id, name: c.name})) || []
                }
                disabled={!!accountCompanies}
                onChange={onChange}
            />
        </>
    );
});

class HostessSelectListStore
{
    list: {[project: string]: CompanyEventRegistrationHostessSelectItem[]} = {};

    constructor()
    {
        makeAutoObservable(this);
    }

    load(project: string)
    {
        companyEventRegistrationHostessSelectList(project).then(action(res =>
        {
            if (res)
            {
                this.list[project] = res;
            }
        }));
    }
}

const hostessSelectListStore = new HostessSelectListStore();

