import {action, computed, makeObservable, observable, toJS} from 'mobx';
import {updateCompany, getCompanies, companyAddressFromVat, deleteCompany, getCompanyChats, setCompanyChats, updateCompanyContactInfo} from './CompanyProxy';
import {ICompanyContext} from '../../graphql/api/company/Company';
import {deepSet, IMaybePath, IPath} from '../common/deepSet';
import {assignValues} from '../common/assignValues';
import {user} from '../stores/user';
import {onlyUnique} from '../../lib/common';
import { addProjectToCompany } from '../Prospects/prospectsProxy';
import { projectStore } from '../stores/ProjectStore';
import { getCompanyOrderValidation, updateExhibitorOrderValidation } from '../stores/userProxy';
import {registerGlobalEventHandler} from '../stores/globalEvents';

export interface ICompany extends ICompanyContext
{
    id: string
}

class CompanyStore
{
    @observable companies: ICompany[] = [];
    @observable companyChats: {[companyId: string]: string[]} = {};
    @observable companyOrderValidation: {[companyId: string]: string[]} = {};
    @observable initialLoad = false;
    @observable loading = false;
    @observable selectedId: string;

    constructor()
    {
        makeObservable(this);
        registerGlobalEventHandler('logout', () =>
        {
            this.companies = [];
        });
    }

    @computed get company()
    {
        return this.selectedId && this.companies.find(c => c.id === this.selectedId);
    }

    @action
    select(id: string)
    {
        this.loadCompany([id]);
        this.selectedId = id;
    }

    @computed get ownCompany()
    {
        if (!user.info?.company?.length)
        {
            return null;
        }
        const ownCompanyId = user.info.company[0];
        return this.companies.find(c => c.id === ownCompanyId);
    }

    @computed get ownCompanies()
    {
        return user.info?.company?.map(cid => this.companies.find(c => c.id == cid)) || [];
    }

    @computed get ownStores()
    {
        return this.ownCompanies.filter(c => c?.store?.id && !c.store.blocked);
    }

    @computed get ownCompaniesSectors()
    {
        const companies = this.ownCompanies;
        return companies && [
            ...companies.map(c => c?.exhibitor?.sector?.primary),
            ...companies.map(c => c?.exhibitor?.sector?.secondary)
        ].filter(s => s).filter(onlyUnique);
    }

    @action
    loadCompany(ids?: string[])
    {
        // if (!this.selectedId)
        // {
        //     return;
        // }

        if (ids)
        {
            ids = ids.filter(onlyUnique).filter(c => !this.companies.some(cc => cc.id == c));
            if (!ids.length)
            {
                return;
            }
        }

        this.loading = true;
        getCompanies(ids).then(action('getCompanies', res =>
        {
            this.loading = false;
            if (res)
            {
                if (!this.initialLoad)
                {
                    this.initialLoad = true;
                }
                // if (ids.length == 1)
                // {
                //     this.selectedId = res[0].id;
                // }
                for (const c of res)
                {
                    const existing = this.companies.some(e => e.id == c.id);
                    if (existing)
                    {
                        Object.assign(existing, c);
                    }
                    else
                    {
                        this.companies.push(c);
                    }
                }
            }
        }));
    }

    @action
    update(key: IMaybePath, value)
    {
        const company = this.company;
        const backup = toJS(company);
        deepSet(company, key, value);
        updateCompany(company).then(action('updateCompany', updated =>
        {
            if (!updated)
            {
                assignValues(company, backup);
            }
        }));
    }

    @action
    updateContactInfo(path: IPath, value: string)
    {
        const company = this.company;
        const [kind, contactsKey, index, key] = path;
        if (contactsKey !== 'contacts')
        {
            throw new Error('Invalid path: ' + path);
        }
        const companyInfo = company[kind];
        const backup = toJS(companyInfo.contacts);
        if (!companyInfo.contacts)
        {
            companyInfo.contacts = [];
        }
        if (!companyInfo.contacts[index])
        {
            companyInfo.contacts[index] = {} as any;
        }
        companyInfo.contacts[index][key] = value;
        updateCompanyContactInfo({id: company.id, contacts: companyInfo.contacts}).then(action(updated =>
        {
            if (!updated)
            {
                companyInfo.contacts = backup;
            }
        }));
    }

    delete(id: string)
    {
        return deleteCompany(id).then(action('deleteCompany', err =>
        {
            if (!err)
            {
                this.handleDeleted(id);
            }
            return err;
        }));
    }
    handleDeleted(id: string)
    {
        this.companies = this.companies.filter(c => c.id != id);
    }

    addProject(companyId: string)
    {
        return addProjectToCompany([companyId], projectStore.id).then(action('addProjectToCompany', res =>
        {
            if (res)
            {
                const company = this.companies.find(c => c.id === companyId);
                if (!company.projects)
                {
                    company.projects = [projectStore.baseProject];
                }
                else
                {
                    company.projects.push(projectStore.baseProject);
                }
            }
            return res;
        }));
    }

    @action
    addCode()
    {
        const ex = this.company.exhibitor;
        if (!ex.code)
        {
            ex.code = [];
        }
        ex.code.push('');
    }

    @action
    removeCode(index: number)
    {
        const company = this.company;
        const backup = toJS(company);
        company.exhibitor.code.splice(index, 1);
        updateCompany(company).then(updated =>
        {
            if (!updated)
            {
                assignValues(company, backup);
            }
        });
    }

    @action
    companyAddressFromVat(contact: number)
    {
        const company = this.company;
        companyAddressFromVat({id: this.selectedId || user.info.company[0], contact}).then(updated =>
        {
            if (updated)
            {
                assignValues(company.exhibitor, updated.exhibitor);
            }
        });
    }

    loadCompanyChats(companyId: string)
    {
        getCompanyChats(companyId).then(action(res =>
        {
            this.companyChats[companyId] = res;
        }));
    }

    @action
    updateCompanyChats(companyId: string, personId: string, enabled: boolean)
    {
        if (!this.companyChats[companyId])
        {
            this.companyChats[companyId] = [];
        }
        const copy = toJS(this.companyChats[companyId]);
        if (enabled)
        {
            this.companyChats[companyId].push(personId);
        }
        else
        {
            this.companyChats[companyId] = this.companyChats[companyId].filter(p => p !== personId);
        }
        setCompanyChats(personId, companyId, enabled).then(action(res =>
        {
            if (res == null)
            {
                this.companyChats[companyId] = copy;
            }
        }));
    }

    getCompanyOrderValidation(companyId: string)
    {
        getCompanyOrderValidation(companyId).then(action(res =>
        {
            if (res)
            {
                this.companyOrderValidation[companyId] = res;
            }
        }));
    }

    updateExhibitorOrderValidation(userId: string, companyId: string, enabled: boolean)
    {
        if (!this.companyOrderValidation[companyId])
        {
            this.companyOrderValidation[companyId] = [];
        }
        const copy = toJS(this.companyOrderValidation[companyId]);
        if (enabled)
        {
            this.companyOrderValidation[companyId].push(userId);
        }
        else
        {
            this.companyOrderValidation[companyId] = this.companyOrderValidation[companyId].filter(p => p !== userId);
        }
        updateExhibitorOrderValidation(userId, companyId, enabled).then(action(res =>
        {
            if (res == null)
            {
                this.companyOrderValidation[companyId] = copy;
            }
        }));
    }
}

export const companyStore = new CompanyStore();
