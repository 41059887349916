import {call, gql} from '../client';
import {ILogViewInput} from '../../graphql/api/logView/Log';
import {debounce} from '../common/debounce';

export const debouncedLogView = debounce(logView, 1000);

export function logView(input: ILogViewInput)
{
    input.from = 'web';
    return call(gql`mutation($input:LogViewInput!){logView(input:$input)}`, {input});
}
