// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductLabels {
  font-weight: bold;
  color: #fff;
  margin-top: 10px;
}
.ProductLabels div {
  display: inline-block;
  font-weight: bold;
  border-radius: 5px;
  padding: 4px 15px;
  margin-right: 10px;
  margin-bottom: 15px;
}
.ProductLabels .label-special-orange {
  background-color: #ff6624;
}
.ProductLabels .label-special-green {
  background-color: #92d36e;
}`, "",{"version":3,"sources":["webpack://./frontend/StoreCatalog/ProductLabels.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;EACA,gBAAA;AACF;AAAE;EACE,qBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AAEJ;AAAE;EACE,yBAAA;AAEJ;AAAE;EACE,yBAAA;AAEJ","sourcesContent":[".ProductLabels {\n  font-weight: bold;\n  color: #fff;\n  margin-top: 10px;\n  div {\n    display: inline-block;\n    font-weight: bold;\n    border-radius: 5px;\n    padding: 4px 15px;\n    margin-right: 10px;\n    margin-bottom: 15px;\n  }\n  .label-special-orange {\n    background-color: #ff6624;\n  }\n  .label-special-green {\n    background-color: #92d36e;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
