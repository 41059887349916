import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import WindowListOverlay from './WindowListOverlay';
import {chatStore} from './ChatStore';
import MinimizableChatWindow from './MinimizableChatWindow';
import {user} from '../stores/user';
import {emailStore} from '../Email/EmailStore';
import EmailWindow from '../Email/EmailWindow';
import SupportChatButton from './SupportChatButton';

export default observer(function WindowsOverlay()
{
    const initialLoad = () =>
    {
        // let lastLoad: number;
        // let interval1;
        // let interval2;

        chatStore.loadChatsPreviewMessage();
        chatStore.reloadChatList().then(res =>
        {
            if (res)
            {
                // lastLoad = Date.now();
                chatStore.reloadOpenChats();
                // interval1 = setInterval(() =>
                // {
                //     const minReloadDelay = chatStore.openChatIds.length ? 40000 : user.support || user.moderator ? 80000 : 150000;
                //     if (lastLoad && Date.now() - lastLoad < minReloadDelay)
                //     {
                //         return;
                //     }
                //     lastLoad = Date.now();
                //     chatStore.reloadOpenChats();
                // }, 1000);
                // interval2 = setInterval(() =>
                // {
                //     chatStore.reloadChatList();
                // }, 30000);
            }
            else
            {
                setTimeout(() =>
                {
                    if (user.loggedIn)
                    {
                        initialLoad();
                    }
                }, 5000);
            }
        });
        chatStore.loadChatActivity();

        // return () =>
        // {
        //     clearInterval(interval1);
        //     clearInterval(interval2);
        // };
    };
    useEffect(initialLoad, []);

    return (
        <>
            <WindowListOverlay
                windows={[
                    ...emailStore.open.map(e => <EmailWindow key={e.key} id={e.key} defaultTo={e.to}/>),
                    ...chatStore.openChats.map(chat => <MinimizableChatWindow key={chat.id} chat={chat}/>),
                ]}
            />
            {!user.moderator && <SupportChatButton/>}
        </>
    );
});
