import {action, makeObservable, observable} from 'mobx';
import {
    callAddComment,
    callDeleteComment,
    callEditComment,
    IComment,
    loadComments
} from './commentProxy';
import {chatStore} from '../Chat/ChatStore';

class CommentStore
{
    // comments grouped by targetId
    @observable comments: {[targetId: string]: IComment[]} = {};
    @observable loadingComments = false;

    constructor()
    {
        makeObservable(this);
    }

    reloadComments(targetId: string)
    {
        this.loadingComments = true;
        loadComments(targetId).then(action(res =>
        {
            if (res)
            {
                this.comments[targetId] = res;
            }
            this.loadingComments = false;
        }));
    }

    getComment(id: string)
    {
        for (const targetId of Object.getOwnPropertyNames(this.comments))
        {
            const comment = this.comments[targetId].find(c => c.id == id);
            if (comment)
            {
                return comment;
            }
        }
        return null;
    }

    addComment(targetId: string, text: string, attachments: string[])
    {
        return callAddComment(targetId, text, attachments).then(action((res: IComment) =>
        {
            if (!this.comments[targetId])
            {
                this.comments[targetId] = [res];
            }
            else
            {
                this.comments[targetId].push(res);
            }
        }));
    }

    editComment(id: string, text: string, removeAttachments?: string[], addAttachments?: string[])
    {
        return callEditComment(id, text, removeAttachments, addAttachments).then(async res =>
        {
            if (!res) return;
            const comment = this.getComment(id);
            if (comment)
            {
                action(() =>
                {
                    comment.text = res.text;
                    comment.attachments = res.attachments;
                })();
            }
        });
    }

    deleteComment(id: string)
    {
        callDeleteComment(id).then(action(res =>
        {
            for (const targetId of Object.getOwnPropertyNames(this.comments))
            {
                const index = this.comments[targetId].findIndex(c => c.id == id);
                if (index >= 0)
                {
                    this.comments[targetId].splice(index, 1);
                    break;
                }
            }
            chatStore.targetDeleted(id);
        }));
    }
}

export const commentStore = new CommentStore();
