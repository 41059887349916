// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileBlock {
  position: relative;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}
.FileBlock .text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 10px;
  text-decoration: none;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./frontend/Pages/FileBlock.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,wBAAA;EACA,2BAAA;EACA,4BAAA;EACA,gCAAA;AACF;AAAE;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,qBAAA;EACA,gBAAA;AAEJ","sourcesContent":[".FileBlock {\n  position: relative;\n  height: 100%;\n  background-size: contain;\n  background-position: center;\n  background-repeat: no-repeat;\n  transition: all .3s ease-in-out;\n  .text {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    text-align: center;\n    padding: 0 10px;\n    text-decoration: none;\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
