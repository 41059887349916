import {ICatalogProductStore} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {cartQuantityStore} from './CartQuantityStore';
import {projectStore} from '../stores/ProjectStore';

export function getProductTotal(productId: string, productStore: ICatalogProductStore)
{
    return productStore ? productStore.weeks.reduce((sum, w) => sum +
            (w.price.selling || w.price.invoice) *
            (cartQuantityStore.getQuantityChange(projectStore.id, productStore.storeId, productId, w.promoCode) ?? w.quantity),
        0
    ) : 0;
}

export function getProductTotalFR(productId: string, productStore: ICatalogProductStore)
{
    const total = {
        invoice: 0,
        selling: 0,
    };
    productStore?.weeks.forEach(w =>
    {
        if (w.price.invoice || w.price.selling)
        {
            const quantity = (cartQuantityStore.getQuantityChange(projectStore.id, productStore.storeId, productId, w.promoCode) ?? w.quantity);
            total.invoice += w.price.invoice * quantity;
            total.selling += w.price.selling * quantity;
        }
    });
    return total;
}

export function getProductTotalIT(productId: string, productStore: ICatalogProductStore)
{
    return productStore ? productStore.weeks.reduce((sum, w) => sum +
            w.price.paid *
            (cartQuantityStore.getQuantityChange(projectStore.id, productStore.storeId, productId, w.promoCode) ?? w.quantity),
        0
    ) : 0;
}
