import {useEffect, useMemo, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {action} from 'mobx';
import {observer} from 'mobx-react-lite';
import Swal from 'sweetalert2';
import './ContentBlock.scss';
import DropZone from '../Email/DropZone';
import {Upload, uploadStore} from '../Upload/UploadStore';
import {pageStore} from './PageStore';
import BlockOptions from './BlockOptions';
import {RotationArrows} from './RotationArrows';
import {ITranslations, Language} from '../../graphql/api/Types';
import {user} from '../stores/user';
import {deepSet, IMaybePath} from '../common/deepSet';
import {isID} from '../../lib/common';
import EmptyBlock from './EmptyBlock';
import {pickLanguage} from '../stores/utility';
import {UploadProgressBar} from './UploadProgressBar';
import {ContactSelection} from './ContactSelection';
import {t} from '../translations';
import {useAutoRotate} from './useAutoRotate';
import {chatStore} from '../Chat/ChatStore';
import {uploadAndConvert} from './uploadAndConvert';
import {CommonBlockProps} from './blockMap';
import useReducedFontSize from './useReducedFontSize';
import {simplifyCustomUrl} from '../common/simplifyCustomUrl';
import {BrandImageCreatorState, openBrandImageCreator} from './BrandImageCreator';

export interface ContentBlockProps extends CommonBlockProps
{
    items: IContentBlockItem[]
    contact?: IContactOptions
    preserveOrder?: boolean
}

interface IContentBlockItem
{
    published?: boolean
    text?: {
        display?: boolean
        title?: ITranslations
        verticalAlign?: 'start' | 'center' | 'end'
        horizontalAlign?: 'left' | 'center' | 'right'
        fontSize?: number
        color?: string
    }
    image?: {
        display?: boolean
        url?: ITranslations
        brandImageCreator?: {[L in Language]?: BrandImageCreatorState}
    }
    background?: {
        display?: boolean
        color: string
    }
    link?: {
        active?: boolean
        to?: ITranslations
    }
}

interface IContactOptions
{
    active?: boolean
    chatName?: string
    contacts?: string[]
    companies?: string[]
}

export default observer(function ContentBlock(props: ContentBlockProps & CommonBlockProps)
{
    const {pageId, id, contact, preserveOrder, layout, fullAccess, editAccess, enableBlockOptions, placeholderText, placeholderImage} = props;
    let {items} = props;

    if (!items?.length)
    {
        items = [{}];
    }
    const shuffleMemo = useMemo(() => genShuffleNumbers(items?.length), []);
    if (!enableBlockOptions)
    {
        items = items.filter(i => i.published);
        if (!items.length)
        {
            items = [{}];
        }
        if (!preserveOrder && items.length > 1)
        {
            items = shuffleWith(items, shuffleMemo);
        }
    }

    const [index, setIndex] = useAutoRotate(items?.length, 4000, !enableBlockOptions);
    const current = items[index] || {};

    const [upload, setUpload] = useState<Upload>();
    const image = pickLanguage(current.image?.url);

    const [showOptions, setShowOptions] = useState(false);

    const handleImage = (source: File | Blob, onEnd?: () => void) =>
    {
        let name: string;
        if ((source as File).name)
        {
            name = (source as File).name;
            if (!name.endsWith('.jpg') && !name.endsWith('.webp'))
            {
                name = name.split('.')[0] + '.jpg';
            }
        }
        uploadAndConvert({source, name, dir: 'block/content', onUploadStart: setUpload, onResizeEnd: resizedRes =>
            {
                setUpload(null);
                current.image = current.image || {};
                current.image.display = true;
                current.image.url = current.image.url || {};
                current.image.url[user.language] = resizedRes;
                pageStore.changeItem(pageId, id, 'items', items);
                onEnd?.();
            }
        });
    };

    const imageRef = useRef<HTMLInputElement>();
    const colorRef = useRef<HTMLInputElement>();
    const backgroundColorRef = useRef<HTMLInputElement>();

    const handleText = (path: IMaybePath) => e =>
    {
        deepSet(current, path, e.target.value);
        pageStore.changeItem(pageId, id, 'items', items);
    };
    const handleNumber = (path: IMaybePath) => e =>
    {
        deepSet(current, path, +e.target.value);
        pageStore.changeItem(pageId, id, 'items', items);
    };
    const handleLinkText = e =>
    {
        deepSet(current, ['link', 'to', user.language], simplifyCustomUrl(e.target.value));
        pageStore.changeItem(pageId, id, 'items', items);
    };
    const handleCheckbox = (path: IMaybePath) => e =>
    {
        deepSet(current, path, e.target.checked);
        pageStore.changeItem(pageId, id, 'items', items);
    };

    const textTitle = current.text?.display ? pickLanguage(current.text.title) : null;
    const {textRef, fontSize} = useReducedFontSize({
        textTitle,
        definedFontSize: +current.text?.fontSize ? +current.text.fontSize : 20,
    });
    const textStyle = current.text?.display ? {
        color: current.text?.color || '#111111',
        fontSize: fontSize + 'px',
        textAlign: current.text?.horizontalAlign || 'center',
        justifyContent: current.text?.verticalAlign == 'start' ? 'flex-start' : current.text?.verticalAlign == 'end' ? 'flex-end' : 'center',
    } : null;

    let loadPageId;
    let contentJsx;
    let link = pickLanguage(current.link?.to);
    if (!enableBlockOptions && contact?.active && (contact.contacts?.length || contact.companies?.length))
    {
        contentJsx = <div
            ref={textRef}
            className='text cursor-pointer'
            style={textStyle}
            onClick={async () =>
            {
                if (!chatStore.openContactChat(pageId, id))
                {
                    const result = await Swal.fire({
                        customClass: {
                            title: 'contact-company-title',
                            confirmButton: 'button ml-2',
                            cancelButton: 'button'
                        },
                        buttonsStyling: false,
                        title: t.global.contactFlowButton,
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: t.global.yes,
                        cancelButtonText: t.global.cancel,
                        reverseButtons: true
                    });
                    if (result.value)
                    {
                        const created = await chatStore.createChat({contactButton: {page: pageId, block: id}});
                        if (created)
                        {
                            Swal.fire('OK!', '', 'success');
                        }
                        else
                        {
                            Swal.fire(t.global.chatNoLongerAvailable, '', 'error');
                        }
                    }
                }
            }}
        >
            {textTitle}
        </div>;
    }
    else if (!enableBlockOptions && current.link?.active && link)
    {
        if (isID(link))
        {
            if (pageStore.pages.some(p => p.id == link))
            {
                contentJsx = <Link
                    ref={textRef}
                    to={(location.pathname.startsWith('/page/mobile/') ? '/page/mobile/' : '/page/') + link}
                    className='text'
                    style={textStyle}
                >
                    {textTitle}
                </Link>;
            }
            else
            {
                loadPageId = link;
                contentJsx = <div ref={textRef} className='text' style={textStyle}>{textTitle}</div>;
            }
        }
        else if (link.startsWith('/'))
        {
            contentJsx = <Link ref={textRef} to={link} className='text' style={textStyle}>{textTitle}</Link>;
        }
        else
        {
            if (!link.startsWith('/') && !link.includes('://'))
            {
                link = 'https://' + link;
            }
            contentJsx = <a ref={textRef} href={link} target='_blank' className='text' style={textStyle}>{textTitle}</a>;
        }
    }
    else
    {
        contentJsx = <div ref={textRef} className='text' style={textStyle}>{textTitle}</div>;
    }

    useEffect(() =>
    {
        if (loadPageId)
        {
            pageStore.load(loadPageId, true);
        }
    }, [loadPageId]);

    const page = pageStore.getActivePage(pageId);
    const engagement = page.linkedPagesEngagement?.find(i => i.id == id)?.linkedPageEngagement[index];

    return (
        <div
            tabIndex={0}
            className={'ContentBlock RotationArrows-container' + (image && current.image?.display ? '' : ' border d-flex justify-content-center align-items-center')}
            style={{
                backgroundImage: image && current.image?.display ? `url(${encodeURI(image)})` : null,
                backgroundColor: current.background?.display ? current.background?.color || '#ffffff' : null,
            }}
            onClick={showOptions && current.image?.display ? () => imageRef.current.click() : null}
            onDoubleClick={enableBlockOptions ? () =>
            {
                setShowOptions(true);
                pageStore.focusItem(pageId, id);
            } : null}
            onBlur={enableBlockOptions ? e =>
            {
                // console.log(e.target, e.currentTarget, e.relatedTarget);
                if (e.relatedTarget ?
                    !e.currentTarget.contains(e.relatedTarget as any) :
                    e.currentTarget == e.target || !e.currentTarget.contains(e.target as any)
                )
                {
                    setShowOptions(false);
                }
            } : null}
        >
            <RotationArrows index={index} maxIndex={items?.length ? items.length - 1 : 0} setIndex={setIndex}/>
            {contentJsx}
            {!!engagement && <div className='engagement'>{'Votre engagement ' + engagement}</div>}
            {!!upload && <UploadProgressBar text={upload.ended ? t.editPage.pleaseWait : null} progress={upload.progress}/>}
            {editAccess &&
            !(current.text?.display && pickLanguage(current.text.title)) &&
            !(current.image?.display && pickLanguage(current.image.url)) &&
            !current.background?.display &&
            <EmptyBlock
                layout={layout}
                name={t.editPage.blockTextImageLink}
                text={placeholderText}
                image={placeholderImage}
            />}
            {enableBlockOptions &&
            <DropZone onAttachment={handleImage} onlySingleFile>{' '}</DropZone>}
            {enableBlockOptions &&
            <input ref={imageRef} className='d-none' type='file' accept='image/*' onChange={e => e.target.files.length && handleImage(e.target.files[0])}/>}
            {showOptions &&
            <BlockOptions
                pageId={pageId}
                itemId={id}
                enableBlockOptions={enableBlockOptions}
                fullAccess={fullAccess}
                restrictedOptions={<>
                    <div className='form-check mb-3'>
                        <input
                            id='preserve-order'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!preserveOrder}
                            onChange={e => pageStore.changeItem(pageId, id, 'preserveOrder', e.currentTarget.checked)}
                        />
                        <label htmlFor='preserve-order' className='form-check-label'>Preserve order</label>
                    </div>
                    {(!items?.length || items.length < 2) &&
                    <div className='form-check mb-3'>
                        <input
                            id='contact-active'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!contact?.active}
                            onChange={e => pageStore.changeItem(pageId, id, 'contact', {...contact, active: e.currentTarget.checked})}
                        />
                        <label htmlFor='contact-active' className='form-check-label'>This block is a contact button</label>
                    </div>}
                    {!!contact?.active &&
                    <div>
                        <input
                            type='text'
                            className='form-control mb-3'
                            placeholder='Chat name'
                            value={contact.chatName || ''}
                            onChange={e => pageStore.changeItem(pageId, id, 'contact', {...contact, chatName: e.currentTarget.value})}
                        />
                        <p className='border-bottom mb-1'>Companies administrating the chat</p>
                        <ContactSelection
                            selected={contact.companies || []}
                            only='companies'
                            onChange={a => pageStore.changeItem(pageId, id, 'contact', {...contact, companies: a})}
                        />
                        <p className='border-bottom mb-1'>Persons administrating the chat</p>
                        <ContactSelection
                            selected={contact.contacts || []}
                            only='persons'
                            onChange={a => pageStore.changeItem(pageId, id, 'contact', {...contact, contacts: a})}
                        />
                    </div>}
                </>}
            >
                <div>
                    <div className='form-check form-check-inline mb-3'>
                        <input
                            id='options-text'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!current.text?.display}
                            onChange={handleCheckbox(['text', 'display'])}
                        />
                        <label htmlFor='options-text' className='form-check-label font-weight-bold'>{t.editPage.text}</label>
                    </div>
                    <textarea
                        className='form-control mb-3'
                        value={current.text?.title?.[user.language] || ''}
                        onChange={handleText(['text', 'title', user.language])}
                    />
                    <div className='row no-gutters mb-3'>
                        <div className='col-6 pr-2'>
                            <select className='form-control form-control-sm' value={current.text?.verticalAlign || 'center'} onChange={handleText(['text', 'verticalAlign'])}>
                                <option value='start'>{t.editPage.alignTop}</option>
                                <option value='center'>{t.editPage.alignCenter}</option>
                                <option value='end'>{t.editPage.alignBottom}</option>
                            </select>
                        </div>
                        <div className='col-6 pl-2'>
                            <select className='form-control form-control-sm' value={current.text?.fontSize || 20} onChange={handleNumber(['text', 'fontSize'])}>
                                {!!current.text?.fontSize && !fontSizes.includes(current.text.fontSize) &&
                                <option value={current.text.fontSize}>{current.text.fontSize}</option>
                                }
                                {fontSizes.map(s => <option key={s} value={s}>{s}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className='row no-gutters'>
                        <div className='col-6 pr-2'>
                            <select className='form-control form-control-sm' value={current.text?.horizontalAlign || 'center'} onChange={handleText(['text', 'horizontalAlign'])}>
                                <option value='left'>{t.editPage.alignLeft}</option>
                                <option value='center'>{t.editPage.alignCenter}</option>
                                <option value='right'>{t.editPage.alignRight}</option>
                            </select>
                        </div>
                        <div className='col-6 pl-2'>
                            <input
                                type='text'
                                className='form-control form-control-sm'
                                value={current.text?.color || '#111111'}
                                onChange={handleText(['text', 'color'])}
                                onClick={() => colorRef.current.click()}
                            />
                            <input
                                ref={colorRef}
                                type='color'
                                className='d-none'
                                value={current.text?.color || '#111111'}
                                onChange={handleText(['text', 'color'])}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className='form-check form-check-inline mb-3'>
                        <input
                            id='options-image'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!current.image?.display}
                            onChange={handleCheckbox(['image', 'display'])}
                        />
                        <label htmlFor='options-image' className='form-check-label font-weight-bold'>{t.editPage.image}</label>
                    </div>
                    {fullAccess &&
                    <button
                        className='button text-nowrap px-3 mb-3'
                        onClick={() =>
                        {
                            openBrandImageCreator({
                                initialState: {
                                    ...(current.image?.brandImageCreator?.[user.language] || {}),
                                    width: layout.w,
                                    height: layout.h,
                                },
                                onDone: (blob, brandImageCreatorState) =>
                                {
                                    const brandImageCreatorStateUpdatePromise = (async () =>
                                    {
                                        if (brandImageCreatorState.imageFile)
                                        {
                                            const u = await uploadStore.upload(brandImageCreatorState.imageFile, null, {
                                                dir: 'block/content/brandImageCreator'
                                            });
                                            brandImageCreatorState.imageUrl = u.url;
                                            delete brandImageCreatorState.imageFile;
                                        }
                                        current.image = current.image || {};
                                        current.image.display = true;
                                        current.image.brandImageCreator = current.image.brandImageCreator || {};
                                        current.image.brandImageCreator[user.language] = brandImageCreatorState;
                                        pageStore.changeItem(pageId, id, 'items', items);
                                    })();
                                    handleImage(blob, () =>
                                    {
                                        brandImageCreatorStateUpdatePromise.then(action(() =>
                                        {
                                            pageStore.changeItemWidth(pageId, id, brandImageCreatorState.width);
                                            pageStore.changeItemHeight(pageId, id, brandImageCreatorState.height);
                                        }));
                                    });
                                }}
                            );
                        }}
                    >
                        Brand Image Creator
                    </button>}
                    <div className='form-check form-check-inline'>
                        <input
                            id='options-bgc'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!current.background?.display}
                            onChange={handleCheckbox(['background', 'display'])}
                        />
                        <label htmlFor='options-bgc' className='form-check-label font-weight-bold'>{t.editPage.backgroundColor}</label>
                    </div>
                    <div>
                        <input
                            type='text'
                            className='form-control form-control-sm'
                            value={current.background?.color || '#ffffff'}
                            onChange={handleText(['background', 'color'])}
                            onClick={() => backgroundColorRef.current.click()}
                        />
                        <input
                            ref={backgroundColorRef}
                            type='color'
                            className='d-none'
                            value={current.background?.color || '#ffffff'}
                            onChange={handleText(['background', 'color'])}
                        />
                    </div>
                </div>
                <div>
                    {fullAccess && !contact?.active &&
                    <div className='form-check form-check-inline mb-3'>
                        <input
                            id='options-link'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!current.link?.active}
                            onChange={handleCheckbox(['link', 'active'])}
                        />
                        <label htmlFor='options-link' className='form-check-label font-weight-bold'>Link</label>
                    </div>}
                    {fullAccess && !contact?.active &&
                    <input
                        type='text'
                        className='form-control form-control-sm mb-3'
                        placeholder={t.editPage.addPageLink}
                        value={(isID(current.link?.to?.[user.language]) ?
                                '/page/' + current.link?.to?.[user.language] :
                                (current.link?.to?.[user.language] || '').startsWith('/') ?
                                current.link?.to?.[user.language] :
                                current.link?.to?.[user.language]
                        ) || ''}
                        onChange={handleLinkText}
                    />}
                    <div className='form-check form-check-inline mb-3'>
                        <input
                            id='options-published'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!current.published}
                            onChange={handleCheckbox('published')}
                        />
                        <label htmlFor='options-published' className='form-check-label font-weight-bold'>{t.editPage.published}</label>
                    </div>
                    {(fullAccess || !!props.items?.length) &&
                    <button
                        className='button text-nowrap px-3 mb-3'
                        onClick={() =>
                        {
                            if (props.items?.length)
                            {
                                if (confirm('Are you sure you want to remove currently selected content from this block?'))
                                {
                                    items.splice(index, 1);
                                    if (index > 0)
                                    {
                                        setIndex(index - 1);
                                    }
                                    pageStore.changeItem(pageId, id, 'items', items);
                                }
                            }
                            else
                            {
                                if (confirm('Do you want to delete this block?'))
                                {
                                    pageStore.deleteItem(pageId, id);
                                }
                            }
                        }}
                    >
                        {t.editPage.deleteContent}
                    </button>}
                    {fullAccess && !contact?.active &&
                    <button
                        className='button text-nowrap px-3 mb-3'
                        onClick={() =>
                        {
                            items.push({});
                            setIndex(items.length - 1);
                            pageStore.changeItem(pageId, id, 'items', items);
                        }}
                    >
                        + Add more content
                    </button>}
                </div>
            </BlockOptions>
            }
        </div>
    );
});

export const fontSizes: number[] = [10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 22, 24, 26, 28, 32, 36, 40, 44, 48, 54];

function genShuffleNumbers(num: number)
{
    const a: number[] = [];
    for (let i = num - 1; i > 0; i--)
    {
        a[i] = Math.floor(Math.random() * (i + 1));
    }
    return a;
}

function shuffleWith<T>(a: T[], numbers: number[]): T[]
{
    for (let i = a.length - 1; i > 0; i--)
    {
        const j = numbers[i];
        const x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}
