import {observer} from 'mobx-react-lite';
import {projectStore} from '../stores/ProjectStore';
import {t} from '../translations';
import {ITranslations} from '../../graphql/api/Types';
import {pickLanguage} from '../stores/utility';

interface Props
{
    className?: string
    workshops: {id: string, name: ITranslations}[]
    selected: string
    onSelect: (id: string) => void
}

export default observer(function CartWorkshopSelect({className, workshops, selected, onSelect}: Props)
{
    if (!projectStore.isFRNonFood || !workshops?.length)
    {
        return null;
    }
    return (
        <select
            className={'form-control ' + (className || '')}
            value={selected || ''}
            onChange={e => onSelect(e.currentTarget.value || null)}
        >
            <option value=''>{t.global.workshops} ({t.global.all})</option>
            {workshops.map(w =>
                <option key={w.id} value={w.id}>{pickLanguage(w.name)}</option>
            )}
            {selected && !workshops.some(w => w.id === selected) &&
            <option value={selected}>{selected}</option>
            }
        </select>
    );
});
