import React, {ButtonHTMLAttributes, ReactNode, useState} from 'react';
import {projectStore} from '../stores/ProjectStore';
import {t} from '../translations';
import {LoadingInline} from './Loading';
import useIsMounted from './useIsMounted';

export interface ActionButtonProps
{
    action(project: string): Promise<unknown>
    actionText?: ReactNode
}

export default function ActionButton({action, actionText, children, ...buttonProps}: ActionButtonProps & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>)
{
    const [status, setStatus] = useState<string>();
    const isMounted = useIsMounted();
    return (
        <button
            {...buttonProps}
            onClick={async () =>
            {
                if (status && status != 'failed')
                {
                    return;
                }
                setStatus('ongoing');
                const res = await action(projectStore.id);
                if (isMounted.current)
                {
                    setStatus(res === false ? 'failed' : '');
                }
            }}
        >
            {status === 'failed' ?
                t.global.somethingWentWrong
                :
                status === 'ongoing' ?
                    actionText ?
                        actionText
                        :
                        <>
                            {children}
                            <LoadingInline className='ml-2'/>
                        </>
                    :
                    children
            }
        </button>
    );
}
