import {call, gql} from '../client';
import {ContactSelectionItem} from './ContactSelectionStore';

export function loadContactSelectList(country: string)
{
    return call<{contactSelectList: ContactSelectionItem[]}>(
        gql`query($input:ContactSelectInput!){contactSelectList(input:$input){id,name,email}}`, {input: {country}})
    .then(({data}) => data?.contactSelectList);
}

export function loadCompanySelectList(country: string)
{
    return call<{companySelectList: ContactSelectionItem[]}>(
        gql`query($input:ContactSelectInput!){companySelectList(input:$input){id,name,email}}`, {input: {country}})
    .then(({data}) => data?.companySelectList);
}
