// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YOJ7EmlELyy0i7sfYdx4 {
  margin: 0 0 10px;
  text-decoration: underline;
}
.YOJ7EmlELyy0i7sfYdx4:not(:first-child) {
  margin-top: 30px;
}

.UnymAtUcXFozUyDR_F8X {
  font-size: 14px;
}

.oaQH4dkBA329NZTQN7mN {
  margin-top: 30px;
  border: 1px solid #aaa;
  padding: 20px 30px;
  font-size: 14px;
}
.oRfsMwiBCjblx3jC_zf8 {
  font-weight: bold;
}
.r5idWITS7gydqkplkdAz {
  margin-bottom: 20px;
}
.oaQH4dkBA329NZTQN7mN a {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/views/Participation.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".groupLabel {\n  margin: 0 0 10px;\n  text-decoration: underline;\n}\n.groupLabel:not(:first-child) {\n  margin-top: 30px;\n}\n\n.time {\n  font-size: 14px;\n}\n\n.agreementContainer {\n  margin-top: 30px;\n  border: 1px solid #aaa;\n  padding: 20px 30px;\n  font-size: 14px;\n}\n.agreementTitle {\n  font-weight: bold;\n}\n.agreementText {\n  margin-bottom: 20px;\n}\n.agreementContainer a {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groupLabel": `YOJ7EmlELyy0i7sfYdx4`,
	"time": `UnymAtUcXFozUyDR_F8X`,
	"agreementContainer": `oaQH4dkBA329NZTQN7mN`,
	"agreementTitle": `oRfsMwiBCjblx3jC_zf8`,
	"agreementText": `r5idWITS7gydqkplkdAz`
};
export default ___CSS_LOADER_EXPORT___;
