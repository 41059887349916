import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {NavLink} from 'react-router-dom';
import {handleNavMenu} from './Navbar';
import {user} from '../stores/user';
import {companyStore} from '../Company/CompanyStore';
import {projectStore} from '../stores/ProjectStore';
import {pageStore} from '../Pages/PageStore';
import {eventStore} from '../Event/EventStore';
import {t} from '../translations';
import {pagePath} from '../Pages/utility';
import {pickPageTemplate} from '../Pages/PickPageTemplateStore';
import {canChoosePage} from '../App';
import {pagesStore} from '../Pages/PagesStore';
import {Country} from '../../graphql/api/Types';
import {visualsAccessStore} from './VisualsAccessStore';
import {productsTableCountStore} from './ProductsTableCountStore';

interface IBaseAccess
{
    project?: boolean
    projectIsActive?: boolean
    projectWasActive?: boolean
    admin?: boolean
    organization?: boolean
    notArchived?: boolean
    store?: boolean
    notCarrefour?: boolean
    productsTable?: boolean
    reports?: boolean
    italyHeadquarters?: boolean
    pages?: boolean
    access?: string[] // Profile ids // Prevent everyone (except admin) from accessing it by passing an empty array
    preventAccess?: string[] // Profiles that are not supposed to have access
    fairParticipation?: boolean
    excludeCountry?: Country[]
    visuals?: boolean
}

interface NavGroup extends IBaseAccess
{
    title?: string
    path?: string
    subGroups?: NavSubGroup[]
    links?: LinkInfo[]
    checkIfEmpty?: boolean
    requireProject?: boolean
}

interface NavSubGroup extends Omit<NavGroup, 'subGroups'>
{
    links: LinkInfo[]
}

interface LinkInfo extends IBaseAccess
{
    path: string
    title: string
}

export default observer(function NavMenuMain()
{
    const projectId = projectStore.id;

    useEffect(() =>
    {
        if (!user.moderator)
        {
            pageStore.loadOwnPages();
        }
    }, []);
    const needsToLoadVisualsAccess = !user.moderator &&
        user.info?.profiles?.some(p => ['exhibitorMainAccount', 'exhibitorKeyAccount', 'exhibitorFinance', 'exhibitorInstallator'].includes(p.id));
    useEffect(() =>
    {
        if (projectId && needsToLoadVisualsAccess)
        {
            visualsAccessStore.load(projectId);
        }
    }, [projectId, needsToLoadVisualsAccess]);
    const needsToLoadProductsTableCount = !user.moderator &&
        projectStore.productsTableAccess &&
        user.info?.profiles?.some(p => ['exhibitorMainAccount', 'exhibitorKeyAccount'].includes(p.id));
    useEffect(() =>
    {
        if (projectId && needsToLoadProductsTableCount)
        {
            productsTableCountStore.load(projectId);
        }
    }, [projectId, needsToLoadProductsTableCount]);
    useEffect(() =>
    {
        if (!user.moderator && canChoosePage())
        {
            projectStore.withId(id => pickPageTemplate.load(id));
        }
        if (!user.moderator)
        {
            projectStore.withId(id => pagesStore.checkHasAccess(id));
        }
    }, [projectId]);

    const filterMenu = <T extends IBaseAccess>(node: T[]): T[] =>
    {
        return node.filter(n =>
        {
            if (
                (n.admin && user.admin) ||
                (n.organization && user.moderator) ||
                (n.reports && (user.hqReports || eventStore.hasSideEventReports)) ||
                (
                    (!n.access || (n.access.length && user.info?.profiles?.some(p => n.access.includes(p.id)))) &&
                    (!n.preventAccess || !user.info?.profiles?.some(p => n.preventAccess.includes(p.id))) &&
                    (!n.project || (companyStore.ownCompany?.projects?.length || user.isGuestOrMedia || user.isStore)) &&
                    (!n.projectIsActive || projectStore.isActive) &&
                    (!n.projectWasActive || projectStore.wasActive) &&
                    (!n.notCarrefour || !companyStore.ownCompany?.exhibitor?.carrefour) &&
                    (!n.notArchived || !user.archivedExhibitor) &&
                    (!n.store || (projectStore.selected?.fair.settings.displayCatalog && (
                        projectStore.canOrder ||
                        (n.italyHeadquarters && projectStore.italyHeadquarters)
                    ))) &&
                    (!(n as NavGroup).requireProject || projectStore.selected) &&
                    (!n.productsTable || (projectStore.productsTableAccess && productsTableCountStore.count[projectId])) &&
                    (!n.pages || pagesStore.hasAccess[projectId]) &&
                    (!n.fairParticipation || (eventStore.canSeeFairParticipation && !eventStore.projectEvent?.frNonFood)) &&
                    (!n.excludeCountry?.includes(projectStore.selectedCountry)) &&
                    (!n.visuals || visualsAccessStore.access[projectId])
                )
            )
            {
                if ((n as NavGroup).subGroups)
                {
                    (n as NavGroup).subGroups = filterMenu((n as NavGroup).subGroups);
                }
                else if ((n as NavGroup).links)
                {
                    (n as NavGroup).links = filterMenu((n as NavGroup).links);
                }
                return !(n as NavGroup).checkIfEmpty || (n as NavGroup).subGroups?.length || (n as NavGroup).links?.length;
            }
        });
    };

    const ownPage = !user.moderator ?
        pageStore.pages.find(p => p.project == projectId && p.partialEditAccess?.some(a => user.info?.company?.includes(a))) :
        null;

    const eventUrl = eventStore.projectEvent?.url;
    const eventPath = eventUrl ? '/' + encodeURIComponent(encodeURIComponent(encodeURIComponent(eventUrl))) : '/event/' + projectId;

    const navMenu: NavGroup[] = [
        {
            projectWasActive: user.isStore,
            checkIfEmpty: true,
            subGroups:
                [
                    {
                        title: t.global.navBar.info,
                        checkIfEmpty: true,
                        requireProject: true,
                        notArchived: true,
                        project: true,
                        links:
                            [
                                {path: '/agenda', title: t.global.navBar.agenda},
                                {path: '/help', title: t.global.navBar.help},
                            ]
                    },
                    {
                        title: t.global.navBar.orders,
                        organization: true,
                        checkIfEmpty: true,
                        preventAccess: ['hostess'],
                        links:
                            [
                                {title: t.global.navBar.catalog, path: '/catalog', organization: true, store: true, italyHeadquarters: true, notArchived: true, projectIsActive: true},
                                {title: t.global.navBar.cart, path: '/cart', store: true, notArchived: true},
                                {title: t.global.navBar.orders, path: '/orders', store: true}
                            ]
                    },
                ]
        },
        {
            title: user.isHostess ? null : t.global.navBar.manager,
            organization: true,
            checkIfEmpty: true,
            path: '/dashboard',
            subGroups:
                [
                    {
                        title: t.global.navBar.myConnections,
                        organization: true,
                        checkIfEmpty: true,
                        links:
                            [
                                {path: '/dashboard', title: t.global.profile, organization: true},
                                {path: '/order-stand', title: t.global.navBar.standCreator, project: true, notCarrefour: true, access: ['organization', 'exhibitorMainAccount', 'exhibitorKeyAccount', 'exhibitorFinance', 'exhibitorInstallator']},
                                {path: '/visuals', title: t.global.navBar.visuals, visuals: true, project: true, notCarrefour: true, organization: true, access: ['exhibitorMainAccount', 'exhibitorKeyAccount', 'exhibitorFinance', 'exhibitorInstallator']},
                                {path: '/products', title: t.global.navBar.products, notArchived: true, productsTable: true, organization: true, project: true, access: ['exhibitorMainAccount', 'exhibitorKeyAccount']},
                                {path: '/reports', title: t.global.navBar.reports, reports: true, project: true, organization: true, access: ['exhibitorMainAccount', 'exhibitorKeyAccount', 'hostess'], excludeCountry: ['ES']},
                                {path: '/chat', title: t.global.navBar.chats, organization: true, preventAccess: ['hostess']},
                                {path: eventPath, title: 'Event registration landing page', access: ['hostess']},
                                {path: user.moderator && '/pages/global', title: t.pages.globalPages, organization: true},
                                user.moderator ?
                                    {path: '/pages/project', title: t.pages.projectPages, organization: true} :
                                    {path: '/pages', title: t.global.navBar.pages, organization: true, pages: true},
                                {path: !user.moderator && ownPage ? pagePath(ownPage) : '', title: t.global.navBar.pages},
                                {path: !user.moderator && pickPageTemplate.templateChoices[projectId] ? '/choose-page' : '', title: t.global.navBar.pages},
                                {path: '/fair-participation', title: t.global.navBar.fairParticipation, fairParticipation: true},
                            ]
                    },
                    {
                        title: t.global.navBar.production,
                        organization: true,
                        checkIfEmpty: true,
                        access: [],
                        links:
                            [
                                {path: '/people', title: t.global.people, organization: true, access: []},
                                {path: '/users', title: t.global.navBar.users},
                                {path: '/prospects', title: t.global.navBar.prospects},
                                {path: '/stands', title: t.global.navBar.stands},
                                {path: '/supplier-catalog', title: t.global.navBar.supplierCatalog},
                                {path: '/packages', title: t.global.navBar.packages},
                                {path: '/store-orders', title: t.global.navBar.storeOrders},
                                {path: '/event-registration', title: t.global.navBar.eventSettings},
                                {path: eventStore.projectEvent?.eventRegistration ? eventPath : '', title: 'Event registration landing page'},
                            ]
                    }
                ]
        },
        {
            title: 'Admin',
            organization: true,
            access: [],
            path: '/admin'
        }
    ];

    const filteredNavMenu = filterMenu(navMenu);

    return (
        <>
            {filteredNavMenu.map((group, i) =>
                <div className='cat' key={i + group.title}>
                    {group.path ?
                        <NavLink
                            className='menu-title'
                            to={group.path}
                            title={group.title}
                            onClick={handleNavMenu}
                        >
                            {group.title}
                        </NavLink>
                        :
                        <h6 className='menu-title'>{group.title}</h6>
                    }
                    {group.links ?
                        <NavGroupLinks links={group.links}/> :
                        group.subGroups?.map(subgroup =>
                            <React.Fragment key={subgroup.title}>
                                {subgroup.path ?
                                    <NavLink
                                        className='menu-subtitle'
                                        to={subgroup.path}
                                        title={subgroup.title}
                                        onClick={handleNavMenu}
                                    >
                                        {subgroup.title}
                                    </NavLink>
                                    :
                                    <h6 className='menu-subtitle'>{subgroup.title}</h6>
                                }
                                <NavGroupLinks links={subgroup.links}/>
                            </React.Fragment>
                        )}
                </div>
            )}
        </>
    );
});

const NavGroupLinks = ({links}: {links: LinkInfo[]}) => (
    <ul>
        {links.filter(link => link.path).map(link =>
            <li key={link.path}>
                <NavLink
                    className='NavbarIcon'
                    to={link.path}
                    title={link.title}
                    onClick={handleNavMenu}
                >
                    {link.title}
                </NavLink>
            </li>
        )}
    </ul>
);
