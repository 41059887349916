// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DropZone {
  position: absolute;
  border-radius: 1rem;
  border: 3px dashed #000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000;
  font-size: 2rem;
  font-weight: bold;
}
.DropZone.hidden {
  opacity: 0;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./frontend/Email/DropZone.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,UAAA;EACA,0CAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;AACF;AAAE;EACE,UAAA;EACA,oBAAA;AAEJ","sourcesContent":[".DropZone {\n  position: absolute;\n  border-radius: 1rem;\n  border: 3px dashed #000;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 1;\n  background-color: rgba(255, 255, 255, .5);\n  opacity: .5;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  color: #000;\n  font-size: 2rem;\n  font-weight: bold;\n  &.hidden {\n    opacity: 0;\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
