import type {GraphQLFormattedError} from 'graphql';

export interface GraphQLClientOptions
{
    // Path to the GraphQL API. Defaults to '/graphql'
    path?: string
    // Set the logic for selecting HTTP method.
    // Default behaviour is to use GET for queries and POST for mutations.
    // Queries will fall back to POST if the server returns 414 or 431 status codes.
    httpMethod?: GraphQLHTTPMethod
    // Set the HTTP authorization header.
    authorization?: string
    // Set the credentials for the HTTP request.
    credentials?: RequestCredentials
    // Global fetch() is used by default.
    // When used in Node.js you will have to provide your own fetch() function.
    fetch?: GenericFetch
}

export type GenericFetch = (url: string, init: GenericFetchRequestInit) => Promise<GenericFetchResponse>
export interface GenericFetchRequestInit
{
    method: 'GET' | 'POST'
    headers: Record<string, string>
    body?: string
    credentials: RequestCredentials
}
export interface GenericFetchResponse
{
    readonly status: number
    text(): Promise<string>
}

export enum GraphQLHTTPMethod
{
    // Use GET for queries and POST for mutations.
    // Queries will fall back to POST if the server returns 414 or 431 status codes.
    GET_AND_POST,
    // Use POST for all requests.
    POST,
}

export interface GraphQLResult<T = any>
{
    data: T
    errors: GraphQLFormattedError[]
}

export interface GraphQLRequest
{
    query: string
    variables?: object
    resolve: (result: GraphQLResult) => void
    method: 'GET' | 'POST'
    options?: GraphQLRequestOptions
}

export interface GraphQLRequestOptions
{
    // Disables batching for this request.
    noBatch?: boolean
    // Disables console logging the errors from the GraphQL Response.
    // Errors will still be logged if the server returned an invalid response.
    noErrorLog?: boolean
    // Set the HTTP authorization header for this request.
    // Requests with a different authorization header can not be batched.
    authorization?: string
    // Set the credentials for the HTTP request for this request.
    // Requests with a different credentials option can not be batched.
    credentials?: RequestCredentials
    // Set HTTP method for this request.
    // Default is determined by the client.
    // Requests with a different HTTP method option can not be batched.
    method?: 'GET' | 'POST'
}

const isGraphQLResult = (r): r is GraphQLResult =>
    r &&
    typeof r === 'object' &&
    (
        r.data == null ||
        typeof r.data === 'object'
    ) &&
    (
        r.errors == null ||
        (
            Array.isArray(r.errors) &&
            r.errors.every(e => e && typeof e === 'object' && typeof e.message === 'string')
        )
    );

export class GraphQLClient
{
    path: string;
    httpMethod: GraphQLHTTPMethod;
    authorization: string;
    credentials: RequestCredentials;
    fetch: GenericFetch;
    interceptResult?: (res: GraphQLResult, req: GraphQLRequest, duration: number | undefined) => GraphQLResult;

    private callQueue: GraphQLRequest[] = [];
    private callTimeout;

    constructor(options?: GraphQLClientOptions)
    {
        options = options || {};
        this.path = options.path || '/graphql';
        this.httpMethod = options.httpMethod || GraphQLHTTPMethod.GET_AND_POST;
        this.authorization = options.authorization || undefined;
        this.credentials = options.credentials || undefined;
        this.fetch = options.fetch || undefined;
    }

    call = <T = any>(query: string, variables?: object, options?: GraphQLRequestOptions): Promise<GraphQLResult<T>> =>
    {
        const method: 'GET' | 'POST' = options?.method ||
            (
                this.httpMethod === GraphQLHTTPMethod.GET_AND_POST ?
                    /(^|[\s}])mutation[\s({]/.test(query) ?
                        'POST'
                        :
                        'GET'
                    :
                    'POST'
            );
        // console.error('GraphQL Request:', query, variables); // debug info
        if (options?.noBatch)
        {
            return new Promise<GraphQLResult<T>>((resolve) =>
            {
                // console.log('GraphQL Request:', query, variables); // debug info
                this.execCall([{query, variables, resolve, method, options}], method, options?.authorization, options?.credentials);
            });
        }
        return new Promise<GraphQLResult<T>>((resolve) =>
        {
            // console.log('GraphQL Request:', query, variables); // debug info
            this.callQueue.push({query, variables, resolve, method, options});
            clearTimeout(this.callTimeout);
            this.callTimeout = setTimeout(this.execTimeout, 0);
        });
    };

    private execTimeout = () =>
    {
        this.callTimeout = null;
        const callQueue = this.callQueue;
        this.callQueue = [];
        if (callQueue.length == 1)
        {
            this.execCall(callQueue, callQueue[0].method, callQueue[0].options?.authorization, callQueue[0].options?.credentials);
        }
        else
        {
            if (callQueue.some(i => i.method !== callQueue[0].method || i.options?.authorization !== undefined || i.options?.credentials !== undefined))
            {
                const groups: {
                    callQueue: GraphQLRequest[]
                    method: 'GET' | 'POST'
                    authorization: string
                    credentials: RequestCredentials
                }[] = [];
                for (const i of callQueue)
                {
                    const method = i.method;
                    const authorization = i.options?.authorization;
                    const credentials = i.options?.credentials;
                    const group = groups.find(g => g.method === method && g.authorization === authorization && g.credentials === credentials);
                    if (!group)
                    {
                        groups.push({
                            callQueue: [i],
                            method,
                            authorization,
                            credentials,
                        });
                    }
                    else
                    {
                        group.callQueue.push(i);
                    }
                }
                for (const group of groups)
                {
                    this.execCall(group.callQueue, group.method, group.authorization, group.credentials);
                }
            }
            else
            {
                this.execCall(callQueue, callQueue[0].method);
            }
        }
    };

    private execCall(callQueue: GraphQLRequest[], method: 'GET' | 'POST', authorization?: string, credentials?: RequestCredentials)
    {
        const headers: Record<string, string> = {
            accept: 'application/json',
        };
        if (method !== 'GET')
        {
            headers['content-type'] = 'application/json';
        }
        if (authorization === undefined)
        {
            authorization = this.authorization;
        }
        if (authorization)
        {
            headers.authorization = authorization;
        }
        if (credentials === undefined && this.credentials !== undefined)
        {
            credentials = this.credentials;
        }
        const usedFetch = this.fetch || fetch;
        const startTime = Date.now();
        let duration: number;
        (
            method === 'GET' ?
                usedFetch(
                    this.path + '?' + (
                        callQueue.some(q => q.variables) ?
                            callQueue.map(({query, variables}) => new URLSearchParams({query, variables: variables ? JSON.stringify(variables) : ''})).join('&')
                            :
                            callQueue.map(({query}) => new URLSearchParams({query})).join('&')
                    ),
                    {
                        method: 'GET',
                        headers,
                        credentials,
                    }
                )
                :
                usedFetch(
                    this.path,
                    {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(
                            callQueue.length > 1 ?
                                callQueue.map(({query, variables}) => variables ? {query, variables} : {query})
                                :
                                callQueue[0].variables ? {query: callQueue[0].query, variables: callQueue[0].variables} : {query: callQueue[0].query}
                        ),
                        credentials,
                    }
                )
        )
            .then(res =>
            {
                if ((res.status === 414 || res.status === 431) && method === 'GET')
                {
                    for (const item of callQueue)
                    {
                        item.method = 'POST';
                    }
                    this.execCall(callQueue, 'POST', authorization, credentials);
                    return;
                }
                // console.log('GraphQL Response:', res); // debug info
                return res.text().then(text =>
                {
                    duration = Date.now() - startTime;
                    // console.log('Request:', callQueue.map(({query, variables}) => variables ? {query, variables} : {query}),
                    //     '\nSize:', text.length / 1000000); // debug info
                    let resultData: GraphQLResult | GraphQLResult[];
                    try
                    {
                        resultData = JSON.parse(text);
                    }
                    catch (e)
                    {
                        console.log('Invalid GraphQL response JSON:\n' + text); // debug info
                        for (const item of callQueue)
                        {
                            const r: GraphQLResult = {data: null, errors: [{message: 'Invalid GraphQL response JSON'}]};
                            item.resolve(this.interceptResult ? this.interceptResult(r, item, duration) : r);
                        }
                        return;
                    }
                    const result = Array.isArray(resultData) ? resultData : [resultData];
                    if (result.length !== callQueue.length || !result.every(isGraphQLResult))
                    {
                        console.log('Invalid GraphQL response format:', resultData, '\nOriginal Request:',
                            callQueue.map(({query, variables}) => variables ? {query, variables} : {query})); // debug info
                        for (const item of callQueue)
                        {
                            const r: GraphQLResult = {data: null, errors: [{message: 'Invalid GraphQL response format'}]};
                            item.resolve(this.interceptResult ? this.interceptResult(r, item, duration) : r);
                        }
                        return;
                    }

                    // console.log('GraphQL Result:', result); // debug info
                    for (let i = 0; i < callQueue.length; ++i)
                    {
                        const r = result[i];
                        const item = callQueue[i];
                        if (r.errors && !item.options?.noErrorLog)
                        {
                            console.log('GraphQL Result Errors:', r.errors, '\nQuery:', item.query, '\nVariables:', item.variables); // debug info
                        }
                        item.resolve(this.interceptResult ? this.interceptResult(r, item, duration) : r);
                    }
                });
            })
            .catch((e) =>
            {
                if (duration == null)
                {
                    duration = Date.now() - startTime;
                }
                console.warn('GraphQL Client Error:', e);
                for (const item of callQueue)
                {
                    const r: GraphQLResult = {data: null, errors: [e]};
                    item.resolve(this.interceptResult ? this.interceptResult(r, item, duration) : r);
                }
            });
    }
}

const optimizedQueries: { [key: string]: string } = {};

export const optimiseQuery = (query: string): string =>
{
    if (!optimizedQueries[query])
    {
        optimizedQueries[query] = query
            .split('\n')
            .map(line =>
            {
                line = line.trim();
                if (line.endsWith('{'))
                {
                    line = line.substring(0, line.length - 1).trim() + '{';
                }
                return line;
            })
            .join('\n').trim();
    }
    return optimizedQueries[query];
};

export const gql = (parts: TemplateStringsArray) => optimiseQuery(String.raw(parts));
