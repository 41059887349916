import {user} from '../frontend/stores/user';
import {projectStore} from '../frontend/stores/ProjectStore';
import {eventCountryRef} from '../frontend/EventRegistrationForm/EventRegistrationFormStore';
import {registerStore} from '../frontend/Landing/RegisterStore';
import type {ITranslations} from '../graphql/api/Types';

type Translated<T> = T extends ITranslations ? string :
    T extends ((...args: infer P) => any) ? ((...args: P) => string) :
    {[K in keyof T]: Translated<T[K]>}

export function makeTranslation<T>(obj: T): Translated<T>
{
    if (!obj) return;
    const out = {} as Translated<T>;
    for (const key of Object.getOwnPropertyNames(obj))
    {
        const val = obj[key];
        if (val && typeof val === 'object')
        {
            if ('en' in val)
            {
                Object.defineProperty(out, key, {
                    get()
                    {
                        const country = user.loggedIn ?
                            projectStore.selectedCountry || eventCountryRef.current :
                            eventCountryRef.current || registerStore.country;
                        const language = user.language;
                        return (
                                country ?
                                    val[language + country] ||
                                    val[language] ||
                                    val['en' + country]
                                    :
                                    val[language]
                            ) ||
                            val.en ||
                            '';
                    },
                });
            }
            else
            {
                out[key] = makeTranslation(val);
            }
        }
        else if (typeof val === 'function')
        {
            out[key] = function wrapped()
            {
                const res = val.apply(this, arguments);
                return res[user.language] || res.en || '';
            };
        }
    }
    return out;
}
