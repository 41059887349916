import {makeAutoObservable} from 'mobx';
import {call, gql} from '../client';

function loadCarrefourCompanyNamesByCountry()
{
    return call<{carrefourCompanies: CarrefourCompanyItem[]}>(gql`{carrefourCompanies{id,country,name}}`)
    .then(({data}) => data?.carrefourCompanies);
}

interface CarrefourCompanyItem
{
    id: string
    country: string[]
    name: string
}

class CarrefourCompanyStore
{
    companies: CarrefourCompanyItem[] = [];

    constructor()
    {
        makeAutoObservable(this);
    }

    load()
    {
        loadCarrefourCompanyNamesByCountry().then(companies =>
        {
            if (companies)
            {
                this.companies = companies;
            }
        });
    }
}

export const carrefourCompanyStore = new CarrefourCompanyStore();
