import {Language} from '../../graphql/api/Types';

export const languageList: Language[] = [
    'en',
    'fr',
    'nl',
    'pl',
    'it',
    'es',
];

export const languageNames: {[language in Language]: string} = {
    en: 'English',
    fr: 'Français',
    nl: 'Nederlands',
    pl: 'Polski',
    it: 'Italiano',
    es: 'Español',
};
