import {call, gql} from '../client';
import {IEmailData} from '../../graphql/api/email/Email';

export function loadEmails(related: string)
{
    return call<{emails: IEmailData[]}>(
        gql`query($related:ID!){emails(related:$related){id,sender{id,firstName,lastName,email},receivers,subject,html,date,attachments{name,key},inReplyTo}}`, {related}
    ).then(({data}) =>
    {
        if (!data) return null;
        for (const email of data.emails)
        {
            email.date = new Date(email.date);
        }
        return data.emails;
    });
}

export function loadSentEmails()
{
    return call<{sentEmails: IEmailData[]}>(
        gql`{sentEmails{id,sender{id,firstName,lastName,email},receivers,subject,html,date,attachments{name,key},inReplyTo}}`
    ).then(({data}) =>
    {
        if (!data) return null;
        for (const email of data.sentEmails)
        {
            email.date = new Date(email.date);
        }
        return data.sentEmails;
    });
}

export function callSendEmail(to: string[], subject: string, html: string, attachments: string[], inReplyTo?: string): Promise<IEmailData>
{
    return call<{sendEmail: IEmailData}>(
        gql`mutation($input:EmailInput!){sendEmail(input:$input){id,sender{id,firstName,lastName,email},receivers,subject,html,date,attachments{name,key},inReplyTo}}`,
        {input: {to, subject, html, attachments, inReplyTo}}
    ).then(({data}) =>
    {
        if (!data) return null;
        const email = data.sendEmail;
        email.date = new Date(email.date);
        return email;
    });
}
