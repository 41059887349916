// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalQuantityOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  padding: 0 1rem;
}
.TotalQuantityOverlay.large {
  font-size: 2rem;
}
.TotalQuantityOverlay.small {
  font-size: 1.1rem;
}
@media (max-width: 440px) {
  .TotalQuantityOverlay.small {
    font-size: 0.8rem !important;
  }
}
@media (max-width: 370px) {
  .TotalQuantityOverlay.small {
    font-size: 0.6rem !important;
  }
}`, "",{"version":3,"sources":["webpack://./frontend/StoreCatalog/TotalQuantityOverlay.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,oCAAA;EACA,cAAA;EACA,eAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,iBAAA;AAEJ;AADI;EAFF;IAII,4BAAA;EAGJ;AACF;AAFI;EANF;IAQI,4BAAA;EAIJ;AACF","sourcesContent":[".TotalQuantityOverlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  background-color: rgba(0, 0, 0, 0.4);\n  color: #ffffff;\n  padding: 0 1rem;\n  &.large {\n    font-size: 2rem;\n  }\n  &.small {\n    font-size: 1.1rem;\n    @media (max-width: 440px)\n    {\n      font-size: .8rem !important;\n    }\n    @media (max-width: 370px)\n    {\n      font-size: .6rem !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
