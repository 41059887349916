// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V91JrzyMvsqPBjsPFgjk {
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #2169CA;
  color: #000;
  cursor: pointer;
  font-size: 18px;
}

/*.account:hover {*/
/*  background-color: #3d8af7;*/
/*  color: #fff;*/
/*}*/

.Kp4ogn3PZYauu0pstlty {
  float: right;
  font-style: italic;
  color: #969CA0;
}

.cr8f58CNzLXcXxir7MsI {
  margin-top: 20px;
}
.DMMDGyEeoEcqqh_bZkXn {
  color: #2169CA;
  font-weight: bold;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/views/SelectAccount.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA,mBAAmB;AACnB,+BAA+B;AAC/B,iBAAiB;AACjB,IAAI;;AAEJ;EACE,YAAY;EACZ,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".account {\n  margin: 10px 0;\n  padding: 10px;\n  border-radius: 3px;\n  border: 1px solid #2169CA;\n  color: #000;\n  cursor: pointer;\n  font-size: 18px;\n}\n\n/*.account:hover {*/\n/*  background-color: #3d8af7;*/\n/*  color: #fff;*/\n/*}*/\n\n.accountProfile {\n  float: right;\n  font-style: italic;\n  color: #969CA0;\n}\n\n.registerLine {\n  margin-top: 20px;\n}\n.registerButton {\n  color: #2169CA;\n  font-weight: bold;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"account": `V91JrzyMvsqPBjsPFgjk`,
	"accountProfile": `Kp4ogn3PZYauu0pstlty`,
	"registerLine": `cr8f58CNzLXcXxir7MsI`,
	"registerButton": `DMMDGyEeoEcqqh_bZkXn`
};
export default ___CSS_LOADER_EXPORT___;
