import {Fragment, useState} from 'react';
import moment from 'moment/moment';
import {GraphQLFormattedError} from 'graphql';
import Swal from 'sweetalert2';
import styles from './Participation.module.css';
import Form from '../components/Form';
import Button from '../components/Button';
import FormCheckbox from '../components/FormCheckbox';
import {IUserRegistrationEvent} from '../EventRegistrationFormStore';
import {sameUTCDay} from '../../../lib/common';
import {pickLanguage} from '../../stores/utility';
import {t} from '../../translations';
import {EventRegistrationInput_v2, SideEvent} from '../../../graphql/api/event/Event';
import {IBadgeSideEvent} from '../../../graphql/api/user/User';
import {user} from '../../stores/user';
import RemainingBadges from '../components/RemainingBadges';
import {EventUsersListItem} from '../../Event/eventsProxy';
import erStyles from './EventRegistration.module.css';
import {LoadingInline} from '../../common/Loading';

interface Props
{
    event: IUserRegistrationEvent
    account: EventUsersListItem
    reloadStatusesForUser: () => void
    onBack: () => void
    onRegister: (info: Omit<EventRegistrationInput_v2, 'project' | 'id' | 'registrationId'>) => Promise<GraphQLFormattedError[]>
}

export default function Participation({event, account, reloadStatusesForUser, onBack, onRegister}: Props)
{
    const project = event.project;
    const guestOf = false;

    const profile = guestOf ? 'guest' : account.profile || '';
    const isInvitedGuest = profile === 'invitedGuest';
    const isExhibitor = profile.includes('exhibitor');

    const badge = account.badges?.find(b => b.project == project);
    const company = account.company?.length ? account.company[0].id : account.companyName || null;
    const stores = account.company.filter(c => c.storeId).map(c => ({
        id: c.storeId,
        name: c.name,
        storeType: c.storeType,
        territory: c.territory,
    }));

    const [fairDates, setFairDates] = useState<Date[]>(() => badge?.days || []);
    const [parking, setParking] = useState<Date[]>(() => badge?.parking || []);
    const [sideEvents, setSideEvents] = useState<IBadgeSideEvent[]>(() => badge?.sideEvents || []);
    const [terms, setTerms] = useState(!!badge);
    const [sendConfirmation, setSendConfirmation] = useState(false);

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<string>();

    const isGuestWithCode = profile == 'guest' &&
        (isInvitedGuest || sideEvents?.some(s => s.invitationCode)) &&
        !user.isModeratorOrHostess;

    const invitationCodeDate = null;
    const invitationCodeRef = null;

    const handleFairDate = (fairDate: Date) =>
    {
        if (event.frNonFood)
        {
            // max 1 date
            setFairDates(sameUTCDay(fairDates[0], fairDate) ? [] : [fairDate]);
        }
        else
        {
            const index = fairDates.findIndex(d => sameUTCDay(d, fairDate));
            const fairDatesCopy = fairDates.slice();
            if (index >= 0)
            {
                fairDatesCopy.splice(index, 1);
                const indexP = parking.findIndex(d => sameUTCDay(d, fairDate));
                if (indexP >= 0)
                {
                    const parkingCopy = parking.slice();
                    parkingCopy.splice(indexP, 1);
                    setParking(parkingCopy);
                }
            }
            else
            {
                fairDatesCopy.push(fairDate);
            }
            setFairDates(fairDatesCopy);
        }
    };

    const handleFairDateParking = (fairDate: Date) =>
    {
        const parkingCopy = parking.slice();
        const index = parkingCopy.findIndex(d => sameUTCDay(d, fairDate));
        if (index >= 0)
        {
            parkingCopy.splice(index, 1);
        }
        else
        {
            parkingCopy.push(fairDate);
        }
        setParking(parkingCopy);
    };

    const handleSideEvent = (sideEvent: SideEvent) =>
    {
        const index = sideEvents.findIndex(s => s.reference === sideEvent.reference);
        let sideEventsCopy = sideEvents.slice();
        if (index >= 0)
        {
            sideEventsCopy.splice(index, 1);
        }
        else
        {
            if (sideEvent.group)
            {
                sideEventsCopy = sideEventsCopy.filter(a => event.sideEvents.find(b => a.reference == b.reference).group != sideEvent.group);
            }
            sideEventsCopy.push({date: sideEvent.date, reference: sideEvent.reference});
        }
        setSideEvents(sideEventsCopy);
    };

    const handleRegister = async () =>
    {
        if (!fairDates.length)
        {
            setError(t.eventRegistration.atLeast1Day);
            return;
        }
        if (!terms)
        {
            setError(t.registration.form.tosNotif);
            return;
        }
        const filteredSideEvents = sideEvents.filter(se => fairDates.some(d => sameUTCDay(se.date, d)));
        setSubmitting(true);
        const errors = await onRegister({
            fairDates,
            parking,
            sideEvents: filteredSideEvents,
            sendConfirmation,
        });
        setSubmitting(false);
        if (errors)
        {
            const e = errors[0];
            switch (e.message)
            {
                case 'no-badges-left':
                    Swal.fire({
                        title: t.fairParticipation.noBadgesAvailable,
                        text: t.fairParticipation.buyMoreBadges,
                        icon: 'error',
                    });
                    break;
                case 'no-capacity':
                {
                    const dates = e.extensions?.dates as string[];
                    if (dates)
                    {
                        setFairDates(prev => prev.filter(a => !dates.some(b => sameUTCDay(a, b))));
                        setSideEvents(prev => prev.filter(s => !dates.some(b => sameUTCDay(s.date, b))));
                        for (const d of dates)
                        {
                            const ss = event.dateStatusesForUser?.find(s => sameUTCDay(s.date, d));
                            if (ss)
                            {
                                ss.full = true;
                            }
                        }
                        reloadStatusesForUser();
                        Swal.fire({
                            title: t.registration.form.errors.mainEventFull(
                                dates.map(d => moment(d).format('DD.MM.YYYY.')).join(', ')
                            ),
                            icon: 'error',
                        });
                    }
                    const references = e.extensions?.references as string[];
                    if (references)
                    {
                        setSideEvents(prev => prev.filter(s => !references.includes(s.reference)));
                        for (const reference of references)
                        {
                            const ss = event.sideEventStatusesForUser?.find(s => s.reference == reference);
                            if (ss)
                            {
                                ss.full = true;
                            }
                        }
                        reloadStatusesForUser();
                        Swal.fire({
                            title: t.registration.form.errors.sideEventFull(
                                references.map(reference => `"${pickLanguage(event.sideEvents.find(s => s.reference == reference)?.name)}"`).join(', ')
                            ),
                            icon: 'error',
                        });
                    }
                    break;
                }
                case 'already-assigned-parking':
                {
                    const alreadyAssignedParking = e.extensions.alreadyAssignedParking as string[];
                    setParking(prev => prev.filter(a => !alreadyAssignedParking.some(b => sameUTCDay(a, b))));
                    Swal.fire({
                        title: `${t.registration.form.errors.parkingAlreadyReserved}: ${
                            alreadyAssignedParking.map(d => moment(d).format('DD.MM.YYYY.')).join(', ')
                        }`,
                        icon: 'error',
                    });
                    break;
                }
                case 'not-verified':
                    Swal.fire({
                        title: 'Email and Phone are not verified',
                        icon: 'error',
                    });
                    break;
                case 'store-registration-limit':
                    Swal.fire({
                        html: 'Vous avez atteint le nombre d’inscriptions maximum. Si vous souhaitez néanmoins une ou plusieurs autres personnes, merci d’en faire la demande soit auprès de votre assistante (direction régionale) à laquelle vous êtes rattaché soit directement par mail - <a href="mailto:c.delsaute@d-sidegroup.com">c.delsaute@d-sidegroup.com</a>.',
                        icon: 'error',
                    });
                    break;
            }
        }
    };

    return (
        <>
            <div className={erStyles.title}>
                <img className={erStyles.icon} src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/participation.svg' alt=''/>
                <h2>{t.eventRegistration.yourParticipation}</h2>
            </div>
            <Form className={erStyles.content} onSubmit={handleRegister}>
                {isExhibitor && company &&
                    <RemainingBadges
                        project={project}
                        companyId={company}
                        userId={account.id}
                        selectedCount={(invitationCodeDate ? fairDates.filter(d => !sameUTCDay(d, invitationCodeDate)) : fairDates).length}
                    />
                }
                <div className={styles.groupLabel}>{t.eventRegistration.iWillBePresentOn}</div>
                {event.fairDates?.filter(d =>
                    isGuestWithCode ?
                        sameUTCDay(d.date, invitationCodeDate)
                        :
                        (
                            !event.dateStatusesForUser ||
                            !event.dateStatusesForUser.find(ds => sameUTCDay(ds.date, d.date))?.full
                        ) && (
                            user.isModeratorOrHostess ||
                            d.profileAccess?.includes(profile) ||
                            d.regionStoreTypeAccess?.some(rp =>
                                stores?.some(rs =>
                                    rp.territory?.toUpperCase() == rs.territory?.toUpperCase() &&
                                    rp.storeType == rs.storeType
                                )
                            ) ||
                            d.contactAccess?.includes(company) ||
                            d.contactAccess?.includes(account.id)
                        )
                ).map(d =>
                {
                    const checkedDate = fairDates.some(fd => sameUTCDay(fd, d.date));
                    return (
                        <Fragment key={d.date + ''}>
                            <FormCheckbox
                                label={pickLanguage(d.name) || moment(d.date).format('D MMMM')}
                                checked={checkedDate}
                                onChangeChecked={isGuestWithCode ? null : () => handleFairDate(d.date)}
                            />
                            {event.country == 'IT' && isExhibitor && checkedDate &&
                                <FormCheckbox
                                    label={t.registration.form.iWantParking}
                                    checked={parking.some(fd => sameUTCDay(fd, d.date))}
                                    onChangeChecked={() => handleFairDateParking(d.date)}
                                />}
                        </Fragment>
                    );
                })}
                {(() =>
                {
                    const eventSideEvents = event.sideEvents?.filter(se =>
                        isGuestWithCode ?
                            se.reference == invitationCodeRef
                            :
                            (
                                !event.dateStatusesForUser ||
                                !event.dateStatusesForUser.find(ds => sameUTCDay(ds.date, se.date))?.full
                            ) &&
                            se.reference &&
                            (
                                user.isModeratorOrHostess ||
                                se.profileAccess?.includes(profile) ||
                                se.contactAccess?.includes(company) ||
                                se.contactAccess?.includes(account.id)
                            ) &&
                            (
                                fairDates.some(fd => sameUTCDay(fd, se.date)) ||
                                !event.fairDates.some(d => sameUTCDay(d.date, se.date))
                            )
                    );
                    if (!eventSideEvents?.length)
                    {
                        return null;
                    }
                    const groups: {date: Date, sideEvents: SideEvent[]}[] = [];
                    for (const se of eventSideEvents)
                    {
                        const e = groups.find(g => sameUTCDay(g.date, se.date));
                        if (e)
                        {
                            e.sideEvents.push(se);
                        }
                        else
                        {
                            groups.push({date: se.date, sideEvents: [se]});
                        }
                    }
                    return groups.map(g =>
                        <Fragment key={g.date + ''}>
                            <div className={styles.groupLabel}>{t.eventRegistration.yourWorkshops} {moment(g.date).format('D MMMM')}:</div>
                            {g.sideEvents.map(se =>
                                <FormCheckbox
                                    key={se.reference}
                                    label={<><div>{pickLanguage(se.name)}</div><div className={styles.time}>{moment(g.date).format('H:mm')}</div></>}
                                    checked={sideEvents.some(c => c.reference === se.reference)}
                                    onChangeChecked={isGuestWithCode ? null : () => handleSideEvent(se)}
                                />
                            )}
                        </Fragment>
                    );
                })()}
                <div className={styles.agreementContainer}>
                    <div className={styles.agreementTitle}>
                        {t.eventRegistration.letsConnectPlatform}
                    </div>
                    <div className={styles.agreementText}>
                        {t.eventRegistration.agreementLabel}
                    </div>
                    <FormCheckbox
                        label={
                            <>
                                <div>{t.registration.form.tos_label}</div>
                                <div dangerouslySetInnerHTML={{__html: t.registration.form.tos_link}}/>
                                <div dangerouslySetInnerHTML={{__html: t.registration.form.personalData}}/>
                            </>
                        }
                        labelColor={false}
                        checked={terms}
                        onChangeChecked={setTerms}
                    />
                </div>
                {user.isModeratorOrHostess &&
                    <FormCheckbox
                        className='mt-4'
                        label={t.eventPage.sendConfEmail}
                        labelColor={false}
                        checked={sendConfirmation}
                        onChangeChecked={setSendConfirmation}
                    />
                }
                <div className='text-danger'>{error}</div>
                <div className={erStyles.buttons}>
                    <Button onClick={onBack}>
                        <img className='mr-2' src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/back.svg' alt=''/>
                        {t.eventRegistration.back}
                    </Button>
                    <Button type='submit' primary className='d-flex justify-content-center align-items-center'>
                        {badge ? t.eventRegistration.updateParticipation : t.eventRegistration.participate}
                        {submitting && <LoadingInline className='ml-2'/>}
                    </Button>
                </div>
            </Form>
        </>
    );
}
