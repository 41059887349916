import {PropsWithChildren} from 'react';
import styles from './Mainlayout.module.css';
import {user} from '../../stores/user';
import {Language} from '../../../graphql/api/Types';

type Props = PropsWithChildren<{image: string, languages: Language[], onChangeLanguage: (language: Language) => void}>

export default function MainLayout({image, children, languages, onChangeLanguage}: Props)
{
    return (
        <div className={styles.container}>
            <div className={styles.bannerContainer}>
                <img className={styles.banner} src={image} alt=''/>
                {!!languages &&
                    <div className={styles.languageButtons}>
                        {languages.map(l =>
                            <button key={l} className={user.language === l ? styles.active : null} onClick={() => onChangeLanguage(l)}>
                                {l}
                            </button>
                        )}
                    </div>
                }
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
}
