import React from 'react';
import './TimelineCommentInput.scss';
import {Attachment} from '../../graphql/api/attachments/Attachment';
import AttachmentTag, {AttachmentTagProps} from '../Attachment/AttachmentTag';
import {TimelineItem, TimelineRow} from './TimelineItem';
import {attachmentStore} from '../Attachment/AttachmentStore';
import {t} from '../translations';
import { observer } from 'mobx-react';

interface TimelineCommentInputProps
{
    mark?: React.ReactNode
    name?: React.ReactNode
    markStyle?: React.CSSProperties
    withLine?: boolean
    canAttach?: boolean
    cancelable?: boolean

    defaultMessage?: string
    attachments?: Attachment[]

    children?: React.ReactNode

    onSubmit?(newMessage: string, addAttachments: string[], removeAttachments: string[]): void
    onCancel?(): void
}

interface TimelineCommentInputState
{
    message?: string
    addAttachments?: string[]
    removeAttachments?: string[]
}

@observer
export default class TimelineCommentInput extends React.PureComponent<TimelineCommentInputProps, TimelineCommentInputState>
{
    state: TimelineCommentInputState = {};
    attachmentButton = React.createRef<HTMLInputElement>();

    reset = () => this.setState({message: null, removeAttachments: null, addAttachments: null});

    handleText = e => this.setState({message: e.target.value});

    handleKey = (e: React.KeyboardEvent) =>
    {
        if (e.key == 'Enter')
        {
            this.submit();
        }
    };

    get canSubmit()
    {
        const s = this.state;
        return !!(s.message || s.addAttachments || s.removeAttachments);
    }

    submit = () =>
    {
        if (this.canSubmit)
        {
            const s = this.state;
            this.props.onSubmit?.(s.message, s.addAttachments, s.removeAttachments);
            this.reset();
        }
    };

    cancel = () =>
    {
        this.props.onCancel?.();
        this.reset();
    };

    removeExistingAttachment = (a: AttachmentTagProps) =>
    {
        const copy = this.state.removeAttachments ? this.state.removeAttachments.slice() : [];
        const i = copy.indexOf(a.keyName);
        if (i < 0)
        {
            copy.push(a.keyName);
        }
        else
        {
            copy.splice(i, 1);
        }
        this.setState({removeAttachments: copy.length > 0 ? copy : null});
    };

    removeNewAttachment = (a: AttachmentTagProps) =>
    {
        attachmentStore.cancel(a.keyName);
        const copy = this.state.addAttachments.slice();
        copy.splice(a.index, 1);
        this.setState({addAttachments: copy.length > 0 ? copy : null});
    };

    selectFile = () =>
    {
        this.attachmentButton.current.click();
    };

    handleAttachment = (e) =>
    {
        e.preventDefault();
        for (const file of e.target.files)
        {
            attachmentStore.upload(file).then(u =>
            {
                const copy = this.state.addAttachments ? this.state.addAttachments.slice() : [];
                copy.push(u.key);
                this.setState({addAttachments: copy});
            });
        }
    };

    render()
    {
        const p = this.props;
        const s = this.state;
        return (
            <TimelineItem
                className='TimelineCommentInput'
                markClassName='TimelineMessageMark'
                markStyle={p.markStyle}
                mark={p.mark}
                withLine={p.withLine}
            >
                {!!p.name && <TimelineRow className='name' content={p.name}/>}
                <TimelineRow
                    className='input'
                    content={
                        <>
                            <input
                                className='form-control'
                                type='text'
                                placeholder={t.contacts.comment}
                                value={s.message || p.defaultMessage || ''}
                                onChange={this.handleText}
                                onKeyDown={this.handleKey}
                            />
                            {!!p.canAttach && <span className='attach' onClick={this.selectFile}>📎</span>}
                            {!!p.canAttach &&
                            <input ref={this.attachmentButton} value='' onChange={this.handleAttachment} type='file' className='d-none' multiple/>
                            }
                            {!!p.attachments && p.attachments.map((a, i) =>
                                <AttachmentTag
                                    key={a.key}
                                    keyName={a.key}
                                    name={a.name}
                                    className={s.removeAttachments && s.removeAttachments.includes(a.key) ? 'removed' : ''}
                                    onClick={this.removeExistingAttachment}
                                />
                            )}
                            {!!s.addAttachments && s.addAttachments.map((a, i) =>
                                <AttachmentTag
                                    key={i}
                                    keyName={a}
                                    index={i}
                                    onClick={this.removeNewAttachment}
                                />
                            )}
                        </>
                    }
                    aside={
                        <div className='d-flex align-items-center'>
                            <button className={'submit btn btn-primary ml-2 ' + (this.canSubmit ? 'canSubmit' : '')} onClick={this.submit}>{t.global.submit}</button>
                            {!!p.cancelable && <a className='ml-2 cancelEdit' href='#' onClick={this.cancel}>{t.global.cancel}</a>}
                        </div>
                    }
                />
                {p.children}
            </TimelineItem>
        );
    }
}
