import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1')
{
    const version = process.env.app_version || process.env.npm_package_version;
    Sentry.init({
        dsn: 'https://48aa79c165e54dacbea8d001218adb07@sentry.io/1879861',
        maxValueLength: 2000,
        normalizeDepth: 10,
        tracesSampleRate: process.env.NODE_ENV == 'production' ? .2 : 0,
        integrations: [
            new BrowserTracing(),
        ],
        release: version ? 'letsconnect@' + version : undefined,
        environment: process.env.NODE_ENV,
    });
    // @ts-ignore
    Sentry.setTag('build.date', BUILD_DATE);
    Sentry.setTag('deployment', location.hostname.split('.')[0].split('-').slice(-1)[0]);
    if (version)
    {
        Sentry.setTag('version', version);
    }
    // Sentry.setTag('locale', window.navigator.language);
}

import {configure} from 'mobx';
configure({enforceActions: 'never'});

import {createRoot} from 'react-dom/client';
import './global.scss';
import App from './App';

const AppWithProfiler = Sentry.withProfiler(App, {name: 'App'});

createRoot(document.getElementById('root')).render(<AppWithProfiler/>);
