import React, {useMemo} from 'react';
import './Checkbox.scss';

interface Props
{
    className?: string
    label?: React.ReactNode
    checked?: boolean
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    border?: boolean
}

export default function Checkbox({className, label, checked, onChange, border}: Props)
{
    const key = useMemo(newKey, []);
    return (
        <label htmlFor={key} className={'Checkbox ' + (border ? 'CheckboxBorder ' : '') + (checked ? 'CheckboxChecked ' : '') + (className || '')}>
            <input
                id={key}
                type='checkbox'
                checked={!!checked}
                onChange={onChange || noop}
            />
            <div className='CheckboxCheckmark'/>
            <div className='CheckboxLabel'>
                {label}
            </div>
        </label>
    )
}

function newKey()
{
    return 'checkbox' + Math.random();
}

const noop = () => {};
