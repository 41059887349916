import {action, makeObservable, observable} from 'mobx';
import {debounce} from '../common/debounce';
import {throttle} from '../common/throttle';
import {ICartTotalProduct, loadCartTotalsProducts} from './cartProxy';
import {projectStore} from '../stores/ProjectStore';
import {cartQuantityStore} from './CartQuantityStore';
import {cartValidateStore} from './CartValidateStore';
import {registerGlobalEventHandler} from '../stores/globalEvents';

class CartTotalStore
{
    @observable loading: boolean = false;
    @observable cartViewUpdated = 0;
    products: {[project: string]: ICartTotalProduct[]};
    private modified: {[project: string]: /* Date */ string};
    @observable cartQuantities: {[project: string]: number} = {};

    get cartQuantity()
    {
        return this.cartQuantities[projectStore.id];
    }

    constructor()
    {
        makeObservable(this);
        this.products = {};
        this.modified = {};
        cartQuantityStore.addListener((project, products, modified, prevModified) =>
        {
            const list = this.products[project];
            if (list)
            {
                for (const product of products)
                {
                    const existingIndex = list?.findIndex(p => p.id == product.id);
                    if (existingIndex >= 0)
                    {
                        if (product.stores.some(s => s.weeks.some(w => w.quantity)))
                        {
                            const existing = list[existingIndex];
                            existing.stores = product.stores;
                        }
                        else
                        {
                            list.splice(existingIndex, 1);
                        }
                    }
                    else
                    {
                        list.push(product);
                    }
                }
                this.updateCartQuantity(project);
                ++this.cartViewUpdated;
                if (this.modified[project] == prevModified)
                {
                    this.modified[project] = modified;
                }
                else
                {
                    this.loadProducts(project);
                }
            }
        });
        cartValidateStore.addListener(project =>
        {
            if (this.products[project])
            {
                this.loadProducts(project);
            }
        });
        registerGlobalEventHandler('logout', () =>
        {
            this.products = {};
            this.cartQuantities = {};
        });
    }

    loadProducts = action((project: string, onlyIfModified?: boolean) =>
    {
        if (this.loading)
        {
            return;
        }
        this.loading = true;
        return loadCartTotalsProducts(project, onlyIfModified ? this.modified[project] : undefined).then(action(res =>
        {
            this.loading = false;
            if (res)
            {
                if (res.products)
                {
                    this.products[project] = res.products;
                    this.modified[project] = res.modified;
                    this.updateCartQuantity(project);
                    ++this.cartViewUpdated;
                }
            }
        }));
    });

    loadProductsThrottled = throttle(this.loadProducts, 10000);

    private updateCartQuantity = debounce(action((project: string) =>
    {
        let quantity = 0;
        const list = this.products[project];
        if (list)
        {
            const len = list.length;
            for (let i = 0; i < len; ++i)
            {
                const product = list[i];
                for (let i1 = 0; i1 < product.stores.length; ++i1)
                {
                    const store = product.stores[i1];
                    for (let i2 = 0; i2 < store.weeks.length; ++i2)
                    {
                        const week = store.weeks[i2];
                        quantity += week.quantity;
                    }
                }
            }
        }
        if (quantity > 9999)
        {
            quantity = 9999;
        }
        this.cartQuantities[project] = quantity;
    }));
}

export const cartTotalStore = new CartTotalStore();
