import {useState, useEffect} from 'react';

export function useStoreProductImage(images: string[])
{
    const [index, setIndex] = useState<number>(0);
    const length = images?.length;

    useEffect(() =>
    {
        if (length > 1)
        {
            const interval = setInterval(() => setIndex(i => i + 1 < images.length ? i + 1 : 0), 2000);
            return () => clearInterval(interval);
        }
    }, [length]);

    return length ? images[index] || null : null;
}
