// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactMessage {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 2rem;
}
.ContactMessage .open {
  font-size: 24px;
  background-color: #3d8af7;
  color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 1.5rem;
  border: none;
  outline: none;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
.ContactMessage .open .fa {
  margin-right: 1rem;
}

.ContactMessagePopup {
  border-radius: 0.5rem;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  overflow: hidden;
  margin-bottom: 1.5rem;
  max-width: 450px;
}
.ContactMessagePopup .top {
  font-size: 22px;
  background-color: #3d8af7;
  color: #fff;
  padding: 0.5rem 1rem;
}
.ContactMessagePopup .top .close {
  color: #fff;
  line-height: initial;
  padding: 0 0.5rem;
}
.ContactMessagePopup .body {
  font-size: 18px;
  padding: 0.8rem 1.5rem;
  background: #fff;
}
.ContactMessagePopup .body .send {
  display: flex;
  justify-content: end;
}`, "",{"version":3,"sources":["webpack://./frontend/Landing/ContactMessage.scss","webpack://./frontend/variables.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,SAAA;EACA,QAAA;EACA,aAAA;AADF;AAEE;EACE,eAAA;EACA,yBCIW;EDHX,WAAA;EACA,oBAAA;EACA,qBAAA;EACA,YAAA;EACA,aAAA;EACA,2EAAA;AAAJ;AACI;EACE,kBAAA;AACN;;AAIA;EACE,qBAAA;EACA,2EAAA;EACA,gBAAA;EACA,qBAAA;EACA,gBAAA;AADF;AAEE;EACE,eAAA;EACA,yBCjBW;EDkBX,WAAA;EACA,oBAAA;AAAJ;AACI;EACE,WAAA;EACA,oBAAA;EACA,iBAAA;AACN;AAEE;EACE,eAAA;EACA,sBAAA;EACA,gBAAA;AAAJ;AACI;EACE,aAAA;EACA,oBAAA;AACN","sourcesContent":["@import 'frontend/variables';\n\n.ContactMessage {\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  padding: 2rem;\n  .open {\n    font-size: 24px;\n    background-color: $button-color;\n    color: #fff;\n    padding: .5rem 2rem;\n    border-radius: 1.5rem;\n    border: none;\n    outline: none;\n    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);\n    .fa {\n      margin-right: 1rem;\n    }\n  }\n}\n\n.ContactMessagePopup {\n  border-radius: .5rem;\n  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);\n  overflow: hidden;\n  margin-bottom: 1.5rem;\n  max-width: 450px;\n  .top {\n    font-size: 22px;\n    background-color: $button-color;\n    color: #fff;\n    padding: .5rem 1rem;\n    .close {\n      color: #fff;\n      line-height: initial;\n      padding: 0 .5rem;\n    }\n  }\n  .body {\n    font-size: 18px;\n    padding: .8rem 1.5rem;\n    background: #fff;\n    .send {\n      display: flex;\n      justify-content: end;\n    }\n  }\n}\n","$font-1: 'Open Sans', sans-serif;\n$font-2: Montserrat, sans-serif;\n\n$desktop: 1200px;\n$tablet: 1024px;\n$phablet: 768px;\n$somePhones: 576px;\n$highEndPhones: 480px;\n$budgetPhones: 320px;\n$sbsVertical: 860px;\n$smallerMenu: 1000px;\n$menuInSideBar: 700px;\n\n$button-color: #3d8af7;\n$button-empty-color: #403d39;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
