import {tryGet, tryGetObj, trySet, trySetObj} from '../stores/userPersist';
import {registerGlobalEventHandler} from '../stores/globalEvents';

const storageKey = 'attachment-cache';

function getAttachmentCache()
{
    return tryGetObj<{[key: string]: string}>(storageKey) || {};
}

function setAttachmentCache(attachmentCache: {[key: string]: string})
{
    trySetObj(storageKey, attachmentCache);
}

export function tryGetCachedAttachment(key: string)
{
    return tryGet(key);
}

export function trySetCachedAttachment(key: string, value: string)
{
    trySet(key, value);

    const attachmentCache = getAttachmentCache();
    attachmentCache[key] = new Date().toISOString();
    setAttachmentCache(attachmentCache);

    clearOldCache();
}

function clearOldCache()
{
    const attachmentCache = getAttachmentCache();

    const clearOlderThan = new Date();
    clearOlderThan.setDate(clearOlderThan.getDate() + 7); // clear everything older than 7 days

    for (const k in attachmentCache)
    {
        const date = new Date(attachmentCache[k]);

        if (+date < +clearOlderThan)
        {
            trySet(k, null);
            attachmentCache[k] = undefined;
        }
    }

    setAttachmentCache(attachmentCache);
}

registerGlobalEventHandler('logout', clearCachedAttachments);

function clearCachedAttachments()
{
    const attachmentCache = getAttachmentCache();

    for (const k in attachmentCache)
    {
        trySet(k, null);
    }

    setAttachmentCache(null);
}
