import {action, makeObservable, observable} from 'mobx';
import {IOrder, loadOrders} from './orderProxy';

class OrderStore
{
    @observable orders: {[project: string]: IOrder[]} = {};
    @observable loading: boolean = false;

    constructor()
    {
        makeObservable(this);
    }

    @action
    load(project: string)
    {
        this.loading = true;
        loadOrders(project).then(action('orders', orders =>
        {
            this.loading = false;
            if (orders)
            {
                this.orders[project] = orders;
            }
        }));
    }
}

export const orderStore = new OrderStore();
