// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EOAtau9ke3wAAlQF8nUq {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  max-height: 100px;
  margin: 10px 0;
  padding: 12px 20px;
  border-radius: 5px;
  border: 1px solid #2169CA;
  color: #000;
  cursor: pointer;
  transition: max-height 500ms, padding 500ms, margin 500ms, opacity 500ms;
}

/*.type:hover, .selected {*/
/*  background-color: #3d8af7;*/
/*  color: #fff;*/
/*}*/

.xPxki7l1qSnQQFPpgktz {
  font-weight: bold;
  border-width: 2px;
}

.VsQ5a6TiPnrBLE6Z7lE8 {
  margin: 5px 0;
  padding: 6px 20px;
  overflow-y: hidden;
}

.c1rwb_jxBRaJV2le30QV {
  opacity: 0;
  max-height: 0;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

.lHgtdaCGy7nojg7f0fGN {
  padding-left: 50px;
}

.EOAtau9ke3wAAlQF8nUq span {
  min-width: 0;
  word-wrap: break-word;
}

@media screen and (max-width: 450px) {
  .lHgtdaCGy7nojg7f0fGN {
    padding-left: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/views/SelectAccountType.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,wEAAwE;AAC1E;;AAEA,2BAA2B;AAC3B,+BAA+B;AAC/B,iBAAiB;AACjB,IAAI;;AAEJ;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,SAAS;EACT,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".type {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 10px;\n  max-height: 100px;\n  margin: 10px 0;\n  padding: 12px 20px;\n  border-radius: 5px;\n  border: 1px solid #2169CA;\n  color: #000;\n  cursor: pointer;\n  transition: max-height 500ms, padding 500ms, margin 500ms, opacity 500ms;\n}\n\n/*.type:hover, .selected {*/\n/*  background-color: #3d8af7;*/\n/*  color: #fff;*/\n/*}*/\n\n.selected {\n  font-weight: bold;\n  border-width: 2px;\n}\n\n.notSelected {\n  margin: 5px 0;\n  padding: 6px 20px;\n  overflow-y: hidden;\n}\n\n.hidden {\n  opacity: 0;\n  max-height: 0;\n  margin: 0;\n  padding: 0;\n  overflow-y: hidden;\n}\n\n.subtypes {\n  padding-left: 50px;\n}\n\n.type span {\n  min-width: 0;\n  word-wrap: break-word;\n}\n\n@media screen and (max-width: 450px) {\n  .subtypes {\n    padding-left: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"type": `EOAtau9ke3wAAlQF8nUq`,
	"selected": `xPxki7l1qSnQQFPpgktz`,
	"notSelected": `VsQ5a6TiPnrBLE6Z7lE8`,
	"hidden": `c1rwb_jxBRaJV2le30QV`,
	"subtypes": `lHgtdaCGy7nojg7f0fGN`
};
export default ___CSS_LOADER_EXPORT___;
