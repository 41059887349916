import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {IContact, UserInfo} from '../stores/userProxy';
import {addCompanyStore} from './AddCompanyStore';
import DropdownSearch from '../common/DropdownSearch';
import {companyStore} from '../Company/CompanyStore';
import {user} from '../stores/user';
import {t} from '../translations';

interface Props
{
    userId: string
    person: IContact | UserInfo
}

export default observer(function AddCompanyToUser({person, userId}: Props)
{
    const [addCompany, setAddCompany] = useState(false);
    const [selectedCompany, selectCompany] = useState('');

    return (
        <div className='position-relative ml-3 mb-3'>
            <button
                className='button'
                onClick={() =>
                {
                    addCompanyStore.load();
                    setAddCompany(!addCompany)
                }}
            >
                Add company
            </button>
            {addCompany &&
            <div className='StandsSelectCompany box-shadow position-absolute p-3 mt-1' style={{right: '2.5px'}}>
                <p>{t.stands.selectCompany}</p>
                <DropdownSearch
                    options={addCompanyStore.companies
                        .filter(c => !person.company.includes(c.id) && person.country.some(country => c.country.includes(country)))
                        .map(c => ({value: c.id, name: c.name, keywords: c.storeId ? [c.storeId] : null}))
                        .sort((a, b) => (a.name || '').localeCompare(b.name || '', 'en', {sensitivity: 'base'}))}
                    selected={selectedCompany}
                    onSelect={selectCompany}
                />
                <div
                    className='d-flex justify-content-center'
                >
                    <button
                        className='button text-decoration-none d-inline-block mt-3'
                        onClick={() =>
                        {
                            if (!selectedCompany)
                            {
                                return;
                            }
                            const updatedCompanies = [...person.company];
                            updatedCompanies.push(selectedCompany);
                            user.updatePerson(userId, {...person, company: updatedCompanies});
                            setAddCompany(false);
                            selectCompany('');
                            companyStore.loadCompany([selectedCompany]);
                        }}
                    >
                        Add company
                    </button>
                </div>
            </div>}
        </div>
    )
});
