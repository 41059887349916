import {lazy, Suspense} from 'react';
import {observer} from 'mobx-react-lite';
import {LoadingInline} from '../common/Loading';
const OrganizationNotificationsList = lazy(() => import('./OrganizationNotificationsList'));

export default observer(function OrganizationNotifications()
{
    return (
        <Suspense
            fallback={
                <h5 className='mb-4 pb-2 border-bottom border-dark d-flex justify-content-between'>
                    <div className='d-flex'>
                        <span className='mr-2 font-2'>New notifications</span>
                        <LoadingInline/>
                    </div>
                    <span>Show notifications about guests</span>
                </h5>
            }
        >
            <OrganizationNotificationsList/>
        </Suspense>
    )
})
