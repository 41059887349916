import {call, gql} from '../client';
import {Country} from '../../graphql/api/Types';

export interface ContactMessageInput
{
    name: string
    email: string
    country: Country
    message: string
}

export function sendContactMessage(input: ContactMessageInput)
{
    return call<{sendContactMessage: boolean}>(gql`mutation($input:ContactMessageInput!){sendContactMessage(input:$input)}`, {input});
}

export function getMyCountryCode()
{
    return call<{myCountryCode: Country}>(gql`{myCountryCode}`).then(({data}) => data?.myCountryCode);
}

export interface StoreByIdInfo
{
    name: string
    storeType: string
    territory?: string
}

export function getStore(country: Country, storeId: string, storeType?: string)
{
    return call<{storeInfoById: StoreByIdInfo}>(
        gql`query($input: SearchStoreInput!){storeInfoById(input:$input){name,storeType,territory}}`,
        {input: {country, storeId, storeType}}
    ).then(({data}) => data?.storeInfoById);
}
