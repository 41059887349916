import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {companyStore, ICompany} from './CompanyStore';
import {carrefourCompanyStore} from './CarrefourCompanyStore';
import DropdownSearch from '../common/DropdownSearch';

export default observer(function AssignCarrefourCompany({company}: {company: ICompany})
{
    useEffect(() =>
    {
        carrefourCompanyStore.load();
    }, []);

    const filteredCompanies = carrefourCompanyStore.companies.filter(c => c.id !== company.id && company.country.some(o => c.country.includes(o)));

    return filteredCompanies.length ?
        <DropdownSearch
            placeholder='Select main carrefour company'
            selected={company.exhibitor.carrefourBrand || ''}
            options={filteredCompanies.map(c => ({
                value: c.id,
                name: c.name,
            }))}
            onChange={id => companyStore.update(['exhibitor', 'carrefourBrand'], id)}
        />
        :
        null;
});
