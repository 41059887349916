import {InputHTMLAttributes, memo, ReactNode} from 'react';
import {useId} from './useId';
import styles from './FormField.module.css';

export default memo(function FormField({label, onChangeText, ...rest}: InputHTMLAttributes<HTMLInputElement> & {label?: ReactNode, onChangeText?: (value: string) => void})
{
    const id = useId();
    return (
        <div className={styles.field}>
            {!!label && <label htmlFor={id}>{label}</label>}
            <input className='form-control' id={id} onChange={onChangeText ? e => onChangeText(e.currentTarget.value) : null}{...rest}/>
        </div>
    );
});
