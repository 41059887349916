import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import './OrderStandProductCard.scss';
import {orderStandStore} from './OrderStandStore';
import { packageStore } from '../Packages/PackageStore';
import {packageProductSetup} from '../../lib/packageLib';
import {t} from '../translations';
import {IProduct, orderStandProductStore} from './OrderStandProductStore';
import { user } from '../stores/user';
import FA from 'react-fontawesome';
import {pickLanguage} from '../stores/utility';

interface OrderStandProductCardProps
{
    className?: string
    selectedId?: string
    product?: IProduct
    selected?: boolean
}

export default observer(function OrderStandProductCard({className, selectedId, product, selected}: OrderStandProductCardProps)
{
    const [showMore, setShowMore] = useState(false);

    const id = product?.id || selectedId;

    if (!product)
    {
        product = orderStandProductStore.projectProducts.find(p => p.id === selectedId);
    }

    const handleAdd = () =>
    {
        orderStandStore.selectProduct(id, quantityCreated + 1);
    };

    const handleSubtract = () =>
    {
        if (quantityCreated > 0)
        {
            orderStandStore.selectProduct(id, quantityCreated - 1);
        }
    };

    const quantitySubmitted = (
        (orderStandStore.selected.submitted?.products?.find(p => p.id === id)?.quantity || 0) +
        (orderStandStore.selected.approved?.products?.find(p => p.id === id)?.quantity || 0)
    );

    const quantityCreated = (orderStandStore.selectedProducts[id] ?? orderStandStore.selected.created?.products?.find(p => p.id === id)?.quantity) || 0;

    const quantity = quantityCreated + quantitySubmitted;

    let packageQuantity: number;
    if (orderStandStore.selectedPackageId)
    {
        const prod = packageStore.packages.find(p => p.id === orderStandStore.selectedPackage)?.products?.find(p => p.id === id);
        packageQuantity = prod ? packageProductSetup(prod, orderStandStore.selected.setup, 1, user.moderator).quantity : 0;
    }
    else
    {
        const s = orderStandStore.selected;
        const pkg =
            s.created?.package ||
            s.submitted?.package ||
            s.approved?.package ||
            s.invoiced?.package;
        if (pkg)
        {
            if (pkg.palletType && product.pallet)
            {
                if (pkg.palletType === id)
                {
                    const palletOptionProductIds = orderStandProductStore.projectProducts.filter(p => p.pallet).map(p => p.id);
                    packageQuantity = pkg.products.find(p => palletOptionProductIds.includes(p.id))?.quantity || 0;
                }
                else
                {
                    packageQuantity = 0;
                }
            }
            else
            {
                packageQuantity = pkg.products.find(p => p.id === id)?.quantity || 0;
            }
        }
        else
        {
            packageQuantity = 0;
        }
    }

    const productDetails = pickLanguage(product?.description);

    const usesElectricity = product?.electricity?.power > 0;

    const overlayQuantity = (quantity || quantityCreated || quantitySubmitted) + packageQuantity;

    const overlayName = quantity > 0 ? t.standCreation.addedToYourStand : t.standCreation.alreadyInYourStand;

    const {image, name} = product;
    const selling = product.price?.selling;
    const price = selling?.value;

    return (
        <div className={'ProductCardOrderStand ' + (selected ? 'selected ' : '') + (className || '')}>
            <div className='overlayHover'>
                <div className='productImageOverlay'>
                    <div className={'productOverlay ' + (overlayQuantity != 0 ? 'overlayVisible' : 'overlayHide')}>
                        <span>{overlayQuantity} {overlayQuantity > 1 ? t.global.pieces : t.global.piece}</span>
                        <span>{overlayName}</span>
                    </div>
                    <div className='image' style={{backgroundImage: image ?  `url(${image})` : 'url(https://yourfair-pub.s3.eu-west-1.amazonaws.com/nF5FWwR58EOvPP%2BX_1.png)'}}/>
                </div>
                <div className='info'>
                    <div>
                        <p className='name'>{pickLanguage(name)}</p>
                    </div>
                    {usesElectricity &&
                    <div className='electricity d-flex'>
                        <img src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/NOrG6QPuAQo2XuEK_electricity.svg' alt=''/>
                        <span>{t.global.included}</span>
                    </div>}
                    {productDetails &&
                    <div className='itemDesc'>
                        {!showMore && <span className='readMore' onClick={() => setShowMore(true)}>{t.global.moreDetails}</span>}
                        {!!showMore && <span className='proDesc'>{productDetails}</span>}
                    </div>}
                </div>
                <p className='price'>
                    {price != null ? user.formatCurrency(selling?.currency || 'EUR', price) : <i className='small'>{t.global.priceOnDemand}</i>}
                </p>
                <div className='quantity'>
                    <div className='inner-quantity'>
                        <div className='quantity-decrement'>
                            <div className='plus-button minus order-stand-plus' onClick={handleSubtract}>
                                <FA className='fal plus-sign' name='minus'/>
                            </div>
                            <span>
                                {quantityCreated}
                            </span>
                        </div>
                        <div className='quantity-increment'>
                            <span>{quantityCreated > 1 ? t.global.pieces : t.global.piece}</span>
                            <div className='plus-button order-stand-plus' onClick={handleAdd}>
                                <FA className='fal plus-sign' name='plus'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
