import React, {ChangeEvent, createRef, KeyboardEvent, useEffect, useState} from 'react';
import FA from 'react-fontawesome';
import './Chat.scss';
import AttachmentTag, {AttachmentTagProps} from '../Attachment/AttachmentTag';
import {attachmentStore} from '../Attachment/AttachmentStore';
import {formatRelativeTime} from '../common/timeFormatting';
import {t} from '../translations';
import {Link} from 'react-router-dom';
import {IFormattedChatMessage} from './ChatWindow';
import {ChatAttachment} from '../../graphql/api/chat/Chat';
import {tryGetCachedAttachment, trySetCachedAttachment} from './attachmentCache';
import {callDownloadAttachment} from '../Attachment/attachmentProxy';
import { user } from '../stores/user';

interface ChatContentProps
{
    id?: string
    messages: IFormattedChatMessage[]
    onSend?(text: string, attachments: string[])
    onHighlight?(author: string, messageDate: Date, highlight: boolean): void
}

interface ChatContentState
{
    input: string
    attachments?: string[]
}

export class ChatContent extends React.PureComponent<ChatContentProps, ChatContentState>
{
    state: ChatContentState = {
        input: ''
    };
    content = createRef<HTMLDivElement>();
    attachmentButton = createRef<HTMLInputElement>();

    scrollToBottom = () => this.content.current.scrollTop = this.content.current.scrollHeight;

    componentDidMount()
    {
        this.scrollToBottom();
    }

    componentDidUpdate()
    {
        this.scrollToBottom();
    }

    handleInput = (e: ChangeEvent<HTMLTextAreaElement>) =>
    {
        this.setState({input: e.currentTarget.value});
        autoSetHeight(e.currentTarget);
    };

    handleSend = (e: KeyboardEvent) =>
    {
        const s = this.state;
        if (e.key === 'Enter' && !e.shiftKey)
        {
            e.preventDefault();
            if (s.input || s.attachments)
            {
                this.props.onSend?.(s.input, s.attachments);
                this.setState({input: '', attachments: null});
            }
        }
    };

    selectFile = () =>
    {
        this.attachmentButton.current.click();
    };

    handleAttachment = (e) =>
    {
        e.preventDefault();
        for (const file of e.target.files)
        {
            attachmentStore.upload(file).then(u =>
            {
                const copy = this.state.attachments ? this.state.attachments.slice() : [];
                copy.push(u.key);
                this.setState({attachments: copy});
            });
        }
    };

    removeAttachment = (a: AttachmentTagProps) =>
    {
        attachmentStore.cancel(a.keyName);
        const copy = this.state.attachments.slice();
        copy.splice(a.index, 1);
        this.setState({attachments: copy.length > 0 ? copy : null});
    };

    render()
    {
        const p = this.props;
        const s = this.state;
        return (
            <>
                <div ref={this.content} className='ChatContent'>
                    {p.messages.map((m, i) => <ChatMessage key={m.date + m.msg + i} {...m} onHighlight={p.onHighlight} scrollToBottom={this.scrollToBottom}/>)}
                </div>
                <div className='ChatInput'>
                    <div className='attachments d-flex'>
                    {!!s.attachments && s.attachments.map((a, i) =>
                        <AttachmentTag
                            key={i}
                            keyName={a}
                            index={i}
                            onClick={this.removeAttachment}
                        />
                    )}
                    </div>
                    <div className='d-flex'>
                        <textarea
                            ref={autoSetHeight}
                            placeholder={t.global.sendMessage}
                            value={s.input}
                            onChange={this.handleInput}
                            onKeyDown={this.handleSend}
                        />
                        <FA name='paperclip' className='attach' onClick={this.selectFile}/>
                        <input ref={this.attachmentButton} value='' onChange={this.handleAttachment} type='file' className='d-none' multiple/>
                    </div>
                </div>
            </>
        );
    }
}

function autoSetHeight(el: HTMLTextAreaElement)
{
    if (el)
    {
        el.style.height = '2em';
        el.style.height = el.scrollHeight + 'px';
    }
}

interface ChatMessageProps extends IFormattedChatMessage
{
    onHighlight?(author: string, messageDate: Date, highlight: boolean): void
    scrollToBottom: () => void
}

class ChatMessage extends React.PureComponent<ChatMessageProps>
{
    render()
    {
        const p = this.props;
        return (
            <div className={'ChatMessage ' + (p.highlighted ? 'highlighted' : '')}>
                <div className='iconContainer'>
                    <div className='ChatIcon' style={{backgroundColor: p.color}}>{p.initials}</div>
                </div>
                <div className='text'>
                    <span className='msg'>{p.msg}</span>
                    <div>
                        {p.attachments?.map(a =>
                            a.contentType.startsWith('image/') ?
                                <AttachedImageView key={a.key} attachment={a} scrollToBottom={p.scrollToBottom}/> :
                                <AttachmentTag key={a.key} keyName={a.key} name={a.name}/>
                        )}
                    </div>
                    {user.moderator ?
                        <Link to={'/contact/' + p.author} className='date unstyle-link'>{p.firstName} {p.lastName}, {formatRelativeTime(p.date)}</Link>
                        : <p className='date unstyle-link'>{p.firstName} {p.lastName}, {formatRelativeTime(p.date)}</p>
                    }
                </div>
                <FA name='highlighter' className='fad highlight' onClick={() => p.onHighlight?.(p.author, p.date, !p.highlighted)}/>
            </div>
        );
    }
}

function AttachedImageView({attachment: {key, name}, scrollToBottom}: {attachment: ChatAttachment, scrollToBottom: () => void})
{
    const [url, setUrl] = useState(null);

    useEffect(() =>
    {
        const cached = tryGetCachedAttachment(key);
        if (cached)
        {
            setUrl(cached);
        }
        else
        {
            callDownloadAttachment(key).then(res =>
            {
                if (res)
                {
                    fetchDataURL(res.signedUrl, dataUrl =>
                    {
                        setUrl(dataUrl);
                        trySetCachedAttachment(key, dataUrl);
                    });
                }
            });
        }
    }, [key]);

    return <img className='chat-image' title={name} alt={name} src={url} onLoad={scrollToBottom}/>;
}

function fetchDataURL(url: string, cb: (dataUrl: string) => void)
{
    const xhr = new XMLHttpRequest();
    xhr.onload = () =>
    {
        const reader = new FileReader();
        reader.onloadend = () =>
        {
            cb(reader.result as string);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}
