import React, {ChangeEventHandler, MouseEventHandler, ReactNode} from 'react';
import {makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import FA from 'react-fontawesome';
import './Registration.scss';
import type {GraphQLFormattedError} from 'graphql';
import PhoneInput from './PhoneInputLazy';
import {RegisterData} from '../../graphql/api/user/User';
import {user} from '../stores/user';
import {getSectorItems} from '../data/sectors';
import {ICompanySearchResult, searchCompanyOrVat} from '../Company/CompanyProxy';
import {t} from '../translations';
import {emailValidation, isID, vatValidation} from '../../lib/common';
import {countryStore} from '../stores/CountryStore';
import {debounce} from '../common/debounce';
import {profiles} from '../data/profiles';
import { Country, ITranslations } from '../../graphql/api/Types';
import { loginStore } from '../stores/LoginStore';
import { getStore } from './landingProxy';
import Checkbox from '../common/Checkbox';
import {registerStore} from './RegisterStore';
import autoValidatedEmail from '../../lib/autoValidatedEmail';
import {pickLanguage} from '../stores/utility';
import {LoadingInline} from '../common/Loading';

type SelectOptions = [string, ReactNode][]

interface State
{
    status?: string;
    fields?: string[];
    inputErrors?: {key: string, message: string, borderWithoutValue?: boolean}[]
    storeErrors?: {index: number; message: string}[];
    companyInput?: string;
    companies?: ICompanySearchResult[];
    submitting: boolean;
    selectedCompany?: ICompanySearchResult;
    terms: boolean;
    personalData: boolean;
}

@observer
export class Registration extends React.Component<{}, State>
{
    state: State = {fields: [], companyInput: '', submitting: false, terms: false, storeErrors: [], personalData: false, inputErrors: []};
    @observable registration: RegisterData = {
        country: null,
        firstName: '',
        lastName: '',
        email: loginStore.registerInitial?.email || '',
        phone: loginStore.registerInitial?.phone || '',
    };

    constructor(props)
    {
        super(props);
        makeObservable(this);
    }

    get hasSector()
    {
        return this.registration.profile === 'exhibitorKeyAccount';
    }

    get companyInputIsVAT()
    {
        return vatValidation(this.state.companyInput);
    }

    get isEtruriaOrApulia()
    {
        return ['etruria', 'apulia'].includes(this.registration.profile);
    }

    handleChange = (e) =>
    {
        const r = this.registration;
        const key = e.target.id;
        r[key] = e.target.value;
        if (key == 'country')
        {
            if (r.sectorPrimary)
            {
                delete r.sectorPrimary;
            }
            if (r.sectorSecondary)
            {
                delete r.sectorSecondary;
            }
            r.company = null;
            r.companyName = null;
            this.setState({companies: null});
            registerStore.setCountry(r[key]);
        }
        if (key == 'profile' && r.profileType === 'store')
        {
            r.stores = [{id: ''}];
        }
        const errors: {key: string, message: string}[] = this.state.inputErrors.filter(err => err.key != key);
        const trimmedFirst = r.firstName?.trim();
        const trimmedLast = r.lastName?.trim();
        this.setState({inputErrors: errors});
        if (trimmedFirst?.length && trimmedFirst[0].toLowerCase() == trimmedFirst[0].toUpperCase())
        {
            errors.push({key: 'firstName', message: t.registration.form.errors.firstnameLetter});
        }
        if (trimmedLast?.length && trimmedLast[0].toLowerCase() == trimmedLast[0].toUpperCase())
        {
            errors.push({key: 'lastName', message: t.registration.form.errors.lastnameLetter});
        }
        if (r.email?.length && !emailValidation(r.email))
        {
            errors.push({key: 'email', message: t.registration.form.errors.emailFormat});
        }
        if (errors.length > 0)
        {
            this.setState({inputErrors: errors});
        }
        this.forceUpdate();
    };
    handleCompanySearch = (e) =>
    {
        this.registration.company = null;
        this.registration.companyName = null;
        const value = e.target.value;
        this.setState({companyInput: value, selectedCompany: null});
        this.debouncedSearchCompanyOrVat(value, this.registration.country);
    };
    debouncedSearchCompanyOrVat = debounce((search: string, country: Country)  =>
    {
        searchCompanyOrVat(search, country).then(companies =>
        {
            if (companies)
            {
                const r = this.registration;
                if (r.company)
                {
                    if (!companies.some(c => c.id === r.company))
                    {
                        r.company = null;
                        r.companyName = null;
                    }
                }
                // auto select company based on vat if name has to be entered
                else if (companies.length == 1 && !isID(companies[0].id) && !companies[0].name)
                {
                    r.company = companies[0].id;
                    r.companyName = null;
                    this.setState({selectedCompany: companies[0]});
                }
                this.setState({companies});
            }
        });
    }, 500);
    handleCompanyName = (e) =>
    {
        this.registration.companyName = e.target.value;
    };
    handleStoreSearch = debounce((storeId: string) =>
    {
        getStore(this.registration.country, storeId).then(res =>
        {
            if (res)
            {
                const target = this.registration.stores.find(s => s.id == storeId);
                if (target)
                {
                    target.name = res.name;
                    this.forceUpdate();
                }
            }
        });
    }, 500);
    handleCompanySelect = (c: ICompanySearchResult) =>
    {
        this.registration.company = c.id;
        this.registration.companyName = c.name;
        this.setState({companyInput: c.vat || c.name, selectedCompany: c});
        this.forceUpdate();
    };
    handleProfile = (e) =>
    {
        this.registration.profile = e.currentTarget.id;
        this.forceUpdate();
    };
    handleProfileType = (e) =>
    {
        const type = e.currentTarget.id;
        this.registration.profile = null;
        this.registration.profileType = type;
        this.registration.company = null;
        this.registration.companyName = null;
        this.registration.stores = null;
        if (type !== 'exhibitor' && type !== 'store')
        {
            this.registration.profile = type;
        }
        this.forceUpdate();
    }
    // handleStoreType = (storeType: string) =>
    // {
    //     this.registration.profile = storeType;
    //     this.forceUpdate();
    // }
    handlePhone = (phone: string) =>
    {
        this.registration.phone = phone;
        this.forceUpdate();
    };

    handleRegistration = () =>
    {
        loginStore.endRegistering();
        loginStore.registered = true;
        loginStore.autoValidated = ['store'].includes(this.registration.profileType) || (
            'headquarter' == this.registration.profileType &&
            !!this.registration.email.match(autoValidatedEmail)
        );
    }

    handleRemoveStore = (index: number) =>
    {
        if (this.registration.stores.length === 1)
        {
            return;
        }
        this.registration.stores.splice(index, 1);
        this.forceUpdate();
    }
    handleChangeStoreId = (index: number, id: string) =>
    {
        const r = this.registration;
        r.stores[index].id = id;
        r.stores[index].name = '';
        if (r.stores[index].id)
        {
            this.setState({storeErrors: this.state.storeErrors.filter(err => err.index != index)});
            this.handleStoreSearch(r.stores[index].id);
        }
        else
        {
            this.setState({
                storeErrors: [
                    ...this.state.storeErrors,
                    {index, message: t.registration.form.enterStoreID}
                ]
            });
        }
    }
    handleAddStore = () =>
    {
        this.registration.stores.push({id: ''});
        this.forceUpdate();
    }

    toggleTerms = () =>
    {
        this.setState({terms: !this.state.terms});
    }

    togglePersonalData = () =>
    {
        this.setState({personalData: !this.state.personalData});
    }

    input = (name: string, key: keyof RegisterData) =>
    {
        const r = this.registration;
        const field = r[key];
        const error = this.state.inputErrors?.find(f => f.key == key);
        const inputClass = error?.borderWithoutValue ? 'error' : !field ? 'typed' : (error ? 'error' : 'pass');
        return (
            <div className='mb-4'>
                <label htmlFor={key}>{name}</label>
                <div className='d-flex align-items-center'>
                    <input
                        className={'form-control ' + inputClass}
                        type='text'
                        id={key}
                        value={!Array.isArray(field) ? field || '' : ''}
                        onChange={this.handleChange}
                    />
                    {error ? (
                        <FA
                            name={'exclamation-circle'}
                            className={'fas ml-2'}
                            style={{color: '#e61610'}}
                        />
                    ) : field?.length ? (
                        <FA
                            name={'check-circle'}
                            className={'fas ml-2'}
                            style={{color: '#1aa260'}}
                        />
                    ) : ''}
                </div>
                {error && <span className='mt-3' style={{color: 'red'}}>{error?.message}</span>}
            </div>
        );
    };

    inputSelect = (name: string, key: string, selectOptions: SelectOptions, optional?: boolean) =>
    {
        const r = this.registration;
        const error = this.state.inputErrors?.find(f => f.key == key);
        const inputClass = error?.borderWithoutValue ? 'error' : !r[key] ? 'typed' : (error ? 'error' : 'pass');
        return (
            <div className='mb-4'>
                <label htmlFor={key}>{name}</label>
                <div className='d-flex align-items-center'>
                    <select
                        className={'form-control ' + inputClass}
                        value={r[key] || ''}
                        id={key}
                        onChange={this.handleChange}
                    >
                        <option value='' disabled={!optional}>{t.global.select}</option>
                        {selectOptions.map(o => <option key={o[0]} value={o[0]}>{o[1]}</option>)}
                    </select>
                    {r[key] ? (
                        <FA
                            name={'check-circle'}
                            className={'fas ml-2'}
                            style={{color: '#1aa260'}}
                        />
                    ) : error ? (
                        <FA
                            name={'exclamation-circle'}
                            className={'fas ml-2'}
                            style={{color: '#e61610'}}
                        />
                    ) : ''}
                </div>
                {error && <span className='mt-3' style={{color: 'red'}}>{error?.message}</span>}
            </div>
        );
    };

    register = async () =>
    {
        const r = this.registration;
        const errors = [];
        let m = '';
        this.setState({storeErrors: []});
        const storeErrors: {index: number; message: string}[] = [];
        const inputErrors: {key: string, message: string, borderWithoutValue?: boolean}[] = [...this.state.inputErrors];
        const trimmedFirst = r.firstName?.trim();
        const trimmedLast = r.lastName?.trim();
        const country = r.country;
        if (!country)
        {
            inputErrors.unshift({key: 'country', message: t.registration.form.errors.countryRequired, borderWithoutValue: true});
        }
        if (!trimmedFirst)
        {
            inputErrors.unshift({key: 'firstName', message: t.registration.form.errors.firstnameRequired, borderWithoutValue: true});
        }
        else if (trimmedFirst[0].toLowerCase() == trimmedFirst[0].toUpperCase())
        {
            inputErrors.unshift({key: 'firstName', message: t.registration.form.errors.firstnameLetter, borderWithoutValue: true});
        }
        if (!trimmedLast)
        {
            inputErrors.unshift({key: 'lastName', message: t.registration.form.errors.lastnameRequired, borderWithoutValue: true});
        }
        else if (trimmedLast[0].toLowerCase() == trimmedLast[0].toUpperCase())
        {
            inputErrors.unshift({key: 'lastName', message: t.registration.form.errors.lastnameLetter, borderWithoutValue: true});
        }
        if (!r.email)
        {
            inputErrors.unshift({key: 'email', message: t.registration.form.errors.emailRequired, borderWithoutValue: true});
        }
        if (!r.profileType)
        {
            errors.push('profileType');
            m = m || t.registration.form.errors.profileRequired;
        }
        // End mandatory check for each profile type

        if (r.profileType == 'store')
        {
            if (!r.profile)
            {
                errors.push('profile');
                m = m || t.registration.form.selectStoreType;
            }
            if (!this.isEtruriaOrApulia)
            {
                r.stores?.forEach((s, i) =>
                {
                    if (!s.id)
                    {
                        storeErrors.push({index: i, message: t.registration.form.enterStoreID});
                    }
                    else if (!s.name)
                    {
                        storeErrors.push({index: i, message: `${t.registration.form.errors.storeWithID} "${s.id}"\n${t.registration.form.errors.notFound}`});
                    }
                })
                if (!r.stores?.length)
                {
                    errors.push('noSelectedStores');
                    m = m || t.registration.form.errors.storeRequired;
                }
            }
        }
        else if (r.profileType === 'guest')
        {
            if (!r.company)
            {
                errors.push('company');
                m = m || t.registration.form.errors.companyRequired;
            }
        }
        else if (r.profileType === 'exhibitor')
        {
            if (!r.profile)
            {
                errors.push('profile');
                m = m || t.registration.form.errors.profileRequired;
            }
            else if (this.hasSector && !r.sectorPrimary)
            {
                errors.push('sectorPrimary');
                m = m || t.registration.form.errors.sectorRequired;
            }
            else if (this.state.companies?.length)
            {
                if (!r.company)
                {
                    if (this.state.companies.length == 1)
                    {
                        r.company = this.state.companies[0].id;
                        r.companyName = this.state.companies[0].name;
                        if (country == 'ES' && !r.companyName)
                        {
                            errors.push('companyName');
                            m = m || t.registration.form.errors.companyRequired;
                        }
                    }
                    else
                    {
                        errors.push('company');
                        m = m || t.registration.form.errors.companyRequired;
                    }
                }
                else
                {
                    if (country == 'ES' && !r.companyName)
                    {
                        errors.push('companyName');
                        m = m || t.registration.form.errors.companyRequired;
                    }
                }
            }
            else
            {
                if (!this.companyInputIsVAT)
                {
                    errors.push('company');
                    m = m || t.registration.form.invalidVat;
                }
            }
        }

        if (loginStore.showTerms !== false)
        {
            if (!this.state.terms)
            {
                errors.push('terms');
                m = m || t.registration.form.tosNotif;
            }
            if (!this.state.personalData)
            {
                errors.push('terms');
                m = m || t.registration.form.tosNotif;
            }
        }
        if (errors.length > 0 || storeErrors.length > 0 || inputErrors.length > 0)
        {
            this.setState({fields: errors, status: m, storeErrors, inputErrors});
        }
        else
        {
            this.setState({submitting: true});
            const res = await user.register({language: user.language, ...r, company: r.company || this.state.companyInput});
            this.setState({submitting: false});
            if (!res.errors)
            {
                this.setState({status: 'Email Sent'});
                this.handleRegistration();
            }
            else
            {
                this.setState({status: getUserRegisterErrorName(res.errors)});
            }
        }
    };

    get profileTypes(): {id: string, name: ITranslations, icon?: string}[]
    {
        return [
            profiles.find(p => p.id == 'headquarter'),
            {
                id: 'exhibitor',
                name: {
                    en: 'Exhibitor',
                    fr: 'Exposant',
                    nl: 'Exposant',
                    pl: 'Wystawca',
                    it: 'Espositore',
                    es: 'Expositor',
                },
                icon: 'hand-holding-box'
            },
            {
                id: 'store',
                name: {
                    en: 'Store',
                    fr: 'Magasin',
                    nl: 'Winkel',
                    pl: 'Sklep',
                    it: 'Negozio',
                    es: 'Tienda',
                },
                icon: 'store-alt'
            },
            profiles.find(p => p.id == 'guest'),
        ];
    }

    get sectorsOpts(): [string, string][]
    {
        return getSectorItems(user.language, this.registration.country || 'BE').map(({keys, names}) => [keys.join('-'), names.join(' > ')]);
    }

    get storeOpts(): [string, string][]
    {
        return profiles.filter(p =>
            p.features?.mobile?.store?.includes(this.registration.country))
        .map(p => [p.id, pickLanguage(p.name)])
    }

    get SearchAndSelectCompany()
    {
        const r = this.registration;
        const s = this.state;
        return <>
            {r.country && (
                <div className='mb-4'>
                    <label htmlFor='company-search'>{r.country == 'ES' ? t.registration.form.companyName : t.registration.form.companyNameOrVAT}</label>
                    <input
                        className='form-control'
                        type='text'
                        id='company-search'
                        value={s.companyInput}
                        onChange={this.handleCompanySearch}
                    />
                </div>
            )}
            {s.selectedCompany ?
                s.selectedCompany.name ?
                    <div className='company-select'>
                        <p>{t.global.company}</p>
                        <div className='item selected'>
                            <div className='radio-circle'>
                                <div className='radio-dot'/>
                            </div>
                            <div>
                                <p>{s.selectedCompany.name}</p>
                                <p>{s.selectedCompany.address}</p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='mb-4'>
                        <label htmlFor='company-name'>{t.registration.form.companyName}</label>
                        <input
                            className='form-control'
                            type='text'
                            id='company-name'
                            value={r.companyName || ''}
                            onChange={this.handleCompanyName}
                        />
                    </div>
                :
                s.companies && (
                    s.companies.length ?
                        <div className='company-select'>
                            <p>{t.registration.form.selectCompany}</p>
                            {s.companies.map(c =>
                                <div
                                    className={'item ' + (c.id === r.company ? 'selected' : '')}
                                    key={c.id}
                                    onClick={() => this.handleCompanySelect(c)}
                                >
                                    <div className='radio-circle'>
                                        {c.id === r.company && <div className='radio-dot'/>}
                                    </div>
                                    <div>
                                        <p>{c.name}</p>
                                        <p>{c.address}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        :
                        <p className='text-danger'>
                            {r.country == 'ES' ?
                                <>{t.registration.form.noCompanyES} <a href='mailto:support.salonES@d-sidegroup.com'>support.salonES@d-sidegroup.com</a></> :
                                t.registration.form.noCompany
                            }
                        </p>
                    )
            }
        </>
    }
    registrationForms(profileType: string)
    {
        const r = this.registration;
        switch (profileType)
        {
            case 'guest':
                return <div className='w-75 mx-auto'>{this.input(t.global.company, 'company')}</div>
            case 'exhibitor':
                return <>
                    <div className='row mb-3 profiles'>
                        {[
                            profiles.find(p => p.id == 'exhibitorKeyAccount'),
                            profiles.find(p => p.id == 'exhibitorRep'),
                            profiles.find(p => p.id == 'exhibitorInstallator'),
                        ].map(({id, name, icon, image}) =>
                            <div key={id} className='col' id={id} onClick={this.handleProfile}>
                                <div className='icon'>
                                    {image ? (
                                        <div
                                            className={'profile-image-icon d-flex align-items-center ' +
                                            (r.profile === id ? 'selected ' : '') +
                                            (this.state?.fields?.find(f => f == 'profile' && !r.profile) ? 'error-stronger' : 'typed')}
                                        >
                                            <img src={image} alt={id}/>
                                        </div>
                                    ) : (
                                        <FA
                                            name={icon}
                                            className={'fad ' +
                                            (r.profile === id ? 'selected ' : '') +
                                            (this.state?.fields?.find(f => f == 'profile' && !r.profile) ? 'error-stronger' : 'typed')}
                                        />
                                    )}
                                </div>
                                <p className='mt-3'>{pickLanguage(name)}</p>
                            </div>
                        )}
                    </div>
                    <div className='w-75 mx-auto'>
                        {r.profile && this.SearchAndSelectCompany}
                        {this.hasSector && !!r.country?.length &&
                        this.inputSelect(
                            t.registration.form.primarySector,
                            'sectorPrimary',
                            this.sectorsOpts.filter(o => o[0] !== r.sectorSecondary)
                        )}
                        {this.hasSector && r.sectorPrimary &&
                        this.inputSelect(
                            t.registration.form.secondarySector,
                            'sectorSecondary',
                            this.sectorsOpts.filter(o => o[0] !== r.sectorPrimary),
                            true
                        )}
                    </div>
                </>
            case 'store': return r.country &&
                <div className='store-select'>
                    {this.inputSelect(t.registration.form.storeType, 'profile', this.storeOpts)}
                    {!this.isEtruriaOrApulia &&
                    <div className='add-stores'>
                        <div className='added-stores'>
                            {r.stores?.map(({id, name}, i) =>
                                <EditStore
                                    key={i}
                                    id={id}
                                    name={name}
                                    profile={r.profile}
                                    error={(!id || !name) && this.state.storeErrors.find(e => e.index === i)?.message}
                                    onChangeId={e => this.handleChangeStoreId(i, e.target.value)}
                                    onRemove={() => this.handleRemoveStore(i)}
                                />
                            )}
                        </div>
                        {r.profile &&
                        <div className='d-flex mt-4 align-items-center' onClick={this.handleAddStore}>
                            <FA name='plus-circle' className='fas add-store-button'/>
                            <span className='ml-2 add-another-store'>{t.registration.form.addStore}</span>
                        </div>}
                    </div>
                    }
                </div>;
        }
    }

    render()
    {
        const s = this.state;
        const r = this.registration;

        return (
            <div className='Registration mx-auto p-4'>
                {this.inputSelect(t.global.country + '*', 'country', countryStore.countryTupleOptions)}
                {this.input(t.registration.form.firstname + '*', 'firstName')}
                {this.input(t.registration.form.lastname + '*', 'lastName')}
                {this.input(t.global.email + '*', 'email')}
                <div className='mb-4'>
                    <label htmlFor='phone'>{t.registration.form.phone}</label>
                    <PhoneInput
                        id='phone'
                        className='mb-4'
                        numberInputProps={{className: 'form-control ' + (s.fields?.find(f => f == 'phone' && !r.phone) ? 'error' : 'typed')}}
                        value={r.phone}
                        onChange={this.handlePhone}
                    />
                    <div style={{width: '80%', margin: '0 auto'}}>
                        <span style={{color: '#aaaaaa', fontSize: '.9em'}}>{t.registration.mobileDisclaimer}</span>
                    </div>
                </div>
                <p className='text-center my-4'>{t.registration.form.selectProfile + '*'}</p>
                <div className='row mb-3 profiles'>
                    {this.profileTypes.map(({name, icon, id}) =>
                        <div key={id} className='col' id={id} onClick={this.handleProfileType}>
                            <div className='icon'>
                                <FA
                                    name={icon}
                                    className={'fad ' + (r.profileType === id ? 'selected ' : '') +
                                    (s?.fields?.find(f => f == 'profileType' && !r.profileType) ? 'error-stronger' : 'typed')}
                                />
                            </div>
                            <p className='mt-3'>{pickLanguage(name)}</p>
                        </div>
                    )}
                </div>
                {this.registrationForms(r.profileType)}
                {!!s.status &&
                <div className='registration-error mt-5'>
                    <FA name='exclamation-circle' className='far mr-2'/>
                    {s.status}
                </div>}
                <div className='d-flex justify-content-center mt-4 flex-column pb-5'>
                    {loginStore.showTerms !== false &&
                    <>
                        <Checkbox
                            label={<span dangerouslySetInnerHTML={{__html: t.registration.form.tos}}/>}
                            checked={s.terms}
                            onChange={this.toggleTerms}
                        />
                        <Checkbox
                            label={<span dangerouslySetInnerHTML={{__html: t.registration.form.personalData}}/>}
                            checked={s.personalData}
                            onChange={this.togglePersonalData}
                        />
                    </>}
                    <button
                        className='button-lg register mt-4'
                        onClick={s.submitting ? null : this.register}
                    >
                        {s.submitting && <LoadingInline className='mr-2'/>}
                        {r.profileType === 'store' || r.profileType === 'guest' ? t.authentication.register : t.registration.form.send}
                    </button>
                    <span className='mt-4 text-center'>
                        {t.registration.form.alreadyHavAcc} <a href='/'>{t.registration.form.alreadyHavAccSignIn}</a>
                    </span>
                </div>
            </div>
        );
    }
}

export function getUserRegisterErrorName(errors: GraphQLFormattedError[]): string
{
    if (errors.some(e => e.message === 'invalid-email'))
    {
        return t.registration.form.errors.emailFormat;
    }
    else if (errors.some(e => e.message === 'invalid-phone'))
    {
        return t.registration.form.errors.phoneFormat;
    }
    else if (errors.some(e => e.message === 'company-already-exists'))
    {
        return 'Company with this VAT already exists';
    }
    else if (errors.some(e => e.message === 'Invalid VAT input'))
    {
        return t.registration.form.invalidVat;
    }
    else if (errors.some(e => e.message === 'Invalid VAT'))
    {
        return t.registration.form.invalidVat;
    }
    else if (errors.some(e => e.message === 'exhibitorMainAccount-exists'))
    {
        return 'Only one Exhibitor Main Account can exist per company, please choose another profile.';
    }
    else
    {
        return t.global.somethingWentWrong;
    }
}

interface EditStoreProps
{
    id?: string
    name?: string
    profile?: string
    error?: ReactNode
    removable?: boolean
    onChangeId?: ChangeEventHandler<HTMLInputElement>
    onRemove?: MouseEventHandler<HTMLInputElement>
}

export function EditStore({id, name, profile, error, removable = true, onChangeId, onRemove}: EditStoreProps)
{
    return (
        <div className='store-edit d-flex flex-column'>
            <div>
                <span>{profile == 'proximity' ? 'N° Client' : t.registration.form.storeId}</span>
            </div>
            <div className='d-flex justify-content-between'>
                <div className='d-flex'>
                    <div className='store-id d-flex flex-column'>
                        <input type='text' className={'form-control ' + (error ? 'error' : '')} value={id || ''} onChange={onChangeId}/>
                    </div>
                    <div className='d-flex align-items-center ml-3'>
                        <span className={(!name ? 'dimmed' : '')}>{name}</span>
                    </div>
                </div>
                {removable &&
                <div className={'d-flex align-items-center remove-store ' + (!id ? 'gray' : '')}>
                    <FA name='times-circle' className='fas' onClick={onRemove}/>
                </div>}
            </div>
            {error && <span className='text-danger'>{error}</span>}
        </div>
    );
}
