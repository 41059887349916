import {observer} from 'mobx-react-lite';
import {projectStore} from '../stores/ProjectStore';
import {t} from '../translations';

interface Props
{
    className?: string
    deliveryWeeks: string[]
    deliveryWeek: string
    setDeliveryWeek: (deliveryWeek: string) => void
}

export default observer(function DeliveryWeekSelect({className, deliveryWeeks, deliveryWeek, setDeliveryWeek}: Props)
{
    if (!projectStore.isFRNonFood || !deliveryWeeks)
    {
        return null;
    }
    return (
        <select
            className={'form-control ' + (className || '')}
            value={deliveryWeek || ''}
            onChange={e => setDeliveryWeek(e.currentTarget.value || null)}
        >
            <option value=''>{t.cart.week} ({t.global.all})</option>
            {deliveryWeeks.map(w =>
                <option key={w} value={w}>{w}</option>
            )}
            {deliveryWeek && !deliveryWeeks.includes(deliveryWeek) &&
            <option value={deliveryWeek}>{deliveryWeek}</option>
            }
        </select>
    );
});
