import {call, gql} from '../client';
import {IInvoice} from './InvoiceStore';
import {IInvoiceTo} from '../../graphql/api/invoice/Invoice';
import {IStand} from '../OrderStand/OrderStandStore';

export interface InvoiceUpdateInput
{
    id: string
    to: IInvoiceTo
    comment: string
    PO: string[]
    vat: string
    date: Date
    dueDate: Date
}

export interface BaseInvoiceInput
{
    invoiceId: string
    standId: string
}

export interface RemoveInvoiceProductInput extends BaseInvoiceInput
{
    package: boolean
    product: string
}

export interface CancelInvoiceInput extends BaseInvoiceInput
{
    products?: string[]
}

export function loadInvoices(project: string)
{
    return call<{invoices: IInvoice[]}>(gql`query($project: ID!){invoices(project: $project){id,number,standId,status,cancelInvoiceId,date,dueDate,total,altTotal,to,project,comment,PO,vat,sent}}`, {project}, {noBatch: true})
    .then(({data}) => data?.invoices);
}

export function createInvoice(standId: string)
{
    return call<{createInvoice: {stand: IStand, invoice: IInvoice}}>(
        gql`mutation ($standId:ID!){createInvoice(standId:$standId){
            stand {
                id,
                approved{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}
            }
            invoice {id,number,standId,status,cancelInvoiceId,date,dueDate,total,altTotal,to,comment,project,PO,vat,sent}
        }}`,
        {standId})
    .then(({data}) => data?.createInvoice);
}

export function updateInvoice(input: InvoiceUpdateInput)
{
    return call<{updateInvoice: IInvoice}>(
        gql`mutation($input:InvoiceUpdateInput!){updateInvoice(input:$input){id,number,standId,status,date,dueDate,total,to,project,comment}}`, {input})
    .then(({data}) => data?.updateInvoice);
}

export function invoiceRecipients(id: string)
{
    return call<{invoiceRecipients: string[]}>(gql`query($id:ID!){invoiceRecipients(id:$id)}`, {id}).then(({data}) => data?.invoiceRecipients);
}
export function sendInvoice(id: string, passCompanyCheck: boolean)
{
    return call<{sendInvoice: {stand: IStand, invoice: IInvoice}}>(
        gql`mutation($input:sendInvoiceInput!){sendInvoice(input:$input){
            invoice{id,number,status,total,altTotal,date,dueDate,project,sent},
            stand{
                id,
                approved{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}
                invoiced{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}
            }
        }}`, {input: {id, passCompanyCheck}});
}
export function cancelInvoice(input: CancelInvoiceInput)
{
    return call<{cancelInvoice: {stand: IStand, cancelInvoice: IInvoice, canceledInvoice: IInvoice}}>(
        gql`mutation($input:CancelInvoiceInput!){cancelInvoice(input:$input){
            cancelInvoice{id,number,standId,status,date,dueDate,total,altTotal,to,project,comment,PO,vat},
            canceledInvoice{id,number,standId,status,date,dueDate,total,altTotal,to,project,comment,PO,vat},
            stand{
                id,
                approved{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}
                invoiced{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}
                canceled{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}}},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice},cancelInvoiceId,canceledInvoiceId,partiallyCanceled}
            }
        }}`, {input})
    .then(({data}) => data?.cancelInvoice);
}
export function removeInvoiceProduct(input: RemoveInvoiceProductInput)
{
    return call<{removeItemFromInvoice: IStand}>(
        gql`mutation($input:RemoveItemFromInvoiceInput!){removeItemFromInvoice(input:$input){
            id,
            approved{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}}}`
            , {input})
    .then(({data}) => data?.removeItemFromInvoice);
}
export function recollectDraftInvoice(input: BaseInvoiceInput)
{
    return call<{recollectDraftInvoice: IStand}>(
        gql`mutation($input:BaseInvoiceInput!){recollectDraftInvoice(input:$input){
            id,
            approved{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}
            invoiced{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}}}`
        , {input})
    .then(({data}) => data?.recollectDraftInvoice);
}
export function downloadInvoice(id: string)
{
    return call<{downloadInvoice: string}>(gql`query($id:ID!){downloadInvoice(id:$id)}`, {id}).then(({data}) => data?.downloadInvoice);
}

export function markAsPaid(id: string)
{
    return call<{markAsPaid: IInvoice}>(
        gql`mutation($id:ID!){markAsPaid(id:$id){id,status}}`, {id})
    .then(({data}) => data?.markAsPaid);
}

// export function convertCancelInvoice(input: BaseInvoiceInput)
// {
//     return call<{convertCancelInvoice: IInvoice}>(
//         gql`mutation($input:BaseInvoiceInput!){convertCancelInvoice(input:$input){id,total,altTotal}}`, {input})
//     .then(({data}) => data?.convertCancelInvoice);
// }

export function loadTimelineInvoices(id: string)
{
    return call<{timelineInvoices: IInvoice[]}>(gql`query($id: String!){timelineInvoices(id: $id){id,number,to,standId,status,date,dueDate,total,altTotal}}`,
    {id})
    .then(({data}) => data?.timelineInvoices);
}

export function deleteInvoice(input: BaseInvoiceInput)
{
    return call<{deleteInvoice: IStand}>(
        gql`mutation($input:BaseInvoiceInput!){deleteInvoice(input:$input){
            id,
            approved{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}}}`
        , {input})
    .then(({data}) => data?.deleteInvoice);
}
