import {Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import moment from 'moment';
import {user} from '../stores/user';
import {getName} from '../common/getName';
import {t} from '../translations';
import { contactsStore } from '../Contact/ContactsStore';
import { companyStore } from '../Company/CompanyStore';
import {findProfile} from '../data/profiles';
import { organizationStore } from './organizationStore';
import Swal from 'sweetalert2';
import {swalModal} from '../common/sweetAlert';
import {pickLanguage} from '../stores/utility';
import {IContact} from '../stores/userProxy';
import {projectStore} from '../stores/ProjectStore';
import {eventStore} from '../Event/EventStore';

interface Props
{
    companyId?: string;
}

export default observer(function CompanyMembers({companyId}: Props)
{
    const [addMembers, setAddMembers] = useState<boolean>(false);
    companyId = companyId || user.info?.company?.[0];
    const canEditCheckboxes = user.moderator || user.info?.profiles.some(p => p.id.includes('exhibitor'));
    useEffect(() =>
    {
        if (companyId)
        {
            contactsStore.loadCompanyMembers([companyId]);
            if (canEditCheckboxes)
            {
                companyStore.loadCompanyChats(companyId);
                companyStore.getCompanyOrderValidation(companyId);
            }
        }
    }, [companyId]);

    let list: IContact[];
    if (user.isHeadquarter)
    {
        list = [{id: user.id, ...user.info}];
    }
    else
    {
        const companyMembers = contactsStore.contacts.filter(c => c.company?.includes(companyId) && c.validated != false);
        list = user.info?.company?.includes(companyId) ? [{id: user.id, ...user.info}, ...companyMembers] : companyMembers;
    }

    return (
        <div className='company-members'>
            <div>
                <h5 className='pb-2 mb-4 border-bottom border-dark'>
                    <FA name='city' className='fad mr-2'/>
                    {t.contacts.members}
                </h5>
                {list.map(c =>
                {
                    const badge = c.badges?.find(b => b.project == projectStore.id);
                    return (
                        <Fragment key={c.id}>
                            <Link className='d-block mb-1 text-dark' to={'/contact/' + c.id}>{getName(c)}</Link>
                            <div className='pl-4 text-secondary mb-3'>
                                {c.profiles.map(p =>
                                    <div key={p.id}>{pickLanguage(findProfile(p.id)?.name) ?? p.id}</div>
                                )}
                                <div>{c.email}</div>
                                <div>{c.phone}</div>
                                {!!badge?.days.length &&
                                <>
                                    <div>{t.contacts.registeredFor}</div>
                                    {badge.days.map(d => new Date(d)).map(d =>
                                        <div key={+d} className='pl-4'>{moment(d).format('DD.MM.YYYY.')}</div>
                                    )}
                                    {badge.sideEvents?.map(s =>
                                        <div key={s.reference} className='pl-4'>
                                            {pickLanguage(eventStore.projectEvent?.sideEvents?.find(e => e.reference == s.reference)?.name) || s.reference}
                                        </div>
                                    )}
                                </>
                                }
                                {canEditCheckboxes && c.profiles.some(p => p.id.includes('exhibitor') || p.id == 'headquarter') &&
                                <div>
                                    {c.profiles.some(p => p.id.includes('exhibitor')) &&
                                    <div className='d-flex align-items-center'>
                                        <input
                                            className='cursor-pointer'
                                            type='checkbox'
                                            id={'company-chat' + c.id}
                                            onChange={e => companyStore.updateCompanyChats(companyId, c.id, e.target.checked)}
                                            checked={companyStore.companyChats[companyId]?.includes(c.id) || false}
                                        />
                                        <label htmlFor={'company-chat' + c.id} className='cursor-pointer mb-0 ml-2'>Company chat</label>
                                    </div>
                                    }
                                    <div>
                                        <input
                                            className='cursor-pointer'
                                            type='checkbox'
                                            id={'order-validation' + c.id}
                                            checked={companyStore.companyOrderValidation[companyId]?.includes(c.id) || false}
                                            onChange={e => companyStore.updateExhibitorOrderValidation(c.id, companyId, e.target.checked)}
                                        />
                                        <label htmlFor={'order-validation' + c.id} className='cursor-pointer ml-2'>Order validation</label>
                                    </div>
                                </div>
                                }
                            </div>
                        </Fragment>
                    );
                })}
            </div>
            {(user.moderator || (
                user.info?.profiles.some(profile => allowedToUse.includes(profile.id)) &&
                user.info?.company.some(c => c === companyId)
            )) && (
                addMembers ?
                    <AddCompanyMembers onCancel={() => setAddMembers(false)}/> :
                    <button className='button' onClick={() => {setAddMembers(true)}}>{t.global.addCompanyMembers}</button>
            )}
        </div>
    );
});

interface AddMembersProps
{
    onCancel(): void
}

const AddCompanyMembers = observer(function AddCompanyMembers({onCancel}: AddMembersProps)
{
    const [firstName, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [profile, setProfile] = useState('exhibitorKeyAccount');

    const handleUpdate = (e) =>
    {
        switch (e.target.name)
        {
            case 'name': setName(e.target.value); break;
            case 'lastName': setLastName(e.target.value); break;
            case 'email': setEmail(e.target.value); break;
            case 'phone': setPhone(e.target.value); break;
            case 'profile': setProfile(e.target.value); break;
        }
    };

    const handleCreate = async () =>
    {
        const res = await organizationStore.addCompanyMember({firstName, lastName, email, phone, profile});
        if (!res)
        {
            return;
        }
        const {code, message} = res;
        Swal.fire(swalModal(code, message));
        if (code === 'success')
        {
            setName('');
            setLastName('');
            setEmail('');
            setPhone('');
        }
    };

    const handleCancel = () => onCancel();

    return (
        <div className='add-company-members d-flex flex-column mt-3'>
            <input className='form-control' name='name' value={firstName} onChange={handleUpdate} placeholder={t.registration.form.firstname}/>
            <input className='form-control mt-2' name='lastName' value={lastName} onChange={handleUpdate} placeholder={t.registration.form.lastname}/>
            <input className='form-control mt-2' name='email' value={email} onChange={handleUpdate} placeholder='Email'/>
            <input className='form-control mt-2' name='phone' value={phone} onChange={handleUpdate} placeholder={t.authentication.phonePlaceholder}/>
            <select value={profile} onChange={handleUpdate} name='profile' className='form-control mt-2'>
                {availableProfiles.map(p =>
                    <option key={p} value={p}>{pickLanguage(findProfile(p)?.name)}</option>
                )}
            </select>
            <div className='d-flex mt-3'>
                <button className='button' onClick={handleCreate}>{t.global.submit}</button>
                <button className='button-empty ml-2' onClick={handleCancel}>{t.global.cancel}</button>
            </div>
        </div>
    );
});

const availableProfiles = ['exhibitorMainAccount', 'exhibitorKeyAccount', 'exhibitorFinance', 'exhibitorInstallator', 'exhibitorRep'];
const allowedToUse = ['exhibitorMainAccount', 'exhibitorKeyAccount'];
