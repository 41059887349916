// expires after 60s
export function objectUrlForBase64(base64Data: string, contentType?: string)
{
    return objectUrlForBlob(base64toBlob(base64Data, contentType));
}

// expires after 60s
export function objectUrlForText(text: string, contentType= 'text/plain')
{
    return objectUrlForBlob(new Blob([text], {type: contentType}));
}

// expires after 60s
export function objectUrlForBlob(blob: Blob)
{
    const url = URL.createObjectURL(blob);
    setTimeout(() =>
    {
        URL.revokeObjectURL(url);
    }, 6e4); // 60s
    return url;
}

export function base64toBlob(base64Data: string, contentType?: string, fileName?: string)
{
    const byteCharacters = atob(base64Data);
    const bytes = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++)
    {
        bytes[i] = byteCharacters.charCodeAt(i);
    }
    return fileName ? new File([bytes], fileName, {type: contentType}) : new Blob([bytes], {type: contentType});
}
