import React, {createContext, DragEvent, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Link, Navigate, useLocation, useNavigate} from 'react-router-dom';
import {action} from 'mobx';
import {observer} from 'mobx-react-lite';
import GridLayout from 'react-grid-layout';
import type {Layout} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import FA from 'react-fontawesome';
import Swal from 'sweetalert2';
import './Page.scss';
import {IImportedPageItem, IPageItem, PageItemType} from '../../graphql/api/page/Page';
import {generateBlockId, IPage, pageStore} from './PageStore';
import {mapItemToBlock, BlockAccessProps} from './blockMap';
import {projectStore} from '../stores/ProjectStore';
import {pagePath} from './utility';
import {profiles} from '../data/profiles';
import {user} from '../stores/user';
import {ErrorBoundary} from '../common/ErrorBoundary';
import CatalogOptions from './CatalogOptions';
import PageCatalog from './PageCatalog';
import { filterProductsByStore } from './ProductBlock';
import DropdownSearch from '../common/DropdownSearch';
import { t } from '../translations';
import {pagesStore} from './PagesStore';
import {companyStore} from '../Company/CompanyStore';
import {debouncedLogView} from '../stores/logView';
import {detectContactButtonChange} from '../../graphql/api/page/detectContactButtonChange';
import {contactButtonChatsExist} from '../Chat/chatProxy';
import PageStats from './PageStats';
import {pageConfig} from './pageConfig';
import DroppableElement from './DroppableElement';
import AddBlock from './AddBlock';
import PageCatalogOptions from './PageCatalogOptions';
import PageSettings from './PageSettings';
import ExhibitorLogos from './ExhibitorLogos';
import isBlockEmpty from './isBlockEmpty';
import BrandImageCreator from './BrandImageCreator';
import {getName} from '../common/getName';
import {formatRelativeTime} from '../common/timeFormatting';

export interface IItemOptions
{
    type?: PageItemType
    isResizable?: boolean
    size?: {w: number, h: number}
}

const {cols, height, padding, totalWidth} = pageConfig;

export const PageScaleProvider = createContext(height);

export function usePageUnits()
{
    const scale = useContext(PageScaleProvider);
    return {
        defaultHeight: height,
        padding,
        rowHeight: height * scale,
        heightToUnits: scale / (height + padding),
    };
}
export function convertToPageSize(pixels: number)
{
    return (pixels + pageConfig.padding) / (pageConfig.width + pageConfig.padding);
}
export function usePageScale()
{
    return useContext(PageScaleProvider);
}

function MatchRowHeight<P extends {cols?: number, width?: number}>(ComposedComponent: React.ComponentType<P & {rowHeight?: number}>)
{
    return function _MatchRowHeight(p: P & {cols?: number, width?: number})
    {
        const scale = p.width / totalWidth;
        const scaledHeight = height * scale;
        const scaledPadding = padding * scale;
        return (
            <PageScaleProvider.Provider value={scale}>
                <ComposedComponent {...p} rowHeight={scaledHeight} margin={[scaledPadding, scaledPadding]}/>
            </PageScaleProvider.Provider>
        );
    };
}

const GridLayoutFlexible = GridLayout.WidthProvider(MatchRowHeight(GridLayout));

export default observer(function Page({id}: {id?: string})
{
    const params = useParams<{id: string}>();
    id = id || params.id;
    const navigate = useNavigate();
    const location = useLocation();
    const isRestricted = !(user.moderator || companyStore.ownCompanies.every(c => !c || !c.exhibitor?.preventPageEdit));
    const locationHash = location.hash.substring(1);
    const editMode = locationHash == 'edit' && !isRestricted;
    const versionsMatch = locationHash.match(/^versions(\/(.+))?$/);
    const versionsMode = !!versionsMatch && !isRestricted;
    const versionDate = versionsMatch?.[2];
    const previewProfile = profiles.find(p => p.id == locationHash)?.id;

    const [startTime, setStartTime] = useState(Date.now());

    useEffect(() =>
    {
        if (id)
        {
            pageStore.resetAllItemsLayout(id);
            pageStore.load(id);
            setStartTime(Date.now());
        }
    }, [id]);
    useEffect(() =>
    {
        if (versionsMode)
        {
            pageStore.loadVersions(id);
        }
    }, [id, versionsMode]);

    const page = versionsMode ?
        versionDate ?
            pageStore.versions.find(v => v.id == id)?.versions?.find(v => v.updatedAt == versionDate)
            :
            pageStore.getPage(id)
        :
        editMode ?
            pageStore.getChangedPage(id)
            :
            pageStore.getActivePage(id);
    const hasChanges = page?.changed;

    useEffect(() =>
    {
        if (page?.project && !(page.project in pagesStore.viewAccess))
        {
            pagesStore.checkHasAccess(page.project);
        }
    }, [page?.project]);

    useEffect(() =>
    {
        if (page && page.project)
        {
            debouncedLogView({type: 'page', project: page.project, page: page.id});
        }
    }, [page]);

    useCheckPageProject(page);

    const canAddItems = canAddPageItems(page);
    const canEditContent = canEditPageContent(page);
    const [showOptions, setShowOptions] = useState(false);
    const [showStats, setShowStats] = useState(false);

    const [itemSize, setItemSize] = useState({w: 4, h: 2});
    const [isDragging, setIsDragging] = useState(false);
    const handleDragStart = (e: React.DragEvent) =>
    {
        setIsDragging(true);
        setItemSize((JSON.parse(e.dataTransfer.getData('text/x-item')) as IItemOptions).size);
    };
    const handleDragEnd = () =>
    {
        setIsDragging(false);
    };

    if (!page)
    {
        if (versionsMode && pageStore.versions.some(v => v.id == id))
        {
            return <Navigate to={`/page/${id}#versions`}/>;
        }
        return null;
    }

    if (!editMode && hasChanges)
    {
        return <Navigate to={pagePath(page) + '#edit'}/>;
    }

    const children: ((IPageItem | (IPageItem & IImportedPageItem)) & {imported?: boolean})[] = page ?
        editMode ?
            page.importedItems ?
                page.importedItems.map(i => ({...i, imported: true})).concat(page.items as any) :
                page.items
            :
            filterDisplayedItems(
                page.importedItems ?
                    [
                        ...page.importedItems.filter(i => i.published !== false && i.showOnWeb !== false && i.props.showOnWeb !== false),
                        ...page.items.filter(i => i.props.showOnWeb !== false),
                    ] :
                    page.items.filter(i => i.props.showOnWeb !== false),
                page,
                previewProfile,
            )
        :
        [];

    const fullAccess = user.moderator || hasPageEditAccess(page);
    const editAccess = fullAccess || hasPagePartialEditAccess(page);
    const blockAccess: BlockAccessProps = {
        editMode,
        fullAccess,
        editAccess,
        enableBlockOptions: editMode && editAccess,
    };

    const editVersionMode = editMode || versionsMode;
    return (
        <div className={'Page' + (editVersionMode ? ' page-edit' : '') + (editVersionMode && canAddItems ? ' with-blocks' : '') + (editVersionMode && !!page.catalog?.active ? ' edit-catalog' : '')}>
            {canEditContent && !editVersionMode && !isRestricted &&
            <div className='d-flex justify-content-between align-items-center flex-wrap'>
                {user.moderator ?
                    <>
                        <div className='setting-toggle mb-3' onClick={() => setShowStats(!showStats)}>
                            <FA name={showStats ? 'caret-up' : 'caret-down'}/>Stats
                        </div>
                        <DropdownSearch
                            className='mb-3'
                            options={[{name: 'View Full', value: ''}].concat(
                                profiles.filter(p => page.viewAccess?.includes(p.id)).map(p => ({name: p.name[user.language], value: p.id})))}
                            selected={previewProfile || ''}
                            onSelect={s => navigate('#' + s)}
                            arrow
                        />
                    </>
                    :
                    <div/>
                }
                <div className='d-flex flex-row-reverse mb-3'>
                    <Link to='#edit' className='unstyle-link button ml-3' onClick={() => {pageStore.getChangedPage(id); setShowStats(false); setStartTime(Date.now())}}>
                        {t.editPage.edit}
                    </Link>
                    <Link to={'/page/mobile/' + id} className='unstyle-link button ml-3'>Mobile</Link>
                    {user.moderator &&
                    <Link to={page.project ? '/pages/project' : '/pages/global'} className='unstyle-link button'>
                        {page.project ? t.pages.projectPages : t.pages.globalPages}
                    </Link>
                    }
                </div>
            </div>
            }
            {showStats && <PageStats id={id}/>}
            {canEditContent && editMode &&
            <div className='d-flex justify-content-between mb-3'>
                {/*<div>*/}
                {/*    <button*/}
                {/*        className='button'*/}
                {/*        onClick={() => {pageStore.newPageFromPage(id).then(newPage => history.replace(pagePath(newPage) + '#edit'))}}*/}
                {/*    >*/}
                {/*        New page from this page*/}
                {/*    </button>*/}
                {/*</div>*/}
                {user.moderator ?
                    <div><Link to='#versions' className='unstyle-link button' onClick={() => {setStartTime(Date.now())}}>Versions</Link></div>
                    :
                    <div/>
                }
                <div>
                    <button
                        className={'ml-3 ' + (hasChanges ? 'button' : 'button-empty')}
                        onClick={() => {setStartTime(Date.now()); pageStore.cancelChanges(id); setShowOptions(false); navigate('#')}}
                    >
                        {t.global.cancel}
                    </button>
                    <button
                        className={'ml-3 ' + (hasChanges ? 'button' : 'button-empty')}
                        onClick={hasChanges ? async () =>
                        {
                            let addNewContactsToChats = false;
                            const changedContactButtons = detectContactButtonChange(
                                pageStore.pages.find(p => p.id == id).items,
                                pageStore.changes.find(p => p.id == id).items,
                            );
                            if (changedContactButtons)
                            {
                                const existingChats = await contactButtonChatsExist(changedContactButtons.map(b => ({page: id, block: b.block})));
                                if (existingChats)
                                {
                                    addNewContactsToChats = await Swal.fire({
                                        title: `Show ${existingChats} already existing chats to new chat admin?`,
                                        html: 'Otherwise they will only see new chats.',
                                        icon: 'question',
                                        showCancelButton: true,
                                        confirmButtonText: 'Yes',
                                        cancelButtonText: 'No',
                                    }).then(r => !!r.value);
                                }
                            }
                            setStartTime(Date.now());
                            pageStore.saveChanges(id, addNewContactsToChats).then(res => {if (res) {setShowOptions(false); navigate('#')}});
                        } : null}
                    >
                        {t.editPage.save}
                    </button>
                </div>
            </div>}
            {canEditContent && versionsMode &&
            <div className='mb-3 ml-auto'>
                <button
                    className={'ml-3 ' + (hasChanges ? 'button' : 'button-empty')}
                    onClick={() => {setStartTime(Date.now()); pageStore.cancelChanges(id); setShowOptions(false); navigate('#edit')}}
                >
                    {t.global.cancel}
                </button>
                <button
                    className={'ml-3 ' + (versionDate ? 'button' : 'button-empty')}
                    onClick={versionDate ? async () =>
                    {
                        setStartTime(Date.now());
                        pageStore.revertToVersion(id, versionDate).then(res =>
                        {
                            if (res)
                            {
                                navigate('#');
                            }
                        });
                    } : null}
                >
                    Revert
                </button>
            </div>}
            {editVersionMode && user.moderator &&
            <div className='setting-toggle' onClick={() => setShowOptions(!showOptions)}>
                <FA name={showOptions ? 'caret-up' : 'caret-down'}/>
                {showOptions ? 'Hide page settings' : 'Show page settings'}
            </div>}
            {showOptions && <PageSettings page={page}/>}
            <div className='page' onDragOver={preventDefault}>
                {editMode && canAddItems &&
                <div className='element-selection'>
                    <div className='sticky'>
                        <div className='text-center font-weight-bold'>{t.editPage.dragAndDrop}</div>
                        <DroppableElement onDragStart={handleDragStart} onDragEnd={handleDragEnd} type='content' name={t.editPage.blockTextImageLink} w={4} h={2} isResizable/>
                        {/*<DroppableElement onDragStart={handleDragStart} onDragEnd={handleDragEnd} type='product' name={t.editPage.blockProductPreview} w={4} h={2}/>*/}
                        <DroppableElement onDragStart={handleDragStart} onDragEnd={handleDragEnd} type='video' name={t.editPage.blockVideo} w={8} h={4.581}/>
                        <DroppableElement onDragStart={handleDragStart} onDragEnd={handleDragEnd} type='slideshow' name={t.editPage.blockSlideshow} w={8} h={4.581}/>
                        <DroppableElement onDragStart={handleDragStart} onDragEnd={handleDragEnd} type='article' name={t.editPage.blockArticle} w={8} h={4} isResizable/>
                        <DroppableElement onDragStart={handleDragStart} onDragEnd={handleDragEnd} type='file' name={t.editPage.blockFileDownload} w={4} h={2} isResizable/>
                        <AddBlock id={id}/>
                        {user.moderator && <ExhibitorLogos page={page}/>}
                        <PageCatalogOptions page={page}/>
                    </div>
                </div>}
                {versionsMode && <PageVersionList id={id}/>}
                <div className='page-content'>
                    <GridLayoutFlexible
                        measureBeforeMount
                        key={locationHash + page.rerender}
                        isDroppable={editMode && canAddItems && isDragging}
                        isResizable={editMode && canAddItems}
                        isDraggable={editMode && canAddItems}
                        cols={cols}
                        // width={totalWidth}
                        // rowHeight={height}
                        // margin={[padding, padding]}
                        layout={children.map(i => ({
                            ...i.layout,
                            i: i.id,
                            isResizable: editMode && canAddItems && !i.imported && i.layout.isResizable,
                            isDraggable: editMode && canAddItems
                        }))}
                        onLayoutChange={editMode && canEditContent ?
                            (layout: Layout[]) =>
                            {
                                if (Date.now() - startTime < 1000)
                                {
                                    return;
                                }
                                pageStore.applyLayout(id, layout);
                            } : () => {}
                        }
                        droppingItem={isDragging ? {
                            i: generateBlockId(page),
                            w: itemSize.w,
                            h: itemSize.h,
                        } : null}
                        onDrop={editMode ? action(((layout: Layout[], item: Layout, event: DragEvent) =>
                        {
                            if (item)
                            {
                                pageStore.appendItem(id, {
                                    id: item.i,
                                    layout: {
                                        x: item.x,
                                        y: item.y,
                                        w: item.w,
                                        h: item.h,
                                        isResizable: item.isResizable,
                                    },
                                    type: (JSON.parse(event.dataTransfer.getData('text/x-item')) as IItemOptions).type,
                                    props: {
                                        viewAccess: page.viewAccess?.slice()
                                    }
                                });
                                pageStore.applyLayout(id, layout);
                            }
                        })) as any : null}
                    >
                        {children.map((i) =>
                            <div
                                key={i.id}
                                style={i.id == page.focused ? {zIndex: 1} : null}
                            >
                                {mapItemToBlock(id, i, blockAccess)}
                            </div>
                        )}
                    </GridLayoutFlexible>
                    {editVersionMode && canAddItems && !!page.catalog?.active &&
                    <CatalogOptions page={page}/>}
                    {!!page.catalog?.active && projectStore.canSeeProducts &&
                    <ErrorBoundary><PageCatalog page={page} previewProfile={previewProfile}/></ErrorBoundary>}
                </div>
            </div>
            {editMode && user.moderator && <BrandImageCreator/>}
        </div>
    );
});

const PageVersionList = observer(function PageVersionList({id}: {id: string})
{
    const location = useLocation();
    const locationHash = location.hash;
    return (
        <div className='versionList'>
            <div className='sticky'>
                <div className='title text-center font-weight-bold'>Versions</div>
                {(pageStore.versions.find(v => v.id == id)?.versions || [pageStore.getActivePage(id)]).map((v, i) =>
                {
                    const versionHash = i ? '#versions/' + v.updatedAt : '#versions';
                    return (
                        <div key={v.updatedAt as string} className={'version clearfix' + (locationHash == versionHash ? ' selected' : '')}>
                            {v.updatedBy && <Link to={'/contact/' + v.updatedBy.id} className='name'>{getName(v.updatedBy)}</Link>}
                            <Link to={versionHash} className='time'>{formatRelativeTime(new Date(v.updatedAt))}</Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

const preventDefault = e => e.preventDefault();

function hasPageEditAccess(page: IPage)
{
    return !!page?.editAccess?.some(a =>
        user.id == a ||
        (user.info && user.info.company?.includes(a) && user.info.profiles?.some(p => ['exhibitorMainAccount', 'exhibitorKeyAccount'].includes(p.id)))
    );
}

function hasPagePartialEditAccess(page: IPage)
{
    return !!page?.partialEditAccess?.some(a =>
        user.id == a ||
        (user.info && user.info.company?.includes(a) && user.info.profiles?.some(p => ['exhibitorMainAccount', 'exhibitorKeyAccount'].includes(p.id)))
    );
}

export function canAddPageItems(page: IPage)
{
    return user.moderator || hasPageEditAccess(page);
}

function canEditPageContent(page: IPage)
{
    return user.moderator || hasPageEditAccess(page) || hasPagePartialEditAccess(page);
}

// function hasFullViewAccess(page: IPage)
// {
//     return !!page && (user.moderator || !!pagesStore.hasAccess);
// }

// export function useEditOptions()
// {
//     const {id} = useParams<{id: string}>();
//     const location = useLocation();
//     const editMode = location.hash == '#edit';
//     const fullAccess = user.moderator || hasPageEditAccess(pageStore.pages.find(p => p.id == id));
//     const editAccess = fullAccess || hasPagePartialEditAccess(pageStore.pages.find(p => p.id == id))
//     return {
//         editMode,
//         fullAccess,
//         editAccess,
//         enableBlockOptions: editMode && editAccess,
//     };
// }

export function filterDisplayedItems(items: (IPageItem | (IPageItem & IImportedPageItem))[], page: IPage, previewProfile?: string)
{
    return items.filter(i =>
        (!('published' in i) || i.published) &&
        (Array.isArray(i.props?.items) ? i.props?.items.some(item => item.published) : i.props?.published) &&
        (i.type !== 'product' || i.props?.items?.filter(filterProductsByStore).length) &&
        (!previewProfile || i.props?.viewAccess?.includes(previewProfile)) &&
        !isBlockEmpty(i.type, i.props)
    );
}

export function useCheckPageProject(page: IPage)
{
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() =>
    {
        if (page && page.project && projectStore.initialLoad)
        {
            if (!projectStore.selectableProjects.some(p => p.id == page.project))
            {
                navigate('/', {replace: true});
            }
            else
            {
                if (projectStore.id != page.project)
                {
                    projectStore.select(page.project);
                }
                if (location.pathname.length > 1)
                {
                    const currentPath = decodeURIComponent(location.pathname);
                    let targetPath = decodeURIComponent(pagePath(page));
                    if (currentPath.startsWith('/page/mobile/'))
                    {
                        targetPath = targetPath.replace('/page/', '/page/mobile/');
                    }
                    if (currentPath != targetPath)
                    {
                        navigate(targetPath + location.hash, {replace: true});
                    }
                }
            }
        }
    }, [page, page?.project, location, projectStore.initialLoad, projectStore.selectableProjects.some(p => p.id == page?.project)]);
}
