// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AttachmentTag {
  z-index: 0;
  display: inline-block;
  position: relative;
  background-color: #f7f8f7;
  border-radius: 1rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
  cursor: pointer;
}
.AttachmentTag.removed {
  background-color: #f85534;
}
.AttachmentTag.added {
  background-color: #6df85c;
}
.AttachmentTag span {
  z-index: 1;
  display: inline-block;
  min-width: 60px;
  margin: 5px 20px;
  transition: background-color 3s ease-in-out;
}
.AttachmentTag .progress {
  z-index: -1;
  border-radius: 1rem;
  background-color: #75b800;
  position: absolute;
  top: 0;
  height: 100%;
  transition: width 0.2s ease-in-out;
}
.AttachmentTag .pending {
  position: relative;
  background: linear-gradient(90deg, #000, #fff, #000) no-repeat;
  background-size: 80%;
  animation: pendingTextAnimation 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.2);
}

@keyframes pendingTextAnimation {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}`, "",{"version":3,"sources":["webpack://./frontend/Attachment/AttachmentTag.scss"],"names":[],"mappings":"AACA;EACE,UAAA;EACA,qBAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;AAAF;AACE;EACE,yBAAA;AACJ;AACE;EACE,yBAAA;AACJ;AACE;EACE,UAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,2CAAA;AACJ;AACE;EACE,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,MAAA;EACA,YAAA;EACA,kCAAA;AACJ;AACE;EACE,kBAAA;EACA,8DAAA;EACA,oBAAA;EACA,kDAAA;EACA,6BAAA;EACA,2CAAA;AACJ;;AAGA;EACE;IACE,0BAAA;EAAF;EAEA;IACE,yBAAA;EAAF;AACF","sourcesContent":["\n.AttachmentTag {\n  z-index: 0;\n  display: inline-block;\n  position: relative;\n  background-color: #f7f8f7;\n  border-radius: 1rem;\n  margin-right: 1rem;\n  margin-top: .5rem;\n  cursor: pointer;\n  &.removed {\n    background-color: #f85534;\n  }\n  &.added {\n    background-color: #6df85c;\n  }\n  span {\n    z-index: 1;\n    display: inline-block;\n    min-width: 60px;\n    margin: 5px 20px;\n    transition: background-color 3s ease-in-out;\n  }\n  .progress {\n    z-index: -1;\n    border-radius: 1rem;\n    background-color: #75b800;\n    position: absolute;\n    top: 0;\n    height: 100%;\n    transition: width .2s ease-in-out;\n  }\n  .pending {\n    position: relative;\n    background: linear-gradient(90deg, #000, #fff, #000) no-repeat;\n    background-size: 80%;\n    animation: pendingTextAnimation 3s linear infinite;\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: rgba(0, 0, 0, .2);\n  }\n}\n\n@keyframes pendingTextAnimation {\n  0% {\n    background-position: -500%;\n  }\n  100% {\n    background-position: 500%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
