// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextEditable {
  min-height: 1em;
  min-width: 1em;
}
.TextEditable.disabled {
  color: #777;
}`, "",{"version":3,"sources":["webpack://./frontend/common/TextEditable.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;AACF;AAAE;EACE,WAAA;AAEJ","sourcesContent":[".TextEditable {\n  min-height: 1em;\n  min-width: 1em;\n  &.disabled {\n    color: #777;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
