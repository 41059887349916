import {ReactNode, useState} from 'react';
import './MultiForm.scss';

interface MultiFormProps<T = any>
{
    className?: string
    tabs: MultiFormTab<T>[]
    render(tab: T): ReactNode
}

export interface MultiFormTab<T = any>
{
    name: string
    value: T
}

export function MultiForm<T = any>(p: MultiFormProps<T>)
{
    const [selected, setSelected] = useState(0);
    return (
        <div className={'MultiForm ' + (p.className || '')}>
            <div className='MultiFormTabs'>
                {p.tabs.map((t, i) =>
                    <span key={i} className={selected == i ? 'selected' : null} onClick={() => setSelected(i)}>
                        {t.name}
                    </span>
                )}
            </div>
            <div className='MultiFormContent'>
                {p.render(p.tabs[selected]?.value)}
            </div>
        </div>
    );
}
