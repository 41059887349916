import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {orderStandStore} from './OrderStandStore';
import {getPalletTypes, IPalletType} from './OrderStandProxy';
import {projectStore} from '../stores/ProjectStore';
import {pickLanguage} from '../stores/utility';
import {t} from '../translations';
import {user} from '../stores/user';

export default observer(function SelectPalletType()
{
    const sel = orderStandStore.selected;
    const palletType =
        sel.created.package?.palletType ||
        sel.submitted.package?.palletType ||
        sel.approved.package?.palletType ||
        sel.invoiced?.package?.palletType;

    const [options, setOption] = useState<IPalletType[]>();

    const country = projectStore.selectedCountry;

    useEffect(() =>
    {
        getPalletTypes(country).then(setOption);
    }, [country]);

    const palletTypeDeadline = projectStore.selected?.fair.settings.palletTypeDeadline;

    return !palletType || !palletTypeDeadline || +new Date(palletTypeDeadline) > Date.now() || user.moderator ?
        <div className='mt-3 mb-2'>
            <p className='text-large text-black font-2'>
                {t.standCreation.simulation.palletType}
            </p>
            <select value={palletType || ''} onChange={e => orderStandStore.changePalletType(e.currentTarget.value)}>
                <option value='' disabled>{t.standCreation.simulation.selectPalletType}</option>
                {options?.map(o =>
                    <option key={o.id} value={o.id}>{pickLanguage(o.name)}</option>
                )}
            </select>
        </div>
        :
        <div className='mt-3 mb-2'>
            <p className='text-large text-black font-2'>
                {t.standCreation.simulation.palletType}
                {' : '}
                {palletType ? options ? pickLanguage(options.find(p => p.id === palletType)?.name) || palletType : t.global.loading : t.standCreation.simulation.notSelected}
            </p>
        </div>;
});
