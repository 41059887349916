import styles from './SelectAccount.module.css';
import Button from '../components/Button';
import {EventUsersListItem} from '../../Event/eventsProxy';
import {getName} from '../../common/getName';
import erStyles from './EventRegistration.module.css';
import {t} from '../../translations';

interface Props
{
    account: EventUsersListItem
    onSelectSelf: () => void
    onSelectGuests: () => void
    onBack: () => void
}

export default function SelectYourselfOrGuest({account, onSelectSelf, onSelectGuests, onBack}: Props)
{
    return (
        <>
            <div className={erStyles.title}>
                <img className={erStyles.icon} src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/select-account-type.svg' alt=''/>
                <h2>Do you want to invite guests?</h2>
            </div>
            <div className={erStyles.content}>
                <div>Logged in as <b>{getName(account)}</b></div>
                <div className={erStyles.buttons}>
                    <Button className={styles.account} onClick={onSelectSelf}>Edit your own participation</Button>
                    <Button className={styles.account} onClick={onSelectGuests}>Edit your guests</Button>
                </div>
                <div className={erStyles.buttons}>
                    <Button className='mr-3 mb-3' onClick={onBack}>
                        <img className='mr-2' src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/back.svg' alt=''/>
                        {t.eventRegistration.back}
                    </Button>
                </div>
            </div>
        </>
    );
}
