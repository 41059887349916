import React from 'react';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import moment from 'moment';
import './TimelineInvoice.scss';
import {TimelineRow, TimelineItem} from './TimelineItem';
import {formatTimelineDate} from '../common/timeFormatting';
import {IInvoice} from '../Invoice/InvoiceStore';
import {downloadInvoice} from '../Invoice/invoiceProxy';
import {objectUrlForBase64} from '../common/blobUtility';
import {user} from '../stores/user';
import {projectCurrency} from '../OrderStand/utility';
import { t } from '../translations';
import { timelineStandStore } from './TimelineStandStore';

export default observer(function TimelineInvoice({id, number: num, status, to, standId, date, dueDate, total}: IInvoice)
{
    const [unfolded, setUnfolded] = React.useState(false);
    const [download, setDownload] = React.useState('download');

    const displayDate = moment(date).format('D MMMM YYYY');

    const handleFold = () =>
    {
        setUnfolded(!unfolded);
    };
    const handleDownloadInvoice = (e: React.MouseEvent<HTMLAnchorElement>) =>
    {
        if (download == 'downloading')
        {
            return;
        }
        setDownload('downloading');
        e.preventDefault();
        const a = e.currentTarget;
        const name = 'Invoice ' + (num || id) + '.pdf';
        downloadInvoice(id).then(pdf =>
        {
            if (pdf)
            {
                a.href = objectUrlForBase64(pdf, 'application/pdf');
                a.download = name;
                a.click();
                setDownload('download');
            }
            else
            {
                setDownload('failed');
            }
        })
    };

    const stand = timelineStandStore.stands.find(s => s.id === standId);
    const totalAmount = user.formatCurrency(projectCurrency(stand?.projectId), total.value * total.vat);

    return (
        <TimelineItem
            className='timeline-invoice'
            withLine
            mark={<FA name='file-invoice' className='fad'/>}
        >
            <TimelineRow
                className='top'
                onClick={handleFold}
                content={<>
                    <div className='d-flex'>
                        <div className='to'>
                            <span>
                                <b>{to.personName} </b>
                                <span
                                    dangerouslySetInnerHTML={
                                        {__html: status == 'cancel' ? t.timelineInvoice.receivedCreditNote : t.timelineInvoice.receivedInvoice}
                                    }
                                />
                            </span>
                        </div>
                        {!unfolded && (
                            <div className='info ml-5'>
                                <span className='mr-1'>{num}</span> |
                                <span className='ml-2 mr-2'>{displayDate}</span> |
                                <span className='ml-2'>{totalAmount}</span>
                            </div>
                        )}
                    </div>
                </>}
                aside={formatTimelineDate(date)}
            />
        {unfolded && (
            <div className='invoiceBody'>
                {!!num && <p><b>{status == 'cancel' ? t.timelineInvoice.creditNoteNumber : t.timelineInvoice.invoiceNumber}</b> {num}</p>}
                <p><b>{t.timelineInvoice.date}</b> {displayDate}</p>
                <p><b>{t.timelineInvoice.amount}</b> {totalAmount}</p>
                <p><a href='#' target='_blank' onClick={handleDownloadInvoice}>{t.timelineInvoice.pdf}</a></p>
            </div>
        )}
        </TimelineItem>
    )
});
