import {call, gql} from '../client';
import {IChatParticipant} from '../../graphql/api/chat/Chat';
import {Attachment} from '../../graphql/api/attachments/Attachment';

export interface IComment
{
    id: string
    author: IChatParticipant
    targetId: string
    text: string
    date: Date
    attachments?: Attachment[]
}

export function loadComments(targetId: string)
{
    return call<{comments: IComment[]}>(gql`query($targetId:ID!){comments(targetId:$targetId){id,author{id,firstName,lastName},targetId,text,date,attachments{name,key}}}`, {targetId})
    .then(({data}) =>
    {
        if (!data) return null;
        for (const comment of data.comments)
        {
            comment.date = new Date(comment.date);
        }
        return data.comments;
    });
}

export function callAddComment(targetId: string, text: string, attachments: string[])
{
    return call<{addComment: IComment}>(gql`mutation($input:CommentInput!){addComment(input:$input){id,author{id,firstName,lastName},targetId,text,date,attachments{name,key}}}`,
        {input: {targetId, text, attachments}})
    .then(({data}) =>
    {
        if (!data) return null;
        const comment = data.addComment;
        comment.date = new Date(comment.date);
        return comment;
    });
}

export function callEditComment(id: string, text: string, removeAttachments?: string[], addAttachments?: string[])
{
    return call<{editComment: IComment}>(gql`mutation($input:EditCommentInput!){editComment(input:$input){text,attachments{name,key}}}`,
        {input: {id, text, removeAttachments, addAttachments}}).then(({data}) => data?.editComment);
}

export function callDeleteComment(id: string)
{
    return call(gql`mutation($id:ID!){deleteComment(id:$id)}`, {id});
}
