import {observer} from 'mobx-react-lite';
// import './OrdersPage.scss';
import {projectStore} from '../stores/ProjectStore';
import OrdersPageAllProjects from './OrdersPageAllProjects';
import OrdersPageSingleProject from './OrdersPageSingleProject';

export default observer(function OrdersPage()
{
    return projectStore.isFRNonFood ? <OrdersPageAllProjects/> : <OrdersPageSingleProject/>;
});
