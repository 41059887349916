import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import './OrdersPage.scss';
import {projectStore} from '../stores/ProjectStore';
import {orderStore} from './OrderStore';
import {user} from '../stores/user';
import {companyStore} from '../Company/CompanyStore';
import {orderTotalsSum} from '../../graphql/api/order/utility';
import {IOrder} from './orderProxy';
import StoreExhibitorOrderCard from './StoreExhibitorOrderCard';
import {catalogProductStore} from './CatalogProductStore';
import CartOrderTotal from './CartOrderTotal';
import {debouncedLogView} from '../stores/logView';
import {Loading} from '../common/Loading';
import {t} from '../translations';
import {flat} from '../../lib/common';

export default observer(function OrdersPageSingleProject()
{
    const [wasLoading, setWasLoading] = useState(false);
    useEffect(() =>
    {
        projectStore.withId(pid =>
        {
            debouncedLogView({type: 'orders', project: pid});
            orderStore.load(pid);
            setWasLoading(true);
        });
    }, [projectStore.id]);

    const orders = (orderStore.orders[projectStore.id] || []).filter(c => c.products.length);

    const [selectedCompany, selectCompany] = useState<string>(() => user.info?.company[0]);
    const ownStores = companyStore.ownStores;
    const store = ownStores.find(c => c.id == selectedCompany);
    const storeIndex = ownStores.findIndex(c => c.id == selectedCompany);

    const storeId = store?.store?.id;
    const currentStoreOrders = orders.filter(o => o.store == storeId);
    const total = orderTotalsSum(currentStoreOrders.map(o => o.total));

    const byExhibitor: {exhibitor: string, exhibitorName: string, orders: IOrder[]}[] = [];
    const productIds = new Set<string>();
    for (const order of currentStoreOrders)
    {
        const group = byExhibitor.find(g => g.exhibitor == order.exhibitor);
        if (group)
        {
            group.orders.push(order);
        }
        else
        {
            byExhibitor.push({
                exhibitor: order.exhibitor,
                exhibitorName: order.exhibitorName,
                orders: [order],
            });
        }
        for (const p of order.products)
        {
            productIds.add(p.id);
        }
    }
    const shouldLoadProductIds = [...productIds].filter(pid => !catalogProductStore.products.some(p => p.id == pid));
    useEffect(() =>
    {
        if (shouldLoadProductIds.length)
        {
            catalogProductStore.loadProducts(shouldLoadProductIds);
        }
    }, [shouldLoadProductIds.join('')]);

    if (orderStore.loading || !wasLoading)
    {
        return (
            <div style={{height: '440px', position: 'relative'}}>
                <Loading/>
            </div>
        );
    }

    const storeType = store?.store?.storeType;

    return (
        <div className='OrdersPage container'>
            <div>
                <div className='StoreOrderCard'>
                    <p className='store'>
                        {storeIndex > 0 &&
                        <FA
                            name='chevron-left'
                            className='fas text-secondary cursor-pointer px-2'
                            onClick={() => selectCompany(ownStores[storeIndex - 1].id)}
                        />}
                        <span className='store-name'>{storeId} {store?.name}</span>
                        {storeIndex < ownStores.length - 1 &&
                        <FA
                            name='chevron-right'
                            className='fas text-secondary cursor-pointer px-2'
                            onClick={() => selectCompany(ownStores[storeIndex + 1].id)}
                        />}
                    </p>
                    <CartOrderTotal
                        storeType={storeType}
                        {...total}
                    />
                </div>
            </div>
            <div className='exhibitors'>
                <h1>{t.global.navBar.orders}</h1>
                {flat(byExhibitor.map(e => e.orders.map(o => ({...o, exhibitorName: e.exhibitorName}))))
                    .sort((a, b) => +new Date(b.date) - +new Date(a.date))
                    .map(o =>
                        <StoreExhibitorOrderCard key={o.id} exhibitorName={o.exhibitorName} order={o} storeType={storeType}/>
                    )
                }
            </div>
        </div>
    );
});
