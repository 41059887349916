import {useState} from 'react';
import {Link} from 'react-router-dom';
import Form from '../components/Form';
import Button from '../components/Button';
import {LoginMethodProps} from '../EventRegistrationFormPage';
import {user} from '../../stores/user';
import styles from './Login.module.css';
import {t} from '../../translations';

export default function EnterBadgeCode({onLogin, projectId}: LoginMethodProps & {projectId: string})
{
    const [regId, setRegId] = useState(() => user.info?.badges?.find(b => b.project == projectId)?.registrationId || '');
    const [error, setError] = useState<string>();
    const confirm = () => onLogin({value: regId, method: 'RegID'}).then(e =>
    {
        if (e)
        {
            setError(e);
        }
    });
    return (
        <Form className={styles.content} onSubmit={confirm}>
            <h3>{t.eventRegistration.modifyRegistration}</h3>
            <div className={styles.badgeCodeLabel}>{t.eventRegistration.yourBadgeCode}</div>
            <input
                className={styles.badgeCodeInput}
                type='text'
                value={regId}
                onChange={e => setRegId(e.currentTarget.value)}
                placeholder='123ABCD'
                required
                autoFocus
            />
            {error && <div className={styles.error}>{error}</div>}
            <Button type='submit' primary className={styles.submitButton}>{t.global.confirm}</Button>
            <div className={styles.textBelowSubmitButton}><Link to='#phone'>{t.eventRegistration.registerUsingPhone}</Link></div>
            <div className={styles.textBelowSubmitButton}><Link to='#email'>{t.eventRegistration.registerUsingEmail}</Link></div>
        </Form>
    );
}
