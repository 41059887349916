import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import './Disclaimer.scss';
import {privacyPolicyStore} from './PrivacyPolicyStore';
import { t } from '../translations';

export default observer(function Disclaimer()
{
    const [preferences, setPreferences] = useState(privacyPolicyStore.preferences);
    const [statistics, setStatistics] = useState(privacyPolicyStore.statistics);

    return (
        <div className='Disclaimer'>
            <div className='text'>
                <h1>{t.disclaimer.cookies.title}</h1>
                <p>
                    {t.disclaimer.cookies.info}
                </p>
            </div>
            <div className='options'>
                <div className='options-row mb-4'>
                    <div className='cb'>
                        <input
                            type='checkbox'
                            id='disclaimer-necessary'
                            checked
                            onChange={necessaryOption}
                        />
                        <label htmlFor='disclaimer-necessary'>{t.disclaimer.cookies.necessary}</label>
                    </div>
                    <div className='cb'>
                        <input
                            type='checkbox'
                            id='disclaimer-preferences'
                            checked={preferences}
                            onChange={e => setPreferences(e.target.checked)}
                        />
                        <label htmlFor='disclaimer-preferences'>{t.disclaimer.cookies.preference}</label>
                    </div>
                    <div className='cb'>
                        <input
                            type='checkbox'
                            id='disclaimer-statistics'
                            checked={statistics}
                            onChange={e => setStatistics(e.target.checked)}
                        />
                        <label htmlFor='disclaimer-statistics'>{t.disclaimer.cookies.statistics}</label>
                    </div>
                </div>
                <div className='options-row'>
                    <button className='button mr-3 mb-3' onClick={() => privacyPolicyStore.accept({necessary: true, preferences: false, statistics: false})}>
                        {t.disclaimer.cookies.necessaryCookiies}
                    </button>
                    <button className='button mr-3 mb-3' onClick={() => privacyPolicyStore.accept({necessary: true, preferences, statistics})}>
                        {t.disclaimer.cookies.selectedCookies}
                    </button>
                    <button className='button mb-3 all' onClick={() => privacyPolicyStore.accept({necessary: true, preferences: true, statistics: true})}>
                        {t.disclaimer.cookies.allCookies}
                    </button>
                </div>
            </div>
        </div>
    )
})

const necessaryOption = () =>
{
    alert(t.disclaimer.cookies.mandatory);
}
