import {getStartOfWeekInYear, parsePromotionWeek} from '../../lib/deliveryMonthUtility';

export function getDeliveryMonth(promotionWeek: number | string, projectYear: number)
{
    const parsedWeek = parsePromotionWeek(promotionWeek, projectYear);
    if (parsedWeek == null)
    {
        return null;
    }
    const {year, week} = parsedWeek;
    const date = getStartOfWeekInYear(year, week);
    // YYYY-MM
    return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0');
}
