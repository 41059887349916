import {action, makeObservable, observable} from 'mobx';
import * as Sentry from '@sentry/browser';
import {initialLoad, user} from './user';
import {ILoginInput} from '../Login/LoginBox';
import {callLogin, callSwitchAccount, callVerify, loadUserAccounts, UsersListItem} from './userProxy';
import {getName} from '../common/getName';
import type {GraphQLFormattedError} from 'graphql';

export interface IAuthenticationData
{
    token: string
    identityId: string
    personId: string
}

interface IRegisterInitial
{
    email?: string
    phone?: string
}

class LoginStore
{
    @observable registering: boolean = false;
    @observable registerInitial?: IRegisterInitial;
    @observable registered: boolean = null;
    @observable autoValidated = false;

    @observable emailOPhone: string;
    @observable authentication: IAuthenticationData;
    @observable authenticationToken: string;
    @observable verified: boolean;

    @observable accounts: UsersListItem[] = [];
    @observable showTerms: boolean = null;

    constructor()
    {
        makeObservable(this);
    }

    @action.bound
    startRegistering()
    {
        this.registering = true;
    }

    @action
    endRegistering()
    {
        this.registering = false;
    }

    @action.bound
    login({value, method}: ILoginInput): Promise<{success: boolean, verified?: boolean, errors?: GraphQLFormattedError[]}>
    {
        if (!value)
        {
            return Promise.resolve({success: false});
        }
        this.emailOPhone = value;
        this.accounts = [];
        this.registered = false;
        this.registering = false;
        this.authentication = null;
        this.authenticationToken = null;
        return callLogin({value, setCookie: true}).then(action(({data, errors}) =>
        {
            if (errors)
            {
                if (errors.some(e => e.message === 'invalid-account'))
                {
                    this.registering = true;
                    if (method === 'Email')
                    {
                        this.registerInitial = {email: value};
                    }
                    else if (method === 'Sms')
                    {
                        this.registerInitial = {phone: value};
                    }
                }
                return {success: false, errors};
            }
            else
            {
                const login = data.login;
                this.authentication = login as IAuthenticationData;
                this.authenticationToken = formatAuthorizationData(login as IAuthenticationData);
                if (login.verified)
                {
                    this.verified = true;
                    this.loadUserAccounts();
                }
                if (login.method === 'Email')
                {
                    this.registerInitial = {email: value};
                }
                else if (login.method === 'Sms')
                {
                    this.registerInitial = {phone: value};
                }
                return {success: true, verified: login.verified};
            }
        }));
    }

    @action.bound
    async submitCode(code: string)
    {
        if (!code) return;
        const errors = await callVerify(this.authentication.identityId, this.authentication.token.split(';')[0], code);
        if (!errors)
        {
            this.verified = true;
            this.loadUserAccounts();
            return {success: true};
        }
        else
        {
            return {success: false, errors};
        }
    }

    private loadUserAccounts()
    {
        loadUserAccounts(this.emailOPhone, this.authenticationToken).then(action(res =>
        {
            if (res)
            {
                this.accounts = res;
                if (!res.length)
                {
                    // if we are org we might not have checked if user exists in advance
                    this.registering = true;
                }
            }
        }));
    }

    @action.bound
    selectAccount(id: string): Promise<{success: boolean, errors?: GraphQLFormattedError[]}>
    {
        const selectedAccount = this.accounts.find(a => a.id == id);
        Sentry.setUser({
            id,
            selectFromUserID: this.authentication.personId,
            email: selectedAccount?.email,
            phone: selectedAccount?.phone,
            username: selectedAccount ? getName(selectedAccount) : null,
        });
        Sentry.captureMessage('Select account ' + id, 'info');
        this.showTerms = selectedAccount?.showTerms;
        if (id == this.authentication.personId)
        {
            user.saveAuthorization(this.authentication);
            user.setVerified(true);
            user.reloadUser();
            initialLoad();
            Sentry.captureMessage('Load', 'info');
            this.verified = null;
            this.authentication = null;
            this.authenticationToken = null;
            return Promise.resolve({success: true});
        }
        else
        {
            return callSwitchAccount(id, this.authenticationToken).then(({data, errors}) =>
            {
                if (data?.switchAccount)
                {
                    user.saveAuthorization(data.switchAccount);
                    user.setVerified(true);
                    user.reloadUser();
                    initialLoad();
                    Sentry.captureMessage('Load', 'info');
                    this.verified = null;
                    this.authentication = null;
                    this.authenticationToken = null;
                    return {success: true};
                }
                else
                {
                    return {success: false, errors};
                }
            });
        }
    }

    @action.bound
    selectNewAccount()
    {
        this.verified = null;
        this.authentication = null;
        this.authenticationToken = null;
        this.registering = true;
    }
}

export const loginStore = new LoginStore();

export function formatAuthorizationData(a: IAuthenticationData)
{
    return a ? a.identityId + ';' + a.personId + ';' + a.token : null;
}
