import {action, makeObservable, observable} from 'mobx';
import {call, gql} from '../client';
import {projectStore} from '../stores/ProjectStore';

function loadBrandCompanies(project: string, productFamilies?: number[])
{
    return call<{brandCompanies: BrandItem[]}>(
        gql`query($input:BrandCompaniesInput!){brandCompanies(input:$input){id,name,count}}`,
        {input: {project, productFamilies}}
    ).then(({data}) => data?.brandCompanies);
}

interface BrandItem
{
    id: string
    name: string
    count: number
}

class BrandListStore
{
    @observable private brandsByProject: {[project: string]: BrandItem[]} = {};
    @observable private brandsByProjectSectors: {[project: string]: {productFamilies: number[], brands: BrandItem[]}[]} = {};
    @observable loading = false;

    constructor()
    {
        makeObservable(this);
    }

    @action
    load(project: string)
    {
        this.loading = true;
        return loadBrandCompanies(project).then(action(companies =>
        {
            this.loading = false;
            this.brandsByProject[project] = companies || [];
        }));
    }

    @action
    loadFor(project: string, productFamilies: number[])
    {
        this.loading = true;
        return loadBrandCompanies(project, productFamilies).then(action(companies =>
        {
            this.loading = false;
            if (!this.brandsByProjectSectors[project])
            {
                this.brandsByProjectSectors[project] = [];
            }
            this.brandsByProjectSectors[project].push({productFamilies, brands: companies || []});
        }));
    }

    get brands()
    {
        return this.brandsByProject[projectStore.id] || [];
    }

    brandsFor(productFamilies: number[])
    {
        return this.brandsByProjectSectors[projectStore.id]?.find(g =>
            g.productFamilies.length == productFamilies.length &&
            g.productFamilies.every(f => productFamilies.includes(f))
        )?.brands || [];
    }
}

export const brandListStore = new BrandListStore();
