import React from 'react';
import {observer} from 'mobx-react-lite';
import {IPage, pageStore} from './PageStore';

interface Props
{
    page: IPage
}

export default observer(function PageCatalogOptions({page}: Props)
{
    const id = page.id;
    return (
        <>
            <div className='mb-3'>
                <input
                    type='checkbox'
                    id='page-catalog'
                    checked={!!page.catalog?.active}
                    onChange={e => pageStore.changePage(id, ['catalog', 'active'], e.currentTarget.checked)}
                />
                <label htmlFor='page-catalog' className='font-weight-bold ml-2'>Catalog</label>
            </div>
            {!!page.catalog?.active &&
            <div className='pl-3'>
                <div className='mb-3'>
                    <input
                        type='checkbox'
                        id='page-catalog-all'
                        checked={!!page.catalog?.products?.all}
                        onChange={e => pageStore.changePage(id, ['catalog', 'products', 'all'], e.currentTarget.checked)}
                    />
                    <label htmlFor='page-catalog-all' className='ml-2'>List all exhibitor products</label>
                </div>
                <label htmlFor='page-catalog-limit' className='m-0'># of products</label>
                <input
                    type='number'
                    min={0}
                    step={1}
                    id='page-catalog-limit'
                    className='form-control'
                    placeholder='# of products'
                    value={page.catalog?.settings?.limit || ''}
                    onChange={e => pageStore.changePage(id, ['catalog', 'settings', 'limit'], +e.currentTarget.value)}
                />
                <div className='mb-3'>
                    <input
                        type='checkbox'
                        id='page-catalog-unlimited'
                        checked={(page.catalog?.settings?.limit || 0) <= 0}
                        onChange={() => pageStore.changePage(id, ['catalog', 'settings', 'limit'], 0)}
                    />
                    <label htmlFor='page-catalog-unlimited' className='ml-2'>Unlimited</label>
                </div>
                <div>
                    <input
                        type='radio'
                        id='page-catalog-col-1'
                        checked={(page.catalog?.settings?.columns || 2) == 1}
                        onChange={() => pageStore.changePage(id, ['catalog', 'settings', 'columns'], 1)}
                    />
                    <label htmlFor='page-catalog-col-1' className='ml-2'>1 block per row</label>
                </div>
                <div className='mb-3'>
                    <input
                        type='radio'
                        id='page-catalog-col-2'
                        checked={(page.catalog?.settings?.columns || 2) == 2}
                        onChange={() => pageStore.changePage(id, ['catalog', 'settings', 'columns'], 2)}
                    />
                    <label htmlFor='page-catalog-col-2' className='ml-2'>2 blocks per row</label>
                </div>
                <div>
                    <input
                        type='checkbox'
                        id='page-catalog-show-on-mobile'
                        checked={page.catalog?.showOnMobile !== false}
                        onChange={e => pageStore.changePage(id, ['catalog', 'showOnMobile'], e.currentTarget.checked)}
                    />
                    <label htmlFor='page-catalog-show-on-mobile' className='ml-2'>Show on mobile</label>
                </div>
            </div>}
        </>
    );
});
