import React from 'react';
import FA from 'react-fontawesome';
import {observer} from 'mobx-react';
import {computed} from 'mobx';
import './PostView.scss';
import '../common/underlineElement.scss';
import {duplicate, formatVideoEmbedUrl} from '../../lib/common';
import moment from 'moment';
import TextEditable from '../common/TextEditable';
import DateTimePicker from '../common/DateTimePicker';
import {ITranslations, Language} from '../../graphql/api/Types';
import TagSelect, {SelectOptions} from '../common/TagSelect';
import {languageList} from '../data/language';
import {profiles} from '../data/profiles';
import {user} from '../stores/user';
import {t} from '../translations';
import {projectStore} from '../stores/ProjectStore';
import DropZone from '../Email/DropZone';
import {uploadStore} from '../Upload/UploadStore';
import { attachmentStore } from '../Attachment/AttachmentStore';
import AttachmentTag from '../Attachment/AttachmentTag'
import {Attachment} from '../../graphql/api/attachments/Attachment';
import DebouncedEditorTinyMCE from '../common/DebouncedEditorTinyMCE';
import {editorChangeHandler, tinyMCEConfig, tinyMCEConfigNoImages} from '../common/tinymceConfig';
import {pickLanguage} from '../stores/utility';

export interface IPost
{
    id?: string
    date?: Date
    updated: Date
    published: boolean | null
    shownOnBadgePDF?: boolean
    title: ITranslations
    content: ITranslations
    image?: ITranslations
    video?: ITranslations
    profiles: string[]
    project: string
    attachments?: {[L in Language]?: Attachment[]}
    addAttachments?: any[]
    removeAttachments?: any[]
}

export interface IPostFromTo extends IPost
{
    dateTo?: Date
}

export interface PostViewProps<TPost extends Omit<IPostFromTo, 'project'> = Omit<IPostFromTo, 'project'>>
{
    item: TPost
    canBeAutoPublished?: boolean
    canShownOnBadgePDF?: boolean
    topImage?: boolean
    topVideo?: boolean
    noImages?: boolean
    collapsible?: boolean
    defaultCollapsed?: boolean
    viewMoreLabel?: boolean
    preview?: boolean
    foldBack?: boolean
    category?: string;
    onChange(item: TPost, key: string | string[], value: any): void
    onDelete(id: string): void
}

interface State
{
    language?: Language // show a different language
    collapsed?: boolean
}

@observer
export class PostView extends React.Component<PostViewProps, State>
{
    state: State = {
        collapsed: this.props.defaultCollapsed,
    };
    attachmentButton = React.createRef<HTMLInputElement>();

    get language()
    {
        return this.state.language || user.language;
    }

    @computed
    get tinymceConfig()
    {
        return duplicate(this.props.noImages ? tinyMCEConfigNoImages : tinyMCEConfig)
    }

    handleDate = (date: Date, dateTo?: Date) =>
    {
        if (+this.props.item.date != +date)
        {
            this.props.onChange(this.props.item, 'date', date);
        }
        if (dateTo && +this.props.item.dateTo != +dateTo)
        {
            this.props.onChange(this.props.item, 'dateTo', dateTo);
        }
    };

    handleTitle = (title: string) => this.props.onChange(this.props.item, ['title', this.language], title);
    handleContent = editorChangeHandler((content: string) =>
    {
        if (this.props.item.content[this.language] !== content)
        {
            this.props.onChange(this.props.item, ['content', this.language], content);
        }
    });

    // null => true
    // true => false
    // false => null | true
    togglePublished = () =>
    {
        const i = this.props.item;
        this.props.onChange(i, 'published', i.published == null ? true : i.published ? false : this.props.canBeAutoPublished ? null : true);
    };

    toggleShownOnBadgePDF = () =>
    {
        const i = this.props.item;
        this.props.onChange(i, 'shownOnBadgePDF', !i.shownOnBadgePDF);
    };

    switchLanguage = (e) =>
    {
        this.setState({language: e.target.dataset.language});
    };

    toggleCollapsed = (e) =>
    {
        e.preventDefault();
        this.setState({collapsed: !this.state.collapsed});
    };

    handleDelete = () =>
    {
        if (confirm('Are you sure you want to delete this post?'))
        {
            this.props.onDelete(this.props.item.id);
        }
    };

    handleProfiles = (prof: string[]) =>
    {
        this.props.onChange(this.props.item, 'profiles', prof);
    };

    handleImage = e =>
    {
        this.props.onChange(this.props.item, ['image', this.language], e.target.value);
    };

    handleDropImage = (file: File) =>
    {
        uploadStore.upload(file, u =>
        {
            this.props.onChange(this.props.item, ['image', this.language], u.url);
        });
    };

    handleVideo = e =>
    {
        this.props.onChange(this.props.item, ['video', this.language], e.target.value);
    };

    handleAttachment = (e) =>
    {
        e.preventDefault();
        for (const file of e.target.files)
        {
            attachmentStore.upload(file).then(u =>
            {
                this.props.onChange(this.props.item, 'addAttachments', {language: this.language, keys: [u.key]});
            });
        }
    };

    @computed
    get profileOptions(): SelectOptions
    {
        const country = projectStore.selected?.fair.country;
        return profiles.filter(p => p.countries.includes(country)).map(p => [p.id, pickLanguage(p.name)]);
    }

    render()
    {
        const p = this.props;
        const item = p.item;
        const language = this.language;
        const title = item.title[language] || item.title.en;
        const content = item.content[language] || item.content.en;
        const attachments = item.attachments?.[language] || [];
        const image = item.image?.[language] || item.image?.en;
        const video = item.video?.[language] || item.video?.en;

        const renderPublished = (className?: string) => (
            <p className={'cursor-pointer ' + (className || '')} onClick={this.togglePublished}>
                {p.canBeAutoPublished && item.published == null ?
                    <><FA name='check-circle' className={+item.date < +new Date() ? 'published-icon' : 'unpublished-icon'}/> Auto published</> :
                    item.published ?
                        <><FA name='check-circle' className='published-icon'/> {t.editPage.published}</> :
                        <><FA name='times-circle' className='unpublished-icon'/> Unpublished</>
                }
            </p>
        );

        if (this.state.collapsed)
        {
            return (
                <div className='PostView'>
                    {user.moderator && renderPublished('float-right')}
                    <h4 className='cursor-pointer' onClick={this.toggleCollapsed}>{title}</h4>
                    {this.props.viewMoreLabel && (<a onClick={this.toggleCollapsed} href='#'>{t.helpPage.viewAnswer}</a>)}
                </div>
            );
        }

        if (user.moderator && !this.props.preview)
        {
            return (
                <div className='PostView'>
                    {!!p.topImage && (<div className='position-relative'>
                        <DropZone onAttachment={this.handleDropImage}/>
                        <input className='form-control mb-2' placeholder='Insert image url or drag&drop' value={image || ''} onChange={this.handleImage}/>
                        {!!image && (<img src={image} className='PostImage' alt='Could not load the image'/>)}
                    </div>)}
                    {!!p.topVideo && (<>
                        <input className='form-control my-2' placeholder='Insert video url' value={video || ''} onChange={this.handleVideo}/>
                        {!!video && (<iframe src={formatVideoEmbedUrl(video)} frameBorder='0' allow='fullscreen' allowFullScreen/>)}
                    </>)}
                    <TextEditable
                        type='h4'
                        className='underlineElement'
                        content={title || 'Title'}
                        onBlur={this.handleTitle}
                    />
                    {!!item.date && <DateTimePicker className='PostDate' date={item.date} to={item.dateTo} onChange={this.handleDate}/>}
                    <DebouncedEditorTinyMCE
                        value={content}
                        {...this.tinymceConfig}
                        onEditorChange={this.handleContent}
                    />
                    <input ref={this.attachmentButton} value='' onChange={this.handleAttachment} type='file' className='d-none' multiple/>
                    <button className='button mt-3' onClick={() => {this.attachmentButton.current.click()}}>Add attachments</button>
                    {!!attachments.length && (<>
                        <h6 className='mt-3 ml-3'>Attachments</h6>
                        {attachments.map(a =>
                            <div className='attachments' key={a.key}>
                                <AttachmentTag
                                    keyName={a.key}
                                    name={a.name}
                                />
                                <span
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {this.props.onChange(this.props.item, ['removeAttachments'], {keys: [a.key], language})}}
                                >
                                    X
                                </span>
                            </div>
                        )}
                    </>)}
                    <div className='admin my-3'>
                        <div className='top-section'>
                            <span>Last update: {moment(item.updated).format('DD/MM/YYYY, H:mm')}</span>
                            <button className='button-delete' onClick={this.handleDelete}>Delete</button>
                        </div>
                        <div className='bottom-section'>
                            <div>
                                <p className='option-title'>Select language</p>
                                {languageList.map(lang =>
                                    <button
                                        className={'language-button ' + (language == lang ? 'active' : '')}
                                        key={lang}
                                        data-language={lang}
                                        onClick={this.switchLanguage}
                                    >
                                        {lang.toUpperCase()}
                                    </button>
                                )}
                            </div>
                            <div>
                                <p className='option-title'>Set visibility</p>
                                {renderPublished()}
                                {p.canShownOnBadgePDF &&
                                <p className='cursor-pointer' onClick={this.toggleShownOnBadgePDF}>
                                    {item.shownOnBadgePDF ?
                                        <FA name='check-circle' className='published-icon'/> :
                                        <FA name='times-circle' className='unpublished-icon'/>
                                    }
                                    {' Show on the badge'}
                                </p>}
                            </div>
                            <TagSelect
                                name='Profile Access'
                                options={this.profileOptions}
                                selected={item.profiles}
                                onChange={this.handleProfiles}
                                allSelector
                            />
                        </div>
                    </div>
                    {!this.state.collapsed && this.props.foldBack && (<a onClick={this.toggleCollapsed} href='#'>{t.helpPage.hideAnswer}</a>)}
                </div>
            );
        }

        const titleOrEn = title || item.title.en || '';
        const contentOrEn = content || item.content.en || '';
        return (
            <div className='PostView'>
                {user.moderator && renderPublished('float-right')}
                {p.collapsible ? <h4 className='cursor-pointer' onClick={this.toggleCollapsed}>{titleOrEn}</h4> : <h4>{titleOrEn}</h4>}
                {!!item.date && <div className='PostDate'>
                    {moment(item.date).format('DD/MM/YYYY, H:mm') +
                    (item.dateTo ? ' - ' + moment(item.dateTo).format('H:mm') : '')}
                </div>}
                <div className='clearfix' dangerouslySetInnerHTML={{__html: contentOrEn}}/>
                {!!attachments.length &&
                <article className='pb-5'>
                    <h6 className='mt-3 ml-3'>Attachments</h6>
                    {attachments.map(a =>
                        <AttachmentTag
                            key={a.key}
                            keyName={a.key}
                            name={a.name}
                        />
                    )}
                </article>}
                {this.props.viewMoreLabel && (<a onClick={this.toggleCollapsed} href='#'>{t.helpPage.hideAnswer}</a>)}
            </div>
        );
    }
}
