type Func<T extends any[] = any, R = any> = (...args: T) => R;

interface IThrottled<T extends any[], R> extends Func<T, R | void>
{
    // if an action is pending it will not be executed
    cancel(): void
    // if an action is pending it will be executed immediately
    fastForward(): void
    // is an action pending
    isPending(): boolean
}

export function throttle<T extends any[], R>(func: Func<T, R>, wait: number = 0, trailing?: boolean): IThrottled<T, R | void>
{
    let lastExecAt = 0;
    let timeout;
    let args;
    const f = function()
    {
        const now = Date.now();
        const elapsed = now - lastExecAt;
        if (wait <= elapsed)
        {
            lastExecAt = now;
            if (timeout)
            {
                clearTimeout(timeout);
                timeout = null;
                args = null;
            }
            return func.apply(this, arguments);
        }
        else if (trailing)
        {
            clearTimeout(timeout);
            args = arguments;
            timeout = setTimeout(() =>
            {
                timeout = null;
                const a = args;
                args = null;
                lastExecAt = Date.now();
                func.apply(this, a);
            }, elapsed);
        }
    } as IThrottled<T, R | void>;
    f.cancel = () =>
    {
        if (timeout)
        {
            clearTimeout(timeout);
            timeout = null;
            args = null;
        }
    };
    f.fastForward = () =>
    {
        if (timeout)
        {
            clearTimeout(timeout);
            timeout = null;
            const a = args;
            args = null;
            func.apply(this, a);
        }
    };
    f.isPending = () => !!timeout;
    return f;
}

// decorator
export function throttled(wait?: number, trailing?: boolean)
{
    return (target, propertyKey: string, descriptor: PropertyDescriptor) =>
    {
        descriptor.value = throttle(descriptor.value, wait, trailing);
    };
}
