import React from 'react';

interface Props
{
    windows: React.ReactElement[]
    onHiddenStartChange?(hiddenStartIndex: number)
}

export class WindowList extends React.PureComponent<React.PropsWithChildren<Props>>
{
    hiddenStartIndex: number;

    container: React.RefObject<HTMLDivElement> = React.createRef();
    children: React.RefObject<HTMLDivElement> = React.createRef();
    elements: HTMLDivElement[] = [];

    componentDidMount()
    {
        this.refreshVisibility();
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount()
    {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () =>
    {
        this.refreshVisibility();
    };

    refreshVisibility()
    {
        // skip if react is still populating references
        if (!this.container.current || this.elements.indexOf(null) != -1) return;

        const parentLeft = this.container.current.getBoundingClientRect().left
            + this.children.current.getBoundingClientRect().width;
        let hideRest = false;
        for (let i = 0; i < this.elements.length; ++i)
        {
            const node = this.elements[i];
            if (node)
            {
                if (hideRest)
                {
                    node.style.display = 'none';
                }
                else
                {
                    node.style.display = '';
                    const left = node.getBoundingClientRect().left;
                    if (left < parentLeft)
                    {
                        node.style.display = 'none';
                        hideRest = true;
                        if (this.hiddenStartIndex != i)
                        {
                            this.props.onHiddenStartChange?.(this.hiddenStartIndex = i);
                        }
                    }
                }
            }
        }
        if (!hideRest && this.hiddenStartIndex != -1)
        {
            this.props.onHiddenStartChange?.(this.hiddenStartIndex = -1);
        }
    }

    render()
    {
        const {windows} = this.props;
        this.elements.length = windows.length;
        return (
            <div ref={this.container} className='ChatWindowList mw-100 d-flex flex-row-reverse align-items-end'>
                {windows.map((window, i) => (
                    <div
                        key={window.props.id || i}
                        data-i={i}
                        className='mr-2'
                        ref={c =>
                        {
                            if (i < this.elements.length)
                            {
                                this.elements[i] = c;
                            }
                            this.refreshVisibility();
                        }}
                    >
                        {window}
                    </div>
                ))}
                <div ref={this.children}>{this.props.children}</div>
            </div>
        );
    }
}
