import React from 'react';
import './UploadProgressBar.scss';

interface Props
{
    text?: string
    progress: number // 0 to 1
}

export function UploadProgressBar({text, progress}: Props)
{
    return (
        <div className='UploadProgressBar'>
            <div className='progressBar' style={{width: progress * 100 + '%'}}/>
            <div className='progressText'>{text ?? (progress * 100).toFixed(0) + '%'}</div>
        </div>
    );
}
