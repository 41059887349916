import React from 'react';
import './Loading.scss';

interface Props
{
    className?: string
}

export class Loading extends React.PureComponent<Props>
{
    render()
    {
        return (
            <div className={'LoadingContainer ' + (this.props.className || '')}>
                <div className='Loading'/>
            </div>
        )
    }
}

export class LoadingInline extends React.PureComponent<Props>
{
    render()
    {
        return <div className={'LoadingInline ' + (this.props.className || '')}/>
    }
}
