import {IPackage, packageStore} from './PackageStore';
import {productStore} from '../Products/ProductStore';
import {orderStandProductStore} from '../OrderStand/OrderStandProductStore';
import {filterPackageProductsForSetup, packageProductSetup} from '../../lib/packageLib';
import {IStandSetup} from '../../graphql/api/stand/Stand';
import {applyDiscounts, earlyBirdDiscounts, lateFeeDiscounts, setupDiscounts} from '../../lib/discounts';
import {projectStore} from '../stores/ProjectStore';
import {user} from '../stores/user';

export function calculatePackagePrice(pkg: IPackage, setup: IStandSetup, pkgDiscount?: number): number
{
    const project = projectStore.projects.find(p => p.id === pkg.project);
    // const country = project?.fair?.country || 'BE';
    pkgDiscount = pkgDiscount || pkg.discount || 1;
    const fixedPrice = pkg.fixedPrice;
    if (pkg.fixedPrice)
    {
        return applyDiscounts(pkg.fixedPrice * pkgDiscount, earlyBirdDiscounts(project).map(d => d.amount));
    }
    let products = pkg.products || [];
    products = filterPackageProductsForSetup(products, setup, user.moderator);
    let total = 0;

    const calculatedSetupExtraCost = setupDiscounts(setup, project);
    const calculatedEarlyBird = earlyBirdDiscounts(project);
    const calculatedLateFee = lateFeeDiscounts(project);

    for (const product of products)
    {
        const prod =
            orderStandProductStore.projectProducts.find(pa => pa.id == product.id) ||
            productStore.products.find(p => p.id === product.id);
        const productPrice = prod?.price?.selling?.value;
        if (productPrice)
        {
            const {priceMultiplier} = packageProductSetup(product, setup, pkgDiscount, user.moderator);
            const useSetupExtraCost = !fixedPrice && product.useDiscounts;
            const discounts = useSetupExtraCost ? calculatedSetupExtraCost : [];
            total += applyDiscounts(productPrice * priceMultiplier, discounts?.map(d => d.amount));
        }
    }

    total = applyDiscounts(total, [...calculatedEarlyBird, ...calculatedLateFee].map(d => d.amount));

    const parent = pkg.parentId && packageStore.packages.find(p => p.id === pkg.parentId);
    if (parent)
    {
        total += calculatePackagePrice(parent, setup, pkgDiscount);
    }

    return total;
}
