import {observer} from 'mobx-react-lite';
import './CheckoutPackageProductList.scss';
import {IStandPackage} from '../../graphql/api/stand/Stand';
import {IPackage} from '../Packages/PackageStore';
import {formatPackageProductName} from '../Packages/utility';
import {productStore} from '../Products/ProductStore';
import {orderStandProductStore} from './OrderStandProductStore';
import {t} from '../translations';
import {user} from '../stores/user';
import {orderStandStore} from './OrderStandStore';
import {discountedOrderTotal} from './lib';

interface Props
{
    // only needed for org view
    standId?: string
    standPkg: IStandPackage
    pkg: IPackage
    currency: string
}

export default observer(function CheckoutPackageProductList({standId, standPkg, pkg, currency}: Props)
{
    return (
        <ul>
            {standPkg.products.map(p =>
            {
                const dv = p.discount && discountedOrderTotal(p.price, p.discount);
                return (
                    <li key={p.id}>
                        {formatPackageProductName(
                            pkg?.products.find(pa => pa.id == p.id),
                            orderStandProductStore.projectProducts.find(pa => pa.id == p.id) ||
                            productStore.products.find(pa => pa.id == p.id),
                            p.quantity
                        )}
                        {p.discount?.map((d, i) =>
                            <div key={i} className='CheckoutPackageProductListDiscount'>
                                {
                                    (
                                        (d.name == 'Head Stand' && t.standCreation.setup.headStandCheckout) ||
                                        (d.name == 'Corner Stand' && t.standCreation.setup.cornerStandCheckout) ||
                                        // (d.name == 'Open Stand' && 'Open Stand') ||
                                        d.name
                                    ) +
                                    ' ' +
                                    (
                                        d.type === 'percentage' ?
                                            `${d.amount > 1 ? '+' : ''} ${((d.amount - 1) * 100).toFixed(0)}% ( ${user.formatCurrency(currency, dv.discountedAmounts[i])} )`
                                            :
                                            `${d.amount > 0 ? '+' : ''} ${user.formatCurrency(currency, d.amount)}`
                                    )
                                }
                                {standId &&
                                    <span
                                        className='delete'
                                        onClick={() =>
                                        {
                                            orderStandStore.handleStandPackageProductsDiscountsChange({
                                                id: standId,
                                                productId: p.id,
                                                discount: p.discount.filter((_, j) => i !== j),
                                            });
                                        }}
                                    >
                                        x
                                    </span>
                                }
                            </div>
                        )}
                    </li>
                );
            })}
        </ul>
    );
});
