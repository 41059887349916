// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart-notification-products {
  z-index: 3000;
  width: 400px;
  position: fixed;
  right: 2em;
  top: 8%;
}
.cart-notification-products *:not(.fa) {
  font-family: "Montserrat", sans-serif;
}
.cart-notification-products .product-notification {
  width: 100%;
  border-radius: 10px;
  border: 2px solid #e0e0e0;
  background: #fff;
  transition: 0.5s ease all;
}
.cart-notification-products .product-notification:not(:first-child) {
  margin-top: 1em;
}
.cart-notification-products .product-notification .image {
  position: relative;
  margin: 10px 5px;
  display: flex;
  width: 120px;
  height: 120px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.cart-notification-products .product-notification .description {
  flex-basis: 70%;
  padding: 1em;
}
.cart-notification-products .product-notification .description p, .cart-notification-products .product-notification .description span {
  font-family: Montserrat, sans-serif;
  word-break: break-word;
}`, "",{"version":3,"sources":["webpack://./frontend/StoreCatalog/CartUpdateOverlay.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,eAAA;EACA,UAAA;EACA,OAAA;AACJ;AAAI;EACI,qCAAA;AAER;AAAI;EACI,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,yBAAA;AAER;AAAQ;EACI,eAAA;AAEZ;AACQ;EACI,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,wBAAA;EACA,2BAAA;EACA,4BAAA;AACZ;AACQ;EACI,eAAA;EACA,YAAA;AACZ;AAAY;EACI,mCAAA;EACA,sBAAA;AAEhB","sourcesContent":[".cart-notification-products {\n    z-index: 3000;\n    width: 400px;\n    position: fixed;\n    right: 2em;\n    top: 8%;\n    *:not(.fa) {\n        font-family: 'Montserrat', sans-serif;\n    }\n    .product-notification {\n        width: 100%;\n        border-radius: 10px;\n        border: 2px solid #e0e0e0;\n        background: #fff;\n        transition: .5s ease all;\n\n        &:not(:first-child) {\n            margin-top: 1em;\n        }\n    \n        .image {\n            position: relative;\n            margin: 10px 5px;\n            display: flex;\n            width: 120px;\n            height: 120px;\n            background-size: contain;\n            background-position: center;\n            background-repeat: no-repeat;\n        }\n        .description {\n            flex-basis: 70%;\n            padding: 1em;\n            p, span {\n                font-family: Montserrat, sans-serif;\n                word-break: break-word;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
