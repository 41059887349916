import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import FA from 'react-fontawesome';
import {carrefourBrandStore} from './CarrefourBrandStore';

export default observer(function CarrefourBrands({id}: {id: string})
{
    useEffect(() =>
    {
        carrefourBrandStore.load(id);
    }, [id]);

    const brands = carrefourBrandStore.brands[id];

    return brands?.length ?
        <div className='mb-4'>
            <h5 className='pb-2 mb-3 border-bottom border-dark'>
                <FA name='city' className='fad mr-2'/>
                Carrefour Brands
            </h5>
            {brands.map(c =>
                <Link key={c.id} to={'/company/' + c.id} className='d-block mb-1 text-dark'>{c.name}</Link>
            )}
        </div>
        :
        null;
});
