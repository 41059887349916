import React from 'react';
import {observer} from 'mobx-react-lite';
import moment from 'moment';
import {projectStore} from '../stores/ProjectStore';
import {t} from '../translations';

interface Props
{
    className?: string
    deliveryMonths: string[]
    deliveryMonth: string
    setDeliveryMonth: (deliveryMonth: string) => void
}

export default observer(function DeliveryMonthSelect({className, deliveryMonths, deliveryMonth, setDeliveryMonth}: Props)
{
    if (!projectStore.isFRNonFood || !deliveryMonths)
    {
        return null;
    }
    return (
        <select
            className={'form-control ' + (className || '')}
            value={deliveryMonth || ''}
            onChange={e => setDeliveryMonth(e.currentTarget.value || null)}
        >
            <option value=''>{t.cart.monthlyEngagement} ({t.global.all})</option>
            {deliveryMonths.map(m =>
                <option key={m} value={m}>{moment(new Date(m)).format('YYYY MMMM')}</option>
            )}
            {deliveryMonth && !deliveryMonths.includes(deliveryMonth) &&
            <option value={deliveryMonth}>{moment(new Date(deliveryMonth)).format('YYYY MMMM')}</option>
            }
        </select>
    );
});
