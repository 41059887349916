import {SweetAlertOptions} from 'sweetalert2';

export function swalModal(code: string, message: string): SweetAlertOptions
{
    const swalPopups: {[key: string]: SweetAlertOptions} = {
        error: {
            title: 'Oops...',
            icon: 'error'
        },
        success: {
            title: 'Success',
            icon: 'success'
        }
    };
    return {
        ...swalPopups[code],
        text: message
    };
}
