import {call, gql} from '../client';

export interface UploadInput
{
    name?: string
    noHashInName?: boolean
    bucket?: string
    dir?: string
    contentType: string
    asAttachment?: boolean
}

export interface UploadResult
{
    name: string
    url: string
    signedUrl: string
}

export function callRequestUpload(input: UploadInput)
{
    return call<{requestUpload: UploadResult}>(
        gql`mutation($input:UploadInput!){requestUpload(input:$input){name,url,signedUrl}}`, {input})
    .then(({data}) => data?.requestUpload);
}

export function callUploadFinished(url: string)
{
    return call(gql`mutation($url:String!){uploadFinished(url:$url)}`, {url});
}

export function callUploadCanceled(url: string)
{
    return call(gql`mutation($url:String!){uploadCanceled(url:$url)}`, {url});
}
