import React from 'react';
import moment from 'moment';
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './DateTimePicker.scss';
import TextEditable from './TextEditable';

interface Props
{
    className?: string
    date: Date | string
    to?: Date | string // only the time part is used, the data is always the same as in the date prop
    dateFormat?: string
    dateOnly?: boolean
    toTheRight?: boolean
    onChange(date: Date, dateTo?: Date): void
}

interface State
{
    showPicker?: boolean
    time?: string
    timeTo?: string
}

export default class DateTimePicker extends React.PureComponent<Props, State>
{
    state: State = {};

    get date()
    {
        return this.props.date instanceof Date ? this.props.date : this.props.date != null ? new Date(this.props.date) : null;
    }

    get to()
    {
        return this.props.to instanceof Date ? this.props.to : this.props.to != null ? new Date(this.props.to) : null;
    }

    handleBlur = (e: React.FocusEvent) =>
    {
        if (this.state.showPicker && !e.currentTarget.contains(e.relatedTarget as any))
        {
            this.setState({showPicker: false});
        }
    };

    togglePicker = () =>
    {
        this.setState({showPicker: !this.state.showPicker});
    };

    handleDate = (date: Date) =>
    {
        const d = new Date(this.date);
        date.setHours(
            d.getHours(),
            d.getMinutes(),
            d.getSeconds(),
            d.getMilliseconds(),
        );
        this.props.onChange(date);
    };

    handleTimeChange = (time: string) =>
    {
        this.setState({time});
    };

    handleTimeToChange = (timeTo: string) =>
    {
        this.setState({timeTo});
    };

    handleTime = (time: string) =>
    {
        if (time && this.state.time && time.includes(':'))
        {
            const [h, m] = time.split(':');
            const hours = +h.trim();
            const minutes = +m.trim();
            if (!isNaN(hours) && !isNaN(minutes))
            {
                const date = new Date(this.date);
                date.setHours(hours, minutes);
                this.props.onChange(date, this.to && (this.to >= date ? this.to : date));
            }
        }
        this.setState({time: null});
    };

    handleTimeTo = (timeTo: string) =>
    {
        if (timeTo && this.state.timeTo && timeTo.includes(':'))
        {
            const [h, m] = timeTo.split(':');
            const hours = +h.trim();
            const minutes = +m.trim();
            if (!isNaN(hours) && !isNaN(minutes))
            {
                const date = new Date(this.date);
                date.setHours(hours, minutes);
                this.props.onChange(this.date, date >= this.date ? date : this.date);
            }
        }
        this.setState({timeTo: null});
    };

    render()
    {
        const {dateFormat, dateOnly, className, toTheRight} = this.props;
        const s = this.state;
        const date = this.date;
        const to = !dateOnly && this.to;
        return (
            <div
                className={'DateTimePicker font-2 ' + (toTheRight ? 'toTheRight ' : '') + (className ? className : '')}
                tabIndex={0}
                onBlur={this.handleBlur}
            >
                <span className='date underlineElement' onClick={this.togglePicker}>
                    {date ? moment(date).format(dateFormat || 'DD/MM/YYYY') : dateFormat || 'DD/MM/YYYY'}
                </span>
                {!dateOnly && <>
                    {', '}
                    <TextEditable
                        type='span'
                        className='time underlineElement'
                        content={s.time || (date ? moment(date).format('H:mm') : 'H:mm')}
                        onChange={this.handleTimeChange}
                        onBlur={this.handleTime}
                    />
                    {!!to && ' - '}
                    {!!to &&
                    <TextEditable
                        type='span'
                        className='time underlineElement'
                        content={s.timeTo || (to ? moment(to).format('H:mm') : 'H:mm')}
                        onChange={this.handleTimeToChange}
                        onBlur={this.handleTimeTo}
                    />}
                </>}
                {s.showPicker &&
                    <DayPicker
                        selected={date}
                        onDayClick={this.handleDate}
                    />
                }
            </div>
        );
    }
}
