import {observable, action, computed, makeObservable} from 'mobx';
import Swal from 'sweetalert2';
import {
    IContact,
    getContact,
    getCompanyMembers,
    loadContacts,
    updateEmailOrPhone,
    deleteUser,
    assignBadgeForDay,
    unAssignBadgeForDay,
    assignParkingForDay,
    unAssignParkingForDay,
    assignSideEvent,
    unAssignSideEvent,
    UserInfo,
    setBadgeStatus,
    updateUserFunction,
    setBadgeVIP,
    renamePerson,
    changePersonCompanies,
    changePersonProfile,
    updateUserOperationalManagement,
    createUserOnEvent,
    CreateUserOnEventInput,
} from '../stores/userProxy';
import { user } from '../stores/user';
import { companyStore } from '../Company/CompanyStore';
import {capitalize} from '../../lib/common';
import {IBadge, IBadgeOperationalManagement, IBadgeStatus} from '../../graphql/api/user/User';
import {t} from '../translations';
import {globalNavigate} from '../App';
import {eventStore} from '../Event/EventStore';
import {projectStore} from '../stores/ProjectStore';
import {registerGlobalEventHandler} from '../stores/globalEvents';
import {throttle} from '../common/throttle';
import {personsOnEventStore} from '../Event/PersonsOnEventStore';
import {PersonOnEvent} from '../../graphql/api/event/PersonOnEvent';

class ContactsStore {

    @observable contacts: IContact[] = [];
    @observable loading: boolean;
    private loadingCompanyMembers: Set<string>;
    private change: string = null;

    @computed
    get currentUserAndContacts()
    {
        if (!user.loaded)
        {
            return this.contacts;
        }
        return [{
            id: user.id,
            ...(user.info || {} as UserInfo),
        }, ...this.contacts];
    }

    @computed
    get contactsAndCurrentUser()
    {
        if (!user.loaded)
        {
            return this.contacts;
        }
        return [...this.contacts, {
            id: user.id,
            ...(user.info || {} as UserInfo),
        }];
    }

    constructor()
    {
        makeObservable(this);
        registerGlobalEventHandler('logout', action(() =>
        {
            this.contacts = [];
        }));
    }

    reloadContacts = throttle(action(() =>
    {
        this.loading = true;
        loadContacts().then(action('reloadContacts', res =>
        {
            this.loading = false;
            if (res)
            {
                if (!this.contacts.length)
                {
                    this.contacts.push(...res);
                }
                else
                {
                    this.updateContacts(res);
                }
            }
        }));
    }), 10000);

    @action
    loadContact(id: string)
    {
        if (id == user.id)
        {
            return;
        }
        this.loading = true;
        getContact(id).then(action(res =>
        {
            this.loading = false;
            if (res)
            {
                res.companyInfo.forEach(ci =>
                {
                    if (!companyStore.companies.find(c => c.id == ci.id))
                    {
                        companyStore.companies.push(ci);
                    }
                })
                const existing = this.contacts.find(c => c.id === id);
                if (existing)
                {
                    Object.assign(existing, res);
                }
                else
                {
                    this.contacts.push(res);
                }
            }
        }));
    }

    loadCompanyMembers(ids: string[])
    {
        if (!ids)
        {
            return;
        }
        ids = ids.filter(id => id && !this.loadingCompanyMembers?.has(id));
        if (!ids.length)
        {
            return;
        }
        if (!this.loadingCompanyMembers)
        {
            this.loadingCompanyMembers = new Set();
        }
        for (const id of ids)
        {
            this.loadingCompanyMembers.add(id);
        }
        getCompanyMembers(ids).then(res =>
        {
            for (const id of ids)
            {
                this.loadingCompanyMembers.delete(id);
            }
            if (res)
            {
                this.updateContacts(res);
            }
        });
    }

    @action
    private updateContacts(contacts: IContact[])
    {
        for (const c of this.contacts)
        {
            const id = c.id;
            const updatedIndex = contacts.findIndex(u => u && u.id === id);
            if (updatedIndex >= 0)
            {
                Object.assign(c, contacts[updatedIndex]);
                contacts[updatedIndex] = null;
            }
        }
        const newList = contacts.filter(u => u);
        if (newList.length)
        {
            this.contacts.push(...newList);
        }
    }

    updateUserEmailOrPhone(value: string, target: 'email' | 'phone', id: string)
    {
        value = value.trim();
        if (target === 'email' && !value)
        {
            alert(`${capitalize(target)} should not be empty!`);
            return Promise.resolve(false);
        }
        if (value == this.change)
        {
            return Promise.resolve();
        }
        this.change = value;
        return updateEmailOrPhone({value, target, id}).then(action(({data, errors}) =>
        {
            const res = data?.updateUserEmailOrPhone;
            if (res)
            {
                if (id === user.id)
                {
                    user.info.email = res.email;
                    user.info.phone = res.phone;
                    user.saveUserInfo();
                }
                else
                {
                    const contact = this.contacts.find(c => c.id === id);
                    if (contact)
                    {
                        Object.assign(contact, res);
                    }
                }
                if (target === 'email')
                {
                    const personOnEvent = personsOnEventStore.currentPersons.find(c => c.id == id);
                    if (personOnEvent)
                    {
                        personOnEvent.e = res.email;
                        personsOnEventStore.markChanged();
                    }
                }
            }
            else
            {
                alert(`Error ${errors[0].message}`);
            }
            return !!res;
        }));
    }

    renamePerson(input: {id: string, firstName: string, lastName: string})
    {
        renamePerson(input).then(action(res =>
        {
            if (res)
            {
                if (user.id == input.id)
                {
                    user.info.firstName = input.firstName;
                    user.info.lastName = input.lastName;
                }
                else
                {
                    const contact = this.contacts.find(c => c.id == input.id);
                    if (contact)
                    {
                        contact.firstName = input.firstName;
                        contact.lastName = input.lastName;
                    }
                }
                const personOnEvent = personsOnEventStore.currentPersons.find(c => c.id == input.id);
                if (personOnEvent)
                {
                    personOnEvent.fn = input.firstName;
                    personOnEvent.ln = input.lastName;
                    personsOnEventStore.markChanged();
                }
            }
        }));
    }

    changePersonCompanies(input: {id: string, companies: string[], companyName?: string})
    {
        changePersonCompanies(input).then(action(res =>
        {
            if (res)
            {
                if (user.id == input.id)
                {
                    user.info.company = res.companies.map(c => c.id);
                    user.info.companyName = res.companyName;
                }
                else
                {
                    const contact = this.contacts.find(c => c.id == input.id);
                    if (contact)
                    {
                        contact.company = res.companies.map(c => c.id);
                        contact.companyName = res.companyName;
                    }
                }
                const personOnEvent = personsOnEventStore.currentPersons.find(c => c.id == input.id);
                if (personOnEvent)
                {
                    personOnEvent.c = res.companies.map(c => ({id: c.id, n: c.name}));
                    personOnEvent.cn = res.companyName;
                    personsOnEventStore.markChanged();
                }
            }
        }));
    }

    changePersonProfile(input: {id: string, profile: string})
    {
        changePersonProfile(input).then(action(res =>
        {
            if (res)
            {
                if (user.id == input.id)
                {
                    user.info.profiles = [{id: input.profile}];
                }
                else
                {
                    const contact = this.contacts.find(c => c.id == input.id);
                    if (contact)
                    {
                        contact.profiles = [{id: input.profile}];
                    }
                }
                const personOnEvent = personsOnEventStore.currentPersons.find(c => c.id == input.id);
                if (personOnEvent)
                {
                    personOnEvent.pr = input.profile;
                    personsOnEventStore.markChanged();
                }
            }
        }));
    }

    removeUser(userId: string)
    {
        return deleteUser(userId).then(action(res =>
        {
            if (res)
            {
                this.handleDeleted(userId);
            }
            return res;
        }));
    }
    handleDeleted(userId: string)
    {
        this.contacts = this.contacts.filter(c => c.id != userId);
    }

    assignBadgeForDay(targetUserId: string, project: string, day: Date | string)
    {
        assignBadgeForDay(targetUserId, project, day).then(action(({data, errors}) =>
        {
            if (errors)
            {
                if (errors[0].message == 'no-badges-left')
                {
                    Swal.fire({
                        title: t.fairParticipation.noBadgesAvailable,
                        text: t.fairParticipation.buyMoreBadges,
                        icon: 'error',
                        showCancelButton: true,
                        confirmButtonText: t.fairParticipation.buyBadgesButton,
                        cancelButtonText: t.global.cancel,
                    }).then(result =>
                    {
                        if (result.value)
                        {
                            globalNavigate()('/order-stand');
                        }
                    });
                }
                else if (errors[0].message == 'no-capacity')
                {
                    Swal.fire({
                        title: t.fairParticipation.mainEventFull,
                        icon: 'error',
                        showCancelButton: false,
                    });
                }
                else if (errors[0].message == 'Access Denied')
                {
                    Swal.fire({
                        title: t.fairParticipation.cantSelectDate,
                        text: '',
                        icon: 'error',
                        showCancelButton: false,
                    });
                }
            }
            else
            {
                this.updateBadges(targetUserId, data.assignBadgeForDay);
                const personOnEvent = personsOnEventStore.persons[project]?.find(c => c.id == targetUserId);
                if (personOnEvent)
                {
                    const badge = data.assignBadgeForDay.find(b => b.project == project);
                    if (badge)
                    {
                        personOnEvent.da = badge.days;
                        personsOnEventStore.markChanged();
                    }
                }
            }
        }));
    }

    unAssignBadgeForDay(targetUserId: string, project: string, day: Date | string)
    {
        const days = user.id == targetUserId ?
            user.info.badges.find(b => b.project == project).days :
            this.contacts.find(c => c.id == targetUserId)?.badges.find(b => b.project == project).days ||
            personsOnEventStore.persons[project]?.find(c => c.id == targetUserId)?.da;
        if (days.length == 1)
        {
            Swal.fire({
                title: t.fairParticipation.removeBadgeConfirmation,
                icon: 'warning',
                confirmButtonText: t.global.confirm,
                cancelButtonText: t.global.cancel,
                showCancelButton: true,
            }).then(result =>
            {
                if (result.value)
                {
                    this._unAssignBadgeForDay(targetUserId, project, day);
                }
            });
        }
        else
        {
            this._unAssignBadgeForDay(targetUserId, project, day);
        }
    }
    private _unAssignBadgeForDay(targetUserId: string, project: string, day: Date | string)
    {
        unAssignBadgeForDay(targetUserId, project, day).then(action(badges =>
        {
            if (badges)
            {
                eventStore.loadStatuses(projectStore.id);
                this.updateBadges(targetUserId, badges);
                const personOnEvent = personsOnEventStore.persons[project]?.find(c => c.id == targetUserId);
                if (personOnEvent)
                {
                    const badge = badges.find(b => b.project == project);
                    if (badge)
                    {
                        personOnEvent.da = badge.days;
                        personsOnEventStore.markChanged();
                    }
                }
            }
        }));
    }

    assignParkingForDay(targetUserId: string, project: string, day: Date | string)
    {
        assignParkingForDay(targetUserId, project, day).then(action(({data, errors}) =>
        {
            if (errors)
            {
                if (errors[0].message == 'badge-not-assigned')
                {
                    Swal.fire({
                        title: t.fairParticipation.confirmParticipation,
                        icon: 'warning',
                    });
                }
                else if (errors[0].message == 'already-assigned-parking')
                {
                    Swal.fire({
                        title: t.fairParticipation.parkingAlreadyAssigned,
                        icon: 'error',
                        showCancelButton: false,
                    });
                }
            }
            else
            {
                this.updateBadges(targetUserId, data.assignParkingForDay);
                const personOnEvent = personsOnEventStore.persons[project]?.find(c => c.id == targetUserId);
                if (personOnEvent)
                {
                    const badge = data.assignParkingForDay.find(b => b.project == project);
                    if (badge)
                    {
                        personOnEvent.pa = badge.parking;
                        personsOnEventStore.markChanged();
                    }
                }
            }
        }));
    }

    unAssignParkingForDay(targetUserId: string, project: string, day: Date | string)
    {
        unAssignParkingForDay(targetUserId, project, day).then(action(({data, errors}) =>
        {
            if (!errors)
            {
                this.updateBadges(targetUserId, data.unAssignParkingForDay);
                const personOnEvent = personsOnEventStore.persons[project]?.find(c => c.id == targetUserId);
                if (personOnEvent)
                {
                    const badge = data.unAssignParkingForDay.find(b => b.project == project);
                    if (badge)
                    {
                        personOnEvent.pa = badge.parking;
                        personsOnEventStore.markChanged();
                    }
                }
            }
        }));
    }

    assignSideEvent(targetUserId: string, project: string, reference: string)
    {
        assignSideEvent(targetUserId, project, reference).then(action(({data, errors}) =>
        {
            if (errors)
            {
                if (errors[0].message == 'badge-not-assigned')
                {
                    Swal.fire({
                        title: t.fairParticipation.confirmParticipation,
                        icon: 'warning',
                    });
                }
                else if (errors[0].message == 'no-capacity')
                {
                    Swal.fire({
                        title: t.fairParticipation.eventFull,
                        icon: 'error',
                    });
                }
                else if (errors[0].message == 'Access Denied')
                {
                    Swal.fire({
                        title: t.fairParticipation.cantSelectDate,
                        text: '',
                        icon: 'error',
                        showCancelButton: false
                    })
                }
            }
            else
            {
                this.updateBadges(targetUserId, data.assignSideEvent);
                const personOnEvent = personsOnEventStore.persons[project]?.find(c => c.id == targetUserId);
                if (personOnEvent)
                {
                    const badge = data.assignSideEvent.find(b => b.project == project);
                    if (badge)
                    {
                        personOnEvent.se = badge.sideEvents?.map(se => se.reference);
                        personsOnEventStore.markChanged();
                    }
                }
            }
        }));
    }

    unAssignSideEvent(targetUserId: string, project: string, reference: string)
    {
        unAssignSideEvent(targetUserId, project, reference).then(action(badges =>
        {
            if (badges)
            {
                eventStore.loadStatuses(projectStore.id);
                this.updateBadges(targetUserId, badges);
                const personOnEvent = personsOnEventStore.persons[project]?.find(c => c.id == targetUserId);
                if (personOnEvent)
                {
                    const badge = badges.find(b => b.project == project);
                    if (badge)
                    {
                        personOnEvent.se = badge.sideEvents?.map(se => se.reference);
                        personsOnEventStore.markChanged();
                    }
                }
            }
        }));
    }

    setBadgeStatus(targetUserId: string, project: string, status: IBadgeStatus)
    {
        return setBadgeStatus(targetUserId, project, status).then(action(({data, errors}) =>
        {
            if (errors)
            {
                if (errors[0].message == 'no-capacity')
                {
                    Swal.fire({
                        title: t.fairParticipation.datesFull,
                        icon: 'error',
                        showCancelButton: false
                    });
                }
            }
            else
            {
                this.updateBadges(targetUserId, data.setBadgeStatus);
                const personOnEvent = personsOnEventStore.persons[project]?.find(c => c.id == targetUserId);
                if (personOnEvent)
                {
                    const badge = data.setBadgeStatus.find(b => b.project == projectStore.id);
                    if (badge)
                    {
                        personOnEvent.rid = badge.registrationId;
                        personOnEvent.bs = badge.status;
                        personsOnEventStore.markChanged();
                    }
                }
            }
            return !errors;
        }));
    }

    setBadgeVIP(targetUserId: string, project: string, vip: boolean)
    {
        setBadgeVIP(targetUserId, project, vip).then(action(({data, errors}) =>
        {
            if (!errors)
            {
                this.updateBadges(targetUserId, data.setBadgeVIP);
                const personOnEvent = personsOnEventStore.persons[project]?.find(c => c.id == targetUserId);
                if (personOnEvent)
                {
                    const badge = data.setBadgeVIP.find(b => b.project == projectStore.id);
                    if (badge)
                    {
                        personOnEvent.bv = badge.vip;
                        personsOnEventStore.markChanged();
                    }
                }
            }
        }));
    }

    @action
    private updateBadges(userId: string, badges: IBadge[])
    {
        if (user.id == userId)
        {
            user.info.badges = badges;
        }
        else
        {
            const contact = this.contacts.find(c => c.id == userId);
            if (contact)
            {
                contact.badges = badges;
            }
        }
    }

    updateUserFunction(targetUserId: string, func: string)
    {
        return updateUserFunction(targetUserId, func).then(action(res =>
        {
            if (res)
            {
                if (user.id == res.id)
                {
                    user.info.function = res.function;
                }
                else
                {
                    const contact = this.contacts.find(c => c.id == res.id);
                    if (contact)
                    {
                        contact.function = res.function;
                    }
                }
                const personOnEvent = personsOnEventStore.currentPersons.find(c => c.id == res.id);
                if (personOnEvent)
                {
                    personOnEvent.fu = res.function;
                    personsOnEventStore.markChanged();
                }
            }
            return !!res;
        }));
    }

    updateUserOperationalManagement(targetUserId: string, project: string, operationalManagement: IBadgeOperationalManagement)
    {
        updateUserOperationalManagement(targetUserId, project, operationalManagement).then(action(res =>
        {
            if (res)
            {
                this.updateBadges(targetUserId, res.badges);
                const personOnEvent = personsOnEventStore.persons[project].find(c => c.id == res.id);
                if (personOnEvent)
                {
                    personOnEvent.om = operationalManagement;
                    personsOnEventStore.markChanged();
                }
            }
        }));
    }

    createUserOnEvent(input: CreateUserOnEventInput)
    {
        return createUserOnEvent(input).then(action(({errors, data}) =>
        {
            const res = data?.createUserOnEvent;
            if (errors)
            {
                switch (errors[0].message)
                {
                    case 'invalid-email':
                        Swal.fire({
                            title: t.registration.form.errors.emailFormat,
                            text: input.email,
                            icon: 'error',
                        });
                        break;
                    case 'invalid-phone':
                        Swal.fire({
                            title: t.registration.form.errors.phoneFormat,
                            text: input.phone,
                            icon: 'error',
                        });
                        break;
                    default:
                        Swal.fire({
                            title: t.global.somethingWentWrong,
                            icon: 'error',
                        });
                }
            }
            else
            {
                const {user: u, companyName} = res;
                this.contacts.push(u);
                const newPersonOnEvent = contactToPersonOnEvent(u, input.project);
                if (companyName)
                {
                    newPersonOnEvent.c[0].n = companyName;
                }
                personsOnEventStore.currentPersons.unshift(newPersonOnEvent);
                personsOnEventStore.markChanged();
            }
            return res;
        }));
    }
}

export const contactsStore = new ContactsStore();

function contactToPersonOnEvent(u: IContact, project: string): PersonOnEvent
{
    const b = u.badges.find(a => a.project == project);
    return {
        id: u.id,
        rid: b.registrationId,
        om: b.operationalManagement,
        pr: u.profiles?.[0]?.id,
        fn: u.firstName,
        ln: u.lastName,
        e: u.email,
        fu: u.function,
        c: u.company?.map(cid => ({id: cid, n: cid})),
        cn: u.companyName,
        ibid: b.invitedBy,
        ibn: b.invitedByName,
        da: b.days,
        lu: b.lunch,
        pa: b.parking,
        se: b.sideEvents?.map(s => s.reference),
        sc: !!(b.scannedIn?.length || b.scannedOut?.length),
        rat: b.registeredAt,
        bs: b.status || 'Pending',
        bv: b.vip,
    };
}
