import {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {observer} from 'mobx-react-lite';
import moment from 'moment';
import './OrderPage.scss';
import {projectStore} from '../stores/ProjectStore';
import {orderStore} from './OrderStore';
import {companyStore} from '../Company/CompanyStore';
import {countryCurrency} from '../OrderStand/utility';
import {catalogProductStore} from './CatalogProductStore';
import { t } from '../translations';
import CartOrderTotal from './CartOrderTotal';
import {Loading} from '../common/Loading';
import OrderProductView from './OrderProductView';

export default observer(function OrderPage()
{
    const country = projectStore.selectedCountry;
    const currency = countryCurrency(country);
    const isFair = !projectStore.isPromotion;
    const isFRNonFood = projectStore.isFRNonFood;

    const {id} = useParams<{id: string}>();
    const [wasLoading, setWasLoading] = useState(false);

    useEffect(() =>
    {
        projectStore.withId(pid =>
        {
            orderStore.load(pid);
        });
    }, [projectStore.id]);

    const order = orderStore.orders[projectStore.id]?.find(o => o.id == id);

    useEffect(() =>
    {
        if (order)
        {
            catalogProductStore.loadProducts(order.products.map(p => p.id));
            setWasLoading(true);
        }
    }, [order]);

    if (!order || ((!wasLoading || catalogProductStore.loading) && order.products.some(p => catalogProductStore.products.every(e => e.id != p.id))))
    {
        return (
            <div style={{height: '440px', position: 'relative'}}>
                <Loading/>
            </div>
        );
    }

    const store = companyStore.companies.find(c => c.store?.id == order.store);
    const storeType = store?.store?.storeType;
    const showBrand = !projectStore.selected.fair.settings.hideBrand;

    return (
        <div className='OrderPage container'>
            <div>
                <div className='summary'>
                    <p className='store'>{store?.store?.id} {store?.name}</p>
                    <p className='date'>{moment(order.date).format('DD MMM YYYY, H:mm')}</p>
                    {showBrand && <p className='exhibitor'>{order.exhibitorName}</p>}
                    <CartOrderTotal
                        storeType={store?.store?.storeType}
                        {...order.total}
                    />
                </div>
            </div>
            <div className='products'>
                <h1 className='mb-4'>{t.global.orderDetails}</h1>
                {order.products.map(p =>
                {
                    const product = catalogProductStore.products.find(e => e.id == p.id);
                    return (
                        <OrderProductView
                            key={p.id}
                            promotion={p.promotion}
                            uvir={product.uvir}
                            flow={product.flow}
                            description={product.description}
                            images={product.images}
                            country={country}
                            storeType={storeType}
                            isFRNonFood={isFRNonFood}
                            isFair={isFair}
                            currency={currency}
                        />
                    );
                })}
            </div>
        </div>
    );
});
