import React from 'react';
import {IPost, PostView, PostViewProps} from '../Post/PostView';

export type IHelpQuestion = Omit<IPost, 'date'>

interface Props extends PostViewProps<IHelpQuestion>
{
    defaultCollapsed?: boolean;
}

export function HelpQuestion(props: Props)
{
    return (
        <div className='py-3'>
            <PostView {...props} collapsible defaultCollapsed={props.defaultCollapsed} foldBack={true} viewMoreLabel category='help'/>
        </div>
    );
}
