import { observer } from 'mobx-react-lite';
import FA from 'react-fontawesome';
import { companyStore } from './CompanyStore';
import { user } from '../stores/user';
import { t } from '../translations';
import { ProfileItem } from '../common/ProfileItem';
import {countryStore} from '../stores/CountryStore';
import CompanyCountryDropdown from './CompanyCountryDropdown';
import {IAddress} from '../../graphql/api/company/Company';
import {Country} from '../../graphql/api/Types';
import {IMaybePath, IPath} from '../common/deepSet';

interface Props
{
    id?: string
}

const handleUpdateField = (key: IMaybePath, value: string) =>
{
    companyStore.updateContactInfo(key as IPath, value.trim());
};

const handleUpdateCountry = (kind: string, index: number) => (value: string) =>
{
    value = value.trim();
    if (/^[A-Z]{2}$/.test(value))
    {
        companyStore.updateContactInfo([kind, 'contacts', index, 'country'], value);
    }
};

const handleUpdateVAT = (key: IMaybePath, value: string) =>
{
    companyStore.updateContactInfo(key as IPath, value.replace(/[\s./\\]/g, ''));
    return false; // force update
};

export default observer(function CompanyContacts({id}: Props)
{
    const isOwnCompany = !id || user.info?.company.includes(id);
    const editable = (isOwnCompany && !user.isHeadquarter && !user.isStore) || user.moderator;
    const company = companyStore.company;
    const kind = company.exhibitor?.contacts?.length ?
        'exhibitor' :
        company.store?.contacts?.length ?
            'store' :
            company.store?.id ?
                'store' :
                'exhibitor';
    const contacts = company[kind]?.contacts || [];
    return (
        <>
            <CompanyContact editable={editable} kind={kind} a={contacts[0] || {} as any} i={0} country={company.country}/>
            {(user.moderator || !(company.store?.id || company.store?.storeType)) &&
            <CompanyContact editable={editable} kind={kind} a={contacts[1] || {} as any} i={1} country={company.country}/>}
        </>
    );
});

interface CompanyContactProps
{
    editable: boolean
    kind: string
    a: IAddress
    i: number
    country: Country[]
}

const CompanyContact = observer(function CompanyContact({editable, kind, a, i, country}: CompanyContactProps)
{
    return (
        <div className='mb-4'>
            <h5 className='pb-2 mb-4 border-bottom border-dark'>
                {i === 0 ? t.companyPage.main : t.companyPage.invoicing}
            </h5>
            <div className='row'>
                <div className='col-sm-6'>
                    <ProfileItem
                        title='Company Name'
                        className='font-weight-bold'
                        objKey={[kind, 'contacts', i, 'company']}
                        content={a.company || t.global.company}
                        onChanged={handleUpdateField}
                        editable={editable}
                        dimmed={!a.company || a.company === 'Company' || a.company === t.global.company}
                    >
                        {user.moderator &&
                        <FA
                            name='arrow-alt-circle-down ml-2 p-2 text-large cursor-pointer'
                            className='fad'
                            onClick={() => companyStore.companyAddressFromVat(i)}
                        />}
                    </ProfileItem>
                    <ProfileItem
                        title='Street'
                        objKey={[kind, 'contacts', i, 'street']}
                        content={a.street || t.global.street}
                        onChanged={handleUpdateField}
                        editable={editable}
                        dimmed={!a.street || a.street == 'Street' || a.street == t.global.street}
                    />
                    <ProfileItem
                        title='Zip Code'
                        objKey={[kind, 'contacts', i, 'zipCode']}
                        content={a.zipCode || t.global.zipCode}
                        onChanged={handleUpdateField}
                        editable={editable}
                        dimmed={!a.zipCode || a.zipCode == 'Zip code' || a.zipCode == t.global.zipCode}
                    />
                    <ProfileItem
                        title='City'
                        objKey={[kind, 'contacts', i, 'city']}
                        content={a.city || t.global.city}
                        onChanged={handleUpdateField}
                        editable={editable}
                        dimmed={!a.city || a.city == 'City' || a.city == t.global.city}
                    />
                    {editable ? (
                        <CompanyCountryDropdown
                            className='company-country'
                            onSelect={handleUpdateCountry(kind, i)}
                            onCustom={handleUpdateCountry(kind, i)}
                            selected={a.country}
                            placeholder={t.global.country}
                        />
                    ) : (
                        <ProfileItem
                            title='Country'
                            content={countryStore.countries.find(c => c.code == a.country)?.name[user.language] || a.country || t.global.country}
                            editable={false}
                            dimmed={!a.country || a.country == 'Country' || a.country == t.global.country}
                        />
                    )}
                </div>
                <div className='col-sm-6'>
                    <div className='d-flex'>
                        <ProfileItem
                            className='pr-2'
                            content={`${t.global.vat}:`}
                            editable={false}
                        />
                        <ProfileItem
                            objKey={[kind, 'contacts', i, 'vat']}
                            content={a.vat || t.global.vat}
                            editable={user.moderator || (editable && country.includes('ES'))}
                            onChanged={handleUpdateVAT}
                            dimmed={!a.vat || a.vat == 'BE99xxxxxx' || a.vat == t.global.vat}
                        />
                    </div>
                    {user.moderator &&
                    <div className='d-flex'>
                        <ProfileItem
                            className='pr-2'
                            content='dside Code:'
                            editable={false}
                        />
                        <ProfileItem
                            objKey={[kind, 'contacts', i, 'dsideCode']}
                            content={a.dsideCode || 'd-side code'}
                            onChanged={handleUpdateField}
                            editable={editable}
                            dimmed={!a.dsideCode || a.dsideCode === 'd-side code'}
                        />
                    </div>}
                    <div>
                        <ProfileItem
                            title='Email'
                            objKey={[kind, 'contacts', i, 'email']}
                            content={a.email || 'Email'}
                            onChanged={handleUpdateField}
                            editable={editable}
                            dimmed={!a.email || a.email == 'Email'}
                        />
                        <ProfileItem
                            title='Phone'
                            objKey={[kind, 'contacts', i, 'phone']}
                            content={a.phone || t.global.phone}
                            onChanged={handleUpdateField}
                            editable={editable}
                            dimmed={!a.phone || a.phone == '+32495xxxxxx' || a.phone == t.global.phone}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});
