import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import './PickPageTemplate.scss';
import {pickPageTemplate} from './PickPageTemplateStore';
import {projectStore} from '../stores/ProjectStore';
import {t} from '../translations';
import {pagePath} from './utility';

export default observer(function PickPageTemplate()
{
    const navigate = useNavigate();
    return (
        <div className='PickPageTemplate'>
            <div>
                <h1>{t.choosePage.title}</h1>
                {pickPageTemplate.templateChoices[projectStore.id]?.map(p =>
                    <div
                        key={p.id}
                        className='choice'
                        onClick={() => pickPageTemplate.choose(projectStore.id, p.id).then(pages => {if (pages) navigate(pagePath(pages[0]))})}
                    >
                        {p.name}
                    </div>
                )}
            </div>
        </div>
    )
});
