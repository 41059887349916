// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-blue {
  background-color: #4687ff;
}

.label-green {
  background-color: #30b330;
}

.label-teal {
  background-color: #23d1d1;
}

.label-orange {
  background-color: #FCA529;
}

.label-red {
  background-color: #fc4c28;
}

.label-peach {
  background-color: #BC8F8F;
}

.label-purple {
  background-color: #9370DB;
}

.label-black {
  background-color: #000;
}`, "",{"version":3,"sources":["webpack://./frontend/Admin/Labels/Labels.scss"],"names":[],"mappings":"AACA;EACE,yBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,sBAAA;AAAF","sourcesContent":["\n.label-blue {\n  background-color: #4687ff;\n}\n\n.label-green {\n  background-color: #30b330;\n}\n\n.label-teal {\n  background-color: #23d1d1;\n}\n\n.label-orange {\n  background-color: #FCA529;\n}\n\n.label-red {\n  background-color: #fc4c28;\n}\n\n.label-peach {\n  background-color: #BC8F8F;\n}\n\n.label-purple {\n  background-color: #9370DB;\n}\n\n.label-black {\n  background-color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
