// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.Checkbox.CheckboxBorder {
  border: 2px solid #fff;
  padding: 0.25em 0.2em;
  border-radius: 20px;
}
.Checkbox input {
  display: none;
}
.Checkbox .CheckboxLabel {
  margin: 0;
  padding-left: 0.5em;
  display: block;
  cursor: pointer;
}
.Checkbox .CheckboxCheckmark {
  position: relative;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50px;
  flex-shrink: 0;
}
.Checkbox .CheckboxCheckmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 10px;
  top: 5px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.Checkbox:hover .CheckboxCheckmark {
  background-color: #ccc;
}
.Checkbox:hover .CheckboxCheckmark:after {
  display: block;
}
.Checkbox.CheckboxChecked {
  border: 2px solid #30b330;
}
.Checkbox.CheckboxChecked .CheckboxCheckmark {
  background-color: #30b330;
}
.Checkbox.CheckboxChecked .CheckboxCheckmark:after {
  display: block;
}`, "",{"version":3,"sources":["webpack://./frontend/common/Checkbox.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,eAAA;EACA,yBAAA;EACA,sBAAA;EACA,qBAAA;EACA,iBAAA;AACF;AACE;EACE,sBAAA;EACA,qBAAA;EACA,mBAAA;AACJ;AAEE;EACE,aAAA;AAAJ;AAGE;EACE,SAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;AADJ;AAIE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;AAFJ;AAKE;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,UAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,gCAAA;EACA,4BAAA;EACA,wBAAA;AAHJ;AAME;EACE,sBAAA;AAJJ;AAMI;EACE,cAAA;AAJN;AAQE;EACE,yBAAA;AANJ;AAQI;EACE,yBAAA;AANN;AASI;EACE,cAAA;AAPN","sourcesContent":[".Checkbox {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 0 auto;\n  cursor: pointer;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n\n  &.CheckboxBorder {\n    border: 2px solid #fff;\n    padding: .25em .2em;\n    border-radius: 20px;\n  }\n\n  input {\n    display: none;\n  }\n\n  .CheckboxLabel {\n    margin: 0;\n    padding-left: .5em;\n    display: block;\n    cursor: pointer;\n  }\n\n  .CheckboxCheckmark {\n    position: relative;\n    height: 25px;\n    width: 25px;\n    background-color: #eee;\n    border-radius: 50px;\n    flex-shrink: 0;\n  }\n\n  .CheckboxCheckmark:after {\n    content: \"\";\n    position: absolute;\n    display: none;\n    left: 10px;\n    top: 5px;\n    width: 7px;\n    height: 14px;\n    border: solid white;\n    border-width: 0 3px 3px 0;\n    -webkit-transform: rotate(45deg);\n    -ms-transform: rotate(45deg);\n    transform: rotate(45deg);\n  }\n\n  &:hover .CheckboxCheckmark {\n    background-color: #ccc;\n\n    &:after {\n      display: block;\n    }\n  }\n\n  &.CheckboxChecked {\n    border: 2px solid #30b330;\n\n    .CheckboxCheckmark {\n      background-color: #30b330;\n    }\n\n    .CheckboxCheckmark:after {\n      display: block;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
