import {
    IPackageContext,
    IPackageProduct,
    IPackageProductReference,
    IPackageProductSurface,
    IPackageProductWall,
    WallType
} from '../graphql/api/package/Package';
import {FreshSpaceRange, IStandSetup, IStandSetupNormal} from '../graphql/api/stand/Stand';

export function filterPackageProductsForSetup(products: IPackageProduct[], setup: IStandSetup, isModerator: boolean)
{
    return products.filter(p =>
        setup.type === 'normal' ? findSurface(p.surfaces, setup.surface, isModerator) || findWall(p.walls, setup) :
            setup.type === 'fresh' ? findReference(p.references, setup.spaceRange) : true
    );
}

function findSurface(surfaces: IPackageProductSurface[], surface: number, isModerator: boolean)
{
    return surfaces && surfaces.length ? surfaces.find(s => compareSurface(s, surface, isModerator)) : null;
}

function compareSurface(s: IPackageProductSurface, surface: number, isModerator: boolean)
{
    return (
        !s.restricted || isModerator
    ) && (
        s.value ? s.value.includes(surface) :
        s.range ? s.range[0] <= surface && surface <= s.range[1] :
            false
    );
}

function findWall(walls: IPackageProductWall[], setup: IStandSetupNormal)
{
    return setup && walls && walls.length ? walls.find(s => compareWall(s, setup)) : null;
}

function compareWall(s: IPackageProductWall, setup: IStandSetupNormal)
{
    const wallLength = calculateWallLength(s.wallType, setup);
    return s.value ? s.value.includes(wallLength) :
        s.range ? s.range[0] <= wallLength && wallLength <= s.range[1] :
            false;
}

function getWallQuantity(wall: IPackageProductWall, setup: IStandSetupNormal)
{
    return wall.customQuantity ? wall.quantity : calculateWallLength(wall.wallType, setup);
}

function calculateWallLength(type: WallType, setup: IStandSetupNormal)
{
    if (setup.open)
    {
        return 0;
    }
    const backSize = setup.dimensions.length;
    const sideSize = setup.head ? 0 : setup.corner || setup.surface <= 6 ? setup.dimensions.depth : 2 * setup.dimensions.depth;
    return type === 'back' ? backSize : type === 'side' ? sideSize : backSize + sideSize;
}

function findReference(references: IPackageProductReference[], spaceRange: FreshSpaceRange)
{
    return references && references.length ? references.find(r => !r.range || r.range === spaceRange) : null;
}

interface PackageProductSetup
{
    quantity: number
    priceMultiplier: number // = quantity * discount
}

export function packageProductSetup(product: IPackageProduct, setup: IStandSetup, pkgDiscount: number, isModerator: boolean): PackageProductSetup
{
    pkgDiscount = pkgDiscount ?? 1;
    if (setup.type === 'normal')
    {
        // 'hide' indicates the product that scales with surface
        // presence of a wall overrides surface
        const sur = findSurface(product.surfaces, setup.surface, isModerator);
        const wall = findWall(product.walls, setup);
        const quantity = wall ? getWallQuantity(wall, setup as any) :
            product.hide ? setup.surface : sur?.quantity || 0;
        const discount = (wall ? wall.discount : sur?.discount) ?? 1;
        return {
            quantity,
            priceMultiplier: quantity * discount * (product.hide ? 1 : pkgDiscount)
        }
    }
    else if (setup.type === 'fresh')
    {
        const ref = findReference(product.references, setup.spaceRange);
        const quantity = ref?.quantity || 0;
        return {
            quantity,
            priceMultiplier: quantity * (ref?.discount ?? 1) * pkgDiscount
        };
    }
    else // concept
    {
        const quantity = product.quantity || 0;
        return {
            quantity,
            priceMultiplier: quantity * pkgDiscount,
        };
    }
}

export function packageContainsSetup(pkg: IPackageContext, setup: IStandSetup, parentGetter: (id: string) => IPackageContext, isModerator: boolean)
{
    return setup.type === 'normal' ? packageContainsSurface(pkg, setup.surface, parentGetter, isModerator) :
        setup.type === 'fresh' ? packageContainsReference(pkg, setup.spaceRange, parentGetter) : true;
}

function packageContainsSurface(pkg: IPackageContext, surface: number, parentGetter: (id: string) => IPackageContext, isModerator: boolean)
{
    let parent: IPackageContext;
    return pkg.products?.some(p => p.hide && findSurface(p.surfaces, surface, isModerator)) ||
        ((parent = pkg.parentId && parentGetter(pkg.parentId)) &&
            packageContainsSurface(parent, surface, parentGetter, isModerator))
}

function packageContainsReference(pkg: IPackageContext, spaceRange: FreshSpaceRange, parentGetter: (id: string) => IPackageContext)
{
    let parent: IPackageContext;
    return pkg.products?.some(p => findReference(p.references, spaceRange)) ||
        ((parent = pkg.parentId && parentGetter(pkg.parentId)) &&
            packageContainsReference(parent, spaceRange, parentGetter))
}
