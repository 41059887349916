// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JmmqWTOFdsz3WMPn6_fn {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rNFBJKx0mmJYRxxcks7n {
  width: 40px;
  height: 60px;
  text-align: center;
  border-radius: 3px;
  border: 2px solid #74A48D;
  font-size: 1rem;
}
.rNFBJKx0mmJYRxxcks7n:first-child {
  margin-left: 0;
}
.rNFBJKx0mmJYRxxcks7n:last-child {
  margin-right: 0;
}
.rNFBJKx0mmJYRxxcks7n::placeholder {
  color: gray;
}

@media only screen and (min-width: 500px) {
  .rNFBJKx0mmJYRxxcks7n {
    width: 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/components/CodeField.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;AACb;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".row {\n  max-width: 360px;\n  margin-left: auto;\n  margin-right: auto;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.single {\n  width: 40px;\n  height: 60px;\n  text-align: center;\n  border-radius: 3px;\n  border: 2px solid #74A48D;\n  font-size: 1rem;\n}\n.single:first-child {\n  margin-left: 0;\n}\n.single:last-child {\n  margin-right: 0;\n}\n.single::placeholder {\n  color: gray;\n}\n\n@media only screen and (min-width: 500px) {\n  .single {\n    width: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `JmmqWTOFdsz3WMPn6_fn`,
	"single": `rNFBJKx0mmJYRxxcks7n`
};
export default ___CSS_LOADER_EXPORT___;
