// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StoreExhibitorOrderCard {
  position: relative;
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  padding: 15px;
}
.StoreExhibitorOrderCard * {
  margin: 0;
}
.StoreExhibitorOrderCard *:not(.fa) {
  font-family: "Montserrat", sans-serif;
}
.StoreExhibitorOrderCard .link {
  font-size: 22px;
  position: absolute;
  top: 10px;
  right: 20px;
}
.StoreExhibitorOrderCard .exhibitorName {
  font-size: 18px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./frontend/StoreCatalog/StoreExhibitorOrderCard.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AACF;AAAE;EACE,SAAA;AAEJ;AAAE;EACE,qCAAA;AAEJ;AAAE;EACE,eAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;AAEJ;AAAE;EACE,eAAA;EACA,iBAAA;AAEJ","sourcesContent":[".StoreExhibitorOrderCard {\n  position: relative;\n  border: 2px solid #e0e0e0;\n  border-radius: 15px;\n  padding: 15px;\n  * {\n    margin: 0;\n  }\n  *:not(.fa) {\n    font-family: 'Montserrat', sans-serif;\n  }\n  .link {\n    font-size: 22px;\n    position: absolute;\n    top: 10px;\n    right: 20px;\n  }\n  .exhibitorName {\n    font-size: 18px;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
