import {action, makeObservable, observable} from 'mobx';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {ICartInput, loadCartListProducts} from './cartProxy';
import {cartQuantityStore} from './CartQuantityStore';
import {catalogProductStore} from './CatalogProductStore';
import {cartValidateStore} from './CartValidateStore';
import {registerGlobalEventHandler} from '../stores/globalEvents';

type Listener = (project: string, products: ICatalogProduct[]) => void

class CartListStore
{
    private listeners = new Set<Listener>();
    @observable products: {[project: string]: {[storeId: string]: ICatalogProduct[]}} = {};
    @observable counts: {[project: string]: {[storeId: string]: number}} = {};
    lastInput: Omit<ICartInput, 'limit' | 'skip'>;
    @observable searchProducts: ICatalogProduct[] = null;
    @observable searchCount: number = null;

    @observable loading: boolean = false;

    constructor()
    {
        makeObservable(this);
        cartQuantityStore.addListener((project, products) =>
        {
            const projectGroup = this.products[project];
            for (const product of products)
            {
                if (projectGroup)
                {
                    for (const storeId of Object.getOwnPropertyNames(projectGroup))
                    {
                        const storeList = projectGroup[storeId];
                        if (storeList)
                        {
                            const existingIndex = storeList.findIndex(p => p.id == product.id);
                            const wasInList = existingIndex >= 0;
                            const shouldBeInList = product.stores.find(s => s.storeId == storeId)?.weeks.some(w => w.quantity);
                            if (wasInList)
                            {
                                if (shouldBeInList)
                                {
                                    Object.assign(storeList[existingIndex], product);
                                }
                                else
                                {
                                    storeList.splice(existingIndex, 1);
                                    --this.counts[project][storeId];
                                }
                            }
                            else
                            {
                                if (shouldBeInList)
                                {
                                    projectGroup[storeId] = null;
                                    this.counts[project][storeId] = null;
                                }
                            }
                        }
                    }
                }
                if (this.searchProducts && this.lastInput)
                {
                    const {storeId} = this.lastInput;
                    const existingIndex = this.searchProducts.findIndex(p => p.id == product.id);
                    const wasInList = existingIndex >= 0;
                    const shouldBeInList = product.stores.find(s => s.storeId == storeId)?.weeks.some(w => w.quantity);
                    if (wasInList)
                    {
                        if (shouldBeInList)
                        {
                            Object.assign(this.searchProducts[existingIndex], product);
                        }
                        else
                        {
                            this.searchProducts.splice(existingIndex, 1);
                            --this.searchCount;
                        }
                    }
                    else
                    {
                        if (shouldBeInList)
                        {
                            this.searchProducts = null;
                            this.searchCount = null;
                        }
                    }
                }
            }
        });
        cartValidateStore.addListener(project =>
        {
            if (this.products[project])
            {
                this.products[project] = {};
                this.counts[project] = {};
            }
            this.reload();
        });
        registerGlobalEventHandler('logout', () =>
        {
            this.reset();
        });
    }

    addListener(listener: Listener)
    {
        if (typeof listener != 'function')
        {
            throw new TypeError('Listener must be a function');
        }
        this.listeners.add(listener);
    }

    // removeListener(listener: Listener)
    // {
    //     this.listeners.delete(listener);
    // }

    reset()
    {
        this.products = {};
        this.counts = {};
        this.lastInput = null;
        this.searchProducts = null;
        this.searchCount = null;
    }

    reload()
    {
        return this.loadProducts(this.lastInput);
    }

    @action
    loadProducts(input: Omit<ICartInput, 'limit' | 'skip'>)
    {
        const {project, storeId} = input;
        const thisIsSearch = input.deliveryMonth || input.brands?.length;
        this.loading = true;
        if (!thisIsSearch || (input.storeId != this.lastInput?.storeId))
        {
            this.searchProducts = null;
            this.searchCount = null;
        }
        this.lastInput = input;
        return loadCartListProducts(input).then(action(res =>
        {
            this.loading = false;
            if (res)
            {
                if (thisIsSearch)
                {
                    this.searchProducts = res.products;
                    this.searchCount = res.count;
                }
                else
                {
                    if (!this.products[project])
                    {
                        this.products[project] = {};
                        this.counts[project] = {};
                    }
                    this.products[project][storeId] = res.products;
                    this.counts[project][storeId] = res.count;
                }
                for (const product of res.products)
                {
                    catalogProductStore.addProduct(product);
                }
                for (const listener of this.listeners)
                {
                    try
                    {
                        listener(project, res.products);
                    }
                    catch (e)
                    {
                        console.error(e);
                    }
                }
            }
        }));
    }

    @action
    loadMoreProducts()
    {
        if (!this.lastInput)
        {
            return;
        }
        const {project, storeId} = this.lastInput;
        const thisIsSearch = this.lastInput.deliveryMonth || this.lastInput.brands?.length;
        if (this.loading || (
            thisIsSearch ?
                !this.searchProducts ||
                this.searchCount == this.searchProducts.length
                :
                !this.products[project] ||
                !this.products[project][storeId] ||
                !this.counts[project] ||
                this.counts[project][storeId] == this.products[project][storeId].length
        ))
        {
            return;
        }
        this.loading = true;
        loadCartListProducts({project, storeId, skip: this.products[project][storeId].length}).then(action(res =>
        {
            this.loading = false;
            if (res)
            {
                if (thisIsSearch)
                {
                    this.searchProducts.push(...res.products);
                    this.searchCount = res.count;
                }
                else
                {
                    this.products[project][storeId].push(...res.products);
                    this.counts[project][storeId] = res.count;
                }
                for (const product of res.products)
                {
                    catalogProductStore.addProduct(product);
                }
            }
        }));
    }
}

export const cartListStore = new CartListStore();
