import React from 'react';
import {observer} from 'mobx-react-lite';
import './ProductLabels.scss';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {flat, onlyUnique} from '../../lib/common';
import {pickLanguage} from '../stores/utility';
import {projectStore} from '../stores/ProjectStore';

interface Props
{
    product: ICatalogProduct
    storeId?: string
}

export default observer(function ProductLabels({product, storeId}: Props)
{
    const catalogList = storeId ?
        product.stores.find(s => s.storeId == storeId)?.weeks.map(w => w.catalog).filter(v => v).filter(onlyUnique) :
        flat(product.stores.map(s => s.weeks.map(w => w.catalog).filter(v => v))).filter(onlyUnique);
    const messageList =
        projectStore.isFRNonFood ? (
            storeId ?
                product.stores.find(s => s.storeId == storeId)?.weeks.map(w => w.messageImage ? null : w.message).filter(v => v).filter(onlyUnique)
                :
                flat(product.stores.map(s => s.weeks.map(w => w.messageImage ? null : w.message).filter(v => v))).filter(onlyUnique)
        ) : null;
    if (!product.labels.length && !catalogList?.length && !messageList?.length)
    {
        return null;
    }
    return (
        <div className='ProductLabels'>
            {product.labels.map(l => <div key={l.id} className={'label-' +  l.color}>{pickLanguage(l.title)}</div>)}
            {catalogList.map((l, i) => <div key={i} className='label-special-orange'>{l}</div>)}
            {messageList?.map((l, i) => <div key={i} className='label-special-green'>{l}</div>)}
        </div>
    );
});
