const cols = 8;
const width = 85;
const height = 85;
const padding = 20;
const totalWidth = cols * width + (cols + 1) * padding;

export const pageConfig = {
    cols,
    width,
    height,
    padding,
    totalWidth,
};
