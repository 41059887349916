// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingContainer {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Loading,
.LoadingInline {
  border: 5px solid rgba(231, 231, 231, 0.5);
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  -webkit-animation: loading-spin 1s linear infinite; /* Safari */
  animation: loading-spin 1s linear infinite;
}

.LoadingInline {
  display: inline-block;
  width: 1em;
  height: 1em;
  min-width: 1em;
  min-height: 1em;
  border-width: 0.2em;
}

.LoadingOnGrayBackground {
  border-color: rgba(183, 183, 183, 0.5);
  border-top-color: #3498db;
}

/* Safari */
@-webkit-keyframes loading-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loading-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./frontend/common/Loading.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;;EAEE,0CAAA;EACA,kBAAA;EACA,6BAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,kDAAA,EAAA,WAAA;EACA,0CAAA;AACF;;AAEA;EACE,qBAAA;EACA,UAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,sCAAA;EACA,yBAAA;AACF;;AAEA,WAAA;AACA;EACE;IAAK,+BAAA;EAEL;EADA;IAAO,iCAAA;EAIP;AACF;AAFA;EACE;IAAK,uBAAA;EAKL;EAJA;IAAO,yBAAA;EAOP;AACF","sourcesContent":[".LoadingContainer {\n  pointer-events: none;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.Loading,\n.LoadingInline {\n  border: 5px solid rgba(231, 231, 231, 0.5);\n  border-radius: 50%;\n  border-top: 5px solid #3498db;\n  width: 50px;\n  height: 50px;\n  min-width: 50px;\n  min-height: 50px;\n  -webkit-animation: loading-spin 1s linear infinite; /* Safari */\n  animation: loading-spin 1s linear infinite;\n}\n\n.LoadingInline {\n  display: inline-block;\n  width: 1em;\n  height: 1em;\n  min-width: 1em;\n  min-height: 1em;\n  border-width: .2em;\n}\n\n.LoadingOnGrayBackground {\n  border-color: rgba(183, 183, 183, 0.5);\n  border-top-color: #3498db;\n}\n\n/* Safari */\n@-webkit-keyframes loading-spin {\n  0% { -webkit-transform: rotate(0deg); }\n  100% { -webkit-transform: rotate(360deg); }\n}\n\n@keyframes loading-spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
