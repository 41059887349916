import React from 'react';
import './CompanyProfile.scss'
import {companyStore, ICompany} from './CompanyStore'
import { observer } from 'mobx-react-lite';
import DropdownSearch from '../common/DropdownSearch'
import ChangeImage from '../common/ChangeImage';
import {MemoizedProfileItem as ProfileItem} from '../common/ProfileItem'
import { user } from '../stores/user';
import {t} from '../translations';
import {projectStore} from '../stores/ProjectStore';
import { countryStore } from '../stores/CountryStore';
import FA from 'react-fontawesome';
import DownloadCompanyBadges from '../FairParticipation/DownloadCompanyBadges';
import {findStoreTypeName, storeTypes} from '../data/storeTypes';
import {pickLanguage} from '../stores/utility';
import {Country} from '../../graphql/api/Types';

interface Props
{
    company: ICompany
}

const handleDelete = () =>
{
    companyStore.update('image', '');
}
const handleUpdateField = (obj, value) =>
{
    const v = value?.trim();
    if (obj === 'name' && !v)
    {
        return false;
    }
    companyStore.update(obj, v);
}
const handledUpload = (url) =>
{
    companyStore.update('image', url);
}

const handleStoreTypeChange = (storeType: string) =>
{
    companyStore.update(['store', 'storeType'], storeType);
}

const handleCountry = (country: Country[]) =>
{
    if (!country.length)
    {
        alert('Add another country first');
        return;
    }
    companyStore.update('country', country);
}

export default observer(function CompanyProfile({company}: Props)
{
    const isOwnCompany = user.info?.company.includes(company.id);
    const editable = (isOwnCompany && !user.isHeadquarter && !user.isStore) || user.moderator;

    const storeType = company.store?.storeType;

    const addCompanyToProject = () =>
    {
        companyStore.addProject(company.id);
    }

    return (
        <div className='company-profile'>
            {isOwnCompany && <h3 className='mb-2 myCompany'>{t.global.myCompany}</h3>}
            <div className='company-profile-inner'>
                <ChangeImage
                    pic={company.image || 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/brmzrZCdwhvr5%2B3R_placeholder_company.png'}
                    onUpload={handledUpload}
                    onDelete={handleDelete}
                    className='companyLogo'
                    editable={editable}
                >
                    {!company.image && <div className='companyLogoText'>300x300</div>}
                </ChangeImage>
                <div className='details'>
                    <div className='d-flex flex-wrap-reverse justify-content-between'>
                        <div className='companyName'>
                            <ProfileItem
                                objKey='name'
                                content={company.name || ''}
                                onChanged={handleUpdateField}
                                editable={editable}
                                className='nameCompany'
                            />
                        </div>
                        <div className='d-flex flex-wrap align-items-start justify-content-end flex-grow-1'>
                            {user.moderator && !company.projects?.find(p => p.id == projectStore.id) &&
                            <button className='button' onClick={addCompanyToProject}>Add to project</button>
                            }
                            {!projectStore.isFRNonFood && <DownloadCompanyBadges companyId={company.id} className='ml-3'/>}
                        </div>
                    </div>
                    <div className='info'>
                        {user.moderator ?
                            <div className='d-flex flex-wrap align-items-center'>
                                <FA name='address-card' className='fad mr-2'/>
                                <DropdownSearch
                                    className='companyProfile'
                                    options={
                                        storeTypes[company.country?.[0] || projectStore.selectedCountry]
                                            ?.map(st => ({value: st.id, name: pickLanguage(st.name)})) || []
                                    }
                                    onSelect={handleStoreTypeChange}
                                    selected={storeType}
                                    defaultValue='Exhibitor'
                                />
                                <DropdownSearch
                                    className='companyCountry'
                                    options={countryStore.countryOptions}
                                    onChange={handleCountry}
                                    selected={company.country}
                                />
                            </div> :
                            editable || user.isHostess ?
                                <ProfileItem
                                    objKey='no'
                                    content={
                                        (storeType ? pickLanguage(findStoreTypeName(company.country[0], storeType)) + ', ' : '') +
                                        (company.country.map(cc => pickLanguage(countryStore.countries.find(c => c.code == cc)?.name) || cc).join(', ') || 'Country')
                                    }
                                    icon='address-card'
                                    editable={false}
                                /> :
                                null
                        }
                        <div className='d-row'>
                            <ProfileItem
                                objKey={['exhibitor', 'website']}
                                content={company.exhibitor.website || t.global.website}
                                icon='link'
                                onChanged={handleUpdateField}
                                editable={editable}
                                dimmed={company.exhibitor.website == 'www.mywebsite.com'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});
