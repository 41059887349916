import React, {PropsWithChildren} from 'react';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';

export default function TabLink({to, children}: PropsWithChildren<{ to: string }>)
{
    const location = useLocation();
    const search = location.search || '?';
    const hash = location.hash;
    to = to.includes('/') || to.includes('?') ? to : '?' + to;
    return (
        <Link
            to={to + hash}
            className={
                'unstyle-link d-inline-block px-3 pb-2' +
                (search == to || location.pathname + location.search == to ? ' border-bottom border-primary' : '')
            }
        >
            {children}
        </Link>
    );
}
