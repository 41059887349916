import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {computed} from 'mobx';
import FA from 'react-fontawesome';
import {chatStore} from '../Chat/ChatStore';
import TimelineMessage from './TimelineMessage';
import {getInitials, getName} from '../common/getName';
import TimelineCommentInput from './TimelineCommentInput';
import {user} from '../stores/user';
import AttachmentTag from '../Attachment/AttachmentTag';
import {TimelineRow} from './TimelineItem';
import {colorHash} from '../common/colorHash';
import {IChat} from '../Chat/chatProxy';
import {IChatMessage} from '../../graphql/api/chat/Chat';
import {t} from '../translations';

interface TimelineChatsProps
{
    targetId?: string
}

export default observer(function TimelineChats({targetId}: TimelineChatsProps)
{
    useEffect(() =>
    {
        chatStore.reloadChatsOnTargets([targetId]);
    }, [targetId]);

    const [folded, setFolded] = useState(true);

    return <>
        <ChatsTop targetId={targetId} onFold={() => setFolded(!folded)}/>
        {!folded && <ChatsList targetId={targetId}/>}
        {!folded && <ChatsHighlights targetId={targetId}/>}
    </>;
});

const ChatsTop = observer(function ChatsTop({targetId, onFold})
{
    const chats = chatStore.chats.filter(c => c.targetId == targetId);
    return (
        <p>
            {!!chats.length &&
            <span className='cursor-pointer' onClick={e => {if (e.target == e.currentTarget) onFold()}}>
                {chats.length}{` ${t.chat.ongoingConversations} - `}
            </span>}
            <a href='#' onClick={() => chatStore.createChat({targetId})}>{t.chat.startNewConversation}</a>
        </p>
    );
});

const ChatsList = observer(function ChatsList({targetId}: TimelineChatsProps)
{
    const chats = chatStore.chats.filter(c => c.targetId == targetId);
    return (
        <div className='d-flex flex-wrap'>
            {chats.map(c =>
                <div key={c.id} className='align-self-stretch border rounded px-3 py-2 cursor-pointer mt-3 mr-3' onClick={() => chatStore.openChat(c.id)}>
                    {c.usersInChat.map(p =>
                        <p key={p.id}>{getName(p)}</p>
                    )}
                </div>
            )}
        </div>
    );
});

const ChatsHighlights = observer(function ChatsHighlights({targetId}: TimelineChatsProps)
{
    const chats = chatStore.chats.filter(c => c.targetId == targetId);
    const highlightGroups: ChatsHighlightGroupProps[] = [];
    for (const c of chats)
    {
        let group: IChatMessage[] = [];
        for (const m of c.messages)
        {
            if (m.highlighted)
            {
                group.push(m);
            }
            else if (group.length)
            {
                highlightGroups.push({chat: c, messages: group});
                group = [];
            }
        }
        if (group.length)
        {
            highlightGroups.push({chat: c, messages: group});
        }
    }
    return <>{highlightGroups.map(g => <ChatsHighlightGroup key={g.chat.id} {...g}/>)}</>;
});

interface ChatsHighlightGroupProps
{
    chat: IChat
    messages: IChatMessage[]
}

const ChatsHighlightGroup = observer(function ChatsHighlightGroup({chat, messages}: ChatsHighlightGroupProps)
{
    const byAuthor: {author: string, messages: IChatMessage[]}[] = [];

    for (const m of messages)
    {
        const last = byAuthor[byAuthor.length - 1];
        if (!last || last.author != m.author)
        {
            byAuthor.push({
                author: m.author,
                messages: [m],
            });
        }
        else
        {
            last.messages.push(m);
        }
    }

    return (
        <div className='ChatsHighlightGroup d-inline-block border rounded px-3 py-2 cursor-pointer mt-3 mr-3' onClick={() => chatStore.openChat(chat.id)}>
            <p className='top mb-2'>
                Highlighted conversation
                <FA name='highlighter' className='fad highlight float-right ml-3'/>
            </p>
            {byAuthor.map((g, i) =>
            {
                const author = chat.participants.find(p => p.id == g.author);
                return (
                    <div key={g.author + i} className='d-flex'>
                        <div>
                            <div className='ChatIcon' style={{backgroundColor: colorHash.hex(g.author)}}>{getInitials(author)}</div>
                        </div>
                        <div>
                            <p className='author'>{getName(author)}</p>
                            {g.messages.map(m =>
                                <div key={m.date + m.msg}>
                                    {m.msg}
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
});



@observer
class TimelineChats1 extends React.PureComponent<TimelineChatsProps>
{
    componentDidMount()
    {
        chatStore.reloadChatsOnTargets([this.props.targetId]);
    }

    @computed
    get items()
    {
        const chat = chatStore.chats.find(c => c.targetId === this.props.targetId);
        if (!chat) return null;
        let messages = chat.messages;
        if (messages.length > 3)
        {
            messages = messages.slice(messages.length - 3);
        }
        return messages.map(m =>
        {
            const author = chat.participants?.find(p => p.id === m.author);
            const attachments = !!m.attachments && m.attachments.map(a => (
                <AttachmentTag
                    key={a.key}
                    keyName={a.key}
                    name={a.name}
                />
            ));
            return (
                <TimelineMessage
                    key={m.date + m.msg}
                    mark={getInitials(author)}
                    markStyle={{backgroundColor: colorHash.hex(author?.id)}}
                    name={getName(author)}
                    message={m.msg}
                    unstyledMessage={attachments}
                    date={m.date}
                >
                    {!!m.msg && <TimelineRow content={attachments}/>}
                </TimelineMessage>
            );
        });
    }

    handleMessage = (msg: string, attachments: string[]) =>
    {
        chatStore.sendMessageOnTarget(this.props.targetId, msg, attachments);
    };

    handleOpenChatWindow = (e) =>
    {
        e.preventDefault();
        const chat = chatStore.chats.find(c => c.targetId === this.props.targetId);
        if (chat)
        {
            chatStore.openChat(chat.id);
        }
    };

    render()
    {
        return (
            <div className='TimelineChat'>
                {!!this.items?.length && (
                    <p className='mb-3'><a href='#' onClick={this.handleOpenChatWindow}>Open in chat window</a></p>
                )}
                {this.items}
                <TimelineCommentInput
                    mark={user.initials}
                    markStyle={{backgroundColor: colorHash.hex(user.id)}}
                    canAttach
                    onSubmit={this.handleMessage}
                />
            </div>
        );
    }
}
