import {action, makeAutoObservable} from 'mobx';
import * as Sentry from '@sentry/browser';
import type {GraphQLFormattedError} from 'graphql';
import {getName} from '../common/getName';
import {user} from '../stores/user';
import {IVerificationMethod, RegisterInput} from '../../graphql/api/user/User';
import {callLogin, callRegister, callVerify, callVerifyOwnership} from '../stores/userProxy';
import {formatAuthorizationData, IAuthenticationData} from '../stores/LoginStore';
import {
    EventUsersListItem,
    loadEventUserAccounts,
    loadInvitedGuests,
    loadUserAccountRegID,
    loadUserRegistrationStatusesForUser,
    registerForEvent_v2,
} from '../Event/eventsProxy';
import {IEvent} from '../Event/EventStore';
import {Country, Language} from '../../graphql/api/Types';
import {call, gql} from '../client';
import {EventRegistrationInput_v2} from '../../graphql/api/event/Event';
import {badgePdfEventRegistration} from '../FairParticipation/badgePdfProxy';

export interface IUserRegistrationEvent extends IEvent
{
    country: Country
    frNonFood?: boolean
    eventOnly?: boolean
    eventProfiles?: string[]
    dateStatusesForUser: {
        date: string | Date
        full: boolean
    }[]
    sideEventStatusesForUser: {
        reference: string
        full: boolean
    }[]
}

export interface ILoginInput
{
    method: IVerificationMethod | 'RegID'
    value: string
}

interface IRegisterInitial
{
    email?: string
    phone?: string
}

export const eventCountryRef: {current: Country} = {current: null};

export class EventRegistrationFormStore
{
    emailOPhone?: string;
    method?: IVerificationMethod | 'RegID';
    codeSent = false;
    registerInitial: IRegisterInitial = null;
    private authentication: IAuthenticationData;
    private authenticationToken: string;
    verificationWithoutAnAccount = false;
    private verificationWithoutAnAccountToken: string;
    verified = false;
    private isModeratorToken?: boolean;
    registrationId?: string;
    accounts: EventUsersListItem[] = null;
    invitedGuests: {[invitedBy: string]: EventUsersListItem[]} = {};
    selectedNewAccount = false;
    selectedAccount: EventUsersListItem = null;
    selectedGuestOf: EventUsersListItem = null;

    constructor(
        public readonly event: IUserRegistrationEvent
    )
    {
        makeAutoObservable(this);
        eventCountryRef.current = event.country;
    }

    get eventHasInvitationCode()
    {
        return this.event.sideEvents?.some(se => se.invitationCode != null);
    }

    changeLanguage(language: Language)
    {
        user.changeLandingLanguage(language);
        if (user.loggedIn)
        {
            user.changeLanguage(language);
        }
    }

    login({method, value}: ILoginInput): Promise<void | GraphQLFormattedError[]>
    {
        value = value.trim();

        if (method === 'RegID')
        {
            return this.loadUserAccountRegID(value).then(res =>
            {
                if (res)
                {
                    this.verified = true;
                    this.loadStatusesForUser();
                }
                else
                {
                    return [{message: 'invalid-id'}];
                }
            });
        }
        else if (this.registrationId)
        {
            this.registrationId = null;
        }

        this.emailOPhone = value;
        this.method = method;

        const isCurrentUser = user.loggedIn && (
            method === 'Email' ?
                user.loginMethod === 'Email' ? user.info.email === value : null
                :
                method === 'Sms' ?
                    user.loginMethod === 'Sms' ? user.info.phone === value : null
                    :
                    user.info.badges?.find(b => b.project == this.event.project)?.registrationId === value
        );

        if (isCurrentUser || user.isModeratorOrHostess)
        {
            this.verified = true;
            this.isModeratorToken = !isCurrentUser;

            if (isCurrentUser)
            {
                this.registerInitial = user.info;
            }
            else
            {
                if (method === 'Email')
                {
                    this.registerInitial = {email: value};
                }
                else if (method === 'Sms')
                {
                    this.registerInitial = {phone: value};
                }
            }

            this.loadUserAccounts().then(action(res =>
            {
                // if there wasn't an error, but there were no account with this email/phone
                if (res && !res.length)
                {
                    this.verificationWithoutAnAccount = true;
                }
            }));

            return Promise.resolve();
        }

        return callLogin({value, eventRegistration: true, eventProjectId: this.event.project, language: user.language}).then(action(({data, errors}) =>
        {
            if (errors)
            {
                return errors;
            }

            const login = data.login;

            if (login.personId) // normal login
            {
                this.authentication = login as IAuthenticationData;
                this.authenticationToken = formatAuthorizationData(login as IAuthenticationData);
            }
            else if (login.token) // verification only
            {
                this.verificationWithoutAnAccount = true;
                this.verificationWithoutAnAccountToken = login.token;
            }

            if (login.verified)
            {
                this.verified = true;
                if (!this.verificationWithoutAnAccount)
                {
                    this.loadUserAccounts();
                }
            }
            else
            {
                this.codeSent = true;
            }

            this.method = login.method;
            if (login.method === 'Email')
            {
                this.registerInitial = {email: value};
            }
            else // if (login.method === 'Sms')
            {
                this.registerInitial = {phone: value};
            }
        }));
    }

    async reSend()
    {
        return this.login({value: this.emailOPhone, method: this.method});
    }

    async submitCode(code: string): Promise<void | GraphQLFormattedError[]>
    {
        const errors = await (
            this.verificationWithoutAnAccount ?
                callVerifyOwnership(this.emailOPhone, this.verificationWithoutAnAccountToken.split(';')[0], code) :
                callVerify(this.authentication.identityId, this.authentication.token.split(';')[0], code)
        );
        if (!errors)
        {
            this.verified = true;
            if (!this.verificationWithoutAnAccount)
            {
                this.loadUserAccounts();
            }
        }
        else
        {
            return errors;
        }
    }

    selectAccount(account: EventUsersListItem, other?: {[key: string]: any})
    {
        this.selectedAccount = account;
        this.loadStatusesForUser();
        if (!user.loggedIn)
        {
            Sentry.setUser({
                id: account.id,
                email: account.email,
                phone: account.phone,
                username: getName(account),
                limitedAccess: 'eventRegistration',
                ...other,
            });
        }
    }

    selectGuestAccount(guestOfAccount: EventUsersListItem, account: EventUsersListItem, other?: {[key: string]: any})
    {
        this.selectedGuestOf = guestOfAccount;
        this.selectedAccount = account;
        this.loadStatusesForUser();
        if (!user.loggedIn)
        {
            Sentry.setUser({
                id: account.id,
                email: account.email,
                phone: account.phone,
                username: getName(account),
                limitedAccess: 'eventRegistration',
                ...other,
            });
        }
    }

    selectNewAccount()
    {
        this.selectedNewAccount = true;
    }

    selectNewGuestAccount(guestOfAccount: EventUsersListItem)
    {
        this.selectedGuestOf = guestOfAccount;
        this.selectedNewAccount = true;
    }

    unselectAccount()
    {
        this.selectedAccount = null;
        this.selectedGuestOf = null;
        this.selectedNewAccount = false;
        // reset statuses for user?
        if (!user.loggedIn)
        {
            Sentry.setUser(null);
        }
    }

    reset()
    {
        this.verified = false;
        this.codeSent = false;
        this.registrationId = null;
        this.unselectAccount();
    }

    private loadUserAccounts()
    {
        return loadEventUserAccounts(this.emailOPhone, this.authenticationToken).then(action(res =>
        {
            if (res)
            {
                // if we are org we might not have checked if user exists in advance
                if (res.length)
                {
                    this.accounts = res;
                }
            }
            return res;
        }));
    }

    loadInvitedGuests(accountWithGuests: EventUsersListItem)
    {
        loadInvitedGuests(this.event.project, accountWithGuests.id, this.registrationId, this.authenticationToken).then(action(res =>
        {
            this.invitedGuests[accountWithGuests.id] = res ?? [];
        }));
    }

    private loadUserAccountRegID(registrationId: string)
    {
        return loadUserAccountRegID(this.event.project, registrationId).then(action(res =>
        {
            if (res)
            {
                this.registrationId = registrationId;
                this.selectAccount(res);
                this.accounts = [this.selectedAccount];
            }
            return res;
        }));
    }

    loadStatusesForUser()
    {
        loadUserRegistrationStatusesForUser(this.event.project, this.selectedAccount.id, this.registrationId, this.authenticationToken).then(action(({errors, data}) =>
        {
            if (!errors && data.event)
            {
                this.event.dateStatusesForUser = data.event.dateStatusesForUser;
                this.event.sideEventStatusesForUser = data.event.sideEventStatusesForUser;
            }
        }));
    }

    register(accountInfo: RegisterInput)
    {
        return callRegister(
            this.verificationWithoutAnAccountToken ?
                {
                    ...accountInfo,
                    verificationToken: this.verificationWithoutAnAccountToken,
                }
                :
                accountInfo
        ).then(action(({errors, data}) =>
        {
            if (errors)
            {
                return errors[0].message;
            }
            if (this.verificationWithoutAnAccountToken)
            {
                this.authenticationToken = `${data.register.identityId};${data.register.personId};${this.verificationWithoutAnAccountToken}`;
                this.verificationWithoutAnAccountToken = null;
            }
            return this.loadUserAccounts().then(action(() =>
            {
                const newAccount = this.accounts.find(a => a.id === data.register.personId);
                if (!newAccount)
                {
                    return 'Account created, but then it is not found';
                }
                this.selectAccount(newAccount);
            }));
        }));
    }

    updateAccountInfo(accountInfo: Omit<UpdatePersonInfoInput, 'id'>)
    {
        const selectedAccount = this.selectedAccount;
        return updatePersonInfo({id: selectedAccount.id, ...accountInfo, registrationId: this.registrationId}, this.authenticationToken).then(action(res =>
        {
            if (res)
            {
                Object.assign(selectedAccount, accountInfo);
            }
            return res;
        }));
    }

    registerForEvent(input: Omit<EventRegistrationInput_v2, 'project' | 'id' | 'registrationId'>)
    {
        const selectedAccount = this.selectedAccount;
        return registerForEvent_v2({project: this.event.project, id: selectedAccount.id, registrationId: this.registrationId, ...input}, this.authenticationToken).then(action(res =>
        {
            if (res.data?.registerForEvent_v2)
            {
                selectedAccount.badges = res.data?.registerForEvent_v2.badges;
                return null;
            }
            else
            {
                return res.errors;
            }
        }));
    }

    downloadBadge()
    {
        return badgePdfEventRegistration(
            this.event.project,
            this.selectedAccount.id,
            this.selectedAccount.badges.find(b => b.project == this.event.project).registrationId,
            this.authenticationToken,
        );
    }
}

export interface UpdatePersonInfoInput
{
    registrationId?: string
    id: string
    firstName: string
    lastName: string
    function: string
}

function updatePersonInfo(input: UpdatePersonInfoInput, authorization?: string)
{
    return call<{updatePersonInfo: boolean}>(gql`mutation($input:UpdatePersonInfoInput!){updatePersonInfo(input:$input)}`, {input}, {authorization})
    .then(({data}) => data?.updatePersonInfo);
}
