import {Country, ITranslations} from '../../graphql/api/Types';

export interface IProfile
{
    id: string
    name: ITranslations
    alias?: ITranslations
    countries: Country[]
    storeType?: string
    storeTypes?: string[]
    icon?: string
    image?: string
    features: {[name: string]: {[name: string]: Country[]}}
}

export const profiles: IProfile[] = [{
    id: 'exhibitorMainAccount',
    name: {
        en: 'Main Exhibitor Administrator Account',
        it: 'Profilo Amministratore Espositore Principale',
        fr: 'Compte Administrateur Principal',
        nl: 'Hoofdbeheerdersaccount',
        pl: 'Wystawca - Główny administrator konta',
        es: 'Cuenta administrativa del expositor principal',
    },
    countries: ['BE', 'IT', 'PL', 'FR', 'ES'],
    features: {
        mobile: {
            agenda: ['BE', 'IT', 'PL', 'FR', 'ES'],
            news: ['BE', 'IT', 'PL', 'FR', 'ES'],
            map: ['BE', 'IT', 'PL', 'FR', 'ES'],
        },
    },
    icon: 'flag',
}, {
    id: 'exhibitorKeyAccount',
    name: {
        en: 'Exhibitor Administrator Account',
        it: 'Profilo Amministratore Espositore',
        fr: 'Exposant - Administateur du compte',
        nl: 'Exposant - beheerdersaccount',
        pl: 'Wystawca - administrator konta',
        es: 'Cuenta administrativa del expositor',
    },
    countries: ['BE', 'IT', 'PL', 'FR', 'ES'],
    features: {
        mobile: {
            agenda: ['BE', 'IT', 'PL', 'FR', 'ES'],
            news: ['BE', 'IT', 'PL', 'FR', 'ES'],
            map: ['BE', 'IT', 'PL', 'FR', 'ES'],
        },
    },
    image: 'https://yourfair-pub.s3-eu-west-1.amazonaws.com/assets/crown.png',
}, {
    id: 'exhibitorFinance',
    name: {
        en: 'Exhibitor Finance',
        it: 'Profilo Espositore settore amministrativo',
        fr: 'Exposant - Service Comptable',
        nl: 'Exposant - boekhouding',
        pl: 'Wystawca - księgowość',
        es: 'Contabilidad del expositor',
    },
    countries: ['BE', 'IT', 'PL', 'FR', 'ES'],
    features: {
        mobile: {
            agenda: ['BE', 'IT', 'PL', 'FR', 'ES'],
            news: ['BE', 'IT', 'PL', 'FR', 'ES'],
            map: ['BE', 'IT', 'PL', 'FR', 'ES'],
        },
    },
    icon: 'key',
}, {
    id: 'exhibitorInstallator',
    name: {
        en: 'Exhibitor Installator',
        it: 'Profilo agenzia creativa dell\'espositore',
        fr: 'Exposant - Constructeur de stand',
        nl: 'Exposant - standbouwer',
        pl: 'Wykonawca stoiska - agencja',
        es: 'Instalador del expositor',
    },
    countries: ['BE', 'IT', 'PL', 'FR', 'ES'],
    features: {
        mobile: {
            agenda: ['BE', 'IT', 'PL', 'FR', 'ES'],
            news: ['BE', 'IT', 'PL'],
            map: ['BE', 'IT', 'PL', 'FR', 'ES'],
        },
    },
    icon: 'wrench',
}, {
    id: 'exhibitorRep',
    name: {
        en: 'Exhibitor Representative',
        it: 'Espositore - Profilo Direttivo / Commerciale',
        fr: 'Exposant - Représentant commercial',
        nl: 'Exposant - vertegenwoordiger',
        pl: 'Wystawca - handlowiec/sprzedaż',
        es: 'Representante del expositor',
    },
    countries: ['BE', 'IT', 'PL', 'FR', 'ES'],
    features: {
        mobile: {
            agenda: ['BE', 'IT', 'PL', 'FR', 'ES'],
            news: ['BE', 'IT', 'PL', 'FR', 'ES'],
            map: ['BE', 'IT', 'PL', 'FR', 'ES'],
        },
    },
    icon: 'user-tie',
}, {
    id: 'express',
    name: {
        en: 'Express',
    },
    countries: ['ES'],
    storeType: 'E',
    features: {
        mobile: {
            store: ['ES'],
            orderPromotion: [],
            orderFair: [],
            agenda: [],
            news: [],
            catalog: [],
            map: [],
        },
    },
}, {
    id: 'expressConvenience',
    name: {
        en: 'Express Convenience',
        pl: 'Express Convenience',
    },
    countries: ['PL'],
    storeType: 'EC',
    features: {
        mobile: {
            store: ['PL'],
            orderPromotion: [],
            orderFair: ['PL'],
            agenda: ['PL'],
            news: ['PL'],
            catalog: ['PL'],
            map: ['PL'],
        },
    },
}, {
    id: 'expressFranchising',
    name: {
        en: 'Express Franchising',
        nl: 'Express Franchising',
        fr: 'Express Franchisé',
        it: 'Express Franchise',
        es: 'Express Franquiciado',
    },
    countries: ['BE', 'IT'],
    storeTypes: ['E', 'EF'],
    features: {
        mobile: {
            store: ['BE', 'IT'],
            orderPromotion: ['BE'],
            orderFair: ['BE', 'IT'],
            agenda: ['BE', 'IT'],
            news: ['BE', 'IT'],
            catalog: ['BE', 'IT'],
            map: ['BE', 'IT'],
        },
    },
}, {
    id: 'expressIntegrated',
    name: {
        en: 'Express Integrated',
        nl: 'Express Geïntegreerd',
        fr: 'Express Intégré',
        it: 'Express Diretti',
        es: 'Express Integrado',
    },
    countries: ['IT'],
    storeTypes: ['ED', 'EI'],
    features: {
        mobile: {
            store: ['IT'],
            orderPromotion: [],
            orderFair: [],
            agenda: ['IT'],
            news: ['IT'],
            catalog: [],
            map: ['IT'],
        },
    },
}, {
    id: 'expressMinimarket',
    name: {
        en: 'Express-minimarket',
        pl: 'Express-minimarket',
    },
    countries: ['PL'],
    storeType: 'EM',
    features: {
        mobile: {
            store: ['PL'],
            orderPromotion: [],
            orderFair: ['PL'],
            agenda: ['PL'],
            news: ['PL'],
            catalog: ['PL'],
            map: ['PL'],
        },
    },
}, {
    id: 'globi',
    name: {
        en: 'Globi',
        pl: 'Globi',
    },
    countries: ['PL'],
    storeType: 'G',
    features: {
        mobile: {
            store: ['PL'],
            orderPromotion: [],
            orderFair: ['PL'],
            agenda: ['PL'],
            news: ['PL'],
            catalog: ['PL'],
            map: ['PL'],
        },
    },
}, {
    id: 'guest',
    name: {
        en: 'Guest',
        it: 'Ospite',
        nl: 'Gast',
        fr: 'Invité',
        pl: 'Gość',
        es: 'Invitado',
    },
    icon: 'user',
    countries: ['BE', 'IT', 'PL', 'FR', 'ES'],
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: ['BE', 'IT', 'PL', 'FR', 'ES'],
            news: ['BE', 'IT', 'PL', 'FR', 'ES'],
            catalog: [],
            map: ['BE', 'IT', 'PL', 'FR', 'ES'],
        },
    },
}, {
    id: 'media',
    name: {
        en: 'Media',
        nl: 'Pers',
        fr: 'La presse',
        es: 'Prensa',
    },
    countries: ['BE', 'IT', 'PL', 'FR', 'ES'],
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: ['BE', 'IT', 'PL', 'FR', 'ES'],
            news: ['BE', 'IT', 'PL', 'FR', 'ES'],
            catalog: [],
            map: ['BE', 'IT', 'PL', 'FR', 'ES'],
        },
    },
}, {
    id: 'headquarter',
    name: {
        en: 'Carrefour Service Center',
        nl: 'Carrefour Service Center',
        fr: 'Carrefour Service Center',
        pl: 'Carrefour Service Center',
        it: 'Carrefour Servizi Centrali',
        es: 'Carrefour Servicios Centrales',
    },
    alias: {
        en: 'Headquarters',
        it: 'Sede',
        nl: 'Hoofdzetel',
        fr: 'Siège social',
        pl: 'Centrala',
        es: 'Oficinas Centrales',
    },
    icon: 'building',
    countries: ['BE', 'IT', 'PL', 'FR', 'ES'],
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: ['BE', 'IT', 'PL', 'FR', 'ES'],
            news: ['BE', 'IT', 'PL', 'FR', 'ES'],
            catalog: [],
            map: ['BE', 'IT', 'PL', 'FR', 'ES'],
        },
    },
}, {
    id: 'carrefourGroup',
    name: {
        en: 'Carrefour Group',
    },
    countries: ['BE', 'IT', 'PL', 'FR', 'ES'],
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: ['BE', 'IT', 'PL', 'FR', 'ES'],
            news: ['BE', 'IT', 'PL', 'FR', 'ES'],
            catalog: [],
            map: ['BE', 'IT', 'PL', 'FR', 'ES'],
        },
    },
}, {
    id: 'hypermarket',
    name: {
        en: 'Hypermarket',
        nl: 'Hypermarkt',
        fr: 'Hypermarché',
        it: 'Ipermercati',
        pl: 'Hipermarket',
        es: 'Hipermercado',
    },
    countries: ['BE', 'IT', 'PL', 'ES'],
    storeType: 'H',
    features: {
        mobile: {
            store: ['BE', 'IT', 'PL', 'ES'],
            orderPromotion: ['BE'],
            orderFair: ['BE', 'PL'],
            agenda: ['BE', 'IT', 'PL'],
            news: ['BE', 'IT', 'PL'],
            catalog: ['BE', 'PL'],
            map: ['BE', 'IT', 'PL'],
        },
    },
}, {
    id: 'hypermarketFranchising',
    name: {
        en: 'Hypermarket Franchising',
        fr: 'Hypermarché Franchisé',
    },
    countries: ['FR'],
    storeType: 'HF',
    features: {
        mobile: {
            store: ['FR'],
            orderPromotion: [],
            orderFair: ['FR'],
            agenda: ['FR'],
            news: ['FR'],
            catalog: ['FR'],
            map: ['FR'],
        },
    },
}, {
    id: 'hypermarketIntegrated',
    name: {
        en: 'Hypermarket Integrated',
        fr: 'Hypermarché Intégré',
    },
    countries: ['FR'],
    storeTypes: ['H', 'FI'],
    features: {
        mobile: {
            store: ['FR'],
            orderPromotion: [],
            orderFair: ['FR'],
            agenda: ['FR'],
            news: ['FR'],
            catalog: ['FR'],
            map: ['FR'],
        },
    },
}, {
    id: 'market',
    name: {
        en: 'Market',
    },
    countries: ['ES'],
    storeType: 'M',
    features: {
        mobile: {
            store: ['ES'],
            orderPromotion: [],
            orderFair: [],
            agenda: [],
            news: [],
            catalog: [],
            map: [],
        },
    },
}, {
    id: 'marketFranchising',
    name: {
        en: 'Market Franchising',
        nl: 'Market Franchising',
        fr: 'Market Franchisé',
        it: 'Market Franchise',
        es: 'Market Franquiciado',
    },
    countries: ['BE', 'IT', 'FR'],
    storeType: 'MF',
    features: {
        mobile: {
            store: ['BE', 'IT', 'FR'],
            orderPromotion: ['BE', 'FR'],
            orderFair: ['BE', 'IT', 'FR'],
            agenda: ['BE', 'IT', 'FR'],
            news: ['BE', 'IT', 'FR'],
            catalog: ['BE', 'IT', 'FR'],
            map: ['BE', 'IT', 'FR'],
        },
    },
}, {
    id: 'internationalPartnership',
    name: {
        en: 'International Partnership',
        fr: 'Partenariat International',
    },
    countries: ['FR'],
    storeType: 'CPI',
    features: {
        mobile: {
            store: ['FR'],
            orderPromotion: ['FR'],
            orderFair: ['FR'],
            agenda: ['FR'],
            news: ['FR'],
            catalog: ['FR'],
            map: ['FR'],
        },
    },
}, {
    id: 'marketIntegrated',
    name: {
        en: 'Market Integrated',
        nl: 'Market Geïntegreerd',
        fr: 'Market Intégré',
        it: 'Market Diretti',
        es: 'Market Integrado',
    },
    countries: ['BE', 'IT', 'FR'],
    storeType: 'MI',
    features: {
        mobile: {
            store: ['BE', 'IT', 'FR'],
            orderPromotion: ['BE', 'FR'],
            orderFair: ['BE', 'FR'],
            agenda: ['BE', 'IT', 'FR'],
            news: ['BE', 'IT', 'FR'],
            catalog: ['BE', 'FR'],
            map: ['BE', 'IT', 'FR'],
        },
    },
}, {
    id: 'organization',
    name: {
        en: 'Organization',
        es: 'Organización',
    },
    countries: ['BE', 'IT', 'PL', 'FR', 'ES'],
    features: {
        mobile: {
            agenda: ['BE', 'IT', 'PL', 'FR', 'ES'],
            news: ['BE', 'IT', 'PL', 'FR', 'ES'],
            catalog: ['BE', 'IT', 'PL', 'FR', 'ES'],
            map: ['BE', 'IT', 'PL', 'FR', 'ES'],
        },
    },
}, {
    id: 'supermarket',
    name: {
        en: 'Supermarket',
        pl: 'Supermarket',
    },
    countries: ['PL'],
    storeType: 'S',
    features: {
        mobile: {
            store: ['PL'],
            orderPromotion: [],
            orderFair: ['PL'],
            agenda: ['PL'],
            news: ['PL'],
            catalog: ['PL'],
            map: ['PL'],
        },
    },
}, {
    id: 'cashCarry',
    name: {
        en: 'Cash Carry',
        it: 'Cash Carry',
    },
    countries: ['IT'],
    storeType: 'CC',
    features: {
        mobile: {
            store: ['IT'],
            orderPromotion: [],
            orderFair: [],
            agenda: ['IT'],
            news: ['IT'],
            catalog: [],
            map: ['IT'],
        },
    },
}, {
    id: 'etruria',
    name: {
        en: 'Etruria',
        it: 'Etruria',
    },
    countries: ['IT'],
    storeType: 'MAF',
    features: {
        mobile: {
            store: ['IT'],
            orderPromotion: [],
            orderFair: [],
            agenda: ['IT'],
            news: ['IT'],
            catalog: [],
            map: ['IT'],
        },
    },
}, {
    id: 'apulia',
    name: {
        en: 'Apulia',
        it: 'Apulia',
    },
    countries: ['IT'],
    storeType: 'MAF',
    features: {
        mobile: {
            store: ['IT'],
            orderPromotion: [],
            orderFair: [],
            agenda: ['IT'],
            news: ['IT'],
            catalog: [],
            map: ['IT'],
        },
    },
}, {
    // removed
    id: 'supermarketFranchised',
    name: {
        en: 'Supermarket Franchising',
        fr: 'Supermarché Franchisé',
    },
    countries: [],
    storeType: 'S',
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: [],
            news: [],
            catalog: [],
            map: [],
        },
    },
}, {
    // removed
    id: 'supermarketIntegrated',
    name: {
        en: 'Supermarket Integrated',
        fr: 'Supermarché Intégré',
    },
    countries: [],
    storeType: 'S',
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: [],
            news: [],
            catalog: [],
            map: [],
        },
    },
}, {
    id: 'proximity',
    name: {
        en: 'Proximity',
        fr: 'Proximité',
    },
    countries: ['FR'],
    storeType: 'PX',
    features: {
        mobile: {
            store: ['FR'],
            orderPromotion: [],
            orderFair: ['FR'],
            agenda: ['FR'],
            news: ['FR'],
            catalog: ['FR'],
            map: ['FR'],
        },
    },
}, {
    // removed
    id: 'proximityFranchising',
    name: {
        en: 'Proximity Franchising',
        fr: 'Proximité Franchisé',
    },
    countries: [],
    storeType: 'PX',
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: [],
            news: [],
            catalog: [],
            map: [],
        },
    },
}, {
    // removed
    id: 'proximityIntegrated',
    name: {
        en: 'Proximity Integrated',
        fr: 'Proximité Intégré',
    },
    countries: [],
    storeType: 'PX',
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: [],
            news: [],
            catalog: [],
            map: [],
        },
    },
}, {
    id: 'hostess',
    name: {
        en: 'Hostess',
        fr: 'Hôtesse',
        nl: 'Hostesse',
        pl: 'Hostessa',
        it: 'Hostess',
        es: 'Montadores y Azafatos/as',
    },
    countries: ['BE', 'FR', 'IT', 'PL', 'ES'],
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: ['BE', 'FR', 'IT', 'PL', 'ES'],
            news: ['BE', 'FR', 'IT', 'PL', 'ES'],
            catalog: ['BE', 'FR', 'IT', 'PL', 'ES'],
            map: ['BE', 'FR', 'IT', 'PL', 'ES'],
        },
    },
}, {
    id: 'supeco',
    name: {
        en: 'Supeco',
        es: 'Supeco',
    },
    countries: ['ES', 'PL'],
    storeType: 'SE',
    features: {
        mobile: {
            store: ['ES', 'PL'],
            orderPromotion: [],
            orderFair: ['PL'],
            agenda: ['PL'],
            news: ['PL'],
            catalog: ['PL'],
            map: ['PL'],
        },
    },
}, {
    id: 'demonstrator',
    name: {
        en: 'Demonstrator',
        fr: 'Démonstrateur',
        nl: 'Demonstrateur',
        it: 'Operatore Animazione',
    },
    countries: ['BE', 'IT', 'PL'],
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: [],
            news: [],
            catalog: [],
            map: [],
        },
    },
}, {
    id: 'activeSupplier',
    name: {
        en: 'Active Supplier',
        fr: 'Fournisseur Actif',
        nl: 'Actieve Leverancier',
    },
    countries: ['BE'],
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: [],
            news: [],
            catalog: [],
            map: [],
        },
    },
}, {
    id: 'externalSuppliersCarrefour',
    name: {
        en: 'External suppliers Carrefour',
        es: 'Animaciones',
    },
    countries: ['ES'],
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: [],
            news: [],
            catalog: [],
            map: [],
        },
    },
}, {
    id: 'maintenanceFournisseur',
    name: {
        en: 'Maintenance Fournisseur',
    },
    icon: 'user',
    countries: ['BE'],
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: ['BE'],
            news: ['BE'],
            catalog: [],
            map: ['BE'],
        },
    },
}, {
    id: 'maintenanceTechnician',
    name: {
        en: 'Maintenance Technician',
        it: 'Assistenza Tecnica',
    },
    countries: ['IT'],
    features: {
        mobile: {
            store: [],
            orderPromotion: [],
            orderFair: [],
            agenda: [],
            news: [],
            catalog: [],
            map: [],
        },
    },
}, {
    id: 'carrefourBio',
    name: {
        en: 'Carrefour Bio',
        pl: 'Carrefour Bio',
    },
    countries: ['PL'],
    storeType: 'CB',
    features: {
        mobile: {
            store: ['PL'],
            orderPromotion: [],
            orderFair: ['PL'],
            agenda: ['PL'],
            news: ['PL'],
            catalog: ['PL'],
            map: ['PL'],
        },
    },
}];

export const findProfile = (id: string) => profiles.find(p => p.id == id);
