import React, {useState} from 'react';
import type {PageItemType} from '../../graphql/api/page/Page';
import type {IItemOptions} from './Page';
import {pageConfig} from './pageConfig';

const {width, height, padding} = pageConfig;

interface DroppableElementProps
{
    type: PageItemType
    name: string
    isResizable?: boolean
    w: number
    h: number
    onDragStart(e: React.DragEvent)
    onDragEnd?(e: React.DragEvent)
}

export default function DroppableElement({type, name, isResizable, w, h, onDragStart, onDragEnd}: DroppableElementProps)
{
    const [size, setSize] = useState({w, h});
    const {w: mw, h: mh} = size;

    return (
        <div
            className='DroppableElement'
            draggable
            unselectable='on'
            onDragStart={e =>
            {
                e.dataTransfer.setData('text/x-item', JSON.stringify({
                    type,
                    isResizable: !!isResizable,
                    size: mw || mh ? {w: mw, h: mh} : null,
                } as IItemOptions));
                onDragStart(e);
            }}
            onDragEnd={onDragEnd}
        >
            <p className='font-weight-bold mb-2'>{name}</p>
            {isResizable ?
                <div>
                    <SizeSelect sizeOptions={[2, 3, 4, 5, 6, 8]} selected={mw} size={width} onSelect={sw => setSize({w: sw, h: mh})}/>
                    {' x '}
                    <SizeSelect sizeOptions={[1, 2, 3, 4, 5, 6, 7, 8]} selected={mh} size={height} onSelect={sh => setSize({w: mw, h: sh})}/>
                </div>
                :
                <div className='text-secondary'>
                    {sizeFromUnits(mw, width) + ' x ' + sizeFromUnits(mh, height)}
                </div>
            }
        </div>
    )
}

interface SizeSelectProps
{
    sizeOptions: number[]
    selected: number
    size: number
    onSelect(value: number): void
}

function SizeSelect({sizeOptions, selected, size, onSelect}: SizeSelectProps)
{
    return (
        <select value={selected} onChange={e => onSelect(+e.currentTarget.value)}>
            {sizeOptions.map(c =>
                <option key={c} value={c}>{sizeFromUnits(c, size)}</option>
            )}
        </select>
    );
}

function sizeFromUnits(units: number, size: number)
{
    return Math.round(units * size + (units - 1) * padding);
}

export function pageWidthSizeFromUnits(units: number)
{
    return sizeFromUnits(units, width);
}

export function pageHeightSizeFromUnits(units: number)
{
    return sizeFromUnits(units, height);
}
