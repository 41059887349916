// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlockOptions {
  background-color: #fff;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  border-radius: 3px;
  box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.55);
  width: max-content;
  max-width: 820px;
  padding: 5px;
}
.BlockOptions > .options {
  display: flex;
  justify-content: space-between;
}
.BlockOptions > .options > * {
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.BlockOptions > .access {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
.BlockOptions > .access .setting-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0.5rem;
}
.BlockOptions > .access .setting-toggle .fa {
  font-size: 2rem;
  margin-right: 0.5rem;
  color: #c0c0c0;
}
.BlockOptions > .access-options {
  margin: 10px;
}
.BlockOptions > .bottom-padding {
  position: absolute;
  top: 100%;
  width: 1px;
  height: 3rem;
}`, "",{"version":3,"sources":["webpack://./frontend/Pages/BlockOptions.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,kBAAA;EACA,sBAAA;EACA,OAAA;EACA,kBAAA;EACA,6CAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;AAEJ;AADI;EACE,YAAA;EACA,aAAA;EACA,sBAAA;AAGN;AAAE;EACE,aAAA;EACA,8BAAA;EACA,YAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;AAGN;AAFM;EACE,eAAA;EACA,oBAAA;EACA,cAAA;AAIR;AAAE;EACE,YAAA;AAEJ;AAAE;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;AAEJ","sourcesContent":[".BlockOptions {\n  background-color: #fff;\n  position: absolute;\n  top: calc(100% + 10px);\n  left: 0;\n  border-radius: 3px;\n  box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.55);\n  width: max-content;\n  max-width: 820px;\n  padding: 5px;\n  > .options {\n    display: flex;\n    justify-content: space-between;\n    > * {\n      margin: 10px;\n      display: flex;\n      flex-direction: column;\n    }\n  }\n  > .access {\n    display: flex;\n    justify-content: space-between;\n    margin: 10px;\n    .setting-toggle {\n      display: flex;\n      align-items: center;\n      cursor: pointer;\n      user-select: none;\n      margin-bottom: .5rem;\n      .fa {\n        font-size: 2rem;\n        margin-right: .5rem;\n        color: #c0c0c0;\n      }\n    }\n  }\n  > .access-options {\n    margin: 10px;\n  }\n  > .bottom-padding {\n    position: absolute;\n    top: 100%;\n    width: 1px;\n    height: 3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
