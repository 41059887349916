import {Country, ITranslations} from '../../graphql/api/Types';

export type IBusinessUnit = 'market' | 'express' | 'hyper' | 'proxi' | 'cpi' | 'supeco'

export const businessUnits = ['market', 'express', 'hyper', 'proxi', 'cpi', 'supeco'];

interface IStoreTypeInfo
{
    id: string
    bu?: IBusinessUnit
    name: ITranslations
}

export const storeTypes: {[C in Country]: IStoreTypeInfo[]} = {
    BE: [
        {
            id: 'MF',
            bu: 'market',
            name: {
                en: 'Market Franchising',
                nl: 'Market Franchising',
                fr: 'Market Franchisé',
            },
        },
        {
            id: 'E',
            bu: 'express',
            name: {
                en: 'Express Franchising',
                nl: 'Express Franchising',
                fr: 'Express Franchisé',
            },
        },
        {
            id: 'MI',
            bu: 'market',
            name: {
                en: 'Market Integrated',
                nl: 'Market Geïntegreerd',
                fr: 'Market Intégré',
            },
        },
        {
            id: 'H',
            bu: 'hyper',
            name: {
                en: 'Hypermarket',
                nl: 'Hypermarkt',
                fr: 'Hypermarché',
            },
        },
    ],
    PL: [
        {
            id: 'EC',
            bu: 'express',
            name: {
                pl: 'Express Convenience',
            },
        },
        {
            id: 'EM',
            bu: 'express',
            name: {
                pl: 'Express-minimarket',
            },
        },
        {
            id: 'G',
            name: {
                pl: 'Globi',
            },
        },
        {
            id: 'H',
            bu: 'hyper',
            name: {
                pl: 'Hipermarket',
            },
        },
        {
            id: 'S',
            bu: 'hyper',
            name: {
                pl: 'Supermarket',
            },
        },
        {
            id: 'CB',
            bu: 'hyper',
            name: {
                pl: 'Carrefour Bio',
            },
        },
        {
            id: 'SE',
            bu: 'supeco',
            name: {
                pl: 'Supeco',
            }
        },
    ],
    IT: [
        {
            id: 'MF',
            bu: 'market',
            name: {
                it: 'Market Franchise',
            },
        },
        {
            id: 'E',
            bu: 'express',
            name: {
                it: 'Express Franchise',
            },
        },
        {
            id: 'MD',
            bu: 'market',
            name: {
                it: 'Market Diretti',
            },
        },
        {
            id: 'ED',
            bu: 'express',
            name: {
                it: 'Express Diretti',
            },
        },
        {
            id: 'H',
            bu: 'hyper',
            name: {
                it: 'Ipermercati',
            },
        },
        {
            id: 'CC',
            name: {
                it: 'Cash Carry',
            },
        },
        {
            id: 'MAF',
            name: {
                it: 'Master Franchise',
            },
        }
    ],
    FR: [
        {
            id: 'MF',
            bu: 'market',
            name: {
                fr: 'Market Franchisé',
            },
        },
        {
            id: 'CPI',
            bu: 'cpi',
            name: {
                fr: 'Carrefour Partenariat International',
            },
        },
        {
            id: 'MI',
            bu: 'market',
            name: {
                fr: 'Market Intégré',
            },
        },
        {
            id: 'H',
            bu: 'hyper',
            name: {
                fr: 'Hypermarché'
            },
        },
        {
            id: 'HF',
            bu: 'hyper',
            name: {
                fr: 'Hypermarché Franchisé'
            },
        },
        {
            id: 'PRX',
            bu: 'proxi',
            name: {
                fr: 'PROXI'
            },
        },
        {
            id: 'CEX',
            bu: 'proxi',
            name: {
                fr: 'CARREFOUR EXPRESS'
            },
        },
        {
            id: 'CTC',
            bu: 'proxi',
            name: {
                fr: 'CARREFOUR CONTACT'
            },
        },
        {
            id: 'CIT',
            bu: 'proxi',
            name: {
                fr: 'CARREFOUR CITY'
            },
        },
        {
            id: 'CMO',
            bu: 'proxi',
            name: {
                fr: 'CARREFOUR MONTAGNE'
            },
        },
        {
            id: 'PGI',
            bu: 'proxi',
            name: {
                fr: 'NEGOCE'
            },
        },
        {
            id: 'HUI',
            bu: 'proxi',
            name: {
                fr: '8 A HUIT'
            },
        },
        {
            id: 'COE',
            bu: 'proxi',
            name: {
                fr: 'CORNER EXPRESS'
            },
        },
        {
            id: 'PSU',
            bu: 'proxi',
            name: {
                fr: 'PROXI SUPER'
            },
        },
        {
            id: 'BIO',
            bu: 'proxi',
            name: {
                fr: 'BIO'
            },
        },
        {
            id: 'CRA',
            bu: 'proxi',
            name: {
                fr: 'CORNER AUTOROUTE'
            },
        },
        {
            id: 'GRD',
            bu: 'proxi',
            name: {
                fr: 'GROSSISTE'
            },
        },
        {
            id: 'GDI',
            bu: 'proxi',
            name: {
                fr: 'GRANDS INDEPENDANTS'
            },
        },
        {
            id: 'STC',
            bu: 'proxi',
            name: {
                fr: 'STATION CRF'
            },
        },
        {
            id: 'PTI',
            bu: 'proxi',
            name: {
                fr: 'PETIT NEGOCE'
            },
        },
        {
            id: 'BAP',
            bu: 'proxi',
            name: {
                fr: 'BON APP'
            },
        },
        {
            id: 'AUT',
            bu: 'proxi',
            name: {
                fr: 'AUTRES SPM'
            },
        },
        {
            id: 'CDP',
            bu: 'proxi',
            name: {
                fr: 'CARREFOUR D. PIETON'
            },
        },
        {
            id: 'BOB',
            bu: 'proxi',
            name: {
                fr: 'BON APPETIT'
            },
        },
        {
            id: 'SOU',
            bu: 'proxi',
            name: {
                fr: 'SOURCES'
            },
        },
    ],
    ES: [
        {
            id: 'M',
            bu: 'market',
            name: {
                es: 'Market',
            }
        },
        {
            id: 'E',
            bu: 'express',
            name: {
                es: 'Express',
            }
        },
        {
            id: 'H',
            bu: 'hyper',
            name: {
                es: 'Hipermercado',
            }
        },
        {
            id: 'SE',
            bu: 'supeco',
            name: {
                es: 'Supeco',
            }
        },
    ],
};

export function findStoreTypeName(country: Country, id: string)
{
    return id ? storeTypes[country]?.find(s => s.id == id)?.name : undefined;
}

export function findStoreTypeBU(country: Country, id: string)
{
    return id ? storeTypes[country]?.find(s => s.id == id)?.bu : undefined;
}
