import React, {ComponentProps, useState} from 'react';
import DropdownSearch from '../common/DropdownSearch';
import {countryStore} from '../stores/CountryStore';
import {Language} from '../../graphql/api/Types';
import {pickLanguage} from '../stores/utility';
import {user} from '../stores/user';

const countryListLoader: {[lang in Language]?: () => Promise<{[key: string]: string}>} = {
    en: () => import('react-phone-number-input/locale/en.json').then(v => v.default),
    fr: () => import('react-phone-number-input/locale/fr.json').then(v => v.default),
    it: () => import('react-phone-number-input/locale/it.json').then(v => v.default),
    pl: () => import('react-phone-number-input/locale/pl.json').then(v => v.default),
    es: () => import('react-phone-number-input/locale/es.json').then(v => v.default),
};

export default function CompanyCountryDropdown(props: Omit<ComponentProps<typeof DropdownSearch>, 'options'>)
{
    const [loadedLanguage, setLoadedLanguage] = useState<Language>();
    const [allCountries, setAllCountries] = useState<ComponentProps<typeof DropdownSearch>['options']>();
    return (
        <DropdownSearch
            {...props}
            options={allCountries ?
                [...countryStore.countryOptions, ...allCountries.filter(c => !countryStore.countryCodes.includes(c.value as any))] :
                countryStore.countryOptions
            }
            onFocus={() =>
            {
                if (loadedLanguage != user.language)
                {
                    setLoadedLanguage(user.language);
                    pickLanguage(countryListLoader)().then(obj =>
                    {
                        const arr = Object.entries(obj);
                        arr.splice(0, 3); // Remove first 3 items which are not countries
                        arr.pop(); // Remove international items
                        setAllCountries(arr.map(c => ({value: c[0], name: c[1]})));
                    });
                }
            }}
        />
    );
}
