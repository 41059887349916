import {call, gql} from '../client';
import {registerGlobalEventHandler} from '../stores/globalEvents';

export interface ISearchInfo
{
    id: string
    key: string
}

function searchKey()
{
    return call<{searchInfo: ISearchInfo}>(gql`{searchInfo{id,key}}`).then(({data}) => data?.searchInfo);
}

class AlgoliaInfoStore
{
    info: ISearchInfo;

    constructor()
    {
        registerGlobalEventHandler('logout', () =>
        {
            this.info = null;
        });
    }

    load()
    {
        return searchKey().then(info =>
        {
            if (info)
            {
                this.info = info;
            }
            return info;
        });
    }
}

export const algoliaInfoStore = new AlgoliaInfoStore();
