import {call, gql} from '../client';
import {IPackage} from './PackageStore';
import {IPackageContext} from '../../graphql/api/package/Package';

export function loadPackages(project: string)
{
    return call<{packages: IPackage[]}>(
        gql`query($project:ID!){packages(project:$project){id,project,type,parentId,image,name,title,description,fixedPrice,discount,products{id,title,useDiscounts,hide,surfaces,walls,references,quantity},companyAccess,sectorAccess,sectorAccessGroups,templatePageIds,cornerHeadOpenOnly}}`,
        {project})
    .then(({data}) => data?.packages);
}

export function loadPackage(packageId: string)
{
    return call<{singlePackage: IPackage}>(
        gql`query($packageId:ID!){singlePackage(packageId:$packageId){id,project,type,parentId,image,name,title,description,fixedPrice,discount,products{id,title,useDiscounts,hide,surfaces,walls,references,quantity},companyAccess,sectorAccess,sectorAccessGroups,templatePageIds,cornerHeadOpenOnly}}`,
        {packageId})
    .then(({data}) => data?.singlePackage);
}

export function callCreatePackage(pkg: IPackageContext)
{
    return call<{createPackage: IPackage}>(
        gql`mutation ($input:PackageCreateInput!){createPackage(input:$input){id,project,type,parentId,image,name,title,description,fixedPrice,discount,products{id,title,useDiscounts,hide,surfaces,walls,references,quantity},companyAccess,sectorAccess,sectorAccessGroups,templatePageIds,cornerHeadOpenOnly}}`,
        {input: pkg})
    .then(({data}) => data?.createPackage);
}

export function callUpdatePackage(pkg: IPackage)
{
    return call<{updatePackage: IPackage}>(
        gql`mutation ($input:PackageUpdateInput!){updatePackage(input:$input){id,project,type,parentId,image,name,title,description,fixedPrice,discount,products{id,title,useDiscounts,hide,surfaces,walls,references,quantity},companyAccess,sectorAccess,sectorAccessGroups,templatePageIds,cornerHeadOpenOnly}}`,
        {input: pkg})
    .then(({data}) => data?.updatePackage);
}

export function callCopyPackage(id: string, project: string)
{
    return call<{copyPackage: IPackage}>(
        gql`mutation ($input:PackageCopyInput!){copyPackage(input:$input){id,project,type,parentId,image,name,title,description,fixedPrice,discount,products{id,title,useDiscounts,hide,surfaces,walls,references,quantity},companyAccess,sectorAccess,sectorAccessGroups,templatePageIds,cornerHeadOpenOnly}}`,
        {input: {id, project}})
    .then(({data}) => data?.copyPackage);
}

export function callDeletePackage(id: string)
{
    return call(gql`mutation ($id:ID!){deletePackage(id:$id)}`, {id});
}
