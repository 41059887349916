import {makeObservable, observable} from 'mobx';

type MessageListener = (this: WsConnection, data) => void;
type OpenListener = (this: WsConnection, connection: WsConnection, isReopen: boolean) => void;

const isLocalhost = location.hostname === 'localhost' || location.hostname === '127.0.0.1';

class WsConnection
{
    private ws: WebSocket;
    private openListeners = new Set<OpenListener>();
    private messageListeners = new Set<MessageListener>();
    private keepAliveLoop;
    @observable hasBeenOpen = false;
    @observable open = false;

    constructor(uri?: string)
    {
        makeObservable(this);
        if (uri)
        {
            this.connect(uri);
        }
    }

    connect(uri: string)
    {
        if (this.ws)
        {
            this.close();
        }
        this.ws = new WebSocket(uri);
        this.ws.onopen = () =>
        {
            const isReopen = this.hasBeenOpen;
            this.hasBeenOpen = true;
            this.open = true;
            // console.log('WebSocket open', new Date().toISOString());
            this.resetKeepAlive();
            for (const listener of this.openListeners)
            {
                try
                {
                    listener.call(this, this, isReopen);
                }
                catch (e)
                {
                    console.error(e);
                }
            }
        };
        this.ws.onerror = (error) =>
        {
            if (!isLocalhost || this.hasBeenOpen)
            {
                console.error('WebSocket error', error);
            }
        };
        this.ws.onclose = (evt) =>
        {
            this.open = false;
            if (!isLocalhost || this.hasBeenOpen)
            {
                console.log('WebSocket close', evt.code, new Date().toISOString());
            }
            this.ws = null;
            this.stopKeepAlive();
            if (evt.code != 1000 && navigator.onLine && (!isLocalhost || this.hasBeenOpen))
            {
                // a timeout to allow for the tab to close if ws was closed due to tab being closed
                setTimeout(() =>
                {
                    this.connect(uri);
                }, 200);
            }
        }
        this.ws.onmessage = (message) =>
        {
            // console.log('WebSocket message', message.data);
            const data = JSON.parse(message.data);
            for (const listener of this.messageListeners)
            {
                if (Array.isArray(data))
                {
                    for (const msg of data)
                    {
                        try
                        {
                            listener.call(this, msg);
                        }
                        catch (e)
                        {
                            console.error(e);
                        }
                    }
                }
                else
                {
                    try
                    {
                        listener.call(this, data);
                    }
                    catch (e)
                    {
                        console.error(e);
                    }
                }
            }
            this.resetKeepAlive();
        };
    }

    addOpenListener(listener: OpenListener)
    {
        if (typeof listener != 'function')
        {
            throw new TypeError('Listener must be a function');
        }
        this.openListeners.add(listener);
    }

    removeOpenListener(listener: OpenListener)
    {
        this.openListeners.delete(listener);
    }

    addMessageListener(listener: MessageListener)
    {
        if (typeof listener != 'function')
        {
            throw new TypeError('Listener must be a function');
        }
        this.messageListeners.add(listener);
    }

    removeMessageListener(listener: MessageListener)
    {
        this.messageListeners.delete(listener);
    }

    close()
    {
        if (this.ws)
        {
            this.ws.close(1000);
            this.ws = null;
        }
    }

    json(data)
    {
        if (this.ws)
        {
            this.ws.send(JSON.stringify(data));
            this.resetKeepAlive();
        }
    }

    resetKeepAlive()
    {
        this.stopKeepAlive();
        this.keepAliveLoop = setInterval(() =>
        {
            // console.log('WebSocket keep alive', new Date().toISOString());
            this.ws.send('');
        }, 9 * 60000);
    }

    stopKeepAlive()
    {
        if (this.keepAliveLoop)
        {
            clearInterval(this.keepAliveLoop);
            this.keepAliveLoop = null;
        }
    }
}

export const wsConnection = new WsConnection();
