import {action, makeAutoObservable} from 'mobx';
import type {ITranslations} from '../../graphql/api/Types';
import {call, gql} from '../client';
import {projectStore} from '../stores/ProjectStore';
import {IPagePartialProduct} from '../../graphql/api/page/Page';

interface IProductWorkshop
{
    // first 8 characters of the page id
    id: string
    name: ITranslations
    products: string[]
    partial: IPagePartialProduct[]
}

function getProductWorkshops(project: string)
{
    return call<{productWorkshops: IProductWorkshop[]}>(
        gql`query($project:ID!){productWorkshops(project:$project){id,name,products,partial{id,promoCodes}}}`,
        {project}
    ).then(({data}) => data?.productWorkshops);
}

class ProductWorkshopProductsStore
{
    list: {[project: string]: IProductWorkshop[]} = {};

    get workshops()
    {
        return this.list[projectStore.id];
    }

    constructor()
    {
        makeAutoObservable(this);
    }

    load(project: string)
    {
        getProductWorkshops(project).then(action(workshops =>
        {
            if (workshops)
            {
                this.list[project] = workshops;
            }
        }));
    }
}

export const productWorkshopProductsStore = new ProductWorkshopProductsStore();
