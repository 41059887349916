// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TranslationFormField {
  margin-bottom: 0.75rem;
}
.TranslationFormField label:first-child {
  font-weight: bold;
  margin-bottom: 0.25rem;
}
.TranslationFormField input {
  display: block;
  width: 100%;
}
.TranslationFormField label:last-child {
  margin-top: 0.25rem;
  margin-bottom: 0;
}
.TranslationFormField:last-child {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./frontend/Products/TranslationForm.scss"],"names":[],"mappings":"AAAA;EAaE,sBAAA;AAXF;AADE;EACE,iBAAA;EACA,sBAAA;AAGJ;AADE;EACE,cAAA;EACA,WAAA;AAGJ;AADE;EACE,mBAAA;EACA,gBAAA;AAGJ;AAAE;EACE,gBAAA;AAEJ","sourcesContent":[".TranslationFormField {\n  label:first-child {\n    font-weight: bold;\n    margin-bottom: .25rem;\n  }\n  input {\n    display: block;\n    width: 100%;\n  }\n  label:last-child {\n    margin-top: .25rem;\n    margin-bottom: 0;\n  }\n  margin-bottom: .75rem;\n  &:last-child {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
