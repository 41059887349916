import React from 'react';
import {t} from '../translations';
import {LoginChildProps} from './LoginBox';
import {RetryLarge} from './Buttons';
import LoginMethodSwitchOptions from './LoginMethodSwitchOptions';
import BlueBox from './BlueBox';

export default function RegIDNotFound(props: LoginChildProps)
{
    return (
        <BlueBox>
            <div className='LoginInput'>
                <p className='text-center'>{t.welcomePageEvent.regIdNotFound}</p>
                <RetryLarge onClick={() => props.setState('enterRegID')}/>
                <LoginMethodSwitchOptions {...props}/>
            </div>
        </BlueBox>
    );
}
