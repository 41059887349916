import {action, makeObservable, observable} from 'mobx';
import {IContact} from '../stores/userProxy';
import {callSendEmail, loadEmails, loadSentEmails} from './emailProxy';
import {IEmailData} from '../../graphql/api/email/Email';
import {user} from '../stores/user';

let nextKey = 0;

interface NewEmail
{
    key: number
    to?: IContact
}

export class EmailStore
{
    @observable open: NewEmail[] = []; // ids
    // id can be person id or id of the email we replied to
    @observable emails: {[id: string]: IEmailData[]} = {};
    @observable sent: IEmailData[] = [];
    @observable loadingEmails = false;

    constructor()
    {
        makeObservable(this);
    }

    @action
    openEmail(to?: IContact)
    {
        this.open.push({
            key: nextKey++,
            to,
        });
    }

    @action
    closeEmail(key: number)
    {
        this.open.splice(this.open.findIndex(o => o.key == key), 1);
    }

    sendEmail(to: string[], subject: string, html: string, attachments: string[], inReplyTo?: string)
    {
        return callSendEmail(to, subject, html, attachments, inReplyTo).then(action((res: IEmailData) =>
        {
            if (res)
            {
                if (inReplyTo)
                {
                    this.emails[inReplyTo].push(res);
                }
                else for (const c of res.receivers)
                {
                    if (!c.includes('@'))
                    {
                        if (this.emails[c])
                        {
                            this.emails[c].push(res);
                        }
                        else
                        {
                            this.emails[c] = [res];
                        }
                    }
                }
            }
            return !!res;
        }));
    }

    @action
    loadEmails(related: string)
    {
        if (related != user.id && !user.moderator)
        {
            return;
        }
        this.loadingEmails = true;
        loadEmails(related).then(action(res =>
        {
            if (res)
            {
                this.emails[related] = res;
            }
            this.loadingEmails = false;
        }));
    }

    @action
    loadSentEmails()
    {
        loadSentEmails().then(action(res =>
        {
            if (res)
            {
                this.sent = res;
            }
        }));
    }
}

export const emailStore = new EmailStore();
