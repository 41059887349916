import React, {Suspense, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import './Navbar.scss';
import {user} from '../stores/user';
import FA from 'react-fontawesome';
import {ErrorBoundary} from '../common/ErrorBoundary';
import NavbarSearch from './NavbarSearch';
import {cartTotalStore} from '../StoreCatalog/CartTotalStore';
import { t } from '../translations';
import { navbarStore } from './NavBarStore';
import { projectStore } from '../stores/ProjectStore';
import {Loading} from '../common/Loading';
import {eventStore} from '../Event/EventStore';
import {pickLanguage} from '../stores/utility';

export const handleNavMenu = () =>
{
    navbarStore.toggleMenu();
};

const handleLogout = () =>
{
    user.logout();
};

const Navbar = observer(function Navbar()
{
    const [showSettings, setShowSettings] = useState<boolean>();

    useEffect(() =>
    {
        projectStore.withId(id =>
        {
            eventStore.loadEvent(projectStore.id);
            cartTotalStore.loadProducts(id);
        });
    }, [projectStore.id]);

    return (
        <nav className='navbar navbar-dark navbar-expand-xl py-2 justify-content-between'>
            <div className='d-flex'>
                <div className='menu-icon' onClick={handleNavMenu}>
                    <FA name='bars' className='fas hamburger-icon'/>
                    MENU
                </div>
                <Link className='navbar-brand font-2 py-0' to='/'>LET'S CONNECT</Link>
                <div className='navbar-mini-logo'>
                    <Link to='/'>
                        <img src='https://yourfair-pub.s3-eu-west-1.amazonaws.com/assets/letsconnect_logo.svg' alt=''/>
                    </Link>
                </div>
                {/*{eventStore.canSeeFairParticipation && !eventStore.projectEvent?.frNonFood &&*/}
                {/*<Link to='/fair-participation' className='unstyle-link fair-participation text-nowrap'>{t.global.navBar.eventRegistration}</Link>}*/}
                <span className='navbar-project-name'>{pickLanguage(projectStore.selected?.name)}</span>
            </div>
            <div className='d-flex justify-content-end'>
                {!user.isExhibitor && <NavbarSearch/>}
                {projectStore.canOrder && projectStore.isActive && projectStore.selected?.fair.settings.addToCart &&
                <Link className='cart-nav position-relative' to={'/cart'}>
                    <FA name='shopping-cart' className='fas cart-icon'/>
                    <span className='badge badge-secondary'>{cartTotalStore.cartQuantity}</span>
                </Link>}
                <div
                    className='h-100 position-relative'
                    tabIndex={0}
                    onBlur={e =>
                    {
                        if (showSettings && !e.currentTarget.contains(e.relatedTarget as any))
                        {
                            setShowSettings(false);
                        }
                    }}
                >
                    <button className='button settings' onClick={() => setShowSettings(s => !s)}>
                        <span className='settings-text'>{t.global.navBar.settings}</span>
                        <FA name='cog' className='mobile-settings'/>
                    </button>
                    {showSettings &&
                    <div className='settings-dropdown d-flex flex-column'>
                        <Link to={'/dashboard'} style={{textDecoration: 'none'}}>
                            <div>{t.global.navBar.updateProfile}</div>
                        </Link>
                        <div onClick={handleLogout}>{t.global.navBar.disconnect}</div>
                    </div>
                    }
                </div>
            </div>
        </nav>
    );
});

export function WithNavbar({children}: React.PropsWithChildren<{}>)
{
    const path = location.pathname.substr(1);
    return (
        <div className='main'>
            <AutoScrollToTop/>
            {(!path || (!path.startsWith('event/') && !eventStore.eventByUrl(decodeURIComponent(path.split('/')[0])))) && <Navbar/>}
            <main className='mainContent'>
                <ErrorBoundary>
                    <Suspense fallback={<Loading className='position-fixed'/>}>
                        {children}
                    </Suspense>
                </ErrorBoundary>
            </main>
        </div>
    );
}

function AutoScrollToTop()
{
    const location = useLocation();
    useEffect(() =>
    {
        window.scrollTo?.(0, 0);
    }, [location.pathname]);
    return null;
}
