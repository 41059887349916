import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite'
import {Link} from 'react-router-dom';
import './NavMenuOverlay.scss'
import {t} from '../translations';
import FA from 'react-fontawesome';
import {handleNavMenu} from './Navbar';
import {user} from '../stores/user';
import {languageList, languageNames} from '../data/language';
import NavbarProjectSelect from './NavbarProjectSelect';
import CustomMenu from './CustomMenu';
import NavMenuMain from './NavMenuMain';
import {mainStateReload, navbarStore} from './NavBarStore';

export default observer(function NavMenuOverlay()
{
    useEffect(mainStateReload, []);

    if (navbarStore.dismountMenu)
    {
        return null;
    }

    const menu = navbarStore.navMenu;

    return (
        <div className={'nav-menu-overlay d-flex ' + (menu ? 'show' : 'hide')}>
            <div className='wrapper'>
                <div className={'nav-menu d-flex flex-column justify-content-between pb-2 ' + (menu ? 'show-menu' : '')}>
                    <div>
                        <div className='inner d-flex align-items-center'>
                            <FA name='times' className='fas hamburger-icon' onClick={handleNavMenu}/>
                            <Link className='navbar-brand font-2 py-0' to='/'>LET'S CONNECT</Link>
                        </div>
                        <NavbarProjectSelect/>
                        <CustomMenu/>
                        <div className='menu-items'>
                            <NavMenuMain/>
                            <NavChangeLanguage/>
                            <NavMenuSettings/>
                        </div>
                    </div>
                    <div className='copyright d-flex flex-column'>
                        <span>Let's connect v1.1.0</span>
                        <span><a href='https://www.d-sidegroup.com/' target='blank'>{t.global.navBar.madeByDSide}</a></span>
                    </div>
                </div>
            </div>
            <div className='overlay-background' onClick={handleNavMenu}/>
        </div>
    )
});

const NavChangeLanguage = observer(function NavChangeLanguage()
{
    return (
        <select onChange={e => user.changeLanguage(e.target.value as any)} className='select-language' value={user.language}>
            {languageList.map(l =>
                <option
                    key={l}
                    className={'list-group-item cursor-pointer ' + (user.language == l ? 'active' : '')}
                    value={l}
                >
                    {languageNames[l]}
                </option>
            )}
        </select>
    );
});

const NavMenuSettings = observer(function NavMenuSettings()
{
    const [settingsDropdown, setSettingsDropdown] = useState<boolean>(false);

    const handleBlur = (e: React.FocusEvent) =>
    {
        if (settingsDropdown && !e.currentTarget.contains(e.relatedTarget as any))
        {
            setSettingsDropdown(false);
        }
    };

    return (
        <div className='h-100 position-relative mt-4 pb-4' tabIndex={0} onBlur={handleBlur}>
            <button className='button settings' onClick={() => setSettingsDropdown(!settingsDropdown)}>
                <span className='settings-text'>{t.global.navBar.settings}</span>
                <FA name='cog' className='mobile-settings'/>
            </button>
            {settingsDropdown && (
                <div className='settings-dropdown d-flex flex-column'>
                    <Link to={'/dashboard'} style={{textDecoration: 'none'}}>
                        <div>{t.global.navBar.updateProfile}</div>
                    </Link>
                    <div onClick={(e) => {e.preventDefault(); user.logout()}}>{t.global.navBar.disconnect}</div>
                </div>
            )}
        </div>
    );
});
