import React from 'react';
import './TextEditable.scss';

export interface TextEditableProps
{
    className?: string
    type?: string // default: div
    content: string
    autoSelect?: boolean
    disabled?: boolean
    onChange?(content: string): void
    onBlur?(content: string): void
    // returning false would revert the changes
    onChangeEnter?(content: string): Promise<boolean> | boolean | void
}

export default class TextEditable extends React.Component<TextEditableProps>
{
    el = React.createRef<HTMLElement>();

    get text()
    {
        const text = this.el.current.innerText;
        if (text.endsWith('\n') && !text.endsWith('\n\n'))
        {
            return text.substr(0, text.length - 1) + ' ';
        }
        return text;
    }

    shouldComponentUpdate(p: TextEditableProps)
    {
        return this.text !== p.content || this.props.type !== p.type || this.props.className !== p.className || this.props.disabled !== p.disabled;
    }

    componentDidMount()
    {
        this.el.current.innerText = this.props.content;
    }

    componentDidUpdate()
    {
        if (this.text !== this.props.content)
        {
            this.el.current.innerText = this.props.content;
        }
    }

    handleKeyDown = (e) =>
    {
        if (e.key == 'Enter')
        {
            e.preventDefault();
            this.el.current.blur();
        }
    };

    handleBlur = () =>
    {
        const text = this.text;
        this.props.onBlur?.(text);
        if (this.props.onChangeEnter && text !== this.props.content)
        {
            const res = this.props.onChangeEnter(text);
            if (typeof res == 'object')
            {
                Promise.resolve(res).then(r =>
                {
                    if (r === false)
                    {
                        this.forceUpdate();
                    }
                })
            }
            else if (res === false)
            {
                this.forceUpdate();
            }
        }
        // clear any special formatting
        if (this.el.current.innerText != text)
        {
            this.el.current.innerText = text;
        }
    };

    handleFocus = () =>
    {
        if (this.props.autoSelect)
        {
            window.getSelection().selectAllChildren(this.el.current);
        }
    };

    handleChange = () =>
    {
        const text = this.text;
        if (text !== this.props.content)
        {
            this.props.onChange?.(text);
        }
    };

    render()
    {
        const p = this.props;
        return React.createElement(
            p.type || 'div',
            {
                spellCheck: false,
                ref: this.el,
                onInput: this.handleChange,
                onBlur: this.handleBlur,
                onFocus: this.handleFocus,
                onKeyDownCapture: this.handleKeyDown,
                contentEditable: !p.disabled,
                className: 'TextEditable ' + (p.className || '') + (p.disabled ? ' disabled' : '')
            }
        );
    }
}
