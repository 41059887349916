import {Fragment} from 'react';
import {observer} from 'mobx-react-lite';
import './CartUpdateOverlay.scss';
import {cartQuantityStore} from './CartQuantityStore';
import {t} from '../translations';
import {catalogProductStore} from './CatalogProductStore';
import {pickLanguage} from '../stores/utility';
import ProductImage from './ProductImage';
import {projectStore} from '../stores/ProjectStore';

export default observer(function CartUpdateOverlay()
{
    return (
        <div className='cart-notification-products d-flex flex-column'>
            {cartQuantityStore.cartNotifications.map((p, i) =>
            {
                const product = catalogProductStore.products?.find(pr => pr.id === p.productId);
                const stores = Object.keys(p.quantity);
                return (
                    <Fragment key={p.productId}>
                        {!!stores.length &&
                        <div className='product-notification d-flex' onClick={() => cartQuantityStore.closeNotificationByIndex(i)}>
                            <div className='image'>
                                <ProductImage images={product?.images ? product.images.length == 1 ? product.images : [product.images[0]] : null}/>
                            </div>
                            <div className='d-flex flex-column description'>
                                <p>{pickLanguage(product?.description)}</p>
                                {stores.map(k =>
                                    <span key={k}>
                                        <b>{projectStore.selectedCountry === 'IT' ? null : '+'}{p.quantity[k]}</b>
                                        {' ' + t.global.navBar.cartWidget.addToCart + ' '}
                                        <b>{k}</b>
                                    </span>
                                )}
                            </div>
                        </div>}
                    </Fragment>
                );
            })}
        </div>
    );
});
