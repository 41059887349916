
// assigns values from object properties - recursive
export function assignValues(target, map)
{
    for (const key of Object.getOwnPropertyNames(map))
    {
        const value = map[key];
        if (value !== undefined)
        {
            if (value && typeof value == 'object' && !Array.isArray(value))
            {
                if (!target[key] || typeof target[key] == 'object')
                {
                    target[key] = {};
                }
                assignValues(target[key], value);
            }
            else
            {
                target[key] = value;
            }
        }
    }
}
