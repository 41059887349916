import {action, makeAutoObservable} from 'mobx';
import {call, gql} from '../client';
import {CartByWorkshopResult} from '../../graphql/api/cart/Cart';

class CartByWorkshopStore
{
    data: {[project: string]: CartByWorkshopResult[]} = {};
    loading = false;

    constructor()
    {
        makeAutoObservable(this);
    }

    load(project: string)
    {
        this.loading = true;
        cartByWorkshop(project).then(action(orders =>
        {
            this.loading = false;
            if (orders)
            {
                this.data[project] = orders;
            }
        }));
    }
}

export const cartByWorkshopStore = new CartByWorkshopStore();

function cartByWorkshop(project: string)
{
    return call<{cartByWorkshop: CartByWorkshopResult[]}>(
        gql`query($project:ID!){cartByWorkshop(project:$project){storeId,storeId,workshopGroups{name,workshops{id,name,invoice,selling}}}}`,
        {project}
    ).then(({data}) => data?.cartByWorkshop);
}
