import {IPageItem} from './Page';
import {ContentBlockProps} from '../../../frontend/Pages/ContentBlock';

interface ContentBlockItem extends IPageItem
{
    props: ContentBlockProps
}

export function detectContactButtonChange(prev: (IPageItem | ContentBlockItem)[], next: (IPageItem | ContentBlockItem)[])
{
    const nextContactButtons = next.filter((i): i is ContentBlockItem => (i.props as ContentBlockProps)?.contact?.active);
    if (nextContactButtons.length)
    {
        const prevContactButtons = prev.filter((i): i is ContentBlockItem => (i.props as ContentBlockProps)?.contact?.active);
        const res: {block: string, addedContacts: string[], addedCompanies: string[]}[] = [];
        for (const n of nextContactButtons)
        {
            const p = prevContactButtons.find(i => i.id == n.id);
            if (p)
            {
                const addedContacts = n.props.contact.contacts?.filter(id => !p.props.contact.contacts?.includes(id));
                const addedCompanies = n.props.contact.companies?.filter(id => !p.props.contact.companies?.includes(id));
                if (addedContacts?.length || addedCompanies?.length)
                {
                    res.push({
                        block: n.id,
                        addedContacts,
                        addedCompanies,
                    });
                }
            }
        }
        if (res.length)
        {
            return res;
        }
    }
}
