import {action, autorun, observable} from 'mobx';
import './overlay.css';

type OverlayCondition = () => unknown

const conditions = observable.array<OverlayCondition>([]);

export const addOverlayCondition = action((condition: OverlayCondition) =>
{
    if (!conditions.includes(condition))
    {
        conditions.push(condition);
    }
});

autorun(() =>
{
    document.body.className = conditions.some(c => c()) ? 'overlay-open' : '';
});
