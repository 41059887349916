import {memo, ReactNode} from 'react';
import {useId} from './useId';
import styles from './FormField.module.css';
import PhoneField from './PhoneField';
import type {Props, DefaultInputComponentProps} from 'react-phone-number-input';

export default memo(function FormFieldPhone({label, ...rest}: Props<DefaultInputComponentProps> & {label?: ReactNode})
{
    const id = useId();
    return (
        <div className={styles.fieldMargin}>
            {!!label && <label htmlFor={id}>{label}</label>}
            <PhoneField {...rest} numberInputProps={rest.numberInputProps ? {...rest.numberInputProps, id} : {id}}/>
        </div>
    );
});
