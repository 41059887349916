// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagSelect {
  outline: none;
}
.TagSelect *:not(.fa) {
  font-family: Montserrat, sans-serif;
}

.TagSelectTop {
  border-bottom: 1px solid #999;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.TagSelectTop .add {
  float: right;
  cursor: pointer;
}
.TagSelectTop .fa {
  color: #999;
}
.TagSelectTop.bold {
  border-bottom: 1px solid #000;
}
.TagSelectTop.bold .title {
  font-weight: bold;
  font-size: 1em;
}
.TagSelectTop.bold .fa {
  color: #000;
}

.TagSelectSelect {
  position: relative;
}
.TagSelectSelect .list {
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
}
.TagSelectSelect .list li {
  cursor: pointer;
}

.TagSelectTag {
  display: inline-block;
  background: #fff;
  border: 1px solid #333;
  border-radius: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  padding-left: 0.6rem;
}
.TagSelectTag b {
  padding: 0 0.6rem;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./frontend/common/TagSelect.scss","webpack://./frontend/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;AAEE;EACE,mCCJK;ADIT;;AAGA;EACE,6BAAA;EACA,sBAAA;EACA,qBAAA;AAAF;AACE;EACE,YAAA;EACA,eAAA;AACJ;AACE;EACE,WAAA;AACJ;AACE;EACE,6BAAA;AACJ;AAAI;EACE,iBAAA;EACA,cAAA;AAEN;AAAI;EACE,WAAA;AAEN;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,YAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;AAEJ;AADI;EACE,eAAA;AAGN;;AACA;EACE,qBAAA;EACA,gBAAA;EACA,sBAAA;EACA,qBAAA;EACA,kBAAA;EACA,qBAAA;EACA,oBAAA;AAEF;AADE;EACE,iBAAA;EACA,eAAA;AAGJ","sourcesContent":["@import 'frontend/variables';\n\n.TagSelect {\n  outline: none;\n  *:not(.fa) {\n    font-family: $font-2;\n  }\n}\n.TagSelectTop {\n  border-bottom: 1px solid #999;\n  padding-bottom: .5rem;\n  margin-bottom: .5rem;\n  .add {\n    float: right;\n    cursor: pointer;\n  }\n  .fa {\n    color: #999;\n  }\n  &.bold {\n    border-bottom: 1px solid #000;\n    .title {\n      font-weight: bold;\n      font-size: 1em;\n    }\n    .fa {\n      color: #000;\n    }\n  }\n}\n.TagSelectSelect {\n  position: relative;\n  .list {\n    z-index: 100;\n    position: absolute;\n    top: 0;\n    right: 0;\n    li {\n      cursor: pointer;\n    }\n  }\n}\n.TagSelectTag {\n  display: inline-block;\n  background: #fff;\n  border: 1px solid #333;\n  border-radius: .5rem;\n  margin-right: 1rem;\n  margin-bottom: .5rem;\n  padding-left: .6rem;\n  b {\n    padding: 0 .6rem;\n    cursor: pointer;\n  }\n}\n","$font-1: 'Open Sans', sans-serif;\n$font-2: Montserrat, sans-serif;\n\n$desktop: 1200px;\n$tablet: 1024px;\n$phablet: 768px;\n$somePhones: 576px;\n$highEndPhones: 480px;\n$budgetPhones: 320px;\n$sbsVertical: 860px;\n$smallerMenu: 1000px;\n$menuInSideBar: 700px;\n\n$button-color: #3d8af7;\n$button-empty-color: #403d39;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
