import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Navigate} from 'react-router';
import './HelpCard.scss';
import {t} from '../translations';
import SplitView from '../Dashboard/SplitView';
import HelpCategoryEdit from './HelpCategoryEdit';
import { observer } from 'mobx-react-lite';
import { helpStore, IHelpCategory } from './HelpStore';
import { user } from '../stores/user';
import { projectStore } from '../stores/ProjectStore';
import { companyStore } from '../Company/CompanyStore';
import {debouncedLogView} from '../stores/logView';
import {pickLanguage} from '../stores/utility';

const handleAddCategory = () =>
{
    helpStore.selectCategory(null);
};

export default observer(function HelpPage()
{
    const projectId = projectStore.id;
    useEffect(() =>
    {
        if (projectId)
        {
            debouncedLogView({type: 'help', project: projectId});
            helpStore.loadCategories(projectId);
            if (helpStore.selectedId)
            {
                helpStore.selectCategory(null);
            }
        }
    }, [projectId]);

    if (companyStore.initialLoad && projectStore.initialLoad && !projectStore.ownProjects.length && !user.isGuest && !user.isStore && !user.moderator)
    {
        return <Navigate to='/dashboard'/>;
    }

    if (user.archivedExhibitor)
    {
        return <Navigate to='/'/>;
    }
    return (
        user.moderator ?
            <SplitView
                left={<MainHelpView/>}
                right={<HelpCategoryEdit/>}
            />
            :
            <MainHelpView/>
    );
});

interface HelpCardProps
{
    category: IHelpCategory;
}

const MainHelpView = observer(function MainHelpView()
{
    return (
        <div className='container p-3'>
            {user.moderator &&
            <div className='d-flex justify-content-end'>
                <button className='button' onClick={handleAddCategory}>{t.helpPage.addCategory}</button>
            </div>
            }
            <h1>{t.helpPage.title}</h1>
            <div className='row mt-4'>
                {helpStore.helpCategories[projectStore.id]?.map((category, i) =>
                    <HelpCard
                        key={i}
                        category={category}
                    />
                )}
            </div>
        </div>
    );
});

export const HelpCard = observer(function HelpCard({category: {id, name, image, customURL}}: HelpCardProps)
{
    return (
        <div className='col-sm-4 mb-5'>
            <div className='HelpCard'>
                <div className='inner' onClick={() => helpStore.selectCategory(id)}>
                    <div className='card-image'>
                        <img src={image || 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/p%2BrTmh0Th9rJgjB7_FAVPNG_grayscale-icon_0d24EUeE.png'} alt=''/>
                    </div>
                    <div className='helpName'>{pickLanguage(name)}</div>
                    <div className='viewButton'>
                        <Link to={'/help/' + (customURL || '#ASSIGN-URL')}>
                            <button className='button'>{t.helpPage.view}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
});
