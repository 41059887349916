import {useEffect, useState} from 'react';

export default function useWindowDimensions()
{
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() =>
    {
        function handleResize()
        {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

function getWindowDimensions()
{
    return {
        width: window.innerWidth,
        height: window.innerHeight,
    };
}
