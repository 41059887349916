import {escapeHtmlTags} from '../../lib/common';

export interface WithName
{
    id?: string
    firstName?: string
    lastName?: string
}

export function getName(i: WithName): string
{
    return escapeHtmlTags(i ? ((i.firstName || '') + ' ' + (i.lastName || '')).trim() || i.id || '' : '');
}

// used when HTML has already been escaped
export function getNameWithoutEscapingHTML(i: WithName): string
{
    return i ? ((i.firstName || '') + ' ' + (i.lastName || '')).trim() || i.id || '' : '';
}

export function getInitials(i: WithName): string
{
    return i ? (i.firstName ? i.firstName[0] : '') + (i.lastName ? i.lastName[0] : '') : '';
}
