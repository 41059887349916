// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiFormTabs {
  padding: 0 5px;
  font-size: 0.8rem;
}
.MultiFormTabs span {
  display: inline-block;
  min-width: 40px;
  background-color: #f7f8f7;
  border: 1px solid #000;
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin-bottom: -1px;
  padding: 0 0.5rem;
}
.MultiFormTabs span.selected {
  background-color: #403d39;
  color: #fff;
}

.MultiFormContent {
  padding: 1rem;
  background-color: #f7f8f7;
  border: 1px solid #000;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./frontend/Products/MultiForm.scss"],"names":[],"mappings":"AACA;EACE,cAAA;EACA,iBAAA;AAAF;AACE;EACE,qBAAA;EACA,eAAA;EACA,yBAAA;EACA,sBAAA;EACA,gBAAA;EACA,2BAAA;EACA,4BAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AAAI;EACE,yBAAA;EACA,WAAA;AAEN;;AAGA;EACE,aAAA;EACA,yBAAA;EACA,sBAAA;EACA,kBAAA;AAAF","sourcesContent":["\n.MultiFormTabs {\n  padding: 0 5px;\n  font-size: .8rem;\n  span {\n    display: inline-block;\n    min-width: 40px;\n    background-color: #f7f8f7;\n    border: 1px solid #000;\n    border-bottom: 0;\n    border-top-left-radius: 5px;\n    border-top-right-radius: 5px;\n    text-align: center;\n    cursor: pointer;\n    margin-bottom: -1px;\n    padding: 0 .5rem;\n    &.selected {\n      background-color: #403d39;\n      color: #fff;\n    }\n  }\n}\n\n.MultiFormContent {\n  padding: 1rem;\n  background-color: #f7f8f7;\n  border: 1px solid #000;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
