import React, {PropsWithChildren} from 'react';
import {observer} from 'mobx-react-lite';
import {navbarStore} from './NavBarStore';
import {pickLanguage} from '../stores/utility';
import {LinkForPages} from './CustomMenu';
import {Label} from '../../graphql/api/navLinks/NavLinks';
import {ITranslations} from '../../graphql/api/Types';

export default observer(function CustomMenuView()
{
    const items = navbarStore.menuItems;

    return (
        <>
            {items?.list?.map(({label, pages}) =>
                <CustomLabel
                    key={label.id}
                    label={label}
                >
                    {pages?.map((page, i) =>
                        <CustomPage
                            key={i}
                            name={page.name}
                            link={page.link}
                        />
                    )}
                </CustomLabel>
            )}
        </>
    )
});

const CustomLabel = observer(function CustomLabel({children, label}: PropsWithChildren<{label: Label}>)
{
    return (
        <div className='label-pages'>
            <div className='w-100 h-100'>
                <h6 className='label'>{pickLanguage(label.name)}</h6>
                <div className='inner'>
                    {children}
                </div>
            </div>
        </div>
    );
});

const CustomPage = observer(function CustomPage({link, name}: {link: string, name: ITranslations})
{
    return (
        <div>
            <div className={'page d-flex align-items-center position-relative'}>
                <LinkForPages link={link} name={pickLanguage(name)}/>
            </div>
        </div>
    );
});
