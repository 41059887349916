import type {SortDirection} from 'mongodb';
import type {Country, Language} from '../Types';
import type {IStore} from '../company/Company';
import type {IExhibitorProductContext, ISequence} from './ExhibitorProduct';
import {businessUnits, IBusinessUnit} from '../../../frontend/data/storeTypes';

interface ICompanyDocumentStoreType
{
    context: {
        store?: Pick<IStore, 'storeType'>
    }
}

export function sequenceSortRules(country: Country, stores: ICompanyDocumentStoreType[])
{
    if (['IT', 'PL'].includes(country))
    {
        return null;
    }
    const res: [string, SortDirection][] = [];
    const type = sequenceSortType(country, stores);
    if (type)
    {
        res.push([`context.code.sequence.${country}.${type}`, 1]);
    }
    res.push([`context.code.sequence.${country}.default`, 1]);
    return res;
}

export function sequenceSortType(country: Country, stores: ICompanyDocumentStoreType[])
{
    if (stores && country == 'BE')
    {
        return stores.some(s => s.context.store.storeType == 'H') ? 'hyper' : undefined;
    }
    if (stores && country == 'FR')
    {
        return stores.some(s => ['MF', 'MI'].includes(s.context.store.storeType)) ?
            undefined :
            stores.some(s => ['H', 'HF'].includes(s.context.store.storeType)) ?
                'hyper' : stores.some(s => s.context.store.storeType == 'CPI') ?
                    'cpi' :
                    'proxi';
    }
}

export function getSequence<T extends Country = any>(country: T, sequence: Pick<Partial<ISequence>, T>, stores: ICompanyDocumentStoreType[])
{
    const countrySequence = sequence?.[country];
    if (!countrySequence)
    {
        return undefined;
    }
    const type = sequenceSortType(country, stores);
    return type && countrySequence[type] ? countrySequence[type] : countrySequence.default;
}

export function cleanSousVente(sousVente: string)
{
    return sousVente?.replace(/ ?(salon|supp?) (crf|carrefour)? ?/i, ' ').trim();
}

export function isBusinessUnitObject(v): v is {[buKey in IBusinessUnit]?: any}
{
    return !!v && typeof v == 'object' && Object.getOwnPropertyNames(v).every(k => businessUnits.includes(k));
}

// export function getBusinessUnitValues<T extends object | any>(v: T): T extends {[buKey in IBusinessUnit]?: infer R} ? R[] : []
// {
//     if (v && typeof v == 'object')
//     {
//         const keys = Object.getOwnPropertyNames(v);
//         if (keys.every(k => businessUnits.includes(k)))
//         {
//             return keys.map(k => v[k]) as any;
//         }
//     }
//     return [] as any;
// }

export function getBusinessUnitPrimitives<T extends number | string>(v: T | {[bu in IBusinessUnit]?: T}): T[]
{
    if (typeof v == 'number' || typeof v == 'string')
    {
        return [v];
    }
    if (v && typeof v == 'object')
    {
        const keys = Object.getOwnPropertyNames(v);
        if (keys.every(k => businessUnits.includes(k)))
        {
            return keys.map(k => v[k]) as any;
        }
    }
    return [] as any;
}

export function getBusinessUnitValue<T extends object | any>(v: T, bu?: IBusinessUnit): T extends {[buKey in IBusinessUnit]?: infer R} ? R : T
{
    if (isBusinessUnitObject(v))
    {
        if (!bu)
        {
            const firstBUKey = Object.getOwnPropertyNames(v).find(k => businessUnits.includes(k));
            if (firstBUKey)
            {
                return v[firstBUKey];
            }
        }
        else if (bu in (v as object))
        {
            return v[bu];
        }
        return null;
    }
    return v as any;
}

export function setImageOnProduct(product: IExhibitorProductContext, url: string, bu?: IBusinessUnit, packageIndex?: number)
{
    if (packageIndex != null)
    {
        product.package[packageIndex].image = url;
    }
    else if (bu)
    {
        if (isBusinessUnitObject(product.image))
        {
            product.image[bu] = url;
        }
        else
        {
            product.image = {[bu]: url};
        }
    }
    else
    {
        product.image = url;
    }
}

export function setVideoOnProduct(product: IExhibitorProductContext, url: string, bu?: IBusinessUnit, language?: Language)
{
    if (bu)
    {
        if (isBusinessUnitObject(product.video))
        {
            if (language)
            {
                if (!product.video[bu])
                {
                    product.video[bu] = {};
                }
                else if (typeof product.video[bu] == 'string')
                {
                    product.video[bu] = {en: product.video[bu] as string};
                }
                product.video[bu][language] = url;
            }
            else
            {
                product.video[bu] = url;
            }
        }
        else
        {
            product.video = language ? {[bu]: {[language]: url}} : {[bu]: url};
        }
    }
    else
    {
        if (isBusinessUnitObject(product.video))
        {
            product.video = null;
        }
        if (language)
        {
            if (!product.video)
            {
                product.video = {};
            }
            else if (typeof product.video == 'string')
            {
                product.video = {en: product.video};
            }
            product.video[language] = url;
        }
        else
        {
            product.video = url;
        }
    }
}
