import {action, makeObservable, observable} from 'mobx';
import {callRequestVideoUpload, callUploadVideoCanceled, callUploadVideoFinished} from './uploadVideoProxy';
import {uploadFile} from './uploadFile';
import {Upload} from './UploadStore';
import {projectStore} from '../stores/ProjectStore';
import checkIfFileIsReadable from './checkIfFileIsReadable';

export interface VideoUploadOptions
{
    // dir?: string
    name?: string
}

class VideoStore
{
    @observable uploads: {[url: string]: Upload} = {};

    constructor()
    {
        makeObservable(this);
    }

    // resolves when the server responds with a signed url (when the actual upload process starts)
    // use the onEnd() callback to get notified when the file upload actually ends
    // onEnd() callback is not called if we fail to get signed url (if the promise is rejected)
    @action
    upload(file: File, onEnd?: (a: Upload) => void, options?: VideoUploadOptions)
    {
        checkIfFileIsReadable(file);
        return callRequestVideoUpload({
            project: projectStore.id,
            name: options?.name || file.name,
            // dir: options?.dir,
            contentType: file.type,
            size: file.size,
        }).then(({name, url, signedUrl}) =>
        {
            console.log('upload to:', url);
            this.uploads[url] = {url, name, progress: 0};
            const u = this.uploads[url];
            u._xhr = uploadFile({
                method: 'PATCH',
                url: signedUrl,
                file,
                headers: {
                    'Tus-Resumable': '1.0.0',
                    'Upload-Offset': '0',
                    'Content-Type': 'application/offset+octet-stream',
                    Accept: 'application/vnd.vimeo.*+json;version=3.4',
                },
                onUploaded: action(() =>
                {
                    u.finished = true;
                    u.ended = true;
                    callUploadVideoFinished(url);
                    // wait a bit before trying to access the video
                    setTimeout(() =>
                    {
                        onEnd?.(u);
                    }, 100);
                }),
                onError: action((e) =>
                {
                    if (!u.canceled)
                    {
                        u.error = true;
                    }
                    console.log('upload error');
                    callUploadVideoCanceled(url);
                    u.ended = true;
                    onEnd?.(u);
                }),
                onProgress: action((progress: number) =>
                {
                    u.progress = progress;
                }),
            });
            return u;
        });
    }

    @action
    cancel(url: string)
    {
        const u = this.uploads[url];
        if (u && !u.canceled)
        {
            u.canceled = true;
            if (!u.ended)
            {
                u.ended = true;
                u._xhr.abort();
            }
        }
    }

    // returns the upload only if it is still in progress
    getUploadInProgress(key: string)
    {
        const u = this.uploads[key];
        return u && !u.ended ? u : null;
    }
}

export const videoStore = new VideoStore();

window.addEventListener('beforeunload', e =>
{
    for (const key of Object.getOwnPropertyNames(videoStore.uploads))
    {
        if (!videoStore.uploads[key].ended)
        {
            console.log('There are active uploads.');
            e.preventDefault();
            e.returnValue = '';
        }
    }
});
