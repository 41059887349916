import React, {useEffect} from 'react'
import { observer } from 'mobx-react-lite';
import { organizationStore } from './organizationStore';
import { companyStore } from '../Company/CompanyStore';
import DropdownSearch from '../common/DropdownSearch';
import {countryStore} from '../stores/CountryStore';
import {profiles} from '../data/profiles';
import { addCompanyStore } from '../Contact/AddCompanyStore';
import {pickLanguage} from '../stores/utility';
import {isID} from '../../lib/common';

export default observer(function OrganizationManagement()
{
    return (
        <>
            <div className='d-flex'>
                <button className='button' onClick={organizationStore.addCompany}>Add company</button>
                <button className='button ml-3' onClick={organizationStore.addPerson}>Add person</button>
            </div>
            {organizationStore.newCompany && <AddCompany/>}
            {organizationStore.newPerson && <AddPerson/>}
        </>
    )
});


const handleCompanyValue = (key) => (e) =>
{
    organizationStore.updateCompany(key, e.target.value)
}
const handleSubmitCompany = () =>
{
    organizationStore.submitAddCompany();
}
const handleCancel = () =>
{
    organizationStore.cancel();
}
const AddCompany = observer(function AddCompany()
{
    const newCompany = organizationStore.newCompany;

    return (
        <div className='add-company-form d-block mt-4'>
            <h2>Add company</h2>
            <div className='d-flex form-group'>
                <input
                    className='form-control mr-2'
                    placeholder='Company Name'
                    value={newCompany?.name || ''}
                    onChange={handleCompanyValue('name')}
                />
                <select onChange={handleCompanyValue('country')} value={newCompany?.country || ''}>
                    <option value='' disabled={true}>Country</option>
                    {countryStore.filteredCountryTupleOptions.map(m =>
                        <option key={m[0]} value={m[0]}>{m[1]}</option>
                    )}
                </select>
            </div>
            <button className='button' onClick={handleSubmitCompany}>Submit</button>
            <button className='button-empty ml-3' onClick={handleCancel}>Cancel</button>
        </div>
    )
})

const handlePersonValue = (key) => (e) =>
{
    organizationStore.updatePerson(key, e.target.value);
}
const handlePersonCompany = (company: string) =>
{
    company = company.trim();
    const companyByVat = companyStore.companies.find(c => c.exhibitor?.contacts.some(a => a.vat == company));
    organizationStore.updatePerson('company', companyByVat ? companyByVat.id : company);
}
const handlePersonProfile = (profile: string) =>
{
    organizationStore.updatePerson('profile', profile);
}
const handleAddPerson = () =>
{
    organizationStore.submitAddPerson();
}

const AddPerson = observer(function AddPerson()
{
    useEffect(() => addCompanyStore.load(), []);
    const newPerson = organizationStore.newPerson;

    return (
        <div className='add-company-form d-block mt-4'>
            <h2>Add person</h2>
            <div className='form-group addPersonFields'>
                <input
                    className='form-control mb-2'
                    placeholder='Name'
                    value={newPerson?.name || ''}
                    onChange={handlePersonValue('name')}
                />
                <select className='form-control mb-2' onChange={handlePersonValue('country')} value={newPerson?.country || ''}>
                    <option value='' disabled>Select Country</option>
                    {countryStore.filteredCountryTupleOptions.map(m =>
                        (<option key={m[0]} value={m[0]}>{m[1]}</option>)
                    )}
                </select>
                <input
                    className='form-control mb-2'
                    placeholder='Email'
                    name='email'
                    value={newPerson?.email || ''}
                    onChange={handlePersonValue('email')}
                />
                <input
                    className='form-control mb-2'
                    placeholder='Phone'
                    name='phone'
                    value={newPerson?.phone || ''}
                    onChange={handlePersonValue('phone')}
                />
                <DropdownSearch
                    className='mb-2'
                    placeholder='Profile Type'
                    options={profiles
                        .filter(p => p.countries.includes(newPerson?.country))
                        .map(c => ({value: c.id, name: pickLanguage(c.name)}))}
                    onSelect={handlePersonProfile}
                    selected={newPerson?.profile}
                />
                <AddPersonCompanyList/>
            </div>
            <button className='button' onClick={handleAddPerson}>Submit</button>
            <button className='button-empty ml-3' onClick={handleCancel}>Cancel</button>
        </div>
    )
});

const AddPersonCompanyList = observer(function AddPersonCompanyList()
{
    const newPerson = organizationStore.newPerson;
    const country = newPerson?.country;
    const company = newPerson?.company;
    const withCustomCompany = ['guest', 'media', 'maintenanceFournisseur'].includes(newPerson?.profile);
    useEffect(() =>
    {
        if (company && withCustomCompany == isID(company))
        {
            handlePersonCompany('');
        }
    }, [withCustomCompany]);
    return (
        <DropdownSearch
            className='mb-2'
            placeholder={withCustomCompany ? 'Enter company name' : 'Select company'}
            options={withCustomCompany ? [] : addCompanyStore.companies
                .filter(co => co.country.includes(country))
                .map(c => ({value: c.id, name: c.name, keywords: c.storeId ? [c.storeId] : null}))
                .sort((a, b) => (a.name || '').localeCompare(b.name || '', 'en', {sensitivity: 'base'}))}
            selected={company || ''}
            onCustom={withCustomCompany ? handlePersonCompany : null}
            onSelect={withCustomCompany ? null : handlePersonCompany}
            allowCustom={withCustomCompany}
        />
    )
});
