import React from 'react';
import FA from 'react-fontawesome';
import './TagSelect.scss';

export type SelectOptions = [string, React.ReactNode][]

export interface TagSelectProps
{
    className?: string
    name: React.ReactNode
    boldName?: boolean
    options: SelectOptions
    selected: string[]
    onSelect?(value: string): void
    onRemove?(value: string): void
    onChange?(selected: string[]): void
    allSelector?: boolean
}

interface State
{
    select?: boolean
}

export default class TagSelect extends React.PureComponent<TagSelectProps, State>
{
    state: State = {};

    get selected()
    {
        const p = this.props;
        return p.selected.filter(s => p.options.some(o => o[0] === s));
    }

    toggleSelect = () =>
    {
        const p = this.props;
        if (p.options.length !== this.selected.length)
        {
            this.setState({select: !this.state.select});
        }
    };

    handleBlur = (e: React.FocusEvent) =>
    {
        if (this.state.select && !e.currentTarget.contains(e.relatedTarget as any))
        {
            this.setState({select: false});
        }
    };

    handleSelect = e =>
    {
        const p = this.props;
        const value = e.target.dataset.value;
        const selected = this.selected;
        if (p.allSelector && value === 'select-all-options')
        {
            p.onChange?.(p.options.map(o => o[0]));
        }
        else
        {
            p.onSelect?.(value);
            p.onChange?.(selected.concat(value));
        }
        if (p.options.length === selected.length)
        {
            this.setState({select: false});
        }
    };

    handleRemove = e =>
    {
        const p = this.props;
        const value = e.target.dataset.value;
        const selected = this.selected;
        p.onRemove?.(value);
        p.onChange?.(this.selected.filter(s => s !== value));
        if (p.options.length === selected.length)
        {
            this.setState({select: false});
        }
    };

    render()
    {
        const p = this.props;
        const opts = p.allSelector && p.options.length !== p.selected.length ?
            ([['select-all-options', 'All']] as SelectOptions).concat(p.options) :
            p.options;
        return (
            <div className={'TagSelect ' + (p.className || '')} tabIndex={0} onBlur={this.handleBlur}>
                <div className={'TagSelectTop' + (p.boldName != false ? ' bold' : '')}>
                    <span className='title'>{p.name}</span>
                    <span className='add' onClick={this.toggleSelect}>+Add <FA className='fas' name='caret-down'/></span>
                </div>
                <div className='TagSelectSelect'>
                    {!!this.state.select &&
                    <ul className='list-group list'>
                        {opts.filter(o => !p.selected.includes(o[0])).map(o => (
                            <li key={o[0]} data-value={o[0]} className='list-group-item list-group-item-action' onClick={this.handleSelect}>{o[1]}</li>
                        ))}
                    </ul>}
                    {this.selected.map(s => (
                        <span key={s} className='TagSelectTag'>
                            {p.options.find(o => o[0] === s)?.[1] || s}
                            <b data-value={s} onClick={this.handleRemove}>X</b>
                        </span>
                    ))}
                </div>
            </div>
        );
    }
}
