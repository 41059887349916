import {ChangeEvent} from 'react';
import {observer} from 'mobx-react-lite';
import {orderStandStore} from './OrderStandStore';
import './OrderStandSetup.scss';
import {filterSurfaceOptions, surfaceOptions, SurfaceSectorType} from '../data/surfaceOptions';
import {FreshSpaceRange, IStandSetupFresh, IStandSetupNormal, ISurfaceDimensions} from '../../graphql/api/stand/Stand';
import {t} from '../translations';
import {projectStore} from '../stores/ProjectStore';
import { user } from '../stores/user';
import { companyStore } from '../Company/CompanyStore';
import { packageStore } from '../Packages/PackageStore';

const handleFreshRange = (newSpaceRange: FreshSpaceRange) =>
{
    const {spaceRange} = orderStandStore.selected.setup as IStandSetupFresh;
    if (newSpaceRange !== spaceRange)
    {
        orderStandStore.changeSpaceRange(newSpaceRange);
    }
};

const handleDimensionsChange = (dimensions: ISurfaceDimensions) =>
{
    orderStandStore.changeDimensions(dimensions);
};

const handleOpenStandChange = (type: 'open' | 'head' | 'corner' | null | undefined) =>
{
    orderStandStore.changeOpenStand(type);
};

export const OrderStandSetup = observer(function OrderStandSetup()
{
    const type = orderStandStore.selected.setup.type;
    return type === 'normal' ?
        <OrderStandSetupNormal/> :
        type === 'fresh' && !['FR', 'IT', 'ES'].includes(projectStore.selectedCountry) ?
            <OrderStandSetupFresh/> :
            null;
});

const previewMaxWidth = 250;
const previewMaxHeight = 150;

const OrderStandSetupNormal = observer(function OrderStandSetupNormal()
{
    return <StandSetupNormal
        setup={orderStandStore.selected.setup as IStandSetupNormal}
        sectorType={orderStandStore.selectedStandSectorType}
        minCorner={orderStandStore.userStandSurfaceMin('corner')}
        minHead={orderStandStore.userStandSurfaceMin('head')}
        minOpen={orderStandStore.userStandSurfaceMin('open')}
        onChangeSurface={orderStandStore.changeSurface}
        onDimensionsChange={handleDimensionsChange}
        onOpenStandChange={handleOpenStandChange}
    />;
});

interface StandSetupNormalProps
{
    setup: IStandSetupNormal
    sectorType: SurfaceSectorType
    minCorner?: number
    minHead?: number
    minOpen?: number
    onChangeSurface?(surface: number, dimensions: ISurfaceDimensions)
    onDimensionsChange?(dimensions: ISurfaceDimensions)
    onOpenStandChange?(type: 'open' | 'head' | 'corner' | null | undefined)
}

export const StandSetupNormal = observer(function StandSetupNormal(
    {setup, sectorType, minCorner, minHead, minOpen, onChangeSurface, onDimensionsChange, onOpenStandChange}: StandSetupNormalProps)
{
    if (!projectStore.initialLoad)
    {
        return null;
    }
    const {surface, dimensions, head, corner, open} = setup as IStandSetupNormal;
    const country = projectStore.selectedCountry;
    const allSurfaceOptions = surfaceOptions(country);
    const thisSurfaceOption = allSurfaceOptions.find(s => s.value === surface);
    const isStandard = thisSurfaceOption?.dimensions.some(d => d.length === setup.dimensions.length && d.depth === setup.dimensions.depth);
    const availableSurfaceOptions = user.moderator ?
        allSurfaceOptions :
        filterSurfaceOptions(projectStore.selected, sectorType);
    const surfaceIndex = availableSurfaceOptions.findIndex(o => o.value === surface);
    let dimensionOptions = isStandard && availableSurfaceOptions[surfaceIndex]?.dimensions || [];
    if (!user.moderator)
    {
        dimensionOptions = dimensionOptions.filter(d => !d.restricted);
    }
    const proportion = previewMaxHeight * dimensions.length / dimensions.depth;
    const proportionScale = previewMaxWidth > proportion ? 1 : previewMaxWidth / proportion;

    const handleNormalSliderChange = e =>
    {
        const option = availableSurfaceOptions[e.target.value];
        const d = option.dimensions[0];
        onChangeSurface?.(option.value, {length: d.length, depth: d.depth, unit: d.unit});
    };
    const pkg = packageStore.packages.find(p => p.id === orderStandStore.selectedPackage);
    const percentages = projectStore.selected?.fair?.percentages;

    return (
        <div className='order-stand-setup'>
            <div className='order-stand-slider'>
                <p className='surface-title'>{t.standCreation.surface}</p>
                <input
                    onChange={handleNormalSliderChange}
                    type='range'
                    min={0}
                    step={1}
                    max={availableSurfaceOptions.length - 1}
                    value={surfaceIndex}
                />
                <p className='surface-counter'>{surface + ' m²' + (surface <= 6 ? ` ${t.standCreation.sharedStand}` : '')}</p>
            </div>
            {isStandard &&
            <div className='d-flex justify-content-center stand-look' style={{height: (previewMaxHeight * proportionScale) + 'px'}}>
                <div>
                    <div
                        className={'stand-skeleton ' + (head ? 'head-stand' : corner || surface <= 6 ? 'corner-stand' : open ? 'open-stand' : '')}
                        style={{width: (proportion * proportionScale) + 'px'}}
                    />
                    <div className='text-center'>{dimensions.length + ' ' + t.global.meters}</div>
                </div>
                <div className='ml-2 stand-depth'>{dimensions.depth + ' ' + t.global.meters}</div>
            </div>
            }
            {thisSurfaceOption?.shared ? (
                <div className='col-md-4'>
                    {t.standCreation.setup.sharedStand}
                </div>
            ) : (
                <div className='order-stand-options'>
                    <p className='surface-title'>{t.standCreation.setup.question}</p>
                    <form className='dimensions'>
                        {dimensionOptions.map(d =>
                            <RadioButton
                                key={d.depth}
                                value={`${d.length}x${d.depth}`}
                                name='size'
                                text={`${d.length}x${d.depth} m`}
                                checked={dimensions.depth == d.depth}
                                onChange={onDimensionsChange ? () => onDimensionsChange(d) : null}
                            />
                        )}
                    </form>
                    {surface >= minCorner &&
                    <form className='stand-type'>
                        <RadioButton
                            name='type'
                            disabled={!user.moderator && pkg?.cornerHeadOpenOnly}
                            value=''
                            text={t.standCreation.setup.classicStand}
                            checked={!corner && !head && !open && (user.moderator || !pkg?.cornerHeadOpenOnly)}
                            onChange={onOpenStandChange ? () => onOpenStandChange(null) : null}
                        />
                        <RadioButton
                            name='type'
                            value='corner'
                            text={t.standCreation.setup.cornerStand(Math.round((percentages?.corner - 1) * 100))}
                            checked={corner}
                            onChange={onOpenStandChange ? () => onOpenStandChange('corner') : null}
                        />
                        {surface >= minHead &&
                        <RadioButton
                            name='type'
                            value='head'
                            text={t.standCreation.setup.headStand(Math.round((percentages?.head - 1) * 100))}
                            checked={head}
                            onChange={onOpenStandChange ? () => onOpenStandChange('head') : null}
                        />}
                        {surface >= minOpen && country === 'FR' &&
                        <RadioButton
                            name='type'
                            value='open'
                            text={t.standCreation.setup.openStand(Math.round((percentages?.open - 1) * 100))}
                            checked={open}
                            onChange={onOpenStandChange ? () => onOpenStandChange('open') : null}
                        />}
                    </form>}
                </div>
            )}
        </div>
    );
});

const OrderStandSetupFresh = observer(function OrderStandSetupFresh()
{
    const isItaly = projectStore.selectedCountry == 'IT';
    const sector = companyStore.companies.find(c => c.id == orderStandStore.selected.companyId)?.exhibitor.sector;
    const primarySector = sector?.primary?.split('-');
    const secondarySector = sector?.secondary?.split('-');

    const italyCase = isItaly && primarySector && secondarySector && (
        (primarySector.includes('PFT') && secondarySector.includes('PLS')) ||
        (primarySector.includes('PLS') && secondarySector.includes('PFT')));

    return <StandSetupFresh
        setup={orderStandStore.selected.setup as IStandSetupFresh}
        italyCase={italyCase}
        onFreshRange={handleFreshRange}
    />;
});

interface StandSetupFreshProps
{
    setup: IStandSetupFresh
    italyCase?: boolean
    onFreshRange?(spaceRange: FreshSpaceRange)
}

export const StandSetupFresh = observer(function StandSetupFresh({setup, italyCase, onFreshRange}: StandSetupFreshProps)
{
    const {spaceRange} = setup;
    const country = projectStore.selectedCountry;
    const isItaly = country == 'IT';

    return (<>
        <span className='font-weight-bold'>{t.standCreation.setup.selectRange}</span>
        <div className='pt-4 pb-5'>
            <button
                className={'mr-4 mb-4 ' + ('low' == spaceRange ? 'button' : 'button-empty')}
                onClick={onFreshRange ? () => onFreshRange('low') : null}
            >
                {t.standCreation.setup.lowRange[country]?.(isItaly ? italyCase ? 15 : 10 : 15)}
            </button>
            <button
                data-value='high'
                className={'high' == spaceRange ? 'button' : 'button-empty'}
                onClick={onFreshRange ? () => onFreshRange('high') : null}
            >
                {t.standCreation.setup.highRange[country]?.(isItaly ? italyCase ? 15 : 10 : 15, isItaly ? italyCase ? 30 : 20 : 15)}
            </button>
        </div>
    </>);
});

interface IRadioButtonProps
{
    name: string
    value: string
    text: string
    checked: boolean
    disabled?: boolean
    onChange?(e: ChangeEvent<HTMLInputElement>): void
}

const RadioButton = (p: IRadioButtonProps) => (
    <div className='d-flex align-items-center'>
        <input
            disabled={p.disabled}
            className='mr-2'
            id={p.name + p.value}
            type='radio'
            checked={p.checked}
            name={p.name}
            value={p.value}
            onChange={p.onChange}
        />
        <label className='flex-grow-1' htmlFor={p.name + p.value}>{p.text}</label>
    </div>
);
