import React from 'react';
import * as Sentry from '@sentry/browser';
import {t} from '../translations';

interface Props
{
    fallback?: React.ReactNode
}

interface State
{
    hasError?: boolean;
    error?: string;
    eventId?: string;
}

export class ErrorBoundary extends React.Component<React.PropsWithChildren<Props>, State>
{
    static getDerivedStateFromError(error)
    {
        return {hasError: true, error: error.toString()};
    }

    state: State = {};

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo)
    {
        Sentry.withScope(scope =>
        {
            scope.setExtras(errorInfo as any);
            const eventId = Sentry.captureException(error);
            this.setState({eventId});
        });
    }

    render()
    {
        if (this.state.hasError)
        {
            return this.props.fallback || (
                <div className='p-3'>
                    <h3>{t.global.somethingWentWrong}</h3>
                    <button className='button' onClick={() => Sentry.showReportDialog({eventId: this.state.eventId})}>
                        {t.global.somethingWentWrongFeedback}
                    </button>
                </div>
            );
        }
        return this.props.children;
    }
}
