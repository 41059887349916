import React from 'react';

interface Props
{
    className?: string
    onClick?(): void
}

interface State
{
    active?: boolean
}

export default class DangerButton extends React.PureComponent<React.PropsWithChildren<Props>, State>
{
    state: State = {};

    handleClick = () =>
    {
        const active = this.state.active;
        this.setState({active: !active});
        if (active)
        {
            this.props.onClick?.();
        }
    };

    render()
    {
        return (
            <button
                className={(this.state.active ? 'button-error px-4' : 'button-empty') + ' ' + (this.props.className || '')}
                onClick={this.handleClick}
            >
                {this.props.children}
                {this.state.active ? ' ?' : null}
            </button>
        );
    }
}
