import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import {companyStore} from './CompanyStore';

interface Props
{
    id: string
}

export default observer(function DeleteCompany({id}: Props)
{
    const navigate = useNavigate();

    const handleDelete = () =>
    {
        if (confirm('Are you sure you want to delete this company and all of its info?'))
        {
            companyStore.delete(id).then(res =>
            {
                if (res)
                {
                    alert(res);
                }
                else
                {
                    navigate('/');
                }
            });
        }
    };

    return (
        <div className='mt-5'>
            <h5 className='pb-2 mb-4 border-bottom border-dark'>Delete company</h5>
            <button onClick={handleDelete} className='button-empty'>Delete Company</button>
        </div>
    );
});

