import {useLocation} from 'react-router';
import {isID} from '../../lib/common';

export function useHash()
{
    return decodeURIComponent(useLocation().hash.substring(1));
}

export function useHashId()
{
    const hash = useHash().split('/')[0];
    return isID(hash) ? hash : '';
}

export function useNewStandCompanyId()
{
    const hash = useHash();
    if (hash.startsWith('new/'))
    {
        const id = hash.substring(4);
        return isID(id) ? id : '';
    }
    return '';
}
