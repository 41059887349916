import moment from 'moment';
import {user} from '../stores/user';

// moment.locale(navigator.language);

moment.relativeTimeThreshold('ss', 3);
moment.relativeTimeThreshold('s', 40);
moment.relativeTimeThreshold('m', 40);
moment.relativeTimeThreshold('h', 20);
moment.relativeTimeThreshold('d', 25);
moment.relativeTimeThreshold('M', 10);

export function formatRelativeTime(date: Date)
{
    user.locale;
    const now = new Date();
    return moment(+date < +now ? date : now).fromNow();
}

export function formatTimelineDate(date: Date)
{
    user.locale;
    if (moment(date).isSame(new Date(), 'week'))
    {
        return formatRelativeTime(date);
    }
    else if (!moment(date).isSame(new Date(), 'year'))
    {
        return moment(date).format('D MMM Y, H:mm');
    }
    else
    {
        return moment(date).format('D MMM, H:mm');
    }
}
