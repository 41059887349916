// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RotationArrows-container:hover .RotationArrows {
  opacity: 1;
}

.RotationArrows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.RotationArrows * {
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
  pointer-events: initial;
  background-color: rgba(0, 0, 0, 0.1);
}
.RotationArrows *:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
@media (hover: none) {
  .RotationArrows {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./frontend/Pages/RotationArrows.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,UAAA;EACA,oCAAA;AADF;AAEE;EACE,6CAAA;EACA,UAAA;EACA,uBAAA;EACA,oCAAA;AAAJ;AACI;EACE,oCAAA;AACN;AAEE;EAtBF;IAuBI,UAAA;EACF;AACF","sourcesContent":[".RotationArrows-container:hover {\n  .RotationArrows {\n    opacity: 1;\n  }\n}\n\n.RotationArrows {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 2rem;\n  color: #fff;\n  opacity: 0;\n  transition: opacity .3s ease-in-out;\n  * {\n    transition: background-color .3s ease-in-out;\n    z-index: 1;\n    pointer-events: initial;\n    background-color: rgba(0, 0, 0, .1);\n    &:hover {\n      background-color: rgba(0, 0, 0, .2);\n    }\n  }\n  @media (hover: none) {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
