import {action, computed, makeObservable, observable} from 'mobx';
import {
    callLogout,
    callRegister,
    loadUser,
    UserInfo,
    updatePerson,
    acceptPrivacyPolicy,
    requestWSToken,
    callAdvancedSwitchAccount,
    updatePersonLanguage,
} from './userProxy';
import {
    tryGetUserInfo,
    tryGetVerified,
    trySetUserInfo,
    trySetVerified,
    trySetLandingLanguage,
    tryGetLandingLanguage,
    tryGet,
    trySet,
    tryGetAdvancedSwitch,
    trySetAdvancedSwitch,
} from './userPersist';
import {client} from '../client';
import {getInitials, getName} from '../common/getName';
import {companyStore} from '../Company/CompanyStore';
import {isFreshSector} from '../data/sectors';
import {Language} from '../../graphql/api/Types';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import {projectStore} from './ProjectStore';
import {countryStore} from './CountryStore';
import {formatCurrency} from '../../lib/currency';
import {contactsStore} from '../Contact/ContactsStore';
import {languageList} from '../data/language';
import {findProfile} from '../data/profiles';
import {IPersonPrivacy, RegisterData} from '../../graphql/api/user/User';
import {privacyPolicyStore} from '../Disclaimer/PrivacyPolicyStore';
import {wsConnection} from '../ws/WsConnection';
import config from '../config';
import { eventStore } from '../Event/EventStore';
import {registerGlobalEventHandler, triggerGlobalEvent} from './globalEvents';
import {pickLanguage} from './utility';

class UserStore
{
    @observable personId = tryGet('personId');
    @observable verified = tryGetVerified();
    private advancedSwitch = tryGetAdvancedSwitch();
    @observable advancedSwitching = false;
    @observable info = tryGetUserInfo();
    @observable loaded = false;
    @observable overrideLanguage: Language = tryGetLandingLanguage();

    constructor()
    {
        makeObservable(this);
    }

    get id()
    {
        return this.personId;
    }

    get loggedIn()
    {
        return !!this.personId && this.verified;
    }

    get loginMethod()
    {
        return this.info?.loginMethod;
    }

    get company()
    {
        return this.info?.company || [];
    }

    @computed get name()
    {
        return getName(this.info);
    }

    @computed get initials()
    {
        return getInitials(this.info);
    }

    get language(): Language
    {
        return this.info?.language || this.overrideLanguage || browserLanguage();
    }

    get admin()
    {
        return !!this.info?.admin;
    }

    get organization()
    {
        return !!this.info?.organization;
    }

    get tester()
    {
        return !!this.info?.tester;
    }

    get support()
    {
        return !!this.info?.support;
    }

    get moderator()
    {
        return this.admin || this.organization;
    }

    get eventAdmin()
    {
        return this.admin || (this.organization && !!this.info.eventAdmin);
    }

    get deleteAccess()
    {
        return this.admin || (this.organization && !!this.info.deleteAccess);
    }

    get archivedExhibitor()
    {
        return !this.moderator && projectStore.archived;
    }

    get viewOnlyExhibitor()
    {
        return !this.moderator && !this.info?.profiles?.some(p => p.id == 'exhibitorMainAccount' || p.id == 'exhibitorKeyAccount');
    }

    get profile()
    {
        return this.info?.profiles?.[0]?.id;
    }

    @computed
    get isGuest()
    {
        return !!this.info?.profiles?.some(p => p.id === 'guest');
    }

    @computed
    get isMedia()
    {
        return !!this.info?.profiles?.some(p => p.id === 'media');
    }

    get isGuestOrMedia()
    {
        return this.isGuest || this.isMedia;
    }

    @computed
    get isStore()
    {
        return !!this.info?.profiles?.some(pr => findProfile(pr.id)?.features?.mobile?.store?.length);
    }

    @computed
    get locale()
    {
        return this.info && this.info.language && this.info.country?.[0] ?
            this.info.language + '-' + this.info.country[0] :
            this.overrideLanguage ?
                this.overrideLanguage + '-' + navigator.language.split('-')[1]
                :
                navigator.language;
    }

    @computed
    get isExhibitor()
    {
        return this.info?.profiles?.[0]?.id.includes('exhibitor');
    }

    @computed
    get isCarrefourGroup()
    {
        return !!this.info?.profiles?.some(p => p.id === 'carrefourGroup');
    }

    @computed
    get isHostess()
    {
        return !!this.info?.profiles?.some(p => p.id === 'hostess');
    }

    @computed
    get isHeadquarter()
    {
        return !!this.info?.profiles?.some(p => p.id === 'headquarter');
    }

    @computed
    get projectBadge()
    {
        return this.info?.badges?.find(b => b.project == projectStore.id);
    }

    get restrictedFairParticipation()
    {
        return !(this.moderator || this.isExhibitor);
        // return this.isGuestOrMedia || (this.isHeadquarter && !this.projectBadge?.crfDatesManager);
    }

    @computed
    get hqReports()
    {
        return !!this.info?.hqReports &&
            (this.isHeadquarter || this.isCarrefourGroup) &&
            (!projectStore.selectedCountry || projectStore.selectedCountry == 'IT');
    }

    get isModeratorOrHostess()
    {
        return this.moderator || this.isHostess;
    }


    formatCurrency(currency: string, num: number)
    {
        try
        {
            return formatCurrency(this.locale, currency, num);
        }
        catch (e)
        {
            return '???';
        }
    }

    @computed
    get hasFreshSector()
    {
        if (this.moderator)
        {
            return true;
        }
        if (companyStore.ownCompany)
        {
            return isFreshSector(companyStore.ownCompany.exhibitor.sector?.primary) ||
                isFreshSector(companyStore.ownCompany.exhibitor.sector?.secondary);
        }
        else
        {
            // companyStore.loadCompany();
            return false;
        }
    }

    @action
    setVerified(verified: boolean)
    {
        trySetVerified(this.verified = verified);
    }

    reloadUser()
    {
        return loadUser().then(action('reloadUser', ({data, errors}) =>
        {
            const userData = data?.user;
            if (this.verified && !userData && !errors)
            {
                this.clearPersisted();
            }
            if (userData)
            {
                const {selectedProject, ...info} = userData;
                if (!this.verified)
                {
                    this.setVerified(true);
                    initialLoad();
                }
                if (!this.info)
                {
                    this.info = info;
                }
                else
                {
                    Object.assign(this.info, info);
                }
                this.loaded = true;
                this.saveUserInfo();
                privacyPolicyStore.addToUser();
                Sentry.captureMessage('Load', 'info');
                projectStore.handleProjectIdLoad(selectedProject);
            }
        }));
    }

    register(input: RegisterData)
    {
        return callRegister({
            ...input,
            stores: input.stores ? input.stores.map(s => s.id) : null,
        });
    }

    async logout()
    {
        if (!this.loggedIn)
        {
            return;
        }
        const {errors} = await callLogout();
        if (this.advancedSwitch)
        {
            this.advancedSwitching = true;
            trySet('personId', this.personId = this.advancedSwitch);
            trySetAdvancedSwitch(this.advancedSwitch = null);
            trySetUserInfo(this.info = null);
            location.reload();
        }
        else
        {
            this.clearPersisted();
            client.authorization = null;
        }
        return errors;
    }

    advancedSwitchAccount(id: string)
    {
        callAdvancedSwitchAccount(id).then(action(res =>
        {
            if (res)
            {
                this.advancedSwitching = true;
                trySetAdvancedSwitch(this.advancedSwitch = this.personId);
                this.saveAuthorization(res);
                trySetUserInfo(this.info = res.user);
                location.reload();
            }
        }));
    }

    @action
    changeLanguage(language: Language)
    {
        this.info = this.info || {} as any;
        this.info.language = language;
        this.saveUserInfo();
        this.updatePersonLanguage(null, language);
    }

    @action
    changeLandingLanguage(language: Language)
    {
        this.overrideLanguage = language;
        trySetLandingLanguage(this.overrideLanguage);
        moment.locale(this.language);
    }

    updatePerson(id: string, info: UserInfo)
    {
        updatePerson({
            id,
            firstName: info.firstName,
            lastName: info.lastName,
            image: info.image,
            language: info.language || 'en',
            profiles: info.profiles,
            company: info.company,
            companyName: info.companyName,
            country: info.country,
        }).then(action('updatePerson', res =>
        {
            if (res)
            {
                if (id)
                {
                    const contact = contactsStore.contacts.find(c => c.id === id);
                    if (contact)
                    {
                        Object.assign(contact, res);
                    }
                    if (info.profiles.some(p => p.id == 'exhibitorMainAccount') && !res.profiles.some(p => p.id == 'exhibitorMainAccount'))
                    {
                        const profile = findProfile('exhibitorMainAccount');
                        alert(`Company can have only 1 ${pickLanguage(profile.name)} user`);
                    }
                }
                if (!id || id == this.id)
                {
                    Object.assign(this.info, res);
                    this.saveUserInfo();
                }
            }
        }));
    }

    updatePersonLanguage(id: string, language: Language)
    {
        updatePersonLanguage({
            id,
            language: language || 'en',
        }).then(action('updatePersonLanguage', res =>
        {
            if (res)
            {
                if (id)
                {
                    const contact = contactsStore.contacts.find(c => c.id === id);
                    if (contact)
                    {
                        contact.language = res;
                    }
                }
                if (!id || id == this.id)
                {
                    this.info.language = res;
                    this.saveUserInfo();
                }
            }
        }));
    }

    acceptPrivacyPolicy(privacy: Omit<IPersonPrivacy, 'date'>)
    {
        acceptPrivacyPolicy(privacy).then(action(res =>
        {
            if (res)
            {
                this.info.privacy = res;
                this.saveUserInfo();
            }
        }));
    }

    @action
    saveUserInfo()
    {
        moment.locale(this.language);
        trySetUserInfo(this.info);
        sentrySetUser();
    }

    @action
    saveAuthorization(login: {personId: string})
    {
        trySet('personId', this.personId = login.personId);
    }

    @action
    private clearPersisted()
    {
        trySet('personId', this.personId = null);
        trySetVerified(this.verified = false);
        trySetUserInfo(this.info = null);
        triggerGlobalEvent({type: 'logout'});
    }
}

function browserLanguage(): Language
{
    const browserLang = navigator.language.split('-')[0] as Language;
    return languageList.includes(browserLang) ? browserLang : 'en';
}

export const user = new UserStore();

export const colon = () => user.language == 'fr' ? ' : ' : ': ';

// @ts-ignore
if (typeof __STORYBOOK_ADDONS === 'undefined')
{
    countryStore.reload();
    moment.locale(user.language);
    if (user.loggedIn)
    {
        sentrySetUser();
        user.reloadUser();
        initialLoad();
    }
}

export function initialLoad()
{
    // companyStore.loadCompany();
    // projectStore.reload();
    eventStore.loadEvents();
    if (!wsConnection.open)
    {
        wsConnect();
    }
}

registerGlobalEventHandler('logout', () =>
{
    wsConnection.close();
});

function wsConnect()
{
    wsConnection.connect(config.wsEndpoint);
    wsConnection.addOpenListener(c =>
    {
        requestWSToken().then(res =>
        {
            c.json({origin: location.origin, ...res});
        });
    });
}

function sentrySetUser()
{
    if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1' && user.id)
    {
        Sentry.setUser({
            id: user.id,
            email: user.info?.email,
            phone: user.info?.phone,
            username: user.name,
        });
    }
}

// check for changes from other tabs
window.addEventListener('storage', action(({key, newValue}) =>
{
    if (key === 'verified')
    {
        const persistedState = tryGetVerified();
        if (persistedState != user.verified)
        {
            if (persistedState)
            {
                // Logged In from another tab
                user.verified = true;
                initialLoad();
                setTimeout(() =>
                {
                    if (!user.info)
                    {
                        user.reloadUser();
                    }
                }, 50);
            }
            else
            {
                // Logged Out from another tab
                user.verified = false;
                user.personId = null;
                client.authorization = null;
            }
        }
    }
    else if (key === 'personId')
    {
        user.personId = newValue;
    }
    else if (key === 'user_info')
    {
        const info = tryGetUserInfo();
        if (!user.info)
        {
            user.info = info;
        }
        else
        {
            Object.assign(user.info, info);
        }
    }
    else if (key === 'landing_lang')
    {
        user.overrideLanguage = tryGetLandingLanguage();
        moment.locale(user.language);
    }
}));
