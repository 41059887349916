import {forwardRef, lazy, Suspense} from 'react';
import {Loading} from '../common/Loading';
import type {HighchartsReactProps, HighchartsReactRef} from './HighchartsReactBundled';
const HighchartsReactBundled = lazy(() => import('./HighchartsReactBundled'));

export default forwardRef(function HighchartsReactLazy(props: HighchartsReactProps, ref: HighchartsReactRef)
{
    return (
        <Suspense
            fallback={
                <div style={{width: props.options?.chart?.width ? props.options.chart.width + 'px' : null, height: '400px', position: 'relative'}}>
                    <Loading/>
                </div>
            }
        >
            <HighchartsReactBundled ref={ref} {...props}/>
        </Suspense>
    );
});
