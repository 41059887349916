import React from 'react';
import {observer} from 'mobx-react-lite';
import './PriceInfo.scss';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {colon} from '../stores/user';
import {t} from '../translations';
import {projectStore} from '../stores/ProjectStore';
import {pickLanguage} from '../stores/utility';

interface Props
{
    product: ICatalogProduct
    storeId: string
}

export const InfoRows = observer(function InfoRows({product, storeId}: Props)
{
    const info = product.info.length ? product.info.find(ps => ps.storeId == storeId) || product.info[0] : null;
    if (!info?.infoRows?.length)
    {
        return null;
    }
    return (
        <div className='InfoRows'>
            {info.infoRows.map((r, ri) =>
                <div key={ri}>
                    {r.map((c, ci) =>
                        <span key={ci} className='d-inline-block mr-4'>{c.name ? c.name + colon() : null}{c.value}</span>
                    )}
                </div>
            )}
            {!!product.package && <div>Astra: {product.package.map(p => p.code.article).join(', ')}</div>}
        </div>
    );
});

export const ExtraRows = observer(function ExtraRows({product, storeId}: Props)
{
    const info = product.info.length ? product.info.find(ps => ps.storeId == storeId) || product.info[0] : null;
    if (!info?.extraRows?.length)
    {
        return null;
    }
    return (
        <div className='FRExtraRows'>
            {info.extraRows.map((i, index) =>
                <p key={index}>
                    {i.name ? i.name + colon() : null}
                    <span className={i.name ? 'font-weight-bold' : null}>{i.value}</span>
                </p>
            )}
        </div>
    );
});

export const PriceRows = observer(function PriceRows({product, storeId}: Props)
{
    const info = product.info.length ? product.info.find(ps => ps.storeId == storeId) || product.info[0] : null;
    if (!info?.priceRows?.length)
    {
        return null;
    }
    return (
        <div className='PriceRows'>
            {info.priceRows.map((r, ri) =>
                <div key={ri}>
                    {r.map((c, ci) =>
                        c.unit ?
                            <span key={ci} className='d-inline-block mr-3'>
                                <b className='d-inline-block'>{c.value} / {t.catalog.unit}</b>
                            </span>
                            :
                            <span key={ci} className='d-inline-block mr-3' style={c.color ? {color: c.color} : null}>
                                {c.name ? c.name + colon() : null}<b className='d-inline-block'>{c.value}</b>
                            </span>
                    )}
                </div>
            )}
        </div>
    );
});

export const ProductComment = observer(function ProductComment({product, storeId}: Props)
{
    const comment = projectStore.selectedCountry == 'PL' ?
        product.stores.find(s => s.storeId == storeId)?.comment :
        product.comment || pickLanguage(product.details);
    if (!comment)
    {
        return null;
    }
    return (
        <div className='ProductComment'>{comment}</div>
    );
});

export const FRInfoRows = observer(function FRInfoRows({product, storeId}: Props)
{
    const info = product.info.length ? product.info.find(ps => ps.storeId == storeId) || product.info[0] : null;
    if (!info?.infoRows?.length)
    {
        return null;
    }
    return (
        <div className='FRInfoRows'>
            {info.infoRows.map((r, ri) =>
                <React.Fragment key={ri}>
                    {r.map((c, ci) =>
                        <p key={ci} className={'mr-4' + (c.bold ? ' font-weight-bold' : '')}>
                            {c.name ? c.name + colon() : null}{c.value}
                        </p>
                    )}
                </React.Fragment>
            )}
        </div>
    );
});

export const FRPriceRow = observer(function FRPriceRow({product, storeId}: Props)
{
    const info = product.info.length ? product.info.find(ps => ps.storeId == storeId) || product.info[0] : null;
    if (!info?.priceRows?.length)
    {
        return null;
    }
    return (
        <div className='FRPriceRow'>
            {info.priceRows.map((r, ri) =>
                <React.Fragment key={ri}>
                    {r.map((c, ci) =>
                        <div key={ci} style={c.color ? {color: c.color} : null}>
                            <div>{c.name ? c.name + colon() : null}</div>
                            <div className='font-weight-bold'>{c.value}</div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    );
});

export const FRProductImage = observer(function FRProductImage({product, storeId}: Props)
{
    let img: string;
    const storeWeeks = product.stores.find(s => s.storeId == storeId)?.weeks;
    const messageImage = storeWeeks?.find(w => w.messageImage)?.messageImage;
    if (messageImage)
    {
        img = messageImage;
    }
    else
    {
        const stars = storeWeeks?.find(w => w.stars)?.stars;
        if (stars == 4)
        {
            img = '4.jpg';
        }
        else if (stars == 3)
        {
            img = '3.jpg';
        }
    }
    if (!img)
    {
        return null;
    }
    return (
        <img className='FRProductImage' src={'https://yourfair-pub.s3-eu-west-1.amazonaws.com/assets/product/fr/' + img} alt=''/>
    );
});

export const FRMarketStars = observer(function FRMarketStars({product, storeId}: Props)
{
    const finSupplier = product.stores.find(s => s.storeId == storeId)?.weeks.find(w => w.finSupplier)?.finSupplier;
    if (!finSupplier)
    {
        return null;
    }
    const stars = [];
    for (let i = 0; i < finSupplier; ++i)
    {
        stars.push(
            <img
                key={i}
                className='FRMarketStar'
                src={'https://yourfair-pub.s3-eu-west-1.amazonaws.com/assets/product/fr/star.svg'}
                alt=''
            />
        );
    }
    return (
        <div>
            <span>Financement Fournisseur :</span>
            <div className='d-inline-block'>{stars}</div>
        </div>
    );
});
