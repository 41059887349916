// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M7dLnIsOWH81kSw2b8_y, .R3GKC0XDvRB0JoZuLmTt {
  margin-top: 15px;
  margin-bottom: 20px;
}
.M7dLnIsOWH81kSw2b8_y th:not(:first-child), .M7dLnIsOWH81kSw2b8_y td:not(:first-child), .R3GKC0XDvRB0JoZuLmTt th:not(:first-child), .R3GKC0XDvRB0JoZuLmTt td:not(:first-child) {
  padding-left: 8px;
}
.M7dLnIsOWH81kSw2b8_y th:not(:last-child), .M7dLnIsOWH81kSw2b8_y td:not(:last-child), .R3GKC0XDvRB0JoZuLmTt th:not(:last-child), .R3GKC0XDvRB0JoZuLmTt td:not(:last-child) {
  padding-right: 8px;
}
@media only screen and (max-width: 576px) {
  .M7dLnIsOWH81kSw2b8_y, .R3GKC0XDvRB0JoZuLmTt {
    margin-top: 3em;
  }
}
@media only screen and (max-width: 500px) {
  .M7dLnIsOWH81kSw2b8_y > *, .R3GKC0XDvRB0JoZuLmTt > * {
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 380px) {
  .M7dLnIsOWH81kSw2b8_y > *, .R3GKC0XDvRB0JoZuLmTt > * {
    font-size: 0.7em;
  }
}

.R3GKC0XDvRB0JoZuLmTt th {
  padding-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./frontend/StoreCatalog/ProductDisplayPackage.module.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,mBAAA;AADF;AAEE;EACE,iBAAA;AAAJ;AAEE;EACE,kBAAA;AAAJ;AAEE;EATF;IAWI,eAAA;EAAF;AACF;AACE;EAEE;IACE,gBAAA;EAAJ;AACF;AAEE;EAEE;IACE,gBAAA;EADJ;AACF;;AAKE;EACE,oBAAA;AAFJ","sourcesContent":["@import 'frontend/variables';\n\n.DisplayItems, .PackageItems {\n  margin-top: 15px;\n  margin-bottom: 20px;\n  th:not(:first-child), td:not(:first-child) {\n    padding-left: 8px;\n  }\n  th:not(:last-child), td:not(:last-child) {\n    padding-right: 8px;\n  }\n  @media only screen and (max-width: $somePhones)\n  {\n    margin-top: 3em;\n  }\n  @media only screen and (max-width: 500px)\n  {\n    > * {\n      font-size: .8em;\n    }\n  }\n  @media only screen and (max-width: 380px)\n  {\n    > * {\n      font-size: .7em;\n    }\n  }\n}\n.PackageItems {\n  th {\n    padding-bottom: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DisplayItems": `M7dLnIsOWH81kSw2b8_y`,
	"PackageItems": `R3GKC0XDvRB0JoZuLmTt`
};
export default ___CSS_LOADER_EXPORT___;
