// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-brand {
  color: #3d8af7 !important;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 30px;
}

@media screen and (max-width: 360px) {
  .navbar-brand {
    font-size: 20px;
  }
}
.LandingTop {
  margin-top: 2em;
}

.login-popup {
  right: 0;
  left: initial;
  width: 400px;
  max-width: 90vw;
}

.language-popup {
  right: 0;
  left: initial;
}

.signin {
  background: #92d36e;
  border-color: #92d36e;
}
.signin:focus {
  box-shadow: none;
  outline: none;
}

.language-select-landing {
  margin-right: 1em;
}

.navbar-nav .login-popup button {
  font-size: 17px !important;
  padding: 5px 2rem;
}
.navbar-nav .minify-font button {
  font-size: 14px !important;
}`, "",{"version":3,"sources":["webpack://./frontend/Landing/LandingNavbar.scss"],"names":[],"mappings":"AACA;EACE,yBAAA;EACA,mCAAA;EACA,iBAAA;EACA,eAAA;AAAF;;AAEA;EACE;IACE,eAAA;EACF;AACF;AAEA;EACE,eAAA;AAAF;;AAGA;EACE,QAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;AAAF;;AAGA;EACE,QAAA;EACA,aAAA;AAAF;;AAEA;EACE,mBAAA;EACA,qBAAA;AACF;AAAE;EACE,gBAAA;EACA,aAAA;AAEJ;;AAEA;EACE,iBAAA;AACF;;AAII;EACE,0BAAA;EACA,iBAAA;AADN;AAKI;EACE,0BAAA;AAHN","sourcesContent":["\n.navbar-brand {\n  color: #3d8af7 !important;\n  font-family: Montserrat, sans-serif;\n  font-weight: bold;\n  font-size: 30px;\n}\n@media screen and (max-width: 360px) {\n  .navbar-brand {\n    font-size: 20px;\n  }\n}\n\n.LandingTop {\n  margin-top: 2em;\n}\n\n.login-popup {\n  right: 0;\n  left: initial;\n  width: 400px;\n  max-width: 90vw;\n}\n\n.language-popup {\n  right: 0;\n  left: initial;\n}\n.signin {\n  background: #92d36e;\n  border-color: #92d36e;\n  &:focus {\n    box-shadow: none;\n    outline: none;\n  }\n}\n\n.language-select-landing {\n  margin-right: 1em;\n}\n\n.navbar-nav {\n  .login-popup {\n    button {\n      font-size: 17px !important;\n      padding: 5px 2rem;\n    }\n  }\n  .minify-font {\n    button {\n      font-size: 14px !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
