import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useLocation} from 'react-router';
import {Link, useNavigate} from 'react-router-dom';
import FA from 'react-fontawesome';
import './OrdersPage.scss';
import {user} from '../stores/user';
import {companyStore} from '../Company/CompanyStore';
import {Loading} from '../common/Loading';
import {call, gql} from '../client';
import {IAllOrdersInfoResult, IOrderedProductResult} from '../../graphql/api/order/Order';
import {t} from '../translations';
import {pickLanguage} from '../stores/utility';
import DeliveryMonthSelect from './DeliveryMonthSelect';
import DeliveryWeekSelect from './DeliveryWeekSelect';
import CartOrderTotal from './CartOrderTotal';
import OrderProductView from './OrderProductView';

export default observer(function OrdersPageAllProjects()
{
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const deliveryMonth = query.get('deliveryMonth');
    const deliveryWeek = query.get('deliveryWeek');

    const [selectedCompany, selectCompany] = useState<string>(() => user.info?.company[0]);
    const ownStores = companyStore.ownStores;
    const store = ownStores.find(c => c.id == selectedCompany);
    const storeIndex = ownStores.findIndex(c => c.id == selectedCompany);

    const storeId = store?.store?.id;
    const storeType = store?.store?.storeType;

    const [loading, setLoading] = useState(true);
    const [allOrdersInfo, setAllOrdersInfo] = useState<IAllOrdersInfoResult>();
    useEffect(() =>
    {
        if (!storeId)
        {
            return;
        }
        setLoading(true);
        loadAllOrdersInfo({storeId, deliveryMonth, deliveryWeek}).then(data =>
        {
            setLoading(false);
            setAllOrdersInfo(data);
        });
    }, [storeId, deliveryMonth, deliveryWeek]);

    if (loading)
    {
        return (
            <div style={{height: '440px', position: 'relative'}}>
                <Loading/>
            </div>
        );
    }
    if (!allOrdersInfo)
    {
        return <h3>{t.global.somethingWentWrong}</h3>;
    }

    const {total, deliveryInfo, projects} = allOrdersInfo;

    return (
        <div className='OrdersPage container'>
            <div>
                <DeliveryMonthSelect
                    className='mb-3'
                    deliveryMonths={deliveryInfo.deliveryMonths}
                    deliveryMonth={deliveryMonth}
                    setDeliveryMonth={selectedDeliveryMonth =>
                    {
                        setAllOrdersInfo(null);
                        if (selectedDeliveryMonth)
                        {
                            query.set('deliveryMonth', selectedDeliveryMonth);
                        }
                        else
                        {
                            query.delete('deliveryMonth');
                        }
                        query.delete('deliveryWeek');
                        navigate('?' + query.toString());
                    }}
                />
                <DeliveryWeekSelect
                    className='mb-3'
                    deliveryWeeks={deliveryInfo.deliveryWeeks}
                    deliveryWeek={deliveryWeek}
                    setDeliveryWeek={selectedDeliveryWeek =>
                    {
                        setAllOrdersInfo(null);
                        if (selectedDeliveryWeek)
                        {
                            query.set('deliveryWeek', selectedDeliveryWeek);
                        }
                        else
                        {
                            query.delete('deliveryWeek');
                        }
                        query.delete('deliveryMonth');
                        navigate('?' + query.toString());
                    }}
                />
                <div className='StoreOrderCard'>
                    <p className='store'>
                        {storeIndex > 0 &&
                        <FA
                            name='chevron-left'
                            className='fas text-secondary cursor-pointer px-2'
                            onClick={() => selectCompany(ownStores[storeIndex - 1].id)}
                        />}
                        <span className='store-name'>{storeId} {store?.name}</span>
                        {storeIndex < ownStores.length - 1 &&
                        <FA
                            name='chevron-right'
                            className='fas text-secondary cursor-pointer px-2'
                            onClick={() => selectCompany(ownStores[storeIndex + 1].id)}
                        />}
                    </p>
                    <CartOrderTotal
                        storeType={storeType}
                        {...total}
                    />
                </div>
            </div>
            <div className='exhibitors'>
                <h1>{t.global.navBar.orders}</h1>
                <Link to='/financial-report' className='d-inline-block mb-4'>{t.order.toYourFinancialReport}</Link>
                {projects.map(p =>
                    <ProjectOrderProducts key={p.id} project={p} storeId={storeId} storeType={storeType} deliveryMonth={deliveryMonth} deliveryWeek={deliveryWeek}/>
                )}
            </div>
        </div>
    );
});

const ProjectOrderProducts = observer(function ProjectOrderProducts({project: {id: projectId, name}, storeId, storeType, deliveryMonth, deliveryWeek}: any)
{
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState<IOrderedProductResult[]>();
    useEffect(() =>
    {
        if (!storeId)
        {
            return;
        }
        setLoading(true);
        loadOrderedProducts({project: projectId, storeId, deliveryMonth, deliveryWeek}).then(data =>
        {
            setLoading(false);
            setProducts(data);
        });
    }, [projectId, storeId, deliveryMonth, deliveryWeek]);
    return (
        <>
            <h3 className='mb-4'>{pickLanguage(name)}</h3>
            {loading ?
                <div style={{height: '200px', position: 'relative'}}>
                    <Loading/>
                </div>
                :
                products ?
                    products.map(p =>
                        <OrderProductView
                            key={p.id}
                            {...p}
                            country='FR'
                            storeType={storeType}
                            isFRNonFood
                            isFair={false}
                            currency='EUR'
                        />
                    )
                    :
                    <h4>{t.global.somethingWentWrong}</h4>
            }
        </>
    );
});

function loadAllOrdersInfo(input)
{
    return call<{allOrdersInfo: IAllOrdersInfoResult}>(
        gql`query($input:AllOrdersTotalInput!){allOrdersInfo(input:$input){total{invoice,selling,discount,total},deliveryInfo{deliveryMonths,deliveryWeeks},projects{id,name}}}`,
        {input},
    ).then(({data}) => data?.allOrdersInfo);
}

function loadOrderedProducts(input)
{
    return call<{orderedProducts: IOrderedProductResult[]}>(
        gql`query($input:OrderedProductsInput!){orderedProducts(input:$input){id,uvir,description,flow,images,ean,promotion{promoCode,week,quantity,invoice,selling,discount,paid}}}`,
        {input},
        {noBatch: true}
    ).then(({data}) => data?.orderedProducts);
}
