import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import './NavbarProjectSelect.scss';
import {projectStore} from '../stores/ProjectStore';
import {pickLanguage} from '../stores/utility';
import {t} from '../translations';

export default observer(function NavbarProjectSelect()
{
    return <ProjectSelect selected={projectStore.id} onSelect={id => projectStore.select(id)}/>;
});

export const ProjectSelect = observer(function ProjectSelect({selected, onSelect}: {selected?: string, onSelect(id: string): void})
{
    const [more, setMore] = useState(false);
    const archivedProjects = projectStore.selectableProjects.filter(p => p.archived);
    return (
        <div className='NavbarProjectSelect'>
            {projectStore.selectableProjects.filter(p => !p.archived).map(p =>
                <span
                    className={p.id == selected ? 'active' : null}
                    key={p.id}
                    onClick={() => onSelect(p.id)}
                >
                    {pickLanguage(p.name)}
                </span>
            )}
            {!!archivedProjects.length &&
            <a href='#' onClick={() => setMore(!more)}>{t.global.archived}</a>
            }
            {(more || archivedProjects.some(p => p.id == selected)) && archivedProjects.map(p =>
                <span
                    className={p.id == selected ? 'active' : null}
                    key={p.id}
                    onClick={() => onSelect(p.id)}
                >
                    {pickLanguage(p.name)}
                </span>
            )}
        </div>
    );
});
