// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BGBNgdANErL5tjGTy3A4, .uhgZi_wD5jyVMrdEm7NY, .jM1xsixK4qhIck7znPYG {
  border: none;
  border-radius: 3px;
  padding: 14px 26px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.BGBNgdANErL5tjGTy3A4 {
  background-color: #2169CA;
  color: #fff;
}
.uhgZi_wD5jyVMrdEm7NY {
  border: 1px solid #E4E4E4;
  background-color: #FFF;
  color: #2A3B52;
}
.jM1xsixK4qhIck7znPYG {
  background-color: #ddd;
  color: #777;
}

.KwmKUMg0RSECv8EDBr2y {
  margin-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/components/Button.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,cAAc;AAChB;AACA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".primary, .secondary, .disabled {\n  border: none;\n  border-radius: 3px;\n  padding: 14px 26px;\n  font-size: 16px;\n  font-weight: 600;\n  cursor: pointer;\n}\n.primary {\n  background-color: #2169CA;\n  color: #fff;\n}\n.secondary {\n  border: 1px solid #E4E4E4;\n  background-color: #FFF;\n  color: #2A3B52;\n}\n.disabled {\n  background-color: #ddd;\n  color: #777;\n}\n\n.icon {\n  margin-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `BGBNgdANErL5tjGTy3A4`,
	"secondary": `uhgZi_wD5jyVMrdEm7NY`,
	"disabled": `jM1xsixK4qhIck7znPYG`,
	"icon": `KwmKUMg0RSECv8EDBr2y`
};
export default ___CSS_LOADER_EXPORT___;
