import {ReactNode, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import './TranslationForm.scss';
import {MultiForm, MultiFormTab} from './MultiForm';
import {ITranslations, Language} from '../../graphql/api/Types';

interface TranslatedValue
{
    key: string
    name: ReactNode
    textBelow?: ReactNode
    placeholder?: string
    value: ITranslations
}

interface TranslationFormProps
{
    className?: string
    values: TranslatedValue[]
    onChange?(key: string, language: Language, value: string): void
}

export function TranslationForm({className, values, onChange}: TranslationFormProps)
{
    return (
        <MultiForm
            className={className}
            tabs={languageTabs}
            render={language => <>{values.map(field => <TranslationFormField key={field.key} fieldKey={field.key} {...field} language={language} onChange={onChange}/>)}</>}
        />
    );
}

const TranslationFormField = observer(function TranslationFormField({language, fieldKey, name, textBelow, placeholder, value, onChange}: TranslatedValue & {fieldKey: string, language: Language} & Pick<TranslationFormProps, 'onChange'>)
{
    const id = useMemo(() => Math.random() + '', []);
    return (
        <div className='TranslationFormField'>
            <label htmlFor={id}>{name}</label>
            <input id={id} type='text' value={value?.[language] || ''} placeholder={placeholder} onChange={e => onChange?.(fieldKey, language, e.currentTarget.value)}/>
            {!!textBelow && <label htmlFor={id}>{textBelow}</label>}
        </div>
    );
});

export const languageTabs: MultiFormTab<Language>[] = [
    {name: 'EN', value: 'en'},
    {name: 'FR', value: 'fr'},
    {name: 'NL', value: 'nl'},
    {name: 'PL', value: 'pl'},
    {name: 'IT', value: 'it'},
    {name: 'ES', value: 'es'},
];
