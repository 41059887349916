import {action, makeObservable, observable} from 'mobx';
import {IPostFromTo} from '../Post/PostView';
import {IPostFromToInput, withoutUpdated} from '../Post/postUtility';
import {callCreateAgenda, callDeleteAgenda, callUpdateAgenda, loadAgenda} from './agendaProxy';
import {deepSet} from '../common/deepSet';

class AgendaStore
{
    @observable agenda: IPostFromTo[] = [];

    constructor()
    {
        makeObservable(this);
    }

    reloadAgenda()
    {
        loadAgenda().then(action(res =>
        {
            if (res)
            {
                this.agenda = res;
            }
        }));
    }

    create(post: IPostFromToInput)
    {
        callCreateAgenda(post).then(action(res =>
        {
            if (res)
            {
                this.agenda.push(res);
            }
        }));
    }

    @action
    handleChange(id: string, key: string | string[], value: any)
    {
        const post = this.agenda.find(i => i.id === id);
        deepSet(post, key, value);
        this.update(post);
    }

    update(update: Partial<IPostFromTo>)
    {
        update = withoutUpdated(update);
        delete update.attachments;
        callUpdateAgenda(update)
            .then(action(res =>
            {
                if (res)
                {
                    const post = this.agenda.find(i => i.id == res.id);
                    if (post)
                    {
                        Object.assign(post, res);
                        delete post.addAttachments;
                        delete post.removeAttachments;
                    }
                }
            }));
    }

    delete(id: string)
    {
        callDeleteAgenda(id).then(action(() =>
        {
            const i = this.agenda.findIndex(p => p.id == id);
            if (i >= 0)
            {
                this.agenda.splice(i, 1);
            }
        }));
    }
}

export const agendaStore = new AgendaStore();
