import React, {useState} from 'react';
import {t} from '../translations';
import {LoginChildProps} from './LoginBox';
import LoginLCTitle from './LCTitle';
import {ConfirmLarge} from './Buttons';
import {handleEnter} from './utility';
import LoginMethodSwitchOptions from './LoginMethodSwitchOptions';
import type {GraphQLFormattedError} from 'graphql';
import BlueBox from './BlueBox';
import useIsMounted from '../common/useIsMounted';

export default function EnterEmail(props: LoginChildProps)
{
    const {isEvent, defaultEmail, onLogin} = props;
    const [email, setEmail] = useState(defaultEmail || '');
    const [errors, setErrors] = useState<GraphQLFormattedError[]>();
    const isMounted = useIsMounted();
    const confirm = () => onLogin({value: email, method: 'Email'}).then(({errors: nextErrors}) =>
    {
        if (nextErrors && isMounted.current)
        {
            setErrors(nextErrors);
        }
    });
    return (
        <BlueBox>
            <LoginLCTitle/>
            <div className='LoginInput'>
                <label>{isEvent ? t.eventPage.registerForTheEvent : t.welcomePage.enterEmail}</label>
                <input
                    className='form-control mb-3'
                    autoFocus
                    type='email'
                    name='email'
                    placeholder='example@domain.com'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onKeyDown={handleEnter(confirm)}
                />
            </div>
            {!!errors && <p className='LoginError'>{loginErrorMessages(errors)}</p>}
            <div className='LoginInput'>
                <ConfirmLarge onClick={confirm}/>
                <LoginMethodSwitchOptions {...props}/>
            </div>
        </BlueBox>
    )
}

export function loginErrorMessages(errors: GraphQLFormattedError[])
{
    if (errors.some(e => e.message === 'invalid-email'))
    {
        return t.registration.form.errors.emailFormat;
    }
    else if (errors.some(e => e.message === 'invalid-phone'))
    {
        return t.registration.form.errors.phoneFormat;
    }
    else if (errors.some(e => e.message === 'not-validated'))
    {
        return t.authentication.notValidated;
    }
    else
    {
        return t.global.somethingWentWrong;
    }
}
