import {observer} from 'mobx-react-lite';
import styles from './ProductDisplayPackage.module.scss';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {pickLanguage} from '../stores/utility';
import {t} from '../translations';
import {colon, user} from '../stores/user';
import {currencySymbol} from '../../lib/currency';

interface Props
{
    product: ICatalogProduct
}

export const ProductDisplay = observer(function ProductDisplay({product}: Props)
{
    if (!product.display?.length)
    {
        return null;
    }
    return (
        <table className={styles.DisplayItems}>
            <thead>
            <tr>
                <th>{t.catalog.articleCode}</th>
                <th>{t.global.description}</th>
                <th>{t.catalog.qty}</th>
            </tr>
            </thead>
            <tbody>
            {product.display.map(d => (
                <tr key={d.articleCode}>
                    <td>{d.articleCode}</td>
                    <td>{pickLanguage(d.description)}</td>
                    <td>{d.uvir}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
});

interface ProductPackageProps
{
    product: ICatalogProduct
    currency: string
}

export const ProductPackage = observer(function ProductPackage({product, currency}: ProductPackageProps)
{
    if (!product.package?.length)
    {
        return null;
    }
    return (
        <table className={styles.PackageItems}>
            <thead>
            <tr>
                <th>Astra</th>
                <th>{t.global.products}</th>
                {product.package.some(p => p.price) && <th>PC</th>}
                <th>UXI</th>
                <th>{t.global.quantity}</th>
            </tr>
            </thead>
            <tbody>
            {product.package.map(p => (
                <tr key={p.code.article}>
                    <td>{p.code.article}</td>
                    <td>{pickLanguage(p.description)}</td>
                    {product.package.some(o => o.price) &&
                        <td>{p.price != null ? (p.price * (1 - (p.discount / 100 || 0))).toFixed(3).replace('.', ',') + ' ' + currencySymbol(currency) : null}</td>
                    }
                    <td>{p.uvir}</td>
                    <td>{p.numeroColli}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
});

export const ProductPackagePrice = observer(function ProductPackagePrice({product, currency}: ProductPackageProps)
{
    if (!product.package?.some(p => p.price))
    {
        return null;
    }
    return (
        <div className='text-center'>
            <div>
                {product.package.length === 1 ? t.catalog.invoicePriceProduct : t.catalog.invoicePricePackage}{colon()}
                <span className='font-weight-bold'>
                    {user.formatCurrency(currency, product.package.reduce((sum, p) => sum + (p.price || 0) * p.uvir * p.numeroColli * (1 - (p.discount / 100 || 0)), 0))}
                </span>
            </div>
            <div className='text-small'>{t.catalog.netWithDiscount}</div>
        </div>
    );
});

interface DisplayFRProps
{
    product: ICatalogProduct
    currency: string
    storeId: string
}

export const ProductDisplayFR = observer(function ProductDisplayFR({product, currency, storeId}: DisplayFRProps)
{
    const display = product.stores.length ? product.stores.find(ps => ps.storeId == storeId)?.displayFR : null;
    if (!display?.length)
    {
        return null;
    }
    return (
        <>
            {display.map(d =>
                <div key={d.internalCode} className='text-small my-4'>
                    <b>{d.description}</b>
                    <div>
                        <div className='d-inline-block mr-2'>EAN: <b>{d.internalCode}</b>,</div>
                        <div className='d-inline-block mr-2'>PCB: <b>{d.uvir}</b>,</div>
                        <div className='d-inline-block'>PVC Promo TTC: <b>{user.formatCurrency(currency, d.selling)}</b></div>
                    </div>
                </div>
            )}
        </>
    );
});
