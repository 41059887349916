import {action, makeAutoObservable} from 'mobx';
import type {ITranslations} from '../../graphql/api/Types';
import {call, gql} from '../client';
import {projectStore} from '../stores/ProjectStore';

export interface IProductFamily
{
    name: ITranslations
    count: number
}

function getProductFamilies(project: string)
{
    return call<{productFamiliesCount: IProductFamily[]}>(
        gql`query($project:ID!){productFamiliesCount(project:$project){name,count}}`,
        {project}
    ).then(({data}) => data?.productFamiliesCount);
}

class ProductFamiliesStore
{
    list: {[project: string]: IProductFamily[]} = {};
    loading = false;

    get sectors()
    {
        return this.list[projectStore.id];
    }

    constructor()
    {
        makeAutoObservable(this);
    }

    load(project: string)
    {
        this.loading = true;
        getProductFamilies(project).then(action(res =>
        {
            this.loading = false;
            if (res)
            {
                this.list[project] = res;
            }
        }));
    }
}

export const productFamiliesStore = new ProductFamiliesStore();
