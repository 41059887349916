import {action, makeObservable, observable} from 'mobx';
import Swal from 'sweetalert2';
import {
    checkHasPageTreeAccess,
    createPage,
    createStandPages,
    deletePages,
    duplicatePages,
    loadPages,
    loadPageTree,
    updatePageTree,
    updatePageTreeViewAccess,
} from './pageProxy';
import {IPage, pageStore} from './PageStore';
import {DataNode} from './FolderTree';
import {Country} from '../../graphql/api/Types';
import {t} from '../translations';

export interface IPagesPage extends Pick<IPage, 'id' | 'country' | 'project' | 'name' | 'isTemplate' | 'viewAccess' | 'keywords' | 'mainPage' | 'partialEditAccess' | 'editAccess' | 'updatedAt'>
{
    completion?: {count: number, filled: number}
}

class PagesStore
{
    @observable globalPages: IPagesPage[] = [];
    @observable pages: {[project: string]: IPagesPage[]} = {};
    @observable viewAccess: {[project: string]: string[]} = {};
    @observable tree: {[project: string]: DataNode[]} = {};
    @observable hasAccess: {[project: string]: boolean} = {};

    constructor()
    {
        makeObservable(this);
    }

    loadPages(project?: string)
    {
        return loadPages(project).then(action('loadPages', pages =>
        {
            if (pages)
            {
                if (project)
                {
                    this.pages[project] = pages;
                }
                else
                {
                    this.globalPages = pages;
                }
            }
            return pages;
        }));
    }

    loadPageTree(project: string)
    {
        return loadPageTree(project).then(action('loadPageTree', tree =>
        {
            // null values are saved, so that we can track what we don't have access to
            this.viewAccess[project] = tree?.viewAccess;
            this.tree[project] = tree?.tree;
            return tree;
        }));
    }

    checkHasAccess(project: string)
    {
        checkHasPageTreeAccess(project).then(action('checkHasPageTreeAccess', hasAccess =>
        {
            this.hasAccess[project] = hasAccess;
        }));
    }

    createPage(input: {country?: Country, project?: string})
    {
        return createPage(input).then(action('createPage', page =>
        {
            if (input.country)
            {
                this.globalPages.push(page);
            }
            if (input.project)
            {
                this.pages[input.project].push(page);
            }
            pageStore.pages.push(page);
            return page;
        }));
    }

    deletePages(project: string, ids: string[])
    {
        deletePages(ids).then(action('deletePages', ({data, errors}) =>
        {
            if (errors)
            {
                Swal.fire({
                    title: 'Failed to delete pages',
                    text: errors[0].message,
                    icon: 'error',
                });
            }
            else
            {
                const pages = data.deletePages;
                pageStore.pages = pageStore.pages.filter(p => !pages.map(o => o.id).includes(p.id));
                this.pages[project] = this.pages[project].filter(p => !pages.map(o => o.id).includes(p.id));
            }
        }));
    }

    duplicatePages(ids: string[], project?: string, ignoreWarning?: boolean)
    {
        return duplicatePages(ids, project, ignoreWarning).then(action('duplicatePages', async ({data, errors}) =>
        {
            if (errors)
            {
                if (errors[0].extensions?.warning)
                {
                    const r = await Swal.fire({
                        title: 'Warning for copying pages',
                        text: errors[0].message,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Copy anyway',
                        cancelButtonText: t.global.cancel,
                    });
                    if (r.value)
                    {
                        return this.duplicatePages(ids, project, true);
                    }
                }
                else
                {
                    Swal.fire({
                        title: 'Failed to copy pages',
                        text: errors[0].message,
                        icon: 'error',
                    });
                }
                return false;
            }
            else
            {
                const {tree, pages} = data.duplicatePages;
                this.tree[tree.project] = tree.tree;
                pageStore.pages = [...pageStore.pages, ...pages];
                for (const page of pages)
                {
                    this.pages[page.project]?.push(page);
                }
                return true;
            }
        }));
    }

    @action
    updateTree(project: string, tree: DataNode[])
    {
        this.tree[project] = tree;
        updatePageTree(project, tree);
    }

    @action
    updateTreeViewAccess(project: string, viewAccess: string[])
    {
        this.viewAccess[project] = viewAccess;
        updatePageTreeViewAccess(project, viewAccess);
    }

    createStandPages(project: string)
    {
        return createStandPages(project).then(({data, errors}) =>
        {
            const res = data?.createStandPages;
            if (res)
            {
                this.loadPages(project);
                this.loadPageTree(project);
            }
            else
            {
                Swal.fire({
                    title: 'Failed to create stand pages',
                    text: errors[0].message,
                    icon: 'error',
                });
            }
            return res;
        });
    }
}

export const pagesStore = new PagesStore();
