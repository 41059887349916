import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {agendaStore} from './AgendaStore';
import {IPost} from '../Post/PostView';
import {TimelinePostView} from '../Post/TimelinePostView';
import TimelinePostSeparator from '../Post/TimelinePostSeparator';
import {user} from '../stores/user';
import {t} from '../translations';
import { projectStore } from '../stores/ProjectStore';
import { Navigate } from 'react-router';
import { companyStore } from '../Company/CompanyStore';
import {debouncedLogView} from '../stores/logView';

function handleCreate()
{
    agendaStore.create({
        date: new Date(),
        dateTo: new Date(),
        published: false,
        title: {en: 'Title'},
        content: {},
        profiles: [],
        project: projectStore.id
    });
}

function handleChange(item: IPost, key: string | string[], value: any)
{
    agendaStore.handleChange(item.id, key, value);
}

function handleDelete(id: string)
{
    agendaStore.delete(id);
}

export default observer(function AgendaPage()
{
    useEffect(() =>
    {
        agendaStore.reloadAgenda();
    }, []);
    useEffect(() =>
    {
        projectStore.withId(pid =>
        {
            debouncedLogView({type: 'agenda', project: pid});
        });
    }, [projectStore.id]);

    const [filterShownOnBadgePDF, setFilterShownOnBadgePDF] = useState(false);

    if (companyStore.initialLoad && projectStore.initialLoad && !projectStore.ownProjects.length && !user.isGuest && !user.isStore && !user.moderator)
    {
        return <Navigate to='/dashboard' />
    }
    if (user.archivedExhibitor)
    {
        return <Navigate to='/' />
    }
    let filtered = agendaStore.agenda.filter(a => a.project == projectStore.id);
    if (filterShownOnBadgePDF)
    {
        filtered = filtered.filter(a => a.shownOnBadgePDF);
    }
    const sorted = filtered.sort((a, b) => +new Date(a.date) - +new Date(b.date));
    return (
        <div className='container'>
            <div className='py-3'>
                {user.moderator && <button className='button float-right' onClick={handleCreate}>New Agenda</button>}
                <h1>{t.agenda.title}</h1>
            </div>
            {user.moderator &&
            <div>
                <input id='filterShownOnBadgePDF' type='checkbox' checked={filterShownOnBadgePDF} onChange={e => setFilterShownOnBadgePDF(e.target.checked)}/>
                <label htmlFor='filterShownOnBadgePDF' className='ml-2'>Visible on Badge PDF</label>
            </div>}
            <div>
                {sorted.map((n, i) =>
                {
                    const post = (
                        <TimelinePostView
                            key={n.id}
                            useTimeMark
                            canShownOnBadgePDF
                            noImages
                            item={n}
                            onChange={handleChange}
                            onDelete={handleDelete}
                        />
                    );
                    const d = n.date;
                    const p = sorted[i - 1]?.date;
                    if (!p || d.getDate() != p.getDate() || d.getMonth() != p.getMonth() || d.getFullYear() != p.getFullYear())
                    {
                        return <React.Fragment key={n.id}>
                            <TimelinePostSeparator date={n.date}/>
                            {post}
                        </React.Fragment>;
                    }
                    return post;
                })}
            </div>
        </div>
    );
});
