import {useEffect} from 'react';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {helpStore} from './HelpStore';
import {HelpQuestion, IHelpQuestion} from './HelpQuestion';
import {user} from '../stores/user';
import {t} from '../translations';
import { projectStore } from '../stores/ProjectStore';
import {debouncedLogView} from '../stores/logView';
import {pickLanguage} from '../stores/utility';

export default observer(function HelpSection()
{
    const params = useParams<{section: string}>();
    const projectId = projectStore.id;

    const section = decodeURIComponent(params.section);

    useEffect(() =>
    {
        if (projectId)
        {
            debouncedLogView({type: 'help', project: projectId, category: section});
            helpStore.loadCategories(projectId);
            helpStore.reloadHelpSection(section, projectId);
        }
    }, [projectId, section]);

    const handleCreate = () =>
    {
        helpStore.create({
            section,
            published: false,
            title: {en: 'Title'},
            content: {},
            profiles: [],
            project: projectId
        });
    };

    const handleChange = (item: IHelpQuestion, key: string | string[], value: any) =>
    {
        helpStore.handleChange(section, item.id, key, value);
    };

    const handleDelete = (id: string) =>
    {
        helpStore.delete(section, id);
    };

    const questions = helpStore.bySection[section]?.[projectId];

    return (
        <div className='container pt-2'>
            <Link to='/help'>{t.helpPage.back}</Link>
            <div className='py-3'>
                {user.moderator && <button className='button float-right' onClick={handleCreate}>New Question</button>}
                <h1>{pickLanguage(helpStore.helpCategories[projectId]?.find(c => c.customURL == section)?.name)}</h1>
            </div>
            <div>
                {questions?.map(n =>
                    <HelpQuestion
                        key={n.id}
                        item={n}
                        defaultCollapsed={questions.length !== 1}
                        onChange={handleChange}
                        onDelete={handleDelete}
                    />
                )}
            </div>
        </div>
    );
});
