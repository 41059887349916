import {action, makeObservable, observable} from 'mobx';
import {
    callAttachmentUploadCanceled,
    callAttachmentUploadFinished,
    callRequestAttachmentUpload,
} from './attachmentProxy';
import {uploadFile} from '../Upload/uploadFile';

interface AttachmentUpload
{
    key: string
    name: string
    progress: number // 0 to 1
    ended?: boolean // true when upload ended for whatever reason
    finished?: boolean // true when the upload successfully finishes
    canceled?: boolean // true when upload is canceled
    error?: boolean // true when there is an error during upload
    _xhr?: XMLHttpRequest
}

class AttachmentStore
{
    @observable uploads: {[key: string]: AttachmentUpload} = {};

    constructor()
    {
        makeObservable(this);
    }

    // resolves when the server responds with a signed url
    // use the onEnd() callback to get notified when the file upload actually ends
    // onEnd() callback is not called if we fail to get signed url (if the promise is rejected)
    @action
    upload(file: File, onEnd?: (a: AttachmentUpload) => void)
    {
        // console.log('upload:', file.name);
        return callRequestAttachmentUpload(file).then(({key, name, signedUrl}) =>
        {
            // console.log('url:', key, signedUrl);
            this.uploads[key] = {key, name, progress: 0};
            const u = this.uploads[key];
            u._xhr = uploadFile({
                url: signedUrl,
                file,
                asAttachment: true,
                onUploaded: action(() =>
                {
                    // console.log('uploaded:', key);
                    u.finished = true;
                    u.ended = true;
                    callAttachmentUploadFinished(key);
                    onEnd?.(u);
                }),
                onError: action(() =>
                {
                    // console.log('error:', key);
                    if (!u.canceled)
                    {
                        u.error = true;
                    }
                    callAttachmentUploadCanceled(key);
                    u.ended = true;
                    onEnd?.(u);
                }),
                onProgress:  action((progress: number) =>
                {
                    u.progress = progress;
                }),
            });
            return u;
        });
    }

    @action
    cancel(key: string)
    {
        // console.log('cancel:', key);
        const u = this.uploads[key];
        if (u && !u.canceled)
        {
            u.canceled = true;
            if (!u.ended)
            {
                u.ended = true;
                u._xhr.abort();
            }
        }
    }

    // returns the upload only if it is still in progress
    getUploadInProgress(key: string)
    {
        const u = this.uploads[key];
        return u && !u.ended ? u : null;
    }
}

export const attachmentStore = new AttachmentStore();

window.addEventListener('beforeunload', e =>
{
    for (const key of Object.getOwnPropertyNames(attachmentStore.uploads))
    {
        if (!attachmentStore.uploads[key].ended)
        {
            console.log('There are active uploads.');
            e.preventDefault();
            e.returnValue = '';
        }
    }
});
