// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderProductView {
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: row;
}
.OrderProductView p {
  margin: 0;
}
.OrderProductView .productImage {
  position: relative;
  width: 100px;
  min-height: 100px;
  margin-right: 1rem;
}
.OrderProductView .productImage img {
  height: auto;
  min-height: 100px;
  max-height: 100%;
}
.OrderProductView .productContent {
  flex-grow: 1;
}
.OrderProductView .name {
  font-size: 18px;
  font-weight: bold;
}
.OrderProductView .ean {
  margin: 10px 0;
}
.OrderProductView .weeks {
  margin: 10px 0;
}
.OrderProductView .weeks p {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1199px) {
  .OrderProductView {
    margin-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  .OrderProductView {
    margin-bottom: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./frontend/StoreCatalog/OrderProductView.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;AADF;AAEE;EACE,SAAA;AAAJ;AAEE;EACE,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AACI;EACE,YAAA;EACA,iBAAA;EACA,gBAAA;AACN;AAEE;EACE,YAAA;AAAJ;AAEE;EACE,eAAA;EACA,iBAAA;AAAJ;AAEE;EACE,cAAA;AAAJ;AAEE;EACE,cAAA;AAAJ;AACI;EACE,aAAA;EACA,8BAAA;AACN;;AAIA;EACE;IACE,mBAAA;EADF;AACF;AAGA;EACE;IACE,mBAAA;EADF;AACF","sourcesContent":["$breakpoint: 1200px;\n\n.OrderProductView {\n  border: 2px solid #e0e0e0;\n  border-radius: 15px;\n  padding: 15px;\n  display: flex;\n  flex-direction: row;\n  p {\n    margin: 0;\n  }\n  .productImage {\n    position: relative;\n    width: 100px;\n    min-height: 100px;\n    margin-right: 1rem;\n    img {\n      height: auto;\n      min-height: 100px;\n      max-height: 100%;\n    }\n  }\n  .productContent {\n    flex-grow: 1;\n  }\n  .name {\n    font-size: 18px;\n    font-weight: bold;\n  }\n  .ean {\n    margin: 10px 0;\n  }\n  .weeks {\n    margin: 10px 0;\n    p {\n      display: flex;\n      justify-content: space-between;\n    }\n  }\n}\n\n@media (max-width: $breakpoint - 1px) {\n  .OrderProductView {\n    margin-bottom: 20px;\n  }\n}\n@media (min-width: $breakpoint) {\n  .OrderProductView {\n    margin-bottom: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
