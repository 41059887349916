import FA from 'react-fontawesome';

export function SortButton({selected, name, onChange}: {selected: string, name: string, onChange(sortBy: string): void})
{
    const up = name + '-up';
    const down = name + '-down';
    const isDown = selected == down || selected == name;
    return <FA
        name={isDown ? 'sort-down' : selected == up ? 'sort-up' : 'sort'}
        onClick={(e) =>
        {
            e.stopPropagation();
            return onChange(isDown ? up : down);
        }}
    />;
}
