import {call, gql} from '../client';

export interface VideoUploadInput
{
    project: string
    name?: string
    dir?: string
    contentType: string
    size: number
}

export interface VideoUploadResult
{
    name: string
    url: string
    signedUrl: string
    contentType: string
}

export function callRequestVideoUpload(input: VideoUploadInput)
{
    return call<{requestVideoUpload: VideoUploadResult}>(
        gql`mutation($input:VideoUploadInput!){requestVideoUpload(input:$input){name,url,signedUrl}}`,
        {input}
    ).then(({data}) => data?.requestVideoUpload);
}

export function callUploadVideoFinished(url: string)
{
    return call(gql`mutation($url: String!){uploadVideoFinished(url:$url)}`, {url});
}

export function callUploadVideoCanceled(url: string)
{
    return call(gql`mutation($url: String!){uploadVideoCanceled(url:$url)}`, {url});
}

export interface IVideo
{
    id: string
    name: string
    description: string
    duration: number
    created_time: string
    stats: {
        plays: number
    }
    pictures: {
        sizes: {
            'width': number,
            'height': number,
            'link': string,
            'link_with_play_button': string
        }[]
    }
}

export function getVideo(url: string)
{
    return call<{video: IVideo}>(gql`query($url:String!){video(url:$url)}`, {url})
    .then(({data}) => data?.video);
}
