import React from 'react';
import FA from 'react-fontawesome';
import './RotationArrows.scss';

interface Props
{
    index: number
    maxIndex: number
    setIndex(index: number): void
}

export function RotationArrows({index, maxIndex, setIndex}: Props)
{
    if (maxIndex <= 0)
    {
        return null;
    }

    return (
        <div className='RotationArrows'>
            <FA
                name='chevron-left'
                className='fas cursor-pointer p-2'
                onClick={e =>
                {
                    e.stopPropagation();
                    setIndex(index > 0 ? index - 1 : maxIndex)
                }}
                onDoubleClick={stopPropagation}
                onMouseDown={stopPropagation}
            />
            <span/>
            <FA
                name='chevron-right'
                className='fas cursor-pointer p-2'
                onClick={e =>
                {
                    e.stopPropagation();
                    setIndex(index < maxIndex ? index + 1 : 0)
                }}
                onDoubleClick={stopPropagation}
                onMouseDown={stopPropagation}
            />
        </div>
    );
}

const stopPropagation = e => e.stopPropagation();
