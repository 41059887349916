import React from 'react';
import {observer} from 'mobx-react';
import './ContactSelection.scss';
import {companySelectionStore, contactSelectionStore} from './ContactSelectionStore';
import {countMatches, replaceAll} from '../Email/PersonSelection';
import {Country} from '../../graphql/api/Types';
import {projectStore} from '../stores/ProjectStore';
import {debounce} from '../common/debounce';
import {escapeHtmlTags} from '../../lib/common';
import {t} from '../translations';

interface Props
{
    className?: string
    selected: string[]
    only?: 'persons' | 'companies'
    onChange(selected: string[]): void
}

interface State
{
    input: string
    showOptions: boolean
    results: SearchResult[]
}

@observer
export class ContactSelection extends React.PureComponent<Props, State>
{
    state: State = {
        input: '',
        showOptions: false,
        results: [],
    };

    componentDidMount()
    {
        if (this.props.only != 'companies')
        {
            setTimeout(() => contactSelectionStore.load(projectStore.selectedCountry), 100);
        }
        if (this.props.only != 'persons')
        {
            setTimeout(() => companySelectionStore.load(projectStore.selectedCountry), 200);
        }
    }

    handleSelect = (e) =>
    {
        this.props.onChange([...this.props.selected, e.currentTarget.dataset.id]);
        this.setState({input: '', results: []});
    };

    handleRemove = (e) =>
    {
        e.preventDefault();
        this.props.onChange(this.props.selected.filter(id => id != e.currentTarget.dataset.id));
    };

    showOptions = () => this.setState({showOptions: true});
    hideOptions = () => this.setState({showOptions: false});
    handleChange = (e) =>
    {
        this.setState({input: e.target.value});
        this.debouncedSearch();
    }

    debouncedSearch = debounce(() =>
    {
        const p = this.props;
        const s = this.state;
        this.setState({
            results: searchContactsAndCompanies({search: s.input, selected: p.selected, limit: 4, country: projectStore.selectedCountry, only: p.only})
        });
    }, 500);

    render()
    {
        const {className, selected} = this.props;
        const {input, showOptions, results} = this.state;
        const country = projectStore.selectedCountry;
        return (
            <div className={'ContactSelection ' + (className || '')}>
                {selected.map(id =>
                {
                    const contact = contactSelectionStore.list(country).find(c => c.id == id);
                    const company = !contact && companySelectionStore.list(country).find(c => c.id == id);
                    return (
                        <a
                            href={contact ? '/contact/' + id : company ? '/company/' + id : '#' + id}
                            className='selected unstyle-link'
                            key={id}
                            data-id={id}
                            onClick={this.handleRemove}
                        >
                            {contact?.name || company?.name || '...'}
                        </a>
                    );
                })}
                <div className='input'>
                    <input
                        type='text'
                        value={input}
                        onFocus={this.showOptions}
                        onBlur={this.hideOptions}
                        onChange={this.handleChange}
                        placeholder={!selected?.length ? t.global.searchContacts : ''}
                    />
                    {showOptions &&
                    <div className='options list-group'>
                        {results.map(c => (
                            <button
                                key={c.id}
                                className='list-group-item list-group-item-action'
                                data-id={c.id}
                                onMouseDown={this.handleSelect}
                                dangerouslySetInnerHTML={{__html: `${c.name || ''}<br/>${c.email || ''}`}}
                            />
                        ))}
                    </div>
                    }
                </div>
            </div>
        );
    }
}

interface SearchOptions
{
    search: string
    selected?: string[]
    limit?: number
    country?: Country
    only?: 'persons' | 'companies'
}

interface SearchResult
{
    id: string
    name: string
    email: string
}

function searchContactsAndCompanies({search, selected, limit, country, only}: SearchOptions): SearchResult[]
{
    const words = search ? search.split(/ +/).filter(w => w).map(word => word.replace(/[\\\[\]()+?.*]/g, c => '\\' + c)) : [];
    let res = [];
    if (only != 'companies')
    {
        res.push(...contactSelectionStore.list(country).filter(c =>  !selected.includes(c.id)));
    }
    if (only != 'persons')
    {
        res.push(...companySelectionStore.list(country).filter(c => !selected?.includes(c.id)));
    }
    const searchEmail = words.length == 1;
    if (search)
    {
        const wordCombinations: string[] = [];
        for (let l = 1; l <= words.length; ++l)
        {
            for (let i = 0; i <= words.length - l; ++i)
            {
                wordCombinations.push(words.slice(i, i + l).join(' *'));
            }
        }
        const matchRegExp = search ? wordCombinations.map(word => new RegExp(word, 'i')) : [];
        res = res
            .map(c => ({
                contact: c,
                matches: countMatches(matchRegExp, c.name) + (searchEmail ? countMatches(matchRegExp, c.email) : 0),
            }))
            .filter(r => r.matches)
            .sort((a, b) => b.matches - a.matches)
            .map(r => r.contact);
    }
    if (limit)
    {
        res = res.slice(0, limit);
    }
    const replaceRegExp = search ? words.map(word => new RegExp('(^|)(' + word + ')(|$)', 'ig')) : [];
    return res.map(c => ({
        id: c.id,
        name: search ? replaceAll(replaceRegExp, escapeHtmlTags(c.name), '$1<b>$2</b>$3') : c.name,
        email: searchEmail ? replaceAll(replaceRegExp, escapeHtmlTags(c.email), '$1<b>$2</b>$3') : c.email,
    }));
}
