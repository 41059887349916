import {ICountry} from '../frontend/stores/CountryStore';

export const defaultCountries: ICountry[] = [{
    name: {
        en: 'Belgium',
        fr: 'Belgique',
        nl: 'België',
        pl: 'Belgia',
        it: 'Belgio',
        es: 'Bélgica',
    },
    code: 'BE',
    languages: ['nl', 'fr'],
    currency: 'EUR',
}, {
    name: {
        en: 'Poland',
        fr: 'Pologne',
        nl: 'Polen',
        pl: 'Polska',
        it: 'Polonia',
        es: 'Polonia',
    },
    code: 'PL',
    languages: ['pl'],
    currency: 'PLN',
}, {
    name: {
        en: 'Italy',
        fr: 'Italie',
        nl: 'Italië',
        pl: 'Włochy',
        it: 'Italia',
        es: 'Italia',
    },
    code: 'IT',
    languages: ['it'],
    currency: 'EUR',
}, {
    name: {
        en: 'France',
        fr: 'France',
        nl: 'Frankrijk',
        pl: 'Francja',
        it: 'Francia',
        es: 'Francia',
    },
    code: 'FR',
    languages: ['fr'],
    currency: 'EUR',
}, {
    name: {
        en: 'Spain',
        fr: 'Espagne',
        nl: 'Spanje',
        pl: 'Hiszpania',
        it: 'Spagna',
        es: 'España',
    },
    code: 'ES',
    languages: ['es'],
    currency: 'EUR',
}];
