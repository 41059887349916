// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateTimePicker {
  position: relative;
}
.DateTimePicker .DayPicker {
  position: absolute;
  left: -1rem;
  top: 1.5rem;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  background-color: #fff;
  z-index: 5000;
}
.DateTimePicker.toTheRight .DayPicker {
  left: initial;
  right: -1rem;
}
.DateTimePicker .date {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./frontend/common/DateTimePicker.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;AACE;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,2EAAA;EACA,sBAAA;EACA,aAAA;AACJ;AAEI;EACE,aAAA;EACA,YAAA;AAAN;AAGE;EACE,eAAA;AADJ","sourcesContent":["\n.DateTimePicker {\n  position: relative;\n  .DayPicker {\n    position: absolute;\n    left: -1rem;\n    top: 1.5rem;\n    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);\n    background-color: #fff;\n    z-index: 5000;\n  }\n  &.toTheRight {\n    .DayPicker {\n      left: initial;\n      right: -1rem;\n    }\n  }\n  .date {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
