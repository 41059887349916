// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FairParticipation .CompanySelectDropdown {
  flex-basis: 500px;
}

.FairParticipationTable label {
  margin-bottom: 0;
}
.FairParticipationTable th {
  white-space: nowrap;
}
.FairParticipationTable th .fa {
  padding: 0 0.5rem;
  cursor: pointer;
  color: #999;
}
.FairParticipationTable td {
  vertical-align: middle;
}
.FairParticipationTable .check-participation .Checkbox {
  white-space: nowrap;
  width: max-content;
}
.FairParticipationTable .check-participation .selectable {
  border: 2px solid transparent;
  border-radius: 20px;
  padding: 5px;
}
.FairParticipationTable .check-participation .selectable.selected {
  border-color: #1aa260;
  color: #1aa260;
}
.FairParticipationTable .complete {
  border-radius: 20px;
  padding: 5px;
  background-color: #eee;
  color: #555;
  text-align: center;
}

.SideEvent {
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  padding: 10px 15px;
  width: 460px;
  margin-right: 20px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./frontend/FairParticipation/FairParticipation.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;;AAKE;EACE,gBAAA;AAFJ;AAIE;EACE,mBAAA;AAFJ;AAGI;EACE,iBAAA;EACA,eAAA;EACA,WAAA;AADN;AAIE;EACE,sBAAA;AAFJ;AAKI;EACE,mBAAA;EACA,kBAAA;AAHN;AAKI;EACE,6BAAA;EACA,mBAAA;EACA,YAAA;AAHN;AAIM;EACE,qBAAA;EACA,cAAA;AAFR;AAME;EACE,mBAAA;EACA,YAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;AAJJ;;AAQA;EACE,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;AALF","sourcesContent":[".FairParticipation {\n  .CompanySelectDropdown {\n    flex-basis: 500px;\n  }\n}\n\n.FairParticipationTable {\n  label {\n    margin-bottom: 0;\n  }\n  th {\n    white-space: nowrap;\n    .fa {\n      padding: 0 .5rem;\n      cursor: pointer;\n      color: #999;\n    }\n  }\n  td {\n    vertical-align: middle;\n  }\n  .check-participation {\n    .Checkbox {\n      white-space: nowrap;\n      width: max-content;\n    }\n    .selectable {\n      border: 2px solid transparent;\n      border-radius: 20px;\n      padding: 5px;\n      &.selected {\n        border-color: #1aa260;\n        color: #1aa260;\n      }\n    }\n  }\n  .complete {\n    border-radius: 20px;\n    padding: 5px;\n    background-color: #eee;\n    color: #555;\n    text-align: center;\n  }\n}\n\n.SideEvent {\n  border: 2px solid #e0e0e0;\n  border-radius: 15px;\n  padding: 10px 15px;\n  width: 460px;\n  margin-right: 20px;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
