import React from 'react';
import {t} from '../translations';

export default function NotValidated()
{
    return (
        <div className='NotValidated'>
            {t.authentication.stillReviewingRequest}
            <br/><br/>
            {t.authentication.thisCanTake}
            <br/><br/>
            {t.authentication.emailWhenApproved}
        </div>
    );
}
