import React from 'react';
import {action} from 'mobx';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';
import './OrderStandCheckout.scss';
import {CheckoutBottomLine, OrderStandCheckoutCategory} from './CheckoutItem';
import {orderStandStore} from './OrderStandStore';
import {IStandDiscount, IStandPackage, IStandProduct, IStandProductWithInvoice, IStandSetup, IStandSubmitted} from '../../graphql/api/stand/Stand';
import {orderStandProductStore} from './OrderStandProductStore';
import {productStore} from '../Products/ProductStore';
import {onlyUnique} from '../../lib/common';
import {categoryStore} from '../Categories/CategoryStore';
import {user} from '../stores/user';
import {t} from '../translations';
import {discountedOrderTotal, orderProductsTotal, orderProductTotal, orderSectionTotal} from './lib';
import CheckoutPackageInfoView from './CheckoutPackageInfoView';
import {projectCurrency} from './utility';
import {projectStore} from '../stores/ProjectStore';
import Swal from 'sweetalert2';
import {companyStore} from '../Company/CompanyStore';
import DangerButton from '../common/DangerButton';
import {useHashId, useNewStandCompanyId} from './useHash';
import StandLayout from './StandLayout';
import SetStandPreferences from './SetStandPreferences';
import {pickLanguage} from '../stores/utility';
import DropdownSearch from '../common/DropdownSearch';
import InvoiceListItem from '../Stands/InvoiceListItem';
import {invoiceStore} from '../Invoice/InvoiceStore';
import SelectPalletType from './SelectPalletType';

const handlePOChange = (e) =>
{
    orderStandStore.changePO(e.target.value);
};

const handleTerms = action((e) =>
{
    orderStandStore.termsAndConditions = !!e.target.checked;
});

const handleSecondTerms = action((e) =>
{
    orderStandStore.secondTermsAndConditions = !!e.target.checked;
});

export default observer(function OrderStandCheckout()
{
    const navigate = useNavigate();
    const stand = orderStandStore.selected;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const newStandCompanyId = user.moderator && useNewStandCompanyId();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const hashId = user.moderator && useHashId();

    const showBackToStands = user.moderator && !!(hashId || newStandCompanyId);

    if (!stand)
    {
        if (user.moderator && newStandCompanyId)
        {
            const existingStands = orderStandStore.stands.filter(s => s.companyId == newStandCompanyId);
            if (!existingStands.length)
            {
                return null;
            }
            return (
                <div className='order-stand-checkout'>
                    <div className='checkout-title'>
                        <DropdownSearch
                            placeholder={`Select existing stand (${existingStands.length})`}
                            options={existingStands.map(s => ({value: s.id, name: s.name}))}
                            onSelect={sid =>
                            {
                                navigate('/order-stand?package#' + sid);
                            }}
                        />
                    </div>
                </div>
            );
        }
        return null;
    }

    const {setup} = stand;
    const project = projectStore.projects.find(p => p.id == stand.project);
    const country = project?.fair.country;
    const isItaly = country == 'IT';
    const isDigital = !!project?.fair.settings?.digital;
    const currency = projectCurrency(stand.project);

    const invoices = invoiceStore.invoices.filter(i => i.standId === stand.id);

    const handleSubmit = () =>
    {
        if (!orderStandStore.termsAndConditions || !orderStandStore.secondTermsAndConditions)
        {
            alert(t.standCreation.simulation.errorTermsFirst);
            return;
        }
        if (!orderStandStore.selectedPackage)
        {
            alert(t.standCreation.simulation.errorNoPackage);
            return;
        }
        if (companyStore.ownCompanies.some(c => c.projects.some(p => projectStore.projects.find(pp => p.id == pp.id && pp.fair?.settings?.digital))))
        {
            Swal.fire({
                title: t.standCreation.conceptStandSubmitQuestion(pickLanguage(projectStore.selected?.name)),
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: t.standCreation.conceptStandConfirmButton,
                cancelButtonText: t.standCreation.conceptStandDeclineButton
            }).then((result) =>
            {
                if (result.value)
                {
                    orderStandStore.submitStand();
                }
            })
        }
        else
        {
            orderStandStore.submitStand();
        }
    };

    const includesPallets =
        stand.created?.package?.includesPallets ||
        stand.submitted?.package?.includesPallets ||
        stand.approved?.package?.includesPallets ||
        stand.invoiced?.package?.includesPallets;

    return (
        <div className='order-stand-checkout'>
            <div className='checkout-title'>
                {user.moderator ?
                    <DropdownSearch
                        selected={stand.id}
                        options={[
                            ...orderStandStore.projectStands.filter(s => s.companyId == stand.companyId)
                                .map(s => ({value: s.id, name: s.name})),
                            {value: '', name: 'Add Stand'}
                        ]}
                        onSelect={sid =>
                        {
                            if (sid)
                            {
                                navigate(`/order-stand${location.search}#${sid}`);
                            }
                            else
                            {
                                navigate('/order-stand#new/' + stand.companyId);
                            }
                        }}
                    />
                    :
                    <h5 className='font-2'>{stand.name}</h5>
                }
            </div>
            {!!invoices.length && <>
                <p className='mt-3 mb-1 smaller'>Invoices</p>
                <div className='mb-3 rounded border p-2 text-muted smaller'>
                    {invoices.map(i =>
                        <InvoiceListItem key={i.id} standId={stand.id} invoice={i} currency={currency}/>
                    )}
                </div>
            </>}
            <SetStandPreferences/>
            {includesPallets && <SelectPalletType/>}
            {!user.archivedExhibitor && !user.viewOnlyExhibitor &&
            <>
                <CheckoutSectionView name={t.standCreation.simulation.savedMsg} setup={setup} section={stand.created} currency={currency}/>
                {(setup.type === 'normal' && stand.created?.package) &&
                    <div className='mt-3'>* {t.standCreation.simulation.surfaceWarning}</div>
                }
                {stand.created?.includesLimitedStock &&
                    <div className='mt-3'>* {t.standCreation.simulation.stockWarning}</div>
                }
                {stand.created?.products?.some(p => !p.price) &&
                    <div className='mt-3'>* {t.standCreation.simulation.priceWarning}</div>
                }
                {!!(stand.created?.package || stand.created?.products?.length) &&
                <div className='my-3'>
                    <div className='checkout-terms'>
                        <div className='d-flex mb-4'>
                            <div className='checkbox'>
                                <input id='terms-and-conditions' type='checkbox' checked={orderStandStore.termsAndConditions} onChange={handleTerms}/>
                            </div>
                            <label htmlFor='terms-and-conditions'>
                                {t.standCreation.simulation.fairConditions.a}
                                <a href={pickLanguage(project?.fair.settings?.fairConditionsPdf)} target='_blank'>{t.standCreation.simulation.fairConditions.b}</a>
                                {!(isItaly && isDigital) && <>
                                    {t.standCreation.simulation.fairConditions.c}
                                    <a href={pickLanguage(project?.fair.settings?.codeOfConductPdf)} target='_blank'>{t.standCreation.simulation.fairConditions.d}</a>
                                </>}
                            </label>
                        </div>
                        <div>
                            <div className='checkbox'>
                                <input id='terms-and-conditions-2' type='checkbox' checked={orderStandStore.secondTermsAndConditions} onChange={handleSecondTerms}/>
                            </div>
                            <label htmlFor='terms-and-conditions-2'>
                                {t.standCreation.simulation.fairConditions.secondOne}
                            </label>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='pr-5'><span>{t.standCreation.simulation.submitMsg}</span></div>
                        <div>
                            <label htmlFor='po-attach' className='mb-2'>{t.standCreation.simulation.poQuestion}</label>
                            <input
                                id='po-attach'
                                type='text'
                                placeholder={t.standCreation.simulation.poNumber}
                                className='mb-2'
                                value={orderStandStore.PO || ''}
                                onChange={handlePOChange}
                            />
                            <button className='checkout-submit-button' onClick={handleSubmit}>{t.standCreation.simulation.submitButton}</button>
                        </div>
                    </div>
                </div>}
            </>}
            <CheckoutSectionView title name={t.standCreation.simulation.approved} setup={setup} section={stand.approved} currency={currency}/>
            <CheckoutSectionView title name={t.standCreation.simulation.invoiced} setup={setup} section={stand.invoiced} currency={currency}/>
            <CheckoutSectionView title name={t.standCreation.simulation.submitted} setup={setup} section={stand.submitted} currency={currency}/>
            {(setup.type === 'normal' && (stand.submitted?.package || stand.approved?.package || stand.invoiced?.package)) &&
            <div className='mt-3'>{t.standCreation.simulation.surfaceWarning}</div>}
            <CheckoutApproveDecline/>
            {(setup.type === 'normal' && (stand.submitted?.package || stand.approved?.package || stand.invoiced?.package)) &&
            <StandLayout/>
            }
            {showBackToStands &&
            <div className='d-flex justify-content-end mt-3'>
                <button className='button' onClick={() => navigate(-1)}>Back to stands</button>
            </div>
            }
        </div>
    );
});

interface CheckoutSectionViewProps
{
    title?: boolean
    name?: React.ReactNode
    setup: IStandSetup
    section: IStandSubmitted
    currency: string
}

export const CheckoutSectionView = observer(function CheckoutSectionView({section, setup, title, name, currency}: CheckoutSectionViewProps)
{
    if (!section || !(section.package || section.products?.length > 0))
    {
        return null;
    }
    return <>
        <h5 className='stand-section-title font-2 mt-3'>{name}</h5>
        <div className='stand-section-body bg-grey px-2'>
            {section.package &&
            <CheckoutPackageView pkg={section.package} setup={setup} currency={currency}/>}
            {section.products?.length > 0 &&
            <CheckoutProductsView products={section.products} currency={currency}/>}
        </div>
        {name &&
        <CheckoutBottomLine
            text={name}
            price={user.formatCurrency(currency, orderSectionTotal(section))}
        />}
    </>;
});

interface CheckoutPackageViewProps
{
    pkg: IStandPackage
    setup: IStandSetup
    currency: string
}

const CheckoutPackageView = observer(function CheckoutPackageView({pkg, setup, currency}: CheckoutPackageViewProps)
{
    const {total, discountedAmounts} = discountedOrderTotal(pkg.price, pkg.discount);
    return (
        <OrderStandCheckoutCategory
            name={t.standCreation.simulation.emplacement}
            hideTopName={projectStore.isDigitalFair}
            total={user.formatCurrency(currency, total)}
        >
            <CheckoutPackageInfoView pkg={pkg} setup={setup} currency={currency}/>
            {pkg.discount?.map((d, i) =>
                <CheckoutProductDiscountView key={i} discountedAmount={discountedAmounts[i]} discount={d} currency={currency}/>
            )}
        </OrderStandCheckoutCategory>
    );
});

interface CheckoutProductsViewProps
{
    products: IStandProductWithInvoice[]
    currency: string
}

const CheckoutProductsView = observer(function CheckoutProductsView({products, currency}: CheckoutProductsViewProps)
{
    const prodList = products.map(op =>
    {
        const product = orderStandProductStore.projectProducts.find(p => p.id === op.id) || productStore.products.find(p => p.id === op.id);
        return {
            ...op,
            category: product?.category?.main,
            name: product ? pickLanguage(product.name) : op.name,
        }
    });
    return (<>
        {prodList.map(p => p.category).filter(onlyUnique).map(m =>
        {
            const orderProducts = prodList.filter(p => p.category === m);
            const categoryName = categoryStore.countryCategories.find(c => c.id === m)?.name;
            return (
                <OrderStandCheckoutCategory
                    key={m || ''}
                    name={pickLanguage(categoryName)}
                    total={user.formatCurrency(currency, orderProductsTotal(orderProducts))}
                >
                    {orderProducts.map((p, i) =>
                        <CheckoutProductView key={p.id + (p.invoice || '') + i} product={p} currency={currency}/>
                    )}
                </OrderStandCheckoutCategory>
            );
        })}
    </>);
});

interface CheckoutProductViewProps
{
    product: IStandProduct
    currency: string
}

const CheckoutProductView = observer(function CheckoutProductView({product, currency}: CheckoutProductViewProps)
{
    const {name, price, quantity, discount} = product;
    const priceOnDemand = price == null;
    const {basePrice, discountedAmounts} = orderProductTotal(product);
    return (
        <div className='d-flex mb-3'>
            <div className='text-nowrap'>
                {quantity ? quantity + ' x ' : ''}
            </div>
            <div className='flex-grow-1 ml-1'>
                <div className='d-flex justify-content-between'>
                    <div>
                        {name +
                        ' ' +
                        (priceOnDemand ? (
                            t.global.priceOnDemand
                        ) : (
                            `( ${user.formatCurrency(currency, +price)} / ${t.standCreation.simulation.unitPrice} )`
                        ))}
                    </div>
                    <div className='text-nowrap ml-4'>
                        {priceOnDemand ? t.standCreation.simulation.notIncluded : user.formatCurrency(currency, basePrice)}
                    </div>
                </div>
                {discount?.map((d, i) =>
                    <CheckoutProductDiscountView key={i} discountedAmount={discountedAmounts[i]} discount={d} currency={currency}/>
                )}
            </div>
        </div>
    );
});

interface CheckoutProductDiscountViewProps
{
    discountedAmount: number
    discount: IStandDiscount
    currency: string
}

const CheckoutProductDiscountView = observer(function CheckoutProductDiscountView(p: CheckoutProductDiscountViewProps)
{
    const {discountedAmount, currency} = p;
    const {name, type, amount} = p.discount;
    const isPercentage = type === 'percentage';
    const positiveSign = (isPercentage ? amount > 1 : amount > 0) ? '+' : '';
    return (
        <div className='d-flex justify-content-between'>
            <div>
                <span className='mr-1'>{name}</span>
                {positiveSign +
                (type === 'percentage' ? ((amount - 1) * 100).toFixed(0) + '%' : user.formatCurrency(currency, amount))}
            </div>
            <div className='text-nowrap ml-4'>
                {positiveSign + user.formatCurrency(currency, discountedAmount)}
            </div>
        </div>
    );
});

const CheckoutApproveDecline = observer(function CheckoutApproveDecline()
{
    const stand = orderStandStore.selected;
    if (!(stand.submitted?.products?.length || stand.submitted?.package) || !user.moderator)
    {
        return null;
    }

    const handleDeclineStand = () =>
    {
        orderStandStore.decline(stand.id);
    }
    const handleApprove = () =>
    {
        if (stand.submitted.products?.some(p => p.price == null))
        {
            alert('Some of the products do not have a price'); // price on demand
            return;
        }
        orderStandStore.approve(stand.id);
    };

    return (
        <div className='d-flex mt-3'>
            <button className='button' onClick={handleApprove}>Approve</button>
            <DangerButton className='ml-3' onClick={handleDeclineStand}>Decline</DangerButton>
        </div>
    )
});
