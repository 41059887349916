import {observer} from 'mobx-react-lite';
import styles from './LimitedQuantityDateOverlay.module.scss';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {t} from '../translations';

interface Props
{
    product: ICatalogProduct
    large?: boolean
}

export default observer(function LimitedQuantityDateOverlay({product: {limitedQuantity, limitedDates}, large}: Props)
{
    if (limitedQuantity == null && !limitedDates)
    {
        return null;
    }
    return (
        <div className={styles.overlay + ' ' + (large ? styles.large : '')}>
            <img src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/product/limited.svg' alt=''/>
            <div>
                {limitedQuantity != null && (
                    limitedQuantity > 0 ?
                        <div>{t.catalog.limitedQuantity}<br/>{limitedQuantity}</div>
                        :
                        <div>{t.catalog.outOfStock}</div>
                )}
                {!!limitedDates && t.catalog.limitedDates(limitedDates.join(', '))}
            </div>
        </div>
    );
});
