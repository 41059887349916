// used on backend as well
import {IStandDiscount, IStandProduct, IStandSubmitted} from '../../graphql/api/stand/Stand';

export function orderSectionTotal(section: IStandSubmitted)
{
    return (section?.package ? discountedOrderTotal(section.package.price, section.package.discount).total : 0)
        + (section?.products ? orderProductsTotal(section.products) : 0);
}

export function orderProductsTotal(products: IStandProduct[])
{
    return products.reduce((sum, p) => sum + orderProductTotal(p).total, 0);
}

export function orderProductTotal({price, quantity, discount}: IStandProduct)
{
    return discountedOrderTotal(+price * (quantity || 1), discount);
}

export function discountedOrderTotal(price: number, discount: IStandDiscount[])
{
    const basePrice = +price;
    const discountedAmounts: number[] = [];
    let total = basePrice;
    if (discount)
    {
        for (let i = 0; i < discount.length; ++i)
        {
            const d = discount[i];
            total += (discountedAmounts[i] = d.type === 'percentage' ? (d.amount - 1) * total : d.amount);
        }
    }
    return {
        basePrice,
        discountedAmounts,
        total,
    };
}
