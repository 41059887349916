import React from 'react';
import {observer} from 'mobx-react-lite';
import {projectStore} from '../stores/ProjectStore';
import {countryCurrency} from '../OrderStand/utility';
import {t} from '../translations';
import {user} from '../stores/user';

interface Props
{
    storeType: string
    invoice?: number
    selling?: number
    discount?: number
    paid?: number
    total?: number
    // FR Non Food
    flow?: 'GSE' | 'GSA' | undefined
}

export default observer(function CartOrderTotal({storeType, invoice, selling, discount, paid, total, flow}: Props)
{
    paid = (total ? total : paid) || 0;
    const isFair = !projectStore.isPromotion;
    const country = projectStore.selectedCountry;
    const isFRNonFood = projectStore.isFRNonFood;
    const currency = countryCurrency(country);
    const isFranchising = ['MF', 'E'].includes(storeType);

    return (
        <div className='totals'>
            {country == 'BE' ?
                isFair ?
                    isFranchising ?
                        <>
                            <p className='total-invoice'><span>{t.reports.total} PF</span><span>{user.formatCurrency(currency, invoice)}</span></p>
                            <p className='discount'><span>{t.global.discount}</span><span>{user.formatCurrency(currency, discount)}</span></p>
                            <p className='grand-total'><span>{t.cart.toBePaid}</span><span>{user.formatCurrency(currency, paid)}</span></p>
                        </>
                        :
                        <p className='grand-total'><span>{t.cart.toBePaid}</span><span>{user.formatCurrency(currency, paid)}</span></p>
                    :
                    <p className='grand-total'><span>{isFranchising ? 'Total PROMO' : 'Total Cost'}</span><span>{user.formatCurrency(currency, paid)}</span></p>
                :
                country == 'PL' ?
                    discount ?
                        <>
                            <p className='total-invoice'><span>{t.cart.totalInvoicePrice}</span><span>{user.formatCurrency(currency, invoice)}</span></p>
                            <p className='discount'><span>{t.global.discount}</span><span>{user.formatCurrency(currency, discount)}</span></p>
                            <p className='grand-total'><span>{t.cart.toBePaid}</span><span>{user.formatCurrency(currency, paid)}</span></p>
                        </>
                        :
                        <p className='grand-total'><span>{t.cart.toBePaid}</span><span>{user.formatCurrency(currency, paid)}</span></p>
                    :
                    country == 'FR' ?
                        !!selling && (
                            isFRNonFood ?
                                'CPI' == storeType ?
                                    <p className='grand-total'>
                                        <span>{`Total ${flow == 'GSA' ? 'FOB' : 'Px Cession'} : `}</span>
                                        <span>{user.formatCurrency(currency, invoice)}</span>
                                    </p>
                                    :
                                    <>
                                        <p className='grand-total'><span>{'Total Px Cession : '}</span><span>{user.formatCurrency(currency, invoice)}</span></p>
                                        <p className='grand-total'><span>{'Total PVC : '}</span><span>{user.formatCurrency(currency, selling)}</span></p>
                                    </>
                                :
                                <p className='grand-total'><span>{'Total PVC : '}</span><span>{user.formatCurrency(currency, selling)}</span></p>
                        )
                        :
                        country == 'IT' ?
                            <>
                                {!!discount && <p className='discount'><span>{t.cart.discountTotal}</span><span>{user.formatCurrency(currency, discount)}</span></p>}
                                <p className='grand-total'><span>{t.cart.totalIT}</span><span>{user.formatCurrency(currency, paid)}</span></p>
                                <p className='text-small'>{t.catalog.netWithDiscount}</p>
                            </>
                            :
                            null
            }
        </div>
    );
});
