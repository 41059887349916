import {call, gql} from '../client';
import {IStand} from './OrderStandStore';
import {IStandDiscount, IStandSetup, StandType} from '../../graphql/api/stand/Stand';
import {Country, ITranslations} from '../../graphql/api/Types';

export type StandSetupInput = Omit<IStandSetup, 'type' | 'realSurface'> & {id: string};

export interface CreateStandInput
{
    type: StandType
    project: string
    companyId?: string
}
export interface SetStandProjectInput
{
    id: string
    project: string
}

export interface SelectProductsInput
{
    id: string
    products: SelectProductInput[]
}
export interface SelectProductInput
{
    id: string
    quantity: number
}
export interface StandPreferenceInput
{
    id: string
    preference: string
}

export interface ChangeStandItemsInput
{
    id: string
    section: string
    products?: ChangeProductInput[]
    packageDiscount?: IStandDiscount[]
}
interface ChangeProductInput
{
    id: string
    price: number
    quantity: number
    discount?: IStandDiscount[]
}

export interface ChangeStandPackageProductDiscountInput
{
    id: string
    productId: string
    discount?: IStandDiscount[]
}

export interface RenameStandInput
{
    id: string
    name: string
}

export interface ChangeStandPositionInput
{
    id: string
    position: string
}

export interface IPalletType
{
    id: string
    name: ITranslations
}

export function getStands(project: string)
{
    return call<{stands: IStand[]}>(gql`query($project:ID!){stands(project:$project){
        id,project,companyId,name,PO,position,preference,submittedBy,attachments,standLayouts{layout,date,downloaded},
        setup{type,surface,realSurface,spaceRange,dimensions{length,depth,unit},corner,head,open},
        created{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}},includesLimitedStock},
        submitted{package{id,name,price,discount{name,type,amount},submittedBy,products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,submittedBy,discount{name,type,amount}}},
        approved{package{id,name,price,invoice,submittedBy,approvedBy,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,submittedBy,approvedBy,discount{name,type,amount},invoice}},
        invoiced{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}
        canceled{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}}},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice},cancelInvoiceId,canceledInvoiceId,partiallyCanceled},
    }}`, {project})
    .then(({data}) => data?.stands);
}
export function getStand(id: string)
{
    return call<{stand: IStand}>(
        gql`query ($id:ID!){stand(id:$id){
            id,project,companyId,name,PO,position,preference,submittedBy,attachments,standLayouts{layout,date,downloaded},
            setup{type,surface,realSurface,spaceRange,dimensions{length,depth,unit},corner,head,open},
            created{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}},includesLimitedStock},
            submitted{package{id,name,price,discount{name,type,amount},submittedBy,products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,submittedBy,discount{name,type,amount}}},
            approved{package{id,name,price,invoice,submittedBy,approvedBy,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,submittedBy,approvedBy,discount{name,type,amount},invoice}},
            invoiced{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}
            canceled{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}}},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice},cancelInvoiceId,canceledInvoiceId,partiallyCanceled},
        }}`, {id})
    .then(({data}) => data?.stand);
}
export function createStand(input: CreateStandInput)
{
    return call<{createStand: IStand}>(
        gql`mutation ($input:CreateStandInput!){createStand(input:$input){
            id,project,companyId,name,PO,position,preference,attachments,
            setup{type,surface,realSurface,spaceRange,dimensions{length,depth,unit},corner,head,open},
            created{package{id,name,price,products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}}},
            submitted{package{id,name,price,products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}}},
            approved{package{id,name,price,invoice,products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount},invoice}}}}`,
        {input});
}
export function setStandProject(input: SetStandProjectInput)
{
    return call<{setStandProject: IStand}>(
        gql`mutation ($input:SetStandProjectInput!){setStandProject(input:$input){id,project}}`, {input})
    .then(({data}) => data?.setStandProject);
}
export function setupStand(input: StandSetupInput)
{
    return call<{setupStand: IStand}>(
        gql`mutation ($input:StandSetupInput!){setupStand(input:$input){
            id,
            created{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType}},
            submitted{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType}},
            approved{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount},invoice}}}}`,
        {input})
    .then(({data}) => data?.setupStand);
}
export function selectPackage(standId: string, packageId: string)
{
    return call<{selectPackage: IStand}>(
        gql`mutation ($input:SelectPackageInput!){selectPackage(input:$input){
            id,
            created{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}},includesLimitedStock},
            submitted{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}}},
            approved{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount},invoice}}
            invoiced{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}}}`,
        {input: {standId, packageId}})
    .then(({data}) => data?.selectPackage);
}
export function selectProducts(input: SelectProductsInput)
{
    return call<{selectProducts: IStand}>(
        gql`mutation ($input:SelectProductsInput!){selectProducts(input:$input){id,created{products{id,name,price,quantity,discount{name,type,amount}},includesLimitedStock}}}`,
        {input})
    .then(({data}) => data?.selectProducts);
}
export function removeOutOfStockProducts(id: string)
{
    return call<{removeOutOfStockProducts: IStand}>(
        gql`mutation ($id:ID!){removeOutOfStockProducts(id:$id){id,created{products{id,name,price,quantity,discount{name,type,amount}},includesLimitedStock}}}`,
        {id}
    ).then(({data}) => data?.removeOutOfStockProducts);
}
export function submitStand(id: string, PO?: string)
{
    return call<{submitStand: IStand}>(
        gql`mutation ($input:SubmitStandInput!){submitStand(input:$input){
            id,PO,submittedBy,
            created{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}},includesLimitedStock}
            submitted{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}}}}}`,
        {input: {id, PO}});
}
export function standPreference(input: StandPreferenceInput)
{
    return call<{standPreference: IStand}>(
        gql`mutation ($input:StandPreferenceInput!){standPreference(input:$input){id,preference}}`, {input})
    .then(({data}) => data?.standPreference);
}
export function approveStand(id: string)
{
    return call<{approveStand: IStand}>(
        gql`mutation ($id:ID!){approveStand(id:$id){
            id,
            submitted{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}}},
            approved{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount},invoice}}}}`,
        {id})
    .then(({data}) => data?.approveStand);
}
export function declineStand(id: string)
{
    return call<{declineStand: IStand}>(
        gql`mutation ($id:ID!){declineStand(id:$id){
            id,
            submitted{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}}}}}`,
        {id})
    .then(({data}) => data?.declineStand);
}
export function changeStandItems(input: ChangeStandItemsInput)
{
    return call<{changeStandItems: IStand}>(
        gql`mutation ($input:ChangeStandItemsInput!){changeStandItems(input:$input){
            setup{type,surface,realSurface,spaceRange,dimensions{length,depth,unit},corner,head,open},
            created{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}}},
            submitted{package{id,name,price,discount{name,type,amount},submittedBy,products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}}},
            approved{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount},invoice}}
            invoiced{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount},invoice}}}}`,
        {input});
}
export function changeStandPackageProductDiscounts(input: ChangeStandPackageProductDiscountInput)
{
    return call<{changeStandPackageProductDiscounts: IStand}>(
        gql`mutation ($input:ChangeStandPackageProductDiscountInput!){changeStandPackageProductDiscounts(input:$input){
            created{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}}},
            submitted{package{id,name,price,discount{name,type,amount},submittedBy,products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}}},
            approved{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount},invoice}}
            invoiced{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount},invoice}}}}`,
        {input}
    );
}
export function renameStand(input: RenameStandInput)
{
    return call<{renameStand: IStand}>(gql`mutation ($input:RenameStandInput!){renameStand(input:$input){id,name}}`, {input})
    .then(({data}) => data?.renameStand);
}
export function changeStandPosition(input: ChangeStandPositionInput)
{
    return call<{changeStandPosition: IStand}>(gql`mutation ($input:ChangeStandPositionInput!){changeStandPosition(input:$input){id,position}}`, {input})
    .then(({data}) => data?.changeStandPosition);
}
export function standAttachments(input: {add: string[], remove: string, standId: string})
{
    return call<{standAttachments: IStand}>(gql`mutation ($input:StandAttachmentsInput!){standAttachments(input:$input){id,attachments}}`, {input})
    .then(({data}) => data?.standAttachments);
}
export function deleteStand(standId: string)
{
    return call<{deleteStand: boolean}>(gql`mutation ($standId:ID!){deleteStand(standId:$standId)}`, {standId})
    .then(({data}) => data?.deleteStand);
}
export function duplicateStand(id: string)
{
    return call<{duplicateStand: IStand}>(
        gql`mutation($id:ID!){duplicateStand(id:$id){
            id,project,companyId,name,PO,position,preference,submittedBy,attachments,standLayouts{layout,date,downloaded},
            setup{type,surface,realSurface,spaceRange,dimensions{length,depth,unit},corner,head,open},
            created{package{id,name,price,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,discount{name,type,amount}}},
            submitted{package{id,name,price,discount{name,type,amount},submittedBy,products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,submittedBy,discount{name,type,amount}}},
            approved{package{id,name,price,invoice,submittedBy,approvedBy,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,submittedBy,approvedBy,discount{name,type,amount},invoice}},
            invoiced{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice}}
            canceled{package{id,name,price,invoice,discount{name,type,amount},products{id,name,price,quantity,discount{name,type,amount}},includesPallets,palletType},products{id,name,price,quantity,invoice,discount{name,type,amount},invoice},cancelInvoiceId,canceledInvoiceId,partiallyCanceled},
        }}`, {id}
    ).then(({data}) => data?.duplicateStand);
}
export function addStandLayout(standId: string, url: string)
{
    return call<{addStandLayout: {layout: string, date: Date}[]}>(gql`mutation ($input:AddStandLayoutInput!){addStandLayout(input:$input){layout,date}}`, {input: {standId, url}})
    .then(({data}) => data?.addStandLayout);
}

export function markLayoutAsDownloaded(standId: string, layout: string)
{
    return call(
        gql`mutation($input: MarkStandAsDownloadedInput!){markStandLayoutAsDownloaded(input:$input)}`, {input: {standId, layout}})
    .then(({data}) => data?.markStandLayoutAsDownloaded);
}

export function getPalletTypes(country: Country)
{
    return call<{palletTypes: IPalletType[]}>(gql`query($country:String!){palletTypes(country:$country){id,name}}`, {country})
    .then(({data}) => data?.palletTypes);
}

export function selectPalletType(id: string, palletType: string)
{
    return call<{selectPalletType: string}>(
        gql`mutation($input:SelectPalletTypeInput!){selectPalletType(input:$input)}`,
        {input: {id, palletType}}
    ).then(({data}) => data?.selectPalletType);
}

export function setStandRealSurface(id: string, realSurface: number)
{
    return call<{setupStand: IStand}>(
        gql`mutation ($input:StandRealSurfaceInput!){setStandRealSurface(input:$input){id,setup {realSurface}}}`,
        {input: {id, realSurface}}
    ).then(({data}) => data?.setupStand);
}
