import type {Language} from '../../graphql/api/Types';
import type {ExtendedPageItemType, PageItemType} from '../../graphql/api/page/Page';
import type {ContentBlockProps} from './ContentBlock'
import type {VideoBlockProps} from './VideoBlock';
import type {SlideshowBlockProps} from './SlideshowBlock';
import type {ArticleBlockProps} from './ArticleBlock';
import type {FileBlockProps} from './FileBlock';

const isBlockEmptyMap: {[type in PageItemType]: (props) => boolean} = {
    content({items}: ContentBlockProps)
    {
        return !items?.some(current =>
            (current.text?.display && pickLanguage(current.text.title)) ||
            (current.image?.display && pickLanguage(current.image.url)) ||
            current.background?.display
        );
    },
    product: null,
    video({url}: VideoBlockProps)
    {
        return !pickLanguage(url);
    },
    slideshow({content}: SlideshowBlockProps)
    {
        return !pickLanguage(content);
    },
    article({content}: ArticleBlockProps)
    {
        return !pickLanguage(content);
    },
    file({text, image, background}: FileBlockProps)
    {
        return !text?.display && !image?.display && !background?.display;
    },
};

export default function isBlockEmpty(type: PageItemType | ExtendedPageItemType, props)
{
    if (!props)
    {
        return true;
    }
    const func = isBlockEmptyMap[type];
    return func ? func(props) : true;
}

// returns any language
function pickLanguage<T>(multiLang: {[L in Language]?: T}): T
{
    if (!multiLang)
    {
        return null;
    }
    const languages = Object.getOwnPropertyNames(multiLang);
    if (!languages.length)
    {
        return null;
    }
    for (const l of languages)
    {
        if (multiLang[l])
        {
            return multiLang[l];
        }
    }
}
