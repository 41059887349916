// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactSelection {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.ContactSelection .selected {
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
}
.ContactSelection .options {
  position: absolute;
  z-index: 100;
}
.ContactSelection .input {
  flex-grow: 1;
}
.ContactSelection .input input {
  border: none;
  outline: none;
  padding: 5px 0 10px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./frontend/Pages/ContactSelection.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,eAAA;AACF;AAAE;EACE,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,kBAAA;EAEA,YAAA;AACJ;AACE;EACE,YAAA;AACJ;AAAI;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;AAEN","sourcesContent":[".ContactSelection {\n  position: relative;\n  display: flex;\n  flex-wrap: wrap;\n  .selected {\n    border-radius: 1rem;\n    background: rgba(0,0,0,.1);\n    padding: 5px 10px;\n    cursor: pointer;\n    margin-right: 10px;\n    margin-bottom: 5px;\n  }\n  .options {\n    position: absolute;\n    //width: 100%;\n    z-index: 100;\n  }\n  .input {\n    flex-grow: 1;\n    input {\n      border: none;\n      outline: none;\n      padding: 5px 0 10px;\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
