import React, {useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import './VideoBlock.scss';
import DropZone from '../Email/DropZone';
import {Upload} from '../Upload/UploadStore';
import {pageStore} from './PageStore';
import BlockOptions from './BlockOptions';
import {videoStore} from '../Upload/VideoStore';
import {formatVideoEmbedUrl} from '../../lib/common';
import {ITranslations} from '../../graphql/api/Types';
import {user} from '../stores/user';
import {CommonBlockProps} from './blockMap';
import EmptyBlock from './EmptyBlock';
import {pickLanguage} from '../stores/utility';
import {requestPageVideoUpload} from './pageProxy';
import {UploadProgressBar} from './UploadProgressBar';
import {t} from '../translations';

export interface VideoBlockProps extends CommonBlockProps
{
    published?: boolean
    url?: ITranslations
}

export default observer(function VideoBlock(
    {pageId, id, published, url, imported, layout, fullAccess, editAccess, enableBlockOptions, placeholderText, placeholderImage}: VideoBlockProps
)
{
    const [showOptions, setShowOptions] = useState(false);
    const [upload, setUpload] = useState<Upload>();

    const handleFile = async (file: File) =>
    {
        if (!user.moderator && !user.info.video && !await requestPageVideoUpload(pageId))
        {
            alert('Video Upload Access Denied');
            return;
        }
        videoStore.upload(file, u =>
        {
            if (u.error)
            {
                setUpload(null);
            }
            else
            {
                pageStore.changeItem(pageId, id, 'url', {...url, [user.language]: u.url});
                setTimeout(() =>
                {
                    setUpload(null);
                }, 1000);
            }
        }).then(u =>
        {
            setUpload(u);
        });
    };

    const fileInputRef = useRef<HTMLInputElement>();

    const videoUrl = pickLanguage(url);

    return (
        <div
            tabIndex={0}
            className={'VideoBlock' + (videoUrl ? '' : ' border d-flex justify-content-center align-items-center')}
            onClick={showOptions && !imported ? () => fileInputRef.current.click() : null}
            onDoubleClick={enableBlockOptions ? () =>
            {
                setShowOptions(true);
                pageStore.focusItem(pageId, id);
            } : null}
            onBlur={enableBlockOptions ? e =>
            {
                if (e.relatedTarget ?
                    !e.currentTarget.contains(e.relatedTarget as any) :
                    e.currentTarget == e.target || !e.currentTarget.contains(e.target as any)
                )
                {
                    setShowOptions(false);
                }
            } : null}
        >
            {!imported && enableBlockOptions &&
            <DropZone onAttachment={handleFile} onlySingleFile>{' '}</DropZone>}
            {!imported && enableBlockOptions &&
            <input ref={fileInputRef} className='d-none' type='file' accept='video/*' onChange={e => e.target.files.length && handleFile(e.target.files[0]) }/>}
            {!upload && !!videoUrl &&
            <iframe
                className={enableBlockOptions ? 'pointer-events-none' : null}
                src={formatVideoEmbedUrl(videoUrl, 'loop=1&byline=0&title=0')}
                frameBorder='0'
                allow='fullscreen'
                allowFullScreen
            />}
            {!!upload && !upload.ended && <UploadProgressBar progress={upload.progress}/>}
            {editAccess && !(upload ? upload.finished : videoUrl) &&
            <EmptyBlock
                layout={layout}
                name={t.editPage.blockVideo + ' 16:9'}
                msg={!imported && showOptions ? t.editPage.DragDropUpload : ' '}
                text={placeholderText}
                image={placeholderImage}
            />}
            {showOptions &&
            <BlockOptions
                pageId={pageId}
                itemId={id}
                enableBlockOptions={enableBlockOptions}
                fullAccess={fullAccess}
                restrictedOptions={<>
                    <input
                        type='url'
                        className='form-control'
                        placeholder='Video URL'
                        value={videoUrl}
                        onChange={e => pageStore.changeItem(pageId, id, 'url', {...url, [user.language]: e.target.value})}
                    />
                </>}
            >
                <div>
                    <p className='font-weight-bold mb-4'>{t.editPage.blockVideo} (16:9)</p>
                </div>
                <div>
                    <div className='form-check form-check-inline mb-3'>
                        <input
                            id='options-published'
                            type='checkbox'
                            className='form-check-input'
                            checked={!!published}
                            onChange={e => pageStore.changeItem(pageId, id, 'published', e.target.checked)}
                        />
                        <label htmlFor='options-published' className='form-check-label font-weight-bold'>{t.editPage.published}</label>
                    </div>
                    {fullAccess &&
                    <button
                        className='button mb-3'
                        onClick={() =>
                        {
                            if (confirm('Do you want to delete this block?'))
                            {
                                pageStore.deleteItem(pageId, id);
                            }
                        }}
                    >
                        {t.editPage.deleteContent}
                    </button>}
                </div>
            </BlockOptions>
            }
        </div>
    );
});
