import { observer } from 'mobx-react-lite';
import { user } from '../stores/user';
import { companyStore } from './CompanyStore';
import { ProfileItem } from '../common/ProfileItem';
import AddCustomTag from '../common/addCustomTag';
import DropdownSearch from '../common/DropdownSearch';
import { getSectorItems } from '../data/sectors';
import { t } from '../translations';
import { Country } from '../../graphql/api/Types';

interface Props
{
    id?: string;
}

const handleUpdateField = (obj, value) =>
{
    companyStore.update(obj, value.trim());
};

const handleAddTag = () =>
{
    companyStore.addCode();
};
const handleRemoveTag = (index: number) =>
{
    companyStore.removeCode(index);
};
const handleChangeText = (text: string, index: number) =>
{
    companyStore.update(['exhibitor', 'code', index.toString()], text.trim());
};

const handleSectors = (sector: string) => (value: string) =>
{
    companyStore.update(['exhibitor', 'sector', sector], value);
};

const handlePreventPageEdit = e =>
{
    companyStore.update(['exhibitor', 'preventPageEdit'], !e.currentTarget.checked);
};

export default observer(function CompanySystem({id}: Props)
{
    const isOwnCompany = !id || user.info?.company.includes(id);
    const editable = (isOwnCompany && !user.isHeadquarter && !user.isStore) || user.moderator;
    const company = companyStore.company;
    const companyCountry = pickCountry(company.country);
    const options = getSectorItems(user.language, companyCountry).map(s =>
        ({ value: s.keys.join('-'), name: s.names.join(' > ') }));

    const storeIdLabel = () => company.country.includes('FR') && company.store?.storeType?.length > 2 ? 'N° Client' : t.companyPage.storeId;

    return user.moderator ? (
        <div className='mb-4 company-additionalInfo'>
            <h5 className='pb-2 mb-4 border-bottom border-dark'>{t.companyPage.system}</h5>
            <div className='d-flex'>
                <span>{storeIdLabel()}: </span>
                <ProfileItem
                    className='d-inline-block'
                    objKey={['store', 'id']}
                    content={company.store?.id || storeIdLabel()}
                    onChanged={handleUpdateField}
                    editable={editable || user.moderator}
                    dimmed={company.store?.id == 'Store ID' || !company.store?.id}
                />
            </div>
            <div className='d-flex mt-3'>
                <span>{t.companyPage.codes}: </span>
                <AddCustomTag
                    className='pl-2'
                    tags={company.exhibitor.code || []}
                    onAdd={handleAddTag}
                    onRemove={handleRemoveTag}
                    onTagTextChange={handleChangeText}
                />
            </div>
            <div className='sectors mt-3'>
                <span>{t.companyPage.primarySector}: </span>
                <DropdownSearch
                    className='d-inline-block pl-2'
                    options={options.filter(o => o.value !== company.exhibitor.sector?.secondary)}
                    selected={company.exhibitor.sector?.primary || ''}
                    onSelect={handleSectors('primary')}
                />
            </div>
            <div className='sectors mt-3'>
                <span>{t.companyPage.secondarySector}: </span>
                <DropdownSearch
                    className='d-inline-block pl-2'
                    options={options.filter(o => o.value !== company.exhibitor.sector?.primary)}
                    selected={company.exhibitor.sector?.secondary || ''}
                    onSelect={handleSectors('secondary')}
                />
            </div>
            <div className='mt-3'>
                <input
                    type='checkbox'
                    id='company-prevent-page-edit'
                    checked={!company.exhibitor?.preventPageEdit}
                    onChange={handlePreventPageEdit}
                />
                <label htmlFor='company-prevent-page-edit' className='pl-3 mb-0'>{t.companyPage.allowPageEdit}</label>
            </div>
            {!!company.store?.warehouse &&
            <div className='mt-3'>
                <span>Warehouse: </span>
                <table>
                    <tbody>
                    {Object.getOwnPropertyNames(company.store.warehouse).map(key =>
                    {
                        const v = company.store.warehouse[key];
                        return <tr key={key}><td className='pr-2'>{key}</td><td>{v ? Array.isArray(v) ? v.length ? v.join(', ') : <i>none</i> : v : <i>none</i>}</td></tr>;
                    })}
                    </tbody>
                </table>
            </div>
            }
        </div>
    ) : (
        user.isHostess ? (
            <div className='pb-4'>
                <div className='mb-4 company-additionalInfo exhibitor-view'>
                    <h5 className='pb-2 mb-4 border-bottom border-dark'>{t.companyPage.system}</h5>
                    <div className='sectors mt-3 d-flex'>
                        <div>{storeIdLabel()}: </div>
                        <div>{company.store?.id}</div>
                    </div>
                </div>
            </div>
        ) :
            company.store?.id ? (
                <div className='mb-4 company-additionalInfo exhibitor-view'>
                    <h5 className='pb-2 mb-4 border-bottom border-dark'>{t.companyPage.system}</h5>
                    <div className='sectors mt-3 d-flex'>
                        <div>{storeIdLabel()}: </div>
                        <div>{company.store?.id}</div>
                    </div>
                </div>
            ) : company.exhibitor?.sector ? (
                <div className='mb-4 company-additionalInfo exhibitor-view'>
                    <h5 className='pb-2 mb-4 border-bottom border-dark'>{t.companyPage.system}</h5>
                    <div className='sectors mt-3 d-flex'>
                        <div>{t.companyPage.primarySector}: </div>
                        <div>{options.find(s => s.value == company.exhibitor.sector.primary)?.name || ''}</div>
                    </div>
                    <div className='sectors mt-3 d-flex'>
                        <div>{t.companyPage.secondarySector}: </div>
                        <div>{options.find(s => s.value == company.exhibitor.sector.secondary)?.name || ''}</div>
                    </div>
                </div>
            ) : null
    );
});

function pickCountry(countries: string[]): Country
{
    if (countries.length > 1)
    {
        for (const c of countries)
        {
            if (['BE', 'IT', 'PL', 'FR'].includes(c))
            {
                return c as Country;
            }
        }
    }
    return countries[0] as Country;
}
