import {KeyboardEvent} from 'react';

export function handleEnter<T>(handler: (e: KeyboardEvent<T>) => void)
{
    return (e: KeyboardEvent<T>) =>
    {
        if (e.key == 'Enter')
        {
            handler(e);
        }
    };
}
