import {ISearchResults} from '../../graphql/api/search/Search';
import {makeObservable, observable, toJS} from 'mobx';
import {tryGetObj, trySetObj} from '../stores/userPersist';
import {registerGlobalEventHandler} from '../stores/globalEvents';

const key = 'search-history';

// type SearchHistoryItems = ({type: keyof ISearchResults} & ISearchResults[keyof ISearchResults][number])[]

type SearchHistoryItem = {[K in keyof ISearchResults]: {type: K} & ISearchResults[K][number]}[keyof ISearchResults]
type SearchHistoryItems = SearchHistoryItem[]

class SearchHistoryStore
{
    @observable items: SearchHistoryItems = [];

    constructor()
    {
        makeObservable(this);
        registerGlobalEventHandler('logout', () =>
        {
            trySetObj(key, null);
            this.items = [];
        });
        const prev = tryGetObj<SearchHistoryItems>(key);
        if (Array.isArray(prev))
        {
            this.items = prev;
        }
    }

    private save()
    {
        trySetObj<SearchHistoryItems>(key, this.items);
    }

    add<T extends keyof ISearchResults>(type: T, item: ISearchResults[T][number])
    {
        this.items = this.items.filter(e => e.id != item.id);
        const copy = JSON.parse(JSON.stringify(toJS(item)).replace(/<em>|<\/em>/g, '')) as SearchHistoryItem;
        copy.type = type;
        this.items.unshift(copy);
        this.save();
    }

    remove(id: string)
    {
        this.items = this.items.filter(e => e.id != id);
        this.save();
    }
}

export const searchHistoryStore = new SearchHistoryStore();
