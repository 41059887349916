import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {IPage, pageStore} from './PageStore';
import {pageProductOptionsStore} from './PageProductOptionsStore';
import DropdownSearch from '../common/DropdownSearch';
import {user} from '../stores/user';
import {canAddPageItems} from './Page';
import {exhibitorStore} from '../ExhibitorProducts/ExhibitorStore';
import {getSectorItems} from '../data/sectors';
import {projectStore} from '../stores/ProjectStore';
import {pickLanguage} from '../stores/utility';
import {getBusinessUnitValue} from '../../graphql/api/exhibitorProduct/utility';
import {IPagePartialProduct} from '../../graphql/api/page/Page';
import {pageProductsPromoCodesStore} from './PageProductsPromoCodesStore';

interface Props
{
    page: IPage
}

export default observer(function CatalogOptions({page}: Props)
{
    const projId = page.project;
    const canAddItems = canAddPageItems(page);

    const [tab, setTab] = useState<keyof typeof TabComponentMap>(() =>
        page.catalog?.products?.ids?.length ?
            'products'
            :
            page.catalog?.products?.promoCodes?.length ?
                'promoCodes'
                :
                page.catalog?.products?.articleCodes?.length ?
                    'articleCodes'
                    :
                    page.catalog?.products?.categories?.length ?
                        'categories'
                        :
                        page.catalog?.products?.partial?.length ?
                            'partial'
                            :
                            'exhibitors'
    );

    const [shouldLoadProductList, setShouldLoadProductList] = useState(false);
    const [shouldLoadProductsPromoCodesList, setShouldLoadProductsPromoCodesList] = useState(false);
    const [shouldLoadExhibitorList, setShouldLoadExhibitorList] = useState(false);

    useEffect(() =>
    {
        if (tab === 'products' || tab === 'partial')
        {
            setShouldLoadProductList(true);
        }
        if (tab === 'partial')
        {
            setShouldLoadProductsPromoCodesList(true);
        }
        if (tab === 'exhibitors')
        {
            setShouldLoadExhibitorList(true);
        }
    }, [tab]);

    useEffect(() =>
    {
        if (projId && shouldLoadProductList)
        {
            pageProductOptionsStore.load(projId);
        }
    }, [projId, shouldLoadProductList]);

    const partialIds = page.catalog?.products?.partial?.map(p => p.id);
    useEffect(() =>
    {
        if (projId && shouldLoadProductsPromoCodesList && partialIds?.length)
        {
            pageProductsPromoCodesStore.load(partialIds);
        }
    }, [partialIds?.join(''), shouldLoadProductsPromoCodesList]);

    useEffect(() =>
    {
        if (projId && shouldLoadExhibitorList && user.moderator)
        {
            exhibitorStore.reload(projId);
        }
    }, [projId, shouldLoadExhibitorList]);

    const TabComponent = TabComponentMap[tab];

    return (
        <div className='catalog-options'>
            <div className='tabs'>
                <div className={tab == 'exhibitors' ? 'font-weight-bold' : null} onClick={() => setTab('exhibitors')}>List all products of the selected companies</div>
                <div className={tab == 'products' ? 'font-weight-bold' : null} onClick={() => setTab('products')}>Add products</div>
                {canAddItems && <div className={tab == 'promoCodes' ? 'font-weight-bold' : null} onClick={() => setTab('promoCodes')}>Add promotion code</div>}
                {canAddItems && <div className={tab == 'articleCodes' ? 'font-weight-bold' : null} onClick={() => setTab('articleCodes')}>Add article code</div>}
                {canAddItems && <div className={tab == 'categories' ? 'font-weight-bold' : null} onClick={() => setTab('categories')}>Add categories</div>}
                {canAddItems && <div className={tab == 'partial' ? 'font-weight-bold' : null} onClick={() => setTab('partial')}>Add partial products</div>}
            </div>
            <TabComponent page={page}/>
        </div>
    );
});

const TabExhibitors = observer(function TabExhibitors({page}: Props)
{
    const projId = page.project;
    return (
        <DropdownSearch
            placeholder='Add exhibitors'
            options={exhibitorStore.list[projId]?.map(e => ({name: e.name, value: e.id})) || []}
            selected={page.catalog?.products?.exhibitors || []}
            onSelect={value =>
                pageStore.changePage(
                    page.id,
                    ['catalog', 'products', 'exhibitors'],
                    page.catalog?.products?.exhibitors?.length ? [...page.catalog.products.exhibitors, value] : [value])
            }
            onRemove={value =>
                pageStore.changePage(
                    page.id,
                    ['catalog', 'products', 'exhibitors'],
                    page.catalog.products.exhibitors.filter(p => p != value)
                )
            }
            onRearrange={selected => pageStore.changePage(page.id, ['catalog', 'products', 'exhibitors'], selected)}
        />
    );
});

const TabProducts = observer(function TabProducts({page}: Props)
{
    const projId = page.project;
    return (
        <DropdownSearch
            placeholder='Add products'
            options={pageProductOptionsStore.products[projId]?.map(p => ({
                value: p.id,
                name: pickLanguage(getBusinessUnitValue(p.description)) || '',
                keywords: [p.articleCode],
            })) || []}
            selected={page.catalog?.products?.ids || []}
            onSelect={value =>
                pageStore.changePage(
                    page.id,
                    ['catalog', 'products', 'ids'],
                    page.catalog?.products?.ids?.length ? [...page.catalog.products.ids, value] : [value]
                )
            }
            onRemove={value =>
                pageStore.changePage(
                    page.id,
                    ['catalog', 'products', 'ids'],
                    page.catalog.products.ids.filter(p => p != value)
                )
            }
            onRearrange={selected => pageStore.changePage(page.id, ['catalog', 'products', 'ids'], selected)}
        />
    );
});

const TabPromoCodes = observer(function TabPromoCodes({page}: Props)
{
    return (
        <DropdownSearch
            placeholder='Add promo codes'
            options={[]}
            selected={page.catalog?.products?.promoCodes || []}
            allowCustom
            onCustom={value =>
                pageStore.changePage(
                    page.id,
                    ['catalog', 'products', 'promoCodes'],
                    page.catalog?.products?.promoCodes?.length ? [...page.catalog.products.promoCodes, value] : [value]
                )
            }
            onRemove={value =>
                pageStore.changePage(
                    page.id,
                    ['catalog', 'products', 'promoCodes'],
                    page.catalog.products.promoCodes.filter(p => p != value))
            }
        />
    );
});

const TabArticleCodes = observer(function TabArticleCodes({page}: Props)
{
    return (
        <textarea
            placeholder='Add article codes (comma separated)'
            className='form-control'
            defaultValue={page.catalog?.products?.articleCodes?.join(', ')}
            onChange={e =>
                pageStore.changePage(
                    page.id,
                    ['catalog', 'products', 'articleCodes'],
                    e.currentTarget.value.split(',').map(c => c.trim()).filter(c => c)
                )
            }
        />
    );
});

const TabCategories = observer(function TabCategories({page}: Props)
{
    return (
        <DropdownSearch
            placeholder='Add categories'
            options={getSectorItems(user.language, projectStore.selectedCountry).map(s => ({value: s.keys.join('-'), name: s.names.join(' > '), keyword: s.keyword}))}
            selected={page.catalog?.products?.categories || []}
            onSelect={value =>
                pageStore.changePage(
                    page.id,
                    ['catalog', 'products', 'categories'],
                    page.catalog?.products?.categories?.length ? [...page.catalog.products.categories, value] : [value])
            }
            onRemove={value =>
                pageStore.changePage(
                    page.id,
                    ['catalog', 'products', 'categories'],
                    page.catalog.products.categories.filter(p => p != value)
                )
            }
            onRearrange={selected => pageStore.changePage(page.id, ['catalog', 'products', 'categories'], selected)}
        />
    );
});

const TabPartial = observer(function TabPartial({page}: Props)
{
    const projId = page.project;
    const allProductNames = pageProductOptionsStore.products[projId];
    return (
        <>
            <DropdownSearch
                placeholder='Add partial products'
                options={allProductNames?.map(p => ({
                    value: p.id,
                    name: pickLanguage(getBusinessUnitValue(p.description)) || '',
                    keywords: [p.articleCode],
                })) || []}
                selected={page.catalog?.products?.partial?.map(p => p.id) || []}
                onSelect={value =>
                {
                    const newItem: IPagePartialProduct = {id: value, promoCodes: []};
                    pageStore.changePage(
                        page.id,
                        ['catalog', 'products', 'partial'],
                        page.catalog?.products?.partial?.length ? [...page.catalog.products.partial, newItem] : [newItem]
                    );
                }}
                onRemove={value =>
                    pageStore.changePage(
                        page.id,
                        ['catalog', 'products', 'partial'],
                        page.catalog.products.partial.filter(p => p.id != value)
                    )
                }
                onRearrange={selected =>
                    pageStore.changePage(
                        page.id,
                        ['catalog', 'products', 'partial'],
                        selected.map(id => page.catalog.products.partial.find(p => p.id === id)
                        )
                    )
                }
            />
            {page.catalog?.products?.partial?.map((p, i) =>
                {
                    const name = allProductNames?.find(n => n.id === p.id);
                    return (
                        <div key={p.id} className='row mt-3'>
                            <div className='col-5 d-flex align-items-center'>{name && pickLanguage(getBusinessUnitValue(name.description)) || p.id}</div>
                            <DropdownSearch
                                className='col-7'
                                placeholder='Pick promo codes'
                                options={pageProductsPromoCodesStore.products.find(o => o.id === p.id)?.promoCodes.map(r => ({name: r, value: r})) || []}
                                selected={p.promoCodes}
                                onChange={value =>
                                    pageStore.changePage(
                                        page.id,
                                        ['catalog', 'products', 'partial', i, 'promoCodes'],
                                        value
                                    )
                                }
                            />
                        </div>
                    );
                }
            )}
        </>
    );
});

const TabComponentMap = {
    exhibitors: TabExhibitors,
    products: TabProducts,
    promoCodes: TabPromoCodes,
    articleCodes: TabArticleCodes,
    categories: TabCategories,
    partial: TabPartial,
};
