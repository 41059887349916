import React from 'react';
import {observer} from 'mobx-react-lite';
import './ProductOrdered.scss';
import {ICatalogProduct} from '../../graphql/api/exhibitorProduct/ExhibitorProduct';
import {t} from '../translations';

interface Props
{
    product: ICatalogProduct
    storeId?: string
}

export default observer(function ProductOrdered({product, storeId}: Props)
{
    const orderedFor = product.stores.filter(s => s.weeks.some(w => w.ordered));
    if (storeId ? !orderedFor.some(s => s.storeId == storeId) : !orderedFor.length)
    {
        return null;
    }
    return storeId ? (
        <div className='ProductOrdered'>
            {t.catalog.alreadyOrdered} {storeId}
        </div>
    ) : <>
        {orderedFor.map(s =>
            <div key={s.storeId} className='ProductOrdered'>
                {t.catalog.alreadyOrdered} {s.storeId}
            </div>
        )}
    </>;
});
