import {useState} from 'react';
import {Link} from 'react-router-dom';
import Form from '../components/Form';
import Button from '../components/Button';
import PhoneField from '../components/PhoneField';
import type {Country} from 'react-phone-number-input';
import {user} from '../../stores/user';
import {LoginMethodProps} from '../EventRegistrationFormPage';
import styles from './Login.module.css';
import {t} from '../../translations';

export default function EnterPhone({onLogin, defaultCountry}: LoginMethodProps & {defaultCountry: Country})
{
    const [phone, setPhone] = useState(() => user.info?.phone || '');
    const [error, setError] = useState<string>();
    const confirm = () => onLogin({value: phone, method: 'Sms'}).then(e =>
    {
        if (e)
        {
            setError(e);
        }
    });
    return (
        <Form className={styles.content} onSubmit={confirm}>
            <h3>{t.eventRegistration.registerUsingPhone}</h3>
            <PhoneField
                className={styles.phone}
                defaultCountry={defaultCountry}
                value={phone}
                onChange={setPhone}
                numberInputProps={{
                    placeholder: 'Your phone number',
                    required: true,
                }}
            />
            {error && <div className={styles.error}>{error}</div>}
            <Button type='submit' primary className={styles.submitButton}>{t.eventRegistration.sendConfirmationCode}</Button>
            <div className={styles.textBelowSubmitButton}><Link to='#email'>{t.eventRegistration.registerUsingEmail}</Link></div>
            <div className={styles.textBelowSubmitButtonFlexible}>{t.eventRegistration.alreadyRegistered}<Link to='#badge-code'>{t.eventRegistration.modifyParticipation}</Link></div>
        </Form>
    );
}
