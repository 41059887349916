import React, {useEffect, useState} from 'react';
import FA from 'react-fontawesome';
import './Chat.scss';
import {IChatParticipant} from '../../graphql/api/chat/Chat';
import {getInitials, getName, getNameWithoutEscapingHTML} from '../common/getName';
import {colorHash} from '../common/colorHash';
import {contactsStore} from '../Contact/ContactsStore';
import {observer} from 'mobx-react-lite';
import {searchContacts} from '../Email/PersonSelection';
import {Link} from 'react-router-dom';
import { t } from '../translations';
import {companyNameStore} from '../StoreCatalog/CompanyNameStore';
import {user} from '../stores/user';

interface ChatUsersProps
{
    id?: string
    usersInChat: IChatParticipant[]
    onClose?(): void
    onAddUsers?(ids: string[]): void
    onRemoveUser?(id: string): void
}

export function ChatUsers(p: ChatUsersProps)
{
    const [add, setAdd] = useState(false);
    const [remove, setRemove] = useState<string>();

    if (add)
    {
        return <ChatAddUser
            usersInChat={p.usersInChat.map(c => c.id)}
            onCancel={() => setAdd(false)}
            onAdd={ids =>
            {
                p.onAddUsers?.(ids);
                setAdd(false);
            }}
        />;
    }

    if (remove)
    {
        return <ChatRemoveUser
            id={remove}
            name={getName(p.usersInChat.find(c => c.id == remove))}
            onCancel={() => setRemove(null)}
            onRemove={() =>
            {
                p.onRemoveUser?.(remove);
                setRemove(null);
            }}
        />;
    }

    return (
        <>
            <UsersInChat usersInChat={p.usersInChat} onRemoveUser={setRemove}/>
            <div className='ChatBottomButtons'>
                <button className='button-empty' onClick={p.onClose}>{t.global.cancel}</button>
                <button className='button' onClick={() => setAdd(true)}>{t.chat.addPeople}</button>
            </div>
        </>
    );
}

interface UsersInChatProps
{
    usersInChat: IChatParticipant[]
    onRemoveUser?(id: string): void
}

const UsersInChat = observer(function UsersInChat(p: UsersInChatProps)
{
    const idsToLoad = p.usersInChat.map(c => c.company).filter(i => i);
    useEffect(() => {companyNameStore.load(idsToLoad)}, [idsToLoad.length]);

    return (
        <div className='ChatUsers'>
            {p.usersInChat.map(c => (
                <ChatParticipant
                    key={c.id}
                    id={c.id}
                    name={getName(c)}
                    companyId={c.company}
                    companyName={companyNameStore.find(c.company)}
                    initials={getInitials(c)}
                    onRemove={() => p.onRemoveUser(c.id)}
                />
            ))}
        </div>
    );
})

interface ChatParticipantProps
{
    id: string
    name?: string
    initials?: string
    companyId?: string
    companyName?: string
    onRemove?(): void
    onClick?(): void
    hoverAdd?: boolean
}

function ChatParticipant(p: ChatParticipantProps)
{
    return (
        <div className={'ChatParticipant ' + (p.onClick ? 'selectable' : '')} onClick={p.onClick}>
            <div className='iconContainer'>
                <div className='ChatIcon' style={{backgroundColor: colorHash.hex(p.id)}}>{p.initials}</div>
            </div>
            <div className='info'>
                <Link to={'/contact/' + p.id} className='name' onClick={stopPropagation} dangerouslySetInnerHTML={{__html: p.name}}/>
                <div>
                    {user.moderator ?
                        <Link to={'/company/' + p.companyId} className='company' onClick={stopPropagation}>{p.companyName}</Link> :
                        <span className='company'>{p.companyName}</span>
                    }
                </div>
            </div>
            {!!p.onRemove && <FA name='times' className='side-icon' onClick={p.onRemove}/>}
            {!!p.hoverAdd && <FA name='user-plus' className='fad side-icon add'/>}
        </div>
    );
}

function stopPropagation(e)
{
    e.stopPropagation();
}

interface ChatRemoveUserProps
{
    id: string
    name: React.ReactNode
    onCancel?(): void
    onRemove?(): void
}

function ChatRemoveUser(p: ChatRemoveUserProps)
{
    return (
        <>
            <div className='ChatRemoveUser'>
                <div className='w1'>{t.chat.removeFromConversation}</div>
                <div className='w2'>
                    <Link to={'/contact/' + p.id} className='font-weight-bold unstyle-link'>{p.name}</Link>
                    {t.chat.willBeRemovedFromConversation}
                </div>
            </div>
            <div className='ChatBottomButtons'>
                <button className='button-empty' onClick={p.onCancel}>{t.global.cancel}</button>
                <button className='button-error' onClick={p.onRemove}>{t.global.remove}</button>
            </div>
        </>
    );
}

interface ChatAddUserProps
{
    usersInChat: string[]
    onCancel?(): void
    onAdd?(ids: string[]): void
}

const ChatAddUser = observer(function ChatAddUser(p: ChatAddUserProps)
{
    useEffect(() => contactsStore.reloadContacts(), []);

    const [search, setSearch] = useState('');
    const [selected, setSelected] = useState<string[]>([]);

    const contacts = searchContacts(search, p.usersInChat.concat(selected), 5);
    const idsToLoad = contacts.map(c => c.contact.company?.[0]).filter(i => i);
    useEffect(() => {companyNameStore.load(idsToLoad)}, [idsToLoad.join('')]);

    return (
        <>
            <div className='ChatAddUserSelected'>
                {selected.map(id =>
                    <span key={id} onClick={() => setSelected(selected.filter(i => i != id))}>
                        {getName(contactsStore.contacts.find(c => c.id == id))}
                    </span>
                )}
            </div>
            <input
                ref={autofocus}
                className='ChatAddUserSearch'
                placeholder={t.chat.search}
                value={search}
                onChange={e => setSearch(e.currentTarget.value.trimLeft())}
            />
            <div className='ChatAddUserSuggestions'>
                {contacts.map(c => (
                    <ChatParticipant
                        key={c.id}
                        id={c.id}
                        name={getNameWithoutEscapingHTML(c)}
                        initials={getInitials(c.contact)}
                        companyId={c.contact.company?.[0]}
                        companyName={companyNameStore.find(c.contact.company?.[0])}
                        onClick={() => setSelected(selected.concat([c.id]))}
                        hoverAdd
                    />
                ))}
            </div>
            <div className='ChatAddUserWarning'>
                {t.chat.peopleYouAddCanSeeAllPreviousMessages}
            </div>
            <div className='ChatBottomButtons'>
                <button className='button-empty' onClick={p.onCancel}>{t.global.cancel}</button>
                {selected.length ?
                    <button className='button' onClick={() => p.onAdd(selected)}>{t.chat.addPeople}</button> :
                    <button className='button-empty'>{t.chat.addPeople}</button>}
            </div>
        </>
    );
});

function autofocus(input)
{
    if (input) input.focus();
}
