import React, {useEffect, useState} from 'react';
import './Email.scss';
import {emailValidation, escapeHtmlTags} from '../../lib/common';
import {getName, getNameWithoutEscapingHTML} from '../common/getName';
import {observer} from 'mobx-react-lite';
import { contactsStore } from '../Contact/ContactsStore';
import {IContact} from '../stores/userProxy';

// email string or contact reference
export type EmailTo = string | IContact

interface PersonSelectionProps
{
    selected: EmailTo[]
    onSelect(selected: EmailTo)
    onRemove(selected: EmailTo)
}

export const PersonSelection = observer(function PersonSelection({selected, onSelect, onRemove}: PersonSelectionProps)
{
    const [input, setInput] = useState('');
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() =>
    {
        contactsStore.reloadContacts();
    }, []);

    const handleSelect = (e) =>
    {
        const id = e.target.dataset.id;
        onSelect(contactsStore.contacts.find(c => c.id == id));
        setInput('');
    };

    const handleRemove = (e) =>
    {
        onRemove(selected[e.target.dataset.index]);
    };

    const handleShowOptions = () => setShowOptions(true);
    const hideOptions = () =>
    {
        if (input)
        {
            onSelect(input);
            setInput('');
        }
        setShowOptions(false);
    }
    const handleChange = (e) => setInput(e.target.value);

    const handleKeyDown = (e: React.KeyboardEvent) =>
    {
        if (e.key == 'Enter')
        {
            if (input)
            {
                onSelect(input);
                setInput('');
            }
            e.preventDefault();
        }
        else if (e.key == 'Backspace')
        {
            if (!input && selected.length)
            {
                onRemove(selected[selected.length - 1]);
                e.preventDefault();
            }
        }
        else if (e.key == ' ')
        {
            if (!input || input.endsWith(' '))
            {
                e.preventDefault();
            }
        }
    };

    return (
        <div className='PersonSelection'>
            <div className='input'>
                <span className='label float-left mr-2'>To</span>
                <div className='d-flex flex-wrap'>
                    {selected.map((c, i) => (
                        <span
                            key={typeof c == 'string' ? c : c.id}
                            className={'selected' + (typeof c == 'string' && !emailValidation(c) ? ' text-danger' : '')}
                            data-index={i}
                            onClick={handleRemove}
                        >
                            {typeof c == 'string' ? getName(contactsStore.contactsAndCurrentUser.find(p => p.id == c || p.email == c)) || c : getName(c)}
                        </span>
                    ))}
                    <div className='PersonSelectionInput'>
                        <input
                            type='text'
                            value={input}
                            onFocus={handleShowOptions}
                            onBlur={hideOptions}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />
                        {showOptions &&
                        <div className='options list-group'>
                            {searchContacts(input, selected, 4).map(c => (
                                <button
                                    key={typeof c == 'string' ? c : c.id}
                                    className='list-group-item list-group-item-action'
                                    data-id={c.id}
                                    onMouseDown={handleSelect}
                                    dangerouslySetInnerHTML={{__html: `${getNameWithoutEscapingHTML(c)}<br/>${c.email}`}}
                                />
                            ))}
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
});

export function searchContacts(search: string, selected: (string | IContact)[], limit?: number)
{
    const words = search ? search.split(/ +/).filter(w => w).map(word => word.replace(/[\\\[\]()+?.*]/g, c => '\\' + c)) : [];
    let res = contactsStore.contacts.filter(c => !selected.includes(c) && !selected.includes(c.id));
    if (search)
    {
        const matchRegExp = search ? words.map(word => new RegExp(word, 'i')) : [];
        res = res
            .map(c => ({
                contact: c,
                matches: countMatches(matchRegExp, c.email) + countMatches(matchRegExp, c.firstName) + countMatches(matchRegExp, c.lastName),
            }))
            .filter(r => r.matches)
            .sort((a, b) => b.matches - a.matches)
            .map(r => r.contact);
    }
    if (limit)
    {
        res = res.slice(0, limit);
    }
    const replaceRegExp = search ? words.map(word => new RegExp('(^|)(' + word + ')(|$)', 'ig')) : [];
    return res.map((c) => ({
        contact: c,
        id: c.id,
        firstName: replaceAll(replaceRegExp, escapeHtmlTags(c.firstName), '$1<b>$2</b>$3'),
        lastName: replaceAll(replaceRegExp, escapeHtmlTags(c.lastName), '$1<b>$2</b>$3'),
        email: replaceAll(replaceRegExp, escapeHtmlTags(c.email), '$1<b>$2</b>$3'),
    }));
}

export function countMatches(regExpList: RegExp[], str: string)
{
    return str ? regExpList.reduce((sum, r) => sum + +r.test(str), 0) : 0;
}

export function replaceAll(regExpList: RegExp[], str: string, replaceValue: string)
{
    if (str && regExpList)
    {
        for (const r of regExpList)
        {
            str = str.replace(r, replaceValue);
        }
    }
    return str;
}
