import {action, makeAutoObservable} from 'mobx';
import {call, gql} from '../client';
import {user} from '../stores/user';

export interface ITimelineStand
{
    id: string
    projectId: string
    companyId: string
    items: ITimelineStandItem[]
}

export interface ITimelineStandItem
{
    date: Date
    status: string
    target: string
    personId: string
}

function loadTimelineStands(companyIds?: string[])
{
    return call<{timelineStands: ITimelineStand[]}>(
        gql`query($companyIds:[ID!]){timelineStands(companyIds:$companyIds){id,projectId,companyId,items{status,target,date,personId}}}`,
        {companyIds},
        {noBatch: !companyIds && user.moderator}
    )
    .then(({data}) =>
    {
        if (!data) return null;
        for (const s of data.timelineStands)
        {
            for (const i of s.items)
            {
                i.date = new Date(i.date);
            }
        }
        return data.timelineStands;
    });
}

class TimelineStandStore
{
    stands: ITimelineStand[] = [];
    loading = false;

    constructor()
    {
        makeAutoObservable(this);
    }

    load(companyIds?: string[])
    {
        this.loading = true;
        loadTimelineStands(companyIds).then(action('loadTimelineStands', stands =>
        {
            this.loading = false;
            if (stands)
            {
                if (companyIds)
                {
                    for (const stand of stands)
                    {
                        const existingIndex = this.stands.findIndex(s => s.id == stand.id);
                        if (existingIndex >= 0)
                        {
                            this.stands[existingIndex] = stand;
                        }
                        else
                        {
                            this.stands.push(stand);
                        }
                    }
                }
                else
                {
                    this.stands = stands;
                }
            }
        }));
    }
}

export const timelineStandStore = new TimelineStandStore();
