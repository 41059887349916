import {call, gql} from '../client';
// import {ISearchResults, IFlashSearchResults} from '../../graphql/api/search/Search';
import { IMenuItems } from './NavBarStore';
import { IListItem } from '../../graphql/api/navLinks/NavLinks';

// export function navbarSearch(searchTerm: string, project: string)
// {
//     return call<{search: ISearchResults}>(
//         gql`query ($input:SearchInput!){search(input:$input){users{id,name,companyId,companyName,email,image,country},companies{id,name,image},stands{id,name},invoices{id,number,standId},pages{id,name},products{id,description}}}`,
//         {input: {searchTerm, project}}
//     ).then(({data}) => data?.search);
// }
//
// export function navbarFlashSearch(searchTerm: string, project: string)
// {
//     return call<{flashSearch: IFlashSearchResults}>(
//         gql`query ($input:SearchInput!){flashSearch(input:$input){pages{id,name},products{id,description}}}`,
//         {input: {searchTerm, project}}
//     ).then(({data}) => data?.flashSearch);
// }

export function navItems(projectId: string)
{
    return call<{navigationItems: IMenuItems}>(
        gql`query ($projectId:String!){navigationItems(projectId:$projectId){project,list{pages,label},home}}`,
        {projectId}
    ).then(({data}) => data?.navigationItems);
}

export function editNavItems(project: string, list: IListItem[], home)
{
    return call<{editNavigationItems: IMenuItems}>(
        gql`mutation ($input: EditNavigationItemsInput!){editNavigationItems(input:$input){project,list{pages,label},home}}`,
        {input: {project, list, home}}
    ).then(({data}) => data?.editNavigationItems);
}
