import {action, makeObservable, observable} from 'mobx';
import {call, gql} from '../client';

interface ExhibitorListItem
{
    id: string
    name: string
    code: string[]
}

export function getExhibitorList(project: string, allAssignedToProject?: boolean)
{
    return call<{exhibitorList: ExhibitorListItem[]}>(
        gql`query($project:ID!,$allAssignedToProject:Boolean){exhibitorList(project:$project,allAssignedToProject:$allAssignedToProject){id,name,code}}`,
        {project, allAssignedToProject}
    ).then(({data}) => data?.exhibitorList);
}

export class ExhibitorStore
{
    // exhibitors with products
    @observable list: {[project: string]: ExhibitorListItem[]}  = {};
    // exhibitors with and without products
    @observable listAll: {[project: string]: ExhibitorListItem[]}  = {};

    constructor()
    {
        makeObservable(this);
    }

    reload(project: string)
    {
        getExhibitorList(project).then(action(exhibitors =>
        {
            if (exhibitors)
            {
                this.list[project] = exhibitors;
            }
        }))
    }

    reloadAll(project: string)
    {
        getExhibitorList(project, true).then(action(exhibitors =>
        {
            if (exhibitors)
            {
                this.listAll[project] = exhibitors;
            }
        }))
    }
}

export const exhibitorStore = new ExhibitorStore();
