import {useRef} from 'react';
import {pageStore} from './PageStore';
import {isID} from '../../lib/common';

export default function AddBlock({id}: {id: string})
{
    const importItemIdRef = useRef<HTMLInputElement>();

    return (
        <>
            <div className='text-center font-weight-bold'>Add block by block ID</div>
            <p style={{fontSize: '.8em', color: '#8e8e8e'}}>
                To find the Block ID first double-click on the block then expand the "Show block settings" section.
                <br/>
                Added block will automatically change based on the original block.
                <br/>
                Good use : banners.
            </p>
            <div className='d-flex'>
                <input
                    ref={importItemIdRef}
                    type='text'
                    placeholder='Paste block ID'
                />
                <button
                    className='button-sm text-nowrap ml-2'
                    onClick={() =>
                    {
                        const [pageId, itemId] = importItemIdRef.current.value.split('/');
                        if (!isID(pageId) || !itemId)
                        {
                            alert(`"${importItemIdRef.current.value}" is not a valid block id!`);
                            return;
                        }
                        if (pageId && itemId)
                        {
                            pageStore.importPageItem(id, pageId, itemId).then(() =>
                            {
                                importItemIdRef.current.value = '';
                            });
                        }
                    }}
                >
                    + Add
                </button>
            </div>
        </>
    );
}
