import type {ISurfaceDimensions} from '../../graphql/api/stand/Stand';
import type {Country} from '../../graphql/api/Types';
import type {IProjectContext} from '../../graphql/api/project/Project';

interface ISurfaceDimensionsOption extends ISurfaceDimensions
{
    // only available to organization
    restricted?: boolean
}

interface SurfaceOption
{
    value: number
    shared?: boolean
    dimensions: ISurfaceDimensionsOption[]
}

export const surfaceOptions = (country: Country) => ({
    BE: surfaceOptionsBE,
    PL: surfaceOptionsPL,
    FR: surfaceOptionsFR,
    IT: surfaceOptionsIT,
    ES: surfaceOptionsES,
}[country] || []);

const surfaceOptionsBE: SurfaceOption[] = [{
    value: 6,
    shared: true,
    dimensions: [
        {length: 2, depth: 3, unit: 'm'}
    ],
}, {
    value: 9,
    dimensions: [
        {length: 3, depth: 3, unit: 'm'}
    ],
}, {
    value: 12,
    dimensions: [
        {length: 4, depth: 3, unit: 'm'},
    ]
}, {
    value: 15,
    dimensions: [
        {length: 5, depth: 3, unit: 'm'}
    ]
}, {
    value: 16,
    dimensions: [
        {length: 4, depth: 4, unit: 'm'}
    ]
}, {
    value: 18,
    dimensions: [
        {length: 6, depth: 3, unit: 'm'}
    ]
}, {
    value: 20,
    dimensions: [
        {length: 5, depth: 4, unit: 'm'}
    ],
}, {
    value: 21,
    dimensions: [
        {length: 7, depth: 3, unit: 'm'}
    ],
}, {
    value: 24,
    dimensions: [
        {length: 8, depth: 3, unit: 'm'},
        {length: 6, depth: 4, unit: 'm'}
    ],
}, {
    value: 25,
    dimensions: [
        {length: 5, depth: 5, unit: 'm'},
    ]
}, {
    value: 27,
    dimensions: [
        {length: 9, depth: 3, unit: 'm'}
    ],
}, {
    value: 28,
    dimensions: [
        {length: 7, depth: 4, unit: 'm'}
    ],
}, {
    value: 30,
    dimensions: [
        {length: 10, depth: 3, unit: 'm'}
    ],
}, {
    value: 32,
    dimensions: [
        {length: 8, depth: 4, unit: 'm'}
    ],
}, {
    value: 35,
    dimensions: [
        {length: 7, depth: 5, unit: 'm'}
    ],
}, {
    value: 36,
    dimensions: [
        {length: 9, depth: 4, unit: 'm'}
    ],
}, {
    value: 40,
    dimensions: [
        {length: 10, depth: 4, unit: 'm'},
        {length: 8, depth: 5, unit: 'm'}
    ],
}, {
    value: 42,
    dimensions: [
        {length: 7, depth: 6, unit: 'm'}
    ],
}, {
    value: 45,
    dimensions: [
        {length: 9, depth: 5, unit: 'm'}
    ],
}, {
    value: 48,
    dimensions: [
        {length: 8, depth: 6, unit: 'm'}
    ],
}, {
    value: 50,
    dimensions: [
        {length: 10, depth: 5, unit: 'm'}
    ],
}, {
    value: 56,
    dimensions: [
        {length: 8, depth: 7, unit: 'm'}
    ]
}, {
    value: 64,
    dimensions: [
        {length: 8, depth: 8, unit: 'm'}
    ]
}];

const surfaceOptionsPL: SurfaceOption[] = [{
    value: 5,
    shared: true,
    dimensions: [
        {length: 2.5, depth: 2, unit: 'm'}
    ],
},{
    value: 6,
    shared: true,
    dimensions: [
        {length: 2, depth: 3, unit: 'm'}
    ],
}, {
    value: 7.5,
    dimensions: [
        {length: 3, depth: 2.5, unit: 'm'}
    ],
}, {
    value: 9,
    dimensions: [
        {length: 3, depth: 3, unit: 'm'}
    ],
}, {
    value: 11,
    dimensions: [
        {length: 4, depth: 2.75, unit: 'm'},
    ]
}, {
    value: 12,
    dimensions: [
        {length: 4, depth: 3, unit: 'm'},
    ]
}, {
    value: 13.5,
    dimensions: [
        {length: 4.5, depth: 3, unit: 'm'},
    ]
}, {
    value: 14,
    dimensions: [
        {length: 5, depth: 3, unit: 'm'}
    ]
}, {
    value: 15,
    dimensions: [
        {length: 5, depth: 3, unit: 'm'}
    ]
}, {
    value: 16,
    dimensions: [
        {length: 4, depth: 4, unit: 'm'}
    ]
}, {
    value: 16.5,
    dimensions: [
        {length: 5.5, depth: 3, unit: 'm'}
    ]
},
 {
    value: 18,
    dimensions: [
        {length: 6, depth: 3, unit: 'm'}
    ]
}, {
    value: 19.5,
    dimensions: [
        {length: 6.5, depth: 3, unit: 'm'}
    ]
}, {
    value: 20,
    dimensions: [
        {length: 5, depth: 4, unit: 'm'}
    ],
}, {
    value: 21,
    dimensions: [
        {length: 7, depth: 3, unit: 'm'}
    ],
}, {
    value: 24,
    dimensions: [
        {length: 8, depth: 3, unit: 'm'},
        {length: 6, depth: 4, unit: 'm'}
    ],
}, {
    value: 27,
    dimensions: [
        {length: 9, depth: 3, unit: 'm'}
    ],
}, {
    value: 28,
    dimensions: [
        {length: 7, depth: 4, unit: 'm'}
    ],
}, {
    value: 30,
    dimensions: [
        {length: 10, depth: 3, unit: 'm'}
    ],
}, {
    value: 32,
    dimensions: [
        {length: 8, depth: 4, unit: 'm'}
    ],
}, {
    value: 35,
    dimensions: [
        {length: 7, depth: 5, unit: 'm'}
    ],
}, {
    value: 36,
    dimensions: [
        {length: 9, depth: 4, unit: 'm'}
    ],
}, {
    value: 40,
    dimensions: [
        {length: 10, depth: 4, unit: 'm'},
        {length: 8, depth: 5, unit: 'm'}
    ],
}, {
    value: 42,
    dimensions: [
        {length: 7, depth: 6, unit: 'm'}
    ],
}, {
    value: 45,
    dimensions: [
        {length: 9, depth: 5, unit: 'm'}
    ],
}, {
    value: 48,
    dimensions: [
        {length: 8, depth: 6, unit: 'm'}
    ],
}, {
    value: 50,
    dimensions: [
        {length: 10, depth: 5, unit: 'm'}
    ],
}, {
    value: 77,
    dimensions: [
        {length: 7, depth: 11, unit: 'm'}
    ],
}];

const surfaceOptionsFR: SurfaceOption[] = [{
    value: 6,
    shared: true,
    dimensions: [
        {length: 2, depth: 3, unit: 'm', restricted: true}
    ],
}, {
    value: 9,
    dimensions: [
        {length: 3, depth: 3, unit: 'm', restricted: true}
    ],
}, {
    value: 12,
    dimensions: [
        {length: 4, depth: 3, unit: 'm', restricted: true}
    ]
}, {
    value: 15,
    dimensions: [
        {length: 5, depth: 3, unit: 'm', restricted: true}
    ]
}, {
    value: 16,
    dimensions: [
        {length: 4, depth: 4, unit: 'm'}
    ],
}, {
    value: 18,
    dimensions: [
        {length: 6, depth: 3, unit: 'm', restricted: true}
    ],
}, {
    value: 19,
    dimensions: [
        {length: 5, depth: 4, unit: 'm', restricted: true}
    ]
}, {
    value: 20,
    dimensions: [
        {length: 5, depth: 4, unit: 'm'}
    ]
}, {
    value: 21,
    dimensions: [
        {length: 7, depth: 3, unit: 'm', restricted: true}
    ],
}, {
    value: 24,
    dimensions: [
        {length: 6, depth: 4, unit: 'm'},
        {length: 8, depth: 3, unit: 'm', restricted: true},
    ]
}, {
    value: 25,
    dimensions: [
        {length: 5, depth: 5, unit: 'm'},
    ]
}, {
    value: 27,
    dimensions: [
        {length: 9, depth: 3, unit: 'm', restricted: true},
    ]
}, {
    value: 28,
    dimensions: [
        {length: 7, depth: 4, unit: 'm'}
    ]
}, {
    value: 30,
    dimensions: [
        {length: 6, depth: 5, unit: 'm'},
        {length: 10, depth: 3, unit: 'm', restricted: true},
    ]
}, {
    value: 32,
    dimensions: [
        {length: 8, depth: 4, unit: 'm'}
    ]
}, {
    value: 33,
    dimensions: [
        {length: 11, depth: 3, unit: 'm', restricted: true},
    ]
}, {
    value: 35,
    dimensions: [
        {length: 7, depth: 5, unit: 'm'}
    ]
}, {
    value: 36,
    dimensions: [
        {length: 9, depth: 4, unit: 'm'},
        {length: 12, depth: 3, unit: 'm', restricted: true},
    ]
}, {
    value: 40,
    dimensions: [
        {length: 10, depth: 4, unit: 'm'},
        {length: 8, depth: 5, unit: 'm'},
    ]
}, {
    value: 42,
    dimensions: [
        {length: 7, depth: 6, unit: 'm'}
    ]
}, {
    value: 44,
    dimensions: [
        {length: 11, depth: 4, unit: 'm'}
    ]
}, {
    value: 45,
    dimensions: [
        {length: 9, depth: 5, unit: 'm'}
    ]
}, {
    value: 48,
    dimensions: [
        {length: 8, depth: 6, unit: 'm'}
    ]
}, {
    value: 50,
    dimensions: [
        {length: 10, depth: 5, unit: 'm'}
    ]
}, {
    value: 54,
    dimensions: [
        {length: 9, depth: 6, unit: 'm'}
    ]
}, {
    value: 56,
    dimensions: [
        {length: 8, depth: 7, unit: 'm'}
    ]
}, {
    value: 60,
    dimensions: [
        {length: 10, depth: 6, unit: 'm'}
    ]
}, {
    value: 63,
    dimensions: [
        {length: 9, depth: 7, unit: 'm'}
    ]
}, {
    value: 64,
    dimensions: [
        {length: 8, depth: 8, unit: 'm'}
    ]
}, {
    value: 70,
    dimensions: [
        {length: 10, depth: 7, unit: 'm'}
    ]
}, {
    value: 72,
    dimensions: [
        {length: 9, depth: 8, unit: 'm'}
    ]
}, {
    value: 75,
    dimensions: [
        {length: 15, depth: 5, unit: 'm'}
    ]
}, {
    value: 80,
    dimensions: [
        {length: 10, depth: 8, unit: 'm'}
    ]
}, {
    value: 81,
    dimensions: [
        {length: 9, depth: 9, unit: 'm'}
    ]
}];

const surfaceOptionsIT: SurfaceOption[] = [{
    value: 6,
    shared: true,
    dimensions: [
        {length: 2, depth: 3, unit: 'm'}
    ],
}, {
    value: 9,
    dimensions: [
        {length: 3, depth: 3, unit: 'm'}
    ],
}, {
    value: 12,
    dimensions: [
        {length: 4, depth: 3, unit: 'm'},
    ]
}, {
    value: 15,
    dimensions: [
        {length: 5, depth: 3, unit: 'm'}
    ]
}, {
    value: 16,
    dimensions: [
        {length: 4, depth: 4, unit: 'm'}
    ]
}, {
    value: 18,
    dimensions: [
        {length: 6, depth: 3, unit: 'm'}
    ]
}, {
    value: 20,
    dimensions: [
        {length: 5, depth: 4, unit: 'm'}
    ],
}, {
    value: 21,
    dimensions: [
        {length: 7, depth: 3, unit: 'm'}
    ],
}, {
    value: 24,
    dimensions: [
        {length: 8, depth: 3, unit: 'm'},
        {length: 6, depth: 4, unit: 'm'}
    ],
}, {
    value: 27,
    dimensions: [
        {length: 9, depth: 3, unit: 'm'}
    ],
}, {
    value: 28,
    dimensions: [
        {length: 7, depth: 4, unit: 'm'}
    ],
}, {
    value: 30,
    dimensions: [
        {length: 10, depth: 3, unit: 'm'}
    ],
}, {
    value: 32,
    dimensions: [
        {length: 8, depth: 4, unit: 'm'}
    ],
}, {
    value: 35,
    dimensions: [
        {length: 7, depth: 5, unit: 'm'}
    ],
}, {
    value: 36,
    dimensions: [
        {length: 9, depth: 4, unit: 'm'}
    ],
}, {
    value: 40,
    dimensions: [
        {length: 10, depth: 4, unit: 'm'},
        {length: 8, depth: 5, unit: 'm'}
    ],
}, {
    value: 42,
    dimensions: [
        {length: 7, depth: 6, unit: 'm'}
    ],
}, {
    value: 45,
    dimensions: [
        {length: 9, depth: 5, unit: 'm'}
    ],
}, {
    value: 48,
    dimensions: [
        {length: 8, depth: 6, unit: 'm'}
    ],
}, {
    value: 49,
    dimensions: [
        {length: 7, depth: 7, unit: 'm'}
    ],
}, {
    value: 50,
    dimensions: [
        {length: 10, depth: 5, unit: 'm'}
    ],
}, {
    value: 60,
    dimensions: [
        {length: 10, depth: 6, unit: 'm'}
    ]
}];

const surfaceOptionsES: SurfaceOption[] = [{
    value: 6,
    shared: true,
    dimensions: [
        {length: 2, depth: 3, unit: 'm'}
    ],
}, {
    value: 9,
    dimensions: [
        {length: 3, depth: 3, unit: 'm'}
    ],
}, {
    value: 12,
    dimensions: [
        {length: 4, depth: 3, unit: 'm'},
    ]
}, {
    value: 15,
    dimensions: [
        {length: 5, depth: 3, unit: 'm'}
    ]
}, {
    value: 16,
    dimensions: [
        {length: 4, depth: 4, unit: 'm'}
    ]
}, {
    value: 18,
    dimensions: [
        {length: 6, depth: 3, unit: 'm'}
    ]
}, {
    value: 20,
    dimensions: [
        {length: 5, depth: 4, unit: 'm'}
    ],
}, {
    value: 21,
    dimensions: [
        {length: 7, depth: 3, unit: 'm'}
    ],
}, {
    value: 24,
    dimensions: [
        {length: 8, depth: 3, unit: 'm'},
        {length: 6, depth: 4, unit: 'm'}
    ],
}, {
    value: 25,
    dimensions: [
        {length: 5, depth: 5, unit: 'm'}
    ],
}, {
    value: 27,
    dimensions: [
        {length: 9, depth: 3, unit: 'm'}
    ],
}, {
    value: 28,
    dimensions: [
        {length: 7, depth: 4, unit: 'm'}
    ],
}, {
    value: 30,
    dimensions: [
        {length: 10, depth: 3, unit: 'm'},
        {length: 6, depth: 5, unit: 'm'},
        {length: 5, depth: 6, unit: 'm'},
    ],
}, {
    value: 32,
    dimensions: [
        {length: 8, depth: 4, unit: 'm'}
    ],
}, {
    value: 33,
    dimensions: [
        {length: 11, depth: 3, unit: 'm'}
    ],
}, {
    value: 34,
    dimensions: [
        {length: 17, depth: 2, unit: 'm'}
    ],
}, {
    value: 35,
    dimensions: [
        {length: 7, depth: 5, unit: 'm'},
        {length: 5, depth: 7, unit: 'm'},
    ],
}, {
    value: 36,
    dimensions: [
        {length: 9, depth: 4, unit: 'm'},
        {length: 12, depth: 3, unit: 'm'}
    ],
}, {
    value: 40,
    dimensions: [
        {length: 10, depth: 4, unit: 'm'},
        {length: 8, depth: 5, unit: 'm'}
    ],
}, {
    value: 42,
    dimensions: [
        {length: 7, depth: 6, unit: 'm'}
    ],
}, {
    value: 44,
    dimensions: [
        {length: 11, depth: 4, unit: 'm'}
    ],
}, {
    value: 45,
    dimensions: [
        {length: 9, depth: 5, unit: 'm'}
    ],
}, {
    value: 48,
    dimensions: [
        {length: 8, depth: 6, unit: 'm'}
    ],
}, {
    value: 49,
    dimensions: [
        {length: 7, depth: 7, unit: 'm'}
    ],
}, {
    value: 50,
    dimensions: [
        {length: 10, depth: 5, unit: 'm'}
    ],
}, {
    value: 52,
    dimensions: [
        {length: 13, depth: 4, unit: 'm'}
    ],
}, {
    value: 55,
    dimensions: [
        {length: 11, depth: 5, unit: 'm'}
    ],
}, {
    value: 56,
    dimensions: [
        {length: 8, depth: 7, unit: 'm'}
    ],
}, {
    value: 60,
    dimensions: [
        {length: 12, depth: 5, unit: 'm'}
    ],
}, {
    value: 63,
    dimensions: [
        {length: 9, depth: 7, unit: 'm'}
    ],
}, {
    value: 64,
    dimensions: [
        {length: 16, depth: 4, unit: 'm'}
    ],
}, {
    value: 70,
    dimensions: [
        {length: 10, depth: 7, unit: 'm'},
        {length: 14, depth: 5, unit: 'm'}
    ]
}, {
    value: 75,
    dimensions: [
        {length: 15, depth: 5, unit: 'm'}
    ]
}];

export type SurfaceSectorType = 'all' | 'PFT' | 'PLS' | 'PGC' | 'Non-Food-Textile' | 'Technologies-and-services'
export type SurfaceStandType = 'stand' | 'corner' | 'head' | 'open'

export function getSectorType(sector: string): SurfaceSectorType | undefined
{
    if (sector)
    {
        if (sector.includes('PFT'))
        {
            return 'PFT';
        }
        if (sector.includes('PLS'))
        {
            return 'PLS';
        }
        if (sector.includes('PGC'))
        {
            return 'PGC';
        }
        if (sector.includes('Non-Food-Textile'))
        {
            return 'Non-Food-Textile';
        }
        if (sector.includes('Technologies-and-services'))
        {
            return 'Technologies-and-services';
        }
    }
}

export function filterSurfaceOptions(project: IProjectContext, sectorType: SurfaceSectorType | string)
{
    const country = project.fair.country;
    const minSize = project.fair?.minSurface?.[sectorType]?.stand || project.fair?.minSurface?.all?.stand || 0;
    return surfaceOptions(country).filter(s =>
        s.dimensions.some(d => !d.restricted) &&
        ((s.shared && ['BE', 'PL', 'IT'].includes(country)) || s.value >= minSize)
    );
}

export function standSurfaceMin(
    project: IProjectContext,
    primarySector: SurfaceSectorType | string,
    secondarySector: SurfaceSectorType | string,
    standType: SurfaceStandType
): number
{
    const sectorToUse = pickSectorToUse(project.fair.country, primarySector, secondarySector);
    return project.fair?.minSurface?.[sectorToUse]?.[standType] || project.fair?.minSurface?.all?.[standType] || 9;
}

export function pickSectorToUse(country: Country, primarySector: SurfaceSectorType | string, secondarySector: SurfaceSectorType | string): SurfaceSectorType
{
    let sectorToUse = primarySector as SurfaceSectorType;
    if (primarySector !== 'PFT' && primarySector !== 'PLS')
    {
        if (country === 'BE')
        {
            if (secondarySector === 'PFT' || secondarySector === 'PLS' || secondarySector === 'PGC' || secondarySector === 'Non-Food-Textile')
            {
                sectorToUse = secondarySector;
            }
        }
        else
        {
            if (secondarySector === 'PFT' || secondarySector === 'PLS')
            {
                sectorToUse = secondarySector;
            }
        }
    }
    if (!sectorToUse)
    {
        sectorToUse = secondarySector as SurfaceSectorType;
    }
    return sectorToUse || 'all';
}
