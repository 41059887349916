import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import Swal from 'sweetalert2';
import DropdownSearch from './DropdownSearch';
import {t} from '../translations';
import {pickLanguage} from '../stores/utility';
import {projectStore} from '../stores/ProjectStore';

interface Props
{
    className?: string
    onSelect: (project: string) => Promise<unknown> | unknown
    allowSameProject?: boolean
}

export default observer(function CopyToAnotherProject({className, onSelect, allowSameProject}: Props)
{
    const [project, setProject] = useState<string>();
    const [status, setStatus] = useState<'copying' | 'success' | 'failed'>();
    return (
        <div className={'d-flex align-content-center ' + (className || '')}>
            <DropdownSearch
                arrow
                className='flex-grow-1'
                placeholder={t.global.copyToProject}
                options={(allowSameProject ? projectStore.ownProjects : projectStore.ownProjects.filter(p => p.id != projectStore.id))
                    .map(p => ({name: pickLanguage(p.name), value: p.id}))
                }
                selected={project}
                onSelect={setProject}
            />
            <button
                className={status ? status == 'copying' ? 'button-sm-empty' : status == 'success' ? 'button-sm' : 'button-sm-error' : project ? 'button-sm' : 'button-sm-empty'}
                onClick={() =>
                {
                    if (!project)
                    {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Please select a project from the dropdown',
                        });
                        return;
                    }
                    setStatus('copying');
                    Promise.resolve(onSelect(project)).then(
                        res => setStatus(res === undefined ? undefined : res ? 'success' : 'failed'),
                        e =>
                        {
                            console.error(e);
                            setStatus('failed');
                        },
                    );
                }}
            >
                {status ? status == 'copying' ? 'Copying...' : status == 'success' ? 'Copied' : 'Failed' : 'Copy'}
            </button>
        </div>
    );
});
