import React, {useState} from 'react';
import './AttachmentTag.scss';
import {autoDownloadAttachment} from './attachmentProxy';
import {observer} from 'mobx-react-lite';
import {attachmentStore} from './AttachmentStore';

export interface AttachmentTagProps
{
    index?: number
    name?: string
    keyName?: string
    className?: string
    onClick?(item: AttachmentTagProps): void
}

export default observer(function AttachmentTag(p: AttachmentTagProps)
{
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState(false);
    const handleClick = (e) =>
    {
        e.preventDefault();
        if (p.onClick)
        {
            p.onClick(p);
        }
        else if (p.keyName)
        {
            if (!attachmentStore.getUploadInProgress(p.keyName))
            {
                setDownloading(true);
                autoDownloadAttachment(p.keyName).then(res =>
                {
                    setDownloading(false);
                    setError(!!res && !res.signedUrl);
                    if (res && !res.signedUrl)
                    {
                        if (res.deleted)
                        {
                            alert('This file was deleted.');
                        }
                        else if (res.canceled)
                        {
                            alert('The upload of this file was canceled.');
                        }
                        else if (!res.finished)
                        {
                            alert('This file is still being uploaded. Please try again later.');
                        }
                    }
                });
            }
        }
    };
    const u = attachmentStore.uploads[p.keyName];
    const name = p.name || u?.name || '';
    return (
        <a className={'AttachmentTag ' + (error ? 'removed ' : '') + (p.className || '')} onClick={handleClick}>
            {!!u && !u.ended && <p className='progress' style={{width: (u.progress * 100).toFixed(0) + '%'}}/>}
            <span className={downloading ? 'pending' : null} title={name}>{name}</span>
        </a>
    );
});
