import {Upload, uploadStore} from '../Upload/UploadStore';
import {parseDataUrl} from '../../lib/dataUrl';
import {objectUrlForBase64} from '../common/blobUtility';
import {resizeUploaded} from '../common/resizeUploaded';

const defaultMaxResolution = 2000;

interface UploadAndConvertInput
{
    source: File | Blob
    name?: string
    dir?: string
    asAttachment?: boolean
    maxResolution?: number
    maxResolutionW?: number
    maxResolutionH?: number
    onBase64?(blobUrl: string): void
    onUploadStart?(u: Upload): void
    onUploadEnd?(u: Upload): void
    // called even if resize is not performed
    onResizeEnd?(url: string): void
}

export function uploadAndConvert({source, name, dir, asAttachment, maxResolution, maxResolutionW, maxResolutionH, onBase64, onUploadStart, onUploadEnd, onResizeEnd}: UploadAndConvertInput)
{
    const reader = new FileReader();
    reader.onloadend = () =>
    {
        const res = reader.result as string;
        if (onBase64)
        {
            const data = parseDataUrl(res);
            onBase64(objectUrlForBase64(data.content, data.contentType));
        }
        const uRes = uploadStore.upload(source, u =>
        {
            onUploadEnd?.(u);
            const img = new Image();
            img.onload = async () =>
            {
                let w = img.width;
                let h = img.height;
                if (!maxResolutionW)
                {
                    maxResolutionW = maxResolution || defaultMaxResolution;
                }
                if (!maxResolutionH)
                {
                    maxResolutionH = maxResolution || defaultMaxResolution;
                }
                const largeResolution = w > maxResolutionW || h > maxResolutionH;
                const largeFile = source.size > 524288;
                if (largeResolution || largeFile)
                {
                    if (largeResolution)
                    {
                        const aspect = w / h;
                        if (w >= h)
                        {
                            w = maxResolutionW;
                            h = Math.round(maxResolutionW / aspect);
                        }
                        else
                        {
                            w = Math.round(maxResolutionH * aspect);
                            h = maxResolutionH;
                        }
                    }
                    const rRes = await resizeUploaded(u.url, w, h, true);
                    if (rRes.status == 200)
                    {
                        onResizeEnd?.(rRes.result);
                    }
                    else
                    {
                        console.log(rRes.result);
                        if (rRes.result == 'Input image exceeds pixel limit')
                        {
                            alert('Image is too large for the server to downsize it.\nYou must downsize the image yourself.');
                        }
                        else
                        {
                            alert('Failed to downsize the image.');
                        }
                        onResizeEnd?.(null);
                    }
                }
                else
                {
                    onResizeEnd?.(u.url);
                }
            };
            img.src = res;
        }, {name, dir, asAttachment});
        if (onUploadStart)
        {
            uRes.then(onUploadStart);
        }
    };
    reader.readAsDataURL(source);
}
