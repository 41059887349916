// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YqxArr32PX_9825n9hNd {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.WFtQAPcp2ZiARAmnvDzV {
  margin-right: 30px;
}

.YqxArr32PX_9825n9hNd label {
  display: inline-block;
  margin: 0;
  cursor: pointer;
}

.cc2GilDoL4rXOvm_RlDG {
  color: #2169CA;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./frontend/EventRegistrationForm/components/FormCheckbox.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".field {\n  margin-bottom: 10px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.checkbox {\n  margin-right: 30px;\n}\n\n.field label {\n  display: inline-block;\n  margin: 0;\n  cursor: pointer;\n}\n\n.labelChecked {\n  color: #2169CA;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `YqxArr32PX_9825n9hNd`,
	"checkbox": `WFtQAPcp2ZiARAmnvDzV`,
	"labelChecked": `cc2GilDoL4rXOvm_RlDG`
};
export default ___CSS_LOADER_EXPORT___;
