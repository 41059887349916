import type {ITranslations} from '../graphql/api/Types';
import type {IStandSetup} from '../graphql/api/stand/Stand';
import type {IProjectContext} from '../graphql/api/project/Project';

export type IDiscountType = 'percentage' | 'value'

export interface IDiscountBase
{
    type: IDiscountType
    amount: number
}

const setupTranslations: {[name: string]: ITranslations} = {
    corner: {
        en: 'Corner Stand',
        fr: 'Stand de Coin',
        nl: 'Hoekstand',
    },
    head: {
        en: 'Head Stand',
        fr: 'Stand de Tête',
        nl: 'Kopstand',
    },
    open: {
        en: 'Open Stand',
    },
};

const earlyBirdTranslations: ITranslations = {
    en: 'Early Bird',
};

const lateFeeTranslations: ITranslations = {
    en: 'Late fee',
    fr: 'Commande tardive',
    nl: 'Late bestelling',
};

export function setupDiscounts(setup: IStandSetup, project: IProjectContext): IProjectDiscountNamed[]
{
    if (setup.type === 'normal' && project?.fair?.percentages)
    {
        if (setup.corner && project.fair.percentages.corner)
        {
            return [{
                amount: project.fair.percentages.corner,
                name: setupTranslations.corner,
            }];
        }
        if (setup.head && project.fair.percentages.head)
        {
            return [{
                amount: project.fair.percentages.head,
                name: setupTranslations.head,
            }];
        }
        if (setup.open && project.fair.percentages.open)
        {
            return [{
                amount: project.fair.percentages.open,
                name: setupTranslations.open,
            }];
        }
    }
    return [];
}

export interface IProjectDiscountNamed
{
    name: ITranslations
    amount: number
}

export function earlyBirdDiscounts(project: IProjectContext)
{
    const res: IProjectDiscountNamed[] = [];
    const fair = project?.fair;
    const now = Date.now();
    if (fair?.earlyBird &&
        fair.earlyBird.amount &&
        fair.earlyBird.from &&
        fair.earlyBird.to)
    {
        if (now > +new Date(fair.earlyBird.from) && now < +new Date(fair.earlyBird.to))
        {
            res.push({
                amount: fair.earlyBird.amount,
                name: earlyBirdTranslations,
            });
        }
    }
    else
    {
        if (now > +new Date('2020-04-30T00:00:00+01:00') && now < +new Date('2020-05-30T00:00:00+01:00'))
        {
            res.push({
                amount: .96,
                name: earlyBirdTranslations,
            });
        }
    }
    return res;
}

export function lateFeeDiscounts(project: IProjectContext)
{
    const res: IProjectDiscountNamed[] = [];
    const fair = project?.fair;
    const now = Date.now();
    if (fair?.lateOrder &&
        fair.lateOrder.amount &&
        fair.lateOrder.from && now > +new Date(fair.lateOrder.from) &&
        fair.lateOrder.to && now < +new Date(fair.lateOrder.to))
    {
        res.push({
            amount: fair.lateOrder.amount,
            name: lateFeeTranslations,
        });
    }
    if (fair?.lateOrder2 &&
        fair.lateOrder2.amount &&
        fair.lateOrder2.from && now > +new Date(fair.lateOrder2.from) &&
        fair.lateOrder2.to && now < +new Date(fair.lateOrder2.to))
    {
        res.push({
            amount: fair.lateOrder2.amount,
            name: lateFeeTranslations,
        });
    }
    return res;
}

// export function setupProjectDiscountsInfo(setup: IStandSetup, project: IProjectContext)
// {
//     return [...setupDiscounts(setup), ...earlyBirdDiscounts(project), ...lateFeeDiscounts(project)];
// }

// export function setupProjectDiscounts(setup: IStandSetup, project: IProjectContext)
// {
//     return setupProjectDiscountsInfo(setup, project).map(d => d.amount);
// }

export function applyDiscounts(price: number, discountAmounts: number[])
{
    if (discountAmounts)
    {
        for (const d of discountAmounts)
        {
            price += (d - 1) * price;
        }
    }
    return price;
}
