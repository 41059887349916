import {createRef, RefObject, useMemo} from 'react';
import styles from './CodeField.module.css';
import {createArray} from '../../../lib/common';

interface Props
{
    className?: string
    size: number
    code: string
    onChange: (code: string) => void
    format?: RegExp
    placeholder?: string
    required?: boolean
    autoFocus?: boolean
}

export default function CodeField({className, size, code, onChange, format, placeholder, required, autoFocus}: Props)
{
    const refs = useMemo(() => createArray<RefObject<HTMLInputElement>>(size, createRef), [size]);
    if (!code)
    {
        code = '';
    }
    return (
        <div className={styles.row + (className ? ' ' + className : '')}>
            {createArray(size, i =>
                <input
                    key={i}
                    ref={refs[i]}
                    className={styles.single}
                    required={required}
                    autoFocus={autoFocus && i === 0}
                    type='code'
                    autoComplete={i === 0 ? 'one-time-code' : null}
                    placeholder={placeholder?.[i]}
                    value={code[i] ? code[i] === ' ' ? '' : code[i] : ''}
                    onFocus={e => e.currentTarget.select()}
                    onChange={e =>
                    {
                        let v = e.currentTarget.value.trim();
                        if (v.length === 0)
                        {
                            v = ' ';
                        }
                        else
                        {
                            if (format && v.split('').some(c => !format.test(c)))
                            {
                                return;
                            }
                            const next = refs[i + v.length];
                            if (next)
                            {
                                next.current.focus();
                            }
                            else
                            {
                                e.currentTarget.blur();
                            }
                        }
                        onChange((code.substring(0, i).padEnd(i, ' ') + v + code.substring(i + v.length)).trimEnd());
                    }}
                    onKeyDown={e =>
                    {
                        if (e.key === 'Backspace' && (!code[i] || code[i] === ' '))
                        {
                            const prev = refs[i - 1];
                            if (prev)
                            {
                                prev.current.focus();
                            }
                        }
                    }}
                />
            )}
        </div>
    );
}
