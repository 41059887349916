// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PickPageTemplate {
  display: flex;
  justify-content: center;
  align-items: center;
}
.PickPageTemplate > div {
  width: max-content;
  padding: 30px;
}
.PickPageTemplate h1 {
  margin-bottom: 30px;
}
.PickPageTemplate .choice {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 20px 30px;
  margin-bottom: 20px;
  border: 1px solid #000;
}
.PickPageTemplate .choice:hover {
  background-color: #eee;
}`, "",{"version":3,"sources":["webpack://./frontend/Pages/PickPageTemplate.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,kBAAA;EACA,aAAA;AAEJ;AAAE;EACE,mBAAA;AAEJ;AAAE;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,sBAAA;AAEJ;AADI;EACE,sBAAA;AAGN","sourcesContent":[".PickPageTemplate {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  > div {\n    width: max-content;\n    padding: 30px;\n  }\n  h1 {\n    margin-bottom: 30px;\n  }\n  .choice {\n    cursor: pointer;\n    font-size: 1.5rem;\n    padding: 20px 30px;\n    margin-bottom: 20px;\n    border: 1px solid #000;\n    &:hover {\n      background-color: #eee;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
