import {action, makeObservable, observable, toJS} from 'mobx';
import type {Layout} from 'react-grid-layout';
import {IImportedPageItem, ILinkedPageEngagement, IPageContext, IPageItem, IPageRules, IUIPageItem} from '../../graphql/api/page/Page';
import {loadOwnPages, loadPage, loadVersions, updatePage} from './pageProxy';
import {pagePath} from './utility';
import {deepSet, IMaybePath, IPath} from '../common/deepSet';
import {globalNavigate} from '../App';
import {registerGlobalEventHandler} from '../stores/globalEvents';

export interface IPage extends IPageContext, IPageRules
{
    id: string
    items: IUIPageItem[]
    importedItems?: (IUIPageItem & IImportedPageItem)[]
    updatedAt?: Date | string
    updatedBy?: {
        id: string
        firstName?: string
        lastName?: string
    }
    changed?: boolean
    focused?: string
    rerender?: number
    linkedPagesEngagement?: ILinkedPageEngagement[]
}

class PageStore
{
    @observable pages: IPage[] = [];
    @observable changes: IPage[] = [];
    @observable versions: {id: string, versions: IPage[]}[] = [];
    private pageLoadedAt: {[id: string]: number} = {};

    constructor()
    {
        makeObservable(this);
        registerGlobalEventHandler('logout', () =>
        {
            this.pages = [];
            this.changes = [];
        });
    }

    load(id: string, noErrorLog?: boolean)
    {
        return this._load({id, noErrorLog});
    }

    loadByName(project: string, name: string, noErrorLog?: boolean)
    {
        return this._load({project, name, noErrorLog});
    }

    private _load(options: {id?: string, project?: string, name?: string, noErrorLog?: boolean})
    {
        const recentlyLoaded = this.pages.find(p => p.id == options.id || p.name == options.name);
        if (recentlyLoaded && Date.now() - this.pageLoadedAt[recentlyLoaded.id] < 2000)
        {
            return Promise.resolve();
        }
        return loadPage(options).then(action('loadPage', page =>
        {
            if (page)
            {
                this.pageLoadedAt[page.id] = Date.now();
                const existing = this.pages.find(p => p.id == page.id);
                if (existing)
                {
                    Object.assign(existing, page);
                    this.pageShouldRerender(existing);
                }
                else
                {
                    this.pages.push(page);
                }
            }
        }));
    }

    loadOwnPages()
    {
        loadOwnPages().then(action('loadOwnPages', pages =>
        {
            if (pages)
            {
                for (const page of pages)
                {
                    const existing = this.pages.find(p => p.id == page.id);
                    if (existing)
                    {
                        Object.assign(existing, page);
                        this.pageShouldRerender(existing);
                    }
                    else
                    {
                        this.pages.push(page);
                    }
                }
            }
        }));
    }

    loadVersions(id: string)
    {
        loadVersions(id).then(action(pageVersions =>
        {
            const existing = this.versions.find(v => v.id == id);
            if (existing)
            {
                existing.versions = pageVersions;
            }
            else
            {
                this.versions.push({
                    id,
                    versions: pageVersions,
                });
            }
        }));
    }

    getPage(pageId: string)
    {
        return this.pages.find(p => p.id == pageId);
    }

    getChangedPage(pageId: string)
    {
        let changed = this.changes.find(p => p.id == pageId);
        if (!changed)
        {
            const page = this.pages.find(p => p.id == pageId);
            if (page)
            {
                this.changes.push(changed = observable(toJS(page)));
            }
        }
        return changed;
    }

    getActivePage(pageId: string)
    {
        return this.changes.find(p => p.id == pageId) || this.pages.find(p => p.id == pageId);
    }

    changePage<K extends keyof IPage>(pageId: string, key: K, value: IPage[K])
    changePage(pageId: string, path: IPath, value)
    @action
    changePage(pageId: string, path: IMaybePath, value)
    {
        const page = this.getChangedPage(pageId);
        deepSet(page, path, value);
        page.changed = true;
    }

    applyLayout(pageId: string, layout: Layout[])
    {
        let changed = false;
        const page = this.getChangedPage(pageId);
        for (const i of layout)
        {
            const applyNewLayout = (item: IUIPageItem) =>
            {
                const itemLayout = item.layout;
                if (itemLayout.x != i.x)
                {
                    itemLayout.x = i.x;
                    changed = true;
                }
                if (itemLayout.y != i.y)
                {
                    itemLayout.y = i.y;
                    changed = true;
                }
                if (itemLayout.w != i.w)
                {
                    itemLayout.w = i.w;
                    changed = true;
                }
                if (itemLayout.h != i.h)
                {
                    itemLayout.h = i.h;
                    changed = true;
                }
            };
            const imported = page.importedItems?.find(e => e.id == i.i);
            if (imported)
            {
                applyNewLayout(imported);
            }
            else
            {
                const item = page.items?.find(e => e.id == i.i);
                if (item)
                {
                    if (item && item.layout.w != i.w)
                    {
                        if (i.w == 1)
                        {
                            i.w = 2;
                        }
                        else if (i.w == 7)
                        {
                            i.w = 8;
                        }
                    }
                    applyNewLayout(item);
                }
            }
        }
        if (changed)
        {
            page.changed = true;
        }
    }

    @action
    appendItem(pageId: string, item: Omit<IPageItem, 'id'> & {id?: string})
    {
        const page = this.getChangedPage(pageId);
        if (item.id == null)
        {
            item.id = generateBlockId(page);
        }
        if (item.layout.x == null)
        {
            item.layout.x = 0;
        }
        if (item.layout.y == null)
        {
            item.layout.y = page.items.length ? Math.max(...page.items.map(i => i.layout.y + i.layout.h)) : 0;
        }
        page.items.push(item as IPageItem);
        page.changed = true;
    }

    @action
    changeItem(pageId: string, itemId: string, key: IMaybePath, value: any)
    {
        const page = this.getChangedPage(pageId);
        const item = page.items.find(i => i.id == itemId);
        item.props = item.props || {};
        deepSet(item.props, key, value);
        page.changed = true;
    }

    @action
    changeImportedItem(pageId: string, itemId: string, key: string, value: any)
    {
        const page = this.getChangedPage(pageId);
        const item = page.importedItems.find(i => i.id == itemId);
        item[key] = value;
        page.changed = true;
    }

    async importPageItem(toPageId: string, fromPageId: string, itemId: string)
    {
        let fromPage = this.pages.find(p => p.id == fromPageId);
        if (!fromPage)
        {
            await this.load(fromPageId);
            fromPage = this.pages.find(p => p.id == fromPageId);
        }
        if (fromPage)
        {
            const item = fromPage.items.find(i => i.id == itemId);
            if (item)
            {
                action(() =>
                {
                    const importedItem = {
                        ...item,
                        pageId: fromPageId,
                        published: true,
                        layout: {
                            ...item.layout,
                            isResizable: false,
                        }
                    };
                    const page = this.getChangedPage(toPageId);
                    if (page)
                    {
                        if (!page.importedItems)
                        {
                            page.importedItems = [];
                        }
                        page.importedItems.push(importedItem);
                        page.changed = true;
                    }
                })();
            }
        }
    }

    @action.bound
    changeItemHeight(pageId: string, itemId: string, units: number)
    {
        itemId = itemId.trim();
        if (units < 1)
        {
            units = 1;
        }
        const page = this.getActivePage(pageId);
        const item = page.items.find(i => i.id.trim() == itemId);
        if (item && item.layout.h != units)
        {
            const diff = units - item.layout.h;
            if (Math.abs(diff) > .03)
            {
                if (!item.initialLayout)
                {
                    item.initialLayout = {...item.layout};
                }
                item.layout.h = units;
                if (diff > 0)
                {
                    for (const i of page.items)
                    {
                        if (i.layout.y > item.layout.y)
                        {
                            i.layout.y += diff;
                        }
                    }
                }
                item.id += ' ';
            }
        }
    }

    @action
    resetAllItemsLayout(pageId: string)
    {
        const page = this.getActivePage(pageId);
        if (page)
        {
            for (const item of page.items)
            {
                if (item.initialLayout)
                {
                    item.layout = {...item.initialLayout};
                }
            }
        }
    }

    @action.bound
    changeItemWidth(pageId: string, itemId: string, units: number)
    {
        itemId = itemId.trim();
        if (units < 1)
        {
            units = 1;
        }
        const page = this.getActivePage(pageId);
        const item = page.items.find(i => i.id.trim() == itemId);
        if (item && item.layout.w != units)
        {
            item.layout.w = units;
            item.id += ' ';
        }
    }

    @action
    pageShouldRerender(page: IPage)
    {
        if (!page.rerender)
        {
            page.rerender = 1;
        }
        else
        {
            ++page.rerender;
        }
    }

    @action
    deleteItem(pageId: string, itemId: string)
    {
        const page = this.getChangedPage(pageId);
        page.items = page.items.filter(i => i.id != itemId);
        page.changed = true;
    }

    @action
    focusItem(pageId: string, itemId: string)
    {
        const page = this.changes.find(p => p.id == pageId) || this.pages.find(p => p.id == pageId);
        page.focused = itemId;
    }

    saveChanges(pageId: string, addNewContactsToChats?: boolean)
    {
        const page = this.changes.find(p => p.id == pageId);
        return this.submitChangedPage(page, addNewContactsToChats);
    }

    private submitChangedPage(page: IPage, addNewContactsToChats?: boolean)
    {
        return updatePage({
            id: page.id,
            name: page.name,
            isTemplate: page.isTemplate,
            orderReports: page.orderReports,
            reportGroup: page.reportGroup,
            viewAccess: page.viewAccess,
            keywords: page.keywords,
            partialEditAccess: page.partialEditAccess,
            editAccess: page.editAccess,
            items: page.items?.map(i =>
            {
                const ri: IPageItem = {
                    id: i.id.trim(),
                    type: i.type as any,
                    layout: i.layout,
                };
                if (i.props != null)
                {
                    ri.props = i.props;
                }
                return ri;
            }),
            importedItems: page.importedItems?.map(i =>
            {
                const ri: IImportedPageItem = {
                    id: i.id.trim(),
                    pageId: i.pageId,
                    published: i.published,
                    layout: {x: i.layout.x, y: i.layout.y} as any,
                };
                if (i.showOnWeb != null)
                {
                    ri.showOnWeb = i.showOnWeb;
                }
                if (i.showOnMobile != null)
                {
                    ri.showOnMobile = i.showOnMobile;
                }
                return ri;
            }),
            catalog: page.catalog,
            addNewContactsToChats,
        }).then(action(res =>
        {
            if (res)
            {
                const pageId = res.id;
                const existing = this.pages.find(p => p.id == pageId);
                Object.assign(existing, res);
                this.cancelChanges(pageId);
            }
            return res;
        }));
    }

    @action
    cancelChanges(pageId: string)
    {
        this.changes = this.changes.filter(p => p.id != pageId);
    }

    revertToVersion(pageId: string, versionDate: Date | string)
    {
        const pageVersion = this.versions.find(p => p.id == pageId).versions.find(v => v.updatedAt == versionDate);
        return this.submitChangedPage(pageVersion).then(action(res =>
        {
            if (res)
            {
                this.loadVersions(pageId);
            }
            return res;
        }));
    }

    // @action
    // newPageFromPage(pageId: string)
    // {
    //     return newPageFromPage(pageId).then(page =>
    //     {
    //         if (page)
    //         {
    //             this.pages.push(page);
    //         }
    //         return page;
    //     });
    // }
}

export const pageStore = new PageStore();

window.addEventListener('beforeunload', e =>
{
    const changedPages = pageStore.changes.filter(p => p.changed);
    if (changedPages.length)
    {
        console.log('There are unsaved page changes.');
        e.preventDefault();
        e.returnValue = '';
        globalNavigate()(pagePath(changedPages[0]) + '#edit');
    }
});

export function generateBlockId(page: IPage)
{
    let next: string;
    do next = Math.floor(Math.random() * 2176782335).toString(36).padStart(6, '0');
    while (page.items.some(i => i.id == next) || page.importedItems?.some(i => i.id == next));
    return next;
}
