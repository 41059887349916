import {call, gql} from '../client';
import {IHelpQuestion} from './HelpQuestion';
import {IHelpQuestionInput, IHelpCategory} from './HelpStore';

export function loadHelpQuestions(section: string, project: string)
{
    return call<{helpQuestions: IHelpQuestion[]}>(
        gql`query ($input:GetHelpQuestionsInput!){helpQuestions(input: $input){id,updated,published,title,content,profiles,attachments}}`,
        {input: {section, project}})
    .then(({data}) =>
    {
        if (!data) return null;
        for (const post of data.helpQuestions)
        {
            post.updated = new Date(post.updated);
        }
        return data.helpQuestions;
    });
}

export function callCreateHelpQuestion(post: IHelpQuestionInput)
{
    return call<{createHelpQuestion: IHelpQuestion}>(
        gql`mutation ($input:HelpQuestionCreateInput!){createHelpQuestion(input:$input){id,updated,published,title,content,profiles}}`,
        {input: post})
    .then(({data}) =>
    {
        if (!data) return null;
        data.createHelpQuestion.updated = new Date(data.createHelpQuestion.updated);
        return data.createHelpQuestion;
    });
}

export function callUpdateHelpQuestion(post: Partial<IHelpQuestionInput>)
{
    return call<{updateHelpQuestion: IHelpQuestion}>(
        gql`mutation ($input:HelpQuestionUpdateInput!){updateHelpQuestion(input:$input){id,updated,published,title,content,profiles,attachments}}`,
        {input: post})
    .then(({data}) =>
    {
        if (!data) return null;
        data.updateHelpQuestion.updated = new Date(data.updateHelpQuestion.updated);
        return data.updateHelpQuestion;
    });
}

export function callDeleteHelpQuestion(id: string)
{
    return call<{deleteHelpQuestion: string}>(gql`mutation ($id:ID!){deleteHelpQuestion(id:$id)}`, {id});
}

export function getHelpCategories(project: string)
{
    return call<{helpCategories: IHelpCategory[]}>(
        gql`query($project: String!){helpCategories(project:$project){id, name, access, customURL, image}}`,
        {project}
    ).then(({data}) => data?.helpCategories);
}
export function createHelpCategory(input)
{
    return call<{createHelpCategory: IHelpCategory}>(
        gql`mutation ($input:HelpCategoryCreateInput!){createHelpCategory(input:$input){id, name, image, customURL, access}}`,
        {input})
    .then(({data}) => data?.createHelpCategory);
}
export function updateHelpCategory(input)
{
    return call<{updateHelpCategory: IHelpCategory}>(
        gql`mutation ($input:HelpCategoryUpdateInput!){updateHelpCategory(input:$input){id, name, image, customURL, access}}`,
        {input})
    .then(({data}) => data?.updateHelpCategory);
}
export function deleteHelpCategory(id: string)
{
    return call<{deleteHelpCategory}>(
        gql`mutation ($id:ID!){deleteHelpCategory(id:$id)}`,
        {id})
    .then(({data}) => data?.deleteHelpCategory);
}
export function copyCategory(project: string, categoryId: string)
{
    return call<{copyCategory: {category: IHelpCategory, questions: IHelpQuestion[]}}>(
        gql`mutation ($input:CopyCategoryInput!){copyCategory(input:$input){questions{id,published,title,content,profiles,attachments},category{id, name, access, customURL, image}}}`,
        {input: {project, categoryId}})
    .then(({data, errors}) => ({data: data?.copyCategory, errors}));
}
