import {action, makeAutoObservable} from 'mobx';
import {call, gql} from '../client';
import {user} from './user';
import {projectStore} from './ProjectStore';

export function getCompanyMemberWasScannedIn(project: string, id: string)
{
    return call<{companyMemberWasScannedIn: boolean}>(
        gql`query($input:CompanyMemberWasScannedInInput!){companyMemberWasScannedIn(input:$input)}`,
        {input: {id, project}}
    ).then(({data}) => data?.companyMemberWasScannedIn);
}

class CompanyMemberWasScannedIn
{
    private byProjectByCompany: {[project: string]: {[company: string]: boolean}} = {};

    constructor()
    {
        makeAutoObservable(this);
    }

    load(project: string, id: string)
    {
        getCompanyMemberWasScannedIn(project, id).then(action(res =>
        {
            if (res != null)
            {
                if (this.byProjectByCompany[project])
                {
                    this.byProjectByCompany[project][id] = res;
                }
                else
                {
                    this.byProjectByCompany[project] = {[id]: res};
                }
            }
        }));
    }

    getIsScannedInOnCurrentProject(id: string)
    {
        return this.byProjectByCompany[projectStore.id]?.[id] || user.projectBadge?.scannedIn?.length;
    }
}

export const companyMemberWasScannedIn = new CompanyMemberWasScannedIn();
