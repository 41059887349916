import {action, makeAutoObservable} from 'mobx';
import {loadCategories} from './categoryProxy';
import {ICategoryContext} from '../../graphql/api/category/Category';
import {projectStore} from '../stores/ProjectStore';
import {Country} from '../../graphql/api/Types';

export interface ICategory extends ICategoryContext
{
    id: string
}

class CategoryStore
{
    categories: ICategory[] = [];
    categoriesByCountry: {[country: string]: ICategory[]} = {};

    get countryCategories()
    {
        const country = projectStore.selectedCountry;
        return this.categories.filter(p => p.country == country);
    }

    constructor()
    {
        makeAutoObservable(this);
    }

    reloadCategories(country: Country)
    {
        loadCategories(country).then(action('reloadCategories', res =>
        {
            if (res)
            {
                this.categoriesByCountry[country] = res;
                const combined = [];
                for (const p of Object.getOwnPropertyNames(this.categoriesByCountry))
                {
                    combined.push(...this.categoriesByCountry[p]);
                }
                this.categories = combined;
            }
        }));
    }
}

export const categoryStore = new CategoryStore();
