import {action, makeObservable, observable} from 'mobx';
import {CompanyEventRegistrationAvailableBadges, companyEventRegistrationAvailableBadges} from './eventsProxy';

class AvailableBadgesStore
{
    @observable private badges = new Map<string, CompanyEventRegistrationAvailableBadges>();

    constructor()
    {
        makeObservable(this);
    }

    load(project: string, company: string)
    {
        companyEventRegistrationAvailableBadges(project, company).then(action(badges =>
        {
            if (badges)
            {
                this.badges.set(project + company, badges);
            }
        }));
    }

    find(project: string, company: string)
    {
        return this.badges.get(project + company);
    }
}

export const availableBadgesStore = new AvailableBadgesStore();
