import {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {Link, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import './StoreCatalog.scss';
import {projectStore} from '../stores/ProjectStore';
import StoreCatalogCard from './StoreCatalogCard';
import {companyStore} from '../Company/CompanyStore';
import {throttle} from '../common/throttle';
import {mainStateReload} from '../Navbar/NavBarStore';
import {brandListStore} from './BrandListStore';
import {productFamiliesStore} from './ProductFamiliesStore';
import {t} from '../translations';
import {ITranslations} from '../../graphql/api/Types';
import {debouncedLogView} from '../stores/logView';
import {pickLanguage} from '../stores/utility';
import {Loading, LoadingInline} from '../common/Loading';
import {catalogStore} from './CatalogStore';
import {ILoadProductsInput} from '../ExhibitorProducts/productProxy';
import {productWorkshopsStore} from './ProductWorkshopsStore';

export default observer(function StoreCatalog()
{
    useEffect(mainStateReload, []);

    const projectId = projectStore.id;
    const projectFilters = projectStore.selected?.fair?.filters;
    const brandsEnabled = projectFilters?.brands;
    const productFamiliesEnabled = projectFilters?.productFamilies;
    const productWorkshopsEnabled = projectFilters?.productWorkshops;
    const projectIsLoaded = !!projectStore.selected;

    useEffect(() =>
    {
        if (projectId && projectIsLoaded)
        {
            debouncedLogView({type: 'catalog', project: projectId});
        }
    }, [projectId]);

    useEffect(() =>
    {
        if (projectId)
        {
            if (productFamiliesEnabled)
            {
                productFamiliesStore.load(projectId);
            }
            if (productWorkshopsEnabled)
            {
                productWorkshopsStore.load(projectId);
            }
        }
    }, [projectId, projectIsLoaded, productFamiliesEnabled, productWorkshopsEnabled]);

    const isTurboView = useIsCatalogTurboView();

    const filters = useCatalogFilters();

    useEffect(() =>
    {
        if (projectId && brandsEnabled)
        {
            if (filters.productFamilies.length)
            {
                brandListStore.loadFor(projectId, filters.productFamilies);
            }
            else
            {
                brandListStore.load(projectId);
            }
        }
    }, [projectId, brandsEnabled, filters.productFamilies.join('')]);

    const brandsList = brandsEnabled ? filters.productFamilies.length ? brandListStore.brandsFor(filters.productFamilies) : brandListStore.brands : null;

    const brands = brandsEnabled ?
        (filters.productFamilies.length ? brandsList || brandListStore.brands : brandListStore.brands)
            .filter(b => filters.brands.find(s => b.id.startsWith(s))) :
        [];
    const brandIds = brands.length ? brands.map(b => b.id) : [];

    useEffect(() =>
    {
        if (projectId && (!filters.brands.length || !brandListStore.loading))
        {
            throttledReload({brands: brandIds, productFamilies: filters.productFamilies, productWorkshops: filters.workshops});
        }
    }, [projectId, brandListStore.loading ? '' : brandIds.join(''), filters.productFamilies.join(''), filters.workshops.join('')]);

    return (
        <div className='StoreCatalog'>
            {(brandsEnabled || productFamiliesEnabled || productWorkshopsEnabled) && <>
                <div className='filters pt-3'>
                    {productFamiliesEnabled && !productFamiliesStore.loading &&
                    <FilterCategory
                        name='productFamilies'
                        categoryName={t.helpPage.sections.sectors}
                        list={productFamiliesStore.sectors?.map((f, i) => ({value: i, label: f.name, count: f.count})).filter(f => f.count)}
                    />}
                    {productWorkshopsEnabled && !productWorkshopsStore.loading &&
                    <FilterCategory
                        name='workshops'
                        categoryName={t.global.workshops}
                        list={productWorkshopsStore.workshops?.map(w => ({value: w.id, label: w.name, count: w.count}))}
                    />}
                    {brandsEnabled && !!(brandsList.length || brandListStore.brands.length) &&
                    <FilterCategory
                        name='brands'
                        categoryName={t.catalog.brands}
                        list={brandListStore.loading ?
                            null :
                            brandsList.map(b => ({value: b.id.substring(0, 8), label: b.name, count: b.count}))
                        }
                    />}
                </div>
                <div className='catalog-spacing'/>
            </>}
            <div className={(brandsEnabled ? 'catalog' : (isTurboView ? 'px-3 width-max-content m-auto' : 'container'))}>
                <StoreCatalogViewMode/>
                <StoreCatalogProducts filters={filters}/>
            </div>
        </div>
    );
});

function useCatalogFilters()
{
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    return {
        query,
        brands: query.get('brands')?.split(' ') || [],
        productFamilies: query.get('productFamilies')?.split(' ')?.map(v => +v) || [],
        // first 8 characters of the page id
        workshops: query.get('workshops')?.split(' ') || [],
    };
}

const StoreCatalogViewMode = observer(function StoreCatalogViewMode()
{
    const isTurboView = useIsCatalogTurboView();
    const location = useLocation();

    if (companyStore.ownStores.length < 2)
    {
        return <h1 className='mb-3'>{t.global.navBar.catalog}</h1>;
    }

    return (
        <div className='d-flex justify-content-between'>
            <h1 className='mb-3'>{t.global.navBar.catalog}</h1>
            <div className='view-modes'>
                <Link to={location.search + '#'}><FA name='table' className={'fad' + (isTurboView ? '' : ' active')}/></Link>
                <Link to={location.search + '#turbo'}><FA name='bolt' className={'fad' + (isTurboView ? ' active' : '')}/></Link>
            </div>
        </div>
    );
});

export function useIsCatalogTurboView()
{
    return useLocation().hash == '#turbo';
}

interface StoreCatalogProductsProps
{
    filters: ReturnType<typeof useCatalogFilters>
}

const StoreCatalogProducts = observer(function StoreCatalogProducts({filters}: StoreCatalogProductsProps)
{
    useEffect(() =>
    {
        const handleScroll = () =>
        {
            const scrollPos = document.documentElement.scrollTop;
            const offsetHeight = window.innerHeight;
            const height = document.documentElement.scrollHeight;

            if (scrollPos + offsetHeight >= height - 150 && !catalogStore.loadingMore)
            {
                catalogStore.loadMoreProducts();
            }
        };
        window.addEventListener('scroll', handleScroll, true);
        return () =>
        {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, [filters.brands.join(''), filters.productFamilies.join('')]);

    const [loading, setLoading] = useState(true);

    useEffect(() =>
    {
        if (catalogStore.loading || brandListStore.loading)
        {
            setLoading(true);
        }
        else
        {
            const timeout = setTimeout(() =>
            {
                setLoading(false);
            });
            return () =>
            {
                clearTimeout(timeout);
            };
        }
    }, [catalogStore.loading || brandListStore.loading]);

    if (loading)
    {
        return <div className='position-relative py-5'><Loading/></div>;
    }

    const products = catalogStore.searchProducts || catalogStore.products[projectStore.id];

    return (
        <>
            {products?.map(p => <StoreCatalogCard key={p.id} product={p} isCatalogView/>)}
        </>
    );
});

const throttledReload = throttle((input: Omit<ILoadProductsInput, 'project'>) =>
{
    catalogStore.loadProducts({...input, project: projectStore.id});
}, 500, true);

type StoreCatalogFilterFields = 'brands' | 'productFamilies' | 'workshops';

interface IFilterCategory
{
    className?: string
    categoryName: string
    name: StoreCatalogFilterFields
    list: {
        value: number | string
        label: string | ITranslations
        count: number
    }[]
}

const FilterCategory = observer(function FilterCategory({className, categoryName, name, list}: IFilterCategory)
{
    const [folded, setFolded] = useState(false);

    return (
        <div className={'filter-category ' + (className ? className : '')}>
            <div className='header d-flex justify-content-between align-items-center' onClick={() => {setFolded(!folded)}}>
                <h4 className='m-0'>{categoryName}</h4>
                <FA name={(folded ? 'caret-up' : 'caret-down')} className='toggle-arrow'/>
            </div>
            <div className={'list mt-3 ' + (folded ? 'folded' : '')}>
                {!list ?
                    <LoadingInline/> :
                    list.map(({value, label, count}, i) =>
                        <FilterItem key={name + i} name={name} value={value} label={label} count={count}/>
                    )
                }
            </div>
        </div>
    );
});

interface FilterItemProps
{
    name: StoreCatalogFilterFields
    value: number | string
    label: string | ITranslations
    count: number
}

const FilterItem = observer(function FilterItem({name, value, label, count}: FilterItemProps)
{
    const navigate = useNavigate();
    const filters = useCatalogFilters();
    const selected: any[] = filters[name];

    const handleCheck = e =>
    {
        if (e.target.checked)
        {
            filters.query.set(name, [...selected, value].join(' '));
        }
        else
        {
            const res = selected.filter((v: string | number) => v != value);
            if (res.length)
            {
                filters.query.set(name, res.join(' '));
            }
            else
            {
                filters.query.delete(name);
            }
        }
        navigate('?' + filters.query.toString() + location.hash);
    };

    const id = name + value;

    return (
        <div className='item mb-2' key={value}>
            <input
                type='checkbox'
                id={id}
                onChange={handleCheck}
                checked={selected.includes(value)}
            />
            <label htmlFor={id} className='mb-0 mx-2 cursor-pointer'>{typeof label == 'object' ? pickLanguage(label) : label}</label>
            <div className='item-spacing'/>
            <span className='badge badge-secondary'>{count}</span>
        </div>
    );
});
