
export function duplicate<T>(o: T): T
export function duplicate(o)
{
    if (Array.isArray(o))
    {
        return o.map(duplicate);
    }
    if (o instanceof Date)
    {
        return new Date(+o);
    }
    if (o && typeof o === 'object')
    {
        const c = Object.create(Object.getPrototypeOf(o));
        for (const key of Object.getOwnPropertyNames(o))
        {
            c[key] = duplicate(o[key]);
        }
        for (const key of Object.getOwnPropertySymbols(o))
        {
            c[key] = duplicate(o[key]);
        }
        return c;
    }
    return o;
}

export function shallowDuplicate<T>(o: T): T
{
    if (o && typeof o === 'object')
    {
        const c = Object.create(Object.getPrototypeOf(o));
        for (const key of Object.getOwnPropertyNames(o))
        {
            c[key] = o[key];
        }
        for (const key of Object.getOwnPropertySymbols(o))
        {
            c[key] = o[key];
        }
        return c;
    }
    return o;
}

// usage: .filter(onlyUnique)
export function onlyUnique<T = any>(value: T, index: number, arr: T[])
{
    return arr.indexOf(value) === index;
}

export function emailValidation(email: string)
{
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).trim().toLowerCase());
}

export function vatValidation(vat: string)
{
    return /^[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}$/.test(vat);
}

export function formatVideoEmbedUrl(video: string, queryOptions?: string)
{
    if (!video)
    {
        return video;
    }
    if (!video.startsWith?.('https://vimeo.com/'))
    {
        return video + (queryOptions ? '?' + queryOptions : '');
    }
    else
    {
        const matchVimeo2 = video.match(/https:\/\/vimeo.com\/([\da-zA-Z]+)\/([\da-zA-Z]+)/);
        if (matchVimeo2)
        {
            return `https://player.vimeo.com/video/${matchVimeo2[1]}?h=${matchVimeo2[2]}${queryOptions ? '&' + queryOptions : ''}`;
        }
        return video.replace('https://vimeo.com/', 'https://player.vimeo.com/video/') + (queryOptions ? '?' + queryOptions : '');
    }
}

export function sameArrays<T>(a: T[], b: T[])
{
    if (!Array.isArray(a) || !Array.isArray(b))
    {
        return !Array.isArray(a) && !Array.isArray(b);
    }
    if (a.length != b.length)
    {
        return false;
    }
    for (let i = 0; i < a.length; ++i)
    {
        if (a[i] !== b[i])
        {
            return false;
        }
    }
    return true;
}

// works with Date object, date string or date number arguments
export function sameDay(a: Date | string | number, b: Date | string | number)
{
    if (!a || !b)
    {
        return !a && !b;
    }
    if (!(a instanceof Date))
    {
        a = new Date(a);
    }
    if (!(b instanceof Date))
    {
        b = new Date(b);
    }
    return a.getFullYear() == b.getFullYear() && a.getMonth() == b.getMonth() && a.getDate() == b.getDate();
}

// works with Date object, date string or date number arguments
export function sameUTCDay(a: Date | string | number, b: Date | string | number)
{
    if (!a || !b)
    {
        return !a && !b;
    }
    if (!(a instanceof Date))
    {
        a = new Date(a);
    }
    if (!(b instanceof Date))
    {
        b = new Date(b);
    }
    return a.getUTCFullYear() == b.getUTCFullYear() && a.getUTCMonth() == b.getUTCMonth() && a.getUTCDate() == b.getUTCDate();
}

export function escapeHtmlTags(unsafe: string)
{
    return unsafe && unsafe.replace(/[<>]/g, v => v == '<' ? '&lt;' : '&gt;');
}

export function flat<T>(array: T[][][][][]): T[]
export function flat<T>(array: T[][][][]): T[]
export function flat<T>(array: T[][][]): T[]
export function flat<T>(array: T[][]): T[]
export function flat<T>(array: T[]): T[]
export function flat<T>(array: T): T
export function flat(array)
{
    return Array.isArray(array) ? [].concat(...array.map(flat)) : array;
}

export function isID(str: string)
{
    return str && str.length === 36 && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(str);
}

export function capitalize(str: string)
{
    return str && str.charAt(0).toUpperCase() + str.slice(1);
}

export function countFiltered<T>(array: T[], filter: (value: T, index: number, array: T[]) => unknown)
{
    if (!array)
    {
        return 0;
    }
    let count = 0;
    for (let i = 0; i < array.length; ++i)
    {
        if (filter(array[i], i, array))
        {
            ++count;
        }
    }
    return count;
}

export function createArray<T>(length: number, callbackfn: (index: number) => T)
{
    const res = new Array<T>(length);
    for (let i = 0; i < length; ++i)
    {
        res[i] = callbackfn(i);
    }
    return res;
}

export const stripAccents = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
