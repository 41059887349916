// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.helpEdit .overview {
  justify-content: flex-end;
}
.helpEdit .overview .helpImage {
  max-width: 150px;
  min-height: 150px;
}
.helpEdit .overview .helpImage .ChangeImage span:first-child {
  display: none;
}
.helpEdit .overview .helpImage img {
  width: 100%;
}
.helpEdit .overview .editButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 75px;
}
.helpEdit .overview .editButtons .button {
  width: 175px;
}
.helpEdit .overview .editButtons .button:first-child {
  margin-bottom: 10px;
}
.helpEdit .overview .editButtons .button.white {
  background: #fff;
  color: #403d39;
  border: 1px solid #403d39;
}
.helpEdit .customUrl {
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./frontend/Help/HelpEdit.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;AAAR;AACQ;EAMI,gBAAA;EACA,iBAAA;AAJZ;AADgB;EACI,aAAA;AAGpB;AAEY;EACI,WAAA;AAAhB;AAGQ;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,kBAAA;AADZ;AAEY;EACI,YAAA;AAAhB;AACgB;EACI,mBAAA;AACpB;AACgB;EACI,gBAAA;EACA,cAAA;EACA,yBAAA;AACpB;AAII;EACI,gBAAA;AAFR","sourcesContent":[".helpEdit {\n    .overview {\n        justify-content: flex-end;\n        .helpImage {\n            .ChangeImage {\n                span:first-child {\n                    display: none;\n                }\n            }\n            max-width: 150px;\n            min-height: 150px;\n            img {\n                width: 100%;\n            }\n        }\n        .editButtons {\n            display: flex;\n            flex-direction: column;\n            align-items: flex-end;\n            padding-left: 75px;\n            .button {\n                width:175px;\n                &:first-child {\n                    margin-bottom: 10px;\n                }\n                &.white {\n                    background: #fff;\n                    color: #403d39;\n                    border: 1px solid #403d39;\n                }\n            }\n        }\n    }\n    .customUrl {\n        margin-top: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
