import {useState} from 'react';
import moment from 'moment';
import styles from './Button.module.css';
import {objectUrlForBase64, objectUrlForText} from '../../common/blobUtility';
import {t} from '../../translations';

export interface DownloadButtonProps
{
    className?: string
    buttonEmpty?: boolean
    getter(): Promise<string>
    title?: string
    filename?: string
    addDate?: boolean
    type?: 'pdf' | 'xlsx' | 'txt' | 'csv' | 'zip'
    downloadLink?: boolean
    downloadText?: string
}

export default function DownloadButton({className, getter, title, filename, addDate, type, downloadLink, downloadText}: DownloadButtonProps)
{
    const [status, setStatus] = useState('download');
    return (
        <a
            href='#'
            target='_blank'
            className={'unstyle-link ' + styles.secondary + (className ? ' ' + className : '')}
            onClick={e =>
            {
                if (status == 'downloading')
                {
                    return;
                }
                setStatus('downloading');
                e.preventDefault();
                const a = e.currentTarget;
                getter().then(res =>
                {
                    if (res != null)
                    {
                        if (downloadLink)
                        {
                            location.href = res;
                        }
                        else
                        {
                            a.href = type == 'txt' || type == 'csv' ? objectUrlForText(res) : objectUrlForBase64(res, 'application/' + type);
                            a.download = (filename || title || '') + (addDate ? '_' + moment().format('DDMMYYYY_HHmm') : '') + '.' + type;
                            a.click();
                        }
                        setStatus('download');
                    }
                    else
                    {
                        setStatus('failed');
                    }
                });
            }}
        >
            {{
                download: downloadText || t.global.download + ' ' + ({pdf: 'PDF', xlsx: 'excel', csv: 'CSV'}[type] || 'file'),
                downloading: downloadLink ? t.global.generating : t.global.downloading,
                failed: t.global.somethingWentWrong,
            }[status]}
            <img className={styles.icon} src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/download.svg' alt=''/>
        </a>
    );
}
