import React, {PropsWithChildren, useRef, useState} from 'react';
import {UploadOptions, uploadStore} from '../Upload/UploadStore';
import { action } from 'mobx';
import './ChangeImage.scss'
import {t} from '../translations';
import {Loading} from './Loading';

interface Props
{
    pic: string
    className?: string
    editable?: boolean
    buttonOnly?: boolean
    pdf?: boolean
    options?: UploadOptions
    onUpload(url: string): void
    onDelete?(): void
}

export default function ChangeImage({pic, onUpload, onDelete, className, buttonOnly, pdf, editable = true, options, children}: PropsWithChildren<Props>)
{
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const attachmentButton = useRef(null);

    const handleAttachment = (attachment: File) =>
    {
        setLoading(true);
        const reader = new FileReader();
        reader.onloadend = () =>
        {
            setImage(reader.result);
        };
        reader.readAsDataURL(attachment);
        uploadStore.upload(attachment, action(u =>
        {
            setImage(null);
            setLoading(false);
            onUpload(u.url);
        }), options);
    };
    const handleDelete = () =>
    {
        onDelete();
    }
    const selectFile = () =>
    {
        attachmentButton.current.click();
    };

    const handleAttachmentEvent = (e) =>
    {
        e.preventDefault();
        handleAttachment(e.target.files[0]);
    };

    const img = image || pic;

    return (
        <div className={'ChangeImage ' + (className || '')}>
            {buttonOnly ?
                <>
                    {loading ?
                        <div className='position-relative py-5'><Loading/></div> :
                        pdf ?
                            <a href={img}>{img}</a> :
                            <img className='picture' src={img} alt=''/>
                    }
                    {editable && <button className='always-visible' onClick={selectFile}>{t.global.selectImage}</button>}
                </>
                :
                <>
                    {editable && <span onClick={handleDelete}>X</span>}
                    {loading ? <div className='position-relative py-5'><Loading/></div> : <img src={img} alt=''/>}
                    {editable && <button onClick={selectFile}>{t.global.selectImage}</button>}
                </>
            }
            {editable && <input ref={attachmentButton} value='' onChange={handleAttachmentEvent} type='file' className='d-none'/>}
            {children}
        </div>
    );
}
