import {action, makeObservable, observable} from 'mobx';
import {call, gql} from '../client';
import {IUserPageContact} from '../stores/userProxy';
import {throttle} from '../common/throttle';

interface UsersPageInput
{
    cursor?: number
    profile?: string
    status?: string
    search?: string
    sortBy?: string
}

function load(input: UsersPageInput)
{
    return call<{usersPage: {count: number, users: IUserPageContact[]}}>(
        gql`query($input:UsersPageInput!){usersPage(input:$input){count,users{id,validated,rejected,email,firstName,lastName,phone,company{id,name},country,language,profiles{id},registered}}}`,
        {input}
    )
    .then(({data}) => data?.usersPage);
}

class UsersListStore
{
    @observable users: IUserPageContact[] = [];
    @observable count: number = 0;
    lastInput: Omit<UsersPageInput, 'cursor'>;
    @observable loading = 0;
    @observable loadingMore = false;

    load = throttle(action((input: Omit<UsersPageInput, 'cursor'>) =>
    {
        this.lastInput = input;
        ++this.loading;
        // this.users = [];
        load(input).then(action(res =>
        {
            --this.loading;
            if (res)
            {
                this.users = res.users;
                this.count = res.count;
            }
        }))
    }), 500, true);

    constructor()
    {
        makeObservable(this);
    }

    @action
    loadMore()
    {
        if (!this.lastInput)
        {
            return;
        }
        if (this.count == this.users.length)
        {
            return;
        }
        if (this.loading || this.loadingMore || this.load.isPending())
        {
            return;
        }
        this.loadingMore = true;
        const input = {...this.lastInput, cursor: this.users.length};
        load(input).then(action(res =>
        {
            this.loadingMore = false;
            if (res &&
                this.lastInput.profile == input.profile &&
                this.lastInput.status == input.status &&
                this.lastInput.search == input.search &&
                this.lastInput.sortBy == input.sortBy
            )
            {
                this.users.push(...res.users);
                this.count = res.count;
            }
        }))
    }
}

export const usersListStore = new UsersListStore();
