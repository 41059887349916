// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Timeline {
  position: relative;
  padding: 0 1rem 2rem;
}
@media screen and (max-width: 860px) {
  .Timeline {
    padding: 0 0 2rem;
  }
}
.Timeline P {
  margin: 0;
}
.Timeline .no-content {
  text-align: center;
  font-style: italic;
}

.TimelineTabs {
  gap: 10px;
}
.TimelineTabs .TimelineTab {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./frontend/Timeline/Timeline.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,oBAAA;AADF;AAEE;EAHF;IAII,iBAAA;EACF;AACF;AAAE;EACE,SAAA;AAEJ;AAAE;EACE,kBAAA;EACA,kBAAA;AAEJ;;AAEA;EACE,SAAA;AACF;AAAE;EACE,oBAAA;EACA,qBAAA;AAEJ","sourcesContent":["@import '../variables.scss';\n\n.Timeline {\n  position: relative;\n  padding: 0 1rem 2rem;\n  @media screen and (max-width: $sbsVertical) {\n    padding: 0 0 2rem;\n  }\n  P {\n    margin: 0;\n  }\n  .no-content {\n    text-align: center;\n    font-style: italic;\n  }\n}\n\n.TimelineTabs {\n  gap: 10px;\n  .TimelineTab {\n    padding-left: 1.5rem;\n    padding-right: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
