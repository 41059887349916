// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckoutPackageProductListDiscount {
  position: relative;
}
.CheckoutPackageProductListDiscount .delete {
  opacity: 0;
  position: absolute;
  right: -1.8rem;
  padding: 0 0.5rem;
  cursor: pointer;
}
.CheckoutPackageProductListDiscount .delete:hover {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./frontend/OrderStand/CheckoutPackageProductList.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,UAAA;EACA,kBAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;AAEJ;AADI;EACE,UAAA;AAGN","sourcesContent":[".CheckoutPackageProductListDiscount {\n  position: relative;\n  .delete {\n    opacity: 0;\n    position: absolute;\n    right: -1.8rem;\n    padding: 0 .5rem;\n    cursor: pointer;\n    &:hover {\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
