import {lazy, Suspense, useEffect, useState} from 'react';
import {Route, BrowserRouter as Router, Routes, Navigate, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {user} from './stores/user';
import {ErrorBoundary} from './common/ErrorBoundary';
const AdminPage = lazy(() => import('./Admin/AdminPage'));
const AdminLimitedPage = lazy(() => import('./Admin/AdminLimitedPage'));
import ChatPage from './Chat/ChatPage';
import {WithNavbar} from './Navbar/Navbar';
import WindowsOverlay from './Chat/WindowsOverlay';
import Dashboard from './Dashboard/Dashboard';
import AgendaPage from './Agenda/AgendaPage';
import HelpPage from './Help/HelpPage';
import HelpSection from './Help/HelpSection';
import OrderStandPage from './OrderStand/OrderStand';
import LandingPage from './Landing/LandingPage';
const SupplierCatalogPage = lazy(() => import('./Catalog/CatalogPage'));
const ExhibitorCatalogPage = lazy(() => import('./ExhibitorProducts/ExhibitorCatalog'));
const NumberOfProducts = lazy(() => import('./ExhibitorProducts/NumberOfProducts'));
import CompanyPage from './Company/CompanyPage';
const PackagesPage = lazy(() => import('./Packages/PackagesPage'));
import ContactPage from './Contact/ContactPage';
const StandsPage = lazy(() => import('./Stands/Stands'));
const InvoicePage = lazy(() => import('./Invoice/InvoicePage'));
const ProspectsPage = lazy(() => import('./Prospects/ProspectsPage'));
const ReportsPage = lazy(() => import('./Reports/ReportsPage'));
const StoreOrdersPage = lazy(() => import('./StoreOrders/StoreOrdersPage'));
const EmailDashboard = lazy(() => import('./EmailDashboard').then(e => ({default: e.EmailDashboard})));
const EmailView = lazy(() => import('./EmailDashboard').then(e => ({default: e.EmailView})));
const ResponseEmailView = lazy(() => import('./EmailDashboard').then(e => ({default: e.ResponseEmailView})));
import NavMenuOverlay from './Navbar/NavMenuOverlay';
import Page from './Pages/Page';
const Pages = lazy(() => import('./Pages/Pages'));
import StoreCatalog from './StoreCatalog/StoreCatalog';
import StoreCatalogProduct from './StoreCatalog/StoreCatalogProduct';
import CartPage from './StoreCatalog/CartPage';
import CartByWorkshopPage from './StoreCatalog/CartByWorkshopPage';
import OrdersPage from './StoreCatalog/OrdersPage';
import OrderPage from './StoreCatalog/OrderPage';
import FinancialReportPage from './StoreCatalog/FinancialReportPage';
import {projectStore} from './stores/ProjectStore';
import {companyStore} from './Company/CompanyStore';
import CartUpdateOverlay from './StoreCatalog/CartUpdateOverlay';
import {isID} from '../lib/common';
import PickPageTemplate from './Pages/PickPageTemplate';
import Disclaimer from './Disclaimer/Disclaimer';
import {privacyPolicyStore} from './Disclaimer/PrivacyPolicyStore';
import {pageStore} from './Pages/PageStore';
import {Loading} from './common/Loading';
const EventRegistrationPage = lazy(() => import('./Event/EventRegistrationPage'));
const LandingEventPage = lazy(() => import('./Event/LandingEventPage'));
import NewEventRegistrationFormPage from './EventRegistrationForm/EventRegistrationFormPage';
const UsersPage = lazy(() => import('./UsersPage/UsersPage'));
import FairParticipation from './FairParticipation/FairParticipation';
import {eventStore} from './Event/EventStore';
const ConfirmationPage = lazy(() => import('./Event/ConfirmationPage'));
const PageMobile = lazy(() => import('./Pages/MobilePreview/PageMobile'));
const EventCustomUrl = lazy(() => import('./Event/EventCustomUrl'));
const EventConfirmationCustomUrl = lazy(() => import('./Event/EventConfirmationCustomUrl'));
const VisualsPage = lazy(() => import('./Visuals/VisualsPage'));
const VisualsItemPage = lazy(() => import('./Visuals/VisualsItemPage'));
const PeoplePage = lazy(() => import('./People/PeoplePage'));

export default observer(function App()
{
    if (user.advancedSwitching)
    {
        return null;
    }

    const loggedIn = user.loggedIn;
    const mod = user.moderator;
    const canOrder = projectStore.canOrder;
    const catalogAccess = !projectStore.initialLoad ? true : user.moderator || projectStore.canSeeCatalog;
    const italyHeadquarters = projectStore.italyHeadquarters;
    const productsTableAccess = projectStore.productsTableAccess;
    const reports = loggedIn && (mod ||
        ['exhibitorMainAccount', 'exhibitorKeyAccount'].includes(user.profile) ||
        user.hqReports ||
        user.isHostess ||
        eventStore.hasSideEventReports
    );

    return (
        <Router>
            {loggedIn ?
                <WithNavbar>
                    <Routes>
                        <Route path='/dashboard' element={<Dashboard/>}/>
                        {mod && <Route path='/inbox' element={<EmailDashboard/>}/>}
                        {mod && <Route path='/inbox/:id' element={<EmailView/>}/>}
                        {mod && <Route path='/email/sent/:id' element={<ResponseEmailView/>}/>}
                        {mod && <Route path='/prospects' element={<ProspectsPage/>}/>}
                        <Route path='/contact/:id' element={<ContactPage/>}/>
                        <Route path='/company/:id?' element={<CompanyPage/>}/>
                        {mod && <Route path='/people' element={<PeoplePage/>}/>}
                        <Route path='/chat' element={<ChatPage/>}/>
                        {mod && <Route path='/supplier-catalog' element={<SupplierCatalogPage/>}/>}
                        {mod && <Route path='/packages' element={<PackagesPage/>}/>}
                        {mod && <Route path='/store-orders' element={<StoreOrdersPage/>}/>}
                        <Route path='/agenda' element={<AgendaPage/>}/>
                        <Route path='/help' element={<HelpPage/>}/>
                        <Route path='/help/:section' element={<HelpSection/>}/>
                        {mod && <Route path='/stands' element={<StandsPage/>}/>}
                        <Route path='/invoice/:stand/:invoice' element={<InvoicePage/>}/>
                        {mod && <Route path='/event-registration' element={<EventRegistrationPage/>}/>}
                        {eventStore.canAccessFairParticipation && <Route path='/fair-participation' element={<FairParticipation/>}/>}
                        {catalogAccess && (mod || ((canOrder || italyHeadquarters) && (!projectStore.initialLoad || projectStore.isActive))) && <Route path='/catalog' element={<StoreCatalog/>}/>}
                        {catalogAccess && (canOrder || mod || italyHeadquarters) && <Route path='/catalog/:product' element={<StoreCatalogProduct/>}/>}
                        {catalogAccess && canOrder && <Route path='/cart' element={<CartPage/>}/>}
                        {catalogAccess && canOrder && (!projectStore.selected || projectStore.isFRNonFood) && <Route path='/cart-by-workshop' element={<CartByWorkshopPage/>}/>}
                        {catalogAccess && canOrder && <Route path='/orders' element={<OrdersPage/>}/>}
                        {catalogAccess && canOrder && <Route path='/order/:id' element={<OrderPage/>}/>}
                        {catalogAccess && canOrder && (!projectStore.selected || projectStore.isFRNonFood) && <Route path='/financial-report' element={<FinancialReportPage/>}/>}
                        {mod && <Route path='/users' element={<UsersPage/>}/>}
                        {(user.admin || user.moderator) && <Route path='/admin/*' element={user.admin ? <AdminPage/> : <AdminLimitedPage/>}/>}
                        <Route path='/' element={<HomePage/>}/>
                        {productsTableAccess && (mod || ['exhibitorMainAccount', 'exhibitorKeyAccount'].includes(user.profile)) && <Route path='/products' element={<ExhibitorCatalogPage/>}/>}
                        {productsTableAccess && <Route path='/products-no' element={<NumberOfProducts/>}/>}
                        {(mod || ['exhibitorMainAccount', 'exhibitorKeyAccount', 'exhibitorInstallator', 'exhibitorFinance'].includes(user.profile)) && <Route path='/order-stand' element={<OrderStandPage/>}/>}
                        {reports && <Route path='/reports/:tab?' element={<ReportsPage/>}/>}
                        <Route path='/pages' element={<Pages/>}/>
                        <Route path='/pages/global' element={<Pages/>}/>
                        <Route path='/pages/project' element={<Pages/>}/>
                        {canChoosePage() && <Route path='/choose-page' element={<PickPageTemplate/>}/>}
                        {(mod || user.isExhibitor) && <Route path='/page/mobile/:id/*' element={<PageMobile/>}/>}
                        <Route path='/page/:id/*' element={<Page/>}/>
                        <Route path='/store' element={<Navigate to='/catalog'/>}/>
                        <Route path='/store/:product' element={<Navigate to='/catalog/:product'/>}/>
                        <Route path='/visuals' element={<VisualsPage/>}/>
                        <Route path='/visuals/:id' element={<VisualsItemPage/>}/>
                        <Route path='/old/event/:id' element={<LandingEventPage/>}/>
                        <Route path='/old/event/:id/confirmation' element={<ConfirmationPage/>}/>
                        <Route path='/old/:url' element={<EventCustomUrl/>}/>
                        <Route path='/old/:url/confirmation' element={<EventConfirmationCustomUrl/>}/>
                        <Route path='/:language?/event/:id' element={<NewEventRegistrationFormPage/>}/>
                        <Route path='/:language?/:url' element={<NewEventRegistrationFormPage/>}/>
                        <Route path='*' element={<Navigate to='/'/>}/>
                    </Routes>
                </WithNavbar> :
                <ErrorBoundary>
                    <Suspense fallback={<Loading className='position-fixed'/>}>
                        <Routes>
                            <Route path='/' element={<LandingPage/>}/>
                            <Route path='/visuals/:id' element={<VisualsItemPage/>}/>
                            <Route path='/old/event/:id' element={<LandingEventPage/>}/>
                            <Route path='/old/event/:id/confirmation' element={<ConfirmationPage/>}/>
                            <Route path='/old/:url' element={<EventCustomUrl/>}/>
                            <Route path='/old/:url/confirmation' element={<EventConfirmationCustomUrl/>}/>
                            <Route path='/:language?/event/:id' element={<NewEventRegistrationFormPage/>}/>
                            <Route path='/:language?/:url' element={<NewEventRegistrationFormPage/>}/>
                            <Route path='*' element={<Navigate to='/'/>}/>
                        </Routes>
                    </Suspense>
                </ErrorBoundary>
            }
            {loggedIn && <ErrorBoundary><WindowsOverlay/></ErrorBoundary>}
            {loggedIn && <NavMenuOverlay/>}
            {loggedIn && companyStore.ownCompanies?.some(c => c?.store?.id) && <CartUpdateOverlay/>}
            <GlobalHooks/>
            {!privacyPolicyStore.showDisclaimer && <Disclaimer/>}
        </Router>
    );
});

export function canChoosePage()
{
    return user.moderator || ['exhibitorMainAccount', 'exhibitorKeyAccount', 'exhibitorInstallator'].includes(user.profile);
}

const HomePage = observer(function HomePage()
{
    if (!projectStore.initialLoad)
    {
        return <Loading className='position-fixed'/>;
    }

    if (!projectStore.ownProjects.length)
    {
        return <Dashboard/>;
    }

    if (user.isHostess)
    {
        return <EventRegistrationPage tab='persons' showTabs={false}/>;
    }

    if (!user.moderator)
    {
        const homeLink = projectStore.selected?.fair.homeLink;
        let id = homeLink;
        if (homeLink?.includes('/'))
        {
            const arr = homeLink.split('/');
            id = arr[arr.length - 1];
        }
        if (isID(id))
        {
            return <Page id={id}/>;
        }
    }

    return user.isStore || user.isGuestOrMedia || user.isCarrefourGroup || projectStore.italyHeadquarters ?
        <Dashboard/> :
        <ExhibitorHomePage/>;
});

const triedLoadingExhibitorWelcomePage: string[] = [];

const ExhibitorHomePage = observer(function ExhibitorHomePage()
{
    const projectId = projectStore.id;

    const mainPage = pageStore.pages.find(p => p.project == projectId && p.name == 'Exhibitor Welcome Page');
    const mainPageNotLoaded = !mainPage;

    const [loading, setLoading] = useState(mainPageNotLoaded);

    useEffect(() =>
    {
        if (mainPageNotLoaded)
        {
            // setLoading(true);
            pageStore.loadByName(projectId, 'Exhibitor Welcome Page', true).then(() =>
            {
                triedLoadingExhibitorWelcomePage.push(projectId);
                setLoading(false);
            });
        }
    }, [projectId, mainPageNotLoaded]);

    return mainPage ? <Page id={mainPage.id}/> :
        loading && !triedLoadingExhibitorWelcomePage.includes(projectId) ? <Loading className='position-fixed'/> :
            <Dashboard/>;
});

const GlobalHooks = observer(function GlobalHooks()
{
    navigateRef = useNavigate();
    return null;
});

let navigateRef: ReturnType<typeof useNavigate>;
export const globalNavigate = () => navigateRef;
