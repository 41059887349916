import {useEffect, useState} from 'react';
import {action} from 'mobx';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router';
import {useNavigate, useSearchParams} from 'react-router-dom';
import type {GraphQLFormattedError} from 'graphql';
import {useHash} from '../OrderStand/useHash';
import {user} from '../stores/user';
import {pickLanguage} from '../stores/utility';
import {Country, Language} from '../../graphql/api/Types';
import {EventRegistrationFormStore, ILoginInput} from './EventRegistrationFormStore';
import {EventUsersListItem, loadUserRegistrationEvent, loadUserRegistrationEventByUrl} from '../Event/eventsProxy';
import {countryStore} from '../stores/CountryStore';
import {Loading} from '../common/Loading';
import MainLayout from './components/MainLayout';
import InitialView from './views/InitialView';
import EnterPhone from './views/EnterPhone';
import EnterEmail from './views/EnterEmail';
import EnterBadgeCode from './views/EnterBadgeCode';
import EnterConfirmationCode from './views/EnterConfirmationCode';
import {t} from '../translations';
import {IVerificationMethod} from '../../graphql/api/user/User';
import {loginCodeErrorMessages} from '../Login/EnterCode';
import SelectAccount from './views/SelectAccount';
import SelectYourselfOrGuest from './views/SelectYourselfOrGuest';
import SelectGuest from './views/SelectGuest';
import SelectAccountType from './views/SelectAccountType';
import AccountInfo from './views/AccountInfo';
import Participation from './views/Participation';
import Confirmation from './views/Confirmation';

export default function EventRegistrationFormPage()
{
    const {language, id, url} = useParams<{language: Language, id: string, url: string}>();
    const [query] = useSearchParams();
    const regID = query.get('regID');
    const navigate = useNavigate();

    const [store, setStore] = useState<EventRegistrationFormStore>();

    useEffect(() =>
    {
        const eventPromise =
            id ?
                loadUserRegistrationEvent(id)
                :
                url ?
                    loadUserRegistrationEventByUrl(url)
                    :
                    null;
        if (!eventPromise)
        {
            navigate('/');
            return;
        }
        eventPromise.then(async event =>
        {
            if (event)
            {
                const countryLanguages = languagesBasedOnCountry(event.country);
                const appliedLanguage = countryLanguages.includes(language) ?
                    language
                    :
                    countryLanguages.includes(user.language) ?
                        user.language
                        :
                        countryLanguages[0];
                if (user.language !== appliedLanguage)
                {
                    user.changeLandingLanguage(appliedLanguage);
                    if (user.loggedIn)
                    {
                        user.changeLanguage(appliedLanguage);
                    }
                }
                if (countryLanguages.length > 1 ? language !== appliedLanguage : language && language !== appliedLanguage)
                {
                    navigate((id ? `/${appliedLanguage}/event/${id}` : `/${appliedLanguage}/${url}`) + '?' + query.toString(), {replace: true});
                }
                const s = new EventRegistrationFormStore(event);
                if (regID)
                {
                    await s.login({method: 'RegID', value: regID});
                }
                setStore(s);
            }
            else
            {
                navigate('/');
            }
        });
        return () =>
        {
            setStore(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, url]);

    if (!store)
    {
        return <Loading className='position-fixed'/>;
    }

    const countryLanguages = languagesBasedOnCountry(store.event.country);

    return (
        <MainLayout
            image={pickLanguage(store.event.image)}
            languages={countryLanguages.length > 1 ? countryLanguages : null}
            onChangeLanguage={l =>
            {
                store.changeLanguage(l);
                navigate((id ? `/${l}/event/${id}` : `/${l}/${url}`) + '?' + query.toString(), {replace: true});
            }}
        >
            <EventRegistrationFormPageState store={store}/>
        </MainLayout>
    );
}

const languagesBasedOnCountry = (code: Country): Language[] => countryStore.countries.find(c => c.code == code)?.languages || ['en'];

const EventRegistrationFormPageState = observer(function EventRegistrationFormPageState({store}: {store: EventRegistrationFormStore})
{
    if (!store.verified)
    {
        if (!store.codeSent)
        {
            return <LoginStep store={store}/>;
        }

        return <ConfirmationCodeStep store={store}/>;
    }

    return <MainState store={store}/>;
});

const MainState = observer(function MainState({store}: {store: EventRegistrationFormStore})
{
    const [eventStep, setEventStep] = useState<boolean>();

    if (!store.verificationWithoutAnAccount)
    {
        if (!store.accounts)
        {
            return <div className='position-relative py-5'><Loading/></div>;
        }

        if (!store.selectedAccount && !store.selectedNewAccount)
        {
            return <PickAccountStep store={store}/>;
        }
    }

    if (!eventStep)
    {
        const handleUnselect = store.verificationWithoutAnAccount ?
            null
            :
            () =>
            {
                store.unselectAccount();
            };

        const handleNext = () => setEventStep(true);

        return <AccountInfoStep store={store} onBack={handleUnselect} onNext={handleNext}/>;
    }

    return <EventFormStep store={store} onBack={() => setEventStep(false)}/>;
});

export interface LoginMethodProps
{
    onLogin(input: ILoginInput): Promise<string | null | undefined>
}

function LoginStep({store}: {store: EventRegistrationFormStore})
{
    const hash = useHash();
    const navigate = useNavigate();

    if (user.loggedIn && !user.loaded)
    {
        return null;
    }

    const handleLogin: LoginMethodProps['onLogin'] = input =>
        store.login(input).then(errors =>
        {
            if (errors)
            {
                return formatLoginErrorMessages(input.method, errors);
            }
            else
            {
                navigate('#', {replace: true});
            }
        });

    if (hash === 'phone')
    {
        return <EnterPhone onLogin={handleLogin} defaultCountry={store.event.country}/>;
    }

    if (hash === 'email')
    {
        return <EnterEmail onLogin={handleLogin}/>;
    }

    if (hash === 'badge-code')
    {
        return <EnterBadgeCode onLogin={handleLogin} projectId={store.event.project}/>;
    }

    return <InitialView title={pickLanguage(store.event.title)} description={pickLanguage(store.event.description)}/>;
}

function ConfirmationCodeStep({store}: {store: EventRegistrationFormStore})
{
    const handleCode = (code: string) => store.submitCode(code).then(formatLoginCodeErrorMessages);

    const handleReSend = () => store.reSend().then(errors => errors ? formatLoginErrorMessages(store.method, errors) : null);

    return <EnterConfirmationCode method={store.method} value={store.emailOPhone} onSubmitCode={handleCode} onResendCode={handleReSend}/>;
}

const formatLoginErrorMessages = (method: IVerificationMethod | 'RegID', errors: GraphQLFormattedError[]) =>
{
    switch (errors[0].message)
    {
        case 'invalid-input':
            return method === 'Email' ?
                t.registration.form.errors.emailFormat
                :
                method === 'Sms' ?
                    t.registration.form.errors.phoneFormat
                    :
                    t.global.somethingWentWrong;
        case 'not-validated': return t.authentication.notValidated;
        case 'invalid-id': return 'The code is not correct';
        default: return t.global.somethingWentWrong;
    }
};
const formatLoginCodeErrorMessages = (errors: GraphQLFormattedError[]) => errors ? loginCodeErrorMessages(errors) : null;

function PickAccountStep({store}: {store: EventRegistrationFormStore})
{
    const [accountWithGuests, setAccountWithGuests] = useState<EventUsersListItem>();

    const handleSelect = (account?: EventUsersListItem) =>
    {
        if (account)
        {
            const project = store.event.project;
            if (account.profile === 'headquarter' && account.badges?.find(b => b.project == project)?.inviter)
            {
                setAccountWithGuests(account);
            }
            else
            {
                store.selectAccount(account);
            }
        }
        else
        {
            store.selectNewAccount();
        }
    };

    const usingAccountWithGuests = accountWithGuests || store.selectedGuestOf;
    if (usingAccountWithGuests)
    {
        const handleBack = () =>
        {
            if (accountWithGuests)
            {
                setAccountWithGuests(null);
            }
            if (store.selectedGuestOf)
            {
                store.unselectAccount();
            }
        };

        return <PickAccountWithGuestsStep store={store} accountWithGuests={usingAccountWithGuests} onBack={handleBack}/>;
    }

    return <SelectAccount method={store.method} value={store.emailOPhone} accounts={store.accounts} onSelect={handleSelect}/>;
}

const PickAccountWithGuestsStep = observer(function PickAccountWithGuestsStep({store, accountWithGuests, onBack}: {store: EventRegistrationFormStore, accountWithGuests: EventUsersListItem, onBack: () => void})
{
    const [showGuests, setShowGuests] = useState<boolean>();

    if (showGuests)
    {
        const guests = store.invitedGuests[accountWithGuests.id];

        if (!guests)
        {
            return <div className='position-relative py-5'><Loading/></div>;
        }

        const handleSelectGuest = (account: EventUsersListItem) =>
        {
            store.selectGuestAccount(accountWithGuests, account);
        };

        const handleAddGuest = () =>
        {
            store.selectNewGuestAccount(accountWithGuests);
        };

        const handleBack = () =>
        {
            setShowGuests(false);
        };

        return <SelectGuest account={accountWithGuests} guests={guests} onSelectGuest={handleSelectGuest} onAddGuest={handleAddGuest} onBack={handleBack}/>;
    }

    const handleSelectSelf = () =>
    {
        store.selectAccount(accountWithGuests);
    };

    const handleSelectGuests = () =>
    {
        setShowGuests(true);
        store.loadInvitedGuests(accountWithGuests);
    };

    return <SelectYourselfOrGuest account={accountWithGuests} onSelectSelf={handleSelectSelf} onSelectGuests={handleSelectGuests} onBack={onBack}/>;
});

function AccountInfoStep({store, onBack, onNext}: {store: EventRegistrationFormStore, onBack: () => void, onNext: () => void})
{
    return store.selectedAccount ?
        <AccountInfoStepUpdate store={store} onBack={onBack} onNext={onNext}/>
        :
        <AccountInfoStepRegister store={store} onBack={onBack} onNext={onNext}/>;
}

function AccountInfoStepRegister({store, onBack, onNext}: {store: EventRegistrationFormStore, onBack: () => void, onNext: () => void})
{
    const [profile, setProfile] = useState<string>(() => store.selectedGuestOf ? 'guest' : null);

    if (!profile)
    {
        return (
            <SelectAccountType
                store={store}
                onBack={onBack}
                onSelect={setProfile}
            />
        );
    }

    return (
        <AccountInfo
            country={store.event.country}
            project={store.event.project}
            method={store.method}
            emailOPhone={store.emailOPhone}
            profile={profile}
            onBack={store.selectedGuestOf ? () => setProfile(null) : onBack}
            onRegister={async accountInfo =>
            {
                const error = await store.register(accountInfo);
                if (error)
                {
                    return error;
                }
                else
                {
                    onNext();
                }
            }}
        />
    );
}

function AccountInfoStepUpdate({store, onBack, onNext}: {store: EventRegistrationFormStore, onBack: () => void, onNext: () => void})
{
    return (
        <AccountInfo
            country={store.event.country}
            project={store.event.project}
            account={store.selectedAccount}
            onBack={onBack}
            onUpdate={async accountInfo =>
            {
                const ok = await store.updateAccountInfo(accountInfo);
                if (ok)
                {
                    onNext();
                }
                return ok;
            }}
            onNext={onNext}
        />
    );
}

function EventFormStep({store, onBack}: {store: EventRegistrationFormStore, onBack: () => void})
{
    const [confirmationPage, setConfirmationPage] = useState<boolean>();

    if (confirmationPage)
    {
        return (
            <Confirmation
                event={store.event}
                account={store.selectedAccount}
                onBack={() => setConfirmationPage(false)}
                onRegisterAnotherPerson={action(() =>
                {
                    onBack();
                    if (store.registrationId)
                    {
                        store.reset();
                    }
                    else
                    {
                        store.unselectAccount();
                        store.selectNewAccount();
                    }
                })}
                onDownloadBadge={() => store.downloadBadge()}
            />
        );
    }

    return (
        <Participation
            event={store.event}
            account={store.selectedAccount}
            reloadStatusesForUser={() => store.loadStatusesForUser()}
            onBack={onBack}
            onRegister={async input =>
            {
                const errors = await store.registerForEvent(input);
                if (!errors)
                {
                    setConfirmationPage(true);
                }
                return errors;
            }}
        />
    );
}
