import React from 'react';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import './Dashboard.scss';
import SplitView from './SplitView';
import {TimelineWithTabs} from '../Timeline/Timeline';
import {emailStore} from '../Email/EmailStore';
import {user} from '../stores/user';
import ContactProfile from '../Contact/ContactProfile';
import CompanyMembers from './CompanyMembers';
import {t} from '../translations';
import OrganizationManagement from './OrganizationManagement';
import {contactsStore} from '../Contact/ContactsStore';
import YourContact from './YourContact';
import OrganizationNotifications from '../OrganizationNotifications/OrganizationNotifications';
import {ErrorBoundary} from '../common/ErrorBoundary';
import {Loading} from '../common/Loading';

export default observer(function Dashboard()
{
    const params = useParams<{id?: string}>();
    const id = params.id;
    if (!user.loaded)
    {
        return <div className='position-relative py-5'><Loading/></div>;
    }
    return (
        <SplitView
            left={
                <>
                    <ContactProfile/>
                    <div className='d-flex'>
                        <div className='dashboard-views'>
                            <FA name='stream' className='fad active'/>
                            {user.moderator && <Link to={'/inbox'}><FA name='envelope'/></Link>}
                            {user.info?.profiles[0].id != 'hostess' && <Link to={'/chat'}><FA name='comments-alt' className='fad'/></Link>}
                        </div>
                        <div className='flex-grow-1'/>
                        {user.moderator &&
                        <button className='button' onClick={() => emailStore.openEmail(id ? contactsStore.contacts.find(c => c.id == id) : null)}>
                            {t.global.newEmail}
                        </button>}
                    </div>
                    <ErrorBoundary>
                        <TimelineWithTabs id={id || user.id} isUserPage/>
                    </ErrorBoundary>
                </>
            }
            right={user.moderator ? <>
                <div className='mb-4'>
                    <OrganizationManagement/>
                </div>
                <OrganizationNotifications/>
            </> : <>
                <YourContact/>
                <CompanyMembers/>
            </>}
        />
    );
});
