import {Country, ITranslations, Language} from '../../graphql/api/Types';

export interface ISector
{
    name: ITranslations & {en: string}
    groupByKeyword?: ITranslations & {en: string}
    sub?: ISector[]
}

const sectorsBE: ISector[] = [{
    name: {
        en: 'Carrefour',
    },
    groupByKeyword: {
        en: 'Carrefour',
    },
}, {
    name: {
        en: 'Fresh',
        fr: 'Frais',
        nl: 'Vers',
        pl: 'Produkty Świeże',
        it: 'Fresco'
    },
    groupByKeyword: {
        en: 'PFT'
    },
    sub: [{
        name: {
            en: 'PFT'
        },
        sub: [{
            name: {
                en: 'Fruits & Vegetables / Flowers & Plants',
                fr: 'Fruits & légumes / Fleurs et plantes',
                nl: 'Groenten & fruit / Bloemen & planten',
                pl: 'Owoce & Warzywa / Kwiaty & Rośliny',
                it: 'Frutta e verdure / fiori e piante',
            },
        }, {
            name: {
                en: 'Meat & Poultry',
                fr: 'Boucherie & volaille',
                nl: 'Beenhouwerij & gevogelte',
                pl: 'Mięso i drób',
                it: 'Carne e Pollame',
            }
        },
        {
            name: {
                en: 'Fish',
                fr: 'Poissonnerie',
                nl: 'Vismarkt',
                pl: 'Ryby',
                it: 'Pesce',
            }
        }, {
            name: {
                en: 'Bakery & Pastry',
                fr: 'Boulangerie & Pâtisserie',
                nl: 'Brood- en banketbakkerij',
                pl: 'Piekarnia & Cukiernia',
                it: 'Pane e pasticceria',
            }
        },
        {
            name: {
                en: 'Delicatessen',
                fr: 'Traiteur',
                nl: 'Fijnkost',
                it: 'Gastronomia',
            }
        }]
    }]
},
{
    name: {
        en: 'PLS',
        it: 'PLS (prodotti a libero servizio)'
    },
    groupByKeyword: {
        en: 'PLS'
    },
    sub: [{
        name: {
            en: 'Fresh',
            fr: 'Frais',
            nl: 'Vers',
            pl: 'Pakowane',
            it: 'Fresco',
        }
    }, {
        name: {
            en: 'Frozen',
            fr: 'Surgelés',
            nl: 'Diepvries',
            pl: 'Garmażeria',
            it: 'Surgelato',
        }
    }]
}, {
    name: {
        en: 'PGC',
        pl: 'PDK',
        it: 'PGC (prodotti di gran consumo)'
    },
    groupByKeyword: {
        en: 'PGC',
        pl: 'PDK',
        it: 'PGC (prodotti di gran consumo)'
    },
    sub: [{
        name: {
            en: 'Grocery',
            fr: 'Epicerie',
            nl: 'Droge voeding',
            pl: 'Produkty suche',
            it: 'Drogheria',
        },
        groupByKeyword: {
            en: 'PGC - Grocery',
        }
    }, {
        name: {
            en: 'Beverages',
            fr: 'Liquides',
            nl: 'Dranken',
            pl: 'Napoje',
            it: 'Liquidi',
        },
        groupByKeyword: {
            en: 'PGC - Beverages',
        }
    },
    {
        name: {
            en: 'DPH',
            pl: 'Chemia i kosmetyki',
            it: 'DPH'
        },
        groupByKeyword: {
            en: 'PGC - DPH',
        }
    }]
}, {
    name: {
        en: 'Non Food & Textile',
        nl: 'Non Food & Textiel',
    },
    groupByKeyword: {
        en: 'Non Food & Textile',
        nl: 'Non Food & Textiel',
    },
}, {
    name: {
        en: 'Technical suppliers',
        fr: 'Fournisseurs techniques',
        nl: 'Technische leveranciers',
    },
    groupByKeyword: {
        en: 'Technical suppliers',
        fr: 'Fournisseurs techniques',
        nl: 'Technische leveranciers',
    },
}, {
    name: {
        en: 'Carrefour services',
        fr: 'Service Carrefour',
        pl: 'Usługi Carrefour',
        nl: 'Carrefour dienst',
        it: 'Carrefour Services'
    },
    groupByKeyword: {
        en: 'Carrefour services',
        fr: 'Service Carrefour',
        pl: 'Usługi Carrefour',
        nl: 'Carrefour dienst',
        it: 'Carrefour Services'
    },
}];

const sectorsFR: ISector[] = [{
    name: {
        en: 'Carrefour',
    },
    groupByKeyword: {
        en: 'Carrefour',
    },
}, {
    name: {
        en: 'Fresh',
        fr: 'Frais',
        nl: 'Vers',
        pl: 'Produkty Świeże',
        it: 'Fresco'
    },
    groupByKeyword: {
        en: 'PFT'
    },
    sub: [{
        name: {
            en: 'PFT'
        },
        sub: [{
            name: {
                en: 'Fruits & Vegetables / Flowers & Plants',
                fr: 'Fruits & légumes / Fleurs et plantes',
                nl: 'Groenten & fruit / Bloemen & planten',
                pl: 'Owoce & Warzywa / Kwiaty & Rośliny',
                it: 'Frutta e verdure / fiori e piante',
            },
        }, {
            name: {
                en: 'Meat & Poultry',
                fr: 'Boucherie & volaille',
                nl: 'Beenhouwerij & gevogelte',
                pl: 'Mięso i drób',
                it: 'Carne e Pollame',
            }
        },
        {
            name: {
                en: 'Fish',
                fr: 'Poissonnerie',
                nl: 'Vismarkt',
                pl: 'Ryby',
                it: 'Pesce',
            }
        }, {
            name: {
                en: 'Bakery & Pastry',
                fr: 'Boulangerie & Pâtisserie',
                nl: 'Brood- en banketbakkerij',
                pl: 'Piekarnia & Cukiernia',
                it: 'Pane e pasticceria',
            }
        },
        {
            name: {
                en: 'Delicatessen',
                fr: 'Traiteur',
                nl: 'Fijnkost',
                it: 'Gastronomia',
            }
        }]
    }]
},
{
    name: {
        en: 'PLS',
        it: 'PLS (prodotti a libero servizio)'
    },
    groupByKeyword: {
        en: 'PLS'
    },
    sub: [{
        name: {
            en: 'Fresh',
            fr: 'Frais',
            nl: 'Vers',
            pl: 'Pakowane',
            it: 'Fresco',
        }
    }, {
        name: {
            en: 'Frozen',
            fr: 'Surgelés',
            nl: 'Diepvries',
            pl: 'Garmażeria',
            it: 'Surgelato',
        }
    }]
}, {
    name: {
        en: 'PGC',
        pl: 'PDK',
        it: 'PGC (prodotti di gran consumo)'
    },
    groupByKeyword: {
        en: 'PGC',
        pl: 'PDK',
        it: 'PGC (prodotti di gran consumo)'
    },
    sub: [{
        name: {
            en: 'Grocery',
            fr: 'Epicerie',
            nl: 'Droge voeding',
            pl: 'Produkty suche',
            it: 'Drogheria',
        },
        groupByKeyword: {
            en: 'PGC - Grocery',
        }
    }, {
        name: {
            en: 'Beverages',
            fr: 'Liquides',
            nl: 'Dranken',
            pl: 'Napoje',
            it: 'Liquidi',
        },
        groupByKeyword: {
            en: 'PGC - Beverages',
        }
    },
    {
        name: {
            en: 'DPH',
            pl: 'Chemia i kosmetyki',
            it: 'DPH'
        },
        groupByKeyword: {
            en: 'PGC - DPH',
        }
    }]
}, {
    name: {
        en: 'Non Food & Textile',
        nl: 'Non Food & Textiel',
    },
    groupByKeyword: {
        en: 'Non Food & Textile',
        nl: 'Non Food & Textiel',
    },
}, {
    name: {
        en: 'Technical suppliers',
        fr: 'Fournisseurs techniques',
        nl: 'Technische leveranciers',
    },
    groupByKeyword: {
        en: 'Technical suppliers',
        fr: 'Fournisseurs techniques',
        nl: 'Technische leveranciers',
    },
}, {
    name: {
        en: 'Carrefour services',
        fr: 'Service Carrefour',
        pl: 'Usługi Carrefour',
        nl: 'Carrefour dienst',
        it: 'Carrefour Services'
    },
    groupByKeyword: {
        en: 'Carrefour services',
        fr: 'Service Carrefour',
        pl: 'Usługi Carrefour',
        nl: 'Carrefour dienst',
        it: 'Carrefour Services'
    },
}, {
    name: {
        en: 'DMR - Regions',
    },
    groupByKeyword: {
        en: 'DMR - Regions',
    },
}];

const sectorsIT: ISector[] = [
    {
        name: {
            en: 'Fresh',
            it: 'Fresco'
        },
        sub: [{
            name: {
                en: 'PFT'
            },
            groupByKeyword: {
                en: 'PFT'
            },
            sub: [{
                name: {
                    en: 'Fruits & Vegetables / Flowers & Plants',
                    it: 'Frutta e verdure / fiori e piante',
                },
            }, {
                name: {
                    en: 'Meat & Poultry',
                    it: 'Carne e Pollame',
                }
            },
            {
                name: {
                    en: 'Fish',
                    it: 'Pesce'
                }
            },
            {
                name: {
                    en: 'Meats',
                    it: 'Salumi'
                }
            },
            {
                name: {
                    en: 'Milk and cheeses',
                    it: 'Latte e formaggi'
                }
            },
            {
                name: {
                    en: 'Bread and pastry',
                    it: 'Pane e pasticceria',
                }
            },
            {
                name: {
                    en: 'Gastronomy',
                    it: 'Gastronomia'
                }
            }]
        }]
    },
    {
        name: {
            en: 'PLS',
            it: 'PLS (prodotti a libero servizio)'
        },
        groupByKeyword: {
            en: 'PLS',
            it: 'PLS (prodotti a libero servizio)'
        },
        sub: [{
            name: {
                en: 'Fresh',
                it: 'Fresco',
            }
        }, {
            name: {
                en: 'Frozen',
                it: 'Surgelato',
            }
        }]
    },
    {
        name:
        {
            en: 'PGC',
            it: 'PGC (prodotti di gran consumo)'
        },
        groupByKeyword: {
            en: 'PGC',
            it: 'PGC (prodotti di gran consumo)'
        },
        sub: [{
            name: {
                en: 'Grocery store',
                it: 'Drogheria',
            },
            groupByKeyword: {
                en: 'PGC - Grocery store',
            }
        },
        {
            name: {
                en: 'Liquids',
                it: 'Liquidi',
            },
            groupByKeyword: {
                en: 'PGC - Liquids',
            }
        },
        {
            name: {
                en: 'DPH',
                it: 'DPH'
            },
            groupByKeyword: {
                en: 'PGC - DPH',
            }
        }]
    },
    {
        name: {
            en: 'Non Food'
        },
        groupByKeyword: {
            en: 'Non Food'
        },
        sub: [{
            name: {
                en: 'Textile',
                it: 'Tessile',
            }
        }, {
            name: {
                en: 'Bazaar',
                it: 'Bazar',
            }
        },
        {
            name: {
                en: 'Efcs',
                it: 'Efcs',
            }
        }]
    },
    {
        name: {
            en: 'Bio Suppliers',
            it: 'Fornitori Bio'
        },
        groupByKeyword: {
            en: 'Bio Suppliers',
            it: 'Fornitori Bio'
        },
    },
    {
        name: {
            en: 'Regional Suppliers',
            it: 'Fornitori Regionali'
        },
        groupByKeyword: {
            en: 'Regional Suppliers',
            it: 'Fornitori Regionali'
        }
    },
    {
        name: {
            en: 'Technologies and services',
            it: 'Tecnologie e servizi'
        },
        groupByKeyword: {
            en: 'Technologies and services',
            it: 'Tecnologie e servizi'
        }
    },
    {
        name: {
            en: 'Carrefour services',
            it: 'Carrefour Services'
        },
        groupByKeyword: {
            en: 'Carrefour services',
            it: 'Carrefour Services'
        },
    },
];

const sectorsPL: ISector[] = [
    {
        name: {
            en: 'Concept Zone',
            pl: 'Strefa Koncept',
        },
        sub: [{
            name: {
                en: 'Bio'
            },
            groupByKeyword: {
                en: 'BIO',
            }
        }, {
            name: {
                en: 'International Food',
                pl: 'Kuchnie świata',
            },
            groupByKeyword: {
                en: 'International Food',
            },
        }]
    },
    {
        name: {
            en: 'Fresh Products',
            fr: 'Frais',
            nl: 'Vers',
            pl: 'Produkty Świeże',
            it: 'Fresco'
        },
        sub: [{
            name: {
                en: 'PFT'
            },
            groupByKeyword: {
                en: 'PFT',
            },
            sub: [{
                name: {
                    en: 'Fruits & Vegetables / Flowers & Plants',
                    fr: 'Fruits & légumes / Fleurs et plantes',
                    nl: 'Groenten & fruit / Bloemen & planten',
                    pl: 'Owoce & Warzywa / Kwiaty & Rośliny',
                    it: 'Frutta e verdure / fiori e piante',
                },
            }, {
                name: {
                    en: 'Meat & Poultry',
                    fr: 'Boucherie & volaille',
                    nl: 'Beenhouwerij & gevogelte',
                    pl: 'Mięso i drób',
                    it: 'Carne e Pollame',
                }
            }]
        },
        {
            name: {
                en: 'PFT/PLS'
            },
            groupByKeyword: {
                en: 'PFT/PLS',
            },
            sub: [
                {
                    name:
                    {
                        en: 'Meats',
                        pl: 'Wędliny'
                    }
                },
                {
                    name: {
                        en: 'Cheeses',
                        pl: 'Sery'
                    }
                },
                {
                    name: {
                        en: 'Fishes',
                        pl: 'Ryby'
                    }
                },
                {
                    name: {
                        en: 'Bakery & Cakes',
                        pl: 'Piekarnia & Cukiernia'
                    }
                },
                {
                    name: {
                        en: 'Frozen',
                        pl: 'Mrożonki'
                    }
                },
                {
                    name: {
                        en: 'VEGE',
                        pl: 'WEGE'
                    }
                },
                {
                    name: {
                        en: 'Juices',
                        pl: 'Soki'
                    }
                }
            ]
        }]
    },
    {
        name: {
            en: 'Fresh Packed Products',
            pl: 'Produkty świeże pakowane'
        },
        sub: [{
            name: {
                en: 'PLS',
                fr: 'PLS',
                nl: 'PLS',
                pl: 'PLS',
                it: 'PLS',
            },
            groupByKeyword: {
                en: 'PLS',
            },
            sub: [{
                name: {
                    en: 'Dairy: yogurts, cottage cheeses, fats, milk, eggs, creams',
                    pl: 'Nabiał: jogurty, sery twarogowe, serki, tłuszcze, mleko, jaja, śmietany',
                    it: 'Latte e formaggi',
                }
            },
            {
                name: {
                    en: 'Delicatessen',
                    fr: 'Traiteur',
                    nl: 'Fijnkost',
                    pl: 'Garmażeria',
                    it: 'Gastronomia',
                }
            }]
        }]
    },
    {
        name: {
            en: 'PGC',
            pl: 'PDK',
            it: 'PGC (prodotti di gran consumo)'
        },
        groupByKeyword: {
            en: 'PGC',
        },
        sub: [{
            name: {
                en: 'Grocery store',
                pl: 'Artykuły spożywcze',
            },
            groupByKeyword: {
                en: 'PGC - Grocery store',
            }
        }, {
            name: {
                en: 'Liquids',
                fr: 'Liquides',
                nl: 'Dranken',
                pl: 'Napoje',
                it: 'Liquidi',
            },
            groupByKeyword: {
                en: 'PGC - Liquids',
            }
        },
        {
            name: {
                en: 'DPH',
                pl: 'Chemia i kosmetyki',
            },
            groupByKeyword: {
                en: 'PGC - DPH',
            }
        }]
    },
    {
        name: {
            en: 'Non Food',
            pl: 'Art. Przemysłowe'
        },
        groupByKeyword: {
            en: 'Non Food',
        },
        sub: [{
            name: {
                en: 'Textile',
                it: 'Tessile',
                pl: 'Tekstylia'
            }
        }, {
            name: {
                en: 'Bazaar',
                pl: 'Bazar - sezon',
                it: 'Bazar',
            }
        },
        {
            name: {
                en: 'EPCS',
                pl: 'EPCS',
            }
        },
        {
            name: {
                en: 'Home',
                pl: 'Home',
            }
        }]
    },
    {
        name: {
            en: 'Technologies and services',
            pl: 'Technologie i usługi',
            it: 'Tecnologie e servizi'
        },
        groupByKeyword: {
            en: 'Technologies and services',
            pl: 'Technologie i usługi',
            it: 'Tecnologie e servizi'
        }
    },
];

const sectorsES: ISector[] = [
    {
        name: {
            en: 'Fresh',
            es: 'Fresco',
        },
        sub: [
            {
                name: {
                    en: 'PFT',
                    es: 'PFT',
                },
                groupByKeyword: {
                    en: 'PFT'
                },
                sub: [
                    {
                        name: {
                            en: 'Fruits and vegetables / flowers and plants',
                            es: 'Frutas y verduras / flores y plantas',
                        },
                    },
                    {
                        name: {
                            en: 'Meat and Poultry',
                            es: 'Carne y aves de corral',
                        },
                    },
                    {
                        name: {
                            en: 'Fish',
                            es: 'Pescado',
                        },
                    },
                    {
                        name: {
                            en: 'Meats',
                            es: 'Embutidos',
                        },
                    },
                    {
                        name: {
                            en: 'Milk and cheeses',
                            es: 'Leche y queso',
                        },
                    },
                    {
                        name: {
                            en: 'Bread and pastry',
                            es: 'Pan y pastelería',
                        },
                    },
                    {
                        name: {
                            en: 'Gastronomy',
                            es: 'Gastronomía',
                        },
                    },
                ],
            }
        ],
    },
    {
        name: {
            en: 'PLS',
            es: 'PLS (productos de libre servicio)',
        },
        groupByKeyword: {
            en: 'PLS',
            es: 'PLS (productos de libre servicio)',
        },
        sub: [
            {
                name: {
                    en: 'Fresh',
                    es: 'Fresco',
                },
            },
            {
                name: {
                    en: 'Frozen',
                    es: 'Congelados',
                },
            },
        ]
    },
    {
        name: {
            en: 'PGC',
            es: 'PGC (productos de consumo)',
        },
        groupByKeyword: {
            en: 'PGC',
            es: 'PGC (productos de consumo)',
        },
        sub: [
            {
                name: {
                    en: 'Grocery store',
                    es: 'Comestibles',
                },
                groupByKeyword: {
                    en: 'PGC - Grocery store',
                }
            },
            {
                name: {
                    en: 'Liquids',
                    es: 'Líquidos',
                },
                groupByKeyword: {
                    en: 'PGC - Liquids',
                }
            },
            {
                name: {
                    en: 'DPH',
                    es: 'DPH',
                },
                groupByKeyword: {
                    en: 'PGC - DPH',
                }
            },
        ]
    },
    {
        name: {
            en: 'Non Food',
            es: 'No Alimentación',
        },
        groupByKeyword: {
            en: 'Non Food'
        },
        sub: [
            {
                name: {
                    en: 'Textile',
                    es: 'Textil',
                },
            },
            {
                name: {
                    en: 'Bazaar',
                    es: 'Bazar',
                },
            },
            {
                name: {
                    en: 'Electro',
                    es: 'Electro',
                },
            },
        ]
    },
    {
        name: {
            en: 'Bio Suppliers',
            es: 'Proveedores Bio',
        },
        groupByKeyword: {
            en: 'Bio Suppliers',
            es: 'Proveedores Bio',
        },
    },
    {
        name: {
            en: 'Regional Suppliers',
            es: 'Proveedores Regionales',
        },
        groupByKeyword: {
            en: 'Regional Suppliers',
            es: 'Proveedores Regionales',
        }
    },
    {
        name: {
            en: 'Technologies and services',
            es: 'Actives (Technical suppliers)',
        },
        groupByKeyword: {
            en: 'Technologies and services',
            es: 'Actives (Technical suppliers)',
        }
    },
    {
        name: {
            en: 'Carrefour Services',
            es: 'Servicios Carrefour',
        },
        groupByKeyword: {
            en: 'Carrefour services',
            es: 'Servicios Carrefour',
        },
    },
];

const countriesMap: {[key in Country]: ISector[]} =
{
    BE: sectorsBE,
    IT: sectorsIT,
    PL: sectorsPL,
    FR: sectorsFR,
    ES: sectorsES,
};

export interface SectorItem
{
    names: string[] // translated name
    keys: string[] // key
    keyword: ITranslations
}

export interface FlatListSectorItem
{
    id: string // key
    name: string // translated name
}

const separator = '-';

// to get the list of IDs in country:
// console.log(getSectorItems('en', 'PL').map(({keys}) => keys.join('-')));

export function getSectorFlatList(country: Country, language?: Language): FlatListSectorItem[]
{
    return getSectorFromList((countriesMap[country]), language).map(({keys, names}) => ({id: keys.join('-'), name: names.join(' > ')}));
}

export function newSectorNameByIdGetter(country?: Country, language?: Language)
{
    const list = getSectorFlatList(country, language);
    return (sectorId: string) => list.find(s => s.id == sectorId)?.name || sectorId;
}

export function getSectorItems(language?: Language, country?: Country): SectorItem[]
{
    return country ? getSectorFromList((countriesMap[country]), language) : [];
}

function getSectorFromList(list: ISector[], language?: Language): SectorItem[]
{
    const res: SectorItem[] = [];
    for (const sector of list)
    {
        const name = language && sector.name[language] || sector.name.en;
        const key = sector.name.en.replace(/[ &/]+/g, separator);
        const keyword = sector.groupByKeyword;
        if (sector.sub)
        {
            for (const sub of getSectorFromList(sector.sub, language))
            {
                sub.names.unshift(name);
                sub.keys.unshift(key);
                res.push({
                    names: sub.names,
                    keys: sub.keys,
                    keyword: sub.keyword || keyword,
                });
            }
        }
        else
        {
            res.push({
                names: [name],
                keys: [key],
                keyword
            });
        }
    }
    return res;
}

// export const sectorOptions: [string, string][] = getSectorItems('en', 'BE')
//     .map(({keys, names}) => [keys.join(separator), names.join(' > ')]);

export function isFreshSector(sector: string)
{
    return !!sector && /(PFT|PLS)/i.test(sector);
}
