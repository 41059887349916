import { gql, call } from '../client';
import { ICompany } from '../Company/CompanyStore';
import {IStatisticsSector} from '../../graphql/api/prospects/prospects';

export interface IProspectsCompany extends ICompany
{
    companyMembers:
    {
        id: string;
        name: string;
    }[]
}

export function prospectsCompanies(project: string, tab: string, search?: string, sector?: string, cursor?: number)
{
    return call<{prospectsCompanies: {count: number, companies: IProspectsCompany[]}}>(
        gql`query($input:ProspectsCompaniesInput!){prospectsCompanies(input:$input){count,companies{id, name, projects, country, companyMembers, exhibitor}}}`,
        {input: {project, tab, search, sector, cursor}})
    .then(({data}) => data?.prospectsCompanies);
}

export function updateCompanyProjectStatus(ids: string[], status: string, project: string)
{
    return call<{updateCompanyStatus: boolean}>(
        gql`mutation ($input:UpdateCompanyStatusInput!){updateCompanyStatus(input:$input)}`,
        {input: {ids, status, project}})
    .then(({data}) => data?.updateCompanyStatus);
}

export function removeCompanyFromProject(ids: string[], project: string)
{
    return call<{removeCompanyFromProject: boolean}>(
        gql`mutation ($input:RemoveCompanyFromProjectInput!){removeCompanyFromProject(input:$input)}`,
        {input: {ids, project}})
    .then(({data}) => data?.removeCompanyFromProject);
}

export function addProjectToCompany(ids: string[], project: string)
{
    return call<{addProjectToCompany: boolean}>(
        gql`mutation ($input:AddProjectToCompanyInput!){addProjectToCompany(input:$input)}`,
        {input: {ids, project}})
    .then(({data}) => data?.addProjectToCompany);
}

export function prospectsStatistics(project: string)
{
    return call<{prospectsStatistics: IStatisticsSector}>(
        gql`query($project:String!){prospectsStatistics(project:$project)}`,
        {project})
    .then(({data}) => data?.prospectsStatistics);
}

export function exportStatistics(obj)
{
    return call<{exportStatistics}>(
        gql`query($obj:JSONObject!){exportStatistics(obj:$obj)}`,
        {obj})
    .then(({data}) => data?.exportStatistics);
}

export function prospectsExport(project: string)
{
    return call<{prospectsExport}>(
        gql`query($project:ID!){prospectsExport(project:$project)}`,
        {project})
    .then(({data}) => data?.prospectsExport);
}

export function callImportProspectCompanies(project: string, file: string, fileName: string)
{
    return call<{importProspectCompanies: number}>(
        gql`mutation ($input: ProspectCompaniesImport!){importProspectCompanies(input:$input)}`, {input: {project, file, fileName}})
    .then(({errors, data}) => ({errors, data: data?.importProspectCompanies}));
}
