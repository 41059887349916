import {Fragment, useMemo, useState} from 'react';
import styles from './SelectAccountType.module.css';
import erStyles from './EventRegistration.module.css';
import Button from '../components/Button';
import {EventRegistrationFormStore} from '../EventRegistrationFormStore';
import {pickLanguage} from '../../stores/utility';
import {t} from '../../translations';
import {findProfile, IProfile, profiles} from '../../data/profiles';
import {user} from '../../stores/user';

interface AccountTypeGroup
{
    id: string
    name: string
    types: AccountType[]
}

interface AccountType
{
    id: string
    name: string
}

interface Props
{
    store: EventRegistrationFormStore
    onBack: () => void
    onSelect: (profile: string) => void
}

const getProfileGroup = (p: IProfile) =>
    ['headquarter', 'carrefourGroup', 'externalSuppliersCarrefour'].includes(p.id) ?
        'carrefour'
        :
        ['guest', 'media'].includes(p.id) ?
            'guest-media'
            :
            p.id.includes('exhibitor') ?
                'exhibitor'
                :
                p.storeType || p.storeTypes || p.features?.mobile?.store?.length ?
                    'store'
                    :
                    'organization';

export default function SelectAccountType({store, onBack, onSelect}: Props)
{
    const [selectedGroupId, setSelectedGroupId] = useState<string>();
    const [selectedTypeId, setSelectedTypeId] = useState<string>();

    const accountTypes: AccountTypeGroup[] = useMemo(() =>
    {
        const resAccountTypes: AccountTypeGroup[] = [
            {
                id: 'store',
                name: t.eventRegistration.groupStores,
                types: [],
            },
            {
                id: 'exhibitor',
                name: t.global.exhibitor,
                types: [],
            },
            {
                id: 'carrefour',
                name: t.eventRegistration.groupCarrefour,
                types: [],
            },
            {
                id: 'guest-media',
                name: t.eventRegistration.guestMedia,
                types: [],
            },
            {
                id: 'organization',
                name: t.eventRegistration.organization,
                types: [],
            },
        ];

        const event = store.event;

        const availableProfiles =
            user.moderator ?
                profiles.filter(p => p.countries.includes(event.country))
                :
                event.eventProfiles
                    ?.map(p =>
                    {
                        if (p === 'exhibitorRep')
                        {
                            return {
                                id: p,
                                name: {en: t.global.exhibitor},
                                group: 'exhibitor',
                            };
                        }
                        return findProfile(p);
                    }) || [];

        for (const p of availableProfiles)
        {
            const groupId = 'group' in p ? p.group : getProfileGroup(p);
            const group = resAccountTypes.find(g => g.id === groupId);
            group.types.push({
                id: p.id,
                name: pickLanguage(p.name),
            });
        }

        if (store.eventHasInvitationCode)
        {
            resAccountTypes.find(g => g.id === 'guest-media').types.push({id: 'invitedGuest', name: t.registration.form.imInvitedWithCode});
        }

        return resAccountTypes.filter(g => g.types.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.language, user.moderator]);

    return (
        <>
            <div className={erStyles.title}>
                <img className={erStyles.icon} src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/select-account-type.svg' alt=''/>
                <h2>{t.eventRegistration.iAm}</h2>
            </div>
            <div className={erStyles.content}>
                {accountTypes.map(g =>
                    <Fragment key={g.id}>
                        <div
                            className={styles.type + ' ' + (selectedGroupId ? selectedGroupId === g.id ? styles.selected : styles.notSelected : '')}
                            onClick={() =>
                            {
                                setSelectedGroupId(selectedGroupId === g.id ? undefined : g.id);
                                setSelectedTypeId(undefined);
                            }}
                        >
                            <span>{g.name}</span>
                            <img src={selectedGroupId === g.id ? 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/minus.svg' : 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/radio-button-unchecked.svg'} alt=''/>
                        </div>
                        <div className={styles.subtypes}>
                            {g.types.map(at =>
                                <div
                                    key={at.id}
                                    className={styles.type + ' ' + (selectedGroupId === g.id ? selectedTypeId ? selectedTypeId === at.id ? styles.selected : styles.notSelected : '' : styles.hidden)}
                                    onClick={() => setSelectedTypeId(at.id)}
                                >
                                    <span>{at.name}</span>
                                    <img src={selectedTypeId === at.id ? 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/radio-button-checked.svg' : 'https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/radio-button-unchecked.svg'} alt=''/>
                                </div>
                            )}
                        </div>
                    </Fragment>
                )}
                <div className={erStyles.buttons}>
                    {onBack &&
                        <Button onClick={onBack}>
                            <img className='mr-2' src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/back.svg' alt=''/>
                            {t.eventRegistration.back}
                        </Button>
                    }
                    <Button primary disabled={!selectedTypeId} onClick={selectedTypeId ? () => onSelect(selectedTypeId) : null}>{t.eventRegistration.confirmSelection}</Button>
                </div>
            </div>
        </>
    );
}
