
export type IPathKey = string | number
export type IPath = IPathKey[]
export type IMaybePath = IPathKey | IPath

export function deepSet(obj: {}, path: IMaybePath, value: any)
{
    if (Array.isArray(path))
    {
        let i;
        for (i = 0; i < path.length - 1; ++i)
        {
            const k = path[i];
            if (!obj[k])
            {
                const nextKey = path[i + 1];
                obj[k] = Number.isInteger(nextKey as number) && (nextKey as number) >= 0 ? [] : {};
            }
            obj = obj[k];
        }
        obj[path[i]] = value;
    }
    else
    {
        obj[path] = value;
    }
}
