import React, {useState} from 'react';
import moment from 'moment';
import {projectStore} from '../stores/ProjectStore';
import {objectUrlForBase64, objectUrlForText} from '../common/blobUtility';
import {t} from '../translations';

export interface DownloadButtonProps
{
    className?: string
    buttonEmpty?: boolean
    getter(project: string): Promise<string>
    title?: string
    filename?: string
    addDate?: boolean
    type?: 'pdf' | 'xlsx' | 'txt' | 'csv' | 'zip'
    downloadLink?: boolean
    downloadText?: string
}

export default function DownloadButton({className, buttonEmpty, getter, title, filename, addDate, type, downloadLink, downloadText}: DownloadButtonProps)
{
    const [status, setStatus] = useState('download');

    function handle(e)
    {
        if (status == 'downloading')
        {
            return;
        }
        setStatus('downloading');
        e.preventDefault();
        const a = e.currentTarget;
        getter(projectStore.id).then(res =>
        {
            if (res != null)
            {
                if (downloadLink)
                {
                    location.href = res;
                }
                else
                {
                    a.href = type == 'txt' || type == 'csv' ? objectUrlForText(res) : objectUrlForBase64(res, 'application/' + type);
                    a.download = (filename || title || '') + (addDate ? '_' + moment().format('DDMMYYYY_HHmm') : '') + '.' + type;
                    a.click();
                }
                setStatus('download');
            }
            else
            {
                setStatus('failed');
            }
        });
    }

    return (
        <>
            {!!title && <h5 className='mb-3'>{title}</h5>}
            <a href='#' target='_blank' className={'unstyle-link d-inline-block ' + (buttonEmpty ? 'button-empty' : 'button') + ' ' + (className?.includes('mb-') ? '' : 'mb-4 ') + (className || '')} onClick={handle}>
                {{
                    download: downloadText || 'Download ' + ({pdf: 'PDF', xlsx: 'excel', csv: 'CSV'}[type] || 'file'),
                    downloading: downloadLink ? t.global.generating : t.global.downloading,
                    failed: t.global.somethingWentWrong,
                }[status]}
            </a>
        </>
    );
}
