// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavbarProjectSelect {
  margin-top: 30px;
  max-width: 323px;
  display: flex;
  flex-direction: column;
}
.NavbarProjectSelect > a {
  padding-left: 1em;
}
.NavbarProjectSelect > a:not(:first-child) {
  margin-top: 5px;
}
.NavbarProjectSelect > span {
  font-size: 16px;
  color: #403d39;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  padding-left: 1em;
  cursor: pointer;
}
.NavbarProjectSelect > span:hover:not(.active) {
  background: #3d8af7;
  color: #fff;
}
.NavbarProjectSelect > span:not(:first-child) {
  margin-top: 5px;
}
.NavbarProjectSelect > span.active {
  background: #000;
  color: #fff;
  font-weight: 500;
  position: relative;
}
.NavbarProjectSelect > span.active::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 20px 12px 0;
  border-color: transparent #fff transparent transparent;
  position: absolute;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./frontend/Navbar/NavbarProjectSelect.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACF;AAAE;EACE,iBAAA;AAEJ;AADI;EAEE,eAAA;AAEN;AACE;EACE,eAAA;EACA,cAAA;EACA,mCAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AACJ;AACM;EAEE,mBAAA;EACA,WAAA;AAAR;AAGI;EAEE,eAAA;AAFN;AAII;EACE,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;AAFN;AAGM;EACE,WAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;EACA,8BAAA;EACA,sDAAA;EACA,kBAAA;EACA,QAAA;AADR","sourcesContent":[".NavbarProjectSelect {\n  margin-top: 30px;\n  max-width: 323px;\n  display: flex;\n  flex-direction: column;\n  > a {\n    padding-left: 1em;\n    &:not(:first-child)\n    {\n      margin-top: 5px;\n    }\n  }\n  > span {\n    font-size: 16px;\n    color: #403d39;\n    font-family: Montserrat, sans-serif;\n    font-weight: 300;\n    padding-left: 1em;\n    cursor: pointer;\n    &:hover {\n      &:not(.active)\n      {\n        background: #3d8af7;\n        color: #fff;\n      }\n    }\n    &:not(:first-child)\n    {\n      margin-top: 5px;\n    }\n    &.active {\n      background: #000;\n      color: #fff;\n      font-weight: 500;\n      position: relative;\n      &::after {\n        content: '';\n        width: 0;\n        height: 0;\n        border-style: solid;\n        border-width: 12px 20px 12px 0;\n        border-color: transparent #fff transparent transparent;\n        position: absolute;\n        right: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
