// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContentBlock {
  position: relative;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}
.ContentBlock .text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  white-space: pre-line;
  text-decoration: none;
  overflow: hidden;
}
.ContentBlock .engagement {
  position: absolute;
  top: 4px;
  right: 0;
  padding: 0 10px;
  text-align: right;
  overflow: hidden;
  color: #fff;
  font-size: 0.8rem;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./frontend/Pages/ContentBlock.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,wBAAA;EACA,2BAAA;EACA,4BAAA;EACA,gCAAA;AACF;AAAE;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,qBAAA;EACA,qBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,QAAA;EACA,QAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,oBAAA;AAEJ","sourcesContent":[".ContentBlock {\n  position: relative;\n  height: 100%;\n  background-size: contain;\n  background-position: center;\n  background-repeat: no-repeat;\n  transition: all .3s ease-in-out;\n  .text {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    display: flex;\n    flex-direction: column;\n    padding: 0 10px;\n    white-space: pre-line;\n    text-decoration: none;\n    overflow: hidden;\n  }\n  .engagement {\n    position: absolute;\n    top: 4px;\n    right: 0;\n    padding: 0 10px;\n    text-align: right;\n    overflow: hidden;\n    color: #fff;\n    font-size: .8rem;\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
