// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SplitView {
  min-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  display: flex;
  overflow-y: hidden;
  flex-grow: 1;
}
.SplitView > .left, .SplitView > .right {
  padding: 1.1rem;
  overflow: auto;
}
.SplitView > .left:after, .SplitView > .right:after {
  content: "";
  display: block;
  height: 1.1rem;
}
.SplitView > .left {
  flex-basis: 66%;
}
.SplitView > .right {
  flex-basis: 34%;
  max-width: 40%;
  border-left: 1px solid #aaaaaa;
}
@media screen and (max-width: 860px) {
  .SplitView {
    min-height: initial;
    height: initial;
    flex-direction: column;
  }
  .SplitView > .left {
    flex-basis: initial;
  }
  .SplitView > .right {
    flex-basis: initial;
    max-width: initial;
    border-left: none;
    border-top: 1px solid #aaaaaa;
  }
}

.scroll-off {
  overflow: hidden !important;
}`, "",{"version":3,"sources":["webpack://./frontend/Dashboard/SplitView.scss"],"names":[],"mappings":"AAEA;EACE,8BAAA;EACA,0BAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;AADF;AAEE;EACE,eAAA;EACA,cAAA;AAAJ;AACI;EACE,WAAA;EACA,cAAA;EACA,cAAA;AACN;AAEE;EACE,eAAA;AAAJ;AAEE;EACE,eAAA;EACA,cAAA;EACA,8BAAA;AAAJ;AAEE;EAvBF;IAwBI,mBAAA;IACA,eAAA;IACA,sBAAA;EACF;EAAE;IACE,mBAAA;EAEJ;EAAE;IACE,mBAAA;IACA,kBAAA;IACA,iBAAA;IACA,6BAAA;EAEJ;AACF;;AAEA;EACE,2BAAA;AACF","sourcesContent":["@import '../variables.scss';\n\n.SplitView {\n  min-height: calc(100vh - 60px);\n  height: calc(100vh - 60px);\n  display: flex;\n  overflow-y: hidden;\n  flex-grow: 1;\n  > .left, > .right {\n    padding: 1.1rem;\n    overflow: auto;\n    &:after {\n      content: '';\n      display: block;\n      height: 1.1rem;\n    }\n  }\n  > .left {\n    flex-basis: 66%;\n  }\n  > .right {\n    flex-basis: 34%;\n    max-width: 40%;\n    border-left: 1px solid #aaaaaa;\n  }\n  @media screen and (max-width: $sbsVertical) {\n    min-height: initial;\n    height: initial;\n    flex-direction: column;\n    > .left {\n      flex-basis: initial;\n    }\n    > .right {\n      flex-basis: initial;\n      max-width: initial;\n      border-left: none;\n      border-top: 1px solid #aaaaaa;\n    }\n  }\n}\n\n.scroll-off {\n  overflow: hidden !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
