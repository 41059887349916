import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {action, makeAutoObservable} from 'mobx';
import {t} from '../../translations';
import {EventUsersListItem} from '../../Event/eventsProxy';
import FA from 'react-fontawesome';
import {debounce} from '../../common/debounce';
import {getStore} from '../../Landing/landingProxy';
import {Country} from '../../../graphql/api/Types';

interface Props
{
    country: Country
    profile: string
    accountCompanies?: EventUsersListItem['company']
    onChange(storeIds: string[]): void
}

export default observer(function SelectCompanyStore({country, profile, accountCompanies, onChange}: Props)
{
    const [stores, setStores] = useState<StoreWithName[]>(() => [new StoreWithName(country)]);

    const isIntegrated = profile.includes('Integrated') || profile == 'hypermarket';
    const removable = !isIntegrated && stores?.length > 1;

    const handleAddStore = () =>
    {
        setStores(stores ? [...stores, new StoreWithName(country)] : [new StoreWithName(country)]);
    };

    const handleChangeStoreId = (index: number, id: string) =>
    {
        stores[index].id = id;
        onChange(stores.map(s => s.id));
    };

    const handleRemoveStore = (index: number) =>
    {
        const newStores = stores.slice();
        newStores.splice(index, 1);
        setStores(newStores);
    };

    return (
        <div className='store-select'>
            <div className='add-stores'>
                <div className='added-stores'>
                    {accountCompanies ?
                        accountCompanies.map(({name}, i) =>
                            <div key={i} className='mb-4'>
                                <label>{t.eventRegistration.storeLabel}</label>
                                <input
                                    className='form-control'
                                    type='text'
                                    value={name}
                                    disabled
                                />
                            </div>
                        ) :
                        stores?.map(({id, name, error}, i) =>
                            <div key={i} className='mb-3'>
                                <label htmlFor={'store-id-' + i}>{profile == 'proximity' ? 'N° Client' : t.registration.form.storeId}</label>
                                <div className='d-flex align-items-center'>
                                    <input
                                        id={'store-id-' + i}
                                        type='text'
                                        className={'form-control' + (error ? ' text-danger' : '')}
                                        style={{width: '5.5rem'}}
                                        value={id || ''}
                                        onChange={e => handleChangeStoreId(i, e.target.value)}
                                    />
                                    <div className={'flex-grow-1 d-flex align-items-center ml-3' + (!name ? ' text-secondary' : '')}>
                                        {name}
                                    </div>
                                    {removable &&
                                        <div className={'d-flex align-items-center cursor-pointer' + (!id ? ' text-secondary' : '')}>
                                            <FA name='times-circle' className='fas' onClick={() => handleRemoveStore(i)}/>
                                        </div>
                                    }
                                </div>
                                {error && <span className='text-danger'>{error}</span>}
                            </div>
                        )
                    }
                </div>
                {!accountCompanies && !isIntegrated &&
                    <div className='d-flex mb-3 align-items-center cursor-pointer' onClick={handleAddStore}>
                        <FA name='plus-circle' className='fas text-larger'/>
                        <span className='ml-2'>{t.eventRegistration.addStore}</span>
                    </div>
                }
            </div>
        </div>
    );
});

class StoreWithName
{
    _id: string = null;
    _name: string = null;
    _error: string = null;

    constructor(private country: Country, private eventOnly?: boolean)
    {
        makeAutoObservable(this);
    }

    get id()
    {
        return this._id;
    }

    set id(id: string)
    {
        this._id = id;
        this.search(id);
    }

    get name()
    {
        return this._name;
    }

    get error()
    {
        return this._error;
    }

    private search = debounce(action((storeId: string) =>
    {
        if (storeId)
        {
            getStore(this.country, storeId, this.eventOnly ? 'MF' : null).then(action(res =>
            {
                if (res)
                {
                    this._name = res.name;
                    this._error = null;
                }
                else
                {
                    this._name = null;
                    this._error = 'Not found';
                }
            }));
        }
        else
        {
            this._name = null;
            this._error = t.registration.form.enterStoreID;
        }
    }), 500);
}
