import styles from './SelectAccount.module.css';
import erStyles from './EventRegistration.module.css';
import {ILoginInput} from '../EventRegistrationFormStore';
import {EventUsersListItem} from '../../Event/eventsProxy';
import {getName} from '../../common/getName';
import {pickLanguage} from '../../stores/utility';
import {findProfile} from '../../data/profiles';
import {t} from '../../translations';

interface Props extends ILoginInput
{
    accounts: EventUsersListItem[]
    onSelect(account?: EventUsersListItem): void
}

export default function SelectAccount({method, value, accounts, onSelect}: Props)
{
    return (
        <>
            <div className={erStyles.title}>
                <img className={erStyles.icon} src='https://yourfair-pub.s3.eu-west-1.amazonaws.com/assets/event-registration/select-account.svg' alt=''/>
                <h2>{t.eventRegistration.selectAccount}</h2>
            </div>
            <div className={erStyles.content}>
                <div>{method === 'Email' ? t.eventRegistration.loggedInWithEmail : t.eventRegistration.loggedInWithPhone} {value}</div>
                {accounts.map(a =>
                    <div
                        key={a.id}
                        className={styles.account + ' clearfix'}
                        onClick={() => onSelect(a)}
                    >
                        {getName(a)}
                        <span className={styles.accountProfile}>{pickLanguage(findProfile(a.profile)?.name) || a.profile}</span>
                    </div>
                )}
                <div className={styles.registerLine}>
                    {t.eventRegistration.iDontSeeMyName + ' '}
                    <span className={styles.registerButton} onClick={() => onSelect()}>{t.eventRegistration.registerHere}</span>
                </div>
            </div>
        </>
    );
}
