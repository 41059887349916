import {call, gql} from '../client';
import {IOrderProductPromotion, IOrderTotal} from '../../graphql/api/order/Order';

export interface IOrder
{
    id: string
    project: string
    store: string
    buyer: string
    exhibitor: string
    exhibitorName: string
    date: Date
    products: IOrderProduct[]
    total: IOrderTotal
}

export interface IOrderProduct
{
    id: string
    promotion: IOrderProductPromotion[]
}

export function loadOrders(project: string)
{
    return call<{orders: IOrder[]}>(
        gql`query($project:ID!){orders(project:$project){id,project,store,buyer,exhibitor,exhibitorName,date,products{id,promotion{promoCode,week,quantity,invoice,selling,discount,paid}},total{invoice,selling,discount,total}}}`,
        {project})
    .then(({data}) => data?.orders);
}
