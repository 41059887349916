import {makeTranslation} from '../../lib/translation';

export const translationsPublic = {
    disclaimer: {
        cookies: {
            title: {
                en: 'This website uses cookies',
                fr: 'Ce site web utilise les cookies',
                nl: 'De website gebruikt cookies',
                pl: 'Ten serwis wykorzystuje pliki cookies',
                it: 'Questo sito utilizza i cookies',
                es: 'Este sitio web utiliza cookies',
            },
            info: {
                en: 'We use cookies necessary for the basic functions of our website, cookies that allow the website to remember your choices and give you better functionality and more personalized features, and cookies to analyze the traffic throughout our website.',
                fr: 'Nous utilisons des cookies nécessaires aux fonctions de bases de notre site web, des cookies qui permettent au site web de se souvenir de vos choix et vous offrent une meilleure fonctionnalité et des fonctionnalités plus personnalisés, et des cookies pour analyser le trafic sur notre site web.',
                nl: 'We gebruiken cookies die nodig zijn voor de basisfuncties van onze website, cookies die de website in staat stellen om uw keuzes te onthouden en u betere functionaliteit en meer gepersonnaliseerde functies te bieden, en cookies om het verkeer op onze website te analyseren.',
                pl: 'Serwis wykorzystuje pliki cookies m.in. w celu poprawienia jej dostępności, personalizacji, obsługi kont użytkowników czy aby zbierać dane, dotyczące ruchu na stronie.',
                it: 'Utilizziamo i cookies necessari alle funzinoi di base del nostro sito web, i cookies che permettono al sito web di ricordare le vs. schelte e offrirvi una migliore funzionalitá e funzionalitá piú personalizzate, e cookies per analizzare il traffico sul nostro sito web',
                es: 'Utilizamos las cookies necesarias para las funciones básicas de nuestro sitio web, las cookies que permiten al sitio web recordar sus elecciones y ofrecerle una mejor funcionalidad y características más personalizadas, y las cookies para analizar el tráfico en nuestro sitio web.',
            },
            mandatory: {
                en: 'Mandatory - cannot be deselected. Necessary cookies are required to enable basic functions of the website, e.g. page navigation and access to secure areas. The website cannot function properly without these cookies.',
                fr: "Obligatoire - ne peut pas être déselectionné. Les cookies nécessaires sont requis pour activer les fonctions de base du site web, par exemple la navigation dans les pages et l'accès aux zones sécurisées. Ce site web ne peut pas fonctionner correctement sans ces cookies.",
                nl: 'Verplicht - kan niet worden geselecteerd. Noodzakelijke cookies zijn nodig om de basisfuncties van de website mogelijk te maken, bijv. paginanavigatie en toegang tot beveiligde gebieden. Zonder deze cookies kan de website niet goed functioneren.',
                it: "Obbligatorio - non si puó deselezionare. I cookies necessari sono richiesti per attivare le funzioni di base del sito web, per esempio la navigazione tra le pagine e l'accesso ad aree sicure. Il sito web non puó funzionare correttamente senza questi cookies",
                es: 'Obligatorias: no se pueden deseleccionar. Las cookies necesarias son requeridas para permitir las funciones básicas del sitio web, por ejemplo, la navegación por las páginas y el acceso a las áreas seguras. El sitio web no puede funcionar correctamente sin estas cookies.',
            },
            necessary: {
                en: 'Necessary',
                fr: 'Nécessaire',
                nl: 'Noodzakelijk',
                pl: 'Konieczne',
                it: 'Necessario',
                es: 'Necesario',
            },
            findOutMore: {
                en: 'To find out more, read our privacy policy. You are free to manage this via your browser settings at any time.',
                fr: 'Pour en savoir plus, lisez notre poitique de confidentialité.Vous êtes libre de gérer cela à tout moment via les paramètres de votre navigateur.',
                nl: 'Lees voor meer informatie ons privacybeleid. U kunt dit te allen tijde via uw browserinstellingen beheren.',
                it: 'Per saperne di piú, legga la nostra informativa sulla privacy. Potrá gestire questo aspetto in ogni momento attraverso le impostazioni del suo browser.',
                es: 'Para saber más, lea nuestra política de privacidad. Puede gestionar sus preferencias en cualquier momento a través de la configuración de su navegador.',
            },
            preference: {
                en: 'Personal preferences',
                fr: 'Préférences personnelles',
                nl: 'Persoonlijke voorkeuren',
                pl: 'Ustawienia spersonalizowane',
                it: 'Preferenze personali',
                es: 'Preferencias personales',
            },
            statistics: {
                en: 'Statistics',
                fr: 'Statistiques',
                nl: 'Statistieken',
                pl: 'Statystyki',
                it: 'Statistiche',
                es: 'Estadísticas',
            },
            necessaryCookiies: {
                en: 'Use necessary cookies only',
                fr: 'Utilisé seulement les cookies nécessaires',
                nl: 'Gebruik alleen noodzakelijke cookies',
                pl: 'Użyj tylko konieczne pliki cookies',
                it: 'Utilizzare solo i cookies necessari',
                es: 'Utilizar sólo las cookies necesarias',
            },
            selectedCookies: {
                en: 'Accept selected cookies',
                fr: 'Accepter les cookies sélectionnés',
                nl: 'Accepteer geselecteerde cookies',
                pl: 'Zaakceptuj wybrane pliki cookies',
                it: 'Accettare i cookies selezionati',
                es: 'Aceptar las cookies seleccionadas',
            },
            allCookies: {
                en: 'Accept all cookies',
                fr: 'Accepter tous les cookies',
                nl: 'Accepteer alle cookies',
                pl: 'Zaakceptuj wszystkie pliki cookies',
                it: 'Accettare tutti i cookies',
                es: 'Aceptar todas las cookies',
            },
        }
    },
    eventPage: {
        registerForTheEvent: {
            en: 'Register for the event and receive my entrance badge',
            fr: "S'inscrire à l'évènement et recevoir mon badge d'entrée",
            nl: 'Schrijf u in voor het evenement en ontvang mijn toegangsbadge ',
            pl: 'Zarejestruj się na event i w celu pobrania wejściówki',
            it: "Iscriversi all'evento e ricevere il badge di ingresso",
            es: 'Inscribirme en el evento y recibir mi acreditación para entrar',
        },
        inviter: {
            yesComex: {
                en: 'Yes (Comex)',
                fr: 'Oui (Comex)',
                nl: 'Ja (Comex)',
                pl: 'Tak (poziom Comex)',
                it: 'Si (Comex)',
                es: 'Sí (Comex)',
            },
            yesMiddle: {
                en: 'Yes (middle)',
                fr: 'Oui (Middel)',
                nl: 'Ja (Middel)',
                pl: 'Tak (poziom wymagający akceptacji Comex)',
                it: 'Si (Medio)',
                es: 'Sí (Medio)',
            },
        },
        updateParticipation: {
            en: 'Update my participation',
            fr: 'Mettre à jour ma participation',
            nl: 'Update mijn deelname',
            pl: 'Modyfikuj uczestnictwo',
            it: 'Aggiorna la mia partecipazione',
            es: 'Actualizar mi inscripción',
        },
        eventRegister: {
            en: 'Confirm my event registration',
            fr: 'Confirmer mon inscription salon',
            nl: 'Bevestig mijn eventregistratie',
            pl: 'Potwierdzam swój udział',
            it: "Confermare la mia registrazione all'evento",
            es: 'Confirmar mi inscripción al evento',
        },
        eventRegisterEventOnly: {
            en: 'Confirm my registration',
            fr: 'Confirmer mon inscription',
        },
        modifyBadge: {
            en: 'Modify my badge',
            fr: 'Modifier mon badge',
            nl: 'Pas mijn badge aan',
            pl: 'Modyfikuj moją wejściówkę',
            it: 'Modificare il mio badge',
            es: 'Modificar mi acreditación',
        },
        enterUniqueCode: {
            en: 'Enter unique code',
            fr: 'Entrée mon code unique',
            nl: 'Voer een unieke code in ',
            pl: 'Wprowadź kod dostępu',
            it: 'Inserire il codice unico',
            es: 'Insertar el código único',
        },
        invitations: {
            en: 'Invitations',
            fr: 'Invitations',
            nl: 'Uitnodigingen ',
            pl: 'Zaproszenia',
            it: 'Invito',
            es: 'Invitaciones',
        },
        userCanInvite: {
            en: 'User can invite guests',
            fr: "L'utilisateur peut inviter des personnes",
            nl: 'Gebruiker kan gasten uitnodigen',
            pl: 'Użytkownik może zaprosić gości',
            it: "L'utente é abilitato ad inserire ospiti",
            es: 'El usuario puede invitar a personas',
        },
        sendConfEmail: {
            en: 'Send a confirmation email',
            fr: 'Envoyer un mail de confirmation',
            nl: 'Stuur een bevestigingsmail',
            pl: 'Wyślij Email z potwierdzeniem',
            it: 'Inviare una mail di conferma',
            es: 'Enviar confirmación por email',
        },
        // myGuests: {
        //     en: 'My guests',
        //     fr: 'Mes invités',
        //     nl: 'Mijn gasten',
        //     pl: 'Moi Goście',
        //     it: 'I miei ospiti',
        //     es: 'Mis invitados',
        // },
        // myParticipation: {
        //     en: 'My participation',
        //     fr: 'Ma participation',
        //     nl: 'Mijn deelname',
        //     pl: 'Moje uczestnictwo',
        //     it: 'La mia partecipazione',
        //     es: 'Mi participación',
        // },
        // invitedGuests: {
        //     en: 'Invited guests',
        //     fr: 'Invités',
        //     nl: 'Gasten',
        //     pl: 'Zaproszeni Goście',
        //     it: 'Ospiti invitati',
        //     es: 'Invitados',
        // },
        status: {
            status: {
                en: 'Status',
                fr: 'Statut',
                nl: 'Status',
                pl: 'Status',
                it: 'Stato',
                es: 'Status',
            },
            accepted: {
                en: 'Accepted',
                fr: 'Accepté',
                nl: 'Geaccepteerd',
                pl: 'Zaakceptowane',
                it: 'Accettato',
                es: 'Aceptado',
            },
            rejected: {
                en: 'Rejected',
                fr: 'Rejeté',
                nl: 'Afgewezen',
                pl: 'Odrzucony',
                it: 'Rifiutato',
                es: 'Rechazado',
            },
            pending: {
                en: 'Pending',
                fr: 'En attente',
                nl: 'In afwachting van',
                pl: 'Oczekujący',
                it: 'In attesa',
                es: 'Pendiente',
            },
            registered: {
                en: 'Registered',
                fr: 'Enregistré',
                nl: 'Geregistreerd',
                pl: 'Zarejestrowany',
                it: 'Registrato',
                es: 'Registrado',
            },
            noBadge: {
                en: 'No badge',
                fr: 'Pas de badge',
                nl: 'Geen badge',
                pl: 'Brak identyfikatora',
                it: 'No badge',
                es: 'Sin acreditación',
            },
            // validated: {
            //     en: 'Validated',
            //     fr: 'Validés',
            //     nl: 'Bevestigd',
            //     pl: 'Zaakceptowano',
            //     it: 'Validati',
            //     es: 'Validado',
            // },
        },
        newGuest: {
            en: 'New guest',
            fr: 'Nouvel invité',
            nl: 'Nieuwe gast',
            pl: 'Nowy gość',
            it: 'Nuovo ospite',
            es: 'Nuevo invitado',
        },
        sideEvents: {
            en: 'Side events',
            fr: 'Evènements parallèles',
            nl: 'Nevenevenementen',
            pl: 'Wydarzenia towarzyszące',
            it: 'Eventi paralleli',
            es: 'Eventos paralelos',
        },
        badgesRemaining: {
            en: 'badges remaining for the exhibitor',
            fr: "badges restants pour l'exposant",
            nl: 'badges beschikbaar voor de exposant',
            pl: 'wejściówki jeszcze dostępne dla wystawcy',
            it: "badges ancora disponibili per l'espositore",
            es: 'acreditaciones restantes para el expositor',
        },
        badgesAdditionalCharges: {
            en: "Exhibitor doesn't have the required amount of badges. Additional charge will be necessary.",
            fr: "L'exposant n'a pas le nombre de badges requis. Des frais supplémentaires seront nécessaires.",
            nl: ' Exposant beschikt niet over het vereiste aantal badges. Extra kosten zullen noodzakelijk zijn.',
            pl: 'Wystawca nie posiada wymaganej ilości identyfikatorów. Niezbędna będzie dodatkowa opłata.',
            it: "L'espositore non ha il numero di badges richiesto. Sará necessario un costo extra.",
            es: 'El expositor no dispone de la cantidad necesaria de acreditaciones. Será necesario un cargo adicional.',
        },
        name: {
            en: 'Name',
            fr: 'Prénom & Nom',
            nl: 'Voornaam en achternaam',
            pl: 'Imię i nazwisko',
            it: 'Nome e cognome',
        },
        operationalManagement: {
            label: {
                en: 'Operational management',
                fr: 'Direction opérationnelle',
            },
            // Massy is a city
            massy: {
                en: 'Massy Headquarters',
                fr: 'Siège Massy',
            },
            east: {
                en: 'East Headquarters',
                fr: 'Siège Est',
            },
            west: {
                en: 'West Headquarters',
                fr: 'Siège Ouest',
            },
            south: {
                en: 'South Headquarters',
                fr: 'Siège Sud',
            },
        },
    },
    authentication: {
        signIn: {
            en: 'Sign In',
            fr: "S'enregistrer",
            nl: 'Log in',
            pl: 'Zaloguj się',
            it: 'Registrazione',
            es: 'Iniciar sesión',
        },
        phonePlaceholder: {
            en: 'Phone Number',
            fr: 'Numéro de portable',
            nl: 'GSM-nummer',
            pl: 'Numer tel. komórkowego',
            it: 'Numero GSM',
            es: 'Número de teléfono',
        },
        loginSwitchToPhone: {
            en: 'Use my mobile phone number',
            fr: 'Utiliser mon numéro de téléphone mobile',
            nl: 'Gebruik mijn GSM nummer',
            pl: 'Użyj mojego numeru komórkowego',
            it: 'Utilizzare il mio numero di telefono',
            es: 'Utilizar mi teléfono móvil',
        },
        loginSwitchToEmail: {
            en: 'Use my email',
            fr: 'Utiliser mon e-mail',
            nl: 'Gebruik mijn e-mail',
            pl: 'Użyj mojego adresu Email.',
            it: 'Utilizzare la mia email',
            es: 'Utilizar mi email',
        },
        loginSwitchToRegID1: {
            en: 'Already registered?',
            fr: 'Déjà enregistré ?',
            nl: 'Bent u al geregistreerd?',
            pl: 'Jesteś już zarejestrowany?',
            it: 'Sei giá registrato?',
            es: '¿Ya se ha inscrito?',
        },
        privacy: {
            en: 'Privacy protection',
            fr: 'Protection de la vie privée',
            nl: 'Bescherming van de privacy',
            pl: 'Ochrona danych osobowych',
            es: 'Protección de privacidad',
        },
        loginSwitchToRegID2: {
            en: 'Modify my participation',
            fr: 'Modifier mon inscription',
            nl: 'Wijzig mijn deelname',
            pl: 'Modyfikuj swój udział',
            it: 'Modicare la mia registrazione',
            es: 'Modificar mi participación',
        },
        sending: {
            en: 'Sending...',
            fr: 'Envoi...',
            nl: 'Aan het verzenden...',
            pl: 'Wysyłamy...',
            it: 'Sta inviando...',
            es: 'Enviando...',
        },
        sent:  {
            en: 'Sent',
            fr: 'Envoyé',
            nl: 'Verzonden',
            pl: 'Wysłany',
            it: 'Inviato',
            es: 'Enviado',
        },
        otpCode: {
            en: 'We sent the code by SMS to your phone',
            fr: 'Nous avons envoyé votre code par SMS sur votre téléphone mobile',
            nl: 'We hebben de code per SMS naar uw GSM gestuurd',
            pl: 'Wysłano SMS na numer komórkowy z kodem dostępu',
            it: 'Le abbiamo inviato il codice per SMS sul suo telefono cellulare',
            es: 'Le hemos enviado un código por SMS a su teléfono móvil',
        },
        createAccount: {
            en: "Don't have an account?",
            fr: "Vous n'avez pas de compte ?",
            nl: 'Heb je geen account?',
            pl: 'Nie masz jeszcze konta?',
            it: 'Non ha un account?',
            es: '¿No tiene una cuenta?',
        },
        smsCodeVerification: {
            en: 'Enter OTP code',
            fr: 'Entrez le code reçu par SMS',
            nl: 'Voer de OTP-code in',
            pl: 'Wprowadź OTP kod',
            it: 'Inserisca il codice OPT',
            es: 'Introduzca el código recibido en su móvil',
        },
        emailCodeVerification: {
            en: 'Enter code from the email',
            fr: "Entrez le code de l'e-mail",
            nl: 'Voer de code uit de e-mail in',
            pl: 'Wprowadź kod z wiadomosci e-mail',
            it: 'Inserisca il codice della mail',
            es: 'Introduzca el código recibido por correo electrónico',
        },
        register: {
            en: 'Register',
            fr: "S'enregistrer",
            nl: 'Registreren',
            pl: 'Zarejestruj się',
            it: 'Si registri',
            es: 'Crear cuenta',
        },
        resend: {
            en: 'Re-send',
            fr: 'Renvoyer',
            nl: 'Herverzenden',
            pl: 'Wyślij ponownie',
            it: 'Inviare di nuovo',
            es: 'Reenviar',
        },
        retry: {
            en: 'Retry',
        },
        failedToSend: {
            en: 'Failed to send',
            fr: "Echec d'envoi",
            nl: 'Niet verzonden',
            pl: 'Błąd wysyłki',
            it: 'Invio fallito',
            es: 'Error en el envío',
        },
        // badCode: {
        //     en: 'Bad code',
        //     fr: "Mauvais code",
        //     nl: 'Slechte code',
        //     pl: 'Błędny kod',
        //     it: 'Codice a barre',
        //     es: 'Código de barras',
        // },
        invalid: {
            en: 'Invalid account',
            fr: 'Compte invalide',
            nl: 'Ongeldig account',
            pl: 'Błędne konto',
            it: 'Account non valido',
            es: 'Cuenta no válida',
        },
        notValidated: {
            en: 'Your request for account creation has not been approved yet',
            fr: "Votre demande de création de compte n'a pas encore été approuvée",
            nl: 'Uw aanvraag voor het maken van een account is nog niet goedgekeurd',
            pl: 'Twoje zapytanie o utworzenie konta jeszcze nie zostało zaakceptowane',
            it: 'La sua richiesta di creazione account non é stata ancora approvata',
            es: 'Su solicitud de creación de cuenta aún no ha sido aprobada',
        },
        stillReviewingRequest: {
            en: 'We are still reviewing your request for account creation.',
            fr: 'Nous examinons toujours votre demande de création de compte.',
            nl: 'Wij zijn nog bezig met het bekijken van uw aanvraag voor het aanmaken van een account.',
            pl: 'Wciąż analizujemy zapytanie o utworzenie konta.',
            it: 'Stiamo ancora esaminando la sua domanda di creazione del profilo.',
        },
        thisCanTake: {
            en: 'This can take up to 48 hours.',
            fr: "Cela peut prendre jusqu'à 48 heures.",
            nl: 'Dit kan tot 48 uur duren.',
            pl: 'Może to potrwać nie więcej niż 48h.',
            it: 'Questo potrebbe richiedere fino a 48 ore.',
        },
        emailWhenApproved: {
            en: "You will receive an email when it's approved.",
            fr: "Vous recevrez un e-mail lorsqu'il sera approuvé.",
            nl: 'U ontvangt een e-mail wanneer het is goedgekeurd.',
            pl: 'Otrzymasz wiadomość e-mail kiedy zostanie zaakceptowany.',
            it: 'Riceverà una email quando è stato approvato.',
        },
        awaitingConfirmation: {
            en: 'Awaiting confirmation',
            fr: 'En attente de confirmation',
            nl: 'In afwachting van bevestiging',
            pl: 'Oczekiwanie na akceptację',
            it: 'Attendere la conferma',
            es: 'Esperando confirmación',
        },
        awaitingConfirmationSub: {
            en: 'We sent an email to',
            fr: 'Nous avons envoyé un mail à',
            nl: 'We hebben een e-mail gestuurd naar',
            pl: 'Wysłano Email na',
            it: 'Abbiamo inviato una mail á',
            // es: 'Hemos enviado un email a',
            es: 'Hemos enviado un correo electrónico a',
        },
        selectAccount: {
            en: 'Please select your account',
            fr: 'Veuillez sélectionner votre compte.',
            nl: 'Selecteer uw account ',
            pl: 'Proszę wybierz swój profil konta.',
            it: 'Selezioni il suo account',
            es: 'Por favor, seleccione su cuenta',
        },
        dontSeeMyName: {
            en: "I don't see my name",
            fr: 'Je ne vois pas mon nom.',
            nl: 'Ik zie mijn naam niet ',
            pl: 'Nie widzę swojego nazwiska',
            it: 'Non vedo il mio nome',
            es: 'No veo mi nombre',
        },
        staySafe: {
            en: 'See "Stay Safe" measures applied',
            fr: 'Voir les mesures "Stay Safe"',
            nl: "Zie de toegepaste 'Stay Safe'-maatregelen",
            pl: 'Zobacz Zasady Bezpieczeństwa',
            it: 'Guarda le misure "Stay Safe" adottate',
            es: 'Vea las medidas "Stay Safe"',
        },
        invalidOtp: {
            en: 'Invalid OTP inserted.',
            fr: 'Code OTP invalide.',
            nl: 'Ongeldige OTP ingebracht.',
            pl: 'Błędny OTP kod.',
            it: 'Codice OTP non valido.',
            es: 'Código OTP inválido.',
        },
        waitForXAmount: (time) => ({
            en: `Please wait for ${time} before making the next login attempt.`,
            fr: `Veuillez attendre ${time} avant une nouvelle tentative.`,
            nl: `Wacht aub enkele ${time} voordat u de volgende inlogpoging doet.`,
            pl: `Poczekaj ${time} zanim rozpoczniesz ponowną próbę logowania.`,
            it: `Attenda ${time} prima di fare un nuovo tentativo.`,
            es: `Por favor, espere ${time} antes de hacer un nuevo intento.`,
        }),
        validOtp: {
            en: 'Please insert a valid OTP code.',
            fr: 'Veuillez insérer un code OTP valide.',
            nl: 'Voer een geldige OTP-code in.',
            pl: 'Proszę wprowadź aktywny OTP kod.',
            it: 'Inserisca un codice OTP valido.',
            es: 'Introduzca un código OTP válido.',
        }
    },
    welcomePage: {
        title: {
            en: 'The original platform that connects brands to stores.',
            fr: 'La plateforme originale qui connecte les marques aux points de vente.',
            nl: 'Het originele platform die de merken van de winkels verbindt.',
            it: "L'originale piattaforma che mette in contatto i brands con i punti vendita.",
            pl: 'Oryginalna platforma, która łączy marki ze sklepami.',
            es: 'La plataforma que conecta las marcas con las tiendas.',
        },
        intro: {
            en: 'Carrefour fairs are yearly commercial and enthusiastic events dedicated to store collaborators, headquarters collaborators and suppliers. These exhibitions aim at stimulating the seasonal sales, promoting innovation and best practices, sharing products and trends.<br/><br/>These fairs are taking place in Belgium, Poland and Italy...',
            fr: "Les salons Carrefour sont des évènements annuels commerciaux et enthousiastes dédiés aux collaborateurs des magasins, aux collaborateurs du siège et aux fournisseurs. Ces foires visent à stimuler les ventes saisonnières, à promouvoir l'innovation et les meilleures pratiques, à partager les produits et les tendances.<br/><br/> Ces salons se déroulent en Belgique, en Pologne et en Italie...",
            nl: 'De Carrefour-beurzen zijn jaarlijkse commerciële en enthousiaste evenementen gewijd aan winkelmedewerkers, medewerkers van het hoofdkantoor en leveranciers met als doel de seizoensgebonden verkoop te stimuleren, innovatie en best practices te promoten en producten en trends te delen.<br/><br/>Deze beurzen vinden plaats in België, Polen en Italië...',
            pl: 'Targi Carrefour to coroczna impreza komercyjna dedykowana pracownikom sklepów, współpracownikom centrali i dostawców. Wydarzenie ma na celu stymulowanie sezonowej sprzedaży, promowanie innowacji i najlepszych praktyk, dzielenie się produktami i trendami.<br/><br/>Targi te odbywają się w Belgii, Polsce i we Włoszech ...',
            it: "I Saloni Carrefour sono eventi annuali di natura commerciale e di grande entusiamo dedicati ai collaboratori dei punti vendita, alle persone di Sede e ai fornitori. Questi Saloni hanno lo scopo di stimolare le vendite stagionali, di promuovere l'innovazione e le migliori azioni commerciali, di condividere prodotti e tendenze.<br/><br/>Questi Saloni si tengono in Italia, Belgio e Polonia...",
            es: 'El Salón Carrefour es un evento anual de carácter comercial y distendido dedicado a los proveedores, las tiendas y  la sede central de Carrefour. Con el objetivo de estimular las ventas, promover la innovación y la excelencia comercial, y compartir productos y tendencias.<br/><br/>Esta feria tiene lugar en Bélgica, Polonia, Francia, Italia y España',
        },
        enterPhone: {
            en: 'Enter your mobile phone number',
            fr: 'Entrez votre numéro de portable',
            nl: 'Vul je GSM nummer in ',
            pl: 'Wprowadź numer tel. komórkowego',
            it: 'Inserisci il tuo numero di telefono',
            es: 'Introduzca su número de teléfono móvil',
        },
        cantFindPhone: {
            en: "We can't find your phone number in our system.",
            fr: 'Nous ne trouvons pas votre N° de téléphone dans nos systèmes.',
            nl: 'We hebben jouw GSM nummer niet in ons systeem gevonden.',
            pl: 'Nie możemy znaleźć tego numeru w systemie.',
            it: 'Il tuo numero non é stato trovato nel nostro sistema.',
            es: 'No podemos encontrar su número de teléfono en nuestro sistema.',
        },
        createAcc: {
            en: 'Please fill in the fields below to create your account.',
            fr: 'Veuillez compléter les champs ci-dessous pour créer votre compte.',
            nl: 'Vul het formulier in om een account te creëren.',
            pl: 'Wypełnij poniższy formularz aby stworzyć profil konta.',
            it: 'Per favore compila i campi in basso per creare il tuo account.',
            es: 'Rellene los campos siguientes para crear su cuenta.',
        },
        enterEmail: {
            en: 'Enter your email',
            fr: 'Encoder votre e-mail',
            nl: 'Vul je email adres in ',
            pl: 'Wprowadź Email',
            it: 'Inserisci la tua email',
            es: 'Introduzca su email',
        },
        cantFindEmail: {
            en: "We can't find your email in our system.",
            fr: 'Nous ne trouvons pas votre e-mail dans notre système.',
            nl: 'We kunnen jouw e-mailadres niet in ons systeem terugvinden.',
            pl: 'Nie możemy odnaleźć tego emaila w systemie.',
            it: 'Non troviamo il suo indirizzo mail nel nostro sistema.',
            es: 'No podemos encontrar su email de teléfono en nuestro sistema.',
        },
    },
    welcomePageEvent: {
        createAccEvent: {
            en: 'Complete the form below to create your account.',
            fr: 'Remplissez le formulaire ci-dessous pour créer votre compte.',
            nl: 'Vul het onderstaande formulier in om uw account aan te maken.',
            pl: 'Proszę wypełnij dane nowego profilu.',
            it: 'Completi il form in basso per creare il suo account.',
            es: 'Rellene el siguiente formulario para crear su cuenta.',
        },
        regIdNotFound: {
            en: "We can't find your registration ID in our system.",
            fr: 'Nous ne trouvons pas votre profil dans nos systèmes',
            nl: 'We kunnen uw registratie-ID niet vinden in ons systeem.',
            pl: 'Nie możemy znaleźć Twojego ID w systemie.',
            it: 'Non troviamo il suo profilo nel nostro sistema.',
            es: 'No podemos encontrar su perfil en nuestro sistema.',
        },
    },
    registration: {
        // title: {
        //     en: "Start Let's connect now",
        //     fr: "Commencez l'expérience Let's connect maintenant",
        //     nl: "Start Let's connect nu",
        //     pl: "Rozpocznij doświadczenie z Let's connect",
        //     it: "Attivi Let's connect ora",
        // },
        // intro: {
        //     en: 'Register on our platform to participate to the next fair. You can order your stand, communicate with organization team, manage your products and more.',
        //     fr: "Inscrivez-vous sur notre plateforme pour accéder aux étapes d'inscription, commander votre stand, communiquer avec l'organisation, gérer vos produits et plus encore.",
        //     nl: 'Schrijf je in op ons platform om deel te nemen aan de volgende beurs. Je kan jouw stand bestellen, communiceren met de organisatie, uw producten beheren en meer. ',
        //     pl: 'Zarejestruj się na naszej platformie, aby wziąć udział w kolejnych targach. Możesz zamówić swoje stoisko, komunikować się z zespołem organizacyjnym, zarządzać swoimi produktami i nie tylko.',
        //     it: "Si registri sulla nostra piattaforma per partecipare al prossimo Salone. Potrà riservare il suo stand, comunicare con il team dell'organizzazione, gestire la partecipazione e altro ancora.",
        // },
        // startButton: {
        //     en: 'Account creation',
        //     fr: "Demandez votre compte",
        //     nl: 'Vraag jouw account aan',
        //     pl: 'Załóż konto',
        //     it: 'Richieda il suo account',
        // },
        registration: {
            en: 'Registration',
            fr: 'Inscription',
            nl: 'Registratie',
            pl: 'Rejestracja',
            it: 'Iscrizione',
            es: 'Registro',
        },
        mobileDisclaimer: {
            en: "Mobile phone number is needed to access our event mobile app, and it provides a simpler way to sign in to our web platform Let's Connect.",
            fr: "Le téléphone portable est requis pour accéder à l'application mobile de l'évènement. Un moyen simplifié de se connecter à notre plateforme  Let's Connect.",
            nl: "GSM nummer is nodig om toegang te krijgen tot onze mobiele app van het evenement en het biedt een eenvoudige manier om in te loggen op ons webplatform Let's Connect.",
            pl: "Numer telefonu komórkowego jest potrzebny, aby uzyskać dostęp do aplikacji mobilnej. Pozwoli to również na szybsze logowanie się do platformy Let's Connect.",
            it: "Per accedere all'applicazione mobile dell'evento viene chiesto di inserire il numero di telefono. Un modo semplificato di connettersi alla nostra piattaforma Let's Connect.",
            es: "El número de teléfono móvil es necesario para acceder a nuestra aplicación móvil para eventos, y proporciona una forma más sencilla de iniciar sesión en nuestra plataforma web Let's Connect.",
        },
        accountSelection: {
            registerNew: {
                en: 'Register a new person',
                fr: 'Inscrire une nouvelle personne',
                nl: 'Registreer een nieuwe persoon',
                pl: 'Zarejestruj nową osobę',
                it: 'Registrare una nuova persona',
                es: 'Registrar una nueva persona',
            },
            pleaseClickToRegisterNew: {
                en: 'Please click on the name to update the registration, or click on "Register a new person" to add a new person.',
                fr: `Veuillez cliquer sur le nom pour mettre à jour l'inscription, ou cliquer sur "Enregistrer une nouvelle personne" pour ajouter une nouvelle personne.`,
                nl: 'Klik op de naam om de registratie bij te werken, of klik op "Registreer een nieuwe persoon" om een nieuwe persoon toe te voegen.',
                pl: 'Kliknij na imię i nazwisko aby zaktualizować swój udział lub kliknij "Zarejestruj nową osobę" aby dodać nową osobę.',
                it: 'Cliccare sul nome per aggiornare la registrazione, oppure cliccare su "registra una nuova persona" per aggiungere una nuova persona.',
                es: 'Por favor, haga clic en el nombre para actualizar el registro, o haga clic en "Registrar una nueva persona" para añadir una nueva persona.',
            },
            enclosedPhone: {
                en: 'Enclosed the people sharing the same phone.',
                fr: 'Attaché les personnes qui partagent le même numéro de téléphone.',
                nl: 'Bijgesloten de mensen die hetzelfde telefoonnummer delen.',
                pl: 'Poniżej osoby zarejestrowane na ten sam numer telefonu komórkowego.',
                it: 'Elancati in basso i nomi delle persone che condividono lo stesso numero di telefono.',
                es: 'A continuación se enumeran los nombres de las personas que comparten el mismo número de teléfono',
            },
            enclosedEmail: {
                en: 'Enclosed the people sharing the same email.',
                fr: 'Attaché les personnes qui partagent le même mail.',
                nl: 'Bijgesloten de mensen die dezelfde mail delen.',
                pl: 'Poniżej osoby zarejestrowane na ten sam adres e-mail.',
                it: 'Elancati in basso i nomi delle persone che condividono lo stesso email.',
                es: 'A continuación se enumeran los nombres de las personas que comparten el mismo email',
            },
            guestsOf: {
                en: 'Guests of',
                fr: 'Invités de',
                nl: 'Gasten van',
                pl: 'Gość',
                it: 'Invitati di',
                es: 'Invitado de',
            },
            inviteNewGuest: {
                en: 'Invite a new guest',
                fr: 'Ajouter un nouvel invité',
                nl: 'Nodig een nieuwe gast uit',
                pl: 'Zaproś nową osobę',
                it: 'Invita un nuovo ospite',
                es: 'Añadir un nuevo invitado',
            },
        },
        form: {
            firstname: {
                en: 'First Name',
                fr: 'Prénom',
                nl: 'Voornaam',
                pl: 'Imię',
                it: 'Nome',
                es: 'Nombre',
            },
            lastname: {
                en: 'Last Name',
                fr: 'Nom',
                nl: 'Achternaam',
                pl: 'Nazwisko',
                it: 'Cognome',
                es: 'Apellidos',
            },
            companyNameOrVAT: {
                en: 'Enter company name or VAT',
                fr: "Entrez le nom de l'entreprise ou la TVA",
                nl: 'Voer de bedrijfsnaam of btw in',
                pl: 'Nazwa firmy lub NIP',
                it: "Nome dell'azienda o partita IVA",
                es: 'Nombre de la empresa o CIF',
            },
            companyName: {
                en: 'Enter company name',
                fr: "Entrez le nom de l'entreprise",
                nl: 'Voer de bedrijfsnaam',
                pl: 'Nazwa firmy',
                it: "Nome dell'azienda",
                es: 'Nombre de la empresa',
            },
            invalidVat: {
                en: 'Invalid VAT',
                fr: 'N° de TVA non valide',
                nl: 'BTW NR niet geldig',
                pl: 'Błędny numer NIP',
                it: 'Partita Iva non valida',
                es: 'CIF inválido',
            },
            phone: {
                en: 'Mobile phone',
                fr: 'N° de portable',
                nl: 'GSM-nummer',
                pl: 'Tel. komórkowy',
                it: 'Numero cellulare',
                es: 'Teléfono móvil',
            },
            selectProfile: {
                en: 'Select Profile',
                fr: 'Sélectionner le profil',
                nl: 'Selecteer het profiel',
                pl: 'Wybierz profil',
                it: 'Selezioni il profilo',
                es: 'Seleccione Perfil',
            },
            noCompany: {
                en: 'There is no matching company in our system, please enter your VAT',
                fr: "Il n'y a pas d'entreprise correspondante dans notre système, veuillez inscrire votre numéro de TVA",
                nl: 'Er is geen overeenkomend bedrijf in ons systeem, gelieve het BTW nummer in te voeren',
                pl: 'Nie ma takiej firmy w naszym systemie, podaj swój numer NIP',
                it: "Non c'è nessuna azienda corrispondente nel nostro sistema, inserisca la sua partita IVA",
                es: 'No hay ninguna empresa que coincida en nuestro sistema, por favor, introduzca su CIF',
            },
            noCompanyES: {
                en: 'There is no matching company in our system, please try again or contact',
                es: 'No hay ninguna empresa que coincida en nuestro sistema, por favor inténtelo de nuevo o contacte con',
            },
            selectCompany: {
                en: 'Please select your company',
                fr: 'Veuillez sélectionner votre entreprise',
                pl: 'Wybierz swoją firmę',
                it: 'Per favore selezioni la sua azienda',
                es: 'Seleccione su empresa',
            },
            storeType: {
                en: 'Store type',
                fr: 'Type de Magasin',
                nl: 'Type Winkel',
                pl: 'Format sklepu',
                it: 'Tipo di punto vendita',
                es: 'Tipo de Tienda',
            },
            storeId: {
                en: 'Store ID',
                fr: 'ID du Magasin - Num SAP',
                nl: 'Winkel ID - Num SAP',
                pl: 'ID Sklepu',
                it: 'ID punto vendita',
                enFR: 'Code Prolog',
                frFR: 'Code Prolog',
                es: 'Número de tienda',
            },
            enterStoreID: {
                en: 'Enter store ID',
                fr: "Entrez l'identifiant du magasin",
                nl: 'Voer winkel ID in',
                pl: 'Wpisz ID sklepu',
                it: 'Inserire il codice identificativo del negozio',
                enFR: 'Enter Prolog',
                frFR: 'Entrez Prolog',
                es: 'Introduzca el ID de la tienda',
            },
            selectStoreType: {
                en: 'Select Store type',
                fr: 'Sélectionner le profil magasin',
                nl: 'Selecteer winkelprofiel',
                pl: 'Wybierz profil sklepu',
                it: 'Selezionare il tipo di punto vendita',
                es: 'Seleccione el tipo de tienda',
            },
            addStore: {
                en: 'Add another store',
                fr: 'Ajouter un magasin supplémentaire',
                nl: 'Voeg nog een winkel-ID toe',
                pl: 'Dodaj kolejne ID sklepu',
                it: 'Aggiungere un altro codice punto vendita',
                es: 'Añada otra Tienda',
            },
            // profiles: {
            //     exhibitor: {
            //         administratorAccount: {
            //             en: 'Exhibitor Administrator Account',
            //             fr: 'Exposant - Administateur du compte',
            //             nl: 'Exposant - beheerdersaccount',
            //             pl: 'Administrator - Wystawca',
            //             it: "Espositore Amministratore dell'account",
            //         },
            //         finance: {
            //             en: 'Exhibitor Finance',
            //             fr: 'Exposant - Service Comptable',
            //             nl: 'Exposant - boekhouding',
            //             pl: 'Wystawca - księgowość',
            //             it: 'Espositore - settore amministrazione',
            //         },
            //         installator: {
            //             en: 'Exhibitor Installator',
            //             fr: 'Exposant - Constructeur de stand',
            //             nl: 'Exposant - standbouwer',
            //             pl: 'Wykonawca stoiska - Agencja',
            //             it: 'Espositre - agenzia allestitrice',
            //         },
            //         representative: {
            //             en: 'Exhibitor Representative',
            //             fr: 'Exposant - Représentant commercial',
            //             nl: 'Exposant - vertegenwoordiger',
            //             pl: 'Wystawca - handlowiec/sprzedaż',
            //             it: 'Espositore - Profilo Direttivo / Commerciale',
            //         },
            //     },
            // },
            primarySector: {
                en: 'Primary Sector',
                fr: 'Secteur primaire',
                nl: 'Primaire sector ',
                pl: 'Sektor główny',
                it: 'Settore Principale',
                es: 'Sector Principal',
            },
            secondarySector: {
                en: 'Secondary Sector (optional)',
                fr: 'Secteur secondaire (optionnel)',
                nl: 'Secondaire sector (optioneel)',
                pl: 'Sektor dodatkowy (opcjonalnie)',
                it: 'Settore secondario (opzionale)',
                es: 'Sector Secundario (opcional)',
            },
            invitedBy: {
                en: 'Invited by',
                fr: 'Invité par',
                nl: 'Uitgenodigd door ',
                pl: 'Zaproszono przez',
                it: 'Invitato da',
                es: 'Invitado por',
            },
            send: {
                en: 'Send Request',
                fr: 'Envoyer requête',
                nl: 'Aanvraag verzenden',
                pl: 'Wyślij zapytanie',
                it: 'Inviare la richiesta',
                es: 'Enviar solicitud',
            },
            alreadyHavAcc: {
                en: 'Already have an account?',
                fr: 'Avez-vous déjà un compte ?',
                nl: 'Al een account?',
                pl: 'Masz już swoje konto?',
                it: 'Hai giá un account?',
                es: '¿Ya tiene una cuenta?',
            },
            alreadyHavAccSignIn: {
                en: 'Sign in',
                fr: "S'identifier",
                nl: 'Log in',
                pl: 'Zaloguj się',
                it: 'Registrati',
                es: 'Inicie sesión',
            },
            thanks: {
                en: "Thank you!<br/><br/>We will review your request for account creation, this can take up to 48 hours.<br/><br/>You will receive an email when it's approved.",
                fr: "Merci !<br/><br/>Nous examinerons votre demande de création de compte, cela peut prendre jusqu'à 48 heures.<br/><br/>Vous recevrez un e-mail lorsqu'il sera approuvé.",
                nl: 'Dank U !<br/><br/>Wij zullen uw aanvraag voor het aanmaken van een account bekijken, dit kan tot 48 uur duren.<br/><br/>U ontvangt een e-mail wanneer het is goedgekeurd.',
                pl: 'Dziękujemy!<br/><br/>Analizujemy zapytanie o utworzenie konta. Może to potrwać do 48h.<br/><br/>Otrzymasz wiadomość e-mail kiedy zostanie zaakceptowany.',
                it: 'Grazie!<br/><br/>La valutazione della sua domanda di creazione del profilo potrebbe richiedere fino a 48 ore.<br/><br/>Riceverà una email quando è stato approvato.',
                es: '¡Gracias!<br/><br/>Revisaremos su solicitud de creación de cuenta, puede tardar hasta 48 horas.<br/><br/>Recibirá un correo electrónico cuando esté aprobada.',
            },
            thanksV2: {
                en: 'Thank you!<br/><br/>Your account has been created successfully.<br/><br/>You can now sign in.',
                fr: 'Merci !<br/><br/>Votre compte a été créé.<br/><br/>Vous pouvez désormais vous connecter.',
                nl: 'Dank U !<br/><br/>Uw account is aangemaakt.<br/><br/>U kunt nu inloggen.',
                pl: 'Dziękujemy!<br/><br/>Twoje konto zostano utworzone.<br/><br/>Możesz się już zalogować.',
                it: 'Grazie!<br/><br/>Il suo profilo è stato creato con successo.<br/><br/>Ora è possibile accedere.',
                es: '¡Gracias!<br/><br/>Su cuenta ha sido creada con éxito.<br/><br/>Ya puede iniciar sesión.',
            },
            tosNotif: {
                en: 'Please read and accept our Terms of service',
                fr: 'Veuillez lire et accepter nos conditions de service',
                nl: 'Lees en accepteer onze Algemene Voorwaarden',
                pl: 'Proszę przeczytaj i zaakceptuj Warunki usługi',
                it: 'Per favore leggi ed accetta i nostri termini di servizio',
                es: 'Por favor, lea y acepte las Condiciones del Servicio',
            },
            tos: {
                en: "I have read and agree to the <a href='/tos/en' target='_blank'>Terms of service</a>",
                enES: "I have read and agree to the <a href='/tos/en-ES' target='_blank'>Terms of service</a>",
                fr: "J'ai lu et j'accepte les <a href='/tos/fr' target='_blank'>Conditions d'utilisation</a>",
                nl: "Ik heb de <a href='/tos/nl' target='_blank'>Servicevoorwaarden</a> gelezen en ga ermee akkoord",
                pl: "Znam i akceptuję <a href='/tos/pl' target='_blank'>Warunki usługi</a>",
                it: "Ho letto ed accettato le <a href='/tos/it' target='_blank'>Condizioni di utilizzo</a>",
                es: "He leído y acepto las <a href='/tos/es' target='_blank'>Condiciones del Servicio</a>",
            },
            tos_label: {
                en: 'I have read and agree on:',
                fr: "J'ai lu et j'accepte :",
                nl: 'Ik heb gelezen en ga akkoord met:',
                pl: 'Zapoznałem się i zgadzam się na:',
                it: 'Ho letto e accetto:',
                es: 'He leído y estoy de acuerdo:',
            },
            tos_link: {
                en: "<a href='/tos/en' target='_blank'>Terms of service</a>",
                enES: "<a href='/tos/en-ES' target='_blank'>Terms of service</a>",
                fr: "<a href='/tos/fr' target='_blank'>Conditions d'utilisation</a>",
                nl: "<a href='/tos/nl' target='_blank'>Servicevoorwaarden</a>",
                pl: "<a href='/tos/pl' target='_blank'>Warunki usługi</a>",
                it: "<a href='/tos/it' target='_blank'>Condizioni di utilizzo</a>",
                es: "<a href='/tos/es' target='_blank'>Condiciones del Servicio</a>",
            },
            personalData: {
                en: "<a href='/personal-data/en' target='_blank'>Processing of personal data</a>",
                enES: "<a href='/personal-data/en-ES' target='_blank'>Processing of personal data</a>",
                fr: "<a href='/personal-data/fr' target='_blank'>Traitement des données personnelles</a>",
                nl: "<a href='/personal-data/nl' target='_blank'>Behandeling van persoonlijke gegevens</a>",
                pl: "<a href='/personal-data/pl' target='_blank'>Przetwarzanie danych osobowych</a>",
                it: "<a href='/personal-data/it' target='_blank'>Elaborazione dei dati personali</a>",
                es: "<a href='/personal-data/es' target='_blank'>Tratamiento de datos personales</a>",
            },
            tos_pp_platform: {
                en: "Let's Connect platform",
                fr: "La plateforme Let's Connect",
                nl: "Platform Let's Connect",
                pl: "Platforma Let's Connect",
                it: "Piattaforma Let's Connect",
                es: "Plataforma Let's Connect",
            },
            tos_pp_info: {
                en: 'You will be able to access fair content, agenda, news, and receive help from our support team on the platform.',
                fr: "Vous pourrez accéder au contenu du salon, à l'agenda, aux actualités et recevoir l'aide de notre équipe d'assistance sur la plateforme.",
                nl: 'U krijgt toegang tot de inhoud van de beurs, agenda, nieuws en hulp van ons ondersteuningsteam op het platform.',
                pl: 'Po zalogowaniu otrzymasz dostęp do informacji programowych, targowych, aktualności oraz uzyskasz pomoc z naszego działu obsługi użytkownika.',
                it: "Puó accedere ai contenuti del Salone, all'agenda, alle news  e ricevere assistenza on line dal nostro team.",
                es: 'Podrá acceder a los contenidos de la feria, a la agenda, a las noticias y recibir ayuda de nuestro equipo de apoyo en la plataforma.',
            },
            tos_pp: {
                en: 'I have read and agree to the <a href="/tos/en" target="_blank">Terms of service</a> and <a href="/personal-data/en" target="_blank">Processing of personal data</a>',
                enES: 'I have read and agree to the <a href="/tos/en-ES" target="_blank">Terms of service</a> and <a href="/personal-data/en-ES" target="_blank">Processing of personal data</a>',
                fr: `J'ai lu et j'accepte les <a href="/tos/fr" target="_blank">conditions d'utilisation</a> et le <a href="/personal-data/fr" target="_blank">traitement des données personnelles</a>`,
                nl: 'Ik heb de <a href="/tos/nl" target="_blank">servicevoorwaarden</a> en de <a href="/personal-data/nl" target="_blank">verwerking van persoonsgegevens</a> gelezen en ga ermee akkoord',
                pl: 'Przeczytałem/am i akceptuję <a href="/tos/pl" target="_blank">warunki usługi</a> oraz <a href="/personal-data/pl" target="_blank">przetwarzania danych osobowych</a>',
                it: 'Ho letto ed accetto i <a href="/tos/it" target="_blank">Termini del servizio</a> e del <a href="/personal-data/it" target="_blank">trattamento dei dati personali</a>',
                es: 'He leído y acepto las <a href="/tos/es" target="_blank">condiciones de servicio</a> y el <a href="/personal-data/es" target="_blank">tratamiento de datos personales</a>',
            },
            errors: {
                countryRequired: {
                    en: 'Please select country',
                    fr: 'Veuillez sélectionner le pays',
                    nl: 'Selecteer een land',
                    pl: 'Wybierz kraj',
                    it: 'Selezionare il Paese',
                    es: 'Por favor sellecione el paía',
                },
                firstnameRequired: {
                    en: 'Please enter first name',
                    fr: 'Veuillez entrer votre prénom',
                    nl: 'Voer jouw voornaam in',
                    pl: 'Wprowadź Imię',
                    it: 'Inserire il Nome',
                    es: 'Por favor, introduzca su nombre',
                },
                firstnameLetter: {
                    en: 'First name must start with a letter',
                    fr: 'Le prénom doit commencer par une lettre',
                    nl: 'Voornaam moet met een letter beginnen',
                    pl: 'Imię powinno zawierać tylko litery',
                    it: 'Il nome deve iniziare con una lettera',
                    es: 'El nombre debe empezar por una letra',
                },
                lastnameRequired: {
                    en: 'Please enter last name',
                    fr: 'Veuillez entrer votre nom',
                    nl: 'Voer jouw achternaam in',
                    pl: 'Wprowadź Nazwisko',
                    it: 'Inserire il Cognome',
                    es: 'Por favor, introduzca su apellido',
                },
                lastnameLetter: {
                    en: 'Last name must start with a letter',
                    fr: 'Le nom doit commencer par une lettre',
                    nl: 'Achternaam moet met een letter beginnen',
                    pl: 'Nazwisko powinno zawierać tylko litery',
                    it: 'Il cognome deve iniziare con una lettera',
                    es: 'El apellido debe empezar por una letra',
                },
                companyRequired: {
                    en: 'Please select company',
                    fr: 'Veuillez sélectionner votre société',
                    nl: 'Selecteer jouw bedrijf',
                    pl: 'Wprowadź nazwę firmy',
                    it: 'Selezionare o inserire il nome della Societá',
                    es: 'Por favor, seleccione el país',
                },
                operationalManagementRequired: {
                    en: 'Operational direction is mandatory',
                    fr: 'Direction opérationnelle est obligatoire',
                },
                emailRequired: {
                    en: 'Please enter your email',
                    fr: 'Veuillez introduire votre e-mail',
                    nl: 'Voer jouw e-mail in',
                    pl: 'Wprowadź E-mail',
                    it: "Inserire l'email",
                    es: 'Por favor, introduzca su email',
                },
                phoneRequired: {
                    en: 'Please enter your phone',
                    fr: 'Veuillez indiquer votre N° de portable',
                    nl: 'Voer jouw GSM-nummer in',
                    pl: 'Wprowadź numer tel. komórkowego',
                    it: 'Inserire il numero di cellulare',
                    es: 'Por favor, introduzca su teléfono',
                },
                profileRequired: {
                    en: 'Please select a profile',
                    fr: 'Veuillez sélectionner le profil',
                    nl: 'Selecteer een profiel',
                    pl: 'Wybierz profil',
                    it: 'Selezionare il Profilo',
                    es: 'Por favor, seleecione un perfil',
                },
                sectorRequired: {
                    en: 'Please select a sector',
                    fr: 'Veuillez sélectionner un secteur',
                    nl: 'Selecteer een sector',
                    pl: 'Wybierz sektor',
                    it: 'Selezionare il Settore',
                },
                emailFormat: {
                    en: 'Email format is not correct',
                    fr: "Le format de mail n'est pas correct",
                    nl: 'E-mail formaat is niet correct',
                    pl: 'Format adresu E-mail jest niepoprawny',
                    it: "Il formato dell'email non é corretto",
                    es: 'Formato del email incorrecto',
                },
                phoneFormat: {
                    en: 'Phone format is not correct',
                    fr: "Le format du téléphone n'est pas correct",
                    nl: 'Telefoonformaat is niet correct',
                    pl: 'Format telefonu jest niepoprawny',
                    it: 'Il formato del telephono non é corretto',
                    es: 'El formato del teléfono no es correcto',
                },
                functionRequired: {
                    en: 'Please insert function',
                    fr: 'Veuillez insérer la fonction',
                    nl: 'Voeg de functie toe',
                    pl: 'Podaj stanowisko',
                    it: 'Inserire la funzione',
                    es: 'Por favor, inserte la función',
                },
                dateRequired: {
                    en: 'Please select at least one available date for participation',
                    fr: 'Veuillez sélectionner au moins une date disponible pour la participation',
                    nl: 'Kies ten minste één beschikbare datum voor deelname',
                    pl: 'Podaj przynajmniej jedną datę dla tego uczestnictwa',
                    it: 'Selezioni almeno una delle date disponibili per la partecipazione',
                    es: 'Por favor, seleccione al menos una fecha disponible para participar',
                },
                storeRequired: {
                    en: 'You must select at least one store',
                    fr: 'Vous devez sélectionner au moins un magasin',
                    nl: 'U moet ten minste 1 winkel aanduiden',
                    pl: 'Musisz wybrać przynajmniej jeden sklep',
                    it: 'Deve selezionarne almeno una',
                    es: 'Debe seleccionar al menos una tienda',
                },
                storeWithID: {
                    en: 'Store with ID',
                    fr: "Magasin avec l'ID",
                    nl: 'Winkel met ID',
                    pl: 'Sklep z numerem ID',
                    it: 'Il negozio con il codice ID',
                },
                notFound: {
                    en: 'not found',
                    fr: 'introuvable',
                    nl: 'niet gevonden',
                    pl: 'nieznaleziony',
                    it: 'non é stato trovato',
                },
                mainEventFull: (dates: string) => ({
                    en: `We are sorry, but the maximum attendance for ${dates} has been reached.`,
                    fr: `Nous sommes désolés, mais la participation maximale à ${dates} a été atteinte.`,
                    nl: `Het spijt ons, maar het maximale aantal deelnemers voor ${dates} is bereikt.`,
                    pl: `Przepraszamy, limit uczestników na ${dates} został przekroczony.`,
                    it: `Siamo spiacenti ma il numero massimo di partecipanti per ${dates} é stato raggiunto.`,
                    es: `Lo sentimos, pero se ha alcanzado el número máximo de participantes para este evento ${dates}.`,
                }),
                sideEventFull: (names: string) => ({
                    en: `We are sorry, but the maximum attendance for the event ${names} has been reached.`,
                    fr: `Nous sommes désolés, mais la participation maximale à l'événement ${names} a été atteinte.`,
                    nl: `Het spijt ons, maar het maximale aantal deelnemers voor het evenement ${names} is bereikt.`,
                    pl: `Przepraszamy, limit uczestników na wydarzenie ${names} został przekroczony.`,
                    it: `Siamo spiacenti ma il numero massimo di partecipanti per questo evento ${names} é stato raggiunto.`,
                    es: `Lo sentimos, pero se ha alcanzado el número máximo de participantes para ${names}.`,
                }),
                parkingAlreadyReserved: {
                    en: 'We are sorry, but parking spot was already reserved by another company member for',
                    fr: 'Nous sommes désolés, mais une place de parking a déjà été réservée par un autre membre de la société pour le',
                    nl: 'Het spijt ons, maar de parkeerplaats was al gereserveerd door een ander bedrijfslid voor',
                    pl: 'Bardzo nam przykro, ale miejsce parkingowe już zostało zarezerwowane przez innego pracownika firmy na dzień',
                    it: 'Siamo spiacenti, ma un posto auto é giá stato riservato da un altro membro della sua azienda per il',
                    es: 'Lo sentimos, pero la plaza de aparcamiento ya ha sido reservada por otro miembro de la empresa para',
                },
            },
            guestSubmittedNotValidated: {
                en: 'Registration has been submitted to the Fair management. Your guest will receive their e-ticket electronically once the request has been validated.',
                fr: "L'inscription a été soumise à la direction du Salon. Votre invité recevra son ticket par voie électronique une fois la demande validée.",
                nl: 'De registratie is ingediend bij de Beursorganisatie. Jouw gast zal zijn/haar e-ticket elektronisch ontvangen zodra de aanvraag is gevalideerd.',
                pl: 'Zgłoszenie zostało przyjęte i czeka na zaakceptowanie przez Organizatora. Twój gość otrzyma identyfikator w formie elektronicznej jak tylko zaproszenie zostanie zatwierdzone.',
                it: "La registrazione é stata inviata alla Direzione del Salone per l'approvazione. Il suo ospite riceverá via email  il badge di ingresso non appena la richiesta verrá validata.",
                es: 'Su inscripción ha sido enviada a la Dirección del Salón para su aprobación. Su invitado recibirá una acreditación por correo electrónico tan pronto como se haya validado la solicitud.',
            },
            guestSubmittedValidated: {
                en: 'You have successfully registered a new guest. Your guest will receive their e-ticket electronically.',
                fr: 'Vous avez enregistré avec succès un nouvel invité. Votre invité recevra son ticket par voie électronique.',
                nl: 'Je hebt succesvol een nieuwe gast geregistreerd. Jouw gast zal zijn/haar e-ticket elektronisch ontvangen.',
                pl: 'Właśnie zarejestrowałeś swojego gościa. Niebawem otrzyma identyfikator drogą elektroniczną.',
                it: 'La registrazione del nuovo ospite é stata effettuata con successo. Il suo ospite riceverá via email il suo badge di ingresso.',
                es: 'Ha registrado con éxito un nuevo invitado. Su invitado recibirá el ticket electrónicamente.',
            },
            weWillReview: {
                en: "We will review your request to participate to the fair, and we will inform you when it's approved.",
                fr: 'Nous examinerons votre demande de participation à la foire et nous vous informerons de son approbation.',
                nl: 'Wij zullen uw aanvraag tot deelname aan de beurs onderzoeken en u op de hoogte brengen wanneer deze is goedgekeurd.',
                pl: 'Rozpatrzymy Twoją prośbę o udział w targach i poinformujemy Cię, kiedy zostanie ona zatwierdzona.',
                it: 'Esamineremo la sua richiesta di partecipare alla Salone, e la informeremo quando sarà approvata.',
                es: 'Revisaremos su solicitud de participación en la feria y le informaremos cuando sea aprobada.',
            },
            iWillBePresentOn: {
                en: 'I will be present on',
                fr: 'Je serai présent le',
                nl: 'Ik zal aanwezig zijn op',
                pl: 'Biorę udział dnia',
                it: 'Saró presente il',
                es: 'Estaré presente en',
            },
            iWillBePresentOnDate: {
                en: 'MMMM Do',
                fr: 'D MMMM',
                nl: 'D MMMM',
                pl: 'D MMMM',
            },
            iWantParking: {
                en: 'I wish to reserve a parking space',
                fr: 'Je souhaite réserver une place de parking',
                nl: 'Ik wil een parkeerplaats reserveren',
                pl: 'Chcę zarezerwować miejsce parkingowe',
                it: 'Desidero riservare un posto auto',
                es: 'Deseo reservar una plaza de aparcamiento',
            },
            iWantToParticipate: {
                en: 'I want to participate to the',
                fr: 'Je souhaite participer à',
                nl: 'Ik wil deelnemen aan de',
                pl: 'Chcę uczestniczyć w:',
                it: 'Desidero partecipare a',
                es: 'Quiero participar en',
            },
            completed: {
                en: 'Completed',
                fr: 'Complet',
                nl: 'Voltooid',
                pl: 'Uzupełniony',
                it: 'Completato',
                es: 'Completado',
            },
            increaseBadgeNumber: {
                en: 'Please contact your admin to increase the number of ordered badges.',
                fr: 'Veuillez contacter votre compte administrateur pour augmenter le nombre de badges disponibles.',
                nl: 'Neem dan contact op met uw admin om het aantal bestelde badges te verhogen.',
                pl: 'Proszę o kontakt z administratorem w celu zwiększenia dostępności identyfikatorów/wejściówek.',
                it: "Contatti l'account amministratore per aumentare il numero di badge disponibili.",
                es: 'Póngase en contacto con la organización para aumentar el número de acreditaciones solicitadas',
            },
            selectWorkshops: {
                en: 'Select workshops',
                fr: 'Sélectionnez des workshops',
                nl: 'Selecteer workshops',
                pl: 'Wybierz event towarzyszący',
                it: 'Selezionare workshop',
                es: 'Seleccionar workshop',
            },
            selectChoice: {
                en: 'Select from the following choices',
                fr: 'Sélectionnez parmi les choix suivants',
            },
            useInvitationCode: {
                en: 'Use invitation code',
                fr: 'Utilisez votre code d’invitation',
            },
            useInvitationCodeOptional: {
                en: 'Use invitation code (optional)',
                fr: 'Utilisez votre code d’invitation (optionnel)',
            },
            imInvitedWithCode: {
                en: "I'm invited with a voucher code",
                fr: 'Je suis invité avec un code',
            },
        },
        confirmation: {
            visit: {
                en: "Visit Let's Connect Platform",
                fr: "Visiter la plateforme Let's Connect",
                nl: "Bezoek het platform Let's Connect",
                pl: "Odwiedź platformę Let's Connect",
                it: "Visiti la piattaforma Let's Connect",
                es: "Visite la plataforma Let's Connect",
            },
            register: {
                en: 'Register someone else',
                fr: "Enregistrer quelqu'un d'autre",
                nl: 'Registreer iemand anders',
                pl: 'Zarejestruj kolejną osobę',
                it: "Registri un'altra persona",
                es: 'Registrar otra persona',
            },
            download: {
                en: 'Download my E-Ticket',
                fr: 'Télécharger mon e-ticket',
                nl: 'Download mijn E-ticket',
                pl: 'Pobierz identyfikator',
                it: 'Scarica il mio Badge',
                es: 'Descargar mi acreditación',
            },
            tableTitle: {
                en: 'Summary of your registration',
                fr: 'Résumé de votre inscription',
                nl: 'Samenvatting van uw inschrijving',
                pl: 'Podsumowanie Twojej rejestracji',
                it: 'I dettagli della sua registrazione',
                es: 'Resumen de su inscripción',
            },
            modify: {
                en: 'Modify',
                fr: 'Modifier',
                nl: 'Wijzigen',
                pl: 'Modyfikuj uczestnictwo',
                it: 'Modificare',
                es: 'Modificar',
            },
            badgeId: {
                en: 'Badge ID',
                pl: 'ID wejściówki',
                it: 'Numero identificativo del suo badge',
                es: 'Número de identificación de su acreditación',
            },
            fair: {
                en: 'Fair',
                fr: 'Salon',
                nl: 'Beurs',
                pl: 'Targi',
                it: 'Salone',
                es: 'Salón',
            },
            summary: {
                en: 'Summary',
                fr: 'Récapitulatif',
            },
            planning: {
                en: 'Planning',
                es: 'Acreditación válida para los días',
            },
        },
    },
    help: {
        title: {
            en: 'How can we help you?',
            fr: 'Comment pouvons-nous vous aider ?',
            nl: 'Hoe kunnen we je helpen?',
            pl: 'Jak możemy pomóc?',
            it: 'Ha bisogno di aiuto?',
            es: '¿Cómo podemos ayudarle?',
        },
        sentTitle: {
            en: 'Message sent',
            fr: 'Message envoyé',
            nl: 'Bericht verzonden',
            pl: 'Wiadomość wysłana',
            it: 'Messaggio inviato',
            es: 'Mensaje enviado',
        },
        sentMsg: (email: string) => ({
            en: `Thank you for contacing us. We have recieved your message. We will reply to the provided email ${email} as soon as we review your message.`,
            fr: `Merci de nous avoir contacés. Nous avons bien reçu votre message. Nous répondrons à l'e-mail ${email} fourni dès que nous aurons examiné votre message.`,
            nl: `Bedankt voor het contaceren. We hebben uw bericht ontvangen. We zullen de opgegeven e-mail ${email} beantwoorden zodra we uw bericht beoordelen.`,
            pl: 'Dziękujemy za kontakt. Otrzymaliśmy Twoją wiadomość. Odpiszemy na podany adres {email} jak tylko zapoznamy się z treścią Twojej wiadomości.',
            it: "Grazie per averci contattato. Abbiamo ricevuto il suo messaggio. Le risponderemo all'indirizzo email fornito non appena avremo esamineremo il suo messaggio",
            es: `Gracias por contactar con nosotros. Hemos recibido su mensaje. Le responderemos al email ${email} proporcionado tan pronto como revisemos su mensaje.`,
        }),
        fullName: {
            en: 'Full Name',
            fr: 'Prénom & Nom',
            nl: 'Voornaam en achternaam ',
            pl: 'Imię i nazwisko',
            it: 'Nome e cognome',
            es: 'Nombre completo',
        },
        message: {
            en: 'Your Message',
            fr: 'Votre message',
            nl: 'Jouw bericht',
            pl: 'Wiadomość',
            it: 'Il suo messaggio',
            es: 'Su Mensaje',
        },
        send: {
            en: 'Send',
            fr: 'Envoyer',
            nl: 'Verzonden',
            pl: 'Wyślij',
            it: 'Invia',
            es: 'Enviar',
        },
        errors: {
            emailRequired: {
                en: 'Email is required',
                fr: 'Un e-mail est requis',
                nl: 'E-mail is vereist',
                pl: 'E-mail jest wymagany',
                it: 'Una mail é richiesta',
                es: 'Se necesita el email',
            },
            nameRequired: {
                en: 'Name is required',
                fr: 'Un nom est requis',
                nl: 'Naam is vereist',
                pl: 'Imię i nazwisko jest wymagane',
                it: 'Il nome é richiesto',
                es: 'Se necesita el nombre',
            },
            messageRequired: {
                en: 'Message can not be empty',
                fr: 'Le message ne peut pas être vide',
                nl: 'Bericht kan niet leeg zijn',
                pl: 'Pole wiadomości nie może być puste',
                it: 'Il messaggio non puó essere vuoto',
                es: 'El mensaje no puede estar en blanco',
            },
        }
    },
    eventRegistration: {
        registerUsingPhone: {
            en: 'Register using your phone',
            fr: 'Enregistrez-vous en utilisant votre téléphone',
            nl: 'Registreer met uw telefoon',
            pl: 'Zarejestruj się za pomocą telefonu',
            it: 'Registrati utilizzando il tuo telefono',
            es: 'Regístrese utilizando su teléfono',
        },
        registerUsingEmail: {
            en: 'Register using your email',
            fr: 'Enregistrez-vous en utilisant votre email',
            nl: 'Registreer met uw e-mailadres',
            pl: 'Zarejestruj się za pomocą maila',
            it: 'Registrati utilizzando la tua mail',
            es: 'Regístrese utilizando su email corporativo',
        },
        modifyParticipation: {
            en: 'Modify your participation',
            fr: 'Modifiez votre participation',
            nl: 'Pas uw deelname aan',
            pl: 'Zmień swój udział',
            it: 'Modifica la tua partecipazione',
            es: 'Modifique su participación',
        },
        alreadyRegistered: {
            en: 'Already registered?',
            fr: 'Vous êtes déjà inscrit ?',
            nl: 'Bent u al geregistreerd?',
            pl: 'Jesteś już zarejestrowany?',
            it: 'Sei già registrato?',
            es: '¿Ya está registrado?',
        },
        sendConfirmationCode: {
            en: 'Send confirmation code',
            fr: 'Envoyez le code de confirmation',
            nl: 'Stuur bevestigingscode',
            pl: 'Proszę wysłać kod potwierdzenia',
            it: 'Invia il codice di conferma',
            es: 'Enviar código de confirmación',
        },
        verifyPhone: {
            en: 'Verify your phone',
            fr: 'Vérifiez votre téléphone',
            nl: 'Controleer uw telefoon',
            pl: 'Sprawdź swój telefon',
            it: 'Verifica il tuo numero di telefono',
            es: 'Verifique su teléfono',
        },
        verifyEmail: {
            en: 'Verify your email',
            fr: 'Vérifiez votre adresse e-mail',
            nl: 'Verifieer uw e-mail',
            pl: 'Sprawdź swój e-mail',
            it: 'Verifica la tua mail',
            es: 'Verifique su email',
        },
        pleaseEnterCodePhone: {
            en: 'Please enter the code we sent to your phone',
            fr: 'Saisissez le code que nous vous avons envoyé sur votre téléphone',
            nl: 'Voer de code in die we naar uw telefoon hebben gestuurd',
            pl: 'Wpisz kod, który wysłaliśmy na Twój telefon',
            it: 'Inserisci il codice che abbiamo inviato al tuo telefono',
            es: 'Introduzca el código que le hemos enviado a su teléfono',
        },
        pleaseEnterCodeEmail: {
            en: 'Please enter the code we sent to your email',
            fr: 'Saisissez le code que nous vous avons envoyé par e-mail',
            nl: 'Voer de code in die we naar uw e-mail hebben gestuurd',
            pl: 'Proszę wpisać kod, który wysłaliśmy na Twój adres e-mail',
            it: 'Inserisca il codice che abbiamo inviato alla sua mail',
            es: 'Introduzca el código que le hemos enviado a su email',
        },
        didntReceiveCode: {
            en: "Didn't receive your code?",
            fr: "Vous n'avez pas reçu votre code ?",
            nl: 'Heeft u uw code niet ontvangen?',
            pl: 'Nie otrzymałeś swojego kodu?',
            it: 'Non ha ricevuto il codice?',
            es: '¿No ha recibido su código?',
        },
        sendItAgain: {
            en: 'Send it again',
            fr: 'Renvoyez-le',
            nl: 'Stuur hem opnieuw',
            pl: 'Wyślij go ponownie',
            it: 'Invia di nuovo',
            es: 'Envíelo de nuevo',
        },
        nextResendIn: {
            en: 'Next resend in',
            fr: 'Prochain renvoi dans',
            nl: 'Vervolgens opnieuw insturen in',
            pl: 'Następne wysłanie za',
            it: 'Prossimo rinvio tra',
            es: 'Siguiente reenvío en',
        },
        seconds: {
            en: 'seconds',
            fr: 'secondes',
            nl: 'seconden',
            pl: 'sekund',
            it: 'secondi',
            es: 'segundos',
        },
        modifyRegistration: {
            en: 'Modify your registration',
            fr: 'Modifiez votre inscription',
            nl: 'Wijzig uw registratie',
            pl: 'Zmień swoją rejestrację',
            it: 'Modifica la tua registrazione',
            es: 'Modifique su participación',
        },
        yourBadgeCode: {
            en: 'Your badge code:',
            fr: 'Code de votre badge :',
            nl: 'Uw badge code:',
            pl: 'Twój kod identyfikacyjny:',
            it: 'Il codice indicato nel tuo badge:',
            es: 'Su código de identificación:',
        },
        iAm: {
            en: 'I Am',
            fr: 'Je suis',
            nl: 'Ik ben',
            pl: 'Jestem',
            it: 'Io sono',
            es: 'Yo soy',
        },
        groupStores: {
            en: 'Store',
            fr: 'Magasin',
            nl: 'Winkel',
            pl: 'Sklep',
            it: 'Punto vendita',
            es: 'Tienda Carrefour',
        },
        groupCarrefour: {
            en: 'Carrefour',
            es: 'Oficinas Carrefour',
        },
        guestMedia: {
            en: 'Guest / Media',
            fr: 'Invités / Médias',
            nl: 'Gast / Media',
            pl: 'Gość / Media',
            it: 'Ospite / Media',
            es: 'Invitado /  Prensa',
        },
        organization: {
            en: 'Organization',
            fr: "L'organisation",
            nl: 'Organisatie',
            pl: 'Organizacja',
            it: 'Organizzazione',
            es: 'Organización',
        },
        confirmSelection: {
            en: 'Confirm selection',
            fr: 'Confirmez la sélection',
            nl: 'Bevestig de selectie',
            pl: 'Potwierdź wybór',
            it: 'Conferma selezione',
            es: 'Confirmar selección',
        },
        selectAccount: {
            en: 'Select Account',
            fr: 'Sélectionnez un compte',
            nl: 'Selecteer account',
            pl: 'Wybierz konto',
            it: 'Seleziona Account',
            es: 'Seleccionar Cuenta',
        },
        loggedInWithPhone: {
            en: 'Logged in with phone:',
            fr: 'Connecté avec le téléphone :',
            nl: 'Aangemeld met telefoon:',
            pl: 'Zalogowany za pomocą telefonu:',
            it: 'Registrato con numero di telefono',
            es: 'Registrado con teléfono:',
        },
        loggedInWithEmail: {
            en: 'Logged in with email:',
            fr: "Connecté avec l'email :",
            nl: 'Aangemeld met e-mail:',
            pl: 'Zalogowany za pomocą e-maila:',
            it: 'Registrato con email:',
            es: 'Registrado con correo electrónico:',
        },
        iDontSeeMyName: {
            en: "I don't see my name.",
            fr: 'Je ne vois pas mon nom.',
            nl: 'Ik zie mijn naam niet.',
            pl: 'Nie widzę swojego imienia i  nazwiska.',
            it: 'Non vedo il mio nome.',
            es: 'No veo mi nombre.',
        },
        registerHere: {
            en: 'Register here.',
            fr: 'Inscrivez-vous ici.',
            nl: 'Schrijf je hier in.',
            pl: 'Zarejestruj się tutaj.',
            it: 'Registrati qui.',
            es: 'Regístrese aquí.',
        },
        personalInfo: {
            en: 'Personal info',
            fr: 'Informations personnelles',
            nl: 'Persoonlijke gegevens',
            pl: 'Dane osobowe',
            it: 'Informazioni personali',
            es: 'Información personal',
        },
        checkPersonalInfo: {
            en: 'Check if your personal info is up to date',
            fr: 'Vérifiez si vos informations personnelles sont à jour',
            nl: 'Controleer of uw persoonlijke gegevens up-to-date zijn',
            pl: 'Sprawdź, czy Twoje dane osobowe są aktualne',
            it: 'Controlla che le tue informazioni personali siano aggiornate',
            es: 'Compruebe si sus datos personales están actualizados',
        },
        validate: {
            en: 'Validate',
            fr: 'Validez',
            nl: 'Valideren',
            pl: 'Zatwierdź',
            it: 'Validare',
            es: 'Validar',
        },
        next: {
            en: 'Next',
            fr: 'Suivant',
            nl: 'Volgende',
            pl: 'Następny',
            it: 'Avanti',
            es: 'Siguiente',
        },
        back: {
            en: 'Back',
            fr: 'Retour',
            nl: 'Terug',
            pl: 'Wstecz',
            it: 'Indietro',
            es: 'Atrás',
        },
        firstName: {
            en: 'First Name',
            fr: 'Prénom',
            nl: 'Voornaam',
            pl: 'Imię',
            it: 'Nome',
            es: 'Nombre',
        },
        lastName: {
            en: 'Last Name',
            fr: 'Nom',
            nl: 'Achternaam',
            pl: 'Nazwisko',
            it: 'Cognome',
            es: 'Apellidos',
        },
        phone: {
            en: 'Phone',
            fr: 'Téléphone',
            nl: 'Telefoon',
            pl: 'Telefon',
            it: 'Telefono',
            es: 'Teléfono',
        },
        function: {
            en: 'Function',
            fr: 'Fonction',
            nl: 'Functie',
            pl: 'Funkcja',
            it: 'Ruolo / Funzione',
            es: 'Función',
        },
        storeLabel: {
            en: 'Store',
            fr: 'Magasin',
            nl: 'Winkel',
            pl: 'Sklep',
            it: 'Punto vendita',
            es: 'Tienda',
        },
        addStore: {
            en: 'Add another store',
            fr: 'Ajoutez un autre magasin',
            nl: 'Een andere winkel toevoegen',
            pl: 'Dodaj kolejny sklep',
            it: 'Aggiungi un altro punto vendita',
            es: 'Añadir otra tienda',
        },
        yourParticipation: {
            en: 'Your Participation',
            fr: 'Votre participation',
            nl: 'Uw deelname',
            pl: 'Twoje uczestnictwo',
            it: 'La tua Partecipazione',
            es: 'Su participación',
        },
        participate: {
            en: 'Participate',
            fr: 'Participez',
            nl: 'Deelnemen',
            pl: 'Weź udział',
            it: 'Partecipa',
            es: 'Participar',
        },
        updateParticipation: {
            en: 'Update participation',
            fr: 'Actualisez la participation',
            nl: 'Deelname bijwerken',
            pl: 'Aktualizuj uczestnictwo',
            it: 'Aggiorna la partecipazione',
            es: 'Actualizar la participación',
        },
        iWillBePresentOn: {
            en: 'I will be present on:',
            fr: 'Je serai présent le :',
            nl: 'Ik zal aanwezig zijn op:',
            pl: 'Będę obecna/y w dniu:',
            it: 'Saró presente il:',
            es: 'Estaré presente el:',
        },
        yourWorkshops: {
            en: 'Your Workshops for',
            fr: 'Vos ateliers pour',
            nl: 'Uw workshops voor',
            pl: 'Twoje warsztaty na',
            it: 'Il tuo Workshop per',
            es: 'Sus talleres para',
        },
        letsConnectPlatform: {
            en: "Let's Connect platform",
            it: "Piattaforma Let's Connect ",
            es: "Plataforma Let's Connect",
        },
        agreementLabel: {
            en: 'You will be able to access fair content, agenda and receive help from our support',
            fr: "Vous pourrez accéder aux informations pratiques, à un agenda et recevoir de l'aide de notre service d'assistance.",
            nl: 'U krijgt toegang tot  praktische informatie, agenda en hulp van onze ondersteuning.',
            pl: 'Będziesz mógł uzyskać dostęp do praktycznych informacji, agendy i otrzymać pomoc od naszego wsparcia',
            it: 'Potrai accedere ai contenuti del Salone, all\'agenda e ricevere aiuto dal nostro supporto',
            es: 'Podrás acceder a contenidos de la feria, agenda y recibir ayuda de nuestro soporte',
        },
        summary: {
            en: 'Summary of your registration',
            fr: 'Résumé de votre inscription',
            nl: 'Samenvatting van uw registratie',
            pl: 'Podsumowanie rejestracji',
            it: 'Riepilogo della tua iscrizione',
            es: 'Resumen de su inscripción',
        },
        summaryDays: {
            en: 'Accreditation valid for the days',
            fr: "L'accréditation est valable pour les jours",
            nl: 'Accreditatie geldig voor de dagen',
            pl: 'Akredytacja ważna w dniach',
            it: 'Registrazione valida per le giornate',
            es: 'Acreditación válida para los días',
        },
        fair: {
            en: 'Fair',
            fr: 'Salon',
            nl: 'Beurs',
            pl: 'Targi',
            it: 'Salone',
            es: 'Salón',
        },
        edit: {
            en: 'edit my registration',
            fr: 'editer mon inscription',
            nl: 'mijn registratie bewerken',
            pl: 'edytuj moją rejestrację',
            it: 'Modifica la mia registazione',
            es: 'editar mi registro',
        },
        registerAnother: {
            en: 'Register another person',
            fr: 'Enregistrez une autre personne',
            nl: 'Registreer een andere persoon',
            pl: 'Zarejestruj inną osobę',
            it: "Registra un'altra persona",
            es: 'Registrar a otra persona',
        },
        atLeast1Day: {
            en: 'Please select at least one day',
            fr: 'Veuillez sélectionner au moins un jour',
            nl: 'Selecteer ten minste één dag',
            pl: 'Proszę wybrać co najmniej jeden dzień',
            it: 'Seleziona almeno un giorno',
            es: 'Seleccione al menos un día',
        },
    },
};

export default makeTranslation(translationsPublic);
