import {action, computed, makeObservable, observable} from 'mobx';
import {IInvoiceContext} from '../../graphql/api/invoice/Invoice';
import {
    createInvoice,
    loadInvoices,
    markAsPaid,
    sendInvoice,
    updateInvoice,
    removeInvoiceProduct,
    recollectDraftInvoice,
    deleteInvoice,
    cancelInvoice,
    // convertCancelInvoice,
} from './invoiceProxy';
import {orderStandStore} from '../OrderStand/OrderStandStore';
import { projectStore } from '../stores/ProjectStore';
import Swal from 'sweetalert2';

export interface IInvoice extends IInvoiceContext
{
    id: string
}

class InvoiceStore
{
    @observable invoices: IInvoice[] = [];
    @observable selectedId: string = null;

    @computed
    get selected()
    {
        return this.invoices.find(i => i.id === this.selectedId);
    }

    constructor()
    {
        makeObservable(this);
    }

    reload(projectId: string)
    {
        loadInvoices(projectId).then(action('loadInvoices', (res) =>
        {
            if (res)
            {
                this.invoices = res;
            }
        }));
    }

    @action
    select(id: string)
    {
        this.selectedId = id;
    }

    createInvoice(id: string)
    {
        return createInvoice(id).then(action('createInvoice', res =>
        {
            if (res)
            {
                const {stand, invoice} = res;
                const loadedStand = orderStandStore.stands.find(s => s.id === stand.id);
                loadedStand.approved = stand.approved;
                this.invoices.push(invoice);
                return invoice.id;
            }
        }));
    }

    update(i: IInvoice)
    {
        updateInvoice({
            id: i.id,
            to: i.to,
            comment: i.comment,
            PO: i.PO?.filter(v => v),
            vat: i.vat,
            date: i.date,
            dueDate: i.dueDate,
        }).then(invoice =>
        {
            if (invoice)
            {
                const loadedInvoice = this.invoices.find(_ => _.id === invoice.id);
                loadedInvoice.total.vat = invoice.total.vat;
            }
            else
            {
                this.reload(projectStore.id);
            }
        });
    }

    sendInvoice(id: string, passCheck: boolean = false)
    {
        return sendInvoice(id, passCheck).then(action('sendInvoice', res =>
        {
            if (res.data?.sendInvoice)
            {
                const {stand, invoice} = res.data.sendInvoice;
                const loadedInvoice = this.invoices.find(i => i.id === invoice.id);
                loadedInvoice.status = invoice.status;
                loadedInvoice.number = invoice.number;
                loadedInvoice.total = invoice.total;
                loadedInvoice.date = invoice.date;
                loadedInvoice.dueDate = invoice.dueDate;
                loadedInvoice.sent = invoice.sent;
                const loadedStand = orderStandStore.stands.find(s => s.id === stand.id);
                loadedStand.approved = stand.approved;
                loadedStand.invoiced = stand.invoiced;
            }
            else if (res.errors)
            {
                // const loadedInvoice = this.invoices.find(i => i.id === id);
                // loadedInvoice.sent = true;
                if (!passCheck && res.errors?.[0].message === 'different-company-user')
                {
                    Swal.fire(
                    {
                        title: `You are about to send ${projectStore.selectedCountry === 'PL' ? 'proforma' : 'invoice'} to an email address that belongs to another company. Are you sure you want to proceed?`,
                        icon: 'question',
                        showCancelButton: true,
                    }).then((result) =>
                    {
                        if (result.value)
                        {
                            this.sendInvoice(id, true);
                        }
                    })
                }
                else
                {
                    alert(res.errors?.[0].message)
                }
            }
            return res;
        }));
    }

    cancel(invoiceId: string, standId: string, products?: string[])
    {
        return cancelInvoice({invoiceId, standId, products}).then(action('cancelInvoice', res =>
        {
            if (res)
            {
                const {stand, cancelInvoice: newCancelInvoice, canceledInvoice} = res;
                const loadedCanceledInvoice = this.invoices.find(i => i.id === canceledInvoice.id);
                Object.assign(loadedCanceledInvoice, canceledInvoice);
                this.invoices.push(newCancelInvoice);
                const loadedStand = orderStandStore.stands.find(s => s.id === stand.id);
                loadedStand.approved = stand.approved;
                loadedStand.invoiced = stand.invoiced;
                loadedStand.canceled = stand.canceled;
                return newCancelInvoice.id;
            }
        }));
    }

    markAsPaid(id: string, standId?: string)
    {
        markAsPaid(id).then(action('markAsPaid', res =>
        {
            if (res)
            {
                const loaded = this.invoices.find(i => i.id === res.id);
                loaded.status = res.status;
                if (projectStore.selectedCountry === 'PL')
                {
                    orderStandStore.loadStand(standId);
                }
            }
        }));
    }

    // convertCancelInvoice(invoiceId: string, standId: string)
    // {
    //     convertCancelInvoice({invoiceId, standId}).then(action('convertCancelInvoice', res =>
    //     {
    //         if (res)
    //         {
    //             const loaded = this.invoices.find(i => i.id === res.id);
    //             loaded.total = res.total;
    //             loaded.altTotal = res.altTotal;
    //         }
    //     }));
    // }

    removeItemFromStand(id: string, standId: string, pkg: boolean = false)
    {
        removeInvoiceProduct({invoiceId: this.selectedId, package: pkg, standId, product: id }).then(action('removeInvoiceProduct', res =>
        {
            if (res)
            {
                const stand = orderStandStore.stands.find(s => s.id === res.id);
                stand.approved = res.approved;
            }
        }));
    }

    recollect(invoiceId: string, standId: string)
    {
        recollectDraftInvoice({invoiceId, standId}).then(action('recollectDraftInvoice', res =>
        {
            if (res)
            {
                const stand = orderStandStore.stands.find(s => s.id === res.id);
                stand.approved = res.approved;
                stand.invoiced = res.invoiced;
            }
        }));
    }

    delete(invoiceId: string, standId: string)
    {
        return deleteInvoice({invoiceId, standId}).then(action('deleteInvoice', res =>
        {
            if (res)
            {
                if (this.selectedId === invoiceId)
                {
                    this.select(null);
                }
                const index = this.invoices.findIndex(i => i.id === invoiceId);
                if (index >= 0)
                {
                    this.invoices.splice(index, 1);
                }
                const stand = orderStandStore.stands.find(s => s.id === res.id);
                stand.approved = res.approved;
            }
            return !!res;
        }));
    }
}

export const invoiceStore = new InvoiceStore();
