import React, {useEffect, useState} from 'react';
import './DropZone.scss';

interface Props
{
    // comma-separated list of content types
    accept?: string
    onlySingleFile?: boolean
    onAttachment(file: File): void
}

export default function DropZone({accept, onlySingleFile, onAttachment, children}: React.PropsWithChildren<Props>)
{
    const [show, setShow] = useState(false);

    const handleDrop = (e: React.DragEvent) =>
    {
        const acceptList = accept?.split(',');
        const isAccepted = accept ?
            (file: File) => acceptList.some(a => a.startsWith('.') ? getExtRegExp(a).test(file.name) : a === file.type)
            :
            () => true;
        e.preventDefault();
        setShow(false);
        if (e.dataTransfer.items)
        {
            for (const item of e.dataTransfer.items)
            {
                if (item.kind === 'file')
                {
                    const file = item.getAsFile();
                    if (isAccepted(file))
                    {
                        onAttachment(file);
                        if (onlySingleFile)
                        {
                            break;
                        }
                    }
                }
            }
        }
        else
        {
            for (const file of e.dataTransfer.files)
            {
                if (isAccepted(file))
                {
                    onAttachment(file);
                    if (onlySingleFile)
                    {
                        break;
                    }
                }
            }
        }
    };

    useEffect(() =>
    {
        let counter = 0;

        const handleEnter = (e: DragEvent) =>
        {
            ++counter;
            const acceptList = accept?.split(',');
            const acceptExtensions = acceptList?.some(a => a.startsWith('.'));
            const {items, files} = e.dataTransfer;
            if ((
                items?.length &&
                [...items].some(item =>
                    item.kind === 'file' &&
                    (
                        !accept ||
                        acceptExtensions ||
                        acceptList.some(a => a === item.type)
                    )
                )
            ) || (
                files?.length &&
                [...files].some(file =>
                    !accept ||
                    acceptList.some(a => a.startsWith('.') ? getExtRegExp(a).test(file.name) : a === file.type)
                )
            ) || (
                !items?.length && !files?.length
            ))
            {
                setShow(true);
            }
        };
        const handleLeave = () =>
        {
            --counter;
            if (!counter)
            {
                setShow(false);
            }
        };
        const setHidden = () =>
        {
            counter = 0;
            setShow(false);
        };

        document.addEventListener('dragenter', handleEnter);
        document.addEventListener('dragleave', handleLeave);
        document.addEventListener('drop', setHidden);
        return () =>
        {
            document.removeEventListener('dragenter', handleEnter);
            document.removeEventListener('dragleave', handleLeave);
            document.removeEventListener('drop', setHidden);
        };
    }, [accept]);

    return (
        <div className={'DropZone ' + (show ? '' : 'hidden')} onDrop={handleDrop} onDragOver={preventDefault}>
            {children || 'Drop files here'}
        </div>
    );
}

const preventDefault = (e) => e.preventDefault();

const getExtRegExp = (ext: string) => new RegExp(ext.replace(/[\\\[\]()+?.*]/g, c => '\\' + c) + '$', 'i');
