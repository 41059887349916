// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticleBlock {
  overflow: hidden;
  height: 100%;
}
.ArticleBlock .text * {
  font-family: "open-sans", sans-serif;
}
.ArticleBlock .text img {
  max-width: 100%;
  height: auto;
}
.ArticleBlock .tox-tinymce {
  height: 100% !important;
}`, "",{"version":3,"sources":["webpack://./frontend/Pages/ArticleBlock.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;AACF;AACI;EACE,oCAAA;AACN;AACI;EACE,eAAA;EACA,YAAA;AACN;AAEE;EACE,uBAAA;AAAJ","sourcesContent":[".ArticleBlock {\n  overflow: hidden;\n  height: 100%;\n  .text {\n    * {\n      font-family: 'open-sans', sans-serif;\n    }\n    img {\n      max-width: 100%;\n      height: auto;\n    }\n  }\n  .tox-tinymce {\n    height: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
