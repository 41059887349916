import {observer} from 'mobx-react-lite';
import FA from 'react-fontawesome';
import moment from 'moment';
import './PageSettings.scss';
import {IPage, pageStore} from './PageStore';
import {projectStore} from '../stores/ProjectStore';
import {profiles} from '../data/profiles';
import TagSelect from '../common/TagSelect';
import {ContactSelection} from './ContactSelection';
import {pickLanguage} from '../stores/utility';
import {getName} from '../common/getName';

interface Props
{
    page: IPage
}

export default observer(function PageSettings({page}: Props)
{
    const id = page.id;
    const country = projectStore.selectedCountry;
    return (
        <div className='PageSettings'>
            <input
                type='text'
                className='setting-group form-control'
                disabled={page?.mainPage}
                placeholder='Page name'
                value={page?.name || ''}
                onChange={e => pageStore.changePage(id, 'name', e.target.value)}
            />
            <div className='setting-group user-select-all cursor-pointer' title='Click to copy' onClick={() => document.execCommand('copy')}>
                <span className='user-select-none pointer-events-none pr-3'>Page ID:</span>
                <span>{page?.id}</span>
                <FA name='copy' className='far user-select-none pointer-events-none pl-2 text-larger float-right'/>
            </div>
            <div className='setting-group'>
                <input
                    type='checkbox'
                    id='page-template'
                    checked={!!page?.isTemplate}
                    onChange={e => pageStore.changePage(id, 'isTemplate', e.target.checked)}
                />
                <label htmlFor='page-template' className='mb-0 ml-2'>Template</label>
            </div>
            <div className='setting-group'>
                <p>Keywords</p>
                <input
                    className='setting-group form-control'
                    type='text'
                    defaultValue={page?.keywords?.join(', ') || ''}
                    onChange={e => pageStore.changePage(id, 'keywords', e.target.value.split(',').map(v => v.trim()).filter(v => v))}
                />
            </div>
            <div className='setting-group'>
                <TagSelect
                    name='View access'
                    boldName={false}
                    options={profiles.filter(p => p.countries.includes(country) && p.id != 'hostess').map(p => [p.id, pickLanguage(p.name)])}
                    selected={page?.viewAccess || []}
                    allSelector
                    onChange={a =>
                    {
                        const prevAccess = page?.viewAccess || [];
                        if (prevAccess.length < a.length)
                        {
                            const added = a.filter(p => !prevAccess.includes(p));
                            for (const item of page.items)
                            {
                                pageStore.changeItem(id, item.id, 'viewAccess', item.props?.viewAccess ? [...item.props.viewAccess, ...added] : added);
                            }
                        }
                        if (prevAccess.length > a.length)
                        {
                            const removed = prevAccess.filter(p => !a.includes(p))[0];
                            for (const item of page.items)
                            {
                                if (item.props?.viewAccess?.includes(removed))
                                {
                                    pageStore.changeItem(id, item.id, 'viewAccess', item.props.viewAccess.filter(p => p != removed));
                                }
                            }
                        }
                        pageStore.changePage(id, 'viewAccess', a);
                    }}
                />
            </div>
            <div className='setting-group'>
                <p className='border-bottom'>Contacts with only content edit access</p>
                <ContactSelection selected={page?.partialEditAccess || []} onChange={a => pageStore.changePage(id, 'partialEditAccess', a)}/>
            </div>
            <div className='setting-group'>
                <p className='border-bottom'>Contacts with full edit access</p>
                <ContactSelection selected={page?.editAccess || []} onChange={a => pageStore.changePage(id, 'editAccess', a)}/>
            </div>
            {!!page?.updatedAt && !!page?.updatedBy &&
            <div className='setting-group'>
                Last updated by {getName(page?.updatedBy)} on {moment(page?.updatedAt).format('DD/MM/YYYY, H:mm')}
            </div>
            }
            {!!projectStore.isFRNonFood &&
            <div className='setting-group'>
                <div>
                    <input
                        type='checkbox'
                        id='orderReports'
                        checked={!!page?.orderReports}
                        onChange={e => pageStore.changePage(id, 'orderReports', e.target.checked)}
                    />
                    <label htmlFor='orderReports' className='mb-0 ml-2'>Show in order reports</label>
                </div>
                <div className='d-flex align-items-center mt-2'>
                    <label htmlFor='reportGroup' className='text-nowrap mb-0 mr-2'>Group:</label>
                    <input
                        className='form-control'
                        type='text'
                        id='reportGroup'
                        defaultValue={page?.reportGroup || ''}
                        placeholder='None'
                        onChange={e => pageStore.changePage(id, 'reportGroup', e.target.value)}
                    />
                </div>
            </div>
            }
        </div>
    );
});
